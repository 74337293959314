import { useContext, useMemo } from "react";
import { Store } from "../Store";


export const useIsInternalOrExternal = () => {
    const {header: {data: {user: {mpInternal}}}} = useContext(Store);

    const isInternalAdmin = useMemo(() => {
        return mpInternal;
    }, [mpInternal]);

    return isInternalAdmin;
};