import { useContext } from "react";
import { Store } from "../Store";
import { NotificationType } from "../utils/interface";

interface NotificationCount {
    total: number;
    unread: number;
}

const useGetNotificationCount = (type: NotificationType): NotificationCount => {
    const {
        header: {
            data: {
                notifications,
            }
        },
    } = useContext(Store);
    
    return {
        total: notifications?.filter(item => item.notificationTypeId === type?.typeId).length || 0,
        unread: notifications?.filter(item => item.notificationTypeId === type?.typeId && item.read === false).length || 0,
    };
}

export default useGetNotificationCount;