import { AxiosError } from "axios";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import React, { CSSProperties, FunctionComponent, ReactElement, useContext, useEffect, useMemo, useState } from "react";
import { Link, generatePath, useNavigate, useParams } from "react-router-dom";
import { Store } from "../../Store";
import Button from "../../components/Button/Button";
import { RadioButtonGroup } from "../../components/UI/Form/RadioButtonsGrouped/RadioButtonsGrouped";
import useHandleBlocker from "../../customHooks/useHandleBlocker";
import { useOnError } from "../../customHooks/useOnError";
import ImageImports from "../../utils/ImageImports";
import { QuoteRequestSurvey } from "../../utils/interface";
import { CustomerSurveyApi, useGet_CustomerSurvey } from "./CustomerSurveyHttpServices";

type SurveyProps = {
  className?: string;
  style?: CSSProperties;
  textClassName?: string;
  text?: string | ReactElement;
  preImg?: ReactElement;
  img?: ReactElement;
  disabled?: boolean;
  viewOnly: boolean;
};

const SurveyWizard: FunctionComponent<SurveyProps> = (props: SurveyProps) => {
  const [completion, setCompletion] = useState<number>(0);
  const { selectedPlant } = useContext(Store);

  const {quoteRequestId} = useParams();
  const id = useMemo(() => {
    return Number(quoteRequestId || '');
  }, [quoteRequestId]);

  const { Arrow } = ImageImports;
  const {viewOnly} = props;

  interface Question {
    affirm?: boolean;
    affirmText: string;
    rangeValue?: number;
    rangeText: string;
    rangeOptions: { label: string; value: number }[];
    complete: boolean;
    ready: boolean;
  }

  const initialValues: Question[] = [
    {
      affirmText: "Was the quote delivered within the promise date?* ",
      affirm: undefined,
      rangeText: "How many days past due was the quote?*",
      rangeValue: undefined,
      rangeOptions: [
        {
          label: "≤ 15 Days",
          value: 14,
        },
        {
          label: "16 - 30 Days",
          value: 30,
        },
        {
          label: "31 - 45 Days",
          value: 45,
        },
        {
          label: "46 - 60 Days",
          value: 60,
        },
        {
          label: "≥ 60 Days",
          value: 61,
        },
      ],
      complete: false,
      ready: true,
    },
    {
      affirmText: "Did the quoted lead time align with your expectation?*",
      affirm: undefined,
      rangeText: "By how many days did the lead time exceed your desired delivery cycle?*",
      rangeValue: undefined,
      rangeOptions: [
        {
          label: "≤ 30 Days",
          value: 29,
        },
        {
          label: "31 - 90 Days",
          value: 90,
        },
        {
          label: "91 - 120 Days",
          value: 120,
        },
        {
          label: "≥ 121 Days",
          value: 121,
        },
      ],
      complete: false,
      ready: false,
    },
    {
      affirmText: "Did you find the price quoted to be reasonable?*",
      affirm: undefined,
      rangeText: "Please choose the option that best describes the price quoted.*",
      rangeValue: undefined,
      rangeOptions: [
        {
          label: "Price was too low",
          value: 0,
        },
        {
          label: "Price was too high, by ≤ 25%",
          value: 25,
        },
        {
          label: "Price was too high, by 26% - 50%",
          value: 50,
        },
        {
          label: "Price was too high, by 51% - 75%",
          value: 75,
        },
        {
          label: "Price was too high, by ≥ 76% ",
          value: 100,
        },
      ],
      complete: false,
      ready: false,
    },
  ];
  // const {className, style, textClassName, text, preImg, img, disabled} = props;

  const [firstQuestion, setFirstQuestion] = useState<Question>(initialValues[0]);
  const [secondQuestion, setSecondQuestion] = useState<Question>(initialValues[1]);
  const [thirdQuestion, setThirdQuestion] = useState<Question>(initialValues[2]);
  const [surveyQuestions, setSurveyQuestions] = useState<Question[]>([firstQuestion, secondQuestion, thirdQuestion]);
  const [sectionReady, setSectionReady] = useState<boolean>(false);
  const [readyToSubmit, setReadyToSubmit] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [otherValues, setOtherValues] = useState<{ name?: string; id: number; type: string; quoteRequestId: number; date?: string; time?: string }>({
    id: 0,
    type: "Parts Quote Request",
    quoteRequestId: 0,
  });
  const [question, setQuestion] = useState<number>(0);
  const navigate = useNavigate();
  const setErrorData = useOnError();
  const { setIsBlocked } = useHandleBlocker();
  const onSuccess = (data: QuoteRequestSurvey) => {
    const { dateTimeSubmitted, quoteRequestSurveyId, fName, lName, pastDueDays, expectedDays, expectedPrice, deliveredWithinDate, meetExpectations, priceReasonable, quoteRequestId } = data;
    setFirstQuestion({
      ...firstQuestion,
      affirm: deliveredWithinDate,
      rangeValue: pastDueDays ? firstQuestion.rangeOptions.filter((options) => options.value === pastDueDays)[0].value : undefined,
    });
    setSecondQuestion({
      ...secondQuestion,
      affirm: meetExpectations,
      rangeValue: expectedDays ? secondQuestion.rangeOptions.filter((options) => options.value === expectedDays)[0].value : undefined,
    });
    setThirdQuestion({
      ...thirdQuestion,
      affirm: priceReasonable,
      rangeValue: expectedPrice ? thirdQuestion.rangeOptions.filter((options) => options.value === expectedPrice)[0].value : undefined,
    });

    setOtherValues({
      ...otherValues,
      name: `${fName ? fName : "Default"} ${lName ? lName : "Name"}`,
      id: quoteRequestSurveyId || 0,
      quoteRequestId: quoteRequestId || 0,
      date: dateTimeSubmitted && dayjs(dateTimeSubmitted).format('MM/DD/YYYY'),
      time: dateTimeSubmitted && dayjs(dateTimeSubmitted).format('h:mm:ss A'),
    });
  };

  const onError = (error: AxiosError<string>) => setErrorData(error);
  useGet_CustomerSurvey(+id, onSuccess, onError);

  const handleAffirmation = (val: string, question: number) => {
    const valAsBool = !!+val;
    setSectionReady(valAsBool);
    const mutator = valAsBool ? 33.33 : 16.66;
    surveyQuestions[question]?.affirm === undefined ? setCompletion(completion + mutator) : setCompletion(completion);
    return question === 0
      ? setFirstQuestion({ ...firstQuestion, affirm: valAsBool, complete: valAsBool })
      : question === 1
      ? setSecondQuestion({ ...secondQuestion, affirm: valAsBool, complete: valAsBool })
      : question === 2
      ? setThirdQuestion({ ...thirdQuestion, affirm: valAsBool, complete: valAsBool })
      : undefined;
  };

  const handleRangeSelection = (val: string, question: number) => {
    setTimeout(() => setIsBlocked(true), 0);
    surveyQuestions[question]?.rangeValue === undefined && setCompletion(completion + 16.66);
    switch (question) {
      case 0:
        setFirstQuestion({ ...firstQuestion, rangeValue: +val, complete: true });
        return setCompletion(completion < 33 ? completion + 16.66 : completion);
      case 1:
        setSecondQuestion({ ...secondQuestion, rangeValue: +val, complete: true });
        return setCompletion(completion < 66 ? completion + 16.66 : completion);
      case 2:
        setThirdQuestion({ ...thirdQuestion, rangeValue: +val, complete: true });
        return setCompletion(completion < 99 ? completion + 16.66 : completion);
      default:
        return;
    }
  };

  const handleNextSection = () => {
    if (firstQuestion?.complete && firstQuestion?.ready) {
      if (!secondQuestion?.ready) {
        setFirstQuestion({ ...firstQuestion, ready: false });
        setSecondQuestion({ ...secondQuestion, ready: true });
        setQuestion(1);
        setSectionReady(secondQuestion.complete);
      }
    } else if (secondQuestion?.complete && secondQuestion?.ready) {
      if (!thirdQuestion?.ready) {
        setSecondQuestion({ ...secondQuestion, ready: false });
        setThirdQuestion({ ...thirdQuestion, ready: true });
        setQuestion(2);
        setSectionReady(thirdQuestion.complete);
      }
    }
  };

  const handlePrevSection = () => {
    setQuestion(question - 1);
    switch (question) {
      case 2:
        setSecondQuestion({ ...secondQuestion, ready: true });
        setThirdQuestion({ ...thirdQuestion, ready: false });
        setSectionReady(true);
        break;
      case 1:
        setFirstQuestion({ ...firstQuestion, ready: true });
        setSecondQuestion({ ...secondQuestion, ready: false });
        setSectionReady(true);
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    setSurveyQuestions([firstQuestion, secondQuestion, thirdQuestion]);
  }, [firstQuestion, secondQuestion, thirdQuestion]);

  useEffect(() => {
    if (firstQuestion?.complete && secondQuestion?.complete && thirdQuestion?.complete) setReadyToSubmit(true);
    else if (firstQuestion?.complete || secondQuestion?.complete || thirdQuestion?.complete) setSectionReady(true);
  }, [firstQuestion?.complete, secondQuestion?.complete, thirdQuestion?.complete]);

  return (
    <>
      {!submitted && <div className="flex mb-8 items-center font-bold text-xl md:text-5xl">Voice of Customer {viewOnly? 'Responses' : 'Survey'}</div>}
      {!viewOnly && !submitted && (
        <div>
          <div className="card flex flex-col item-start px-6 pt-6 pb-10 gap-6">
            <div>
              <div className={`flex flex-row justify-between gap-6 align-center`}>
                <div className={`flex flex-col gap-6`}>
                  <div className={"font-bold text-2xl"}>For your Recent Parts Quote Request</div>
                  <div className={"gap-6 text-[font-size:var(--fs14px)]"}>We value your feedback. Please tell us about your most recent experience.</div>
                </div>
                <div className={`flex flex-col gap-6`}>
                  <button type="button" onClick={() => navigate('/parts&service/QuoteRequests')} className={`primaryBtn whiteBtn`}>
                    Cancel
                  </button>
                </div>
              </div>
              <hr className="my-6" />
              <div className="mt-4 " aria-hidden="true">
                <div className="overflow-hidden rounded-full bg-gray-200">
                  <div className="h-2 rounded-full bg-[#3c5D69]" style={{ width: `${completion}%` }} />
                </div>
              </div>
              <Formik
                initialValues={initialValues}
                onSubmit={async (values) => {
                  const formData = new FormData();
                  id && formData.append("quoteRequestId", `${id}`);
                  formData.append("deliveredWithinData", firstQuestion!.affirm!.toString());
                  firstQuestion.rangeValue && formData.append("pastDueDays", firstQuestion.rangeValue!.toString());
                  formData.append("meetExpectations", secondQuestion!.affirm!.toString());
                  secondQuestion.rangeValue && formData.append("expectedDays", secondQuestion!.rangeValue!.toString());
                  formData.append("priceReasonable", thirdQuestion!.affirm!.toString());
                  thirdQuestion.rangeValue && formData.append("expectedPrice", thirdQuestion!.rangeValue!.toString());
                  formData.append("dataTimeSubmitted", Date.now().toString());
                  CustomerSurveyApi.create(formData);
                  setIsBlocked(false);
                  setSubmitted(!submitted);
                }}
              >
               {
                (formikProps) => {
            
                
                  return (
                    <Form style={{ marginTop: "2%" }}>
                    {surveyQuestions.map(
                      (item, index) =>
                        item?.ready && (
                          <React.Fragment key={`${item?.affirmText}-${item?.ready}`}>
                            <span>{index + 1}.</span>
                            <label className="ml-6">{item.affirmText}</label>
                            <div style={{ marginBottom: "2%", marginTop: "1%", marginLeft: "2%" }}>
                              <RadioButtonGroup
                                onChange={(val) => handleAffirmation(val, index)}
                                name={`${index}-question-radio-group`}
                                options={[
                                  { label: "Yes", value: 1 },
                                  { label: "No", value: 0 },
                                ]}
                                value={item?.affirm ? 1 : item?.affirm === undefined ? undefined : 0}
                              />
                            </div>
                            {item?.affirm === false && <label style={{ marginLeft: "2%" }}>{item.rangeText}</label>}
                            {item?.affirm === false && (
                              <div style={{ marginBottom: "2%", marginTop: "1%", marginLeft: "2%" }}>
                                <RadioButtonGroup
                                  onChange={(val) => handleRangeSelection(val, index)}
                                  name=""
                                  options={item?.rangeOptions}
                                  value={item?.rangeValue !== undefined ? item.rangeValue : undefined || undefined}
                                  capitalize={false}
                                />
                              </div>
                            )}
                          </React.Fragment>
                        )
                    )}
                    <div className="h-2">
                      <hr className="my-4" />
                    </div>
                    <div style={{ justifyContent: "space-between" }} className="flex px-4 py-3 sm:px-6">
                      <button type="button" onClick={handlePrevSection} className={`primaryBtn whiteBtn ${question > 0 && !submitted ? "visible" : "invisible"}`}>
                        Previous
                      </button>
                      {sectionReady && question !== 2 && (
                        <button type="button" onClick={handleNextSection} className="primaryBtn darkBlue">
                          Next
                        </button>
                      )}
                      {readyToSubmit && question === 2 && (
                        <button type="submit" className="primaryBtn darkBlue">
                          Submit
                        </button>
                      )}
                    </div>
                  </Form>
                  )
                }
               }
     
              </Formik>
            </div>

            <div></div>
          </div>
        </div>
      )}
      {!viewOnly && submitted && (
        <div>
          <p style={{ fontSize: "40px", fontWeight: 600, margin: "1% 0 0" }}>Thank You!</p>
          <p style={{ marginBottom: "2%" }}>Your voice of customer response for this quote request was successfully submitted.</p>
          <Button className={"darkBlue"} text={"Return to Quote Requests"} onClick={() => navigate(generatePath("/parts&service/:moduleName", { moduleName: "QuoteRequests" }))} />
        </div>
      )}
      {viewOnly && (
        <>
          <div className="card flex flex-col item-start px-6 pt-6 pb-10 gap-6 ">
            <Link to={generatePath("/parts&service/customer-survey")} className="link_text">
              <div className="flex flex-row gap-[1px] items-center">
                <img src={Arrow} className="back-arrow" alt={`Back to Voice of Customer Responses`} />
                Back to Voice of Customer Responses
              </div>
            </Link>{" "}
            <div className="flex items-center font-bold text-2xl">
              Survey #{otherValues?.id}, {otherValues.type} #{otherValues.quoteRequestId} ({selectedPlant.name})
            </div>
            <div className="text-base leading-4">{`Completed by ${otherValues.name}, on ${otherValues.date}, at ${otherValues.time}`}</div>
            <hr style={{ margin: "8px" }} />
            <div className="flex flex-col gap-8">
              {surveyQuestions.map((item, index) => (
                <div key={`view-only-${item?.affirmText}-${item?.ready}`} className="flex flex-col gap-4">
                  <label>
                    {index + 1}. {item.affirmText}
                  </label>
                  <div className="ml-[2%]">
                    <RadioButtonGroup
                      onChange={(val) => handleAffirmation(val, index)}
                      disabled={viewOnly}
                      name={`${index}-question-radio-group`}
                      options={[
                        { label: "Yes", value: 1 },
                        { label: "No", value: 0 },
                      ]}
                      value={item?.affirm ? 1 : item?.affirm === undefined ? undefined : 0}
                    />
                  </div>
                  {item?.rangeValue && (
                    <div className="flex flex-col gap-4">
                      <label className="ml-[2%]">{item.rangeText}</label>
                      <div className="ml-[2%]">
                        <RadioButtonGroup
                          onChange={(val) => handleRangeSelection(val, index)}
                          disabled={viewOnly}
                          name=""
                          options={item?.rangeOptions}
                          value={item?.rangeValue !== undefined ? item.rangeValue : undefined || undefined}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className={`flex mt-14 flex-col`}>
            <h2 className={`text-xl mb-8 text-black capitalize md:text-xl lg:text-2xl`}>Related Technical Documentation</h2>
            <div className={`flex flex-col lg:flex-row gap-6`}>
              <div className={" w-full mb-8 lg:mb-0 lg:w-1/3 lg:pr-4 flex flex-auto flex-col align-baseline"}>
                <h4 className="text-[#3C5D69] text-lg md:text-base lg:text-xl">Technical Documents</h4>
                <p className="text-sm">Click below for technical documents related to your Plant and Equipment and a link to the Technical Bulletin Web..</p>
                <div className="flex flex-auto flex-col align-baseline justify-end">
                  <Button
                    text={"View Technical Documents"}
                    className={"primaryBtn whiteBtn mb-0 w-full md:w-[300px] lg:w-auto"}
                    onClick={() => navigate(generatePath('/technical-documentation/:moduleName', { moduleName: 'Technical Bulletins' }))}
                  />
                </div>
              </div>
              <div className={"w-full mb-8 lg:mb-0 lg:w-1/3 lg:pr-4 flex flex-auto flex-col align-baseline"}>
                <h4 className="text-[#3C5D69] text-lg md:text-base lg:text-xl">O&M Manuals</h4>       
                <p className="text-sm">Click below for Operation and Maintenance Manuals related to your Plant, Unit and Equipment.</p>
                <div className="flex flex-auto flex-col align-baseline justify-end">
                  <Button
                    text={"View O&M Manuals"}
                    className={"primaryBtn whiteBtn mb-0 w-full md:w-[300px] lg:w-auto"}
                    onClick={() => navigate(generatePath('/technical-documentation/:moduleName', { moduleName: 'O&M Manuals' }))}
                  />
                </div>
              </div>
              <div className={"w-full mb-8 lg:mb-0 lg:w-1/3 lg:pr-4 flex flex-auto flex-col align-baseline"}>
                <h4 className="text-[#3C5D69] text-lg md:text-base lg:text-xl">Parts Catalog</h4>
                <p className="text-sm">Click below for Parts Catalogs related to your Plant, Unit and Equipment.</p>
                <div className="flex flex-auto flex-col align-baseline justify-end">
                  <Button
                    text={"View Parts Catalog"}
                    className={"primaryBtn whiteBtn mb-0 w-full md:w-[300px] lg:w-auto"}
                    onClick={() => navigate(generatePath('/parts&service/:moduleName', { moduleName: 'Parts Catalog' }))}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SurveyWizard;
