import React, { ReactElement, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { Outlet, useNavigate, useOutletContext, useParams } from "react-router-dom";
import Dropdown from "../../components/Dropdown/Dropdown";
import Link from "../../components/Link/Link";
import Notification from "../../components/Notification/Notification";
import useWindowSize from "../../customHooks/useWindowSize";
import { DocumentGroup } from "../../utils/interface";
import { getSortObjectsByKeyFn } from "../../utils/Sortutils";
import Reports from "../Reports/Reports";
import classes from "./TechnicalDocumentation.module.css";
import { TechnicalDocumentationModuleOutletInterface } from "./TechnicalDocumentationModuleContainer";

function TechnicalDocumentationGroupContainer() {
  const outletContext = useOutletContext<TechnicalDocumentationModuleOutletInterface>();
  const { documentModule, refetch, documentGroups, notifications, documentAdded, setDocumentAdded } = outletContext;
  const params = useParams();
  const groupId = Number.parseInt(params.groupId || '');
  const navigate = useNavigate();
  const { isMobile, isTablet } = useWindowSize();
  const [selectedReportType, setSelectedReportType] = useState<string | null>(null);
  const [notificationCounts, setNotificationCounts] = useState<{ id: number, count: number }[]>([]);

  const handleGroupType = (e: React.MouseEvent<HTMLDivElement>) => {
    const groupId = e.currentTarget.getAttribute("data-id");
    if (!groupId) return;
    const filteredGroupId = documentGroups.filter((e) => e.id === parseInt(groupId));
    //console.log("🚀 ~ file: TechnicalDocumentationGroupContainer.tsx ~ line 24 ~ handleGroupType ~ filteredGroupId", filteredGroupId)
    navigate(`../${filteredGroupId[0].id}`);
  };

  const sortFn = getSortObjectsByKeyFn<DocumentGroup>("displayOrder", 'asc');

  useEffect(() => {
      const tempArr: React.SetStateAction<{ id: number; count: number; }[]> = [];
      documentGroups?.sort(sortFn).map(group => {
        const count = notifications?.filter(item => item.itemTypeId === group.id && !item.read).length;
        return tempArr.push({id: group.id, count})
      })
      setNotificationCounts(tempArr);
  }, [documentGroups, notifications]);

  const queryClient = useQueryClient();

  useEffect(() => {
    // Using this to check for notification updates after documents are added or updated.
    let interval: ReturnType<typeof setInterval>;
    if (documentAdded) {
      // Check if the added document exists in the notifactions
      const foundNotification = notifications?.find(item => item.itemTypeId === documentAdded.itemTypeId && item.itemId === documentAdded.globalItemId && !item.read);

      // refetch the header on an interval every 4 seconds
      interval = setInterval(() => {
        if (!foundNotification) {
          queryClient.invalidateQueries(["header"]); 
        }
      }, 4000);

      // This is for redundency so the interval does not continue - after 16 seconds force stop
      setTimeout(() => { setDocumentAdded(undefined); clearInterval(interval);}, 16000);  

      // If the notification is found clear the interval an remove the added notification from the state.
      if (foundNotification) {
        return () => {
          clearInterval(interval);
          setDocumentAdded(undefined);
        }        
      }
    
      return () => {clearInterval(interval)};
    }
    
  }, [documentAdded, notifications]);

  useEffect(() => {
    setSelectedReportType(documentGroups.find(d => d.id === groupId)?.name || null);
  })

  useEffect(() => {
    if ((documentGroups.length > 0 && documentGroups.findIndex((g) => g.id === groupId) === -1)) {
      navigate(`../${documentGroups[0].id}`, {
        replace: true
      });
    }
  }, [groupId, documentGroups]);

  useEffect(() => {
    refetch();
  }, [])

  let documentGroupSelection: ReactElement = <></>;
  if (documentGroups) {
    if (isMobile || isTablet) {
      documentGroupSelection = (
        <div className={classes.flex_column}>
          Report Type
          <div className="borderDropdown">
            <Dropdown
              style={{ width: '100%' }}
              handleDropDownSelect={handleGroupType}
              selectedOption={selectedReportType}
              setSelectedOption={setSelectedReportType}
              options={documentGroups}
            />
          </div>
        </div>
      );
    } else {
      documentGroupSelection = (
        <div className={`${classes.flex_column} ${classes.module_list}`}>
          {documentGroups.map((documentGroupItem) => {
            const count = notificationCounts?.find(el => el.id === documentGroupItem.id)?.count;
            return (
              <div className="flex justify-between items-center w-full" key={documentGroupItem.id}>
                <Link
                  className={`${classes.document_group_link} w-4/5 text-ellipsis overflow-hidden py-3 ${groupId === documentGroupItem.id ? classes.active : ""}`}
                  to={`../${documentGroupItem.id}`}
                >
                  {documentGroupItem.name}
                </Link>
                <div>
                  <Notification shade={!!(groupId !== documentGroupItem.id)} tableVariant disabled value={count || 0}>{' '}</Notification>
                </div>
              </div>
            )
          })}
        </div>
      );
    }
  }

  return (
	<div className={`${isMobile || isTablet ? classes.flex_column : classes.flex_row} ${classes.module_information}`}>
		{documentGroupSelection}
		<Reports documentModule={documentModule} documentGroups={documentGroups} setReportPermissions={outletContext.setReportPermissions} />
		<Outlet context={outletContext} />
	</div>
  );
}

export default TechnicalDocumentationGroupContainer;
