import React from "react";
// import Button from '../Button/Button';
import { generatePath, Link } from 'react-router-dom';
import linkedin from "../../assets/linkedin-logo.svg";
import useWindowSize from "../../customHooks/useWindowSize";

import { userGroupPortalURL } from "../../endpoints/endpoints";
import { PrivacyPolicy, TermsOfUse } from "../../utils/globalVariables";
import "./Footer.css";

function Footer() {
  const { isMobile, isDesktop } = useWindowSize();
  return (
    <div className="card footer mt-16 lg:mt-20">
      <div className="column row-item">
        <div className={`row column-item ${isMobile? '!mb-0' : ''}`}>
          <div className={`row-to-col ${isDesktop ? "flexbase17" : "flexbase33"}`}>
          <div className="col-item text-fade">
            <strong className="marBot5">Customer Portal</strong>
            </div>
            <div className="col-item">
              <span>
                <Link to="/" className="text-white no-underline marBot5" >Home</Link>
              </span>
            </div>
            <div className="col-item">
              <span>
                <Link to="/plant-information" className="text-white no-underline marBot5" >Plant Information</Link>
              </span>
            </div>
            <div className="col-item">
              <span>
                <Link to="/issue-reports" className="text-white no-underline marBot5" >Issue Reports</Link>
              </span>
            </div>
          </div>
          <div
            className={`row-to-col ${isDesktop ? "flexbase25" : "flexbase33"}`}
          >
            <div className="col-item text-fade">
              <strong className="marBot5">Support</strong>
            </div>
            <div className="col-item">
              <span>
                <Link to={generatePath('/support/esr/:supportModuleName', {supportModuleName:"Engineering Support Requests"})} className="text-white no-underline marBot5" >Engineering Support Requests</Link>
              </span>
              {/* <span className="marBot5">Engineering Support Requests</span> */}
            </div>
            <div className="col-item">
              <span>
                <Link to={generatePath('/support/faq/:supportModuleName', {supportModuleName:"Frequently Asked Questions"})} className="text-white no-underline marBot5" >Frequently Asked Questions</Link>
              </span>
            </div>
          </div>
          <div className="row-item row-to-col">
            <div className="col-item text-fade">
              <strong className="marBot5">External Links</strong>
            </div>
            <div className="col-item">
              <span className="marBot5">
                <a href={userGroupPortalURL} className="no-underline marBot5 text-white" target="_blank" rel="noreferrer">Users' Group Portal</a>
              </span>
            </div>
            <div className="col-item">
              <span className="marBot5">
                <a href="https://www.gtnews.mhi.co.jp/en/" className="no-underline marBot5 text-white" target={"_blank"} rel="noreferrer">Takasago Technical Bulletin Website (TBW)</a>
              </span>
            </div>
          </div>
          <div className="row-to-col">
            <div className="col-item !mb-0">
              <span className="">              
                <a href="https://www.linkedin.com/company/mitsubishi-power" className="no-underline marBot5" target={"_blank"} rel="noreferrer"><img src={linkedin} alt="linkedin" /></a>
              </span>
            </div>
          </div>
        </div>
        {isMobile ? (
          <>
          <div className="row-item row-to-col">
            <div className="col-item">
              <a href="https://www.mhi.com/privacy.html" className="text-white no-underline marBot5" target={"_blank"} rel="noreferrer">Privacy Policy</a>
            </div>
            <div className="col-item">
              <a href="https://www.mhi.com/conditions.html" className="text-white no-underline marBot5" target={"_blank"} rel="noreferrer">Terms of Use</a>
            </div>
            
          </div>
          <div className="row-item row-to-col">
            
            <div className="row-item text-fade">
              © Mitsubishi Power All Rights Reserved
            </div>
          </div>
          </>
        ) : (
          <div className="row">
            <div className="marRight5">
              <span className="text-fade">© Mitsubishi Power All Rights Reserved</span>
            </div>
            <div>
              <a href={PrivacyPolicy} className="text-white no-underline marBot5 marRight10" target={"_blank"} rel="noreferrer">Privacy Policy</a>
              <a href={TermsOfUse} className="text-white no-underline marBot5" target={"_blank"} rel="noreferrer">Terms of Use</a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Footer;
