import { ReactElement, useContext, useMemo, useState } from 'react';
import useWindowSize from '../../customHooks/useWindowSize';

import classes from './ConfirmUserStatusChangeModal.module.css';
import EmptyModal from '../../components/Modals/EmptyModal';
import Button from '../../components/Button/Button';
import ImageImports from '../../utils/ImageImports';
import { UserRoleAssignments } from '../../utils/interface';

const { Arrow, help } = ImageImports;


const ucFirst = (str: string = '') => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

interface ConfirmUserStatusChangeModalProps {
    shown: boolean;
    actionType: 'reactivate'|'deactivate';
    user: UserRoleAssignments;
    onClose: () => void;
    onConfirmDeactivate: () => void;
    onConfirmReactivate: () => void;
}

export const ConfirmUserStatusChangeModal = (props: ConfirmUserStatusChangeModalProps) => {
    const {isMobile} = useWindowSize();
    const isDeactivate = props.actionType === 'deactivate';
    
    let width: number = 564;
    if (isMobile) {
        width = 300;
    }
    let modalBody: ReactElement|undefined;
    let modalFooter: ReactElement|undefined;
    if (props.shown) {
        modalBody = (
            <>
                <div className="grid gap-4 grid-cols-1">
                    <span className="text-base">Are you sure you want to {props.actionType} the following user?</span>
                </div>
                <div className="grid gap-1.5 grid-cols-1">
                    <div className="text-sm text-[#666666]">{props.user?.userFName} {props.user?.userLName}</div>
                    {props.user.roles ? 
                        props.user.roles.map((role, index) => {
                            return (
                                <div key={index + 1} className="text-sm text-[#666666]">{role.roleName} for {(role.baseOrgName || role.orgGroupName)}</div>
                            );
                            
                        })
                        :
                        <div className="text-sm text-[#666666]">This user has no assigned roles.</div>
                    }
                </div>                
            </>
        );

        modalFooter = (
            <>
                <div className="flex flex-row items-start gap-4"> {/* Actions */}
                    {isDeactivate 
                        ? <Button className="redDelete text-xs md:text-base h-9 md:h-11 px-3 md:px-10 md:py-2.5" text={`Yes, ${ucFirst(props.actionType)}`} onClick={props.onConfirmDeactivate} />
                        : <Button className="darkBlue text-xs md:text-base h-9 md:h-11 px-3 md:px-10 md:py-2.5" text={`Yes, ${ucFirst(props.actionType)}`} onClick={props.onConfirmReactivate} />
                    }
                    <Button className="whiteBtn text-xs md:text-base h-9 md:h-11 px-3 md:px-10 md:py-2.5" text="Cancel" onClick={props.onClose} />
                </div> 
            </>
        );
    }

    return (
        <EmptyModal shown={props.shown} body={modalBody} footer={modalFooter} heading={`${ucFirst(props.actionType)} User?`} onClose={props.onClose} width={width} footerClass={classes.modal_footer} />
    );
};