import { ErrorMessage, Field, useField } from "formik";
import React from "react";
import { useContext } from "react";
import { Store } from "../../../../Store";
import ImageImports from "../../../../utils/ImageImports";

import classes from "./TextAreaField.module.css";

const TextAreaField = (props: TextAreaField) => {
  const [field, meta] = useField(props);
  const stateContext = useContext(Store);
  const { tooltip } = ImageImports;
  return (
    <>
      <label htmlFor={props.name} className="flex marginBot8">
        {props.labelName}
      </label>
      <div className={`${classes.form_field_row}`}>
        <div className={`${classes.form_group}`}>
          <Field
            id={props.name}
            as="textarea"
            className={meta.touched && meta.error ? `${classes.input} ${classes.errorField} ${classes.error_text}` : `${classes.input}`}
            placeholder={props.placeholder}
            name={props.name}
            disabled={props.disabled}
            autoComplete="off"
            style={{ height: props.height }}
          ></Field>
          {/* <ErrorMessage name={props.name}>
            {(msg) => <div className={classes.error_text}>{msg}</div>}
          </ErrorMessage> */}
        </div>
      </div>
    </>
  );
};

interface TextAreaField {
  name: string;
  labelName: string;
  placeholder?: string;
  disabled?:boolean;
  showTooltip: boolean;
  className: string | "";
  height?: number;
}

TextAreaField.defaultProps = {
  showTooltip: false,
  type: "text",
};
export default TextAreaField;
