import React, { useContext } from "react";
import { Store } from "../../../Store";
import { useOnError } from "../../../customHooks/useOnError";
import useWindowSize from "../../../customHooks/useWindowSize";
import classes from "./ESR.module.css";
import ESRLinks from "./ESRLinks";
import KPITabs from "./KPITabs";

const regex = /(^'?)(.*)('$)/gm;
const subst = `$2`;
const powerAppsUrl = (process.env.REACT_APP_POWERAPPS_URL as string)?.replace(regex, subst);

const ESRContainer = () => {
	const { selectedPlant } = useContext(Store);
	const { isDesktop } = useWindowSize();
	const setErrorData = useOnError();
	return (
		<div>
			<div>
			Use this page to request technical information or to send technical questions to Mitsubishi Power.
			</div>
			<div className={`${classes.header} ${isDesktop ? classes.desktop : ""}`}>
				<KPITabs setErrorData={setErrorData} />
				<ESRLinks setErrorData={setErrorData} />
			</div>
			<div className={`${classes.powerAppsContainer}`}>
				{/* 
				Sandbox permissions:
				- allow-forms: allows form submission.
				- allow-popups: allows (shock!) popups.
				- allow-pointer-lock: allows (surprise!) pointer lock.
				- allow-same-origin: allows the document to maintain its origin; pages loaded from https://example.com/ will retain access to that origin's data.
				- allow-scripts: allows JavaScript execution, and also allows features to trigger automatically (as they'd be trivial to implement via JavaScript).
				- allow-top-navigation: allows the document to break out of the frame by navigating the top-level window. 
				*/}
				{powerAppsUrl && <iframe sandbox="allow-same-origin allow-scripts allow-popups allow-forms" src={`${powerAppsUrl}&vPlantId=${selectedPlant.plantId}`} title="PowerApps" frameBorder="0"></iframe>}
			</div>
		</div>
	);
};

export default ESRContainer;
