import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Store } from "../../../Store";
import Button from "../../../components/Button/Button";
import ErrorCard from "../../../components/ErrorCard/ErrorCard";
import PlainDragDropField from "../../../components/UI/Form/DragDropField/PlainDragDropField";
import MultiSelectDropDown from "../../../components/UI/Form/DropDownSelect/MultiSelectDropDown";
import { DropDownOptionItem, PlainDropDown } from "../../../components/UI/Form/DropDownSelect/PlainDropDown";
import ImageImports from "../../../utils/ImageImports";
import { DocumentGroup, OMManualSectionDATA, OMManualSectionGetAllDTO, PlantEquipmentsDATA, SiteUnit } from "../../../utils/interface";
import { ModuleIDs } from "../../../utils/moduleIDs";
import { useGetAll_OMManualSectionData } from "../../CPItemConfiguration/OMManualSectionHttpServices";
import { useGetFile_TBReport, usePlantSiteUnitEquipment, useUnitsOfEquipmentIds } from "../../Reports/ReportsHttpServices";
import { AddDocumentRequiredProps } from "../TechnicalDocumentationContainer";
import classes from './PowerReport.module.css';
import DragDropFieldInfo from "../../../components/UI/Form/DragDropField/DragDropFieldInfo";

const { trash } = ImageImports;

const AddEditDocument: FunctionComponent<AddDocumentRequiredProps> = ({ documentModule, documentGroups, onSave, siteUnits, isEdit }) => {
    const [reportName, setReportName] = useState<string>('');
    const [documentGroup, setDocumentGroup] = useState<DocumentGroup>();
    const [documentSection, setDocumentSection] = useState<OMManualSectionDATA>();
    const [siteUnit, setSiteUnit] = useState<SiteUnit>();
    const [attachment, setAttachment] = useState<File>();
    const [equipment, setEquipment] = useState<number[]>([]);
    const [productTypes, setProductTypes] = useState<{ id: number, name: string }[]>([]);
    const { plantConfig, report, showError } = useContext(Store);
    const [ommanualSections, setOmmanualSections] = useState<OMManualSectionDATA[]>([]);
    const [selectionText, setSelectionText] = useState<string>('');
    const [editedFlag, setEditedFlag] = useState<boolean>(false);
    const [attachmentEdited, setAttachmentEdited] = useState<boolean>(false);
    const navigate = useNavigate();

    const params = useParams();
    const groupId = params.groupId && Number.parseInt(params.groupId);

    useEffect(() => {
        if (groupId && +groupId !== documentGroup?.id) setDocumentGroup(documentGroups?.filter(g => g.id === +groupId)[0])
    }, [documentGroups, groupId])

    useGetAll_OMManualSectionData(
        (data: OMManualSectionGetAllDTO) => {
            if (documentModule?.id === ModuleIDs.OMManuals) {
                setOmmanualSections(data.data);
            }
        },
        () => { }
    );

    useEffect(() => {
        if (isEdit && report.globalId === 0) {
            navigate(`/technical-documentation/${documentModule?.name || ''}/${documentGroup?.id || ''}`);
        }
    }, []);

    useEffect(() => {
        setReportName(attachment?.name || '');
    }, [attachment]);

    useEffect(() => {
        if (report && isEdit && (documentModule?.id !== ModuleIDs.OMManuals || ommanualSections.length)) {
            const selectedEquipment = (report.associatedEquipment || []);
            if (selectedEquipment.length > 0) {
                const selectedEquipmentIds = selectedEquipment.map(d => d.equipmentId);
                handleEquipmentSelection(selectedEquipmentIds, selectedEquipmentIds[0]);
            }
            setDocumentGroup(documentGroups.find(dg => dg.id === report.itemTypeId));
            setDocumentSection(ommanualSections.find(s => s.id === report.sectionId));
            setSiteUnit(siteUnits[0])
        }
    }, [report, ommanualSections]);

    useUnitsOfEquipmentIds(equipment, {
        onSuccess: (unitArray) => {
            setSiteUnit(siteUnits.find(d => d.unitId === unitArray[0]));
        },
        enabled: isEdit && !!report && equipment.length > 0
    });

    const { isFetching: fileLoading } = useGetFile_TBReport(report.globalId, {
        enabled: report.globalId > 0 && isEdit,
        onSuccess: (data: Blob) => {
            setAttachment(new File([data], report.originalFileName || report.displayFileName, { type: data.type }));
        },
        onError: (error: any) => { },
    });

    const handleDocumentGroupSelection = (selectedOption: DropDownOptionItem) => {
        setDocumentGroup(documentGroups.find(dg => dg.id === selectedOption.id));
        setEditedFlag(true);
    };

    const handleDocumentSectionSelection = (selectedOption: DropDownOptionItem) => {
        setDocumentSection(ommanualSections.find(s => s.id === selectedOption.id));
        setEditedFlag(true);
    };

    const handleUnitSelection = (selectedOption: DropDownOptionItem) => {
        siteUnits && setSiteUnit(siteUnits.find(su => su.unitId === selectedOption.id));
        setEditedFlag(true);
    };

    const saveReport = () => {
        if (attachment && documentGroup) {
            const formData = new FormData();
            formData.append('BaseOrgId', `${plantConfig.data.baseOrgId}`);
            if (!isEdit || attachmentEdited) {
                formData.append('File', attachment);
            }
            formData.append('ItemTypeId', `${documentGroup.id}`);
            formData.append('DisplayName', reportName);
            if (documentSection) {
                formData.append('SectionId', `${documentSection?.id}`);
            }
            const selectedEquipment = equipment.filter(d => d > 0);
            for (let i = 0; i < selectedEquipment.length; i++) {
                formData.append(`AssociatedEquipment[${i}].equipmentId`, `${selectedEquipment[i]}`);
            }
            if (isEdit && report) {
                onSave([report.globalId, formData]);
            } else {
                onSave(formData);
            }
        }
    };

    const getEquipmentLabel = (values: number[]) => {
        if (!!(values.length > 1)) {
            const eqCountByType = siteEquipment?.filter(eq => eq.productType === (values.filter(v => v < 0)[0] * -1)).length || 0;
            const valuesIncludeEqFromOutsideGroup = values.length !== eqCountByType + 1;
            if (valuesIncludeEqFromOutsideGroup)
                setSelectionText('Multiple equipment options selected');
            else if (!valuesIncludeEqFromOutsideGroup)
                setSelectionText(productTypes.filter(type => values.includes(type.id))[0].name);
        }
        // if values includes 'All Equipment'
        if (values.includes(0)) setSelectionText(equipmentOptions[0].name);
        // if only 1 option selected
        if (values.length === 1) setSelectionText(equipmentOptions.filter(el => el.id === values[0])[0]?.name)
    }

    const handleEquipmentSelection = (values: number[], toggled: number) => {
        let tmpValues = [...values];
        let tmpSiteEquipment = (siteEquipment || []);

        /**
         * Remove all grouped options from tmpValues
         * if toggled = 0 and orig values includes 0, select all
         * if toggled = 0 and orig values does not include 0, un select all
         * 
         */

        if (toggled === 0) {
            if (values.includes(0)) {
                tmpValues = tmpSiteEquipment.map((d) => d.plantEquipmentId);
            } else {
                tmpValues = [];
            }
        }

        if (toggled < 0) {
            let productTypeId = toggled * -1;
            // a group was selected, check everything in that group
            if (values.includes(toggled)) {
                for (let i = 0; i < tmpSiteEquipment.length; i++) {
                    if (tmpSiteEquipment[i].productType === productTypeId && !tmpValues.includes(tmpSiteEquipment[i].productType)) {
                        tmpValues.push(tmpSiteEquipment[i].plantEquipmentId);
                    }
                }
            } else {
                // a group was deselected, un check everything in that group
                let productEquipment = tmpSiteEquipment.filter(eq => eq.productType === productTypeId).map(eq => eq.plantEquipmentId);
                tmpValues = tmpValues.filter(v => !productEquipment.includes(v));
            }
        }

        for (let i = 0; i < productTypes.length; i++) {
            let allOptionsOfTypeSelected = true;
            let productTypeId2 = +productTypes[i].id * -1;
            for (let j = 0; j < tmpSiteEquipment.length; j++) {
                if (tmpSiteEquipment[j].productType === productTypeId2) {
                    allOptionsOfTypeSelected = allOptionsOfTypeSelected && tmpValues.includes(tmpSiteEquipment[j].plantEquipmentId);
                }
            }
            if (allOptionsOfTypeSelected) {
                tmpValues.push(productTypes[i].id);
            } else {
                tmpValues = tmpValues.filter(val => (val * -1) !== productTypeId2)
            }
        }

        // if everything is selected, check select all
        if (tmpValues.length > 0 && tmpValues.filter((d) => d > 0).length === tmpSiteEquipment.length) {
            tmpValues.push(0);
            for (let i = 0; i < productTypes.length; i++) {
                tmpValues.push(productTypes[i].id);
            }
        } else tmpValues = tmpValues.filter((val) => val !== 0);

        tmpValues = tmpValues.filter((v, i, a) => a.indexOf(v) === i);

        setEquipment(tmpValues);

        getEquipmentLabel(tmpValues);
    };

    const { data: siteEquipment } = usePlantSiteUnitEquipment((siteUnit?.unitId || 0), {
        enabled: !!siteUnit,
        initialData: []
    });

    // get all unique product type ids and names
    useEffect(() => {
        let tmpProductTypes: { [index: number]: string } = {};
        if (siteEquipment) {
            tmpProductTypes = siteEquipment?.reduce((carry: { [index: number]: string }, current: PlantEquipmentsDATA) => {
                carry[current.productType] = current.productTypeName;
                return carry;
            }, {});
        }
        const ids = Object.keys(tmpProductTypes);
        const productTypes: { id: number, name: string }[] = [];
        for (let i = 0; i < ids.length; i++) {
            let key = Number.parseInt(ids[i]);
            productTypes.push({ id: key * -1, name: `All ${tmpProductTypes[key]}` });
        }
        setProductTypes(productTypes);
    }, [siteEquipment]);

    const equipmentOptions: { id: number, name: string }[] = [
        { id: 0, name: 'All Equipment' },
        ...productTypes
    ];

    if (siteEquipment) {
        for (let i = 0; i < siteEquipment.length; i++) {
            equipmentOptions.push({
                id: siteEquipment[i].plantEquipmentId,
                name: siteEquipment[i].equipmentName
            })
        }
    }

    useEffect(() => {
        // add should start with default state
        if (!isEdit) {
            setSiteUnit(undefined)
            setEquipment([])
            setDocumentGroup(undefined)
            setSelectionText('')
            setOmmanualSections([])
        }
    }, [])

    return (

        <div className={`${classes.flex_column} ${classes.add_document_form}`}>
            {showError.isError && <ErrorCard ErrorMessage={showError.title} ErrorType={"danger"} />}
            <div className={`flex flex-row gap-x-6 !gap-y-2 ${classes.add_document_field}`}>
                <span className={`w-full md:w-1/4`}>Document Name*</span>
                <input type="text" value={reportName} disabled onChange={e => setReportName(e.target.value)}  />
            </div>
            <div className="flex justify-start md:justify-end">
                <p className="text-xs text-secondary-text w-full md:w-3/4 md:pl-6 -mt-6 -mb-4">{"Document name will auto-populate to match the attachment’s filename."}</p>
            </div>
            <div className={`flex flex-row gap-x-6 !gap-y-2 ${classes.add_document_field}`}>
                <span className={`w-full md:w-1/4`}>Document Group*</span>

                <PlainDropDown value={documentGroup?.id}
                    className={`${classes.select_container} ${!!documentGroup?.id ? classes.selected : classes.placeholder}`}
                    selectClass={`${classes.flex_row} ${classes.select}`}
                    optionsClass={`${classes.flex_column} ${classes.select_options}`}
                    options={[{ id: false, name: 'Select document group' }, ...documentGroups]}
                    onSelection={handleDocumentGroupSelection} />
            </div>
            {ommanualSections.length > 0 && (
                <div className={`flex flex-row gap-x-6 !gap-y-2 ${classes.add_document_field}`}>
                    <span className={`w-full md:w-1/4`}>Section</span>
                    <PlainDropDown value={documentSection?.id}
                        className={`${classes.select_container} ${!!documentSection?.id ? classes.selected : classes.placeholder}`}
                        selectClass={`${classes.flex_row} ${classes.select}`}
                        optionsClass={`${classes.flex_column} ${classes.select_options}`}
                        options={[{ id: false, name: 'Select section' }, ...ommanualSections]}
                        onSelection={handleDocumentSectionSelection} />
                </div>
            )}
            <div className={`flex flex-row gap-x-6 !gap-y-2 ${classes.add_document_field}`}>
                <span className={`w-full md:w-1/4`}>Associated Unit*</span>

                <PlainDropDown value={siteUnit?.unitId}
                    className={`${classes.select_container} ${!!siteUnit?.unitId ? classes.selected : classes.placeholder}`}
                    selectClass={`${classes.flex_row} ${classes.select}`}
                    optionsClass={`${classes.flex_column} ${classes.select_options}`}
                    options={[{ id: false, name: 'Select site unit' }, ...siteUnits.map(unit => ({ id: unit.unitId, name: unit.name }))]}
                    onSelection={handleUnitSelection} />
            </div>
            {siteEquipment && siteEquipment.length > 0 && (
                <div className={`flex flex-row gap-x-6 !gap-y-2 ${classes.add_document_field}`}>
                    <span className={`w-full md:w-1/4`}>Associated Equipment*</span>
                    <MultiSelectDropDown value={equipment}
                        className={`${classes.select_container} ${equipment.length > 0 ? classes.selected : classes.placeholder}`}
                        selectClass={`${classes.flex_row} ${classes.select}`}
                        optionsClass={`${classes.flex_column} ${classes.select_options}`}
                        options={equipmentOptions}
                        noSelectionText="Select equipment association(s)"
                        hasSelectionText={selectionText}
                        onSelection={(values: number[], toggled: number) => {
                            handleEquipmentSelection(values, toggled);
                            setEditedFlag(true);
                        }
                        } />
                </div>
            )}
            <div className={`flex flex-row gap-x-6 !gap-y-2 ${classes.add_document_field}`}>
                <span className={`w-full md:w-1/4`}>Attachment*</span>
                {attachment && (
                    <>
                        <input type="text" disabled className={classes.attachment_name} value={reportName} />
                        <img className={classes.delete_file}
                            onClick={() => setAttachment(undefined)}
                            src={trash}
                            alt="Remove Attachment" />
                    </>
                )}
            </div>
            <PlainDragDropField onChange={e => { setAttachment(e); setEditedFlag(true); setAttachmentEdited(true);}} fileLoading={fileLoading} attachment={attachment} />
            <DragDropFieldInfo fileTypes=".PDF, .DOC, .XLSX, .JPG, .PNG" containerClassName="-mt-5" />
            <div className="flex flex-row justify-between items-center">
                {isEdit ? (
                    <>
                        <Button className={`darkBlue`} disabled={!editedFlag || !(reportName.length > 0 && documentGroup && siteUnit && attachment && equipment.length > 0)} text="Save Document" onClick={saveReport} />
                    </>
                ) : (
                    <Button className={`darkBlue`} disabled={!(reportName.length > 0 && documentGroup && siteUnit && attachment && equipment.length > 0)} text="Add Document" onClick={saveReport} />
                )}
                <span>*Required fields</span>
            </div>
        </div>
    );
}

export default AddEditDocument;
