import React, { useContext } from "react";
import Button from "../Button/Button";
import documentSearch from "../../assets/document-search-grey.svg";
import rightArrow from "../../assets/arrow-right.svg";
import tooltip from "../../assets/Tool-Tip-icon.svg";
import { Store } from "../../Store";
import Notification from "../Notification/Notification";

import { TechnicalDocumentationDTO } from "../../utils/interface";
import { AxiosError } from "axios";
import { useGet_TechnicalDocumentation } from "../../containers/PlantInformation/DashboardHttpServices";
import useGetNotificationCount from "../../customHooks/useGetNotificationCount";
import { generatePath, Link, useNavigate } from "react-router-dom";
import MiniTooltip from "../UI/MiniTooltip/MiniTooltip";
import ImageImports from "../../utils/ImageImports";
import Loader from "../Loader/Loader";
import { useIsFetching } from "react-query";
import { queryKeys } from "../../react-query/queryKeys";
import HomeCardLoader from "../HomeCardLoader/HomeCardLoader";

const { help } = ImageImports;

function TechnicalDoc() {
  const {
    technicalDocumentation: {
      data: { totalCount, newMitsubishiReportsCount, newTechnicalBulletinsCount, oandMManualsCount },
    },
    setTechnicalDocumentation,
    toolTip,
    toggleTooltip,
    notificationTypes,
    setShowError,
  } = useContext(Store);
  const isFetching = useIsFetching([queryKeys.technicalDocumentation]);

  const onSuccess = (data: TechnicalDocumentationDTO) => {
    setTechnicalDocumentation && setTechnicalDocumentation(data);
  };
  const onError = (error: AxiosError<string>) => {
    setShowError &&
      setShowError({
        isError: true,
        title: error.response?.data ? error.response?.data : error.message,
        ErrorType: "danger",
      });
  };

  const { isLoading } = useGet_TechnicalDocumentation(onSuccess, onError);
  const navigate = useNavigate();

  const notificationCount = useGetNotificationCount(notificationTypes[4]);
  
  return (
    <>
      {isFetching > 0 ? (
        <HomeCardLoader showBottom={true} minHeight={"400px"}/>
      ) : (
        <div className="flex flex-col flex-nowrap h-full p-6">
          <div className="flex-1 row">
            <span className="title">
              <b>Technical Documentation</b>
            </span>
            <Notification innerSpacingZero={false} leftAlignment={26} value={notificationCount.unread} selectedType={notificationTypes[4]}>
              <div className="">
                <img src={documentSearch} className="report-icon" alt="" />
              </div>
            </Notification>
          </div>
          <div className="flex-1 row center h-6">
            <div className="notify techDoc">{isLoading ? <Loader containerStyle={{margin: 0}} iconStyle={{width: 18}}/> : newMitsubishiReportsCount}</div>
            <div className="flex flex-row row-item text-l items-center">
                New Mitsubishi Reports
                <span className="cursor-pointer">
                    <MiniTooltip text={"Click here for Plant, Unit and Equipment Reports."} space={15} placement={"top"}>
                        <img src={help} style={{ maxWidth: "16px", display: "block", marginLeft: "6px" }} alt="Help" />
                    </MiniTooltip>
                </span>
            </div>
            <div className="arrow-wrapper">
              <Link to={generatePath('/technical-documentation/:moduleName', { moduleName: 'Mitsubishi Power Reports' })}>
                <img src={rightArrow} className="arrow" alt="" />
              </Link>
            </div>
          </div>
          <div className="flex-1 row center h-6">
            <div className="notify techDoc">{isLoading ? <Loader containerStyle={{margin: 0}} iconStyle={{width: 18}}/> : newTechnicalBulletinsCount}</div>
            <div className="row-item text-l">New Technical Bulletins</div>
            <div className="arrow-wrapper">
              <Link to={generatePath('/technical-documentation/:moduleName', { moduleName: 'Technical Bulletins' })}>
                <img src={rightArrow} className="arrow" alt="" />
              </Link>
            </div>
          </div>
          <div className="flex-1 row center h-6">
            <div className="notify techDoc">{isLoading ? <Loader containerStyle={{margin: 0}} iconStyle={{width: 18}}/> : oandMManualsCount}</div>
            <div className="row-item text-l">O&amp;M Manuals</div>
            <div className="arrow-wrapper">
              <Link to={generatePath('/technical-documentation/:moduleName', { moduleName: 'O&M Manuals' })}>
                <img src={rightArrow} className="arrow" alt="" />
              </Link>
            </div>
          </div>
          <div className="flex h-full items-end mb-4">
            <span className="grow">
              <Button onClick={() => navigate(generatePath('/technical-documentation/:moduleName', { moduleName: 'Mitsubishi Power Reports' }))} className="darkBlue" text="View All Mitsubishi Power Reports" />
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export default TechnicalDoc;
