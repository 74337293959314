import { MouseEventHandler, useContext, useId, useState } from "react";
import BulkActionTooltip, { tooltipActionItem } from "../../components/PopoutTooltip/BulkActionTooltip";
import { Store } from "../../Store";
import ImageImports from "../../utils/ImageImports";
import { Report } from "../../utils/interface";
import { ColumnDefinition } from "./Reports";
import classes from "./Reports.module.css";
import { ViewDocPaneContent } from "./ViewDocPaneContent";

interface ReportRowTableProps {
  data: Report;
  columnDefinitions: ColumnDefinition[];
  tooltipActions: tooltipActionItem[];
  isChecked: boolean;
  onRowChecked: MouseEventHandler;
  unread: boolean;
}

function ReportRowTable({ data, columnDefinitions, tooltipActions, isChecked, onRowChecked, unread }: ReportRowTableProps) {
  const { ellipsis: ellipsis, tooltip: tooltipImage, checkbox } = ImageImports;
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const showTooltip = (e: React.MouseEvent<HTMLImageElement>) => {
    const willBeVisible = !isTooltipVisible;
    const rect = e.currentTarget.getBoundingClientRect();
    setIsTooltipVisible(willBeVisible);
  };

  const { toggleTooltip, setToolTipContent, toolTip, toggleNotificationPane, notificationPaneOpen } = useContext(Store);
  const tooltipId = useId().replaceAll(":", "_");

  const Tooltip = () => {
    return (
      <div
        className="row-item text-l cursor-pointer"
        style={{ color: "#00749E" }}
        tabIndex={1}
        onClick={() => {
          setToolTipContent && setToolTipContent(<ViewDocPaneContent report={data} />);
          toggleTooltip && toggleTooltip(!toolTip);
        }}
      >
        <strong>View</strong>
      </div>
    );
  };

  return (
    <tr style={{ color: data.isDeleted ? 'var(--secondaryGrey)' : 'inherit' }} className={unread ? classes.unread : ''}>
      <td>
        <div className={classes.checkmark_container}>
          <div className={classes.checkbox}>
            <input type="checkbox" checked={isChecked} onChange={() => { }} />
            <img src={checkbox} className={"cursor-pointer"} alt="checkbox" onClick={onRowChecked} style={isChecked ? {display: "inherit"} : {display: "none"}} />
            <span className={classes.checkmark} onClick={onRowChecked} style={!isChecked ? {display: "inherit"} : {display: "none"}} />
          </div>
        </div>
      </td>
      {columnDefinitions.map((columnDefinition) => (
        <td key={columnDefinition.key} className={`${classes.data_point} ${columnDefinition.className}`} style={columnDefinition.style}>
          {columnDefinition.component ? <columnDefinition.component report={data} tooltip={<Tooltip />} /> : <div><>{data[columnDefinition.key]}</></div>}
        </td>
      ))}
      <td>
        <div id={tooltipId} className={`${classes.flex_row} ${classes.more_actions}`} onClick={showTooltip}>
          <img src={ellipsis} alt="More Actions" style={{ filter: 'var(--svgFilterDarkBlueButton)' }} />
          <BulkActionTooltip shown={isTooltipVisible} isDeleted={data?.isDeleted} parentId={tooltipId} setShown={setIsTooltipVisible} listItems={tooltipActions} data={[data]} />
        </div>
      </td>
    </tr>
  );
}

export default ReportRowTable;
