import React, { useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { usePermissions } from "../../../../customHooks/useUserPermissions";
import { Store } from "../../../../Store";
import { useStore } from "../../../../useStore";
import ImageImports from "../../../../utils/ImageImports";
import { PlantEquipmentsDATA } from "../../../../utils/interface";

const EquipmentAccordianPanel = ({ Paneldata, index, editRow, openEquipmentsAttachments }: PanelProps) => {
  const { pencil, arrowDown, fileTab } = ImageImports;
  const { accordian_tab, setAccordian_tab, user } = useStore();
  const [height, setHeight] = useState(0);
  const acc_contentHeight = useRef<HTMLDivElement>(null);
  const { hasOnePermission } = usePermissions();
  const { plantEquipmentId, equipmentName, equipmentID, equipmentModelName, productTypeName, manufacturerName, equipmentAttachmentCount } = Paneldata;

  const isActive = accordian_tab === index;
  const innerStyle = {
    height: `${isActive ? height : 0}px`,
  };

  const handleActiveTab = (e: number) => {
    setAccordian_tab?.(e);
    let contentHeight = acc_contentHeight.current && acc_contentHeight.current.clientHeight;
    if (contentHeight) {
      setHeight(contentHeight);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/role-supports-aria-props
    <div className="panel" role="tabpanel" aria-expanded={isActive}>
      <button className="panel__label" role="tab" onClick={() => handleActiveTab(index)}>
        {equipmentName}
        {hasOnePermission(['ThirdPartyEquipment', 'MitsubishiPowerEquipment'], 'edit') && editRow && (
          <img
            src={pencil}
            className="pencil"
            alt=""
            onClick={(e) => {
              // e.stopPropagation();
              editRow(plantEquipmentId);
            }}
          />
        )}

        <img src={arrowDown} alt="down Arrow" className="arrow-accordian" />
      </button>
      <div className="panel__inner" style={innerStyle} aria-hidden={!isActive}>
        <div ref={acc_contentHeight} className="panel__content">
          <div className="panel_tbl">
            <div className="panel_tbl_cell">
              <strong>Equipment ID</strong>
            </div>
            <div className="panel_tbl_cell">{equipmentID}</div>
          </div>
          <div className="panel_tbl">
            <div className="panel_tbl_cell">
              <strong>Product Type</strong>
            </div>
            <div className="panel_tbl_cell">{productTypeName}</div>
          </div>
          <div className="panel_tbl">
            <div className="panel_tbl_cell">
              <strong>Manufacturer</strong>
            </div>
            <div className="panel_tbl_cell">{manufacturerName}</div>
          </div>
          <div className="panel_tbl">
            <div className="panel_tbl_cell">
              <strong>Model Name</strong>
            </div>
            <div className="panel_tbl_cell">{equipmentModelName}</div>
          </div>
          <div className="panel_tbl">
            <div className="panel_tbl_cell">
              <strong>Nameplates</strong>
            </div>
            <div className="panel_tbl_cell">
              <div className="row-data flex align-center">
                <Link to={`/plant-information/equipmentAttachments/${plantEquipmentId}`}>
                  <img src={fileTab} className="pencil" alt="" />
                </Link>
                <span className="table_text"> {equipmentAttachmentCount}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export interface PanelProps {
  Paneldata: PlantEquipmentsDATA;
  index: number;
  editRow: (id: number) => void;
  openEquipmentsAttachments: (value: number) => void;
}

export default EquipmentAccordianPanel;
