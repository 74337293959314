import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Store } from "../Store";


export const usePageRequiresAdmin = () => {
    const {header: {data: {user}}} = useContext(Store);
    const navigate = useNavigate();
    
    useEffect(() => {
        if (user && !user.portalAdmin) {
            navigate('/', {replace: true});
        }
    }, [user]);
};