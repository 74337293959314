import { AxiosError } from "axios";
import React, { useContext, useRef, useState, useCallback } from "react";
import { Store } from "../../Store";
import { EquipmentAttachmentsApi, useGetImage_PlantEquipmentAttachment } from "../../containers/PlantInformation/EquipmentAttachmentsHttpServices";
import { useOnError } from "../../customHooks/useOnError";
import useWindowSize from "../../customHooks/useWindowSize";
import { CloseAllModal } from "../../utils/CloseAllModal";
import { PrintPDF, toBase64 } from "../../utils/FileUtils";
import ImageImports from "../../utils/ImageImports";
import Loader from "../Loader/Loader";
import PopoutToolTip from "../PopoutTooltip/PopoutTooltip";
import ModalGlobal from "../UI/Modal/ModalGlobal";
import classes from "./Nameplate.module.css";
import { supportedFileTypes } from "../../utils/getSupportedFileTypes";

const NamePlateViewModal = ({ namePlateData }: NamePlateViewModalProps) => {
  const stateContext = useContext(Store);
  const { isModal, setIsModal } = stateContext;
  const { closeImg, download, print, ellipsis } = ImageImports;
  const [attachmentBlob, setAttachmentBlob] = useState<Blob | null>(null);
  const setErrorData = useOnError();
  const [imgBase64, setImgBase64] = useState("");
  const { isDesktop, isTablet } = useWindowSize();
  const [objUrl, setObjUrl] = useState<string>();
  //{***************************************************************************************************************************}

  ///////////////////////////////////////////
  // Fetch useGetImage_PlantEquipmentAttachment Start
  ///////////////////////////////////////////

  const onSuccess = (data: Blob) => {
    setAttachmentBlob(data);
    setObjUrl(URL.createObjectURL(data));
    toBase64(data)
      .then((base64Image: string) => setImgBase64(base64Image))
      .catch((err) => setErrorData(err));
  };
  const onError = (error: AxiosError<string>) => {
    setErrorData(error);
  };
  const { isFetching } = useGetImage_PlantEquipmentAttachment(namePlateData.equipmentAttachmentId, onSuccess, onError);

  ///////////////////////////////////////////
  // Fetch useGetImage_PlantEquipmentAttachment End
  ///////////////////////////////////////////

  //{***************************************************************************************************************************}
  const downloadEmployeeData = () => {
    if (attachmentBlob) {
      EquipmentAttachmentsApi.download(attachmentBlob, namePlateData.name, namePlateData.ext);
    }
  };

  const handlePrint = () => {
    attachmentBlob && PrintPDF(attachmentBlob);
  };

  const handleModalClose = () => {
    setIsModal!({
      ...CloseAllModal,
      isView: { ...isModal.isView, isNameplate: false },
    });    
  };


  const popoutToolTipListItems = [
    {
      img: download,
      text: "Download",
      onclick: downloadEmployeeData,
    },
    {
      img: print,
      text: "Print",
      onclick: handlePrint,
    },
  ];

  const PreviewContainer = useCallback(() => {
    const { docTypes, excelTypes, docTypeExtensions, excelTypeExtensions } = supportedFileTypes();
    const { docDummy, excelDummy } = ImageImports;
    if (isFetching) {
      return <Loader containerStyle={{marginTop:"50%", marginBottom:"50%"}} />;
    }
    
    if (attachmentBlob && attachmentBlob.type) {
      if (attachmentBlob.type.includes('image')) {
        return <img src={imgBase64} alt={`${namePlateData.name}`} className="object-contain h-[200px]" onLoad={() => URL.revokeObjectURL(imgBase64 || '')} />;
      }  else if (attachmentBlob.type.includes("application")) {
          if (attachmentBlob.type.includes("application/pdf")) {
              return (<div className="w-full flex flex-row">
                  <iframe title={`${namePlateData.name}`} className="w-full h-[50vh]" src={`${objUrl}#toolbar=0&navpanes=0&scrollbar=0&&scrolling=0&view=FitH&#page=1&pagemode=thumbs`}></iframe>
                </div>)
          } else {
            if (docTypes.find(type => type === `application/${namePlateData.fileTypeName}`) || docTypeExtensions.find(type => type === `${namePlateData.ext}`)) {
              return <img src={docDummy} alt={`${namePlateData.name}`} className="object-contain h-[200px]" style={{ width: "100%" }} onLoad={() => URL.revokeObjectURL(docDummy || "")} />;
            }

            if (excelTypes.find(type => type === `application/${namePlateData.fileTypeName}`) || excelTypeExtensions.find(type => type === `${namePlateData.ext}`)) {
              return <img src={excelDummy} alt={`${namePlateData.name}`} className="object-contain h-[200px]" style={{ width: "100%" }} onLoad={() => URL.revokeObjectURL(excelDummy || "")} />;
            }
          }
      }
    }

    return (
      <div style={{ textAlign: "center", color: "#3C5D69" }}>
        <p>File preview not available.</p>
      </div>
    );
  }, [attachmentBlob, isFetching, imgBase64, objUrl, namePlateData]);

  const ActionToolTipRef = useRef<HTMLImageElement>(null);
  const [actionToolTip, setActionToolTip] = useState(false);

  return (
    <ModalGlobal modaltitle={namePlateData.name} isModalHeader={false} modalSize={"lg"} modalClass="!focus-visible:outline-none outline-0">
      <>
        <div className="flex flex-col gap-y-9">
          <div className="flex flex-row gap-2 justify-between items-start">
            <div className="w-1/2 md:w-3/4">
              <div className="text-black font-bold text-xl mb-3 break-all">{namePlateData.name}</div>
              <div className="text-sm text-gray-60">{namePlateData.fileSize}</div>
            </div>
            <div>
              <div className="flex justify-end items-center gap-x-2 md:gap-x-3">
                {(isDesktop || isTablet) && 
                  <>
                    <div className="text-dark-blue hover:cursor-pointer" onClick={downloadEmployeeData}>
                      <img className={`${classes.modalActionIcon}`} src={download} alt="Download" />
                    </div>
                    <div className="text-dark-blue hover:cursor-pointer" onClick={handlePrint}>
                      <img className={`${classes.modalActionIcon}`} src={print} alt="Print" />
                    </div>
                  </>
                }
                {(!isDesktop && !isTablet) &&
                  <div className={`${classes.nameplate_ellipse}`}>
                    <img
                      ref={ActionToolTipRef}
                      src={ellipsis}
                      className={`ellipsis hover:cursor-pointer ${classes.modalActionIcon}`}
                      alt="ellipsis"
                      onClick={() => {
                        setActionToolTip(!actionToolTip);
                      }}
                      tabIndex={1}
                    />
                    <PopoutToolTip listItems={popoutToolTipListItems} displayTrigger={actionToolTip} id={namePlateData.equipmentAttachmentId} />
                  </div>
                }
                <div className="text-dark-blue hover:cursor-pointer" onClick={handleModalClose}><img className={`${classes.modalActionIcon}`} src={closeImg} alt="Close" /></div>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-center w-full">
            <div className={`${classes.imageWrapper} w-full text-center`}>
              <PreviewContainer />
            </div>
          </div>
        </div>
      </>
    </ModalGlobal>
  );
};

interface NamePlateViewModalProps {
  namePlateData: {
    equipmentAttachmentId: number;
    name: string;
    fileTypeName: string;
    description: string;
    attachments: string;
    fileSize: string;
    ext: string;
  };
}

export default NamePlateViewModal;
