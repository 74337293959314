import { useState } from "react";
import { UnitDATA } from "../../utils/interface";
import Button from "../Button/Button";
import EmptyModal from "../Modals/EmptyModal";


export const DeleteUnitReason = ({unit, plant, onDelete, onClose}: {unit: UnitDATA, plant: string, onDelete: (unit: UnitDATA, reason: string) => void, onClose: () => void}) => {
    const [deleteReason, setDeleteReason] = useState<string>('');
    return (
        <EmptyModal body={(
                <div className="flex flex-col items-start gap-6">
                    <span>Are you sure you want to delete the selected unit within {plant}? Deleting this unit will also delete any associated equipment (and their attachments). This cannot be undone.</span>
                    <div className="flex flex-col items-start gap-2 self-stretch">
                        <span>Reason for Deletion* </span>
                        <input type="text"
                                className={`pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base shadow-[0_1px_0_#B3B3B3] w-full h-16`}
                                value={deleteReason}
                                placeholder="ex. duplicate unit"
                                onChange={e => setDeleteReason(e.target.value)} />
                    </div>
                </div>
            )}
            footer={(
                <div className="flex flex-row justify-between grow">
                    <div className="flex flex-row gap-4">
                        <Button className="redDelete" text="Yes, Delete" disabled={deleteReason.length === 0} onClick={() => onDelete(unit, deleteReason)} />
                        <Button className="whiteBtn" text="Cancel" onClick={onClose} />
                    </div>
                </div>
            )}
            heading={`Delete "${unit.name} Unit?`}
            onClose={onClose} shown={true} width={564} />
    );
};