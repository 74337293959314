import React, { useEffect, useRef, useState, useCallback } from "react";

import PopoutToolTip from "../../PopoutTooltip/PopoutTooltip";
import classes from "../Nameplate.module.css";
import ImageImports from "../../../utils/ImageImports";
import moment from "moment";
import { EquipmentAttachmentModelDTO, IdObj } from "../../../utils/interface";
import { EquipmentAttachmentsApi, useGetImage_PlantEquipmentAttachment } from "../../../containers/PlantInformation/EquipmentAttachmentsHttpServices";
import { PrintPDF, toBase64 } from "../../../utils/FileUtils";
import { AxiosError } from "axios";
import { useOnError } from "../../../customHooks/useOnError";
import Skeleton from "react-loading-skeleton";
import { usePermissions } from "../../../customHooks/useUserPermissions";
import Loader from "../../Loader/Loader";
import { supportedFileTypes } from "../../../utils/getSupportedFileTypes";

interface NameplateCardProps {
  IdObj: IdObj;
  nameplateData: EquipmentAttachmentModelDTO;
  getIdOpenEditModal(id: number): void;
  getIdOpenViewModal(id: number): void;
  getIdDeleteViewModal(id: IdObj): void;
}

function NameplateCard({nameplateData, getIdOpenEditModal, getIdDeleteViewModal, getIdOpenViewModal, IdObj}: NameplateCardProps) {
  const {hasPermission} = usePermissions();
  // Image imports;
  const { ellipsis, trash, file_edit, download, print } = ImageImports;
  const [attachmentBlob, setAttachmentBlob] = useState<Blob | null>(null);
  const setErrorData = useOnError();
  const [imgBase64, setImgBase64] = useState("");
  //{***************************************************************************************************************************}

  ///////////////////////////////////////////
  // Fetch useGetImage_PlantEquipmentAttachment Start
  ///////////////////////////////////////////

  const onSuccess = (data: Blob) => {
    setAttachmentBlob(data);
    toBase64(data)
      .then((base64Image: string) => setImgBase64(base64Image))
      .catch((err) => setErrorData(err));
  };
  const onError = (error: AxiosError<string>) => {
    setErrorData(error);
  };
  const { isLoading: attachmentIsLoading, isFetching } = useGetImage_PlantEquipmentAttachment(IdObj.attachmentid, onSuccess, onError);

  ///////////////////////////////////////////
  // Fetch useGetImage_PlantEquipmentAttachment End
  ///////////////////////////////////////////

  //{***************************************************************************************************************************}
  
  const popoutToolTipListItems = [
    {
      img: download,
      text: "Download",
      onclick: (IdObj: IdObj) => {
        if (attachmentBlob) {
          EquipmentAttachmentsApi.download(attachmentBlob, nameplateData.name, nameplateData.fileTypeObj.extension);
        }
      },
    },
    {
      img: print,
      text: "Print",
      onclick: (IdObj: IdObj) => {
        if (attachmentBlob) {
          PrintPDF(attachmentBlob);
        }
      },
    },
  ];

  if (hasPermission('EquipmentAttachments', 'edit')) {
    popoutToolTipListItems.unshift({
      img: file_edit,
      text: "Edit",
      onclick: (IdObj: IdObj) => {
        getIdOpenEditModal(IdObj.attachmentid);
      },
    });
  }
  if (hasPermission('EquipmentAttachments', 'delete')) {
    popoutToolTipListItems.push({
      img: trash,
      text: "Delete",
      onclick: (IdObj: IdObj) => {
        getIdDeleteViewModal(IdObj);
      },
    })
  }

  const ActionToolTipRef = useRef<HTMLImageElement>(null);
  const [actionToolTip, setActionToolTip] = useState(false);

  useEffect(() => {
    const handleCloseDropDown = (event: any) => {
      var path = event.path || (event.composedPath && event.composedPath());
      if (path[0] !== ActionToolTipRef.current) {
        setActionToolTip(false);
      }
    };
    document.body.addEventListener("click", handleCloseDropDown);
    //Cleanup
    return () => document.body.removeEventListener("click", handleCloseDropDown);
  }, []);

  
  const PreviewContainer = useCallback(() => {
    const { docTypes, excelTypes, docTypeExtensions, excelTypeExtensions } = supportedFileTypes();
    const { excelDummy, docDummy, pdfDummy } = ImageImports;
    if (attachmentIsLoading || isFetching) {
      return <Loader containerStyle={{marginTop:"50%", marginBottom:"50%"}} />;
    }

    if (nameplateData && imgBase64) {
      if (nameplateData.fileTypeObj.contentType.includes('image')) {
        return <img src={imgBase64} alt={`${nameplateData.name}`} className="object-contain w-full h-[200px]" onLoad={() => URL.revokeObjectURL(imgBase64 || '')} />;
      }  else if (nameplateData.fileTypeObj.contentType.includes("application")) {
        if (nameplateData.fileTypeObj.contentType.includes("application/pdf")) {
          return <img src={pdfDummy} alt={`${nameplateData.name}`} className="object-contain w-full h-[200px]" onLoad={() => URL.revokeObjectURL(pdfDummy || "")} />;
        } else {
          if (docTypes.find(type => type === `${nameplateData.fileTypeObj.contentType}`) || docTypeExtensions.find(type => type === `${nameplateData.fileTypeObj.extension}`)) {
            return <img src={docDummy} alt={`${nameplateData.name}`} className="object-contain w-full h-[200px]" onLoad={() => URL.revokeObjectURL(docDummy || "")} />;
          }
          if (excelTypes.find(type => type === `${nameplateData.fileTypeObj.contentType}`) || excelTypeExtensions.find(type => type === `${nameplateData.fileTypeObj.extension}`)) {
            return <img src={excelDummy} alt={`${nameplateData.name}`} className="object-contain w-full h-[200px]" onLoad={() => URL.revokeObjectURL(excelDummy || "")} />;
          }
        }
      }
    }

    return (
      <div style={{ textAlign: "center", color: "#3C5D69" }}>
        <p>File preview not available.</p>
      </div>
    );
  }, [attachmentIsLoading, isFetching, nameplateData, imgBase64]);

  return (
    <>
      <div className={classes.nameplate_title}>
        { (
          <div className={classes.nameplate_content}>
            <div className={classes.nameplate_ellipse}>
              <img
                ref={ActionToolTipRef}
                src={ellipsis}
                className="ellipsis"
                alt="ellipsis"
                onClick={() => {
                  setActionToolTip(!actionToolTip);
                }}
                tabIndex={1}
              />
              <PopoutToolTip listItems={popoutToolTipListItems} displayTrigger={actionToolTip} id={nameplateData.equipmentAttachmentId} IdObj={IdObj} />
            </div>
            {
                (isFetching || attachmentIsLoading) ? (
                  <Skeleton height={200} />
                ) :  <div
                className={`${classes.col_item} ${classes.imgPrevWrapper}`}
                onClick={() => getIdOpenViewModal(nameplateData.equipmentAttachmentId)}
              >
                <PreviewContainer />
              </div>}
            
            <div className={`${classes.flex_grow1} ${classes.col_item} ${classes.nameplate_text_container}`}>
              <div className="col-item flex-grow-1">{nameplateData.name}</div>
              <div className={`${classes.flex_grow1} ${classes.col_item} ${classes.grey_font}`}>
                Last Modified: {`${moment(nameplateData.updated).format("MMM DD YYYY")}`}
              </div>
              <div className={`${classes.flex_grow1} ${classes.col_item} ${classes.grey_font}`}>Description: {nameplateData.description}</div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default NameplateCard;
