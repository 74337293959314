import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../useStore";
import { CloseAllModal } from "../../utils/CloseAllModal";
import Button from "../Button/Button";

export const Forbidden: FunctionComponent = () => {
  const { setIsModal } = useStore();
  const navigate = useNavigate();
  useEffect(() => {
    setIsModal?.({
      ...CloseAllModal,
    });
  }, []);

  return (
    <div>
      <div className="flex flex-col mx-4 p-8 relative mt-12" style={{ gap: "24px" }}>
        <h1>Access Denied</h1>
        <p className="text-lg mblx-2">
          Unfortunately, you do not have permission to access this page or resource. If you think you require access to this page, please contact your
          Mitsubishi Power administrator.
        </p>
        <div>
          <Button className="darkBlue" text={"Return to Home Page"} onClick={() => navigate("/")} />
        </div>
      </div>
    </div>
  );
};
