import axios, { AxiosError, AxiosResponse } from "axios";
import { QueryFunctionContext, useMutation, useQuery, UseQueryOptions } from "react-query";
import { quoteRequestSurvey } from "../../endpoints/endpoints";
import { queryKeys } from "../../react-query/queryKeys";
import { handleAxiosResponse, handleModulePermissionResponseMulti } from "../../utils/handleAxiosResponse";
import { CPApiResponse, DataWithPermissions, ModulePermissionResponseInterface, QuoteRequestSurvey, QuoteRequestSurveyDTO } from "../../utils/interface";

interface CustomerSurveyCrudMethods<A, T> {
    create: (data: FormData) => Promise<A>;
    get: (quoteRequestSurveyId: number) => Promise<T>;
}
// AxiosResponse<CPApiResponse<ModulePermissionResponseInterface<Thread[]>[]>>
// Promise<DataWithPermissions<Thread[]>>
// QuoteRequestSurvey
export const CustomerSurveyApi: CustomerSurveyCrudMethods<
    QuoteRequestSurveyDTO,
    QuoteRequestSurvey
> = {
    create: async (data: FormData): Promise<QuoteRequestSurveyDTO> => {
        const response = await axios({
            method: "post",
            url: `${quoteRequestSurvey}`,
            data,
            headers: { "Content-Type": "multipart/form-data" },
        })
        return response.data;
    },
    get: async (quoteRequestSurveyId: number): Promise<QuoteRequestSurvey> => {
        const response: AxiosResponse<CPApiResponse<QuoteRequestSurvey>> = await axios({
            url: `${quoteRequestSurvey}/${quoteRequestSurveyId}`,
            method: "get",
        });
        return handleAxiosResponse(response);
    },
};

export const useCreate_CustomerSurvey = () => {
    return useMutation(CustomerSurveyApi.create);
};


declare type GetBaseOrgCustomerSurveysParameters = ['getAllCustomerSurveys', {baseOrgId: number}];
const getBaseOrgCustomerSurveys = async ({queryKey}: QueryFunctionContext<GetBaseOrgCustomerSurveysParameters>): Promise<DataWithPermissions<QuoteRequestSurvey[]>> => {
    const [_key, {baseOrgId}] = queryKey;
    const response: AxiosResponse<CPApiResponse<ModulePermissionResponseInterface<QuoteRequestSurvey[]>[]>> = await axios({
        url: `${quoteRequestSurvey}?BaseOrgId=${baseOrgId}`,
        method: "get",
    });
    return handleModulePermissionResponseMulti(response);
}
export const useGetAllByBaseOrg_CustomerSurveys = (baseOrgId: number, options: Omit<UseQueryOptions<DataWithPermissions<QuoteRequestSurvey[]>, AxiosError<string>, DataWithPermissions<QuoteRequestSurvey[]>, GetBaseOrgCustomerSurveysParameters>, 'queryKey' | 'queryFn'> = {}) => {
    return useQuery(['getAllCustomerSurveys', {baseOrgId}], getBaseOrgCustomerSurveys, options);
};

export const useGet_CustomerSurvey = (quoteRequestSurveyId: number, onSuccess: any, onError: any) => {
    return useQuery(queryKeys.getAllCustomerSurveys, () => CustomerSurveyApi.get(quoteRequestSurveyId), {
        onSuccess,
        onError,
    });
};