import { ModalsStateManagementDTO } from "./interface";

export const CloseAllModal: ModalsStateManagementDTO = {
  isCopy: {
    isCopyLinkModal: false,
  },
  isView: {
    isNameplate: false,
    isCloseQuoteRequest: false,
    isLeavePage: false,
  },
  isAdd: {
    isEquipment: false,
    isContactModal: false,
    isNameplate: false,
    isNameplateSamePage: false,
    isUnit: false,
    isPartsListDocument: false,
    isAttachNameplateAsset: false,
    isQuoteRequest: false,
    isQuoteDocument: false,
    isPODocument: false,
  },
  isDelete: {
    isEquipment: false,
    isPlantConfig: false,
    isNameplate: false,
    isContactModal:false,
    isUnit: false,
    isQuoteRequest: false,
    isQuoteDocument: false,
    isPODocument: false,
  },
  isEdit: {
    isEquipment: false,
    isPlantConfig: false,
    isUnit: false,
    isContactModal: false,
    isNameplate: false,
    isFaq: false,
    isPartsListDocument: false,
    isAttachNameplateAsset: false,
    isQuoteRequest: false,
    isQuoteDocument: false,
    isPODocument: false,
  },
  isDeleteSuccess: {
    isEquipment: false,
    isContactModal: false,
    isPlantConfig: false,
    isNameplate: false,
    isUnit: false,
    isQuoteRequest: false,
    isQuoteDocument: false,
    isPODocument: false,
  },
  isAddedSuccess: {
    isEquipment: false,
    isContactModal: false,
    isNameplate: false,
    isNameplateSamePage: false,
    isUnit: false,
    isQuoteRequest: false,
    isQuoteDocument: false,
    isPODocument: false,
  },
  isEditedSuccess: {
    isEquipment: false,
    isUnit: false,
    isContactModal: false,
    isFaq: false,
    isPlantConfig: false,
    isNameplate: false,
    isQuoteRequest: false,
    isQuoteDocument: false,
    isPODocument: false,
  },
};
