import { FunctionComponent, useEffect } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { TechnicalDocumentationModuleOutletInterface } from "./TechnicalDocumentationModuleContainer";


const TechnicalDocumentationModuleIndex: FunctionComponent = () => {
    const {documentGroups} = useOutletContext<TechnicalDocumentationModuleOutletInterface>();
    const navigate = useNavigate();
    useEffect(() => {
        if (documentGroups?.length > 0) {
            navigate(`./${documentGroups[0].id}`, {
                replace: true
            });
        }
    }, [documentGroups]);
    return <></>;
};

export default TechnicalDocumentationModuleIndex;