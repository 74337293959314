import { useFormikContext } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Store } from "../../Store";
import { CloseAllModal } from "../../utils/CloseAllModal";
import { toBase64 } from "../../utils/FileUtils";
import ImageImports from "../../utils/ImageImports";
import { RequestQuoteFormInterface } from "../../utils/interface";
import DragDropFieldFormik from "../UI/Form/DragDropField/DragDropFieldFormik";
import TextInputField from "../UI/Form/TextInputField/TextInputField";
import ModalGlobal from "../UI/Modal/ModalGlobal";
import classes from "./AttachPartDocumentModal.module.css";
import DragDropFieldInfo from "../UI/Form/DragDropField/DragDropFieldInfo";

const AttachPartDocumentModal = () => {
  const { values, setFieldValue, submitForm } = useFormikContext<RequestQuoteFormInterface>();

  const { isModal, setIsModal, unitTypes, setUnitTypes } = useContext(Store);
  const [previewBase24, setPreviewBase24] = useState<string>("");
  const [attachment, setAttachment] = useState<File | Blob>();
  const { trash } = ImageImports;
  const [name, setName] = useState<string>("");


  useEffect(() => {
    setFieldValue("partsDocument.name", "");
    setFieldValue("partsDocumentBase64", "");
  }, []);


  const handleAddDocument = () => {
  
    if (attachment) {
      toBase64(attachment)
        .then((base64Image: string) => {
          setFieldValue("partsDocumentBase64", base64Image);
          setFieldValue("partsDocument.name", name);
          setIsModal && setIsModal({ ...CloseAllModal });
        })
        .catch((err: Error) => {
          setFieldValue("partsDocumentBase64", "");
        });
    }
  };

  useEffect(() => {
    if (!!values.partsDocumentBase64) {
      setPreviewBase24(values.partsDocumentBase64);
      setName(values?.partsDocument?.name);
    }
  }, []);
  
  useEffect(() => {
    if (attachment && attachment instanceof File && attachment?.name !== name) {
      setName(attachment.name);
    } else if (attachment && attachment instanceof Blob && !name.includes("partsDocument")) { 
      setName(`partsDocument.${attachment.type}`);
    }
  }, [attachment]);

  return (
    <ModalGlobal modaltitle={"Add a Parts List Document"} modalSize={"md"} isModalHeader={true}>
      <div>
        <>
          <div>
            <TextInputField
              name="partsDocument.name"
              disabled
              details="Document name will auto-populate to match the attachment’s filename."
              labelName="Document Name* "
              className=""
            />
          </div>
          <div className={`${classes.form_field_row} ${classes.add_document_field}`}>
            <span className={classes.input_label}>Attachment*</span>
            <div className={classes.form_group}>
              {(!!attachment || !!previewBase24) && (
                <>
                  <input type="text" disabled className={`${classes.input} pr-8`} style={{}} value={name} />
                  <img
                    className={classes.deleteFile}
                    onClick={() => {
                      setFieldValue("partsDocument.name", "");
                      setFieldValue("partsDocument.file", "");
                      setPreviewBase24("");
                      setAttachment(undefined);
                    }}
                    src={trash}
                    alt="Remove Attachment"
                  />
                </>
              )}
            </div>
          </div>
          <DragDropFieldFormik
            name="partsDocument.file"
            onChange={setAttachment}
            displayName={"partsDocument.name"}
            previewBase24={previewBase24}
            attachment={attachment}
            //maxFileSizeBytes={1000000000}
          />
          <div className={`${classes.form_field_row}`}>
            <DragDropFieldInfo fileTypes=".PDF, .DOC, .XLSX, .JPG, .PNG" />
          </div>
          <div className="flex align-center">
            <div className="flex-grow-1 flex">
              <button type={"button"} className="primaryBtn darkBlue" disabled={!attachment || name.length < 1} onClick={handleAddDocument}>
                Add Document
              </button>
            </div>
            <span>*Required fields</span>
          </div>
        </>
      </div>
    </ModalGlobal>
  );
};

export default AttachPartDocumentModal;
