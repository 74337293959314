import React, { useEffect, useMemo, useRef, useState } from "react";
import ImageImports from "../../../../utils/ImageImports";
import { getSortObjectsByKeyFn } from "../../../../utils/Sortutils";

import { useStore } from "../../../../useStore";
import { searchArrayObjects } from "../../../../utils/DataUtils";
import { PlantContact } from "../../../../utils/interface";
import Dropdown from "../../../Dropdown/Dropdown";
import SiteContact from "../../../SiteContacts/SiteContact/SiteContact";
import SearchField from "../../../UI/Form/SearchField/SearchField";
import PaginationBasic from "../../../UI/Pagination/PaginationBasic/PaginationBasic";
import classes from "./SiteContactAccordianPanel.module.css";

type SortOption = {
  id: number;
  name: string;
  isDefault: boolean;
  key: keyof PlantContact,
  direction: 'asc' | 'desc'
};

const SiteContactAccordianPanel = (props: SiteContactAccordianPanelProps) => {
  let defaultPostsPerPage = 16;
  const { index, contact_type, contact_list } = props;
  const { arrowDown } = ImageImports;
  const { accordian_tab, setAccordian_tab } = useStore();
  const acc_contentHeight = useRef<any>(0);
  const [selectedOption, setSelectedOption] = useState<SortOption>();

  const sortFn = useMemo(() => {
    if (selectedOption) {
      return getSortObjectsByKeyFn<PlantContact>(selectedOption.key, selectedOption.direction, d => d.toString().trim().toLowerCase());
    }
    
    return getSortObjectsByKeyFn<PlantContact>('firstName', 'asc', d => d.toString().trim().toLowerCase());
  }, [selectedOption]);

  const [currentPosts, setcurrentPosts] = useState<PlantContact[]>(contact_list.sort(sortFn));
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(defaultPostsPerPage);
  const [showAll, setShowAll] = useState(false);
  const [listCount, setlistCount] = useState(0);
  const [searchText, setSearchText] = useState("");

  const sortOptions: SortOption[] = [
    {
      id: 1,
      name: "First Name A-Z (default)",
      isDefault: true,
      key: 'firstName',
      direction: 'asc'
    },
    {
      id: 2,
      name: "First Name Z-A",
      isDefault: false,
      key: 'firstName',
      direction: 'desc'
    },
    {
      id: 3,
      name: "Last Name A-Z",
      isDefault: false,
      key: 'lastName',
      direction: 'asc'
    },
    {
      id: 4,
      name: "Last Name Z-A",
      isDefault: false,
      key: 'lastName',
      direction: 'desc'
    }
  ];

  const handleActiveTab = (e: number) => {
    setAccordian_tab && setAccordian_tab(e);
  };

  const handleShowAll = () => {
    setShowAll((prevState) => !prevState);
  };

  const handlePaginate = (pagenumber: number) => {
    setCurrentPage(pagenumber);
  };

  const handleNextPaginate = () => {
    setCurrentPage((prev) => prev + 1);
  };
  const handlePrevPaginate = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const isActive = accordian_tab === index;
  const innerStyle = {
    height: `${isActive ? "auto" : 0}`,
  };
  useEffect(() => {
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    let currentPostsList;
    if (showAll) {
      currentPostsList = contact_list;
      setcurrentPosts(currentPostsList.sort(sortFn));
    } else {
      currentPostsList = contact_list.sort(sortFn).slice(indexOfFirstPost, indexOfLastPost);
      setcurrentPosts(currentPostsList);
    }

    setlistCount(contact_list.length);
  }, [currentPage, showAll, searchText, postsPerPage, contact_list, selectedOption, sortFn]);

  useEffect(() => {
    setPostsPerPage(defaultPostsPerPage);
    setShowAll(false);
    setCurrentPage(1);
  }, [accordian_tab, defaultPostsPerPage]);

  useEffect(() => {
    if (searchText.trim().length > 0) {
      const keys = ["firstName", "lastName"];
      const results = searchArrayObjects(keys, contact_list, searchText);
      setcurrentPosts(results.sort(sortFn));
      setlistCount(results.length || 0);
    } else {
      setlistCount(contact_list.length);
    }
  }, [searchText, contact_list, sortFn]);

  const handleSearchText = (searchText: string) => {
    setSearchText(searchText);
  };

  const isContactListEmpty = props.contact_list.length < 1;

  return (
    // eslint-disable-next-line jsx-a11y/role-supports-aria-props
    <div className={`panel ${classes.panel}`} role="tabpanel" aria-expanded={isActive}>
      <button className={`panel__label ${classes.panel__label}`} role="tab" onClick={() => isContactListEmpty ? undefined : handleActiveTab(isActive ? -1 : index)}>
        {contact_type} ({contact_list.length})
        {!isContactListEmpty && <img src={arrowDown} alt="down Arrow" className="arrow-accordian" />}
      </button>
      <div className="panel__inner" style={innerStyle} aria-hidden={!isActive}>
        <div ref={acc_contentHeight} className={`panel__content ${classes.panelSpacing}`}>
          {
            <div className={classes.contactFilter}>
              <div className="nameplate-dropdown inline-flex" style={{ marginRight: "10px" }}>
                <Dropdown
                  dropdowntype={"Sort by:"}
                  handleDropDownSelect={(a, e) => {
                    setSelectedOption(e);
                  }}
                  selectValue={"First Name A-Z (default)"}
                  selectedOption={selectedOption?.name || ''}
                  options={sortOptions}
                />
              </div>
              <SearchField placeholder="Search contacts" handleSearchText={handleSearchText} />
            </div>
          }

          {currentPosts?.length === 0 ? (
            <p>There are no contacts to display</p>
          ) : (
            <div className="flex flex-wrap gap-3 lg:gap-0">
              {currentPosts?.map((contactList) => {
                return <SiteContact key={contactList.plantContactId} contactList={contactList} handleOpenEditContact={props.handleOpenEditContact} />;
              })}
            </div>
          )}
          <div className={classes.pagination_wrp}>
            <p className={classes.paginationCount}>
              {showAll ? currentPosts.length : Math.min(postsPerPage, currentPosts.length)} of {listCount} Results
            </p>{" "}
            {
              (currentPosts.length === postsPerPage && contact_list.length !== postsPerPage) && <button onClick={handleShowAll} className={`${classes.showAllBtn} primaryBtn whiteBtn marginLeft16`}>
              {!showAll ? "Show All" : "Paginate"}
            </button>
            }
            
            {!showAll && (
              <div className={classes.paginate}>
                <PaginationBasic
                  totalPosts={contact_list.length}
                  postsPerPage={postsPerPage}
                  currentPage={currentPage}
                  paginate={handlePaginate}
                  next_paginate={handleNextPaginate}
                  prev_paginate={handlePrevPaginate}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export interface SiteContactAccordianPanelProps {
  id: number;
  contact_type: string;
  contact_list: PlantContact[];
  index: number;
  handleOpenEditContact(value: number): void;
}

export default SiteContactAccordianPanel;
