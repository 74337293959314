import React, { useEffect, useState } from "react";

import { AxiosError } from "axios";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useGetAllByUnitID_PlantEquipments, useGet_PlantEquipment } from "../../containers/PlantInformation/EquipmentsHttpServices";
import { useGetAll_UnitData } from "../../containers/PlantInformation/UnitHttpServices";
import { useOnError } from "../../customHooks/useOnError";
import { usePermissions } from "../../customHooks/useUserPermissions";
import useWindowSize from "../../customHooks/useWindowSize";
import { apiGetEquipmentAttachmentFile } from "../../endpoints/endpoints";
import { useStore } from "../../useStore";
import { CloseAllModal } from "../../utils/CloseAllModal";
import ImageImports from "../../utils/ImageImports";
import { SortAttachmentArray } from "../../utils/Sortutils";
import { EquipmentAttachmentModelDTO, IdObj, PlantEquipmentDTO, PlantEquipmentsDTO, UnitGetAllDTO } from "../../utils/interface";
import Button from "../Button/Button";
import Dropdown from "../Dropdown/Dropdown";
import FormNamePlatesAddEdit from "../FormsPages/FormNamePlatesAddEdit/FormNamePlatesAddEdit";
import FormSuccess from "../FormsPages/FormSuccess/FormSuccess";
import Loader from "../Loader/Loader";
import PaginationBasic from "../UI/Pagination/PaginationBasic/PaginationBasic";
import NamePlateDeleteModal from "./NamePlateDeleteModal";
import NamePlateViewModal from "./NamePlateViewModal";
import "./Nameplate.css";
import classes from "./Nameplate.module.css";
import NameplateCard from "./NameplateCard/NameplateCard";

function Nameplate() {
  const {hasPermission} = usePermissions();
  
  //image imports
  const { Arrow } = ImageImports;

  const [attachmentList, setAttachmentList] = useState<EquipmentAttachmentModelDTO[]|[]>([]);
  const [currentPosts, setCurrentPosts] = useState<EquipmentAttachmentModelDTO[]|[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPosts, setTotalPosts] = useState<number>(0);
  const [perPagePostStart, setPerPagePostStart] = useState<number>(0);
  const [perPagePostEnd, setPerPagePostEnd] = useState<number>(0);
  const [postsPerPage] = useState(8);
  
  const [sortKey, setSortKey] = useState<string>('1');

  const navigate = useNavigate();
  const { plantEquipmentId } = useParams();

  const {
    plantInformation: { nameplate },
    selectedPlant,
    plantEquipment,
    setPlantEquipment,
    setEquipmentTypes,
    equipmentTypes,
    plantEquipment: { data: equipmentData },
    plantEquipmentsAttachment,
    plantEquipments,
    setPlantEquipments,
    setUnitGetAll,
    unitGetAll,
    setUnitTypes,
    isModal,
    setIsModal,
  } = useStore();
  const setErrorData = useOnError();
  const { isDesktop, isMobile } = useWindowSize();
  const [attachmentID, setAttachmentID] = useState<number>(0);
  const [IdObj, setidObj] = useState<IdObj | null>(null);
  const [urlSearchParams] = useSearchParams();

  const [sortSelectedOption, setSortSelectedOption] = useState<string | null>(null);

  const [filterSelectedOption, setFilterSelectedOption] = useState<string | null>(null);

  const [namePlateData, setNamePlateData] = useState<{
    equipmentAttachmentId: number;
    name: string;
    fileTypeName: string;
    description: string;
    attachments: string;
    fileSize: string;
    ext: string;
  }>({
    equipmentAttachmentId: 0,
    name: "",
    fileTypeName: "",
    description: "",
    attachments: "",
    fileSize: "",
    ext: "",
  });
  const [paramsID, setParamsID] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const getIdOpenEditModal = (id: number) => {
    setAttachmentID?.(id);
    setIsModal!({
      ...CloseAllModal,
      isEdit: { ...isModal.isEdit, isNameplate: true },
    });
  };

  const getIdOpenViewModal = (id: number) => {
    const filteredData = equipmentData.equipmentAttachmentModels?.find((data, i) => data.equipmentAttachmentId === id);
    if (filteredData) {
      setNamePlateData?.({
        equipmentAttachmentId: filteredData.equipmentAttachmentId,
        name: filteredData.name,
        fileTypeName: filteredData.fileTypeName,
        description: filteredData.description,
        attachments: `${apiGetEquipmentAttachmentFile}/${filteredData.equipmentAttachmentId}`,
        fileSize: filteredData.displaySize,
        ext: filteredData.fileTypeObj.extension,
      });
      setIsModal!({
        ...CloseAllModal,
        isView: { ...isModal.isView, isNameplate: true },
      });
    }
  };
  const getIdDeleteViewModal = (IdObj: IdObj) => {
    setidObj(IdObj);
    setIsModal!({
      ...CloseAllModal,
      isDelete: { ...isModal.isDelete, isNameplate: true },
    });
  };

  //{***************************************************************************************************************************}

  ///////////////////////////////////////////
  // Fetch useGet_PlantEquipment Start
  ///////////////////////////////////////////

  const onSuccessEquipment = (data: PlantEquipmentDTO) => {
    setPlantEquipment?.(data);
  };

  const onErrorEquipment = (error: AxiosError<string>) => {
    setErrorData(error);
  };

  const { isLoading } = useGet_PlantEquipment(paramsID, onSuccessEquipment, onErrorEquipment);

  ///////////////////////////////////////////
  // Fetch useGet_PlantEquipment End
  ///////////////////////////////////////////

  //{***************************************************************************************************************************}

  ///////////////////////////////////////////
  // Fetch useGetAll_UnitData Start
  ///////////////////////////////////////////

  const onSuccessUnitData = (data: UnitGetAllDTO) => {
    // console.log("onSuccess", data);
    setUnitGetAll?.(data);
    const UnitSelectOptions = data.data.map((unit) => {
      return {
        id: unit.unitId,
        name: unit.name,
      };
    });
    if (UnitSelectOptions) {
      setUnitTypes?.({ data: [...UnitSelectOptions] });
    }
  };
  const onErrorUnitData = (error: AxiosError<string>) => {
    setErrorData(error);

    setUnitGetAll?.({
      ...unitGetAll,
      data: [],
    });
    setUnitTypes?.({ data: [] });
    navigateBack();
  };
  useGetAll_UnitData(selectedPlant.plantId, onSuccessUnitData, onErrorUnitData);

  ///////////////////////////////////////////
  // Fetch useGetAll_UnitData End
  ///////////////////////////////////////////

  //{***************************************************************************************************************************}

  ///////////////////////////////////////////
  // Fetch useGetAllByUnitID_PlantEquipments Start
  ///////////////////////////////////////////

  const onSuccessPlantEquipments = (data: PlantEquipmentsDTO) => {
    setPlantEquipments?.(data);
    const plantEquipmentTypes = data.data.map((equipment) => {
      return {
        id: equipment.plantEquipmentId,
        name: equipment.equipmentName,
      };
    });
    if (plantEquipmentTypes) {
      setEquipmentTypes?.({ data: [...plantEquipmentTypes] });
    }
  };
  const onErrorPlantEquipments = (error: AxiosError<string>) => {
    setPlantEquipments?.({
      ...plantEquipments,
      data: [],
    });
    setErrorData(error);
  };
  useGetAllByUnitID_PlantEquipments(plantEquipment.data.unitID, onSuccessPlantEquipments, onErrorPlantEquipments);

  ///////////////////////////////////////////
  // Fetch useGetAllByUnitID_PlantEquipments End
  ///////////////////////////////////////////

  //{***************************************************************************************************************************}

  useEffect(() => {
    if (urlSearchParams.has('plantid')) {
      navigate('/plant-information');
    }
  }, [urlSearchParams]);

  useEffect(() => {
    if (plantEquipment.data.equipmentAttachmentModels && plantEquipment?.data?.equipmentAttachmentModels.length > 0) {
      setAttachmentList(plantEquipment.data.equipmentAttachmentModels);
    } else {
      setAttachmentList([]);
    }
  }, [plantEquipment]);

  const openAddAttachments = (id: number) => {
    setAttachmentID?.(id);
  };

  const handlePaginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPaginate = () => {
    setCurrentPage((prev) => prev + 1);
  };
  const handlePrevPaginate = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const [sortOption] = useState<
    {
      id: number;
      name: string;
    }[]
  >([
    {
      id: 1,
      name: "Last Modified",
    },
    {
      id: 2,
      name: "Last Added",
    },
    {
      id: 3,
      name: "File Name A-Z",
    },
    {
      id: 4,
      name: "File Name Z-A",
    },
  ]);

  // Get current posts
  useEffect(() => {
    const SortedArray = SortAttachmentArray(sortKey, attachmentList);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    let currentPostsList = SortedArray.slice(indexOfFirstPost, indexOfLastPost);
    setCurrentPosts(currentPostsList);
    setPerPagePostStart(attachmentList.length ? indexOfFirstPost + 1 : 0);
    setPerPagePostEnd((currentPostsList && indexOfLastPost + currentPostsList.length - postsPerPage) || 0);
    setTotalPosts(attachmentList.length);
    
  }, [attachmentList, sortKey, currentPage, postsPerPage]);

  const handleSort = (e: React.MouseEvent<HTMLDivElement>) => {
    const sortedtypedata = e.currentTarget.getAttribute("data-id") || '1';
    setSortKey(sortedtypedata);
  };
  const handleEquipmentFilter = (e: React.MouseEvent<HTMLDivElement>) => {
    const equipmentData = e.currentTarget.getAttribute("data-id");

    if (equipmentData) {
      const newParams = { id: equipmentData };
      setSearchParams(newParams);
      setParamsID(parseInt(equipmentData));
    }
  };

  useEffect(() => {
    if (plantEquipmentId && Number(plantEquipmentId) !== paramsID) {
      setParamsID(parseInt(plantEquipmentId));
    }
    const currentParams = searchParams.get("id");
    if (currentParams) {
      setParamsID(parseInt(currentParams));
    }
  }, [paramsID, searchParams, plantEquipment, plantEquipmentId]);

  const SortDropDown = (
    <Dropdown
      dropdowntype={"Sort by:"}
      handleDropDownSelect={handleSort}
      selectValue={"Last Modified"}
      selectedOption={sortSelectedOption}
      setSelectedOption={(sortOptionName) => setSortSelectedOption(sortOptionName)}
      options={sortOption}
    />
  );
  const FilterDropDown = (
    <Dropdown
      dropdowntype={"Equipment:"}
      handleDropDownSelect={handleEquipmentFilter}
      selectValue={equipmentData.equipmentName}
      selectedOption={filterSelectedOption}
      setSelectedOption={setFilterSelectedOption}
      options={equipmentTypes.data}
    />
  );

  const navigateBack = (): void => {
    navigate(-1);
  };

  return (
    <div className="card row-to-col nameplate-card">
      <div className={` col-to-row ${classes.backto}`}>
        <div className={`col-item ${isMobile && "marginBot24"}`}>
          <Link to={".."} className={`go-back flex align-center `}>
            <img className={`back-arrow`} src={Arrow} alt="back" />
            Back to Configuration Details
          </Link>
        </div>
        {!isDesktop && (
          <div className="nameplate-dropdown inline-flex" style={{ marginRight: "10px" }}>
            {FilterDropDown}
          </div>
        )}
      </div>

      <div className={`col-to-row align-item-center`}>
        <div className={`row-item nameplate-title `}>Viewing Equipment Attachments for {equipmentData.equipmentName}</div>
        {isDesktop && <div className="nameplate-dropdown">{FilterDropDown}</div>}
      </div>
      <hr />
      <div className="col-to-row marginBot52">
        <div className={`row-item ${isMobile && "marginBot24"}`}>
          <div className="nameplate-dropdown inline-flex">{SortDropDown}</div>
        </div>
        {hasPermission('EquipmentAttachments', 'add') && (
          <div className="">
            <Button
              className={classes.darkBlue}
              text="Add Nameplate"
              onClick={() => {
                openAddAttachments(nameplate.id);
                setIsModal!({
                  ...CloseAllModal,
                  isAdd: { ...isModal.isAdd, isNameplate: true },
                });
              }}
            />
          </div>
        )}
      </div>
      <div className={classes.nameplate_container}>
        {isLoading ? (
          <Loader />
        ) : currentPosts && currentPosts?.length > 0 ? (
          currentPosts.map((obj, i) => (
            <NameplateCard
              key={i}
              nameplateData={obj}
              getIdOpenEditModal={getIdOpenEditModal}
              getIdOpenViewModal={(id: number) => getIdOpenViewModal(id)}
              getIdDeleteViewModal={(IdObj) => getIdDeleteViewModal(IdObj)}
              IdObj={{ equipmentid: obj.equipmentId, attachmentid: obj.equipmentAttachmentId }}
            />
          ))
        ) : (
          <div style={{ marginLeft: "1%" }} className="col-to-row marginBot52">
            No Attachments Found for this Equipment
          </div>
        )}
      </div>

      <div className="col-to-row font14">
        <div className="row-item">
          <div className="inline-flex">
            Displaying {perPagePostStart} - {perPagePostEnd} of {totalPosts} Results
          </div>
        </div>
        <div className="flex align-center">
          <PaginationBasic
            totalPosts={totalPosts!}
            postsPerPage={postsPerPage}
            currentPage={currentPage}
            paginate={handlePaginate}
            next_paginate={handleNextPaginate}
            prev_paginate={handlePrevPaginate}
          />
        </div>
      </div>

      {isModal.isAdd.isNameplate && <FormNamePlatesAddEdit isEdit={false} attachmentID={nameplate.id} modaltitle="Add a Nameplate Asset" />}
      {isModal.isEdit.isNameplate && (
        <FormNamePlatesAddEdit
          attachmentID={attachmentID}
          modaltitle="Edit a Nameplate Asset"
          isEdit={true}
          // attachmentID={getIdOpenEditModal}

          handleAttachmentDelete={getIdDeleteViewModal}
          editNamePlateData={namePlateData}
        />
      )}
      {isModal.isEditedSuccess.isNameplate && (
        <FormSuccess
          comment={`The attachment details for “${plantEquipmentsAttachment.data.name}” have been successfully updated.`}
          modalSize={"sm"}
          modalClass="max-w-[536px]"
          isTitleCenter={true}
          button_addmore={false}
          button_done={true}
          modaltitle={`Changes Saved`}
          modaltitlename=""
        />
      )}
      {isModal.isAddedSuccess.isNameplate && (
        <FormSuccess
          comment={`“${plantEquipmentsAttachment.data.name}” was successfully added to the ${plantEquipmentsAttachment.data.equipmentName} equipment attachments.`}
          modalSize={"sm"}
          modalClass="max-w-[536px]"
          isTitleCenter={true}
          button_addmore={true}
          buttonName="Add Another Nameplate"
          onClick={() => {
            openAddAttachments(nameplate.id);
            setIsModal!({
              ...CloseAllModal,
              isAdd: { ...isModal.isAdd, isNameplate: true },
            });
          }}
          button_done={true}
          modaltitle={`Nameplate Asset Successfully Added`}
          modaltitlename=""
        />
      )}
      {isModal.isDeleteSuccess.isNameplate && (
        <FormSuccess
          comment={`You successfully deleted the nameplate “${plantEquipmentsAttachment.data.name}”. Would you like to add another nameplate to this piece of equipment?`}
          modalClass="max-w-[536px]"
          modalSize={"sm"}
          button_addmore={true}
          buttonName="Add Another Nameplate"
          onClick={() => {
            openAddAttachments(nameplate.id);
            setIsModal!({
              ...CloseAllModal,
              isAdd: { ...isModal.isAdd, isNameplate: true },
            });
          }}
          button_done={true}
          modaltitle={`Attachment Successfully Deleted`}
          isTitleCenter={true}
          modaltitlename=""
        />
      )}

      {isModal.isView.isNameplate && <NamePlateViewModal namePlateData={namePlateData} />}

      {isModal.isDelete.isNameplate && <NamePlateDeleteModal IdObj={IdObj!} namePlateData={namePlateData} />}
    </div>
  );
}

export default Nameplate;
