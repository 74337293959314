
import axios, {AxiosResponse} from "axios";
import {CPApiResponse, DocumentGroup, DocumentGroups, DocumentGroupCreatable, MitsubishiPowerReportGroup, ModulePermissionResponseInterface, DataWithPermissions } from '../../utils/interface';
import {apiMitsubishiPowerReport, apiMitsubishiPowerReportType, omManualApiUrl, technicalBulletinApiUrl, apiPartsCatalog } from "../../endpoints/endpoints";
import { handleAxiosResponse, handleModulePermissionResponse } from "../../utils/handleAxiosResponse";


export interface CrudMethods<T, TC, T2> {
    get: (id: number) => Promise<T>;
    update: (data: T) => Promise<T>;
    create: (data: TC) => Promise<T>;
    del: (data: T) => Promise<boolean>;
    getAll: () => Promise<DataWithPermissions<T[]>>;
    generateReport: (itemTypeId:number) => Promise<Blob>;
    getGenerateReportLink: (itemTypeId:number) => string;
}


export const MitsubishiPowerReports: CrudMethods<DocumentGroup, DocumentGroupCreatable, DocumentGroups> = {
	
	get: async (id: number): Promise<DocumentGroup> => {
        const response: AxiosResponse<CPApiResponse<DocumentGroup>> = await axios(`${apiMitsubishiPowerReportType}/${id}`);
        return handleAxiosResponse(response);
    },

    update: async (data: DocumentGroup): Promise<DocumentGroup> => {
        const response: AxiosResponse<CPApiResponse<DocumentGroup>> = await axios(`${apiMitsubishiPowerReportType}/${data.id}`, {
            method: 'PUT',
            data
        });
        return handleAxiosResponse(response);
    },

    create: async (data: DocumentGroupCreatable): Promise<DocumentGroup> => {
        const response: AxiosResponse<CPApiResponse<DocumentGroup>> = await axios(apiMitsubishiPowerReportType, {
            method: 'POST',
            data
        });
        return handleAxiosResponse(response);
    },

    del: async (data: DocumentGroup): Promise<boolean> => {
        const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${apiMitsubishiPowerReportType}/${data.id}`, {
            method: 'DELETE'
        });
        return handleAxiosResponse(response);
    },

    getAll: async (): Promise<DataWithPermissions<DocumentGroup[]>> => {
        const response: AxiosResponse<CPApiResponse<ModulePermissionResponseInterface<DocumentGroup[]>>> = await axios(`${apiMitsubishiPowerReport}/GetSubTypes`);
        return handleModulePermissionResponse(response);
    },
    generateReport: async (itemTypeId: number): Promise<Blob> => {        
        const response: AxiosResponse<Blob> = await axios(`${apiMitsubishiPowerReport}/GenerateUsageReport?ItemTypeId=${itemTypeId}`);
        if (response.status >= 400) {
            throw response.statusText;
        }
        return response.data;
    },
    getGenerateReportLink: (itemTypeId): string => {
        return `${apiMitsubishiPowerReport}/GenerateUsageReport?ItemTypeId=${itemTypeId}`;
    },
};

export const TechnicalBulletins: CrudMethods<DocumentGroup, DocumentGroupCreatable, DocumentGroups> = {
    get: async (id: number): Promise<DocumentGroup> => {
        const response: AxiosResponse<CPApiResponse<DocumentGroup>> = await axios(`${technicalBulletinApiUrl}/Group/${id}`);
        return handleAxiosResponse(response);
    },

    update: async (data: DocumentGroup): Promise<DocumentGroup> => {
        const response: AxiosResponse<CPApiResponse<DocumentGroup>> = await axios(`${technicalBulletinApiUrl}/Group/${data.id}`, {
            method: 'PUT',
            data
        });
        return handleAxiosResponse(response);
    },

    create: async (data: DocumentGroupCreatable): Promise<DocumentGroup> => {
        const response: AxiosResponse<CPApiResponse<DocumentGroup>> = await axios(`${technicalBulletinApiUrl}/Group`, {
            method: 'POST',
            data
        });
        return handleAxiosResponse(response);
    },

    del: async (data: DocumentGroup): Promise<boolean> => {
        const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${technicalBulletinApiUrl}/Group/${data.id}`, {
            method: 'DELETE'
        });
        return handleAxiosResponse(response);
    },

    getAll: async (): Promise<DataWithPermissions<DocumentGroup[]>> => {
        const response: AxiosResponse<CPApiResponse<ModulePermissionResponseInterface<DocumentGroup[]>>> = await axios(`${technicalBulletinApiUrl}/GetSubTypes`);
        return handleModulePermissionResponse(response);
    },
    generateReport: async (itemTypeId: number): Promise<Blob> => {        
        const response: AxiosResponse<Blob> = await axios(`${technicalBulletinApiUrl}/GenerateUsageReport?ItemTypeId=${itemTypeId}`);
        if (response.status >= 400) {
            throw response.statusText;
        }
        return response.data;
    },
    getGenerateReportLink: (itemTypeId): string => {
        return `${technicalBulletinApiUrl}/GenerateUsageReport?ItemTypeId=${itemTypeId}`;
    }
};

export const OmManuals: CrudMethods<DocumentGroup, DocumentGroupCreatable, DocumentGroups> = {
    get: async (id: number): Promise<DocumentGroup> => {
        const response: AxiosResponse<CPApiResponse<DocumentGroup>> = await axios(`${omManualApiUrl}/Group/${id}`);
        return handleAxiosResponse(response);
    },

    update: async (data: DocumentGroup): Promise<DocumentGroup> => {
        const response: AxiosResponse<CPApiResponse<DocumentGroup>> = await axios(`${omManualApiUrl}/Group/${data.id}`, {
            method: 'PUT',
            data
        });
        return handleAxiosResponse(response);
    },

    create: async (data: DocumentGroupCreatable): Promise<DocumentGroup> => {
        const response: AxiosResponse<CPApiResponse<DocumentGroup>> = await axios(`${omManualApiUrl}/Group`, {
            method: 'POST',
            data
        });
        return handleAxiosResponse(response);
    },

    del: async (data: DocumentGroup): Promise<boolean> => {
        const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${omManualApiUrl}/Group/${data.id}`, {
            method: 'DELETE'
        });
        return handleAxiosResponse(response);
    },

    getAll: async (): Promise<DataWithPermissions<DocumentGroup[]>> => {
        const response: AxiosResponse<CPApiResponse<ModulePermissionResponseInterface<DocumentGroup[]>>> = await axios(`${omManualApiUrl}/GetSubTypes`);
        return handleModulePermissionResponse(response);
    },
    generateReport: async (itemTypeId: number): Promise<Blob> => {        
        const response: AxiosResponse<Blob> = await axios(`${omManualApiUrl}/GenerateUsageReport?ItemTypeId=${itemTypeId}`);
        if (response.status >= 400) {
            throw response.statusText;
        }
        return response.data;
    },
    getGenerateReportLink: (itemTypeId): string => {
        return `${omManualApiUrl}/GenerateUsageReport?ItemTypeId=${itemTypeId}`;
    }
};

// parts Catalog section starts here
export const PartsCatalog: CrudMethods<DocumentGroup, DocumentGroupCreatable, DocumentGroups> = {
    get: async (id: number): Promise<DocumentGroup> => {
        const response: AxiosResponse<CPApiResponse<DocumentGroup>> = await axios(`${apiPartsCatalog}/Group/${id}`);
        return handleAxiosResponse(response);
    },

    update: async (data: DocumentGroup): Promise<DocumentGroup> => {
        const response: AxiosResponse<CPApiResponse<DocumentGroup>> = await axios(`${apiPartsCatalog}/Group/${data.id}`, {
            method: 'PUT',
            data
        });
        return handleAxiosResponse(response);
    },

    create: async (data: DocumentGroupCreatable): Promise<DocumentGroup> => {
        const response: AxiosResponse<CPApiResponse<DocumentGroup>> = await axios(`${apiPartsCatalog}/Group`, {
            method: 'POST',
            data
        });
        return handleAxiosResponse(response);
    },

    del: async (data: DocumentGroup): Promise<boolean> => {
        const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${apiPartsCatalog}/Group/${data.id}`, {
            method: 'DELETE'
        });
        return handleAxiosResponse(response);
    },

    getAll: async (): Promise<DataWithPermissions<DocumentGroup[]>> => {
        const response: AxiosResponse<CPApiResponse<ModulePermissionResponseInterface<DocumentGroup[]>>> = await axios(`${apiPartsCatalog}/GetSubTypes`);
        return handleModulePermissionResponse(response);
    },
    generateReport: async (itemTypeId: number): Promise<Blob> => {        
        const response: AxiosResponse<Blob> = await axios(`${apiPartsCatalog}/GenerateUsageReport?ItemTypeId=${itemTypeId}`);
        if (response.status >= 400) {
            throw response.statusText;
        }
        return response.data;
    },
    getGenerateReportLink: (itemTypeId): string => {
        return `${apiPartsCatalog}/GenerateUsageReport?ItemTypeId=${itemTypeId}`;
    }
};
// Parts Catalog section ends here

