import { AxiosError } from "axios";
import { useContext } from "react";
import rightArrow from "../../assets/arrow-right.svg";
import partsNServies from "../../assets/parts-n-services-request.svg";
import Button from "../Button/Button";
import Notification from "../Notification/Notification";

import { useGet_PartsAndServices } from "../../containers/PlantInformation/DashboardHttpServices";
import useGetNotificationCount from "../../customHooks/useGetNotificationCount";
import { useOnError } from "../../customHooks/useOnError";
import { Store } from "../../Store";
import { PartsAndServicesDTO } from "../../utils/interface";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import { useIsFetching } from "react-query";
import { queryKeys } from "../../react-query/queryKeys";
import HomeCardLoader from "../HomeCardLoader/HomeCardLoader";

// import './SectionCard.css';

function PartsNSerivcesRequest() {

  const setErrorData  = useOnError();
  const {
    partsAndServices: {
      data: { partsQuotesAndOrdersCount, servicesQuotesAndOrdersCount, averagePartsTurnaroundTime, averageServicesTurnaroundTime },
    },
    notificationTypes,
    partsAndServices,
    setPartsAndServices,
    setShowError,
  } = useContext(Store);
  const isFetching = useIsFetching([queryKeys.partsAndServices]);

  // Fetch Plant Details Start

  const onSuccess = (data: PartsAndServicesDTO) => {
    setPartsAndServices && setPartsAndServices(data);
  };
  const onError = (error: AxiosError<string>) => {
    setErrorData(error);
    
  };
  const { isLoading } = useGet_PartsAndServices(onSuccess, onError);
  const navigate = useNavigate();

  // Fetch Plant Details End

  const notificationCount = useGetNotificationCount(notificationTypes[3])
  return (
    <>
      {isFetching > 0 ? (
        <HomeCardLoader showBottom={true} minHeight={"400px"}/>
      ) : (
        <div className="flex flex-col flex-nowrap h-full p-6">
          <div className="flex-1 row">
            <span className="title">
              <b>Quote Requests</b>
            </span>
            <Notification innerSpacingZero={false} leftAlignment={26} value={notificationCount.unread} selectedType={notificationTypes[3]}>
              <div className="">
                <img src={partsNServies} className="report-icon" alt="" />
              </div>
            </Notification>
          </div>
          <div className="flex-1 row center h-6">
            <div className="notify partsNServ">{isLoading ? <Loader containerStyle={{margin: 0}} iconStyle={{width: 18}}/> : partsQuotesAndOrdersCount}</div>
            <div className="row-item text-l">Parts Quote Requests</div>
            <div className="arrow-wrapper">
              <Link to='parts&service/QuoteRequests' state={{ preFilter: "Parts Quote" }}>
              <img src={rightArrow} className="arrow" alt="" />
              </Link>
            </div>
          </div>
          <div className="flex-1 row center h-6">
            <div className="notify partsNServ">{isLoading ? <Loader containerStyle={{margin: 0}} iconStyle={{width: 18}}/> : servicesQuotesAndOrdersCount}</div>
            <div className="row-item text-l">Services Quote Requests</div>
            <div className="arrow-wrapper">
              <Link to='parts&service/QuoteRequests' state={{ preFilter: "Service Quote" }}>
              <img src={rightArrow} className="arrow" alt="" />
              </Link>
            </div>
          </div>
          <div className="flex-1 row">
            <span className="grow bold">Average Parts Turnaround Time</span>
            <span className="bold">{averagePartsTurnaroundTime}d</span>
          </div>
          <div className="flex-1 row">
            <span className="grow bold">Average Service Turnaround Time</span>
            <span className="bold">{averageServicesTurnaroundTime}d</span>
          </div>
          <div className="flex h-full items-end mb-4">
            <span className="grow">
              <Button onClick={() => navigate('/parts&service/QuoteRequests')} className="darkBlue" text="View All Quote Requests" />
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export default PartsNSerivcesRequest;
