import { useContext, useState, useEffect } from "react";
import { Store } from "../../../../Store";
import { ListGroup, RoleModulePermissions } from "../../../../utils/interface";
import Accordion from "../../../../components/Accordian/Accordion";
import { RoleConfigurationApi } from "../../../../customHooks/RoleConfigurationHttpServices";
import { useOnError } from "../../../../customHooks/useOnError";
import ModulePermissions from "./ModulePermissions";
import Button from "../../../../components/Button/Button";
import { useParams } from "react-router-dom";

function RoleConfigurationBody() {
	const {
		showError: error,
		setShowError,
		module: { groups },
	} = useContext(Store);

	const { groupId: roleId } = useParams();

	const [openedPanelIndex, setOpenedPanelIndex] = useState<number>(-1);
	const [currentPanelIndex, setCurrentPanelIndex] = useState<number>(-1);
	const [roleModulePermissions, setRoleModulePermissions] = useState<RoleModulePermissions>();
	const [permissionsSaved, setPermissionsSaved] = useState<boolean>(true);

	const setErrorData = useOnError();

	const queryRoleModulePermissions = async () => roleId && setRoleModulePermissions((await RoleConfigurationApi.query(parseInt(roleId))).data.data);

	useEffect(() => {
		if (!error.isError && groups instanceof Array<ListGroup> && groups?.find((g) => g.name === roleId) && roleModulePermissions?.roleId.toString() !== roleId) {
			(async () => {
				try {
					await queryRoleModulePermissions();
					setCurrentPanelIndex(openedPanelIndex);
				} catch (error) {
					setErrorData(error);
				}
			})();
		}
	}, [roleModulePermissions, openedPanelIndex, roleId, setErrorData, queryRoleModulePermissions, groups, error.isError]);

	useEffect(() => {
		setOpenedPanelIndex(1);
		setCurrentPanelIndex(1);
		// TODO - If we want to add prompt for unsaved changes this is where we would check it. 
		setPermissionsSaved(true);
	}, [roleId]);

	const updateRoleModulePermissions = async () => {
		if (roleModulePermissions) {
			try {
				setRoleModulePermissions((await RoleConfigurationApi.update(roleModulePermissions)).data.data);
				setPermissionsSaved(true);
			} catch (error) {
				setErrorData(error);
			}
		}
	};

	const resetPermissions = async () => {
        await queryRoleModulePermissions();
		setPermissionsSaved(true);
    };

	useEffect(() => {
		setShowError?.({
			title: `${"Please note: any modifications made to roles below will be applied to all Customer Portal users assigned to that role."}`,
			ErrorType: "warning",
			isError: false,
		});
		return () => setShowError?.({ title: "", ErrorType: null, isError: false });
	}, []);

	return (
		<div style={{ flexGrow: 1 }}>
			{roleModulePermissions?.items?.map((modulePermissions, index) => (
				<div key={index}>
					{index > 0 && (
						<Accordion
							isDeleted={false}
							titleContent={`${modulePermissions.parentModuleName}`}
							isOpen={openedPanelIndex === index}
							toggleOpen={() => setOpenedPanelIndex(openedPanelIndex !== index ? index : -1)}
							headerStyle={{ borderRadius: 4, marginTop: index > 1 ? 20 : 0, paddingLeft: 20, fontWeight: "bold" }}
							contentStyle={{}}
						>
							<ModulePermissions
								modulePermissions={modulePermissions}
								roleModulePermissions={roleModulePermissions}
								setRoleModulePermissions={setRoleModulePermissions}
								setPermissionsSaved={setPermissionsSaved}
							/>
						</Accordion>
					)}
				</div>
			))}
			<hr />
			<div style={{ display: "flex", gap: 10 }}>
				<Button className="darkBlue" text={"Save Changes"} disabled={permissionsSaved || !roleModulePermissions} onClick={updateRoleModulePermissions}></Button>
				<Button className="whiteBtn" text={"Cancel"} disabled={permissionsSaved || !roleModulePermissions} onClick={resetPermissions}></Button>
			</div>
		</div>
	);
}

export default RoleConfigurationBody;
