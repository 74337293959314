import React, { ReactElement, useContext } from "react";
import classes from './ListApiMethods.module.css';
import { Outlet, useOutletContext, useParams, Link } from "react-router-dom";
import useWindowSize from "../../customHooks/useWindowSize";
import { AdministrationListModuleOutletInterface } from "./AdministrationListModuleContainer";
import { ListGroup } from "../../utils/interface";
import { Store } from "../../Store";

function AdministrationGroupContainer() {
  const outletContext = useOutletContext<AdministrationListModuleOutletInterface>();
  const { module, module: { groups } } = outletContext;
  const params = useParams();
  const groupId = String(params.groupId || '');

  const { isMobile, isTablet } = useWindowSize();

  let listGroupSelection: ReactElement = <></>;

  if (groups) {
    if (isMobile || isTablet) {
      listGroupSelection = (
        <div className={classes.flex_column}>
          Mobile List filter pending
          {/* <div className="borderDropdown">
            <Dropdown         
              style={{width:'100%'}}  
              handleDropDownSelect={handleGroupType}
              selectedOption={selectedReportType}
              setSelectedOption={setSelectedReportType}
              options={groups}
            />
          </div>           */}
        </div>
      );
    } else {
      listGroupSelection = (
        <div className={`${classes.flex_column} ${classes.module_list}`}>
          {groups instanceof Array<ListGroup> && (groups as Array<ListGroup>).sort((a, b) => !a.order || !b.order ? 1 : a.order > b.order ? 1 : -1 ).map((listGroupItem,i) => (
            <Link
              className={`${classes.document_group_link} ${groupId === listGroupItem.name ? classes.active : ""}`}
              key={i}
              to={`../${listGroupItem.name}`}
            >
              {listGroupItem.title}
            </Link>
          ))}
        </div>
      );
    }
  }

  return (
    <>
      <div className={`${classes.top_section} ${classes.flex_column}`}>{module.moduleHeaderContent}</div>
      <div className={`${classes.line}`} />
      <div className={`${isMobile || isTablet ? classes.flex_column : classes.flex_row} ${classes.module_information}`}>
        {listGroupSelection}
		{module.moduleBodyContainer}
        <Outlet context={outletContext} />
      </div>
    </>
  );
}

export default AdministrationGroupContainer;
