import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../../components/Button/Button";
import ModalGlobal from "../../../../components/UI/Modal/ModalGlobal";
import classes from "./PlantManagement.module.css";

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

function PlantAdded() {
	const navigate = useNavigate();

	const query = useQuery();

	return (
		<ModalGlobal modaltitle={`${query.get("plantName")} Plant Successfully Added`} isModalHeader={true} modalClose={() => navigate("../")} modalSize="md">
			<div style={{ textAlign: "left" }}>
				<p>{`${query.get(
					"plantName"
				)} was successfully added to the global Mitsubishi Power plants list. This plant is now able to be associated to organizations and users. Would you like to view this plant’s details at this time?`}</p>
				<div style={{ marginTop: 40, display: "flex" }} className={classes.flex_row}>
					<Button className={`darkBlue`} disabled={false} text={"View Plant Details"} onClick={() => navigate(`/administration/Plant-Management/plant/${query.get("plantId")}`)} />&nbsp;&nbsp;&nbsp;
					<Button className={`whiteBtn`} disabled={false} text={"Done"} onClick={() => navigate("../")} />
				</div>
			</div>
		</ModalGlobal>
	);
}

export default PlantAdded;
