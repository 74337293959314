import React from "react";

import type { unstable_Blocker as Blocker } from "react-router-dom";
import { useStore } from "../../useStore";
import { CloseAllModal } from "../../utils/CloseAllModal";
import Button from "../Button/Button";
import EmptyModal from "./EmptyModal";

const ConfirmNavigationModal = ({ blocker, isShown }: { blocker: Blocker, isShown: boolean}) => {
    const { setNavigationIsBlocked, setIsModal, setCloseModalIsBlocked, setShowConfirmCloseModal } = useStore();    
    return (
        <>
            <EmptyModal 
                body={(
                    <div className="flex flex-col items-start gap-5 justify-center">
                        <span className="text-center">Are you sure you want to leave this page? You will lose all changes made so far. This cannot be undone.</span>
                    </div>
                )}
                footer={(
                    <div className="flex flex-row justify-center grow">
                        <div className="flex flex-row gap-4 justify-center">
                            <Button className="darkBlue" text="Yes, Leave" onClick={() => {setNavigationIsBlocked?.(false); blocker.proceed?.(); setShowConfirmCloseModal?.(false); setCloseModalIsBlocked?.(false); setIsModal?.({
                ...CloseAllModal,
                });}} />
                            <Button className="whiteBtn" text="Cancel" onClick={() => {blocker.reset?.(); setShowConfirmCloseModal?.(false);}} />
                        </div>
                    </div>
                )}
                heading={`Leave This Page?`}
                headingClass="text-center !gap-0"
                onClose={() => {blocker.reset?.(); setShowConfirmCloseModal?.(false); }} 
                shown={isShown} 
                width={564} 
            />
        </>
    );
}
export default ConfirmNavigationModal;
  