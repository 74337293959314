import { MouseEvent, ReactElement, useCallback, useMemo, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Dropdown from "../Dropdown/Dropdown";
import { ItemList, ItemListProps } from "./ItemList";
import useWindowSize from "../../customHooks/useWindowSize";
import { SelectOptionProps } from "../../utils/interface";

interface TabbedSectionDefinition {
    path: number|string,
    text: string|ReactElement
}

interface TabbedSectionsProps<T> extends ItemListProps<T> {
    sections: TabbedSectionDefinition[];
    sectionsLabel: string;
}

export const TabbedSections = <T extends {id: number}>({sections, sectionsLabel, ...tabSectionProps}: TabbedSectionsProps<T>) => {
    const {isDesktop} = useWindowSize();
    const [selectedSection, setSelectedSection] = useState<string|null>('');
    const navigate = useNavigate();

    const dropdownOptions: SelectOptionProps[] = useMemo(() => {
        return sections.map((s, i) => ({
            id: i,
            name: s.text as string
        }));
    }, [sections]);

    const handleSectionSelection = useCallback((e: MouseEvent<HTMLDivElement>, option: SelectOptionProps) => {
        navigate(`${sections[option.id].path}`);
    }, [sections]);

    return (
        <div className={`flex gap-4 ${isDesktop? 'flex-row' : 'flex-col'}`}>
            {isDesktop? (
                <div className="flex flex-col basis-48">
                    {sections.map((section) => (
                        <NavLink key={section.path}
                                to={`${section.path}`}
                                className={({ isActive }) =>
                                    `h-10 no-underline text-sm font-bold flex flex-start items-center ${isActive ? 'text-black border-solid border-0 border-l-4 border-[#567783] pl-3' : 'text-[#999] pl-4'}`
                                }>
                            {section.text}
                        </NavLink>
                    ))}
                </div>
            ) : (
                <div className={'classes.flex_column'}>
                    {sectionsLabel}
                    <div className="borderDropdown">
                        <Dropdown style={{width:'100%'}}
                                handleDropDownSelect={handleSectionSelection}
                                selectedOption={selectedSection}
                                setSelectedOption={setSelectedSection}
                                options={dropdownOptions} />
                    </div>
                </div>
            )}
            <ItemList {...tabSectionProps} />
        </div>
    );
};

TabbedSections.defaultProps = {
    sectionsLabel: 'Type'
};