import { AxiosError } from "axios";
import moment from "moment";
import React, { useContext, useState, useCallback } from "react";
import { useQueryClient } from "react-query";
import { Store } from "../../Store";
import {
  useDelete_PlantEquipmentAttachment,
  useGetImage_PlantEquipmentAttachment,
  useGet_PlantEquipmentAttachment,
} from "../../containers/PlantInformation/EquipmentAttachmentsHttpServices";
import { useOnError } from "../../customHooks/useOnError";
import { queryKeys } from "../../react-query/queryKeys";
import { CloseAllModal } from "../../utils/CloseAllModal";
import { toBase64 } from "../../utils/FileUtils";
import { IdObj, PlantEquipmentAttachmentDTO } from "../../utils/interface";
import ErrorCard from "../ErrorCard/ErrorCard";
import ModalGlobal from "../UI/Modal/ModalGlobal";
import classes from "./Nameplate.module.css";
import Loader from "../Loader/Loader";
import { supportedFileTypes } from "../../utils/getSupportedFileTypes";
import ImageImports from "../../utils/ImageImports";

const NamePlateDeleteModal = ({ namePlateData, IdObj }: NamePlateDeleteModalProps) => {
  const stateContext = useContext(Store);
  const { isModal, setIsModal, plantEquipmentsAttachment, setPlantEquipmentsAttachment,showError } = stateContext;
  const queryClient = useQueryClient();
  const setErrorData = useOnError();
  const [imgBase64, setImgBase64] = useState("");
  
  //{***************************************************************************************************************************}

  const onSuccessEquipmentsAttachment = (data: PlantEquipmentAttachmentDTO) => {
    setPlantEquipmentsAttachment && setPlantEquipmentsAttachment(data);
  };
  const onErrorEquipmentsAttachment = (error: AxiosError<string>) => {
    setErrorData(error);
  };
  useGet_PlantEquipmentAttachment(IdObj.attachmentid, true, onSuccessEquipmentsAttachment, onErrorEquipmentsAttachment);

  const onSuccess = (data: Blob) => {
    toBase64(data)
      .then((base64Image: string) => setImgBase64(base64Image))
      .catch((err) => setErrorData(err));
  };
  const onError = (error: AxiosError<string>) => {
    setErrorData(error);
  };
  const {isFetching, isLoading} = useGetImage_PlantEquipmentAttachment(IdObj.attachmentid, onSuccess, onError);

  //{***************************************************************************************************************************}

  const { mutate: deleteAttachments } = useDelete_PlantEquipmentAttachment();

  const handleDeleteAttachment = () => {
    deleteAttachments(IdObj, {
      onError: (error: unknown) => {
        setErrorData(error);
      },
      onSuccess: (responseData) => {
        queryClient.invalidateQueries([queryKeys.plantEquipment, IdObj.equipmentid]);
        setIsModal!({
          ...CloseAllModal,
          isDeleteSuccess: {
            ...isModal.isDeleteSuccess,
            isNameplate: true,
          },
        });
      },
    });
  };
  const handleModalClose = () => {
    setIsModal!({
      ...CloseAllModal,
    });
  };

  const PreviewContainer = useCallback(() => {
    const { docTypes, excelTypes, docTypeExtensions, excelTypeExtensions } = supportedFileTypes();
    const { docDummy, excelDummy, pdfDummy } = ImageImports;
    if (!!isFetching || !!isLoading) {
      return <Loader containerStyle={{marginTop:"30px"}} />;
    }
    
    if (plantEquipmentsAttachment) {
      if (plantEquipmentsAttachment.data.fileTypeObj.contentType.includes('image')) {
        return <img src={imgBase64} alt={`${plantEquipmentsAttachment.data.name}`} className="object-contain w-full" onLoad={() => URL.revokeObjectURL(imgBase64 || '')} />;
      }  else if (plantEquipmentsAttachment.data.fileTypeObj.contentType.includes("application")) {
        if (plantEquipmentsAttachment.data.fileTypeObj.contentType.includes("application/pdf")) {
          return <img src={pdfDummy} alt={`${plantEquipmentsAttachment.data.name}`} className="object-contain w-full max-h-[200px]" onLoad={() => URL.revokeObjectURL(pdfDummy || "")} />;
        } else {
          if (docTypes.find(type => type === `${plantEquipmentsAttachment.data.fileTypeObj.contentType}`) || docTypeExtensions.find(type => type === `${plantEquipmentsAttachment.data.fileTypeObj.extension}`)) {
            return <img src={docDummy} alt={`${plantEquipmentsAttachment.data.name}`} className="object-contain w-full max-h-[200px]" onLoad={() => URL.revokeObjectURL(docDummy || "")} />;
          }
          if (excelTypes.find(type => type === `${plantEquipmentsAttachment.data.fileTypeObj.contentType}`) || excelTypeExtensions.find(type => type === `${plantEquipmentsAttachment.data.fileTypeObj.extension}`)) {
            return <img src={excelDummy} alt={`${plantEquipmentsAttachment.data.name}`} className="object-contain w-full max-h-[200px]" onLoad={() => URL.revokeObjectURL(excelDummy || "")} />;
          }
        }
      }
    }

    return (
      <div style={{ textAlign: "center", color: "#3C5D69" }}>
        <p>File preview not available.</p>
      </div>
    );
  }, [ isFetching, isLoading, imgBase64, plantEquipmentsAttachment]);

  let deleteModalContent;

  deleteModalContent = (

    <>
    {showError.isError && <ErrorCard ErrorMessage={showError.title} ErrorType={"danger"} />}
      <div className={classes.deleteModalText}>
        Are you sure you want to delete the attachment “{plantEquipmentsAttachment.data.name}”? This cannot be undone.
      </div>

      <div className={classes.row}>
        <div className={classes.imageWrapperLeft}>
          <PreviewContainer />
        </div>
        <div className={classes.imageWrapperRight}>
          <div className={classes.tbl}>
            <div className={classes.tbl_cell}>File Type</div>
            <div className={classes.tbl_cell}>{plantEquipmentsAttachment.data.fileTypeName}</div>
          </div>
          <div className={classes.tbl}>
            <div className={classes.tbl_cell}>Date Uploaded</div>
            <div className={classes.tbl_cell}>{`${moment(plantEquipmentsAttachment.data.created).format("YYYY-MM-DD")}`}</div>
          </div>
        </div>
      </div>
      <div className="flex align-center">
        <div className="flex-grow-1 flex gap-x-4">
          <button type="button" className="primaryBtn redDelete" onClick={handleDeleteAttachment} disabled={!!isFetching}>
            Yes, Delete
          </button>
          <button onClick={handleModalClose} className="primaryBtn whiteBtn">
            Cancel
          </button>
        </div>
      </div>
    </>
  );

  return (
    <ModalGlobal modaltitle={"Delete Nameplate Asset"} isModalHeader={true}>
      {deleteModalContent}
    </ModalGlobal>
  );
};

interface NamePlateDeleteModalProps {
  IdObj: IdObj;
  namePlateData: {
    equipmentAttachmentId: number;
    name: string;
    fileTypeName: string;
    description: string;
    attachments: string;
    fileSize: string;
    ext: string;
  };
}

export default NamePlateDeleteModal;
