function NotificationHeader() {
    
  return (
    <div style={{justifyContent:"space-between"}} className={`flex flex-row gap-6 `}>
      <div className={`flex flex-col gap-6`}>
        {/* <div className={headerClasses.header_title}>{selectedPlant.name} Bulletins</div> */}
        <div className={'font-bold text-2xl'}>Email Alert & Notifications Management</div>
        <div className={'gap-6 text-[font-size:var(--fs14px)]'}>
          
Manage in-app notifications and email alert settings for all Customer Portal users.
        </div>     
      </div>      
    </div>
  );
}
 
export default NotificationHeader;
