import { MouseEvent, useCallback, useMemo } from "react";
import Moment from "react-moment";
import { useQueryClient } from "react-query";
import { queryKeys } from "../../react-query/queryKeys";
import { Notification } from "../../utils/interface";
import { useDismissNotifications } from "./NotificationHttpServices";

interface NotificationItemProps {
    notification: Notification;
}

export const NotificationItem = ({notification}: NotificationItemProps) => {
    const queryClient = useQueryClient();

    const {mutate: dismissNotifications} = useDismissNotifications({
        onSuccess: () => {
            queryClient.invalidateQueries(queryKeys.header);
        }
    });

    const dismiss = useCallback((e: MouseEvent) => {
        dismissNotifications([notification.notificationId]);
        e.preventDefault();
    }, []);

    const url = useMemo(() => {
        try {
            const url = new URL(notification.ctaLink);
            url.searchParams.append('notificationId', `${notification.notificationId}`);
            return url.href;
        } catch (e) {}
        return notification.ctaLink;
    }, [notification]);

    return (
        <div className={`flex col h-auto text-xs border-b-2 border-[#E6E6E6] justify-between ${notification.read? '' : 'bg-ocean/5'}`}>
            <div className="px-4 text-gray-600 font-md text-xs py-2">
                <Moment date={notification.date} format="MM/DD/YYYY" />
            </div>
            <div className="flex font-md text-sm px-4">
                <span dangerouslySetInnerHTML={{ __html: notification.body}}></span>
            </div>
            <div className="flex row justify-between text-dkbluebutton text-sm px-4 py-2">
                <a className="font-bold no-underline text-dark-blue" href={url} target="_blank" rel="noreferrer">View</a>
                <a href="#/dismiss" className="font-bold no-underline text-dark-blue" onClick={dismiss}>Dismiss</a>
            </div>
        </div>
    );
}