import React from 'react';
import TomoniImg from '../../assets/TOMONI-tile-img.svg';
import TakasagoImg from '../../assets/TGO.svg';
import userGroupImg from '../../assets/User_Group_Portal.svg';
import rightArrow from '../../assets/right-caret.svg';
import  "./ResourcesTile.css";
import { Link } from 'react-router-dom';
import { userGroupPortalURL } from '../../endpoints/endpoints';


const supportData = {
  tomoni: { 
    title: "TOMONI Dashboard",
    img: TomoniImg,
    paragraph: "Access to Plant Operational Information, Current Turbine status, and System trends", 
    linkTitle: "View TOMONI Dashboard",
    link: "https://dashboard.amermhi.com/"
    },
  takasago: { 
    title: "Takasago Technical Bulletin Website (TBW)",
    img: TakasagoImg,
    paragraph: "Gas turbine and gas turbine generator Technical Bulletins on the Technical Bulletin website", 
    linkTitle: "Go to Takasago Technical Bulletin Website (TBW)",
    link: "https://www.gtnews.mhi.co.jp/en/"
    },
  userGroup: { 
    title: "Users' Group Portal",
    img: userGroupImg,
    paragraph: "Users' Group Portal for customers with Mitsubishi Power Equipment", 
    linkTitle: "Go to Users' Group Portal",
    link: userGroupPortalURL
    },
}

interface resourcesTileProps {
  id: keyof typeof supportData
};

function ResourcesTile(props: resourcesTileProps) {

  return (
    <div className='column padd24'>
    <div className=''>
        <img src={supportData[props.id].img} className="tile-img" alt="" />
    </div>
    <div className='tile-header'>{supportData[props.id].title}</div>
    <div className='font14 tile-paragraph'>
      {supportData[props.id].paragraph}
    </div>
    <a href={supportData[props.id].link} className="no-underline" target="_blank">
      <div className='tile-nav'>
     {supportData[props.id].linkTitle} <img className='right__arrow_link' src={rightArrow} alt="arrow right" />
    </div></a>
  </div>
  );
}

export default ResourcesTile;
