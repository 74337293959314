import React, { useEffect, useState } from "react";
import { QuoteRequestEvents } from "../../utils/interface";
import { QuoteRequestEventID } from "../../utils/moduleIDs";

const useIsEventClosed = (quoteRequestEvents: QuoteRequestEvents[] | null): boolean => {
  const [isEventClosed, setIsEventClosed] = useState<boolean>(false);
  useEffect(() => {
    if (quoteRequestEvents) {
      const isEventPresent = quoteRequestEvents
        .filter((e) => (e ? e : null))
        .map((e) => e.eventTypeId)
        .indexOf(QuoteRequestEventID.QuoteClosed);
      if (isEventPresent === -1) {
        setIsEventClosed(false);
      } else {
        setIsEventClosed(true);
      }
    }

    // console.log("quoteRequestEvents",quoteRequestList);
  }, [quoteRequestEvents]);

  return isEventClosed;
};

export default useIsEventClosed;
