import { AxiosError } from "axios";
import { useState } from "react";
import { useAvatar_PlantContact } from "../../../containers/PlantInformation/PlantContactHttpServices";
import { usePermissions } from "../../../customHooks/useUserPermissions";
import useWindowSize from "../../../customHooks/useWindowSize";
import { toBase64 } from "../../../utils/FileUtils";
import ImageImports from "../../../utils/ImageImports";
import { PlantContact } from "../../../utils/interface";
import { phoneNumberFormatter } from "../../../utils/phoneNumberFormatter";

import classes from "./SiteContact.module.css";

const SiteContact = ({ contactList, handleOpenEditContact }: SiteContactProps) => {
  const { pencil } = ImageImports;
  const { isDesktop } = useWindowSize();
  const [base64Image, setBase64Image] = useState("");
  const { hasPermission } = usePermissions();

  const onSuccess = (data: any) => {
    toBase64(data)
      .then((base64Image: any) => {
        setBase64Image(base64Image);
      })
      .catch((error) => {
        setBase64Image("");
      });
  };
  const onError = (error: AxiosError<string>) => {
    setBase64Image("");
  };
  useAvatar_PlantContact(contactList.avatarSize > 0, contactList.plantContactId, onSuccess, onError);
  return (
    <>
      {isDesktop && (
        <div className={classes.panel_tbl}>
          <div className={classes.panel_tbl_cell}>
            <div className={classes.profiledetails}>
              <div className={classes.avatarWrap} style={{ marginRight: "15px" }}>
                {base64Image.length > 0 ? (
                  <img style={{ maxWidth: "100%" }} src={base64Image} alt={contactList.firstName} />
                ) : (
                  <span className={classes.avatarDefault}>
                    {contactList.firstName.slice(0, 1)}
                    {contactList.lastName.slice(0, 1)}
                  </span>
                )}
              </div>
              <div>
                <div className={classes.contactname}>
                  <strong>
                    {contactList.firstName} {contactList.lastName}
                  </strong>
                </div>
                <div className={classes.jobtitle}>{contactList.companyName}</div>
                <div className={classes.jobtitle}>{contactList.jobTitle}</div>
              </div>
            </div>
          </div>
          <div className={classes.panel_tbl_cell}>
            <div>
              {contactList.primaryPhone && (
                <>
                  <strong>{contactList.primaryPhoneType}: </strong>
                  {contactList.primaryPhone && phoneNumberFormatter(contactList.primaryPhone)}
                </>
              )}
            </div>
            <div>
              {contactList.secondaryPhone && (
                <>
                  <strong>{contactList.secondaryPhoneType}: </strong> {contactList.secondaryPhone && phoneNumberFormatter(contactList.secondaryPhone)}
                </>
              )}
            </div>
          </div>
          <div className={classes.panel_tbl_cell}>
            <div>
              {" "}
              <strong>Email: </strong>
              {contactList.email}
            </div>
          </div>
          {hasPermission(contactList.isMitsubishiContact? 'MitsubishiPowerContacts' : 'CustomerContacts', 'edit') && (
            <div className={classes.panel_tbl_cell} style={{ flexBasis: "2%" }}>
              <img src={pencil} style={{ cursor: "pointer" }} onClick={() => handleOpenEditContact(contactList.plantContactId)} alt="" />
            </div>
          )}
        </div>
      )}
      {!isDesktop && (
        <div className={classes.panel_tbl}>
          <div className={classes.avatarcol}>
            <div className={classes.avatarWrap} style={{ marginRight: "15px" }}>
              {base64Image.length > 0 ? (
                <img style={{ maxWidth: "100%" }} src={base64Image} alt={contactList.firstName} />
              ) : (
                <span className={classes.avatarDefault}>
                  {contactList.firstName.slice(0, 1)}
                  {contactList.lastName.slice(0, 1)}
                </span>
              )}
            </div>
          </div>
          <div>
            <div className={classes.panel_tbl_cell}>
              <div className={classes.profiledetails}>
                <div>
                  <div className={classes.contactname}>
                    <strong>
                      {contactList.firstName} {contactList.lastName}
                    </strong>
                  </div>
                  <div className={classes.jobtitle}>{contactList.jobTitle}</div>
                </div>
              </div>
            </div>
            <div className={classes.panel_tbl_cell}>
              <div>
                {contactList.primaryPhone && (
                  <>
                    <strong>{contactList.primaryPhoneType}: </strong>
                    {contactList.primaryPhone && phoneNumberFormatter(contactList.primaryPhone)}
                  </>
                )}
              </div>
              <div>
                {contactList.secondaryPhone && (
                  <>
                    <strong>{contactList.secondaryPhoneType}: </strong>{" "}
                    {contactList.secondaryPhone && phoneNumberFormatter(contactList.secondaryPhone)}
                  </>
                )}
              </div>
            </div>
            <div className={classes.panel_tbl_cell}>
              <div>
                {" "}
                <strong>Email: </strong>
                {contactList.email}
              </div>
            </div>
            {hasPermission(contactList.isMitsubishiContact? 'MitsubishiPowerContacts' : 'CustomerContacts', 'edit') && (
              <div className={classes.editAction} style={{ flexBasis: "2%" }}>
                <img src={pencil} style={{ cursor: "pointer" }} onClick={() => handleOpenEditContact(contactList.plantContactId)} alt="" />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

interface SiteContactProps {
  contactList: PlantContact;
  handleOpenEditContact(id: number): void;
}

export default SiteContact;
