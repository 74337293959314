import { ModulePermissions, RoleModulePermissions, ModulePermission } from "../../../../utils/interface";
import tableClasses from "../../../../components/Tables/DisplayLikeTable/DisplayLikeTable.module.css";
import classes from "./RoleConfiguration.module.css";

function ModulePermissionsPanel({
	modulePermissions,
	roleModulePermissions,
	setRoleModulePermissions,
	setPermissionsSaved,
}: {
	modulePermissions: ModulePermissions;
	roleModulePermissions: RoleModulePermissions;
	setRoleModulePermissions: React.Dispatch<React.SetStateAction<RoleModulePermissions | undefined>>;
	setPermissionsSaved: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const handleChange = (newPermissionValue: boolean, permissionProperty: string, updatedModulePermission: ModulePermission) => {
		setRoleModulePermissions({
			...roleModulePermissions,
			items: roleModulePermissions.items.map((modulePermissions) => ({
				...modulePermissions,
				permissions: modulePermissions.permissions.map((modulePermission: any) => ({
					...modulePermission,
					[permissionProperty]: modulePermission.moduleId === updatedModulePermission.moduleId ? newPermissionValue : modulePermission[permissionProperty],
				})),
			})),
		});
		setPermissionsSaved(false);
	};

	return (
		<div className={`${tableClasses.table} ${classes.modulePermissions}`}>
			<div>
				<div>
					<div>Sub-Module</div>
					<div>Can Admin</div>
					<div>Can Add</div>
					<div>Can Edit</div>
					<div>Can Delete</div>
					<div>Can View</div>
				</div>
			</div>
			<div>
				{modulePermissions.permissions.map((modulePermission, index) => (
					<div key={index}>
						<div>{modulePermission.moduleName || <span>ID: {modulePermission.moduleId} (nameless)</span>}</div>
						<div>
							<input className="cursor-pointer" type="checkbox" checked={modulePermission.canAdmin} onChange={(e) => handleChange(e.target.checked, "canAdmin", modulePermission)} />
						</div>
						<div>
							<input className="cursor-pointer" type="checkbox" checked={modulePermission.canAdd} onChange={(e) => handleChange(e.target.checked, "canAdd", modulePermission)} />
						</div>
						<div>
							<input className="cursor-pointer" type="checkbox" checked={modulePermission.canEdit} onChange={(e) => handleChange(e.target.checked, "canEdit", modulePermission)} />
						</div>
						<div>
							<input className="cursor-pointer" type="checkbox" checked={modulePermission.canDelete} onChange={(e) => handleChange(e.target.checked, "canDelete", modulePermission)} />
						</div>
						<div>
							<input className="cursor-pointer" type="checkbox" checked={modulePermission.canView} onChange={(e) => handleChange(e.target.checked, "canView", modulePermission)} />
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default ModulePermissionsPanel;
