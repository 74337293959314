import { ErrorMessage, Field, useField } from "formik";
import { useContext } from "react";
import { Store } from "../../../../Store";
import ImageImports from "../../../../utils/ImageImports";
import MiniTooltip from "../../MiniTooltip/MiniTooltip";

import classes from "./TextAreaField.module.css";

const TextAreaField = (props: TextAreaFieldInterface) => {
  const [field, meta] = useField(props);
  const stateContext = useContext(Store);
  const { tooltip } = ImageImports;
  return (
    <>
      {props.display === "block" && (
        <div className="input-field flex align-baseline marginBot30 row-to-col">          
          <label htmlFor={props.name} className="flex align-center marginBot8">
          {props.labelName}
          {props.tooltip?.text.length && (
            <div className={classes.cursor_pointer}>
              <MiniTooltip text={props.tooltip.text} space={props.tooltip.space} placement={props.tooltip.placement}>
                <img src={tooltip} className={classes.tooltip_icon} alt="tool tip" />
              </MiniTooltip>
            </div>
          )}
        </label>
        </div>
      )}

      <div className={`${classes.form_field_row}`}>
        {props.display === "inline" && (
          <label htmlFor={props.name} className={classes.input_label}>
          {props.labelName}
          {props.tooltip?.text.length && (
            <div className={classes.cursor_pointer}>
              <MiniTooltip text={props.tooltip.text} space={props.tooltip.space} placement={props.tooltip.placement}>
                <img src={tooltip} className={classes.tooltip_icon} alt="tool tip" />
              </MiniTooltip>
            </div>
          )}
        </label>
        )}
        <div className={`${classes.form_group}`}>
          <Field
            id={props.name}
            as="textarea"
            className={meta.touched && meta.error ? `${classes.input} ${classes.errorField} ${classes.error_text}` : `${classes.input}`}
            placeholder={props.placeholder}
            name={props.name}
            autoComplete="off"
            style={{ height: props.height }}
          ></Field>
          {/* <ErrorMessage name={props.name}>
            {(msg) => <div className={classes.error_text}>{msg}</div>}
          </ErrorMessage> */}
        </div>
      </div>
    </>
  );
};

interface TextAreaFieldInterface {
  name: string;
  labelName: string;
  placeholder?: string; 
  className: string | "";
  display: "inline" | "block";
  height?: number;
  tooltip?:{   
    placement?: "bottom" | "top" | "right" | "left";
    isDisabled?: 0 | 1;
    space?:number;
    text:string;
  };
}

TextAreaField.defaultProps = { 
  type: "text",
};
export default TextAreaField;
