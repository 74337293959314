import { FunctionComponent, ReactElement, useContext, useId, useState } from "react";
import Moment from "react-moment";
import { toast } from "react-toastify";
import { Store } from "../../Store";
import TextAvatar from "../../components/Avatar/TextAvatar";
import Button from "../../components/Button/Button";
import Loader from "../../components/Loader/Loader";
import { DownloadMultipleFilesModal } from "../../components/Modals/DownloadMultipleFilesModal";
import BulkActionTooltip, { tooltipActionItem } from "../../components/PopoutTooltip/BulkActionTooltip";
import ModalGlobal from "../../components/UI/Modal/ModalGlobal";
import { downloadMessageAttachmentByFileId } from "../../customHooks/FileHttpServices";
import { useGetUser } from "../../customHooks/UserHttpServices";
import useMessageAttachmentFileViewer from "../../customHooks/useMessageAttachmentFileViewer";
import { useOnError } from "../../customHooks/useOnError";
import { useThreads } from "../../customHooks/useThreads";
import { usePermissions } from "../../customHooks/useUserPermissions";
import { apiMessageAttachment } from "../../endpoints/endpoints";
import ImageImports from "../../utils/ImageImports";
import { DiscussionItem, Permissions, ThreadMessage } from "../../utils/interface";
import classes from "./DiscussionBoard.module.css";

const MessageContainer: FunctionComponent<{
  discussionItem: DiscussionItem;
  message: ThreadMessage;
  unread: boolean;
  pagePermissions: Permissions;
  fetchMessages: () => Promise<void>;
}> = ({ discussionItem, message, unread, fetchMessages, pagePermissions }) => {
  const { header: {data: {user}} } = useContext(Store);
  const { deleteThreadMessage } = useThreads();
  const { ellipsis, download, copy_link, blueTick: blue_tick, paperclip_blue, trash } = ImageImports;
  const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
  const [allVersionsDownload, setAllVersionsDownload] = useState<number>();
  const [modal, setModal] = useState<ReactElement>();
  const { handleFileView, isLoading } = useMessageAttachmentFileViewer();
  const setErrorData = useOnError();
  const link = `${window.location.origin}/shared-document/${message?.messageAttachments?.[0]?.id}/${encodeURIComponent(`${apiMessageAttachment}/Download/${message?.messageAttachments?.[0]?.id}`)}`;
  const { hasOnePermission } = usePermissions();
  const handleCloseDeletedModal = () => {
    setModal(undefined);
    fetchMessages();
  };

  const tooltipId = useId().replaceAll(":", "_");

  const modals = {
    copy_link: (
      <ModalGlobal modalSize="sm" modalClass="!max-w-[500px]" titleClassName="centeredText" modaltitle={`Copy Link`} isModalHeader={true} useFallbackInitialFocus={true} modalClose={() => setModal(undefined)}>
        <div className="flex flex-row gap-2 justify-between items-center">
          <input type="text" className="w-2/3 px-[13px] py-[16px] text-black" disabled defaultValue={link} />
          &nbsp;
          <Button
            className={`primaryBtn darkBlue hover:bg-dark-blue-30 focus:bg-dark-blue-30 active:bg-black whitespace-nowrap`}
            text="Copy Link"
            preImg={
              <img
                src={copy_link}
                alt=""
                style={{ filter: "invert(100%) sepia(100%) saturate(2%) hue-rotate(357deg) brightness(106%) contrast(101%)" }}
              />
            }
            onClick={() => {
              navigator.clipboard.writeText(link);
              toast("Link successfully copied");
              setTimeout(() => setModal(undefined), 2000);
            }}
          />
          {false && (
            <div style={{ fontSize: "14px", color: "#00749E", display: "flex", alignItems: "center", textAlign: "left", marginTop: "16px" }}>
              <img src={blue_tick} alt="" /> Link successfully copied
            </div>
          )}
        </div>
      </ModalGlobal>
    ),
    delete: (
      <ModalGlobal modalSize="sm" modaltitle={`Delete Documents`} isModalHeader={true} modalClose={() => setModal(undefined)}>
        <div style={{ textAlign: "justify" }}>
          <p>
            {`Are you sure you want to delete this post from the discussion for ${discussionItem.topic} #${discussionItem.id}: ${discussionItem.description}? This will also delete any attached documents. This cannot be undone.`}
          </p>
          <div style={{ color: "#666666", marginTop: 20 }}>Message from {message.createdBy}</div>
          <div style={{ marginTop: 40 }} className={classes.flex_row}>
            <Button
              className={`redDelete`}
              disabled={false}
              text={"Yes, Delete"}
              onClick={async () => {
                const success = await deleteThreadMessage(message.id);
                if (success) {
                  setModal(
                    <ModalGlobal modaltitle={`Post Successfully Deleted`} isModalHeader={true} modalClose={handleCloseDeletedModal} modalSize="sm" titleClassName="centeredText">
                      <div>
                        <p>{`You successfully deleted the post from the discussion for ${discussionItem.topic} #${discussionItem.id}: ${discussionItem.description}.`}</p>
                        <div style={{ marginTop: 40, display: "flex", justifyContent: "center" }}>
                          <Button className={`darkBlue`} disabled={false} text={"Done"} onClick={handleCloseDeletedModal} />
                        </div>
                      </div>
                    </ModalGlobal>
                  );
                }
              }}
            />
            &nbsp;&nbsp;&nbsp;
            <Button className={`whiteBtn`} disabled={false} text={"Cancel"} onClick={() => setModal(undefined)} />
          </div>
        </div>
      </ModalGlobal>
    ),
  };

  const tooltipActions: tooltipActionItem[] = [];
  if (message.messageAttachments?.length) {
    tooltipActions.push({
      text: "Download",
      img: download,
      async onClick() {
        try {
          const fileIds: number[] = message?.messageAttachments?.map((d) => d.id) || [];
          if (fileIds.length <= 0) return;
          setAllVersionsDownload(fileIds.length);
          await downloadMessageAttachmentByFileId(fileIds!).then((message) => {setAllVersionsDownload(undefined);});
        } catch (error) {
          setAllVersionsDownload(undefined);
          setErrorData(error);
        }
      },
    });
    tooltipActions.push({
      text: "Copy Link",
      img: copy_link,
      onClick: () => setModal(modals.copy_link),
    });
  }

  // Rules for deleteing a message
  //  The user is Portal Admin, or
  //  The user CaneDelete and is looking at a message they themselves created, or
  //  The user CanAdmin
  if ((hasOnePermission('DiscussionMessages', ['admin']) || (hasOnePermission('DiscussionMessages', ['delete']) && user.userId === message.createdById)) && !message.deleted && !message.isParent) {
    tooltipActions.push({
      text: "Delete Post",
      img: trash,
      onClick: () => setModal(modals.delete),
    });
  }

  const {isFetching: isFetchingCreatedUser, data: createdUser} = useGetUser(message.createdById || 0);

  return (
    <>
      {allVersionsDownload !== undefined && allVersionsDownload > 0 && <DownloadMultipleFilesModal type="Discussion" fileCount={allVersionsDownload} />}

      {message.isStatusMessage ? (
        message.created && (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <hr />
            <div style={{ position: "absolute", backgroundColor: "white", padding: 10 }}>
              {discussionItem.topic} {message.title === "Open" ? "Open" : "Closed"}
              {" on "}
              {new Date(message.created).toLocaleString("en-us", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </div>
          </div>
        )
      ) : (
        <div 
          className={`flex flex-col text-black gap-6 p-6 pt-[16px] mb-[10px] min-h-[112px] w-full !border border-solid border-gray-10 rounded ${message.deleted ? 'bg-[#f2f2f2]' : 'bg-transparent'} `}
          style={message.isParent ? { background: "rgba(0, 116, 158, 0.05)", border: "1px solid #00749E" } : { background: "" }}
        >
          {modal}
          {isLoading || isFetchingCreatedUser ? (
            <Loader containerStyle={{ marginTop: "10px", marginBottom: "0px" }} />
          ) : (
            <>
              <div className="flex flex-row items-start md:items-center justify-between w-full !pb-0 flex-nowrap">
                <div className="flex flex-row items-start md:items-center justify-start gap-3 !pb-0">
                  <div className="flex flex-row gap-3 items-center !pb-0 flex-nowrap">
                    <TextAvatar userName={message.createdBy} className="w-[30px] h-[30px] text-[9.6px]" />
                    <div className="text-black text-sm md:text-base whitespace-nowrap">{message.createdBy}</div>
                  </div>
                  <div className="flex flex-col md:flex-row gap-3 items-start md:items-center grow !pb-0">
                    {createdUser && createdUser.accountDisabled &&
                      <span className="text-gray-50">(Deactivated)</span>
                    }
                    <span className="w-[5px] h-[5px] bg-center bg-gray-30 rounded-full border-none hidden md:inline-block" />
                    <Moment className="text-gray-50 text-sm whitespace-nowrap" date={message.created} format="MM/DD/YYYY , h:mm A" />
                    {message.deleted && 
                      <>
                        <span className="w-[5px] h-[5px] bg-center bg-gray-30 rounded-full border-none hidden md:inline-block" />
                        <span className="text-gray-50">Deleted</span>
                      </>
                    }
                  </div>
                </div>
                <div className="flex flex-row items-start mt-1 md:mt-0 md:items-center justify-end grow-0">
                  {( tooltipActions.length > 0) && (
                    <div id={tooltipId} className="flex flex-row justify-end items-start grow-0 hover:cursor-pointer" onClick={(e) => setShowContextMenu(!showContextMenu)}>
                      <img src={ellipsis} alt="More Actions" />
                      <BulkActionTooltip
                        isDeleted={message.deleted !== null} shown={showContextMenu} setShown={setShowContextMenu} parentId={tooltipId} listItems={tooltipActions} data={message} displayRight={true} 
                      />
                    </div>
                  )}
                </div>
              </div> 
              <div className="mx-0 md:mx-8 !text-black text-sm" dangerouslySetInnerHTML={{ __html: message.content }}/>
              
              {message.messageAttachments?.map((attachment, index) => (
                <div key={`message-id-${message?.id}`} className="flex flex-row items-center gap-3 flex-nowrap">
                  <img className="hover:cursor-pointer" src={paperclip_blue} alt="" onClick={() => handleFileView(attachment.id)} />
                  <div className="text-ocean hover:cursor-pointer text-sm" onClick={() => handleFileView(attachment.id)}>
                    {attachment.originalFileName}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default MessageContainer;
