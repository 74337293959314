
import './ToolTip.css';
import ImageImports from '../../utils/ImageImports';
import { useStore } from '../../useStore';
import { FocusEventHandler } from 'react';

interface ToolTipProps{
  headerHeight?:number;
}
function ToolTip({headerHeight}:ToolTipProps) {
  const {toolTip, toggleTooltip, toolTipContent} = useStore();
  const {closeLg} = ImageImports;
 
  const blur: FocusEventHandler<HTMLDivElement> = (e) => {
    if (e.relatedTarget === null || !e.relatedTarget.closest('.tooltip-wrapper')) {
      toggleTooltip?.(false);
    }
  };

  if(toolTip) {
    return (
      <div className='tooltip-wrapper'
          tabIndex={1}
          onMouseEnter={(e) => {
            e.currentTarget.focus({preventScroll: true});
            toggleTooltip && toggleTooltip(true);
          }}
          onBlur={blur}>
        <div className={toolTip ? "tooltip active" : "tooltip"}>
          <nav  className={toolTip ? "tooltip-menu active" : "tooltip-menu"}>
            <div className='tooltip-menu-items'>

                <div className={toolTip ? "active close" : "close"}
                onClick={() => toggleTooltip && toggleTooltip(!toolTip)}>
                    <img src={closeLg}  alt='close icon' />
                </div>

              <div className={"tooltip-content-wrapper"}>
                {toolTipContent}
              </div>
            </div>
          </nav>
        </div>
      </div>
    );
  }

  return null;
}

export default ToolTip;