import React from 'react'
import { Link } from 'react-router-dom'
import style from '../ErrorPage/ErrorPage.module.css'

const ErrorPage = () => {
  return (
    <div className='column flex-grow-1 errorPage'  >
        <div className='content'>
        <div   className={`login-header ${style.margin_bottom}`}  >Error 404 - Page Not Found</div>
        <p>Unfortunately, the requested URL was not found. Please try again later.</p>
        <div className={style.button_wrapper}>
        <Link to="/" className="primaryBtn darkBlue">Return to Home Page</Link>
        </div>
        </div>
    </div>
  )
}

export default ErrorPage