import React from "react";
import { KPI } from "../../../utils/interface";
import useWindowSize from "../../../customHooks/useWindowSize";
import ImageImports from "../../../utils/ImageImports";
import classes from "./ESR.module.css";

const KPIsContainer = ({ KPI }: { KPI?: KPI }) => {
	const { isMobile } = useWindowSize();
	const { help } = ImageImports;
	return (
		<div className={`${classes.KPIsContainer}`} style={{ whiteSpace: isMobile ? "normal" : "nowrap" }}>
			<div className="flex items-center">
				Average Turnaround Time <img src={help} className="relative mr-4 ml-1 w-4" alt="Tooltip - Average Turnaround Time"  title="The KPIs are based on a ROLLING 12 months’ worth of data."/> {KPI?.avgTurnAroundTime}
			</div>
			<div className="flex items-center">
				Requests Delivered by Promise Date <img src={help} className="relative mr-4 ml-1 w-4" alt="Tooltip - Requests Delivered by Promise Date" title="The KPIs are based on a ROLLING 12 months’ worth of data." /> {KPI?.requestsDeliveredOnTime || 0} of {KPI?.numberOfRequests || 0} ESRs (
				{KPI?.numberOfRequests && KPI?.numberOfRequests > 0 ? Math.round(((KPI?.requestsDeliveredOnTime || 0) / (KPI?.numberOfRequests || 0)) * 100) : 0}%)
			</div>
		</div>
	);
};

export default KPIsContainer;
