
import React, {useState, ReactElement, useEffect} from 'react';
import classes from "./ManageDocumentGroups.module.css";
import EmptyModal from '../../../components/Modals/EmptyModal';
import Button from '../../../components/Button/Button';
import useWindowSize from '../../../customHooks/useWindowSize';
import { useOutletContext } from 'react-router-dom';
import { TechnicalDocumentationModuleOutletInterface } from '../../TechnicalDocumentation/TechnicalDocumentationModuleContainer';
import Alert from '../../../components/Alert/Alert';

interface AddNewDocumentGroupModalProps {
    shown: boolean;
    onCreate: (name: string) => void;
    onClose: () => void;
    error: boolean;
}

function AddNewDocumentGroupModal(props: AddNewDocumentGroupModalProps) {
    const [documentName, setDocumentName] = useState<string>('');
    const {isMobile} = useWindowSize();
    const {documentModule} = useOutletContext<TechnicalDocumentationModuleOutletInterface>();

    useEffect(() => {
        setDocumentName('');
    }, [props.shown]);

    let width: number|undefined;
    if (isMobile) {
        width = 300;
    }

    let modalBody: ReactElement|undefined;
    if (props.shown) {
        modalBody = (
            <>
                <form onSubmit={e => {
                    e.preventDefault();
                    props.onCreate(documentName)
                }} className="flex flex-col gap-12">
                    {props.error? <Alert onClose={() => {}} type="error" dismissible={false}><>The document group cannot be added because the group name already exists within {documentModule.name}. Please rename the document group and try again.</></Alert> : <></>}
                    <span className={``}>Please Note: Once a document group is created, users with the accepted permissions will be permitted to add documents to this group for customers. This group will be visible and accessible to all customers in the Customer Portal on the {documentModule.name} page.</span>
                    <div className={`${classes.modal_body_input_group} ${classes.flex_row}`}>
                        <span>Document Group Name*</span>
                        <div className={`${classes.modal_body_input_container} ${classes.flex_column}`}>
                            <input type="text" required className={classes.modal_body_input} placeholder="ex. Repair Reports" value={documentName} onChange={e => setDocumentName(e.target.value)} />
                        </div>
                    </div>
                    <div className="flex flex-row justify-between items-center self-stretch mt-6">
                        <div className={`${classes.modal_button_group} ${classes.flex_row}`}>
                            <button className="darkBlue primaryBtn" type="submit" disabled={!(documentName.length > 0)}>Add Document Group</button>
                            <Button className={`whiteBtn`} text="Cancel" onClick={props.onClose} />
                        </div>
                        <span className={classes.modal_footer_required_fields}>*Required Fields</span>
                    </div>
                </form>
            </>
        );
    }

    return (
            <EmptyModal shown={props.shown} body={modalBody} footer="" heading="Add New Document Group" width={width} onClose={props.onClose} />
    );
}

export default AddNewDocumentGroupModal;