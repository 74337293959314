import { generatePath, Outlet, useParams } from 'react-router-dom';
import classes from './TechnicalDocumentation.module.css';
import Link from '../../components/Link/Link';
import RelatedLinks from '../../components/RelatedLinks/RelatedLinks';
import Footer from '../../components/Footer/Footer';
import useWindowSize from '../../customHooks/useWindowSize';
import { CrudMethods, PartsCatalog } from './documentGroupApiMethods';
import PartsHeader from './ModuleSpecifics/PartsHeader';
import AddPartsCatalog from './ModuleSpecifics/AddPartsCatalog';
import { PartsCatalog as apiPartsCatalog, DocumentModuleApiMethods } from './documentApiMethods';
import React, { useContext, useEffect, useState } from 'react';
import { DataWithPermissions, DocumentGroup, DocumentGroups, Permissions, Report, SiteUnit } from '../../utils/interface';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { Store } from '../../Store';
import { useOnError } from '../../customHooks/useOnError';
import ErrorCard from '../../components/ErrorCard/ErrorCard';
import usePlantSiteDetailsApiCalls from '../../components/PlantSiteDetails/usePlantSiteDetailsApiCalls';
import { ModuleIDs } from '../../utils/moduleIDs';
import { useBaseOrgIdHeader } from '../../customHooks/useBaseOrgIdHeader';


const documentModules: DocumentModuleInterface[] = [
    {
        id: ModuleIDs.PartsCatalog,
        name: 'Parts Catalog',
        addEditHeaderName: 'Mitsubishi Power Parts Catalog',
        ModuleHeaderContent: PartsHeader,
        addDocumentModalBodyContent: AddPartsCatalog,
        documentGroupApi: PartsCatalog,
        documentApi: apiPartsCatalog,
        addModalHeaderContent: [],
        relatedData: {
            relatedTitle: "Related Parts & Service Information",
            relatedSections: [
                {
                    title: "Quote Requests",
                    description: "Click here to view open Quote Requests or to create new requests.",
                    buttonLink: "View Quote Requests",
                    route: generatePath('/parts&service/:moduleName', { moduleName: 'QuoteRequests' }),

                },
                {
                    title: "Technical Documents",
                    description: "Click below for Technical Documents related to your Plant and Equipment and a link to the Technical Bulletin Web.",
                    buttonLink: "View Technical Documents",
                    route: generatePath('/technical-documentation/:moduleName', { moduleName: 'Technical Bulletins' }),
                },
                {
                    title: "Operation and Maintenance Manuals",
                    description: "Click below for Operation and Maintenance Manuals related to your Plant, Unit and Equipment.",
                    buttonLink: "View Manuals",
                    route: generatePath('/technical-documentation/:moduleName', { moduleName: 'O&M Manuals' }),
                }
            ]
        }
    },
];

export interface AddDocumentRequiredProps {
    onSave: (data: any) => void;
    documentGroups: DocumentGroup[];
    siteUnits: SiteUnit[];
    documentModule?: DocumentModuleInterface;
    isEdit: boolean;
}

export interface DocumentModuleInterface {
    name: string;
    id: number;
    addEditHeaderName: string;
    addModalHeaderContent: string[];
    ModuleHeaderContent: React.FunctionComponent<{groupPermissions: Permissions, reportPermissions: Permissions}>;
    addDocumentModalBodyContent: React.FunctionComponent<AddDocumentRequiredProps>;
    documentGroupApi: CrudMethods<any, any, any>;
    documentApi: DocumentModuleApiMethods<any>;
    relatedData: {
        relatedTitle: string,
        relatedSections:
        {
            title: string,
            description: string,
            buttonLink: string,
            route: string
        }[]

    }
}

export interface TechnicalDocumentationOutletInterface {
    documentModules: DocumentModuleInterface[];
    documentModule?: DocumentModuleInterface;
};

interface TechnicalDocumentationContainerProps { }

function PartsAndServicesContainer(props: TechnicalDocumentationContainerProps) {
	const hasBaseOrgForHeader = useBaseOrgIdHeader();
	
	const { isMobile } = useWindowSize();
    const { moduleName } = useParams();
    const [documentGroups, setDocumentGroups] = useState<DocumentGroup[]>([]);
    const [documentAdded, setDocumentAdded] = useState<Report|undefined>(undefined);
    const { showError } = useContext(Store);
    const [groupPermissions, setGroupPermissions] = useState<Permissions>({
        baseOrg: null,
        canAdd: false,
        canAdmin: false,
        canDelete: false,
        canEdit: false,
        canView: false,
        orgGroup: null,
        role: ''
    });
    const [reportPermissions, setReportPermissions] = useState<Permissions>({
        baseOrg: null,
        canAdd: false,
        canAdmin: false,
        canDelete: false,
        canEdit: false,
        canView: false,
        orgGroup: null,
        role: ''
    });
    const setErrorData = useOnError();
    usePlantSiteDetailsApiCalls();

    const sortByDisplayOrderAsc = ({ displayOrder: a }: DocumentGroup, { displayOrder: b }: DocumentGroup): number => {
        if (a > b) return 1;
        if (b > a) return -1;
        return 0;
    };

    useEffect(() => {
        setDocumentGroups([]);
    }, [moduleName]);

    const documentModule = documentModules.find(module => module.name === moduleName);
    const { isFetching, refetch } = useQuery(`${documentModule?.name}.documentGroups.get`, (documentModule ? documentModule.documentGroupApi.getAll : () => new Promise((resolve) => resolve([]))), {
        onSuccess: (response: DataWithPermissions<DocumentGroup[]>) => {
            const {data, permissions} = response;
			setGroupPermissions(permissions);
            setDocumentGroups(data.sort(sortByDisplayOrderAsc) || []);
        },
        onError: (error: AxiosError<string>) => {
            setErrorData(error);
        },
		enabled: hasBaseOrgForHeader
    });
    const handleRefresh = () => {
        window.location.reload();
    };

    return (
        <div className={`${classes.flex_column} ${classes.documentation_container} ${isMobile ? classes.is_mobile : ''}`}>
            <div className='content'>
                {showError.isError && <ErrorCard ErrorMessage={showError.title} onRefresh={handleRefresh} ErrorType={showError.ErrorType} />}
                {documentModule && (
                    <>
                        <div className='path breadcrumb'>
                            <Link to={"/"} className="link_text">Home</Link> / Parts & Service /{' '}
                            {documentModule.name}
                        </div>
                        <div className='login-header'>
                            {documentModule.name}
                        </div>
                        <div className={`card ${classes.flex_column} ${classes.documentation_container_outlet}`}>
                            <div className={`${classes.module_container} ${classes.flex_column}`}>
                                <Outlet context={{ documentModules, refetch, documentModule, documentGroups: isFetching ? [] : documentGroups, setDocumentGroups, groupPermissions, reportPermissions, setReportPermissions, documentAdded, setDocumentAdded }} />
                            </div>
                        </div>
                    </>
                )}
                {documentModule ? <RelatedLinks data={documentModule.relatedData} /> : null}
            </div>


            <div className='content flex-grow-0 flex-end'>
                <Footer />
            </div>
        </div>
    );
}

export default PartsAndServicesContainer;