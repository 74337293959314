import React, { createContext, useCallback } from "react";
import { ActionType } from "./Reducers/action-types";
import {
  HeaderDTO,
  AccountSettingsDTO,
  IssueReportsDTO,
  IssueReportItemsDTO,
  QuoteRequestDTO,
  QuoteRequestItemsDTO,
  PlantSiteDetailsDTO,
  PartsAndServicesDTO,
  TechnicalDocumentationDTO,
  PlantInformation,
  PlantConfigDTO,
  TypeDTO,
  UnitDTO,
  UnitGetAllDTO,
  OMManualSectionGetAllDTO,
  PlantConfigImageDTO,
  ModalsStateManagementDTO,
  PlantEquipmentDTO,
  PlantEquipmentsDTO,
  PlantEquipmentAttachmentsDTO,
  PlantEquipmentIdDTO,
  PlantEquipmentAttachmentDTO,
  TypeDATA,
  ContactAccordianInterface,
  ContactAvatarImageDTO,
  PlantContactsDTO,
  FaqInterfaceDTO,
  ShowErrorInterface,
  SelectedPlantDTO,
  Report,
  QuoteRequestGetAllDTO,
  NotificationType,
  QuoteRequestSurveyDTO,
  QuoteRequestSurvey,
  PlantConfigData,
  ListModuleInterface,
  CPApiResponse,
  User,
} from "./utils/interface";

export interface StateInterface {
  header: HeaderDTO;
  accountSettings: AccountSettingsDTO;
  setHeader?: (value: HeaderDTO) => void;
  notificationTypes: NotificationType[];
  setNotificationTypes?: (value: NotificationType[]) => void;

  showA2hs: boolean;
  setShowA2hs?: (value: boolean) => void;

  setAccountSettings?: (value: AccountSettingsDTO) => void;

  plants: PlantConfigData[];
  setPlants?: (value: PlantConfigData[]) => void;

  selectedPlant: SelectedPlantDTO;
  setSelectedPlant?: (value: SelectedPlantDTO) => void;

  plantConfigImage: PlantConfigImageDTO;
  setPlantConfigImage?: (value: PlantConfigImageDTO) => void;

  faq: FaqInterfaceDTO;
  setFaq?: (value: FaqInterfaceDTO) => void;

  selectedPlantDetails: PlantSiteDetailsDTO;
  setSelectedPlantDetails?: (value: PlantSiteDetailsDTO) => void;

  report: Report;
  setReport?: (value: Report) => void;

  plantEquipment: PlantEquipmentDTO;
  setPlantEquipment?: (value: PlantEquipmentDTO) => void;

  plantEquipments: PlantEquipmentsDTO;
  setPlantEquipments?: (value: PlantEquipmentsDTO) => void;

  plantContacts: PlantContactsDTO;
  setPlantContacts?: (value: PlantContactsDTO) => void;

  typePlantContacts: PlantContactsDTO;
  setTypePlantContacts?: (value: PlantContactsDTO) => void;

  typeMitsubiContacts: PlantContactsDTO;
  setTypeMitsubiContacts?: (value: PlantContactsDTO) => void;

  contactAccordianData: ContactAccordianInterface;
  setContactAccordianData?: (value: ContactAccordianInterface) => void;

  plantEquipmentId: PlantEquipmentIdDTO;
  setPlantEquipmentId?: (value: PlantEquipmentIdDTO) => void;

  plantEquipmentsAttachment: PlantEquipmentAttachmentDTO;
  setPlantEquipmentsAttachment?: (value: PlantEquipmentAttachmentDTO) => void;

  plantEquipmentsAttachments: PlantEquipmentAttachmentsDTO;
  setPlantEquipmentsAttachments?: (value: PlantEquipmentAttachmentsDTO) => void;

  issueReports: IssueReportsDTO;
  setIssueReports?: (value: IssueReportsDTO) => void;

  QuoteRequest: QuoteRequestDTO;
  setQuoteRequest?: (value: QuoteRequestDTO) => void;

  partsAndServices: PartsAndServicesDTO;
  setPartsAndServices?: (value: PartsAndServicesDTO) => void;

  plantConfig: PlantConfigDTO;
  setPlantConfig?: (value: PlantConfigDTO) => void;

  unit: UnitDTO;
  setUnit?: (value: UnitDTO) => void;

  unitGetAll: UnitGetAllDTO;
  setUnitGetAll?: (value: UnitGetAllDTO) => void;

  ommanualSectionGetAll: OMManualSectionGetAllDTO;
  setOMManualSectionGetAll?: (value: OMManualSectionGetAllDTO) => void;

  showError: ShowErrorInterface;
  setShowError?: (value: ShowErrorInterface) => void;

  equipmentAttachmentType: TypeDTO;
  setEquipmentAttachmentType?: (value: TypeDTO) => void;

  manufacturerType: TypeDTO;
  setManufacturerType?: (value: TypeDTO) => void;

  productType: TypeDTO;
  setProductType?: (value: TypeDTO) => void;

  fuelType: TypeDTO;
  setFuelType?: (value: TypeDTO) => void;

  equipmentModelNameTypes: TypeDTO;
  setEquipmentModelNameTypes?: (value: TypeDTO) => void;

  unitTypes: TypeDATA;
  setUnitTypes?: (value: TypeDATA) => void;

  equipmentTypes: TypeDATA;
  setEquipmentTypes?: (value: TypeDATA) => void;

  unitConfigurationTypes: TypeDTO;
  setUnitConfigurationTypes?: (value: TypeDTO) => void;

  technicalDocumentation: TechnicalDocumentationDTO;
  setTechnicalDocumentation?: (value: TechnicalDocumentationDTO) => void;

  plantInformation: PlantInformation;
  setNameplate?: (value: { id: number }) => void;

  contactAvatarImage: ContactAvatarImageDTO[];
  setContactAvatarImage?: (value: ContactAvatarImageDTO[]) => void;

  accordian_tab: number;
  setAccordian_tab?: (value: number) => void;

  module: ListModuleInterface;
  setModule?: (value: ListModuleInterface) => void;

  toolTip: boolean;
  toggleTooltip?: (value: boolean) => void;

  toolTipContent: React.ReactElement;
  setToolTipContent?: (value: React.ReactElement) => void;

  notificationContent: React.ReactElement;
  setNotificationContent?: (value: React.ReactElement) => void;

  sidebarToggle: boolean;
  toggleSidebar?: (value: boolean) => void;

  mobileNavToggle: boolean;
  toggleMobileNav?: (value: boolean) => void;

  isModal: ModalsStateManagementDTO;
  setIsModal?: (value: ModalsStateManagementDTO) => void;

  trackLocation: string;
  setActiveLocation?: (value: string) => void;

  globalUniqueIds: string[];
  setGlobalUniqueIds?: (value: string[]) => void;

  user?: User;
  setUser?: (value: User) => void;

  notificationPaneOpen: boolean;
  toggleNotificationPane?: (value: boolean, viewDocPane?: boolean) => void;

  notificationContentType: boolean;

  navigationIsBlocked: boolean;
  setNavigationIsBlocked?: (value: boolean) => void;

  showConfirmCloseModal: boolean;
  setShowConfirmCloseModal?: (value: boolean) => void;

  closeModalIsBlocked: boolean;
  setCloseModalIsBlocked?: (value: boolean) => void;

  modalFormDirty: boolean;
  setModalFormDirty?: (value: boolean) => void;
}

export const defaultState: StateInterface = {
  header: {
    success: false,
    message: "",
    permissions: 0,
    data: {
      user: {
        userId: 0,
        firstName: "",
        lastName: "",
        pictureUrl: "",
        portalAdmin: false,
        mpInternal: false,
        email: '',
        tomoniServiceAccount: false,
        aDuid: '',
        plants: [{ plantId: 0, name: "", isDefault: false, baseOrgId: 0 }],
      },
      notifications: [],
      newNotificationCount: 0,
    },
  },
  notificationTypes: [],
  accountSettings: {
    success: true,
    message: "",
    permissions: 0,
    data: {
      fName: "",
      lName: "",
      email: "",
      defaultBaseOrgId: 0,
      defaultBaseOrgName: "",
      optedOut: true,
    },
  },
  showA2hs: false,
  showError: {
    title: "",
    isError: false,
    ErrorType: null,
  },
  contactAvatarImage: [
    {
      success: false,
      preview: "",
      name: "",
      error: "",
    },
  ],
  faq: {
    faqText: "",
  },
  isModal: {
    isCopy: {
      isCopyLinkModal: false,
    },
    isView: {
      isNameplate: false,
      isCloseQuoteRequest: false,
      isLeavePage: false,
    },
    isAdd: {
      isEquipment: false,
      isContactModal: false,
      isNameplate: false,
      isNameplateSamePage: false,
      isUnit: false,
      isPartsListDocument: false,
      isAttachNameplateAsset: false,
      isQuoteRequest: false,
      isQuoteDocument: false,
      isPODocument: false,
    },
    isDelete: {
      isEquipment: false,
      isPlantConfig: false,
      isNameplate: false,
      isContactModal: false,
      isUnit: false,
      isQuoteRequest: false,
      isQuoteDocument: false,
      isPODocument: false,
    },
    isEdit: {
      isEquipment: false,
      isPlantConfig: false,
      isContactModal: false,
      isNameplate: false,
      isFaq: false,
      isUnit: false,
      isPartsListDocument: false,
      isAttachNameplateAsset: false,
      isQuoteRequest: false,
      isQuoteDocument: false,
      isPODocument: false,
    },
    isDeleteSuccess: {
      isEquipment: false,
      isContactModal: false,
      isPlantConfig: false,
      isNameplate: false,
      isUnit: false,
      isQuoteRequest: false,
      isQuoteDocument: false,
      isPODocument: false,
    },
    isAddedSuccess: {
      isEquipment: false,
      isContactModal: false,
      isNameplate: false,
      isNameplateSamePage: false,
      isUnit: false,
      isQuoteRequest: false,
      isQuoteDocument: false,
      isPODocument: false,
    },
    isEditedSuccess: {
      isEquipment: false,
      isContactModal: false,
      isFaq: false,
      isPlantConfig: false,
      isNameplate: false,
      isUnit: false,
      isQuoteRequest: false,
      isQuoteDocument: false,
      isPODocument: false,
    },
  },
  report: {
    reportType: "",
    globalId: 0,
    itemTypeId: 0,
    created: "",
    createdBy: "",
    updated: "",
    updatedBy: "",
    displayFileName: "",
    originalFileName: "",
    associatedEquipment: [],
    fileId: 0,
    fileModel: {
      globalItemId: 0,
      id: 0,
      location: "",
      originalFileName: "",
      uploaded: "",
      displaySize: "",
      fileType: {
        contentType: "",
        description: "",
        extension: "",
        id: 0,
        maxSizeMb: 0,
        name: "",
      },
      fileTypeId: 0,
    },
    scope: "",
    issueDate: "",
    applicability: "",
    summaryGuideline: "",
    implementationStatus: "",
    isDeleted: false,
    previousFiles: [],
    sectionId: 0,
  },
  selectedPlant: { plantId: 0, baseOrgId: 0, name: "", isDefault: false },

  selectedPlantDetails: {
    success: false,
    message: "",
    permissions: 0,
    data: {
      plantId: 4,
      plantLoad: "",
      ambientTemperatureFahrenheit: "",
      ambientTemperatureCelsius: "",
      lastOutage: undefined,
      nextPlannedOutage: undefined,
    },
  },

  issueReports: {
    success: false,
    message: "",
    permissions: 0,
    data: {
      totalCount: 0,
      newIssueReportsCount: 0,
      agedIssueReportsCount: 0,
      resolvedIssueReportsCount: 0,
      averageOpenTime: 0,
    },
  },

  QuoteRequest: {
    success: false,
    message: "",
    permissions: 0,
    data: {
      totalCount: 0,
      newQuoteRequestCount: 0,
      agedQuoteRequestCount: 0,
      resolvedQuoteRequestCount: 0,
      averageOpenTime: 0,
    },
  },

  equipmentModelNameTypes: {
    success: false,
    message: "",
    permissions: 0,
    data: [
      {
        id: 0,
        name: "",
      },
    ],
  },
  fuelType: {
    success: false,
    message: "",
    permissions: 0,
    data: [
      {
        id: 0,
        name: "",
      },
    ],
  },
  manufacturerType: {
    success: false,
    message: "",
    permissions: 0,
    data: [
      {
        id: 0,
        name: "Other OEM",
      },
    ],
  },
  productType: {
    success: false,
    message: "",
    permissions: 0,
    data: [
      {
        id: 0,
        name: "",
      },
    ],
  },
  unitTypes: {
    data: [],
  },
  equipmentTypes: {
    data: [
      {
        id: 0,
        name: "",
      },
    ],
  },
  unitConfigurationTypes: {
    success: false,
    message: "",
    permissions: 0,
    data: [
      {
        id: 0,
        name: "",
      },
    ],
  },
  equipmentAttachmentType: {
    success: false,
    message: "",
    permissions: 0,
    data: [
      {
        id: 0,
        name: "",
      },
    ],
  },
  partsAndServices: {
    success: false,
    message: "",
    permissions: 0,
    data: {
      totalCount: 0,
      partsQuotesAndOrdersCount: 0,
      servicesQuotesAndOrdersCount: 0,
      averagePartsTurnaroundTime: 0,
      averageServicesTurnaroundTime: 0,
    },
  },

  technicalDocumentation: {
    success: false,
    message: "",
    permissions: 0,
    data: {
      totalCount: 0,
      newMitsubishiReportsCount: 0,
      newTechnicalBulletinsCount: 0,
      oandMManualsCount: 0,
    },
  },
  plantConfigImage: {
    file: "",
  },
  plantEquipment: {
    success: false,
    message: "",
    permissions: 0,
    data: {
      plantEquipmentId: 0,
      unitID: 0,
      equipmentName: "",
      equipmentID: "",
      productType: 0,
      manufacturerType: 0,
      equipmentModel: "",
      equipmentModelId: 0,
      productTypeName: "",
      equipmentModelName: "",
      manufacturerName: "",
      any: false,
      notes: null,
      modelConfigurationComments: null,
      equipmentAttachmentCount: 0,
      equipmentAttachmentModels: [
        {
          equipmentAttachmentId: 0,
          equipmentId: 0,
          name: "",
          equipmentAttachmentTypeId: 0,
          description: "",
          fileType: 0,
          filePath: "",
          created: "",
          createdBy: "",
          createdById: 0,
          updated: "",
          updatedBy: "",
          updatedById: 0,
          file: "",
          size: "",
          equipmentName: "",
          equipmentAttachmentTypeName: "",
          associatedUnitName: "",
          fileTypeName: "",
          displaySize: "",
          fileTypeObj: {
            id: 0,
            name: "",
            description: "",
            extension: "",
            maxSizeMb: 0,
            contentType: "",
          },
        },
      ],
    },
  },
  plantEquipments: {
    success: false,
    message: "",
    permissions: 0,
    data: [],
  },
  plantContacts: {
    success: false,
    message: "",
    permissions: 0,
    data: [],
  },
  typePlantContacts: {
    success: false,
    message: "",
    permissions: 0,
    data: [],
  },
  typeMitsubiContacts: {
    success: false,
    message: "",
    permissions: 0,
    data: [],
  },
  contactAccordianData: {
    data: [],
  },
  plantEquipmentId: {
    id: 0,
  },
  plantConfig: {
    success: false,
    message: "",
    permissions: 0,
    data: {
      plantId: 0,
      baseOrgId: 0,
      baseOrgName: "",
      operators: "",
      websiteURL: "",
      owner: "",
      parentGroup: "",
      address1: "",
      address2: "",
      address3: "",
      mainPhone: "",
      frequency: "",
      mhiRegion: "",
      notesComments: "",
      file: "",
      units: null,
    },
  },
  unit: {
    success: false,
    message: "",
    permissions: 0,
    data: null,
  },
  unitGetAll: {
    success: false,
    message: "",
    permissions: 0,
    data: [],
  },

  ommanualSectionGetAll: {
    success: false,
    message: "",
    permissions: 0,
    data: [],
  },

  plantInformation: {
    nameplate: {
      id: 0,
    },
  },
  plantEquipmentsAttachments: {
    success: false,
    message: "",
    permissions: 0,
    data: [
      {
        equipmentAttachmentId: 0,
        equipmentId: 0,
        name: "",
        equipmentAttachmentTypeId: 0,
        description: "",
        fileType: 0,
        filePath: "",
        created: "",
        createdBy: "",
        createdById: 0,
        updated: "",
        updatedBy: "",
        updatedById: 0,
        file: "",
        size: "",
        equipmentName: "",
        equipmentAttachmentTypeName: "",
        associatedUnitName: "",
        fileTypeName: "",
        displaySize: "",
        fileTypeObj: {
          id: 0,
          name: "",
          description: "",
          extension: "",
          maxSizeMb: 0,
          contentType: "",
        },
      },
    ],
  },
  plantEquipmentsAttachment: {
    success: false,
    message: "",
    permissions: 0,
    data: {
      equipmentAttachmentId: 0,
      equipmentId: 0,
      name: "",
      equipmentAttachmentTypeId: 0,
      description: "",
      fileType: 0,
      filePath: "",
      created: "",
      createdBy: "",
      createdById: 0,
      updated: "",
      updatedBy: "",
      updatedById: 0,
      file: "",
      size: "",
      equipmentName: "",
      equipmentAttachmentTypeName: "",
      associatedUnitName: "",
      fileTypeName: "",
      displaySize: "",
      fileTypeObj: {
        id: 0,
        name: "",
        description: "",
        extension: "",
        maxSizeMb: 0,
        contentType: "",
      },
    },
  },
  accordian_tab: 0,
  module: {
    name: "",
    route: "",
    addEditHeaderName: "",
    addModalHeaderContent: [],
    moduleHeaderContent: "",
  },
  toolTip: false,
  toolTipContent: <></>,
  notificationContent: <></>,
  sidebarToggle: false,
  mobileNavToggle: false,
  trackLocation: window.location.pathname,
  plants: [],
  globalUniqueIds: [],
  notificationPaneOpen: false,
  notificationContentType: false,
  navigationIsBlocked: false,
  showConfirmCloseModal: false,
  closeModalIsBlocked: false,
  modalFormDirty: false,
};

export const Store = createContext<StateInterface>(defaultState);
