import { AxiosError } from "axios";
import React, { useState } from "react";
import { useStore } from "../../../useStore";
import { KPI } from "../../../utils/interface";
import classes from "./ESR.module.css";
import { useQuery_AllPlantsKPIs, useQuery_PlantKPIs } from "./ESRHttpServices";
import KPIsContainer from "./KPIsContainer";

const KPITabs = ({ setErrorData }: { setErrorData: (errorData: any) => void }) => {
  const { selectedPlant } = useStore();
	const [KPITabIndex, setKPITabIndex] = useState(0);
	const [plantKPIs, setPlantKPIs] = useState<KPI>();
	const [allPlantKPIs, setAllPlantKPIs] = useState<KPI>();

	const onSuccessPlantKPIs = (data: KPI) => setPlantKPIs(data);
	const onSuccessAllPlantKPIs = (data: KPI) => setAllPlantKPIs(data);
	const onError = (error: AxiosError<string>) => setErrorData(error);

	useQuery_PlantKPIs({
		onSuccess: onSuccessPlantKPIs,
		onError,
		enabled: KPITabIndex === 0
	});
	useQuery_AllPlantsKPIs({
		onSuccess: onSuccessAllPlantKPIs,
		onError,
		enabled: KPITabIndex === 1
	});
	return (
		<div>
			<div className={`${classes.KPITabs}`}>
				<div onClick={() => setKPITabIndex(0)} className={`${KPITabIndex === 0 ? classes.selected : classes.unselected}`}>
					{selectedPlant.name}
				</div>
				<div onClick={() => setKPITabIndex(1)} className={`${KPITabIndex === 1 ? classes.selected : classes.unselected}`}>
					All Customers
				</div>
			</div>
			{KPITabIndex === 0 ? <KPIsContainer KPI={plantKPIs} /> : KPITabIndex === 1 && <KPIsContainer KPI={allPlantKPIs} />}
		</div>
	);
};

export default KPITabs;
