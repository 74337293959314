import React from "react";
import { generatePath, Outlet, useParams } from "react-router-dom";
import classes from "./SupportContainer.module.css";
import { MainContainer } from "../MainContainer";

function SupportContainer() {
  const { supportModuleName } = useParams();
  
  const relatedData = {
    relatedTitle: "Related Support Information",
    relatedSections: [
      {
        title: "Engineering Support Requests",
        description: "Request technical information or send questions to Mitsubishi Power Americas on technical topics. Responses will be documented here along with the requests.",
        buttonLink: "View ESRs",
        route: generatePath('/support/esr/:supportModuleName', { supportModuleName: "Engineering Support Requests" }),
      },
      {
        title: "O&M Manuals",
        description: "Click below for Operation and Maintenance Manuals related to your Plant, Unit and Equipment.",
        buttonLink: "View O&M Manuals",
        route: generatePath('/technical-documentation/:moduleName', { moduleName: 'O&M Manuals' })
      },
      {
        title: "Issue Reports",
        description: "This page contains the reports issued by the Mitsubishi Power TOMONI HUB.",
        buttonLink: "View Issue Reports",
        route: "/issue-reports"
      }
    ],
  };

  return (
    <MainContainer breadCrumbs={['Support', `${supportModuleName}`]} title={supportModuleName || ''} relatedData={relatedData}>
        <div className={classes.card}>
          <Outlet />
        </div>
    </MainContainer>
  );
}

export default SupportContainer;
