import { ErrorMessage, Field, useField, useFormikContext } from "formik";
import { Key, useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Store } from "../../../../Store";

import ImageImports from "../../../../utils/ImageImports";
import classes from "./DragDropField.module.css";

const DragDropField = ({
  name,
  labelName,
  showFilename,
  defaultImage,
  isEdit,
  placeholder,
  fileAcceptance
}: DragDropFieldProps) => {
  const { values, setFieldValue, isSubmitting } = useFormikContext<any>();
  const { selectedPlant,contactAvatarImage,setContactAvatarImage } = useContext(Store);
  const [field,meta] = useField(name);
  const [fileTypeAcceptance, setFileTypeAcceptance] = useState({
    'image/jpeg': [],
    'image/png': [],
    'application/pdf':[]
  })
  
  const { trash } = ImageImports;
  
  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    accept: fileAcceptance || fileTypeAcceptance,
    multiple: false,
    onDrop: (acceptedFiles: any) => {
        acceptedFiles.map((file: any) =>{
         
          setContactAvatarImage && setContactAvatarImage([
            {success:false ,error:"",preview:"",name:file.name}       
          ]);
          toBase64(file)
        .then((base64Image: string) => {           
          setContactAvatarImage && setContactAvatarImage(           
            [{success:true,error:"",preview:base64Image,name:file.name}]
          );
          file.preview = base64Image;
          setFieldValue(name, file);
        })
        .catch((err:Error) => {
          setContactAvatarImage && setContactAvatarImage(        
            [{success:true,error:err.message,preview:"",name:file.name}]
          );
          console.error(err)});
        }
          
        )      
        
    },
  });

  
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
      <ul key={file.name}>
        {errors.map(e => (
          <li key={e.code} style={{ color: "#991B1B" }}>{e.code === 'file-invalid-type' ? e.message.replaceAll(',', ', ') : e.message}</li>
        ))}
      </ul>
  ));


  function toBase64(file: File) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }


  const thumbs = contactAvatarImage.map((file) => (
    <div key={file.name} style={{ maxWidth: "100%" }}>
      {
        (file.preview && file.preview.includes('data:image') ? <img src={file.preview} style={{width: "100%"}} onLoad={() => URL.revokeObjectURL(file.preview)} alt='' /> : <>{file.name}</>)
      }
    </div>
  ));
  const handleImageRemove = () => {
    setFieldValue(name, null);
  };

  useEffect(() => {
    if (isEdit) {
      let previewFile ;     
       previewFile = defaultImage;
       setContactAvatarImage && setContactAvatarImage([
        {
          success:true,
          error:"",
          name: "",          
          preview: `${previewFile}`,
        },
      ]);
    } else {
      setContactAvatarImage && setContactAvatarImage([]);
    }
    
  }, [isSubmitting, defaultImage]);


  return (
    <div className="container">
      <div className={classes.form_field_row}>
      {
          showFilename && 
        
          <>
          <label className={classes.input_label} htmlFor={name}>
            {labelName}
          </label>
          <div className={classes.form_group}>
            {
              isEdit ? <><Field
              id={name}
              type="text"
              className={`${classes.input} pr-8`}
              value={contactAvatarImage.length > 0 ? contactAvatarImage[0].name : ""}
              placeholder={placeholder}
              autoComplete="off"
            />
  
            <img
              className={classes.deleteFile}
              onClick={handleImageRemove}
              src={trash}
              alt=""
            /></> : ""
            }
            
          </div>
          </>
         
        }
        </div>
      
      <div className={classes.form_group}>
        {/* <ErrorMessage name={name}>
          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
        </ErrorMessage> */}

        <div {...getRootProps({           
          className: `${(meta.touched && meta.error) || (fileRejections[0]?.errors) || (meta.error) ? `${classes.dropzone} ${classes.errorField} ${classes.error_text}` : `${classes.dropzone}`}`
          
          })}>
        
          <input {...getInputProps()} />
          {thumbs.length > 0 ? (
            thumbs
          ) : (
            <>
              {isDragActive ? (
                <p>Drag & drop file here or</p>
              ) : (
                <div style={{ textAlign: "center", color: "#3C5D69" }}>
                  <p>Drag & drop file here or</p>
                  <strong>Browse for File</strong>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {/* {values.attachments &&
      <div className={classes.form_group}>
          {
              values.attachments.map(
                (
                  file: { name: any; type: any; size: any },
                  i: Key | null | undefined
                ) => (
                  
                  <li key={i}>                    
                    {`File:${file.name} Type:${file.type} Size:${file.size} bytes`}{" "}
                  </li>
                )
              )
          }
          
      </div>
          } */}
      <div>
        {fileRejectionItems}
      </div>
    </div>
  );
};

interface DragDropFieldProps {
  name: string;
  labelName: string;
  placeholder: string;
  isEdit: boolean;
  showFilename: boolean;
  defaultImage: string;
  fileAcceptance?: {[key: string]: any[]}

}

export default DragDropField;
