import React from "react";
import spinner from "../../assets/loader.gif";
import "./HomeCardLoader.css";

const HomeCardLoader = (props: HomeCardLoaderProps) => {
	return (
		<div style={{ minHeight: `${props.minHeight}` }} className="homecardloader-container">
		  <div style={{ minHeight: `${props.minHeight}` }} className="homecardloader-back">
            <div style={{ backgroundImage: `url(${spinner})`, minHeight: `${props.minHeight}` }} className="homecardloader-spinner"></div>
          </div>
          <div className="homecardloader-top-content"></div>
		  {props.showBottom && (
			<div className="homecardloader-bottom-container">
				<div className="homecardloader-bottom-content"></div>
			</div>
		  )}
		</div>
	);
};

interface HomeCardLoaderProps {
	showBottom: boolean,
	minHeight: string
}

export default HomeCardLoader;
