import { useCallback, useMemo, useState } from "react";
import ImageImports from '../../utils/ImageImports';
import Button from "../Button/Button";
import { withTooltip } from "../PopoutTooltip/Tooltip";
import { Checkbox } from "../UI/Form/Checkbox/Checkbox";
import { ItemListProps } from "./ItemList";

const {downArrow, ellipsis} = ImageImports;

type TableListProps<T> = Pick<ItemListProps<T>, 'bulkActions' | 'itemActions' | 'itemActionText' | 'readItemKey' | 'columns' | 'data' | 'noResultsText' | 'deletedItem'> & {selectedItems: T[], onItemSelectionChange: (item: T) => void, onSelectAllItems: () => void};

export const TableList = <T extends {id: number}>({bulkActions, itemActions, itemActionText, columns, data, noResultsText, readItemKey, deletedItem, selectedItems, onItemSelectionChange, onSelectAllItems}: TableListProps<T>) => {
    const [forceClose, setForceClose] = useState<number>(0);
    const ItemActionTooltip = useMemo(() => {
        if (itemActionText) {
            return withTooltip(Button);
        }
    }, [itemActionText]);

    const BulkActionTooltip = useMemo(() => {
        if (bulkActions) {
            return withTooltip(() => <button className="border-none bg-transparent cursor-pointer">
                <img src={downArrow} alt="Bulk Actions" className="h-6 w-6 mb-[-8px]" />
            </button>);
        }
    }, [bulkActions]);

    const isItemRead = useCallback((item: T) => {
        return !readItemKey || !item[readItemKey];
    }, [readItemKey]);

    const isDeleted = useCallback((item: T): boolean => {
        if (deletedItem) {
            if (typeof deletedItem === 'function') {
                return deletedItem(item);
            } else {
                return !!item[deletedItem];
            }
        }
        return false;
    }, [deletedItem]);

    return (
        <table className="w-full border-collapse">
            <thead>
                <tr>
                    {bulkActions?.length && (
                        <th className="bg-[#F2F2F2] p-3 text-left">
                            <div className="flex flex-row items-center shrink">
                                <Checkbox isChecked={data.length === selectedItems.length && data.length > 0} onChange={onSelectAllItems} />
                                {BulkActionTooltip && bulkActions && (
                                    <BulkActionTooltip forceClose={forceClose} tooltipContent={<div className="p-6 rounded-lg">
                                        <div className="flex flex-col items-start gap-6">
                                            {bulkActions.map((action, i) => (
                                                <span key={`action-${i}`} onClick={(e) => {
                                                    action.onClick(e, selectedItems);
                                                    setForceClose(fc => ++fc);
                                                }}>{action.text}</span>
                                            ))}
                                        </div>
                                    </div>} />
                                )}
                            </div>
                        </th>
                    )}
                    {columns.map(column => <th key={column.key as string} className="bg-[#F2F2F2] font-bold p-3 text-left">{column.title}</th>)}
                    {itemActions?.length && <th className="bg-[#F2F2F2] font-bold p-3 text-left"></th>}
                </tr>
            </thead>
            <tbody>
                {data.map((item, i) => (
                    <tr key={item.id} style={{ color: isDeleted(item)? 'var(--primaryGreyFont)' : 'inherit'}}>
                        {bulkActions?.length && (
                            <td className={`${isItemRead(item)? '' : 'bg-[#00749e]/5'} p-3`}>
                                <Checkbox isChecked={selectedItems.map(d => d.id).includes(item.id)} onChange={() => onItemSelectionChange(item)} />
                            </td>
                        )}
                        {columns.map((column, ci) => (
                            <td key={column.key as string} className={`p-3 text-left text-sm ${i > 0? 'border-0 border-t border-solid border-[#F2F2F2]' : ''} ${isItemRead(item)? '' : 'bg-[#00749e]/5'}  ${!isItemRead(item) && ci === 0? 'font-bold' : ''}`}>
                                {column.component ? <column.component data={item} /> : <div className={column.className ?? ''}><>{item[column.key]}</></div>}
                            </td>
                        ))}
                        {ItemActionTooltip && itemActions && (
                            <td className={`p-3 text-left text-sm ${i > 0? 'border-0 border-t border-solid border-[#F2F2F2]' : ''} ${isItemRead(item)? '' : 'bg-[#00749e]/5'}`}>
                                <ItemActionTooltip 
                                  className='border-none bg-transparent cursor-pointer !p-0'
                                  text={
                                    <img src={ellipsis} alt="More Actions" className="cursor-pointer" style={{filter: 'var(--svgFilterDarkBlueButton)'}} />
                                  }
                                  style={{right: "0 !important", left: "auto !important"}}
                                  forceClose={forceClose} 
                                  tooltipContent={<div className="rounded-lg p-3">
                                    <div className="flex flex-col items-start gap-6">
                                        {itemActions.map((action, i) => (
                                            <span key={`action-${i}`} onClick={(e) => {
                                                action.onClick(e, item);
                                                setForceClose(fc => ++fc);
                                            }}>{action.text}</span>
                                        ))}
                                    </div>
                                </div>} />
                            </td>
                        )}
                        {itemActions?.length && <td></td>}
                    </tr>
                ))}
                {data.length === 0 && (
                    <tr>
                        <td colSpan={99}>{noResultsText}</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};