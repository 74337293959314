import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { useThreads } from "../../customHooks/useThreads";
import { usePermissions } from "../../customHooks/useUserPermissions";
import useWindowSize from "../../customHooks/useWindowSize";
import { useStore } from "../../useStore";
import ImageImports from "../../utils/ImageImports";
import { SystemUserId } from "../../utils/globalVariables";
import { DiscussionItem, Permissions, Thread, ThreadMessage } from "../../utils/interface";
import ParticipantList from "../Thread/ParticipantList";
import classes from "./DiscussionBoard.module.css";
import MessageContainer from "./MessageContainer";
import PostThreadMessage from "./PostThreadMessage";

const Moment = require("moment");

const SortMessageArray = (sortType: string, Data: ThreadMessage[]) => {
	if (Data) {
		switch (sortType) {
			case "1":
				return Data.sort((a, b) => Moment(b.created).valueOf() - Moment(a.created).valueOf());
			case "2":
				return Data.sort((a, b) => Moment(a.created).valueOf() - Moment(b.created).valueOf());
			default:
				return Data;
		}
	}
};

const ThreadContainer: FunctionComponent<{ discussionItem: DiscussionItem }> = ({ discussionItem }) => {
	const { userGroupIcon, documentIcon } = ImageImports;

	const {
		setToolTipContent,
		toolTip,
		toggleTooltip,
		header: {
			data: { notifications, user },
		},
		showError,
		setShowError,
	} = useStore();

	const { isDesktop } = useWindowSize();
	const { getItemThreads } = useThreads();
  const { hasOnePermission } = usePermissions();
	const [thread, setThread] = useState<Thread>();
	const [messages, setMessages] = useState<ThreadMessage[]>([]);
	const [participants, setParticipants] = useState<string[]>([]);
	const [pagePermissions, setPagePermissions] = useState<Permissions>({
		baseOrg: null,
		canAdd: false,
		canAdmin: false,
		canDelete: false,
		canEdit: false,
		canView: false,
		orgGroup: null,
		role: ''
	});

	const buildStatusChanges = () => {
		return discussionItem.statusChanges
			? discussionItem.statusChanges?.map((statusChange) => {
					const message: ThreadMessage = {
						threadId: 0,
						title: statusChange.status,
						content: "",
						created: statusChange.modified,
						createdBy: statusChange.modifiedBy,
						isStatusMessage: true,
				
					};

					return message;
			  })
			: [];
	};

	const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const  navigate = useNavigate();
	const fetchMessages = () =>
		(async () => {
			setIsLoadingMessages(true);
			try {
				const {data: threads, permissions} = await getItemThreads(discussionItem?.globalId);
				setPagePermissions(permissions);
        
        if (!permissions.canView && !permissions.canAdmin && !permissions.canAdd && !user.portalAdmin) {
          navigate('/forbidden', { replace: true });
        }
				if (threads.length > 0) {
					setThread(threads[0]);
					const pMessages = threads[0].messages.map((a, i) => ({ ...a, isParent: a.createdById === SystemUserId }));
					const statusChanges: ThreadMessage[] = buildStatusChanges();
					const ParentMessage = SortMessageArray("2", [...pMessages, ...statusChanges]) || [];
	
					if (threads[0].messages) {
						const childMessages = threads[0].messages
							.filter((a) => a.messages?.length)
							.map((b) => b.messages)
							.pop();
						if (childMessages && childMessages.length > 0) {
					
							setMessages([...ParentMessage, ...childMessages]);
						} else {
							setMessages([...ParentMessage]);
						}
						// console.log(childMessages);
						if(childMessages && childMessages?.length > 0){
							let parent = threads[0].messages.map((item) => item.createdBy);
							let children = childMessages.map((item) => item.createdBy);
							setParticipants([...new Set([...parent,...children])]);
						}else{
							setParticipants([...new Set(threads[0].messages.map((item) => item.createdBy))]);
						}						
					}
				}
			} catch (error) {
				setShowError?.({
					title: `This Issue Report does not have a thread to post messages.`,
					ErrorType: "danger",
					isError: true,
					code: "MigrationError",
				});
				setMessages([]);
			} finally {
				setIsLoadingMessages(false);
			}
		})();

	useEffect(() => {
		if (discussionItem && !messages?.length) {
			fetchMessages();
		} else {
			const statusChanges: ThreadMessage[] = buildStatusChanges();

			setMessages(SortMessageArray("2", [...messages, ...statusChanges]) || []);
		}
	}, []);

	const showParticipants = () => {
		setToolTipContent && setToolTipContent(<ParticipantList users={participants} />);
		toggleTooltip && toggleTooltip(!toolTip);
	};

	const messageAttachments = useMemo(() => {
		return messages.reduce((carry, current) => {
			return carry + (current.messageAttachments || []).length;
		}, 0);
	}, [messages]);

	const canPostNewMessage = useMemo(() => {
		return !discussionItem.isLocked && (user.portalAdmin || hasOnePermission('DiscussionMessages', ['add', 'admin']));
	}, [discussionItem.isLocked, hasOnePermission, user.portalAdmin]);

	return (
		<>
			{showError.code !== "MigrationError" && (
				<div>
					<div className={`${classes.thread_header} ${isDesktop ? classes.flex_row : ""}`}>
						<div style={{ marginTop: isDesktop ? 0 : 20 }}>
							<strong>Start Discussion</strong> <span>(oldest to newest)</span>
						</div>
						<div className={`${classes.thread_header_right}`} style={{ order: isDesktop ? 2 : -1 }}>
							<div style={{ marginRight: 20 }}>
								<img src={documentIcon} alt="" />
								&nbsp;{messageAttachments} attachment{messageAttachments === 1 ? "" : "s"}
							</div>
							{participants.length > 0 ? (
								<div onClick={showParticipants} style={{ color: "#567783", cursor: "pointer" }}>
									<img src={userGroupIcon} alt="" style={{ filter: "var(--svgFilter567783)" }} />
									&nbsp;{participants.length} participant{participants.length === 1 ? "" : "s"}
								</div>
							) : (
								<div>
									<img src={userGroupIcon} alt="" />
									&nbsp;0 participants
								</div>
							)}
						</div>
					</div>
					<div>
						{isLoadingMessages ? (
							<Loader />
						) : (
							messages
								?.filter((message) => !message.deleted || hasOnePermission('DiscussionMessages', ['admin']) || user.portalAdmin) // pagePermissions.canAdmin
								?.map((message, index) => (
									<MessageContainer
										unread={notifications?.filter((item) => item.itemId === message.id && item.read === false).length > 0}
										key={index}
										discussionItem={discussionItem}
										message={message}
										fetchMessages={fetchMessages}
										pagePermissions={pagePermissions}
									/>
								))
						)}
					</div>
					{canPostNewMessage && (
						<div style={{ marginTop: 30 }}>
							<PostThreadMessage 
                messages={messages} 
                thread={thread} 
                fetchMessages={fetchMessages} 
                isLoadingMessages={isLoadingMessages} 
              />
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default ThreadContainer;

