import dayjs, { Dayjs } from "dayjs";
import * as React from "react";
import { DateInputWithCalendarToggle } from "../DatePickerField/DatePickerFields";
import { CustomSingleDatePicker } from "./DatePicker";

interface IDateRangePickerProps {
  startDate: Dayjs;
  endDate: Dayjs;
  showEndCalendar: boolean;
  showStartCalendar: boolean;
  startName: string;
  endName: string;
  startDateChange: (val: Dayjs) => void;
  endDateChange: (val: Dayjs) => void;
  setToggleStartCalendarDisplay: (x: boolean) => void;
  setToggleEndCalendarDisplay: (x: boolean) => void;
}

const CustomDateRangePicker: React.FunctionComponent<IDateRangePickerProps> = (props) => {
  const { startDate, endDate, showEndCalendar, showStartCalendar, startName, endName, startDateChange, endDateChange, setToggleStartCalendarDisplay, setToggleEndCalendarDisplay } = props;

  var customParseFormat = require("dayjs/plugin/customParseFormat");
  dayjs.extend(customParseFormat);

  return (
    <div style={{ width: "130%" }}>
      <label htmlFor={startName}>From</label>

      <CustomSingleDatePicker
        selectedDate={startDate}
        onChange={(val) => {
          startDateChange(dayjs(val))
          showStartCalendar && setToggleStartCalendarDisplay(!showStartCalendar)
        }}
        openPicker={showStartCalendar}
        maxDate={endDate}
        startDate={startDate}
        endDate={endDate}
        input={
          <DateInputWithCalendarToggle
            onBlur={() => showStartCalendar && setToggleStartCalendarDisplay(!showStartCalendar)}
            value={dayjs(startDate, "MM/DD/YYYY").isValid() ? dayjs(startDate).format("MM/DD/YYYY") : undefined}
            onChange={(val: Dayjs) => startDateChange(val)}
            toggleIcon={() => setToggleStartCalendarDisplay(!showStartCalendar)}
            maxDate={endDate}
            name={startName}
          />
        }
      />

      <label htmlFor={endName}>To</label>
      <CustomSingleDatePicker
        selectedDate={endDate}
        onChange={(val) => {
          endDateChange(dayjs(val.endOf('day')))
          showEndCalendar && setToggleEndCalendarDisplay(!showEndCalendar)
        }}
        openPicker={showEndCalendar}
        minDate={startDate}
        startDate={startDate}
        endDate={endDate}
        input={
          <DateInputWithCalendarToggle
            onBlur={() => showEndCalendar && setToggleEndCalendarDisplay(!showEndCalendar)}
            value={dayjs(endDate, "MM/DD/YYYY").isValid() ? dayjs(endDate).format("MM/DD/YYYY") : undefined}
            onChange={(val: Dayjs) => endDateChange(val.endOf('day'))}
            toggleIcon={() => setToggleEndCalendarDisplay(!showEndCalendar)}
            minDate={startDate}
            name={endName}
          />
        }
      />
    </div>
  );
};

export default CustomDateRangePicker;
