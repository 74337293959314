import { useStore } from "../useStore";

export const useOnError = () => {
  const { setShowError } = useStore();
  const setErrorData = (errorData: any) => {
    let errorMessage: string = "";    
    if (!errorData.response?.data && errorData.name === 'AxiosError' && errorData.code === "ERR_BAD_REQUEST") {
      errorMessage =  errorData.message;
    }else if (errorData.response.status  >= 400 && errorData.response.status < 500 ) {  
      errorMessage = errorData.response?.data;
    }else if (errorData.response.status  >= 500) {
      if(!errorData.response?.data.Success){
        errorMessage = errorData.response?.data.Message;
      }else{
        errorMessage = errorData.message;
      }
    }     
    if (errorMessage) {
        setShowError?.({
          isError: true,
          title: errorMessage,
          ErrorType: "danger",
        });
    }
  };
  return setErrorData;
};
