import { useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import PlantDetails from "../../components/PlantManagement/PlantDetails";
import { usePageRequiresAdmin } from "../../customHooks/usePageRequiresAdmin";
import { MainContainer } from "../MainContainer";
import { useGetPlantConfigData } from "../PlantInformation/PlantConfigHttpServices";


export const PlantManagementContainer = () => {
    usePageRequiresAdmin();
    const {plantId} = useParams();
	const location = useLocation();

    const nPlantId = useMemo(() => {
        return Number(plantId);
    }, [plantId]);

    const {data: plant} = useGetPlantConfigData(nPlantId, {
        enabled: nPlantId > 0
    });
    return (
        <MainContainer breadCrumbs={['Administration', 'Plant Management']} title="Plant Management">
            {plant && <PlantDetails plant={plant} />}
        </MainContainer>
    );
};