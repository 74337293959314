
import React, {useRef, MouseEventHandler, useEffect, useCallback, Fragment} from 'react';
import ReactDOM from "react-dom";
import classes from "./EmptyModal.module.css";
import ImageImports from "../../utils/ImageImports";
import useWindowSize from '../../customHooks/useWindowSize';
const {closeImg} = ImageImports;

function EmptyModal({shown, heading, body, footer, width: w, footerClass, headingClass, onClose}: EmptyModalProps) {
    const ref = useRef<HTMLDivElement>(null);
    const {isMobile} = useWindowSize();
    const portalElement = document.getElementById("overlays")!;
    useEffect(() => {
        if(shown){
            document.body.classList.add("noScroll");
        } else {
            document.body.classList.remove('noScroll');
        }
        return () => document.body.classList.remove('noScroll');
    }, [shown]);

    const width = isMobile? '100%' : `${w}px`;
    if (!shown) {
        return null;
    }
    return (
        <Fragment>     
                {ReactDOM.createPortal(<>
                    <div className={classes.Backdrop} onClick={onClose}></div>
                    <div ref={ref} className={`${classes.ModalContainer} ${isMobile? classes.IsMobile : ''}`} style={{width}}>
                        <div className={classes.ModalHeader}>
                            <span className={`${classes.HeadingText} ${headingClass ?? ''}`}>{heading}</span>
                            <div className={classes.CloseIcon} onClick={onClose}>
                                <img src={closeImg} alt="Close" />
                            </div>
                        </div>
                        {body}
                        {footer? (
                            <div className={`${classes.ModalFooter} ${footerClass ?? ''}`}>
                                {footer}
                            </div>
                        ) : null}
                    </div>
                </>,portalElement)}
                </Fragment>);
}

interface EmptyModalProps {
    shown: boolean,
    heading: string,
    body: any,
    footer: any,
    onClose: MouseEventHandler,
    width?: number,
    footerClass?: string,
    headingClass?: string
}

EmptyModal.defaultProps = {
    width: 700,
    footerClass: '',
    headingClass: ''
};

export default EmptyModal;