import React, { useState, useEffect } from "react";
import { useStore } from "../useStore";

const useHandleBlocker = () => {
  let [isBlocked, setIsBlocked] = useState<boolean>(false);
  let [closeModalBlocked, setCloseModalBlocked] = useState<boolean>(false);
  let [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  let [isModalFormDirty, setIsModalFormDirty] = useState<boolean>(false);
  const { setNavigationIsBlocked, setShowConfirmCloseModal, setCloseModalIsBlocked, setModalFormDirty } = useStore();

  useEffect(() => {
    setNavigationIsBlocked?.(isBlocked);
  }, [isBlocked]);

  useEffect(() => {
    setModalFormDirty?.(isModalFormDirty);
  }, [isModalFormDirty]);

  useEffect(() => {
    setShowConfirmCloseModal?.(confirmModalOpen);
  }, [confirmModalOpen]);

  useEffect(() => {
    setCloseModalIsBlocked?.(closeModalBlocked);
  }, [closeModalBlocked]);
  
  return { isBlocked, setIsBlocked, isModalFormDirty, setIsModalFormDirty, confirmModalOpen, setConfirmModalOpen, closeModalBlocked, setCloseModalBlocked };
};

export default useHandleBlocker;