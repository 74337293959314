// the other DragDropField in this directory has a bunch of code relating to avatar images..

import { useField, useFormikContext } from "formik";
import { useEffect, useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { toBase64 } from "../../../../utils/FileUtils";
import ImageImports from "../../../../utils/ImageImports";
import { supportedFileTypes } from "../../../../utils/getSupportedFileTypes";
import Alert from "../../../Alert/Alert";
import Loader from "../../../Loader/Loader";
import classes from "./DragDropField.module.css";

export const maxFileSizeValidator = (file: File, maxFileSize?: number) => {
  // 2MB
  //console.log('maxFileSize', maxFileSize);
  if (!maxFileSize) maxFileSize = 1000000000;
  if (file.size > maxFileSize) {
    return {
      code: "file-too-large",
      message: `File size is larger than ${formatFileSize(maxFileSize)}.`,
    };
  }

  return null;
};

export const formatFileSize = (bytes: number, decimalPoint?: number) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1000,
    dm = decimalPoint || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const DragDropFieldFormik = (props: DragDropFieldFormikProps) => {
  const { setFieldValue } = useFormikContext<any>();
  const [, meta] = useField(props.name);
  const [preview, setPreview] = useState<string>();
  const { docTypes, excelTypes, excelTypesObject, docTypesObject } = supportedFileTypes();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [attachmentType, setAttachmentType] = useState<string|null>(null);
  const [objUrl, setObjUrl] = useState<string>();

  let acceptedTypes;
  if (props.isAllowOnlyImage) {
    acceptedTypes = {
      "image/jpeg": [],
      "image/png": [],
    };
  } else if (props.isAllowOnlyPdfImage) {
    acceptedTypes = {
      "image/jpeg": [],
      "image/png": [],
      "application/pdf": [],
    };
  } else {
    acceptedTypes = {
      "image/jpeg": [],
      "image/png": [],
      "application/pdf": [],
      ...docTypesObject,
      ...excelTypesObject,
    };
  }

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    accept: acceptedTypes,
    validator: (file) => maxFileSizeValidator(file, props.maxFileSizeBytes),
    multiple: false,
    onDrop: (acceptedFiles: any) => {
      acceptedFiles.map((file: File) => {
        setObjUrl(URL.createObjectURL(file));
        setFieldValue(props.displayName, file.name);
        setFieldValue(props.name, file);
        return props.onChange(file);
      });
    },
    onDropAccepted: (acceptedFiles: any) => {
      setShowLoading(true);
    },
    onDropRejected: (rejectedFiles: any) => {
      setShowLoading(false);
    }
  });

  const FileRejectionItems = () => {
    if (fileRejections.length > 0) {
      return (
        <>
          {fileRejections.map(({ file, errors }) => (
            <div className="flex flex-col w-full" key={file.name}>
              <Alert type="error" onClose={() => {}} dismissible={false}>
                {`Upload Error: ${file.name}`}
              </Alert>

              <ul>
                {errors.map((e) => (
                  <li key={e.code} className="text-[#991B1B]">
                    {e.code === "file-invalid-type" ? "File type not allowed" : e.message}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </>
      );
    }

    return <></>;
  };

  useEffect(() => {
    setShowLoading(true);
    
    if (props.attachment) {
      setObjUrl(URL.createObjectURL(props.attachment));
      setAttachmentType(props.attachment.type);
      toBase64(props.attachment).then((base64Image: string) => {
        setPreview(base64Image);
      }).catch((err: Error) => {
        setPreview(undefined);
      });
    } else {
      setPreview(undefined);
      setAttachmentType(null);
    }
    
    setShowLoading(false);
    
  }, [props.attachment]);

  const PreviewContainer = useCallback(() => {
    const { excelDummy, docDummy } = ImageImports;
    if (showLoading || props.fileLoading) {
      return <Loader containerStyle={{marginTop:"50%", marginBottom:"50%"}} />;
    }
    
    if (isDragActive) {
      return <p>Drop file here</p>;
    }

    if (preview  && attachmentType) {
      if (preview.includes('data:image')) {          
        return <img src={preview} alt="Document Preview" style={{ width: "100%" }} onLoad={() => URL.revokeObjectURL(preview || '')} />;
      } else if (preview.includes("data:application")) {
          if (preview.includes("data:application/pdf")) {
              return <iframe title="Document Preview" src={objUrl !== undefined ? `${objUrl}#toolbar=0&navpanes=0&scrollbar=0&&scrolling=0&view=FitH&#page=1&pagemode=thumbs` : `${preview}#toolbar=0&navpanes=0&scrollbar=0&&scrolling=0&view=FitH&#page=1&pagemode=thumbs`}></iframe>;
          } else {
            if (docTypes.find(type => type === attachmentType)) {
              return <img src={docDummy} alt="Word Document" style={{ width: "100%" }} onLoad={() => URL.revokeObjectURL(docDummy || "")} />;
            }

            if (excelTypes.find(type => type === attachmentType)) {
              return <img src={excelDummy} alt="Excel Document" style={{ width: "100%" }} onLoad={() => URL.revokeObjectURL(excelDummy || "")} />;
            }
          }
      } else {
        return (
          <div style={{ textAlign: "center", color: "#3C5D69" }}>
            <p>File preview not available.</p>
          </div>
        );
      }
    } else {
      return (
        <div style={{ textAlign: "center", color: "#3C5D69" }}>
          <p>Drag &amp; drop file here or</p>
          <strong>Browse for File</strong>
        </div>
      );
    }
    return (
      <div style={{ textAlign: "center", color: "#3C5D69" }}>
        <p>Drag &amp; drop file here or</p>
        <strong>Browse for File</strong>
      </div>
    );
  }, [preview, isDragActive, showLoading, props.fileLoading, objUrl, attachmentType]);

  return (
    <div className={classes.form_group}>
      <FileRejectionItems />
      <div
        {...getRootProps({
          className: `${(meta.touched && meta.error) || fileRejections.length ? `${classes.dropzone} ${classes.errorField} ${classes.error_text}` : `${classes.dropzone}`}`,
        })}
      >
        <input {...getInputProps()} />
        <PreviewContainer /> 
      </div>
    </div>
  );
};

interface DragDropFieldFormikProps {
  onChange: (file: File | undefined) => void;
  attachment: File | Blob | undefined;
  name: string;
  displayName: string;
  previewBase24?: string;
  isAllowOnlyImage: boolean;
  isAllowOnlyPdfImage: boolean;
  maxFileSizeBytes?: number;
  fileLoading?: boolean;
}
DragDropFieldFormik.defaultProps = {
  displayName: "",
  previewBase24: "",
  isAllowOnlyImage: false,
  isAllowOnlyPdfImage: false,
  maxFileSizeBytes: 1000000000,
  fileLoading: false,
};

export default DragDropFieldFormik;
