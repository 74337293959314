import axios, { AxiosError, AxiosResponse } from 'axios';
import { MutationFunction, QueryFunctionContext, useQuery, UseQueryOptions, useMutation } from 'react-query';
import { roleApiUrl, userApiUrl, reactivateUserApiUrl, baseOrgRoleApiUrl, apiEmailOptOut } from '../endpoints/endpoints';
import { handleAxiosResponse } from '../utils/handleAxiosResponse';
import { CPApiResponse, Role, User, UserRole, UserRoleAssignments, deleteInterface } from '../utils/interface';



declare type GetUserParameters = ['user', {userId: number}];
const getUser = async ({queryKey}: QueryFunctionContext<GetUserParameters>): Promise<User> => {
    const [_key, {userId}] = queryKey;
    const response: AxiosResponse<CPApiResponse<User>> = await axios(`${userApiUrl}/${userId}`);
    const user = handleAxiosResponse(response);
    return user;
};
// for ManageUsers use -- for current user see store.header.user || user
export const useGetUser = (userId: number, options: Omit<UseQueryOptions<User, AxiosError<string>, User, GetUserParameters>, "queryKey" | "queryFn"> = {}) => {
    return useQuery<User, AxiosError<string>, User, GetUserParameters>(['user', {userId}], getUser, {
        cacheTime: 1000,
        ...options
    });
};

declare type GetUserRoleAssignmentParameters = ['user.roles', {userId: number}];
const getUserRoleAssignments = async ({queryKey}: QueryFunctionContext<GetUserRoleAssignmentParameters>): Promise<UserRoleAssignments> => {
    const [_key, {userId}] = queryKey;
    const response: AxiosResponse<CPApiResponse<UserRoleAssignments>> = await axios(`${userApiUrl}/GetUserRoleAssignments/${userId}`);
    const assignments = handleAxiosResponse(response);
    return assignments;
};
export const useGetUserRoleAssignments = (userId: number, options: Omit<UseQueryOptions<unknown, unknown, UserRoleAssignments, GetUserRoleAssignmentParameters>, 'queryKey' | 'queryFn'> = {}) => {
    return useQuery<unknown, unknown, UserRoleAssignments, GetUserRoleAssignmentParameters>(['user.roles', {userId}], getUserRoleAssignments, options);
}

declare type GetRolesParameters = ['roles'];
export const getRoles = async (): Promise<Role[]> => {
    const response: AxiosResponse<CPApiResponse<Role[]>> = await axios(`${roleApiUrl}/List`);
    const assignments = handleAxiosResponse(response);
    return assignments;
};
export const useGetRoles = (options: Omit<UseQueryOptions<unknown, unknown, Role[], GetRolesParameters>, 'queryKey' | 'queryFn'> = {}) => {
    return useQuery<unknown, unknown, Role[], GetRolesParameters>(['roles'], getRoles, options);
}


export interface UserCrudMethods<T> {
    //get: (id: number) => Promise<T>;
    //getAll: (id: number) => Promise<A>;
    //create: (data: TD) => Promise<R>;
    //update: (data: TD) => Promise<R>;
    reactivate: (userId: number) => Promise<T>;
    delete: (userId: number) => Promise<T>;
    update: (user: User) => Promise<T>;
}

export const UsersApi: UserCrudMethods<
    AxiosResponse<deleteInterface>|CPApiResponse<User>
> = {
    delete: async (userId: number): Promise<AxiosResponse<deleteInterface>> => {
	    const response = await axios({
		    url: `${userApiUrl}/${userId}`,
			method: "DELETE",
		});
		return response.data;
	},
    reactivate: async (userId: number): Promise<AxiosResponse<CPApiResponse<boolean>>> => {
	    const response = await axios({
		    url: `${reactivateUserApiUrl}/${userId}`,
			method: "PUT",
		});
		return response.data;
	},

    update: async (user: User): Promise<CPApiResponse<User>> => {
		const response = await axios({
			url: `${userApiUrl}/${user.id}`,
			method: "PUT",
			data: user,
		});
		return handleAxiosResponse(response);
	}
};

export const useDelete_User = () => {
    return useMutation(UsersApi.delete);
};

export const useReactivate_User = () => {
    return useMutation(UsersApi.reactivate);
};

export const useUpdateUser = () => {
    return useMutation(UsersApi.update);
};


declare type GetAllUsersParameters = ['users'];
const getAllUsers = async (): Promise<User[]> => {
    const response: AxiosResponse<CPApiResponse<User[]>> = await axios(`${userApiUrl}/GetList`);
    return handleAxiosResponse(response);
};
export const useGetAllUsers = (options: Omit<UseQueryOptions<User[], unknown, User[], GetAllUsersParameters>, 'queryKey' | 'queryFn'> = {}) => {
    return useQuery<User[], unknown, User[], GetAllUsersParameters>(['users'], getAllUsers, options);
}

declare type SearchUserByEmailParameters = ['users', {email: string}];
const searchUserByEmail = async ({queryKey}: QueryFunctionContext<SearchUserByEmailParameters>): Promise<User> => {
    const [_key, {email}] = queryKey;
    const response: AxiosResponse<CPApiResponse<User>> = await axios(`${userApiUrl}/${email}`);
    return handleAxiosResponse(response);
}
export const useSearchUserByEmail = (email: string, options: Omit<UseQueryOptions<User, {message: string}, User, SearchUserByEmailParameters>, 'queryKey' | 'queryFn'> = {}) => {
    return useQuery<User, {message: string}, User, SearchUserByEmailParameters>(['users', {email}], searchUserByEmail, {
        enabled: false,
        ...options
    });
};

export const createUser: MutationFunction<User, User> = async (data: User): Promise<User> => {
    const response: AxiosResponse<CPApiResponse<User>> = await axios(userApiUrl, {
        method: 'POST',
        data
    });
    return handleAxiosResponse(response);
};

declare type UpdateUserOrganizationRolesParameters = {
    userId: number,
    roles: UserRole[]
};
export const updateUserOrganizationRoles: MutationFunction<UserRoleAssignments, UpdateUserOrganizationRolesParameters> = async ({userId, roles}: UpdateUserOrganizationRolesParameters) => {
    const response: AxiosResponse<CPApiResponse<UserRoleAssignments>> = await axios(`${userApiUrl}/AddUpdateUserBaseOrgRoles/${userId}`, {
        method: 'PUT',
        data: roles
    });
    return handleAxiosResponse(response);
};

declare type RemoveUserAssignmentParameters = {
    baseOrgRoleId: number,
};
export const removeUserAssignment: MutationFunction<boolean, RemoveUserAssignmentParameters> = async ({baseOrgRoleId}: RemoveUserAssignmentParameters) => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${baseOrgRoleApiUrl}/${baseOrgRoleId}`, {
        method: 'DELETE',
        
    });
    return handleAxiosResponse(response);
};


export const emailOptOut: MutationFunction<boolean> = async () => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${apiEmailOptOut}/OptOut`, {
        method: 'POST',
        
    });
    return handleAxiosResponse(response);
};

const getIsOptedOut = async (): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${apiEmailOptOut}/CheckOptedOut`);
    return handleAxiosResponse(response);
};

export const useGetIsOptedOut = (options: Omit<UseQueryOptions<boolean>, 'queryKey' | 'queryFn'> = {}) => {
    return useQuery(['emailoptout'], getIsOptedOut, options);
}