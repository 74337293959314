import { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useStore } from "../../useStore";
import Button from "../Button/Button";
import EmptyModal from "../Modals/EmptyModal";
import LocalStorage from "../../utils/LocalStorage";
import ImageImports from "../../utils/ImageImports";
import useWindowSize from "../../customHooks/useWindowSize";
import { isIOS } from 'react-device-detect';

const {share, ellipsis: ellipsis} = ImageImports;

const pageViewCountExpiry = 'P30D';
const showAfterNPageViews = 4;
const showEveryNPageViews = 0;


const useA2hs = (isMobile: boolean) => {
    const {showA2hs, setShowA2hs} = useStore();

    const closeA2hs = useCallback(() => {
        if (setShowA2hs) {
            setShowA2hs(false);
        }
    }, [setShowA2hs]);

    const openA2hs = useCallback(() => {
        if (setShowA2hs) {
            setShowA2hs(true);
        }
    }, [setShowA2hs]);

    return {
        closeA2hs,
        openA2hs,
        isOpen: isMobile && showA2hs
    };
};

const useA2hsStorage = (isMobile: boolean) => {
    const pageViewKey = 'a2hsPageCount';
    const disableKey = 'a2hsDisabled';
    const location = useLocation();

    const getCurrentCount = () => Number(LocalStorage.getItem(pageViewKey))
    const addPageView = () => setPageViewCount(getCurrentCount() + 1);
    const setPageViewCount = (count: number) => LocalStorage.setItem(pageViewKey, count, pageViewCountExpiry);

    const disable = useCallback(() => {
        localStorage.setItem(disableKey, '1');
    }, []);

    const enable = useCallback(() => {
        localStorage.removeItem(disableKey);
    }, []);

    const getIsEnabled = useCallback(() => {
        return !localStorage.getItem(disableKey);
    }, []);

    useEffect(() => {
        if (isMobile) {
            addPageView();
        }
    }, [location.pathname]);
    
    return {
        pageViewCount: getCurrentCount(),
        isEnabled: getIsEnabled(),
        disable, enable
    };
};


export const AddToHomeScreen = () => {
    const {isMobile} = useWindowSize();
    const {closeA2hs, openA2hs, isOpen} = useA2hs(isMobile);
    const {pageViewCount, disable, isEnabled} = useA2hsStorage(isMobile);
    
    useEffect(() => {
        if ((pageViewCount > 0 && pageViewCount % showEveryNPageViews === 0) || (pageViewCount === showAfterNPageViews + 1)) {
            openA2hs();
        }
    }, [pageViewCount]);

    const dontShowAgain = useCallback(() => {
        disable();
        closeA2hs();
    }, []);

    const body = useMemo(() => {
        // if is android
        if (isIOS) {
            return <>
                <span className="text-center w-full">
                    For iOS Safari users, you can easily add a website shortcut for this portal to your iPhone's home screen for quick access.
                </span>
                <span className="text-center w-full">
                    Tap <img src={share} alt="share" className="h-4 w-4" style={{filter: 'var(--svgFilterLightBlueButton)'}} />, scroll down the list of options, then tap 'Add to Home Screen'.
                </span>
            </>;
        }
        return <>
            <span className="text-center w-full">
                For Android users, you can easily add a website shortcut for this portal to your device's home screen.
            </span>
            <span className="text-center w-full">
                Tap  <img src={ellipsis} alt="menu" className="h-4 w-4 rotate-90" />, scroll down the list of options, then tap 'Add to Home screen'.
            </span>
        </>;
    }, [isIOS]);

    return (
        <EmptyModal body={body}
                    headingClass="text-center"
                    footer={<div className="flex flex-col">
                        <Button className="darkBlue" text="Dismiss" onClick={closeA2hs} />
                        <span className="text-ocean font-bold cursor-pointer text-center" onClick={dontShowAgain}>Don't Show This Again</span>
                    </div>}
                    heading="Access Customer Portal from your Home Screen"
                    onClose={closeA2hs}
                    shown={isEnabled && isOpen} />
    );
};