import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
// import { Link } from 'react-router-dom';

import classes from "./SelectField.module.css";

import { Store } from "../../../../Store";
import ImageImports from "../../../../utils/ImageImports";
import { ErrorMessage, useField, useFormikContext } from "formik";
import { ItemTypeCatalogIds } from "../../../../utils/moduleIDs";

function SelectFieldComplex(props: SelectFieldProps) {
  const [selectToggle, setSelectToggle] = useState(false);
  const { values, setFieldValue, errors } = useFormikContext<any>();
  const [field, meta] = useField(props.name);

  const stateContext = useContext(Store);

  const selectButtonRef = useRef<HTMLDivElement>(null);
  const [selectedValue, setSelectedValue] = useState<{ id: number; name: string }>({ id: 0, name: "" });
  const { downArrow, tooltip } = ImageImports;
  //   const options = ["option 1", "option 2", "option 3", "option 4"];
  const toggleSelect = () => setSelectToggle(!selectToggle);

  useEffect(() => {
    const handleCloseDropDown = (event: any) => {
      var path = event.path || (event.composedPath && event.composedPath());
      if (path[0] !== selectButtonRef.current) {
        if (path[0].id !== "drop-arrow") {
          setSelectToggle(false);
        }
      }
    };

    document.body.addEventListener("click", handleCloseDropDown);
    //Cleanup
    return () => document.body.removeEventListener("click", handleCloseDropDown);
  }, [props.defaultValue, values, stateContext]);

  useEffect(() => {
    if (props.defaultValue !== null && props.options.length >= 0) {
      if (props.placeholder) {
        setSelectedValue({ ...selectedValue, id: 0, name: props.placeholder });
      } else {
        const updatedValue = props.options.filter((data) => data.id === props.defaultValue);
        setSelectedValue({ ...updatedValue[0] });
      }
    }
  }, []);

  // const handleRequestType = (e: React.MouseEvent<HTMLDivElement>) => {
  //   const requestTypeSelected = e.currentTarget.getAttribute("data-id");
  //   if (!requestTypeSelected) return;
  //   const requestType = requestTypeOption.filter((u) => u.id === parseInt(requestTypeSelected));
  //   setSelectedrequestType(requestType[0]);
  //   formikProps.setFieldValue("quoteRequest.quoteRequestType", parseInt(requestTypeSelected));
  //   if (requestType[0].id === 0) {
  //     formikProps.setFieldValue("quoteRequest.itemTypeId", ItemTypeCatalogIds.PartsQuoteRequest);
  //   } else {
  //     formikProps.setFieldValue("quoteRequest.itemTypeId", ItemTypeCatalogIds.ServiceQuoteRequest);
  //   }
  // };
  return (
    <div>
      <div className={classes.form_field_row}>
        <label htmlFor={props.name} className={classes.input_label}>
          {props.labelName}
          {props.showTooltip && (
            <div
              className={classes.cursor_pointer}
              // tabIndex={1}
              onClick={() => {
                if (stateContext.toggleTooltip !== undefined) {
                  stateContext.toggleTooltip(!stateContext.toolTip);
                }
              }}
            >
              <img src={tooltip} className={classes.tooltip_icon} alt="tool tip" />
            </div>
          )}
        </label>
        <div className={classes.form_group} tabIndex={0}>
          <div className={meta.touched && meta.error ? `${classes.input_box} ${classes.errorField} ${classes.error_text}` : `${classes.input_box}`}>
            <div
              className={
                props.disabled
                  ? `${classes.form_control} ${classes.disabled} ${classes.selectDropdown}`
                  : `${classes.form_control}  ${classes.selectDropdown}`
              }
              ref={selectButtonRef}
              style={selectedValue.id === -1 ? { color: "#999" } : { color: "#000" }}
              onClick={toggleSelect}
            >
              {selectedValue.name}

              <img
                style={{ pointerEvents: "none" }}
                src={downArrow}
                className={selectToggle ? `${classes.selectArrowActive}` : `${classes.selectArrow}`}
                alt=""
              />
            </div>
          </div>
          <div
            className={
              // selectToggle ? `${classes.input_box} dropdown active` : `${classes.input_box} dropdown`
              selectToggle ? `${classes.dropdown} ${classes.isActive}` : `${classes.dropdown} `
            }
          >
            <div className={classes.options_wrapper}>
              {props.options.map((option, i) => {
                return (
                  <div
                    className={classes.options}
                    key={i}
                    onClick={(e) => {
                      setSelectedValue({ ...selectedValue, id: option.id, name: option.name });
                      if (option.id === -1) {
                        setFieldValue(props.name, null);
                        setFieldValue("quoteRequest.itemTypeId", 0);
                      } else {
                        setFieldValue(props.name, option.id,);
                        if (option.id === 0) {
                          setFieldValue("quoteRequest.itemTypeId", ItemTypeCatalogIds.PartsQuoteRequest);
                        } else {
                          setFieldValue("quoteRequest.itemTypeId", ItemTypeCatalogIds.ServiceQuoteRequest);
                        }
                      }

                      if (props.getSelectedOption) {
                        props.getSelectedOption(option.id);
                      }
                    }}
                  >
                    {option.name}
                  </div>
                );
              })}
            </div>
          </div>
          {/* <ErrorMessage name={props.name}>{(msg) => <div className={classes.error_text}>{msg}</div>}</ErrorMessage> */}
        </div>
      </div>
    </div>
  );
}

interface SelectFieldProps {
  options: {
    id: number;
    name: string;
  }[];
  defaultValue?: number | boolean | string | undefined;
  name: string;
  labelName: string;
  showTooltip: boolean;
  disabled: boolean;
  placeholder?: string;
  getSelectedOption?: Dispatch<SetStateAction<number | boolean | string | null>>;
}
SelectFieldComplex.defaultProps = {
  showTooltip: false,
  disabled: false,
};

export default SelectFieldComplex;
