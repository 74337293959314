import React from "react";
import { useNavigate } from "react-router-dom";
import useWindowSize from "../../customHooks/useWindowSize";
import Button from "../Button/Button";

import "./RelatedLinks.css";

export type RelatedLinksData = {
  relatedTitle: string;
  relatedSections: Array<{
    title: string;
    description: string;
    buttonLink: string;
    route: string;
  }>;
};

interface RelatedLinksProps {
  data: RelatedLinksData;
}

function RelatedLinks(props: RelatedLinksProps) {
  const navigate = useNavigate();
  const { isMobile, isTablet } = useWindowSize();

  return (
    <div className={`flex mt-14 flex-col`}>
      <h2 className="text-xl mb-8 text-black capitalize md:text-xl lg:text-2xl">{props.data.relatedTitle}</h2>
      <div className="flex flex-col lg:flex-row gap-x-16 gap-y-8">
        {props.data.relatedSections.map((obj, i) => {
          return (
            <div className="w-full lg:w-1/3 flex flex-col" key={i}>
              <h4 className="text-[#3C5D69] text-lg md:text-base lg:text-xl">{obj.title}</h4>
              <div className="mt-2 mb-4 text-sm">{obj.description}</div>
              <div className="flex flex-auto flex-col align-baseline justify-end">
                <Button
                  className="whiteBtn mb-0"
                  style={{ width: isMobile ? "100%" : isTablet ? 300 : "unset" }}
                  text={obj.buttonLink}
                  onClick={() => navigate(obj.route)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default RelatedLinks;
