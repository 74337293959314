import React from "react";
import { Field, ErrorMessage, useFormikContext, useField } from "formik";
import classes from "./SelectField.module.css";

function DefaultSelect(props: DefaultSelectProps) {
  const { label, name, options, showTooltip, ...rest } = props;
  const { values, setFieldValue, errors } = useFormikContext<any>();
  const [field, meta] = useField(props.name);

  return (
    <div>
      <Field as="select" id={name} className={meta.touched && meta.error ? `${classes.errorField} ${classes.error_text}` : ``} name={name} {...rest}>
        {options.length > 0 &&
          options.map((a, i) => {
            return (
              <option key={`${i}-${name}`} value={i}>
                {i}
              </option>
            );
          })}
      </Field>
    </div>
  );
}

interface DefaultSelectProps {
  options: number[];
  defaultValue?: number | boolean | string | undefined;
  name: string;
  label: string;
  showTooltip: boolean;
  disabled: boolean;
  placeholder?: string;
}
export default DefaultSelect;
