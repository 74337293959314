import { AxiosError } from "axios";
import { Form, Formik, FormikHelpers, useFormikContext } from "formik";
import React, { Dispatch, useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import * as Yup from "yup";
import { Store } from "../../../Store";
import {
  useAvatar_PlantContact,
  useCreate_PlantContact,
  useUpdate_PlantContact,
} from "../../../containers/PlantInformation/PlantContactHttpServices";
import useHandleBlocker from "../../../customHooks/useHandleBlocker";
import { useOnError } from "../../../customHooks/useOnError";
import { usePermissions } from "../../../customHooks/useUserPermissions";
import useWindowSize from "../../../customHooks/useWindowSize";
import { queryKeys } from "../../../react-query/queryKeys";
import { CloseAllModal } from "../../../utils/CloseAllModal";
import { toBase64 } from "../../../utils/FileUtils";
import ImageImports from "../../../utils/ImageImports";
import { ContactFormInterface, PlantContact, PlantContactDTO } from "../../../utils/interface";
import ErrorCard from "../../ErrorCard/ErrorCard";
import Loader from "../../Loader/Loader";
import DragDropField from "../../UI/Form/DragDropField/DragDropField";
import DropDownSelect from "../../UI/Form/DropDownSelect/DropDownSelect";
import RadioButtonsGrouped from "../../UI/Form/RadioButtonsGrouped/RadioButtonsGrouped";
import TextInputField from "../../UI/Form/TextInputField/TextInputField";
import ModalGlobal from "../../UI/Modal/ModalGlobal";
import classes from "./FormContacts.module.css";

const FormContacts = ({ selectedContact, isEdit, modaltitle, modaltitlename, setContactApiResponseData }: FormAddContactsProps) => {
  const queryClient = useQueryClient();
  const setErrorData = useOnError();
  const { showError, setContactAvatarImage, setIsModal, isModal, selectedPlant } = useContext(Store);
  const { mutate: addPlantContacts, isLoading: isAdding } = useCreate_PlantContact();
  const { mutate: editPlantContacts, isLoading: isEditing } = useUpdate_PlantContact();


  const [avatarUploadModal, setAvatarUploadModal] = useState<boolean>(false);
  const [base64Image, setbase64Image] = useState("");

  const { hasPermission } = usePermissions();

  const { add } = ImageImports;

  const { isMobile } = useWindowSize();

  const contactStaticType: {success: boolean, message: string, permissions: number, data: {id: boolean, name: string}[]} = {
    success: true,
    message: "Successfully retrieved all PlantFuelTypes.",
    permissions: 0,
    data: [],
  };

  if ((hasPermission('MitsubishiPowerContacts', 'add') && !isEdit) || (hasPermission('MitsubishiPowerContacts', 'edit') && isEdit)) {
    contactStaticType.data.push({ id: true, name: 'Mitsubishi Contact' });
  }

  if ((hasPermission('CustomerContacts', 'add') && !isEdit) || (hasPermission('CustomerContacts', 'edit') && isEdit)) {
    contactStaticType.data.push({ id: false, name: `${selectedPlant.name} Contact` });
  }

  const handleUploadModalOpen = () => {
    setAvatarUploadModal(true);
  };

  const handleGetImageDat = (values: any) => {
    setAvatarUploadModal(false);
  };

  let initialValues: ContactFormInterface;

  initialValues = {
    plantId: selectedPlant.plantId,
    avatarFile: "",
    PlantContactId: selectedContact?.plantContactId ? selectedContact?.plantContactId : 0,
    firstName: selectedContact?.firstName ? selectedContact?.firstName : "",
    lastName: selectedContact?.lastName ? selectedContact?.lastName : "",
    isMitsubishiContact: selectedContact?.isMitsubishiContact ? selectedContact?.isMitsubishiContact : false,
    jobTitle: selectedContact?.jobTitle ? selectedContact?.jobTitle : "",
    primaryPhone: selectedContact?.primaryPhone ? selectedContact?.primaryPhone : "",
    primaryPhoneTypeId: selectedContact?.primaryPhoneTypeId ? selectedContact?.primaryPhoneTypeId.toString() : "1",
    secondaryPhone: selectedContact?.secondaryPhone ? selectedContact?.secondaryPhone : "",
    secondaryPhoneTypeId: selectedContact?.secondaryPhoneTypeId ? selectedContact?.secondaryPhoneTypeId.toString() : "1",
    email: selectedContact?.email ? selectedContact?.email : "",
    companyName: selectedContact?.companyName ? selectedContact?.companyName : "",
  };

  if (!isEdit) {
    initialValues = {
      plantId: selectedPlant.plantId,
      PlantContactId: 0,
      avatarFile: "",
      firstName: "",
      lastName: "",
      isMitsubishiContact: true,
      jobTitle: "",
      primaryPhone: "",
      primaryPhoneTypeId: "1",
      secondaryPhone: "",
      secondaryPhoneTypeId: "1",
      email: "",
      companyName: "",
    };
  }
  const { setIsBlocked, setIsModalFormDirty } = useHandleBlocker();
  const CheckDirty = () => {
    const { dirty, touched } = useFormikContext();
    useEffect(() => {
        setIsBlocked(touched && dirty);
        setIsModalFormDirty(touched && dirty);  
    }, [dirty, touched]); 
    return null;
  };

  const onSuccess = (data: any) => {
    toBase64(data)
      .then((base64Image: any) => {
        setbase64Image(base64Image);
      })
      .catch((error) => {
        setbase64Image("");
      });
  };
  const onError = (error: AxiosError<string>) => {
    setbase64Image("");
  };
  const { isLoading } = useAvatar_PlantContact(isEdit, selectedContact?.plantContactId!, onSuccess, onError);

  const handleOnSubmit = (values: ContactFormInterface, formikHelpers: FormikHelpers<ContactFormInterface>) => {
    if (isEdit) {
      editPlantContacts(values, {
        onSuccess: (editResponseData) => {
            setIsModal?.({
              ...CloseAllModal,
              isEditedSuccess: {
                ...isModal.isEditedSuccess,
                isContactModal: true,
              },
            });
          if (editResponseData.data) {
            setContactApiResponseData?.(editResponseData);
            queryClient.invalidateQueries(queryKeys.plantContacts);
          }
        },
        onError(error, variables, context) {
          setErrorData(error);
          formikHelpers.setSubmitting(false);
        },
      });
    } else {
      addPlantContacts(values, {
        onSuccess: (addResponseData) => {
            setIsModal?.({
              ...CloseAllModal,
              isAddedSuccess: {
                ...isModal.isAddedSuccess,
                isContactModal: true,
              },
            });
          if (addResponseData.data) {
            setContactApiResponseData?.(addResponseData);
            queryClient.invalidateQueries(queryKeys.plantContacts);
          }
        },
        onError(error, variables, context) {
          setErrorData(error);
          formikHelpers.setSubmitting(false);
        },
      });
    }
  };

  const phoneRegExp = /^\+?[0-9]{0,2}[-. ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return (
    <>
      <ModalGlobal modaltitle={avatarUploadModal ? "Add Avatar" : modaltitle} modaltitlename={modaltitlename} isModalHeader={true} confirmBeforeClose={true}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          validationSchema={Yup.object({
            plantId: Yup.string().required("This field is required"),
            firstName: Yup.string().min(3).required("This field is required"),
            lastName: Yup.string().min(3).required("This field is required"),
            isMitsubishiContact: Yup.boolean().required("This field is required"),
            primaryPhoneTypeId: Yup.string().required("Primary phone type is required"),
            jobTitle: Yup.string().required("This field is required"),
            primaryPhone: Yup.string().matches(phoneRegExp, "Phone number is not valid").required("This field is required"),
            secondaryPhone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
            email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
            companyName: Yup.string().max(255).required("Company Name is required"),
          })}
        >
          {(formikProps) => {
            const avatarObject: { preview: string } = Object(formikProps.values.avatarFile);
            const { dirty, isSubmitting } = formikProps;
            const handleAvatarUploadClose = () => {
              setAvatarUploadModal(false);
              setContactAvatarImage?.([]);
              formikProps.setFieldValue("avatarFile", "");
            };
            return (
              <>
                {showError.isError && <ErrorCard ErrorMessage={showError.title} ErrorType={"danger"} />}

                <Form>
                  <CheckDirty/>
                  {
                    <div style={!avatarUploadModal ? { display: "block" } : { display: "none" }}>
                      {isAdding || isEditing ? (
                        <Loader containerStyle={{marginTop: "0"}} />
                      ) : (
                        <>
                          <div>
                            <div className={`flex flex-row ${isMobile? 'items-start justify-between' : 'items-center gap-[145px]'}`}>
                              <label className="font-bold">Avatar</label>
                              <div>
                                {formikProps.values.avatarFile ? (
                                  <div className={classes.avatarWrapper} onClick={handleUploadModalOpen}>
                                    <div
                                      className={classes.avatarImage}
                                      style={{
                                        background: `url(${avatarObject.preview}) center center  no-repeat`,
                                      }}
                                    ></div>
                                    <div className={classes.avatarBtn}>Add Avatar</div>
                                  </div>
                                ) : isEdit ? (
                                  <>
                                    <div className={classes.avatarWrapper} onClick={handleUploadModalOpen}>
                                      {base64Image.length > 0 && !isLoading ? (
                                        <div
                                          className={classes.avatarImage}
                                          style={{
                                            background: `url(${base64Image}) center center  no-repeat`,
                                          }}
                                        ></div>
                                      ) : (
                                        <div className={classes.avatarImage}>
                                          <span className={classes.avatarDefault}>
                                            {formikProps.values.firstName.slice(0, 1)}
                                            {formikProps.values.lastName.slice(0, 1)}
                                          </span>
                                        </div>
                                      )}
                                      <div className={classes.avatarBtn}>Add Avatar</div>
                                    </div>
                                  </>
                                ) : (
                                  <div className={classes.avatarWrapper} onClick={handleUploadModalOpen}>
                                    <>
                                      {/* {console.log("🚀 ~ file: FormContacts.tsx ~ line 232 ~ FormContacts ~ avatarFile Edit add")} */}
                                      <div className={`${classes.avatarImage} ${classes.avatarImageAdd}`}>
                                        <img src={add} alt="Add" />
                                      </div>
                                      <div className={classes.avatarBtn}>Add Avatar</div>
                                    </>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div>
                            <TextInputField
                              name="firstName"
                              labelName="First Name*"
                              placeholder="First name"
                              className=""                             
                            />
                          </div>
                          <div>
                            <TextInputField name="lastName" labelName="Last Name*" className="" placeholder="Last Name" />
                          </div>
                          <div className="text-black">
                            <DropDownSelect
                              name="isMitsubishiContact"
                              options={contactStaticType.data}
                              labelName="Contact Type*"
                              defaultValue={isEdit ? selectedContact?.isMitsubishiContact! : true}
                              spanClassName="text-black"
                              optionClassName="text-black"
                            />
                          </div>
                          <div>
                            <TextInputField name="companyName" labelName="Company Name*" className="" placeholder="Company Name" />
                          </div>
                          <div>
                            <TextInputField name="jobTitle" labelName="Job Title*" className="" placeholder="Job Title" />
                          </div>

                          <div>
                            <TextInputField name="primaryPhone" labelName="Primary Phone*" className="" placeholder="(000) 000-0000" />
                          </div>
                          <div>
                            <RadioButtonsGrouped
                              name="primaryPhoneTypeId"
                              firstRadioName="office"
                              firstRadioValue="1"
                              SecondRadioName="mobile"
                              SecondRadioValue="2"
                              labelName=""
                              className=""
                            />
                          </div>
                          <div>
                            <TextInputField name="secondaryPhone" labelName="Secondary Phone" className="" placeholder="(000) 000-0000" />
                          </div>
                          <div>
                            <RadioButtonsGrouped
                              name="secondaryPhoneTypeId"
                              firstRadioName="office"
                              firstRadioValue="1"
                              SecondRadioName="mobile"
                              SecondRadioValue="2"
                              labelName=""
                              className=""
                            />
                          </div>
                          <div>
                            <TextInputField name="email" labelName="Email*" className="" placeholder="example@domain.com" />
                          </div>
                        </>
                      )}
                      <div className="flex align-center">
                        <div className="flex-grow-1 flex gap-4">
                          {isEdit ? (
                            <>
                              <button type="submit" className="primaryBtn darkBlue" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                                Save Contact
                              </button>
                              <button
                                type="button"
                                className="primaryBtn redDelete"
                                onClick={() => setIsModal?.({
                                  ...CloseAllModal,
                                  isDelete: {
                                    ...isModal.isDelete,
                                    isContactModal: true,
                                  },
                                })}
                              >
                                Delete
                              </button>
                            </>
                          ) : (
                            <>                            
                              <button type="submit" className="primaryBtn darkBlue" disabled={!!(
                                !formikProps.values.email.length || !formikProps.values.firstName.length ||
                                !formikProps.values.lastName.length || !formikProps.values.jobTitle.length || 
                                !formikProps.values.primaryPhone.length
                              )
                                }>
                                Add Contact
                              </button>
                            </>
                          )}
                        </div>
                        <span style={{ margin: isMobile ? "auto" : "inherit" }}>*Required fields</span>
                      </div>
                    </div>
                  }

                  {
                    <div style={avatarUploadModal ? { display: "block" } : { display: "none" }}>
                      <DragDropField name="avatarFile" labelName="" placeholder="" isEdit={false} defaultImage="" showFilename={false} fileAcceptance={{'image/jpeg': [], 'image/png': [],  }} />
                      <div className={classes.marginBottom25}>Accepted file types: JPG, PNG; Max file size: 1GB</div>

                      <div className="flex align-center justify-center">
                        <div className="flex-grow-1 flex gap-4">
                          <button type={"button"} className="primaryBtn darkBlue" onClick={handleGetImageDat} disabled={!dirty || isSubmitting}>
                            Add Avatar
                          </button>
                          <button type={"button"} className="primaryBtn whiteBtn" onClick={handleAvatarUploadClose}>
                            Cancel
                          </button>
                        </div>
                        <span>*Required fields</span>
                      </div>
                    </div>
                  }
                </Form>
              </>
            );
          }}
        </Formik>
      </ModalGlobal>
    </>
  );
};

interface FormAddContactsProps {
  modaltitle: string;
  modaltitlename: string;
  isEdit: boolean;
  selectedContact?: PlantContact;
  setContactApiResponseData?: Dispatch<React.SetStateAction<PlantContactDTO | null>>;

}

export default FormContacts;
