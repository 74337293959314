import { generatePath, useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import "./SupportTile.css";

function SupportTile() {
  let navigate = useNavigate();
  return (
    <div className="card support">
      <div className="row-to-col lg:w-1/2">
        <div className="support-header">Customer Information Requests</div>
        <div className="support-body font14">Click below to submit / view all Non-Technical Information Requests</div>
        <div className="support-link">
          <Button
            onClick={() => navigate(generatePath("/support/esr/:supportModuleName", { supportModuleName: "Engineering Support Requests" }))}
            className="whiteBtn"
            text="View Information Requests"
          />
        </div>
      </div>
      <div className="row-to-col lg:w-1/2">
        <div className="support-header">Frequently Asked Questions</div>
        <div className="support-body font14">
          Contains a list of commonly asked questions and answers related to topics such as Product Information, Technical Information and Portal
          Content
        </div>
        <div className="support-link">
          <Button
            onClick={() => navigate(generatePath("/support/faq/:supportModuleName", { supportModuleName: "Frequently Asked Questions" }))}
            className="whiteBtn"
            text="Frequently Asked Questions"
          />
        </div>
      </div>
    </div>
  );
}

export default SupportTile;
