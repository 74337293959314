import { AxiosError } from "axios";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { Store } from "../../Store";
import { UnitDetailsContainer } from "../../containers/Administration/ModuleSpecific/PlantManagement/UnitDetails";
import { getPlantDetails, getPlantDetailsParameters } from "../../containers/PlantInformation/DashboardHttpServices";
import { useGetFile_PlantConfigData, useUpdateSystemDetails } from "../../containers/PlantInformation/PlantConfigHttpServices";
import { useApiPlantFuelType, useApiUnitConfigurationTypes } from "../../containers/PlantInformation/TypesHttpServices";
import { useCreate_UnitData, useDeleteUnitData, useGetAll_UnitData, useUpdate_UnitData } from "../../containers/PlantInformation/UnitHttpServices";
import { useAddBaseOrgToGroup, useGetAllBaseOrgGroups, useGetBaseOrg, useRemoveBaseOrgFromGroup } from "../../customHooks/PlantHttpServices";
import { queryKeys } from "../../react-query/queryKeys";
import { toBase64 } from "../../utils/FileUtils";
import ImageImports from "../../utils/ImageImports";
import { BaseOrgGroup, PlantConfigData, PlantSiteDetails, PlantSiteDetailsDTO, PlantSystemDetails, TypeDTO, UnitDATA } from "../../utils/interface";
import { pick } from "../../utils/pick";
import Alert from "../Alert/Alert";
import Button from "../Button/Button";
import Loader from "../Loader/Loader";
import PlantSiteDataBlock from "../PlantSiteDetails/PlantSiteDataBlock/PlantSiteDataBlock";
import { DropDownOptionItem, PlainDropDown } from "../UI/Form/DropDownSelect/PlainDropDown";
import { AddEditUnit } from "./AddEditUnit";
import { AddEditUnitConfirmation } from "./AddEditUnitConfirmation";
import { DeleteUnitReason } from "./DeleteUnitReason";
import { EditPlantSystemDetails } from "./EditPlantSystemDetails";

const { pencilWhite, Arrow, pencil } = ImageImports;

export const PlantDetails = ({ plant }: { plant: PlantConfigData }) => {

	const navigate = useNavigate();

	const [plantImage, setPlantImage] = useState<string>();
	const [plantDetails, setPlantDetails] = useState<PlantSiteDetails>();
	const [addEditUnit, setAddEditUnit] = useState<UnitDATA>();
	const [addEditUnitConfirmation, setAddEditUnitConfirmation] = useState<UnitDATA>();
	const [deleteUnitReason, setDeleteUnitReason] = useState<UnitDATA>();
	const [editSystemDetails, setEditSystemDetails] = useState<PlantSystemDetails>();
	const [selectedOrgGroups, setSelectedOrgGroups] = useState<number[]>([]);
	const [savedOrgGroups, setSavedOrgGroups] = useState<number[]>([]);
	const [addAnotherOrgGroup, setAddAnotherOrgGroup] = useState<number>();

	const { refetch: getPlantUnits, data: plantUnits } = useGetAll_UnitData(plant.plantId, () => { }, () => { setPlantUnits(undefined) });
	const [plantUnitData, setPlantUnits] = useState(plantUnits)
	const { refetch: refetchOrgGroups } = useGetBaseOrg(plant.baseOrgId, {
		onSuccess: data => {
			const ogIds = (data.orgGroups || []).map(d => d.id);
			setSelectedOrgGroups(ogIds);
			setSavedOrgGroups(ogIds);
		}
	});

	const queryClient = useQueryClient();

	const addRemoveBaseOrgGroupsData = useMemo(() => {
		return {
			baseOrgId: plant.baseOrgId,
			orgGroupId: 0
		};
	}, [plant]);

	const { setShowError, setFuelType, setUnitConfigurationTypes } = useContext(Store);
	const { mutate: createUnit } = useCreate_UnitData({
		onSuccess: () => {
			getPlantUnits();
		}
	});
	const { mutate: updateUnit } = useUpdate_UnitData({
		onSuccess: () => {
			getPlantUnits();
		}
	});
	const { mutate: deleteUnit } = useDeleteUnitData({
		onSuccess: () => {
			getPlantUnits();
		},
		onError: (err) => {
			setShowError?.({
				title: `${(err as AxiosError).response?.data}`,
				ErrorType: "danger",
				isError: true,
			})
		}
	});

	const defaultUnitData: UnitDATA = useMemo(() => {
		return {
			unitId: 0,
			plantId: plant?.plantId || 0,
			name: '',
			parentGroupOwnership: '',
			operator: '-',
			directOwnership: '',
			coDate: '',
			fuelTypeId: 0,
			unitConfigurationId: 0,
			totalMW: 0,
			notes: '',
			fuelTypeName: '',
			unitConfigurationName: '',
			equipmentCount: 0
		};
	}, [plant]);

	const { isLoading: isLoadingPlantImage, isFetching: isFetchingPlantImage } = useGetFile_PlantConfigData(
		plant.plantId,
		(data: any) => {
			toBase64(data)
				.then((base64Image: any) => {
					setPlantImage?.(base64Image);
				})
				.catch((error) => {
					setPlantImage?.("");
				});
		},
		(error: AxiosError<string>) => {
			setPlantImage?.("");
		}
	);
	useApiPlantFuelType((data: TypeDTO) => {
		setFuelType && setFuelType(data);
	}, () => { });

	useApiUnitConfigurationTypes((data: TypeDTO) => {
		setUnitConfigurationTypes && setUnitConfigurationTypes(data);
	}, () => { });

	const { isLoading: isLoadingPlantDetails, isFetching: isFetchingPlantDetails } = useQuery<PlantSiteDetailsDTO, unknown, PlantSiteDetailsDTO, getPlantDetailsParameters>([queryKeys.plantSiteDetails, { plantId: plant.plantId }], getPlantDetails, {
		onSuccess: ({ data }: { data: PlantSiteDetails }) => setPlantDetails(data),
		onError: (err: unknown) =>
			setShowError?.({
				title: `${(err as AxiosError).response?.data}`,
				ErrorType: "danger",
				isError: true,
			}),
	});

	const saveUnitCallback = useCallback((unit: UnitDATA) => {
		setAddEditUnit(undefined);
		setAddEditUnitConfirmation(unit);
		queryClient.invalidateQueries([queryKeys.unitGetAll, plant.plantId]);
	}, []);

	const saveUnit = useCallback((unit: UnitDATA) => {
		if (unit.unitId > 0) {
			updateUnit(unit, {
				onSuccess: saveUnitCallback
			})
		} else {
			createUnit(unit, {
				onSuccess: () => saveUnitCallback(unit)
			});
		}
	}, []);

	const removeUnit = useCallback((unit: UnitDATA, reason: string) => {
		deleteUnit({ unit, reason });
		setDeleteUnitReason(undefined);
	}, []);

	useEffect(() => { setPlantUnits(plantUnits) }, [plantUnits])

	const { mutate: updateSystemDetails } = useUpdateSystemDetails({
		onSuccess: () => {
			queryClient.invalidateQueries([queryKeys.plantConfig, { plantId: plant.plantId }])
		}
	});

	const { mutateAsync: addBaseOrgToGroup } = useAddBaseOrgToGroup();
	const { mutateAsync: removeBaseOrgFromGroup } = useRemoveBaseOrgFromGroup();

	const saveSystemDetails = useCallback((plantSystemDetails: PlantSystemDetails) => {
		updateSystemDetails({
			plantId: plant.plantId,
			plantSystemDetails
		});
		setEditSystemDetails(undefined);
	}, []);

	const systemDetails: PlantSystemDetails = useMemo(() => {
		return pick(plant, ['esrPlantName', 'spLegacyID', 'tomoniDashPlantName']);
	}, [plant]);

	const { data: orgGroups } = useGetAllBaseOrgGroups();

	const selectableOrgGroups: DropDownOptionItem[] = useMemo(() => {
		if (orgGroups) {
			return orgGroups.filter(group => group.deleted === null).map(d => pick(d, ['id', 'name']));
		}
		return [];
	}, [orgGroups]);

	const saveBaseOrgGroups = async () => {
		let i;
		for (i = 0; i < selectedOrgGroups.length; i++) {
			if (!savedOrgGroups.includes(selectedOrgGroups[i])) {
				await addBaseOrgToGroup({
					...addRemoveBaseOrgGroupsData,
					orgGroupId: selectedOrgGroups[i]
				});
			}
		}
		for (i = 0; i < savedOrgGroups.length; i++) {
			if (!selectedOrgGroups.includes(savedOrgGroups[i]) && savedOrgGroups[i] !== addAnotherOrgGroup) {
				await removeBaseOrgFromGroup({
					...addRemoveBaseOrgGroupsData,
					orgGroupId: savedOrgGroups[i]
				});
			}
		}
		if (addAnotherOrgGroup && !savedOrgGroups.includes(addAnotherOrgGroup)) {
			await addBaseOrgToGroup({
				...addRemoveBaseOrgGroupsData,
				orgGroupId: addAnotherOrgGroup
			});
		}
		refetchOrgGroups();
		setAddAnotherOrgGroup(undefined);
	};

	const canSave = useMemo(() => {
		return (addAnotherOrgGroup !== undefined && addAnotherOrgGroup > 0) || Object.entries([...selectedOrgGroups].sort()).toString() !== Object.entries([...savedOrgGroups].sort()).toString();
	}, [addAnotherOrgGroup, selectedOrgGroups, savedOrgGroups]);	
	return (
		<div className="flex flex-col items-start gap-8">
			{addEditUnit && <AddEditUnit unit={addEditUnit} onSave={saveUnit} onClose={() => setAddEditUnit(undefined)} onDelete={unit => {
				setAddEditUnit(undefined);
				setDeleteUnitReason(unit);
			}} />}
			{addEditUnitConfirmation && <AddEditUnitConfirmation unit={addEditUnitConfirmation} onClose={() => setAddEditUnitConfirmation(undefined)} />}
			{deleteUnitReason && <DeleteUnitReason unit={deleteUnitReason} plant={plant.baseOrgName} onDelete={removeUnit} onClose={() => setDeleteUnitReason(undefined)} />}
			{editSystemDetails && <EditPlantSystemDetails systemDetails={editSystemDetails} onClose={() => setEditSystemDetails(undefined)} onSave={saveSystemDetails} />}
			<Outlet context={{ plant, plantImage, setPlantImage }} />
			<div className="flex flex-col items-start gap-8">
				<Link to={`/administration/Plant-Management`} className="no-underline">
					<div className="inline-flex flex-row gap-[10px] items-center font-bold text-dark-blue text-sm">
						<img src={Arrow} alt="Back" className="rotate-180" style={{ filter: 'var(--svgFilterDarkBlueButton)' }} />
						<span>Back to Mitsubishi Power Plants</span>
					</div>
				</Link>
				<span className="font-bold text-xl">{plant.baseOrgName} Details</span>
			</div>
			{plantUnitData === undefined ? (
				<Alert onClose={() => { }} type="warning" dismissible={false}>
					<>
						This plant currently does not have any units configured. Unit configurations are required for external customers to add relevant equipment information.&nbsp;
						<span className="font-bold underline cursor-pointer" onClick={() => setAddEditUnit(defaultUnitData)}>Add Unit(s)</span>
					</>
				</Alert>
			) : ''}
			<div className="w-full">
				<div className="flex flex-col items-start gap-8">
					<div className="flex flex-col gap-6">
						<span className="font-bold text-xl">Organization Associations (Optional)</span>
						<span className="w-3/4">
							This plant can be added to an infinite list of organization groups. All active Mitsubishi Power organizations can be managed from the{" "}
							<Link className="no-underline" to="/administration/organization-management">Organization Management</Link> page.
						</span>
					</div>
					<div className="flex flex-col items-start gap-2">
						{selectedOrgGroups.length > 0 && (
							<>
								<span className="">Organization*</span>
								{selectedOrgGroups.map((g, i) => (
									<div className="flex flex-row items-center gap-6" key={i}>
										<PlainDropDown onSelection={(item: (DropDownOptionItem)) => {
											const si = [...selectedOrgGroups];
											si.splice(i, 1, item.id as number);
											setSelectedOrgGroups(si);
										}}
											className="grow hover:cursor-pointer"
											options={selectableOrgGroups}
											value={g}
											defaultText="Select a unit"
											selectClass="flex flex-row pt-1 pr-2 !pb-1 pl-1 border-0 lg:min-w-[240px] justify-between border border-solid border-[#999] w-full rounded"
											optionsClass="p-4 bg-white rounded" itemClass="py-[14px] px-6 cursor-pointer hover:bg-primary-20" />
										<span className="text-dark-blue font-bold cursor-pointer" onClick={async () => {
											await removeBaseOrgFromGroup({
												...addRemoveBaseOrgGroupsData,
												orgGroupId: g
											});
											refetchOrgGroups();
										}}>Remove Association</span>
									</div>
								))}
							</>
						)}
						{addAnotherOrgGroup !== undefined && (
							<PlainDropDown onSelection={(item: (DropDownOptionItem)) => {
								setAddAnotherOrgGroup(item.id as number);
							}}
								className="grow"
								options={[{ id: 0, name: 'Select Organization' }, ...selectableOrgGroups.filter(d => !selectedOrgGroups.includes(d.id as number))]}
								value={addAnotherOrgGroup}
								defaultText="Select a unit"
								selectClass="flex flex-row pt-1 pr-2 !pb-1 pl-1 border-0 lg:min-w-[240px] justify-between border border-solid border-[#999] w-full rounded"
								optionsClass="p-4 bg-white rounded" itemClass="py-[14px] px-6 cursor-pointer hover:bg-primary-20" />
						)}
					</div>
					<div>
						<Button className={`whiteBtn`} disabled={addAnotherOrgGroup !== undefined} text={`Add to ${selectedOrgGroups.length > 0 ? 'Another ' : ''}Organization`} onClick={() => setAddAnotherOrgGroup(0)} />
					</div>
				</div>
				<hr />
				<div className="flex flex-col items-start gap-6">
					<div className="flex flex-row items-center gap-4">
						<div>
							<h4>System Details</h4>
						</div>
						<div>
							<div className="flex flex-row !pb-2 items-center gap-[10px] cursor-pointer" onClick={() => setEditSystemDetails(systemDetails)}>
								<img src={pencil} alt="Edit" />
								<span className="text-ocean font-bold text-sm">Edit Details</span>
							</div>
						</div>
					</div>
					<div className="flex flex-col items-start gap-1">
						<span className="font-bold text-sm">Legacy SharePoint ID:</span>
						<span className="text-sm">{systemDetails.spLegacyID || ''}</span>
					</div>
					<div className="flex flex-col items-start gap-1">
						<span className="font-bold text-sm">ESR Plant Name:</span>
						<span className="text-sm">{systemDetails.esrPlantName}</span>
					</div>
					<div className="flex flex-col items-start gap-1">
						<span className="font-bold text-sm">TOMONI Dashboard Plant Name:</span>
						<span className="text-sm">{systemDetails.tomoniDashPlantName}</span>
					</div>
				</div>
				<hr />
				<div className="flex flex-col gap-4">
					<div className="flex flex-row items-center gap-4">
						<span className="font-bold text-xl">{plant.baseOrgName}</span>
						<Link className="flex flex-row items-center gap-[10px] cursor-pointer text-dark-blue no-underline" to="./edit">
							<img src={pencil} alt="Edit" />
							<span className="text-ocean font-bold text-sm">Edit Details</span>
						</Link>
					</div>
					<div className="flex flex-row items-start gap-6">
						{isLoadingPlantImage || isFetchingPlantImage ? <Loader /> : <img src={plantImage} className="w-[272px] h-[215px]" alt="" />}
						<div className="flex flex-row items-start" style={{columnGap: "5rem"}}>
							<div className="flex flex-col items-start gap-6">
								{isLoadingPlantDetails || isFetchingPlantDetails ? <Loader /> : (
									<>
										<div className="flex flex-col items-start gap-1">
											{
												plantDetails?.plantLoad ? <>
													<span className="font-bold text-sm">TOMONI Hub Monitored Output</span>
													<div className="flex flex-row items-start gap-1">
														<span className="font-bold text-40">{plantDetails?.plantLoad}</span>
														<span className="font-bold text-xs">MW</span>
													</div>
												</>
													: <>
														<strong style={{ color: "#991B1B" }} className="text-sm">TOMONI Hub Monitored Output</strong>
														<p style={{ margin: "2% 0 0", fontSize: "14px", color: "#991B1B" }}>Unavailable</p>
													</>
											}
										</div>
										<div className="flex flex-col items-start gap-1">
											<PlantSiteDataBlock
												label={"Ambient Temperature"}
												value1={
													plantDetails?.ambientTemperatureCelsius || plantDetails?.ambientTemperatureFahrenheit ? `${plantDetails?.ambientTemperatureFahrenheit}\u00B0 F / ${plantDetails?.ambientTemperatureCelsius}\u00B0 C` : "Unavailable"
												}
											/>
										</div>

									</>
								)}
								<div className="flex flex-col items-start gap-1">
									<PlantSiteDataBlock label={"Frequency"} value1={(plant.frequency.includes("Hz") ? plant.frequency : plant.frequency+" Hz") || "Unavailable"} />
								</div>
							</div>
							<div className="flex flex-col items-start gap-6">
								<PlantSiteDataBlock label={"Last Planned Outage"} value1={plant.lastOutage ? `${plant.lastOutage}` : "Unavailable"} />
								<PlantSiteDataBlock
									label={"Next Planned Outage"}
									value1={plant.nextPlannedOutage ? `${plant.nextPlannedOutage}` : "Unavailable"}
								/>
							</div>
						</div>
					</div>
					<div className="flex flex-row items-start" style={{ columnGap: "1.75rem" }}>
						<div className="flex flex-row items-start basis-5/12 flex-wrap" style={{ columnGap: "5rem", rowGap: "1rem" }}>
							<div className="inline-flex flex-col flex-wrap items-start gap-1 min-w-[40%]">
								<span className="font-bold text-sm">Owner</span>
								<span className="text-sm">{plant.owner}</span>
							</div>
							<div className="inline-flex flex-col flex-wrap items-start gap-1 min-w-[40%]">
								<span className="font-bold text-sm">Operator</span>
								<span className="text-sm">{plant.operators}</span>
							</div>
							<div className="inline-flex flex-col flex-wrap items-start gap-1 min-w-[40%]">
								<span className="font-bold text-sm">Address</span>
								<span className="text-sm">
									{plant.address1}
									<br />
									{plant.address2}
									<br />
									{plant.address3}
								</span>
							</div>
							<div className="inline-flex flex-col flex-wrap items-start gap-1 min-w-[40%]">
								<span className="font-bold text-sm">MHI Region</span>
								<span className="text-sm">{plant.mhiRegion}</span>
							</div>
						</div>
						<div className="flex flex-row items-start">
							<div className="flex flex-col items-start gap-1">
								<span className="font-bold text-sm">Notes</span>
								<span className="text-sm">{plant.notesComments}</span>
							</div>
						</div>
					</div>
				</div>
				<hr />
				<div className="flex flex-col items-start gap-8">
					<div>
						<div style={{ display: "flex", alignItems: "center", gap: 0 }}>
							<h4>Configuration Details</h4>
						</div>
					</div>
					{plantUnitData && <UnitDetailsContainer plantUnits={plantUnitData} onEdit={setAddEditUnit} />}
					<Button className={`whiteBtn`} disabled={false} text={"Add Unit"} onClick={() => setAddEditUnit(defaultUnitData)} />
				</div>
				<hr />
				<Button className={`darkBlue`} disabled={!canSave} text={"Save Changes"} onClick={saveBaseOrgGroups} />
			</div>
		</div>
	);
}

export default PlantDetails;