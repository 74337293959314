import React, { useContext, useEffect } from "react";
import ModalGlobal from "../../UI/Modal/ModalGlobal";
import { Form, Formik, FormikHelpers, useFormikContext } from "formik";
import * as Yup from "yup";
import TextInputField from "../../UI/Form/TextInputField/TextInputField";
import DropDownSelect from "../../UI/Form/DropDownSelect/DropDownSelect";
import TextAreaField from "../../UI/Form/TextAreaField/TextAreaField";
import { Store } from "../../../Store";
import { useUpdate_UnitData } from "../../../containers/PlantInformation/UnitHttpServices";
import { CloseAllModal } from "../../../utils/CloseAllModal";
import { UnitDATA } from "../../../utils/interface";
import { useQueryClient } from "react-query";
import Loader from "../../Loader/Loader";
import { queryKeys } from "../../../react-query/queryKeys";
import { useOnError } from "../../../customHooks/useOnError";
import ErrorCard from "../../ErrorCard/ErrorCard";
import useWindowSize from "../../../customHooks/useWindowSize";
import { useApiPlantFuelType, useApiUnitConfigurationTypes } from "../../../containers/PlantInformation/TypesHttpServices";
import dayjs from "dayjs";
import useHandleBlocker from "../../../customHooks/useHandleBlocker";

const FormUnitAddEdit = ({ isEdit, modaltitle, modaltitlename }: FormEquipmentAddEditProps) => {
  const {
    fuelType: { data: fuelTypes },
    selectedPlant,
    isModal,
    setShowError,
    unitConfigurationTypes: { data: unitConfigTypes },
    unit: { data: uData },
    setIsModal,
    showError,
    setUnitConfigurationTypes,
    setFuelType
  } = useContext(Store);
  const queryClient = useQueryClient();
  const { isMobile, isTablet, isDesktop, isTabletLandscape } = useWindowSize();
  const setErrorData = useOnError();

  // const { mutate: CreateUnit, isLoading: isAdding, isSuccess: isAdded } = useCreate_UnitData();
  const { mutate: EditUnitData, isLoading: isEditing } = useUpdate_UnitData();

  const handleOnSubmit = (values: UnitDATA, formikHelpers: FormikHelpers<UnitDATA>) => {
    if (isEdit) {
      EditUnitData(values, {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKeys.unit);
          formikHelpers.setSubmitting(false);
          setIsModal!({
            ...CloseAllModal,
            isEditedSuccess: { ...isModal.isEditedSuccess, isUnit: true },
          });
        },
        onError: (error: unknown) => {
          setErrorData(error);
        },
      });
    } else {
      // CreateUnit(values, {
      //   onSuccess: (apiResponseEditData) => {
      //     document.body.classList.remove("noScroll");
      //     // setEquipmentApiResponseData(apiResponseEditData.data);
      //     queryClient.invalidateQueries(queryKeys.unit);
      //     setIsModal!({
      //       ...CloseAllModal,
      //       isAddedSuccess: { ...isModal.isAdd, isUnit: true },
      //     });
      //   },
      //   onError: (error: unknown) => {
      //     const title = error instanceof Error ? error.message : "error connecting to server";
      //     setShowError &&
      //       setShowError({
      //         isError: true,
      //         title: title,
      //         ErrorType: "danger",
      //       });
      //     document.body.classList.remove("noScroll");
      //     setIsModal!({
      //       ...CloseAllModal,
      //     });
      //   },
      // });
    }
  };
  useApiPlantFuelType(setFuelType, setErrorData);
  useApiUnitConfigurationTypes(setUnitConfigurationTypes, setErrorData);

  let initialValues: UnitDATA;

  initialValues = {
    unitId: uData ? uData.unitId : 0,
    plantId: selectedPlant.plantId,
    name: uData ? uData.name : "",
    parentGroupOwnership: uData ? uData.parentGroupOwnership : "",
    operator: uData ? uData.operator : "",
    directOwnership: uData ? uData.directOwnership : "",
    coDate: uData ? dayjs(uData.coDate).format('MM/DD/YYYY') : "",
    fuelTypeId: uData ? uData.fuelTypeId : 0,
    unitConfigurationId: uData ? uData.unitConfigurationId : 0,
    totalMW: uData ? uData.totalMW : 0,
    notes: uData && uData.notes ? uData.notes : "",
    fuelTypeName: uData ? uData.fuelTypeName : "",
    unitConfigurationName: uData ? uData.unitConfigurationName : "",
    equipmentCount: uData ? uData.equipmentCount : 0,
  };

  if (!isEdit) {
    initialValues = {
      unitId: 0,
      plantId: selectedPlant.plantId,
      name: "",
      parentGroupOwnership: "",
      operator: "",
      directOwnership: "",
      coDate: "",
      fuelTypeId: 0,
      unitConfigurationId: 0,
      totalMW: 0,
      notes: "",
      fuelTypeName: "",
      unitConfigurationName: "",
      equipmentCount: 0,
    };
  }

  const { setIsBlocked, setIsModalFormDirty } = useHandleBlocker();
  const CheckDirty = () => {
    const { dirty, touched } = useFormikContext();
    useEffect(() => {
        setIsBlocked(touched && dirty);
        setIsModalFormDirty(touched && dirty);  
    }, [dirty, touched]); 
    return null;
  };

  return (
    <ModalGlobal modaltitle={modaltitle} modaltitlename={modaltitlename} isModalHeader={true} confirmBeforeClose={true}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        validationSchema={Yup.object({
          unitId: Yup.number().required("This field is required"),
          plantId: Yup.number().required("This field is required"),
          name: Yup.string().required("This field is required"),
          parentGroupOwnership: Yup.string().required("This field is required"),
          directOwnership: Yup.string().required("This field is required"),
          coDate: Yup.string().required("This field is required"),
          fuelTypeId: Yup.number().required("This field is required"),
          unitConfigurationId: Yup.number().required("This field is required"),
          totalMW: Yup.number().required("This field is required"),
        })}
      >
        {(formikProps) => (
          <>
            {showError.isError && <ErrorCard ErrorMessage={showError.title} ErrorType={"danger"} />}
            <Form>
              <CheckDirty />
              {isEditing || formikProps.isSubmitting ? (
                <Loader />
              ) : (
                <>
                  <div>
                    <DropDownSelect
                      name="unitConfigurationId"
                      options={unitConfigTypes}
                      labelName="Unit Configuration*"
                      defaultValue={uData ? uData.unitConfigurationId : 0}
                    />
                  </div>
                  <div>
                    <TextInputField name="totalMW" labelName="Output Potential*" placeholder="" className="" />
                  </div>
                  <div>
                    <TextInputField name="coDate" labelName="CO Date*"
                      tooltip={{
                        text: "Commercial Operation Date",
                      }}
                      className="" placeholder="" 
                    />

                  </div>
                  <div>
                    <DropDownSelect
                      name="fuelTypeId"
                      options={fuelTypes}
                      labelName="Fuel Type*"
                      defaultValue={uData ? uData.fuelTypeId : 0}
                      spanClassName={`max-w-[200px] sm:max-w-sm overflow-hidden text-ellipsis whitespace-nowrap`}
                    />
                  </div>
                  <div>
                    <TextInputField name="directOwnership"
                      tooltip={{
                        text: "Listed Plant Site Owner",
                      }}
                      labelName="Direct Ownership*" className="" placeholder="" />
                  </div>
                  <div>
                    <TextInputField name="parentGroupOwnership"
                      tooltip={{
                        text: "Ultimate Plant Site Parent Company",
                      }}
                      labelName="Parent Owner*" className="" placeholder="" />
                  </div>
                  <div>
                    <TextAreaField name={"notes"} height={!isMobile ? 46 : 100} className={""} labelName={"Notes"} display={"inline"} />
                  </div>
                  <div className="flex align-center marginBot30">
                    <div className="flex-grow-1 font14">
                      OPTIONAL: Once equipment has been added, you may add an attachment (e.g. nameplate asset) before returning to the Plant
                      Information page.
                    </div>
                  </div>
                </>
              )}

              <div className="flex align-center">
                <div className="flex-grow-1 flex">
                  <button type="submit" className="primaryBtn darkBlue" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                    Save Changes
                  </button>
                </div>
                <span>*Required fields</span>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </ModalGlobal>
  );
};

interface FormEquipmentAddEditProps {
  modaltitle: string;
  modaltitlename: string;
  isEdit: boolean;
  // setEquipmentApiResponseData: Dispatch<React.SetStateAction<PlantEquipmentResponseData | null>>;
}

export default FormUnitAddEdit;
