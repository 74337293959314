import { generatePath, useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import classes from "./RelatedPnSinformation.module.css";

const RelatedPnSinformation = () => {
  const navigate = useNavigate();

  return (
    <div className={`flex mt-14 flex-col`}>
      <h2 className={`text-xl mb-8 text-black capitalize md:text-xl lg:text-2xl`}>Related Parts & Service Information</h2>
      <div className={classes.flex}>
        <div className={" w-full mb-8 lg:mb-0 lg:w-1/3 lg:pr-4 flex flex-auto flex-col align-baseline"}>
          <h4 className="text-[#3C5D69] text-lg md:text-base lg:text-xl">Parts Catalog</h4>
        
          <p className="text-sm">Click below for Parts Catalogs related to your Plant, Unit and Equipment.</p>
          <div className="flex flex-auto flex-col align-baseline justify-end">
            <Button
              text={"View Parts Catalog"}
              className={"primaryBtn whiteBtn mb-0 w-full md:w-[300px] lg:w-auto"}
              onClick={() => navigate(generatePath("/parts&service/:moduleName", { moduleName: "Parts Catalog" }))}
            />
          </div>
        </div>
        <div className={"w-full mb-8 lg:mb-0 lg:w-1/3 lg:pr-4 flex flex-auto flex-col align-baseline"}>
          <h4 className="text-[#3C5D69] text-lg md:text-base lg:text-xl">Technical Documents</h4>       
          <p className="text-sm">
            Click below for technical documents related to your Plant and Equipment and a link to the Technical Bulletin Web.
          </p>
          <div className="flex flex-auto flex-col align-baseline justify-end">
            <Button
              text={"View Technical Documents"}
              className={"primaryBtn whiteBtn mb-0 w-full md:w-[300px] lg:w-auto"}
              onClick={() => navigate(generatePath("/technical-documentation/:moduleName", { moduleName: "Technical Bulletins" }))}
            />
          </div>
        </div>
        <div className={"w-full mb-8 lg:mb-0 lg:w-1/3 lg:pr-4 flex flex-auto flex-col align-baseline"}>
          <h4 className="text-[#3C5D69] text-lg md:text-base lg:text-xl">O&M Manuals</h4>
          <p className="text-sm">Click below for Operation and Maintenance Manuals related to your Plant, Unit and Equipment.</p>
          <div className="flex flex-auto flex-col align-baseline justify-end">
            <Button
              text={"View O&M Manuals"}
              className={"primaryBtn whiteBtn mb-0 w-full md:w-[300px] lg:w-auto"}
              onClick={() => navigate(generatePath("/technical-documentation/:moduleName", { moduleName: "O&M Manuals" }))}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelatedPnSinformation;
