import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGet_MessageAttachmentFileBlob } from "../containers/IssueReports/IssueReportHttpServices";
import { useOnError } from "./useOnError";

const useMessageAttachmentFileViewer = () => {
  const [getFileURL, setFileURL] = useState<string>("");
  const [getFileId, setFileId] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const setErrorData = useOnError();

  const { refetch: GetFileBlob } = useGet_MessageAttachmentFileBlob(getFileId, {
    enabled: getFileId > 0,
    onSuccess(blob) {
      var file = new Blob([blob], { type: blob.type });
      var fileURL = URL.createObjectURL(file);
      setFileURL(fileURL);
      !!fileURL && window.open(fileURL);
      setIsLoading(false);
    },
    onError(error) {
      setErrorData(error);
      setIsLoading(false);
      // navigate("/something-went-wrong");
    },
    onSettled() {
      URL.revokeObjectURL(getFileURL);
      setIsLoading(false);
      setFileId(0);
    },
  });

  const handleFileView = (FileId: number) => {
    setFileId(FileId);
    !!getFileId && GetFileBlob();
    setIsLoading(true);
  };

  return { handleFileView, isLoading };
};

export default useMessageAttachmentFileViewer;
