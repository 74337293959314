import dayjs from "dayjs";
import React, { CSSProperties, FunctionComponent, ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Accordion from "../../components/Accordian/Accordion";
import useWindowSize from "../../customHooks/useWindowSize";
import { QuoteRequestGetAll } from "../../utils/interface";
import { QuoteRequestEventID } from "../../utils/moduleIDs";
import classes from "./QuoteRequests.module.css";

export interface QuoteRequestColumnDefinition {
  title: string;
  key: keyof QuoteRequestGetAll;
  style: CSSProperties;
  component?: FunctionComponent<{ report: QuoteRequestGetAll; tooltip?: ReactElement<any, any> }>;
  className?: string;
}

const QuoteRequestItem: FunctionComponent<{
  QuoteRequestItem: QuoteRequestGetAll;
  index: number;
  unread: boolean;
}> = ({ QuoteRequestItem, index, unread }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false); // props.index === 0
  const [isEventClosed, setIsEventClosed] = useState<boolean>(false);
  const [modal, setModal] = useState<ReactElement>();
  const [QuoteRequest, setQuoteRequest] = useState(QuoteRequestItem);

  const navigate = useNavigate();

  const { isDesktop, isTablet, isMobile } = useWindowSize();

  useEffect(() => {
    setQuoteRequest(QuoteRequestItem);
  }, [QuoteRequestItem]);

  useEffect(() => {
    const closeAccordion = () => setIsOpen(false);
    window.addEventListener("resize", closeAccordion);
    return () => window.removeEventListener("resize", closeAccordion);
  }, []);

  useEffect(() => {
    if (QuoteRequest?.quoteRequestEvents) {
      const isEventPresent = QuoteRequest?.quoteRequestEvents
        .filter((e) => (e ? e : null))
        .map((e) => e.eventTypeId)
        .indexOf(QuoteRequestEventID.QuoteClosed);
      if (isEventPresent === -1) {
        setIsEventClosed(false);
      } else {
        setIsEventClosed(true);
      }
    } else {
      setIsEventClosed(false);
    }
  }, [QuoteRequest]);

  // const tooltipActions = Array<tooltipActionItem>;
  let Status;
  if (isEventClosed) {
    Status = <div className={`${classes.indicator} ${classes.closed}`}>Closed</div>;
  } else {
    Status = <div className={`${classes.indicator} ${classes.progress}`}>In Progress</div>;
  }

  const fields = (
    <>
      <td title="ID" className="whitespace-nowrap text-center">
        {QuoteRequest.quoteRequestId}
      </td>
      <td className="whitespace-nowrap" style={{ fontWeight: `${unread ? "bold" : "inherit"}` }} title="Type">
        {QuoteRequest.requestTypeName.replace('Quote', '').trim()}
      </td>
      <td title="Request Description">{QuoteRequest.description}</td>
      <td title="Equipment">{QuoteRequest.equipmentName}</td>
      <td title="Requestor" className="whitespace-nowrap">
        {QuoteRequest.customerContact ? QuoteRequest.customerContact : QuoteRequest.createdBy}
      </td>
      <td title="Submitted On" className="whitespace-nowrap">
        {dayjs(QuoteRequest.submittedOn).format('MM/DD/YYYY')}
      </td>
      <td title="Date Quote Needed" className="whitespace-nowrap">
        {dayjs(QuoteRequest.quoteNeededDate).format('MM/DD/YYYY')}
      </td>
      <td title="Priority">{QuoteRequest.quotePriority}</td>

      <td title="Status" className="whitespace-nowrap">
        {Status}
      </td>
      <td title="Details" className="text-center">
        <span
          style={{ color: "#3C5D69", fontWeight: "bold", fontSize: "14px", cursor: "pointer" }}
          onClick={() => navigate(`${QuoteRequest.globalItemId}`)}
        >
          View
        </span>
      </td>
    </>
  );  
  const Mobilefields = (
    <>
      <div title="ID">
        <div>{QuoteRequest.quoteRequestId}</div>
      </div>
      <div style={{ fontWeight: `${unread ? "bold" : "inherit"}` }} title="Type">
        <div>{QuoteRequest.requestTypeName.replace('Quote', '').trim()}</div>
      </div>
      <div title="Request Description">
        <div>{QuoteRequest.description}</div>
      </div>
      <div title="Equipment">
        <div>{QuoteRequest.equipmentName}</div>
      </div>
      <div title="Requestor">
        <div>{QuoteRequest.customerContact ? QuoteRequest.customerContact : QuoteRequest.createdBy}</div>
      </div>
      <div title="Submitted On">
        <div>{dayjs(QuoteRequest.submittedOn).format('MM-DD-YYYY')}</div>
      </div>
      <div title="Date Quote Needed">
        {QuoteRequest.quoteRequestType > 0 ? (
          <div>{dayjs(QuoteRequest.quoteNeededDate).format('MM-DD-YYYY')}</div>
        ) : (
          <div>{dayjs(QuoteRequest.partsNeededDate).format('MM-DD-YYYY')}</div>
        )}
      </div>
      <div title="Priority">
        <div>{QuoteRequest.quotePriority}</div>
      </div>

      <div title="Status">{Status}</div>
      <div title="Details">
        <div
          style={{ color: "#3C5D69", fontWeight: "bold", fontSize: "14px", cursor: "pointer" }}
          onClick={() => navigate(`${QuoteRequest.globalItemId}`)}
        >
          View
        </div>
      </div>
    </>
  );

  return (
    <>
      {isDesktop && (
        <>
          <tr
            style={{
              background: `${unread ? "rgba(0, 116, 158, 0.05)" : "inherit"}`,
            }}
          >
            {fields}
          </tr>
        </>
      )}
      {(isTablet || isMobile) && (
        <div className="flex flex-col flex-wrap grow">
          <Accordion
            isDeleted={false}
            titleContent={<AccordionTitle reportName={`${QuoteRequest.requestTypeName.toString()}`} unread={unread} />}
            isOpen={isOpen}
            headerStyle={{
              fontSize: 14,
            }}
            contentStyle={{}}
            toggleOpen={() => setIsOpen(!isOpen)}
          >
            <div className={`${classes.accordion} ${isTablet || isMobile ? classes.isTablet : ""}`}>{Mobilefields}</div>
          </Accordion>
        </div>
      )}

      {modal}
    </>
  );
};

interface AccordionTitleProps {
  reportName: string;
  unread: boolean;
}

function AccordionTitle({ reportName, unread }: AccordionTitleProps) {
  
  return (
    <div className={`${classes.flex_row} ${classes.accordion_title} ${unread ? classes.unread : ""}`}>
      <div className="whitespace-nowrap">
        {reportName}
      </div>
    </div>
  );
}

export default QuoteRequestItem;
