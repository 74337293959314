import { useContext } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useGetAllPlantsConfig } from "../../../../customHooks/PlantHttpServices";
import { Store } from "../../../../Store";
import PlantList from "./PlantList";

function PlantManagementBody() {
	
	const { plants, setPlants } = useContext(Store);

	const { isLoading } = useGetAllPlantsConfig({
		onSuccess: (data) => setPlants?.(data),
	});

	const { plantId } = useParams();

	return (
		<div style={{ flexGrow: 1 }}>{plantId ? <Outlet context={{ plant: plants?.find((p) => p.plantId === parseInt(plantId)) }} /> : <PlantList plants={plants} isLoading={isLoading} />}</div>
	);
}

export default PlantManagementBody;
