import { generatePath, Outlet, useParams } from 'react-router-dom';
import classes from './TechnicalDocumentation.module.css';
import Link from '../../components/Link/Link';
import RelatedLinks from '../../components/RelatedLinks/RelatedLinks';
import Footer from '../../components/Footer/Footer';
import useWindowSize from '../../customHooks/useWindowSize';
import { CrudMethods, MitsubishiPowerReports, OmManuals, TechnicalBulletins } from './documentGroupApiMethods';
import OmManualHeader from './ModuleSpecifics/OmManualHeader';
import PowerReportHeader from './ModuleSpecifics/PowerReportHeader';
import TechBulletinHeader from './ModuleSpecifics/TechBulletinHeader';
import AddEditDocument from './ModuleSpecifics/AddEditDocument';
import { MitsubishiPowerReports as mitReportApi, OmManuals as omManualReportApi, TechnicalBulletins as tbReportApi, DocumentModuleApiMethods } from './documentApiMethods';
import React, { useContext, useEffect, useState } from 'react';
import { DataWithPermissions, DocumentGroup, DocumentGroups, ModulePermissionResponseInterface, Permissions, Report, SiteUnit } from '../../utils/interface';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { Store } from '../../Store';
import { useOnError } from '../../customHooks/useOnError';
import ErrorCard from '../../components/ErrorCard/ErrorCard';
import usePlantSiteDetailsApiCalls from '../../components/PlantSiteDetails/usePlantSiteDetailsApiCalls';
import TechnicalAddDocument from './ModuleSpecifics/TechnicalAddDocument';
import { ModuleIDs } from '../../utils/moduleIDs';
import { useBaseOrgIdHeader } from '../../customHooks/useBaseOrgIdHeader';

const documentModules: DocumentModuleInterface[] = [
    {
        id: ModuleIDs.MitsubishiPowerReports,
        name: 'Mitsubishi Power Reports',
        addEditHeaderName: 'a Mitsubishi Power Report',
        ModuleHeaderContent: PowerReportHeader,
        addDocumentModalBodyContent: AddEditDocument,
        documentGroupApi: MitsubishiPowerReports,
        documentApi: mitReportApi,
        addModalHeaderContent: [],
        relatedData: {
            relatedTitle: "Related Technical Documentation",
            relatedSections: [
                {
                    title: "Technical Documents",
                    description: "Click below for technical documents related to your Plant and Equipment and a link to the Technical Bulletin Web.",
                    buttonLink: "View Technical Documents",
                    route: generatePath('/technical-documentation/:moduleName', { moduleName: 'Technical Bulletins' })
                },
                {
                    title: "O&M Manuals",
                    description: "Click below for Operation and Maintenance Manuals related to your Plant, Unit and Equipment.",
                    buttonLink: "View O&M Manuals",
                    route: generatePath('/technical-documentation/:moduleName', { moduleName: 'O&M Manuals' })
                },
                {
                    title: "Parts Catalog",
                    description: "Click below for Parts Catalogs related to your Plant, Unit and Equipment.",
                    buttonLink: "View Parts Catalog",
                    route: generatePath('/parts&service/:moduleName', { moduleName: 'Parts Catalog' })
                }
            ]
        }
    },
    {
        id: ModuleIDs.TechnicalBulletins,
        name: 'Technical Bulletins',
        addEditHeaderName: 'a Mitsubishi Power Technical Document',
        ModuleHeaderContent: TechBulletinHeader,
        addDocumentModalBodyContent: TechnicalAddDocument,
        documentGroupApi: TechnicalBulletins,
        documentApi: tbReportApi,
        addModalHeaderContent: [],
        relatedData: {
            relatedTitle: "Related Technical Documentation",
            relatedSections: [
                {
                    title: "O&M Manuals",
                    description: "Click below for Operation and Maintenance Manuals related to your Plant, Unit and Equipment.",
                    buttonLink: "View O&M Manuals",
                    route: generatePath('/technical-documentation/:moduleName', { moduleName: 'O&M Manuals' })
                },
                {
                    title: "Mitsubishi Power Reports",
                    description: "Click here for Plant, Unit and Equipment Reports.",
                    buttonLink: "View Mitsubishi Power Reports",
                    route: generatePath('/technical-documentation/:moduleName', { moduleName: 'Mitsubishi Power Reports' })
                },
                {
                    title: "Parts Catalog",
                    description: "Click below for Parts Catalogs related to your Plant, Unit and Equipment.",
                    buttonLink: "View Parts Catalog",
                    route: generatePath('/parts&service/:moduleName', { moduleName: 'Parts Catalog' })
                }
            ]
        }
    },
    {
        id: ModuleIDs.OMManuals,
        name: 'O&M Manuals',
        addEditHeaderName: 'a Mitsubishi Power O&M Manual',
        ModuleHeaderContent: OmManualHeader,
        addDocumentModalBodyContent: AddEditDocument, // this is a placeholder
        documentGroupApi: OmManuals,
        documentApi: omManualReportApi,
        addModalHeaderContent: ['If you are looking to add an O&M manual document that contains multiple chapter content, please name the file accordingly before uploading. Users will be able to see, browse and navigate the embedded table of contents within the document once they’ve opened the file in their PDF file viewer.', 'You may assign each document upload a “Section” tag, which will communicate the content type to customers.'],
        relatedData: {
            relatedTitle: "Related Technical Documentation",
            relatedSections: [
                {
                    title: "Mitsubishi Power Reports",
                    description: "Click here for Plant, Unit and Equipment Reports.",
                    buttonLink: "View Mitsubishi Power Reports",
                    route: generatePath('/technical-documentation/:moduleName', { moduleName: 'Mitsubishi Power Reports' })
                },
                {
                    title: "Technical Documents",
                    description: "Click below for technical documents related to your Plant and Equipment and a link to the Technical Bulletin Web.",
                    buttonLink: "View Technical Documents",
                    route: generatePath('/technical-documentation/:moduleName', { moduleName: 'Technical Bulletins' })
                },
                {
                    title: "Parts Catalog",
                    description: "Click below for Parts Catalogs related to your Plant, Unit and Equipment.",
                    buttonLink: "View Parts Catalog",
                    route: generatePath('/parts&service/:moduleName', { moduleName: 'Parts Catalog' })
                }
            ]
        }
    },

];

export interface AddDocumentRequiredProps {
    onSave: (data: any) => void;
    documentGroups: DocumentGroup[];
    siteUnits: SiteUnit[];
    documentModule?: DocumentModuleInterface;
    isEdit: boolean;
    isLoading?: {
        isAdding?:boolean,
        isEditing?:boolean,
      }
}

export interface DocumentModuleInterface {
    name: string;
    id: number;
    addEditHeaderName: string;
    addModalHeaderContent: string[];
    ModuleHeaderContent: React.FunctionComponent<{groupPermissions: Permissions, reportPermissions: Permissions}>;
    addDocumentModalBodyContent: React.FunctionComponent<AddDocumentRequiredProps>;
    documentGroupApi: CrudMethods<any, any, any>;
    documentApi: DocumentModuleApiMethods<any>;
    relatedData: {
        relatedTitle: string,
        relatedSections:
        {
            title: string,
            description: string,
            buttonLink: string,
            route: string
        }[]

    }
}

export interface TechnicalDocumentationOutletInterface {
    documentModules: DocumentModuleInterface[];
    documentModule?: DocumentModuleInterface;
};

interface TechnicalDocumentationContainerProps { }

function TechnicalDocumentationContainer(props: TechnicalDocumentationContainerProps) {
	const hasBaseOrgForHeader = useBaseOrgIdHeader();

	const { isMobile } = useWindowSize();
    const { moduleName } = useParams();
    const [documentGroups, setDocumentGroups] = useState<DocumentGroup[]>([]);
    const [documentAdded, setDocumentAdded] = useState<Report|undefined>(undefined);
    const { showError } = useContext(Store);
    const [groupPermissions, setGroupPermissions] = useState<Permissions>({
        baseOrg: null,
        canAdd: false,
        canAdmin: false,
        canDelete: false,
        canEdit: false,
        canView: false,
        orgGroup: null,
        role: ''
    });
    const [reportPermissions, setReportPermissions] = useState<Permissions>({
        baseOrg: null,
        canAdd: false,
        canAdmin: false,
        canDelete: false,
        canEdit: false,
        canView: false,
        orgGroup: null,
        role: ''
    });
    const setErrorData = useOnError();
    usePlantSiteDetailsApiCalls();

    const sortByDisplayOrderAsc = ({ displayOrder: a }: DocumentGroup, { displayOrder: b }: DocumentGroup): number => {
        if (a > b) return 1;
        if (b > a) return -1;
        return 0;
    };

    useEffect(() => {
        setDocumentGroups([]);
    }, [moduleName]);

    const documentModule = documentModules.find(module => module.name === moduleName);
    const { refetch, isFetching } = useQuery(`${documentModule?.name}.documentGroups.get`, (documentModule ? documentModule.documentGroupApi.getAll : () => new Promise((resolve) => resolve([]))), {
        onSuccess: (response: DataWithPermissions<DocumentGroup[]>) => {
            const {data, permissions} = response;
			setGroupPermissions(permissions);
            setDocumentGroups(data?.sort(sortByDisplayOrderAsc) || []);
        },
        onError: (error: AxiosError<string>) => {
            setErrorData(error);
        }, 
		enabled: hasBaseOrgForHeader
    });
    const handleRefresh = () => {
        window.location.reload();
    };

    return (
        <div className={`${classes.flex_column} ${classes.documentation_container} ${isMobile ? classes.is_mobile : ''}`}>
            <div className='content'>
                {showError.isError && <ErrorCard ErrorMessage={showError.title} onRefresh={handleRefresh} ErrorType={showError.ErrorType} />}
                {documentModule && (
                    <>
                        <div className='path breadcrumb'>
                            <Link to={"/"} className="link_text">Home</Link> / Technical Documentation /{' '}
                            {documentModule.name}
                        </div>
                        <div className='login-header'>
                            {documentModule.name}
                        </div>
                        <div className={`card ${classes.flex_column} ${classes.documentation_container_outlet}`}>
                            <div className={`${classes.module_container} ${classes.flex_column}`}>
                                <Outlet context={{ documentModules, documentModule, refetch, documentGroups: isFetching ? [] : documentGroups, setDocumentGroups, groupPermissions, reportPermissions, setReportPermissions, documentAdded, setDocumentAdded }} />
                            </div>
                        </div>
                    </>
                )}
                  {documentModule ? <RelatedLinks data={documentModule.relatedData} /> : null}
            </div>          

            <div className='content flex-grow-0 flex-end'>
                <Footer />
            </div>
        </div>
    );
}

export default TechnicalDocumentationContainer;