import { apiMessageAttachment, issueReportApiUrl } from "../../endpoints/endpoints";
import { CPApiResponse, DataWithPermissions, deleteInterface, IssueReport, IssueReportItemsDTO, ModulePermissionResponseInterface, updateInterface } from "../../utils/interface";

import axios, { AxiosResponse } from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import { queryKeys } from "../../react-query/queryKeys";

import { handleModulePermissionResponseMulti } from "../../utils/handleAxiosResponse";
import { ModuleIDs } from "../../utils/moduleIDs";

interface IssueReportCrudMethods<T1, T2, T3> {
  query: (baseOrgId: number) => Promise<T1>;
  delete: (globalId: number) => Promise<T2>;
  update: (reportId: number, status: string) => Promise<T3>;
  getFileBlob: (fileId: number) => Promise<Blob>;
}

export const IssueReportApi: IssueReportCrudMethods<
  DataWithPermissions<IssueReport[]>,
  AxiosResponse<deleteInterface>,
  AxiosResponse<updateInterface>
> = {
  query: async (baseOrgId: number): Promise<DataWithPermissions<IssueReport[]>> => {
    const response: AxiosResponse<CPApiResponse<ModulePermissionResponseInterface<IssueReport[]>[]>> = await axios({
      url: `${issueReportApiUrl}/get-items-list?ModuleId=${ModuleIDs.IssueReports}&BaseOrgId=${baseOrgId}`,
      method: "GET",
    });
    return handleModulePermissionResponseMulti(response);
  },
  delete: async (globalId: number): Promise<AxiosResponse<deleteInterface>> => {
    const response = await axios({
      url: `${issueReportApiUrl}/DeleteByGlobalItemId/${globalId}`,
      method: "DELETE",
    });
    return response.data;
  },
  update: async (reportId: number, status: string): Promise<AxiosResponse<updateInterface>> => {
    const response = await axios({
      url: `${issueReportApiUrl}/${reportId}?status=${status}`,
      method: "PUT",
      // headers: {
      // 	// Accept: "application/json",
      // 	"Content-Type": "application/json",
      // },
      // data: data,
    });
    return response.data;
  },
  getFileBlob: async (id: number): Promise<Blob> => {
    const response = await axios({
      url: `${apiMessageAttachment}/Download/${id}`,
      method: "GET",
      responseType: "blob",
    });
    return response.data;
  },
};

export const useQuery_IssueReportItems = (baseOrgId: number, onSuccess: any, onError: any) => {
  return useQuery([queryKeys.issueReportItems, baseOrgId], () => IssueReportApi.query(baseOrgId), {
    enabled: baseOrgId > 0,
    onSuccess,
    onError,
  });
};

export const useGet_MessageAttachmentFileBlob = (id: number, options: Omit<UseQueryOptions<Blob>, "queryKey" | "queryFn"> = {}) => {
  return useQuery<Blob>([queryKeys.DeleteFile, id], () => IssueReportApi.getFileBlob(id), {
    ...options,
  });
};
