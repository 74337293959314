
import classes from '../TechnicalDocumentation.module.css';
import headerClasses from './PowerReport.module.css';
import { useContext } from 'react';
import { Store } from '../../../Store';
import HeaderButtons from '../HeaderButtons';
import useWindowSize from '../../../customHooks/useWindowSize';
import { Permissions } from '../../../utils/interface';

function PowerReportHeader({ groupPermissions, reportPermissions }: { groupPermissions: Permissions, reportPermissions: Permissions }) {
    const { selectedPlant } = useContext(Store);
    const { isDesktop, isMobile } = useWindowSize();
    return (
        <div className={`${classes.flex_row} ${headerClasses.header} ${isDesktop ? '' : headerClasses.not_desktop} w-[100%]`}>
            <div className={`${headerClasses.header_content} ${classes.flex_column} grow`}>
                <div className={headerClasses.header_title}>
                    {selectedPlant.name} Reports
                </div>
                {!isMobile &&
                    <div className={headerClasses.group_header_text}>
                        Plant, Unit and Equipment Reports.
                    </div>
                }
                {!isDesktop && <HeaderButtons manageButtonClass={headerClasses.manage_button} addButtonClass={headerClasses.add_button} addButtonText='Add Report' groupPermissions={groupPermissions} reportPermissions={reportPermissions} />}
            </div>
            {isDesktop && <HeaderButtons manageButtonClass={headerClasses.manage_button} addButtonClass={headerClasses.add_button} addButtonText='Add Report' groupPermissions={groupPermissions} reportPermissions={reportPermissions} />}
        </div>
    );
}

export default PowerReportHeader;