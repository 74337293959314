import { CPApiResponse, Role, RoleModulePermissions } from "../utils/interface";
import { roleApiUrl, roleModulePermissionApiUrl } from "../endpoints/endpoints";

import axios, { AxiosResponse } from "axios";
import { handleAxiosResponse } from "../utils/handleAxiosResponse";
import { MutationFunction, MutationOptions, useMutation, useQuery, UseQueryOptions } from "react-query";

interface RoleConfigurationCrudMethods<T1, T3> {
	query: (roleId: number) => Promise<T1>;
	update: (roleModulePermissions: RoleModulePermissions) => Promise<T3>;
}

export const RoleConfigurationApi: RoleConfigurationCrudMethods<AxiosResponse<CPApiResponse<RoleModulePermissions>>, AxiosResponse<CPApiResponse<RoleModulePermissions>>> = {
	query: async (roleId: number): Promise<AxiosResponse<CPApiResponse<RoleModulePermissions>>> => {
		return await axios({
			url: `${roleModulePermissionApiUrl}/GetAllByRoleId?RoleId=${roleId}`,
			method: "GET",
		});
	},
	update: async (roleModulePermissions: RoleModulePermissions): Promise<AxiosResponse<CPApiResponse<RoleModulePermissions>>> => {
		return await axios({
			url: `${roleModulePermissionApiUrl}/UpdateAllByRole`,
			method: "PUT",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			data: roleModulePermissions,
		});
	},
};

declare type GetRolesParameters = ['roles'];
const getRoles = async (): Promise<Role[]> => {
    const response: AxiosResponse<CPApiResponse<Role[]>> = await axios(`${roleApiUrl}/List`);
    return handleAxiosResponse(response);
};

export const useGetRoles = (options: Omit<UseQueryOptions<unknown, unknown, Role[], GetRolesParameters>, 'queryKey' | 'queryFn'> = {}) => {
    return useQuery<unknown, unknown, Role[], GetRolesParameters>(['roles'], getRoles, options);
};


declare type CreateRoleParameters = {
    name: string;
    mpInternal: boolean;
    description?: string;
};
const createRole: MutationFunction<Role, CreateRoleParameters> = async (data: CreateRoleParameters): Promise<Role> => {
    const response: AxiosResponse<CPApiResponse<Role>> = await axios(roleApiUrl, {
        method: 'POST',
        data
    });
    return handleAxiosResponse(response);
};
export const useCreateRole = (options: Omit<MutationOptions<Role, unknown, CreateRoleParameters>, 'mutationFn'> = {}) => useMutation(createRole, options);

const updateRole: MutationFunction<Role, Role> = async (data: Role): Promise<Role> => {
    const response: AxiosResponse<CPApiResponse<Role>> = await axios(`${roleApiUrl}/${data.id}`, {
        method: 'PUT',
        data
    });
    return handleAxiosResponse(response);
};
export const useUpdateRole = (options: Omit<MutationOptions<Role, unknown, Role>, 'mutationFn'> = {}) => useMutation(updateRole, options);

const deleteRole: MutationFunction<boolean, number> = async (id: number): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${roleApiUrl}/${id}`, {
        method: 'DELETE'
    });
    return handleAxiosResponse(response);
};
export const useDeleteRole = (options: Omit<MutationOptions<boolean, unknown, number>, 'mutationFn'> = {}) => useMutation(deleteRole, options);

const reorderRoles: MutationFunction<boolean, Array<{id: number, displayOrder: number}>> = async (data: Array<{id: number, displayOrder: number}>): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${roleApiUrl}/ReOrder`, {
        method: 'POST',
        data
    });
    return handleAxiosResponse(response);
};
export const useReorderRoles = (options: Omit<MutationOptions<boolean, unknown, Array<{id: number, displayOrder: number}>>, 'mutationFn'> = {}) => useMutation(reorderRoles, options);