import axios, { AxiosResponse } from "axios";
import { apiMitsubishiPowerReport, omManualApiUrl, technicalBulletinApiUrl, apiPartsCatalog } from "../../endpoints/endpoints";
import { CPApiResponse, PlantPermissionedReports, Report, PartsCatalogInterface, ModulePermissionResponseInterface, DataWithPermissions } from "../../utils/interface";
import { handleAxiosResponse, handleModulePermissionResponseMulti } from "../../utils/handleAxiosResponse";
import { ModuleIDs } from "../../utils/moduleIDs";

// interface starts here //
export interface DocumentModuleApiMethods<T> {
  getAll: (itemTypeId?: number, baseOrgId?: number) => Promise<DataWithPermissions<T[]>>;
  get: (globalItemId?: number) => Promise<T>; // changed from report to T - if issues arise this maybe why //
  bulkDelete: (ids: number[], reason: string) => Promise<boolean>;
  bulkMove: (ids: number[], newItemTypeId: number) => Promise<boolean>;
}
// end interface //

// Mitsubishi Power Reports section starts here //
export const MitsubishiPowerReports: DocumentModuleApiMethods<Report> = {
  getAll: async (itemTypeId?: number, baseOrgId?: number): Promise<DataWithPermissions<Report[]>> => {
    const response: AxiosResponse<CPApiResponse<ModulePermissionResponseInterface<Report[]>[]>> = await axios(
      `${apiMitsubishiPowerReport}/get-items-list?ItemTypeId=${itemTypeId}&ModuleId=${ModuleIDs.MitsubishiPowerReports}&BaseOrgId=${baseOrgId}`
    );
    return handleModulePermissionResponseMulti(response);
  },
  get: async (globalItemIds: number | undefined): Promise<Report> => {
    const response: AxiosResponse<CPApiResponse<Report>> = await axios(`${apiMitsubishiPowerReport}/${globalItemIds}`);
    return handleAxiosResponse(response);
  },
  bulkDelete: async (globalItemIds: number[], deletionReason: string): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${apiMitsubishiPowerReport}/BulkDelete`, {
      method: "DELETE",
      data: {
        globalItemIds,
        deletionReason,
      },
    });
    return handleAxiosResponse(response);
  },
  bulkMove: async (globalItemIds: number[], newItemTypeId: number): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${apiMitsubishiPowerReport}/Move`, {
      method: "POST",
      data: {
        globalItemIds,
        newItemTypeId,
      },
    });
    return handleAxiosResponse(response);
  },
};
// end Mitsubishi Power Reports section //

//OmManuals section starts Here //
export const OmManuals: DocumentModuleApiMethods<Report> = {
  getAll: async (itemTypeId?: number, baseOrgId?: number): Promise<DataWithPermissions<Report[]>> => {
    const response: AxiosResponse<CPApiResponse<ModulePermissionResponseInterface<Report[]>[]>> = await axios(
      `${omManualApiUrl}/get-items-list?ItemTypeId=${itemTypeId}&ModuleId=${ModuleIDs.OMManuals}&BaseOrgId=${baseOrgId}`
    );
    return handleModulePermissionResponseMulti(response);
  },
  get: async (globalItemIds: number | undefined): Promise<Report> => {
    const response: AxiosResponse<CPApiResponse<Report>> = await axios(`${omManualApiUrl}/${globalItemIds}`);
    return handleAxiosResponse(response);
  },
  bulkDelete: async (globalItemIds: number[], deletionReason: string): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${omManualApiUrl}/BulkDelete`, {
      method: "DELETE",
      data: {
        globalItemIds,
        deletionReason,
      },
    });
    return handleAxiosResponse(response);
  },
  bulkMove: async (globalItemIds: number[], newItemTypeId: number): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${omManualApiUrl}/Move`, {
      method: "POST",
      data: {
        globalItemIds,
        newItemTypeId,
      },
    });
    return handleAxiosResponse(response);
  },
};
// end OmManuals section //

// Technical Bulletins section starts here //
export const TechnicalBulletins: DocumentModuleApiMethods<Report> = {
  getAll: async (itemTypeId?: number, baseOrgId?: number): Promise<DataWithPermissions<Report[]>> => {
    const response: AxiosResponse<CPApiResponse<ModulePermissionResponseInterface<Report[]>[]>> = await axios(
      `${technicalBulletinApiUrl}/get-items-list?ItemTypeId=${itemTypeId}&ModuleId=${ModuleIDs.TechnicalBulletins}&BaseOrgId=${baseOrgId}`
    );
    return handleModulePermissionResponseMulti(response);
  },
  get: async (globalItemIds: number | undefined): Promise<Report> => {
    const response: AxiosResponse<CPApiResponse<Report>> = await axios(`${technicalBulletinApiUrl}/${globalItemIds}`);
    return handleAxiosResponse(response);
  },
  bulkDelete: async (globalItemIds: number[], deletionReason: string): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${technicalBulletinApiUrl}/BulkDelete`, {
      method: "DELETE",
      data: {
        globalItemIds,
        deletionReason,
      },
    });
    return handleAxiosResponse(response);
  },
  bulkMove: async (globalItemIds: number[], newItemTypeId: number): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${technicalBulletinApiUrl}/Move`, {
      method: "POST",
      data: {
        globalItemIds,
        newItemTypeId,
      },
    });
    return handleAxiosResponse(response);
  },
};
// end Technical Bulletins section //

// Parts Catalog section starts here //
export const PartsCatalog: DocumentModuleApiMethods<PartsCatalogInterface> = {
  getAll: async (itemTypeId?: number, baseOrgId?: number): Promise<DataWithPermissions<PartsCatalogInterface[]>> => {
    const response: AxiosResponse<CPApiResponse<ModulePermissionResponseInterface<PartsCatalogInterface[]>[]>> = await axios(
      `${apiPartsCatalog}/get-items-list?ItemTypeId=${itemTypeId}&ModuleId=${ModuleIDs.PartsCatalog}&BaseOrgId=${baseOrgId}`
      );
      return handleModulePermissionResponseMulti(response);
    },
  get: async (globalItemIds: number | undefined): Promise<PartsCatalogInterface> => {
    const response: AxiosResponse<CPApiResponse<PartsCatalogInterface>> = await axios(`${apiPartsCatalog}/${globalItemIds}`);
    return handleAxiosResponse(response);
  },
  bulkDelete: async (globalItemIds: number[], deletionReason: string): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${apiPartsCatalog}/BulkDelete`, {
      method: "DELETE",
      data: {
        globalItemIds,
        deletionReason,
      },
    });
    return handleAxiosResponse(response);
  },
  bulkMove: async (globalItemIds: number[], newItemTypeId: number): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${apiPartsCatalog}/Move`, {
      method: "POST",
      data: {
        globalItemIds,
        newItemTypeId,
      },
    });
    return handleAxiosResponse(response);
  },
};
// end Parts Catalog Reports section //