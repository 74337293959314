import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import "./DatePicker.css";
import { DatePickerCalendar } from "./DatePickerCalendar/DatePickerCalendar";
import { DatePickerSelector } from "./DatePickerMonthSelector/DatePickerSelector";

export interface IDatePickerProps {
    // date selected in calendar/input
    selectedDate: Dayjs;
    openPicker: boolean;
    minDate?: Dayjs;
    maxDate?: Dayjs;
    startDate: Dayjs;
    endDate: Dayjs;
    input?: JSX.Element;
    onChange: (newDate: Dayjs) => void;
}

export const CustomSingleDatePicker: React.FC<IDatePickerProps> = ({
  selectedDate,
  input,
  openPicker,
  startDate,
  endDate,
	minDate, 
  maxDate,
  onChange
}) => {
    // date shown in month selector
    const [shownDate, setShownDate] = useState(dayjs(selectedDate).clone());

    // needs to only run against selectedDate
    useEffect(() => {

        if (selectedDate.toString() !== 'Invalid Date' && !dayjs(shownDate).isSame(selectedDate, 'day')) setShownDate(selectedDate)
        else if (selectedDate.toString() === 'Invalid Date') setShownDate(dayjs())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate]);

    return (
        <>
            {input}
            {openPicker && <div className={"DatePicker"}>
              <DatePickerSelector
                shownDate={shownDate}
                setShownDate={setShownDate}
              />
              <DatePickerCalendar
                selectedDate={selectedDate}
                shownDate={shownDate}
                onChange={onChange}
                minDate={minDate}
                maxDate={maxDate}
                startDate={startDate}
                endDate={endDate}
              />
            </div>}
        </>
    );
};
