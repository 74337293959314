import { FunctionComponent, MouseEvent, ReactElement, useCallback, useEffect, useState } from "react";
import usePagination from "../../customHooks/usePagination";
import useWindowSize from "../../customHooks/useWindowSize";
import ImageImports from '../../utils/ImageImports';
import Button from "../Button/Button";
import Pill from "../Pill/Pill";
import { withTooltip } from "../PopoutTooltip/Tooltip";

import { useLocation } from "react-router-dom";
import Dropdown from "../Dropdown/Dropdown";
import SimpleSearch from "../Search/SimpleSearch";
import { AccordionList } from "./AccordionList";
import { Pagination } from "./Pagination";
import { TableList } from "./TableList";

const { magnifyGlassBlack, filter } = ImageImports;
const ButtonWithTooltip = withTooltip(Button);

export interface ItemListColumnDefinition<T> {
    title: string;
    key: keyof T;
    component?: FunctionComponent<{data: T}>;
    className?: string;
}

export interface SortablePropertiesDefinition<T> {
    key: keyof T;
    label: string;
    direction: 'asc' | 'desc';
    default?: boolean;
}
export type SortOptionsDefinition<T> = {
    [K in keyof T]: {
        id: number;
        key: K;
        name: string;
        direction: 'asc' | 'desc';
        default?: boolean;
        manipulateFn?: (data: T[K]) => any;
    }
}[keyof T];

interface Test {
    id: number;
    name: string;
}

const x: SortOptionsDefinition<Test> = {
    direction: 'asc',
    id: 1,
    key: "id",
    name: 'string',
    manipulateFn(data) {
        
    },
};

export interface BulkItemActionDefinition<T> {
    text: string|ReactElement;
    onClick: (event: MouseEvent, data: T[]) => void;
}

export interface ItemActionDefinition<T> {
    text: string|ReactElement;
    onClick: (event: MouseEvent, data: T) => void;
}

export type PropertiesOfType<T, V> = {[K in keyof T]-?: T[K] extends V ? K : never}[keyof T];
export interface ItemListProps<T> {
    columns: ItemListColumnDefinition<T>[];
    data: T[];
    sortValues?: SortOptionsDefinition<T>[] | [];
    bulkActions?: BulkItemActionDefinition<T>[];
    itemActions?: ItemActionDefinition<T>[];
    itemActionText?: FunctionComponent;
    deletedItem?: (((data: T) => boolean) | PropertiesOfType<T, boolean>);
    readItemKey?: PropertiesOfType<T, boolean>;
    accordionTitle?: (data: T) => string;
    filterContent?: ReactElement;
    filterCount?: number;
    filters?: ReactElement;
    searchBoxPlaceholder?: string;
    noResultsText?: string;
    closeFilters?: number;
}

export const ItemList = <T extends {id: number}>({
    data,
    columns,
    sortValues,
    bulkActions,
    itemActions,
    itemActionText,
    deletedItem,
    readItemKey,
    accordionTitle,
    filterContent,
    filterCount,
    filters,
    searchBoxPlaceholder,
    noResultsText,
    closeFilters
}: ItemListProps<T>) => {
    const [filterText, setFilterText] = useState<string>('');
    const { isMobile, isTablet, isDesktop } = useWindowSize();
    const [selectedItems, setSelectedItems] = useState<T[]>([]);
    const [sortOptions, setSortOptions] = useState<SortOptionsDefinition<T>[]>([]);
    const [sortSelectedOption, setSortSelectedOption] = useState<string | null>(null);
    const location = useLocation();

    const {
        pagedData,
        setData,
        totalRecords,
        setFilter: setPaginationFilter,
        pageButtonNumbers,
        hasPreviousPage,
        previousPage,
        hasNextPage,
        nextPage,
        setCurrentPageNumber,
        currentPageNumber,
        resultsPerPage,
        setResultsPerPage,
        setSortKey,
        setSortDirection,
        setSortManipulateFn
    } = usePagination<T>();

    useEffect(() => {
        setData(data);
        setSelectedItems([]);
        setCurrentPageNumber(1);
    }, [data]);

    useEffect(() => {
        if (sortValues) {   
            const tmpOptions: typeof sortOptions & SortablePropertiesDefinition<T>[] = [];
            tmpOptions.push(...sortValues.map((d, i) => ({...d, id: i})));
            setSortOptions(tmpOptions);
            handleSort(tmpOptions.find(o => o?.default === true) || tmpOptions[0]);
        }
    }, [sortValues]);

    useEffect(() => {
        setFilterText('');
      }, [location]);

    useEffect(() => {
        setPaginationFilter(filterText.trim());
    }, [filterText]);

    const handleSort = useCallback((item: SortOptionsDefinition<T> & {id: number|false}) => {
        setSortManipulateFn(() => item.manipulateFn);
        setSortKey(item.key);
        setSortDirection(item.direction);
    }, []);

    const toggleSelectedItem = useCallback((selectedItem: T) => {
        const items = [...selectedItems];
        const i = items.findIndex(d => d.id === selectedItem.id);
        if (i >= 0) {
            items.splice(i, 1);
        } else {
            items.push(selectedItem);
        }
        setSelectedItems(items);
    }, [selectedItems]);

    const toggleAllItems = () => {
        if (selectedItems.length === pagedData.length) {
            setSelectedItems([]);
        } else {
            setSelectedItems(pagedData);
        }
    };

    return (
        <div className="flex flex-col gap-4 grow">
            <div className="flex justify-between">
                <div className="flex flex-col md:flex-row items-center gap-4 wrap">
                    {(isDesktop || !filterContent) && sortOptions && (
                        <div className="nameplate-dropdown ">
                            <Dropdown
                                className="text-black"
							                  dropdowntype={"Sort by:"}
                                selectValue={sortOptions[0]?.name}
                                handleDropDownSelect={(e, option) => handleSort(option)}
                                selectedOption={sortSelectedOption}
							                  setSelectedOption={(sortOptionName) => setSortSelectedOption(sortOptionName)}
                                options={sortOptions}
                            />
                        </div>
                    )}

                    <div className="flex flex-col lg:flex-row">
                      <div className="grow">
                        <SimpleSearch searchText={filterText} setSearchText={setFilterText} placeholder={searchBoxPlaceholder} />
                      </div>
                    </div>
                    {!isMobile && (
                        <div className="flex flex-col lg:flex-row text-sm">
                            {totalRecords} {totalRecords === 1 ? "Result" : "Results"}
                        </div>
                    )}
                </div>
                {filterContent && sortOptions &&
                    <div className="flex flex-col md:flex-row items-center gap-4 mt-4 md:mt-0">
                        <div className="flex">
                            <div className="grow">
                                <ButtonWithTooltip
                                    className="whiteBtn w-full"
                                    text={
                                        <>
                                            {filterCount && filterCount > 0 ? <Pill type="primary">{filterCount}</Pill> : ""} {isDesktop ? "Filter" : "Sort/Filter"}
                                        </>
                                    }
                                    img={<img src={filter} alt="filter" className="bottom pl-2" />}
                                    textClassName="filter-text"
                                    forceClose={closeFilters || 0}
                                    toggleClickWatcher={true}
                                    tooltipContent={isDesktop && filterContent? filterContent : isDesktop? <></> : (
                                        <div className="flex flex-col">
                                            <Dropdown
                                                handleDropDownSelect={(e, option) => handleSort(option)}
                                                selectValue={sortOptions[0]?.name}
                                                selectedOption={sortSelectedOption}
							                                  setSelectedOption={(sortOptionName) => setSortSelectedOption(sortOptionName)}
                                                options={sortOptions}
                                                overrideInputMargin={true}
                                            />
                                            {filterContent}
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    
                    </div>
                }
            </div>
            
            {filters}
            <div className="flex flex-col gap-2 lg:gap-6 self-stretch items-stretch">
                {isDesktop? 
                    <TableList data={pagedData}
                            columns={columns}
                            bulkActions={bulkActions}
                            itemActions={itemActions}
                            itemActionText={itemActionText}
                            readItemKey={readItemKey}
                            selectedItems={selectedItems}
                            noResultsText={noResultsText}
                            onItemSelectionChange={toggleSelectedItem}
                            deletedItem={deletedItem}
                            onSelectAllItems={toggleAllItems} />
                    : <AccordionList data={pagedData}
                                    accordionTitle={accordionTitle}
                                    columns={columns}
                                    bulkActions={bulkActions}
                                    itemActions={itemActions}
                                    selectedItems={selectedItems}
                                    onItemSelectionChange={toggleSelectedItem}
                                    onSelectAllItems={toggleAllItems} />
                }
            </div>
            {data.length > 0 ? (
                <Pagination
                    currentPage={currentPageNumber}
                    hasNextPage={hasNextPage}
                    hasPreviousPage={hasPreviousPage}
                    nextPage={nextPage}
                    pageNumbers={pageButtonNumbers}
                    previousPage={previousPage}
                    resultCount={pagedData.length}
                    resultsPerPage={resultsPerPage}
                    setCurrentPage={setCurrentPageNumber}
                    setResultsPerPage={setResultsPerPage}
                    totalRecords={totalRecords} />
            ) : ''}
        </div>
    );
};

