import axios, { AxiosResponse } from "axios";
import { userApiUrl } from "../../endpoints/endpoints";
import { CPApiResponse, User } from "../../utils/interface";
import { useQuery, UseQueryOptions } from "react-query";
import { queryKeys } from "../../react-query/queryKeys";

// interface starts here //
export interface ListModuleApiMethod<T> {
  getAll: () => Promise<T>;
  getAllByBaseOrg: (baseOrgId: number) => Promise<T>;
}
// end interface //z

// User Management section starts here //
export const UserManagementCRUD: ListModuleApiMethod<CPApiResponse<User[]>> = {
  getAll: async (): Promise<CPApiResponse<User[]>> => {
    const response = await axios(`${userApiUrl}/GetList`);
    return response.data;
  },

  getAllByBaseOrg: async (baseOrgId: number): Promise<CPApiResponse<User[]>> => {
    const response = await axios({
      url: `${userApiUrl}/GetListByBaseOrg`,
      method: "GET",
      params: {
        BaseOrgId: baseOrgId,
      },
    });
    return response.data;
  },
};
// end User Management section //


export const useGetAll_Users = (options: Omit<UseQueryOptions<CPApiResponse<User[]>>, "queryKey" | "queryFn"> = {}) => {
  return useQuery<CPApiResponse<User[]>>(queryKeys.user, UserManagementCRUD.getAll, {
    ...options,
  });
};

export const useGetAllByBaseOrg_Users = (baseOrgId: number, onSuccess: any, onError: any) => {
  return useQuery([queryKeys.user, baseOrgId], () => UserManagementCRUD.getAllByBaseOrg(baseOrgId), {
    onSuccess,
    onError,
  });
};