import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Link, Outlet, useNavigate, useParams, useSearchParams } from "react-router-dom";
import ErrorCard from "../../components/ErrorCard/ErrorCard";
import Footer from "../../components/Footer/Footer";
import PaginationBasic from "../../components/UI/Pagination/PaginationBasic/PaginationBasic";
import { getSearchResults } from "../../endpoints/endpoints";
import classes from "./SearchContainer.module.css";

import axios, { AxiosResponse } from "axios";
import { QueryFunctionContext, useQuery } from "react-query";
import { Store } from "../../Store";
import { GlobalSearch, GlobalSearchDTO } from "../../utils/interface";
import Tooltip, { withTooltip } from "../../components/PopoutTooltip/Tooltip";
import Button from "../../components/Button/Button";
import useWindowSize from "../../customHooks/useWindowSize";
import Pill from "../../components/Pill/Pill";
import ImageImports from "../../utils/ImageImports";
import Loader from "../../components/Loader/Loader";
import { Checkbox } from "../../components/UI/Form/Checkbox/Checkbox";
import { useFetchSearchText } from "../../customHooks/SearchHttpServices";
import usePagination from "../../customHooks/usePagination";

const SearchContainer = () => {
  const navigate = useNavigate();
  const { selectedPlant, plantConfig } = useContext(Store);
  const { searchText } = useParams();
  const [isFetching, setIsFetching] = useState<boolean>(true);
  // -------------------filters-----------------------//
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<number[]>([]);

  // -----------------filters-------------------------//

  // -----------------start pagination-------------------------//
  const [currentPage, setCurrentPage] = useState(1);
  const [totalpost, setTotalpost] = useState<number>(0);
  const [perPagePost, setPerPagePost] = useState<number>(0);
  const [inputValue, setInputValue] = useState<string>("");
  const [reportTypes, setReportTypes] = useState<{ id: number; name: string }[]>([]);

  const [currentPosts, setCurrentPosts] = useState<GlobalSearch[] | []>([]);
  const [postsPerPage] = useState(16);
  // -----------------end pagination-------------------------//

  const { isDesktop, isMobile, isTablet, isTabletLandscape } = useWindowSize();

  const [searchData, setSearchData] = useState<any>([]);
  const [searchItems, setSearchItems] = useState<any>([]);

  // -----------------start Image import hook-------------------------//
  const { filter } = ImageImports;
  const selectButtonRef = useRef<HTMLDivElement>(null);
  // -----------------end Image import hook-------------------------//

  const debounceFunc = useCallback(() => {
    setIsFetching(true);
    let timeOut = setTimeout(() => {
      searchText && setInputValue(searchText);
    }, 700);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchText]);

  useEffect(debounceFunc, [searchText, debounceFunc]);

  const {
    pagedData,
    setData,
    data,
    setFilter,
    pageButtonNumbers,
    hasPreviousPage,
    previousPage,
    hasNextPage,
    nextPage,
    setCurrentPageNumber,
    currentPageNumber,
    resultsPerPage,
    setResultsPerPage,
    setSortKey,
    setSortDirection
  } = usePagination<any>();

  const {isLoading} = useFetchSearchText(inputValue, {
    enabled: selectedPlant.plantId > 0 && !!inputValue,
    onSuccess: (SearchData: GlobalSearch[]) => {
      setSearchData(SearchData);
      setSearchItems(SearchData);
      // setCurrentPage(1);
      setCurrentPageNumber(1);
      setFilters([]);
      var reportTypes = SearchData.map((r) => {
        return { id: r.itemTypeId, name: r.type };
      });

      if (!reportTypes) return;
      reportTypes = reportTypes.filter((v, i, a) => a.findIndex((d) => d.name === v.name) === i);
      setReportTypes(reportTypes);
      setIsFetching(false);
    },
    onError: (err) => {
      setCurrentPosts([]);
      setIsFetching(false);
    }
  });


  useEffect(() => {
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    let currentPostsList;

    currentPostsList = searchItems.slice(indexOfFirstPost, indexOfLastPost);
    let Totalpost = searchItems?.length;
    setTotalpost(Totalpost);
    setData(searchItems);
    let perPagePost = currentPostsList && indexOfLastPost + currentPostsList.length - postsPerPage;

    setPerPagePost(perPagePost);

    if (searchText) {
      setCurrentPosts(currentPostsList);
    } else {
      setCurrentPosts([]);
      setPerPagePost(0);
      setTotalpost(0);
      navigate("/");
    }
  }, [currentPage, searchItems, searchText, postsPerPage]);

  const handleFilter = () => {
    const activeServiceList = searchData.filter((item: { itemTypeId: number }) => {
      return filters.includes(item.itemTypeId);
    });

    if (activeServiceList.length > 0) {
      setSearchItems(activeServiceList);
      setCurrentPageNumber(1);
      setIsOpen(false);
    } else {
      setSearchItems(searchData);
      setCurrentPageNumber(1);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const handleCloseDropDown = (event: any) => {
      var path = event.path || (event.composedPath && event.composedPath());
      if (path[0] !== selectButtonRef.current) {
        if (path[0].id !== "filter") {
          setIsOpen(false);
        }
      }
    };
    
    document.body.addEventListener("click", handleCloseDropDown);

    //Cleanup
    return () => document.body.removeEventListener("click", handleCloseDropDown);
  }, []);

  const handlePaginate = (pagenumber: number) => {
    setCurrentPage(pagenumber);
  };

  const handleNextPaginate = () => {
    setCurrentPage((prev) => prev + 1);
  };
  const handlePrevPaginate = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const paginationData = {
    totalPosts: totalpost,
    perPagePost: perPagePost,
    postsPerPage: postsPerPage,
    currentPage: currentPage,
    paginate: handlePaginate,
    next_paginate: handleNextPaginate,
    prev_paginate: handlePrevPaginate,
  };
  
  useEffect(() => {
    handlePaginate(currentPageNumber)
  }, [currentPageNumber])

  const selectOption = (selectOption: number) => {
    const index = filters.indexOf(selectOption);
    const selectedOptions: number[] = [...filters];
    if (index < 0) {
      selectedOptions.push(selectOption);
    } else {
      selectedOptions.splice(index, 1);
    }

    setFilters(selectedOptions);
  };
  
  return (
    <div className={`column flex-grow-1 `}>
      <div className={`content ${classes.GolbalSContent}`}>
        <div className={classes.filterRow}>
          <div style={{ marginBottom: "0" }} className={`login-header ${classes.loginHeader}`}>
            {!isLoading ? <>Search results for '{searchText}' </> : <>Search results for {" ..."}</>}
          </div>
          {!isLoading && (
            <div style={{ position: "relative" }} className={`${classes.filterWrapper}`}>
              <button className="primaryBtn whiteBtn" id="filter" onClick={() => setIsOpen(!isOpen)}>
                 Filter{""}<img id="filter" src={filter} alt="filter" className="bottom pl-2" />
              </button>
              <Tooltip shown={isOpen} style={{padding: "0", marginTop: "10px", right: "0", borderRadius: "8px"}}>
                <div ref={selectButtonRef} className={`${classes.filter_tooltip_content}`}>
                  <form className={`${classes.filterForm}`}
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div>
                      <h4 className={classes.checkboxRow}>Search Result Type:</h4>
                      <div className={classes.checkboxWrp}>
                        {reportTypes.length > 0 &&
                          reportTypes.map((opt, i) => {
                            return (
                              <div key={i} className={classes.checkboxRow}>
                                <div key={`${opt.id}`} onClick={() => selectOption(opt.id)}>
                                  <Checkbox isChecked={filters.includes(opt.id)} onChange={() => {}}  label={opt.name} />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div>
                      <button type="button" disabled={(reportTypes.length >= 1 && filters.length >= 1) ||
                      (searchData.length !== searchItems.length) ? false : true} className="primaryBtn darkBlue" onClick={handleFilter}
                      style={isTablet ? {width: "-webkit-fill-available"} : {}}>
                        Apply
                      </button>
                    </div>
                  </form>
                </div>
              </Tooltip>
            </div>
          )}
        </div>

        {(isFetching || isLoading) ? (
          <div className="card" style={{ height: "40vh" }}>
            <Loader containerStyle={{marginTop: "50px"}} />{" "}
          </div>
        ) : (
          <>
            {!searchText || currentPosts.length === 0 ? (
              <div className="card" style={{ padding: "24px", fontSize: "14px" }}>
                Apologies, we couldn’t find any matches for that keyword. Please check your spelling or try a new search.
              </div>
            ) : (
              <>
                <Outlet context={{ currentPosts, paginationData, pageButtonNumbers, currentPageNumber,
                  hasPreviousPage,
                  previousPage,
                  hasNextPage,
                  nextPage,
                  setCurrentPageNumber }} />
              </>
            )}
          </>
        )}
      </div>
      <div className="content flex-grow-0 flex-end">
        <Footer />
      </div>
    </div>
  );
};

export default SearchContainer;
