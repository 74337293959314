import { useEffect, useMemo, useRef, useState } from "react";
import { useStore } from "../../useStore";
import { UnitDATA } from "../../utils/interface";
import Button from "../Button/Button";
import EmptyModal from "../Modals/EmptyModal";
import { PlainDropDown } from "../UI/Form/DropDownSelect/PlainDropDown";

export const AddEditUnit = ({unit, onSave, onDelete, onClose}: {unit: UnitDATA, onSave: (unit: UnitDATA) => void, onDelete: (unit: UnitDATA) => void, onClose: () => void}) => {
	const [unitName, setUnitName] = useState<string>('');
	const [unitConfiguration, setUnitConfiguration] = useState<number|string>('');
	const [outputPotential, setOutputPotential] = useState<number>(0);
	const [coDate, setCoDate] = useState<string>('');
	const [fuelType, setFuelType] = useState<number|string>('');
	const [directOwnership, setDirectOwnership] = useState<string>('');
	const [parentOwner, setParentOwner] = useState<string>('');
	const [notes, setNotes] = useState<string>('');
	const formRef = useRef<HTMLFormElement>(null);

	const {unitConfigurationTypes, fuelType: fTypes} = useStore();
	const configTypes = useMemo(() => {
		return unitConfigurationTypes.data;
	}, [unitConfigurationTypes]);
	const fuelTypes = useMemo(() => {
		return fTypes.data;
	}, [fTypes]);

	useEffect(() => {
		setUnitName(unit.name);
		setUnitConfiguration(unit.unitConfigurationId);
		setOutputPotential(unit.totalMW);
		setCoDate(unit.coDate);
		setFuelType(unit.fuelTypeId);
		setDirectOwnership(unit.directOwnership);
		setParentOwner(unit.parentGroupOwnership);
		setNotes(unit.notes || '');
	}, [unit]);

	const canSave = useMemo(() => {
		return formRef.current && formRef.current.checkValidity() && Object.entries({
			unitName,
			unitConfiguration,
			outputPotential,
			coDate,
			fuelType,
			directOwnership,
			parentOwner,
			notes
		}).toString() !== Object.entries({
			unitName: unit.name,
			unitConfiguration: unit.unitConfigurationId,
			outputPotential: unit.totalMW,
			coDate: unit.coDate,
			fuelType: unit.fuelTypeId,
			directOwnership: unit.directOwnership,
			parentOwner: unit.parentGroupOwnership,
			notes: unit.notes,
		}).toString();
	}, [unitName, unitConfiguration, outputPotential, coDate, fuelType, directOwnership, parentOwner, notes, unit, formRef]);

	const isEditing = useMemo(() => {
		return unit.unitId > 0;
	}, [unit]);

	const submitUnit =() => {
		onSave({
			...unit,
			name: unitName,
			unitConfigurationId: Number(unitConfiguration),
			totalMW: Number(outputPotential),
			coDate,
			fuelTypeId: Number(fuelType),
			directOwnership,
			parentGroupOwnership: parentOwner,
			notes
		});
	};

	const footer = (
		<div className="flex flex-row justify-between w-full items-center">
			<div className="flex flex-row gap-4">
				{isEditing? (
					<>
						<Button className="darkBlue" disabled={!canSave} text="Save Changes" onClick={submitUnit} />
						<Button className="redDelete" text="Delete" onClick={() => onDelete(unit)} />
					</>
				) : (
					<Button className="darkBlue" disabled={!canSave} text="Add Unit" onClick={submitUnit} />
				)}
			</div>
			<span className="text-sm font-light">*Required fields</span>
		</div>
	);

	return <EmptyModal shown={true} onClose={onClose} body={(
		<div className="flex flex-col items-start gap-6">
		<span>
			A unit is a dispatchable power block, and in the case of combined cycle, units would contain more than one turbine, called equipment. For example, 2 on 1 GTCC would contain two gas turbines and one steam turbine.
		</span>
		<form ref={formRef} className="w-full">
			<div className="flex flex-col items-start gap-6 self-stretch">
				<div className="flex flex-row gap-6 self-stretch">
					<span className="basis-1/3">Unit Name*</span>
					<input type="text"
							className={`pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base shadow-[0_1px_0_#B3B3B3]`}
							value={unitName}
							placeholder="Unit name"
							required
							onChange={e => setUnitName(e.target.value)} />
				</div>
				<div className="flex flex-row gap-6 self-stretch">
					<span className="basis-1/3">Unit Configuration*</span>
					<PlainDropDown onSelection={item => {setUnitConfiguration(item.id || 0)}}
						className="grow hover:cursor-pointer"
						options={configTypes}
						value={Number(unitConfiguration)}
						defaultText="Select unit configuration"
						selectClass="flex flex-row pt-1 pr-2 !pb-1 pl-1 border-0 lg:min-w-[240px] justify-between shadow-[0_1px_0_#B3B3B3] w-full"
						optionsClass="p-4 bg-white rounded max-h-80 !overflow-y-auto" itemClass="py-[14px] px-6 cursor-pointer hover:bg-primary-20" />
				</div>
				<div className="flex flex-row gap-6 self-stretch">
					<span className="basis-1/3">Output Potential*</span>
          <input type="number"
            className={`pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base shadow-[0_1px_0_#B3B3B3] `}
            value={outputPotential || ''}
            min="1"
            placeholder="1200"
            required
            onChange={e => setOutputPotential(parseInt(e.target.value) || 0)}
          />
				</div>
				<div className="flex flex-row gap-6 self-stretch">
					<span className="basis-1/3">CO Date*</span>
					<input type="text"
							className={`pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base shadow-[0_1px_0_#B3B3B3] ${coDate? '' : 'text-[#999]'}`}
							value={coDate}
							placeholder="CO date"
							required
							onChange={e => setCoDate(e.target.value)} />
				</div>
				<div className="flex flex-row gap-6 self-stretch">
					<span className="basis-1/3">Fuel Type*</span>
					<PlainDropDown onSelection={item => {setFuelType(item.id || 0)}}
						className="grow hover:cursor-pointer"
						options={fuelTypes}
						value={Number(fuelType)}
						defaultText="Select fuel type"
						selectClass="flex flex-row pt-1 pr-2 !pb-1 pl-1 border-0 lg:min-w-[240px] justify-between shadow-[0_1px_0_#B3B3B3] w-full"
						optionsClass="p-4 bg-white rounded max-h-96 !overflow-y-auto" itemClass="py-[14px] px-6 cursor-pointer hover:bg-primary-20" />
				</div>
				<div className="flex flex-row gap-6 self-stretch">
					<span className="basis-1/3">Direct Ownership*</span>
					<input type="text"
							className={`pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base shadow-[0_1px_0_#B3B3B3]`}
							value={directOwnership}
							placeholder="Ownership"
							required
							onChange={e => setDirectOwnership(e.target.value)} />
				</div>
				<div className="flex flex-row gap-6 self-stretch">
					<span className="basis-1/3">Parent Owner*</span>
					<input type="text"
							className={`pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base shadow-[0_1px_0_#B3B3B3]`}
							value={parentOwner}
							placeholder="Parent Owner"
							required
							onChange={e => setParentOwner(e.target.value)} />
				</div>
				<div className="flex flex-row gap-6 self-stretch">
					<span className="basis-1/3">Notes</span>
					<input type="text"
							className={`pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base shadow-[0_1px_0_#B3B3B3]`}
							value={notes}
							placeholder="Notes"
							onChange={e => setNotes(e.target.value)} />
				</div>
			</div>
		</form>
		</div>)
		} footer={footer} heading={isEditing? `Edit Unit Details for ${unit.name}` : 'Add Unit'}  />
};