import classes from "../TechnicalDocumentation.module.css";
import headerClasses from "./TechBulletinHeader.module.css";
import { useContext } from "react";
import { Store } from "../../../Store";
import HeaderButtons from "../HeaderButtons";
import useWindowSize from "../../../customHooks/useWindowSize";
import ImageImports from "../../../utils/ImageImports";
import { TakasagoTechBulletinWebsiteURL, tbwApplicationFileLink, tbwApplicationItemId } from "../../../endpoints/endpoints";
import { Link } from "react-router-dom";
import { downloadStaticFile } from "../../../customHooks/FileHttpServices";
import { Permissions } from "../../../utils/interface";


function PartsHeader({ groupPermissions, reportPermissions }: { groupPermissions: Permissions, reportPermissions: Permissions }) {
  const { isDesktop, isMobile } = useWindowSize();
  const { linkout } = ImageImports;
  
  return (
    <div className={`${classes.flex_row} ${headerClasses.header} w-[100%]`}>
      <div className={`${headerClasses.header_content} ${classes.flex_column} grow`}>
        <div className={headerClasses.header_title}>
          Parts Catalog
        </div>
        {
          !isMobile && <>
            <div className={headerClasses.group_header_text}>
              Please contact your Mitsubishi Power representative if you have questions about this catalog.
            </div>
            <div className={`${headerClasses.group_header_text} ${classes.flex_column}`}>
              <div className="inline-flex">
                <a href={TakasagoTechBulletinWebsiteURL} className={`${headerClasses.external_link} ${classes.flex_row}`} target="_blank" rel="noreferrer">
                  <div className={`${headerClasses.image_wrapper} ${classes.flex_row}`}>
                    <img src={linkout} alt="Go to Takasago Technical Bulletin Website (TBW)" />
                  </div>
                  Go to Takasago Technical Bulletin Website (TBW)
                </a>
              </div>
              <p>
                The Gas Turbine and Gas Turbine Generator Technical Bulletins are on the Technical Bulletin Website administered by Gas Turbine Service Engineering in Takasago, and requires a separate username and password.
                If you do not have those separate log-on credentials, you can apply for them by completing the form linked <span className="no-underline text-ocean cursor-pointer" onClick={() => { downloadStaticFile(tbwApplicationItemId) }} >here</span> and sending it to your LTSA Program Manager or Sales Manager.
              </p>
            </div>
          </>
        }
        {!isDesktop && (
          <HeaderButtons manageButtonClass={headerClasses.manage_button} addButtonClass={headerClasses.add_button} addButtonText="Add Catalog" groupPermissions={groupPermissions} reportPermissions={reportPermissions} />
        )}
      </div>
      {isDesktop && (
        <HeaderButtons manageButtonClass={headerClasses.manage_button} addButtonClass={headerClasses.add_button} addButtonText="Add Catalog" groupPermissions={groupPermissions} reportPermissions={reportPermissions} />
      )}
    </div>
  );
}

export default PartsHeader;