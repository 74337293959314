import {
  FunctionComponent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import Alert from "../../components/Alert/Alert";
import Button from "../../components/Button/Button";
import {
  ItemListColumnDefinition,
  SortOptionsDefinition,
} from "../../components/List/ItemList";
import { TabbedSections } from "../../components/List/TabbedSections";
import Loader from "../../components/Loader/Loader";
import EmptyModal from "../../components/Modals/EmptyModal";
import Pill from "../../components/Pill/Pill";
import { withTooltip } from "../../components/PopoutTooltip/Tooltip";
import { Checkbox } from "../../components/UI/Form/Checkbox/Checkbox";
import { downloadFileByUrl } from "../../customHooks/FileHttpServices";
import {
  createUser,
  useGetAllUsers,
  useSearchUserByEmail,
} from "../../customHooks/UserHttpServices";
import { usePageRequiresAdmin } from "../../customHooks/usePageRequiresAdmin";
import useWindowSize from "../../customHooks/useWindowSize";
import {
  apiGenerateAccessRequestReportUrl,
  apiGenerateUserReportUrl,
} from "../../endpoints/endpoints";
import ImageImports from "../../utils/ImageImports";
import { ShowErrorInterface, User } from "../../utils/interface";
import { MainContainer } from "../MainContainer";
import classes from "./UserManagementContainer.module.css";

const { magnifyGlassBlack, spinner } = ImageImports;
const ButtonWithTooltip = withTooltip(Button);

type Section = "internal" | "external" | "default";
interface UserManagementProps {
  section: Section;
}
type SectionFilters = {
  [key in Section]: (u: User) => boolean;
};

type DownloadReportType = "accessReport" | "userReport";

export const UserManagementContainer = ({ section }: UserManagementProps) => {
  usePageRequiresAdmin();
  const [searchUsers, setSearchUsers] = useState<boolean>(false);
  const [showReportMenu, setShowReportMenu] = useState<boolean>(false);
  const [forceClose, setForceClose] = useState<number>(0);
  const [downloadReport, setDownloadReport] = useState<DownloadReportType | null>(null);
  const [userCreated, setUserCreated] = useState<User>();
  const [filters, setFilters] = useState<Filters>({});
  const [closeFilters, setCloseFilters] = useState<number>(0);
  const [tableData, setTableData] = useState<User[]>([]);
  const { refetch: refetchUsers, data: users } = useGetAllUsers({
    initialData: [],
  });

  const [addUserError, setAddUserError] = useState<ShowErrorInterface>({
    title: "",
    isError: false,
    ErrorType: null,
  });

  //User record could not be created: User already exists

  const navigate = useNavigate();
  const { mutate: createNewUser } = useMutation(createUser, {
    onSuccess: (u) => {
      // console.log('user added', u);
      setSearchUsers(false);
      setUserCreated(u);
      refetchUsers();
    },
    onError: (error: any) => {
      setAddUserError({
        ErrorType: "danger",
        isError: true,
        title:
          error.response?.data ===
          "User record could not be created: User already exists"
            ? "This user has already been added to Customer Portal."
            : error.response?.data || "User record could not be created",
      });
    },
  });

  const sections = [
    {
      path: "/administration/user-management/external",
      text: "External Users",
    },
    {
      path: "/administration/user-management/internal",
      text: "Internal Users",
    },
  ];

  const [generatingReport, setGeneratingReport] = useState(false);

  const handleDownloadReport = async (url: string) => {
    setGeneratingReport(true);
    try {
      await downloadFileByUrl(url);
    } finally {
      setGeneratingReport(false);
    }
  };

  const handleGenerateReport = async () => {
    switch (downloadReport) {
      case "accessReport":
        await handleDownloadReport(apiGenerateAccessRequestReportUrl);

        break;
      case "userReport":
        await handleDownloadReport(apiGenerateUserReportUrl);

        break;
    }
  };

  useEffect(() => {
    if (section === "default") {
      navigate(sections[0].path);
    }
  }, [section]);

  const sectionFilters: SectionFilters = useMemo(() => {
    return {
      external: (u) => !u.mpInternal,
      internal: (u) => u.mpInternal,
      default: () => true,
    };
  }, []);

  const sectionFilter = useCallback(
    (user: User): boolean => {
      return sectionFilters[section](user);
    },
    [section]
  );

  const onConfigure = useCallback((u: User) => {
    // console.log('Created User', u);
    navigate(`/administration/user-management/${u.id}`);
  }, []);

  const columns: ItemListColumnDefinition<User>[] = [
    {
      title: "User ID",
      key: "id",
    },
    {
      title: "First Name",
      key: "fName",
    },
    {
      title: "Last Name",
      key: "lName",
    },
    {
      title: "Email Address",
      key: "email",
    },
    {
      title: "Account Status",
      key: "accountDisabled",
      component: ({ data }) => (
        <>{data.accountDisabled ? "Deactivated" : "Activated"}</>
      ),
    },
    {
      title: "",
      key: "aDuid",
      component: ({ data }) => (
        <Link
          className="text-ocean font-bold no-underline"
          to={`/administration/user-management/${data.id}`}
        >
          Manage
        </Link>
      ),
    },
  ];
  const sortableProperties: SortOptionsDefinition<User>[] = [
    {
      id: 1,
      name: "First Name: A to Z",
      key: "fName",
      direction: "asc",
    },
    {
      id: 2,
      name: "First Name: Z to A",
      key: "fName",
      direction: "desc",
    },
    {
      id: 3,
      name: "Last Name: A to Z",
      key: "lName",
      direction: "asc",
      default: true,
    },
    {
      id: 4,
      name: "Last Name: Z to A",
      key: "lName",
      direction: "desc",
    },
    {
      id: 5,
      name: "Email: A to Z",
      key: "email",
      direction: "asc",
    },
    {
      id: 6,
      name: "Email: Z to A",
      key: "email",
      direction: "desc",
    },
  ];

  const handleClearErrors = () => {
    setAddUserError({
      title: "",
      isError: false,
      ErrorType: null,
    });
  };
  const handleCloseSearchUserModal = () => {
    setSearchUsers(false);
    handleClearErrors();
  };

  let filterCount = 0;
  if (filters.isStatus?.length) {
    filterCount += filters.isStatus?.length;
  }

  const handleCloseFilters = (applyCloseFilter: boolean) => {
    if (applyCloseFilter) {
      setCloseFilters(closeFilters + 1);
    }
  };

  const filterData = useCallback((user: User): boolean => {
    let matches = true;

    if (filters.isStatus?.length) {
      if (
        matches &&
        filters.isStatus.includes("Active") &&
        filters.isStatus.includes("Deactivated")
      ) {
        matches = !user.accountDisabled || user.accountDisabled;
      } else {
        if (
          matches &&
          filters.isStatus?.length &&
          filters.isStatus.includes("Active")
        ) {
          matches = !user.accountDisabled;
        }
        if (
          matches &&
          filters.isStatus?.length &&
          filters.isStatus.includes("Deactivated")
        ) {
          matches = user.accountDisabled;
        }
      }
    }

    return matches;
  }, [filters]);

  useEffect(() => {
    setTableData((users ?? []).filter(sectionFilter).filter(filterData));
  }, [users,  sectionFilter, filterData]);

  return (
    <MainContainer
      breadCrumbs={["Administration", "User Management"]}
      title="User Management"
    >
      {searchUsers && (
        <SearchUsersModal
          onClose={handleCloseSearchUserModal}
          onCreateUser={createNewUser}
          addUserError={addUserError}
          setAddUserError={setAddUserError}
          clearErrors={handleClearErrors}
        />
      )}
      {userCreated && (
        <UserCreatedModal
          user={userCreated}
          onClose={() => setUserCreated(undefined)}
          onConfigure={() => onConfigure(userCreated)}
        />
      )}

      <div className={`flex flex-col md:flex-row gap-6 justify-between`}>
        <div className="flex flex-col md:flex-row items-center gap-2 wrap">
          <div className="flex flex-col gap-2 grow">
            <span className="font-bold text-2xl self-stretch">
              Customer Portal Users
            </span>
            <span className="text-base">
              See below for a list of all users of the Customer Portal (active &
              inactive):
            </span>
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-start gap-2">
          <div
            className="flex flex-col grow gap-2 lg:gap-4"
            onClick={() => setShowReportMenu(!showReportMenu)}
          >
            <ButtonWithTooltip
              className="whiteBtn"
              text={
                generatingReport ? (
                  <Loader
                    containerStyle={{ margin: 0 }}
                    iconStyle={{ width: 18 }}
                  />
                ) : (
                  "Generate Report"
                )
              }
              forceClose={forceClose}
              toggleClickWatcher={false}
              tooltipContent={
                <div className="flex flex-col items-start p-4 gap-6 whitespace-nowrap">
                  <div className="flex flex-col items-start gap-2">
                    <span className="font-bold">Select report type to download:</span>
                    <label className="flex flex-row gap-2 items-center">
                      <input
                        type="radio"
                        name="downloadReportType"
                        value="accessReport"
                        onChange={(e) => setDownloadReport("accessReport")}
                        checked={downloadReport === "accessReport"}
                      />
                      Registration Report
                    </label>
                    <label className="flex flex-row gap-2 items-center">
                      <input
                        type="radio"
                        name="downloadReportType"
                        value="userReport"
                        onChange={(e) => setDownloadReport("userReport")}
                        checked={downloadReport === "userReport"}
                      />
                      Portal User Report
                    </label>
                  </div>
                  <div className="flex flex-row w-full">
                    <Button
                      className="darkBlue"
                      text="Download"
                      onClick={() => {
                        handleGenerateReport();
                        setForceClose((fc) => ++fc);
                      }}
                      disabled={!downloadReport}
                    />
                  </div>
                </div>
              }
            />
          </div>
          <div className="flex flex-col md:flex-row grow gap-2 lg:gap-4">
            <Button
              className="darkBlue"
              text="Add New User"
              onClick={() => setSearchUsers(true)}
            />
          </div>
        </div>
      </div>
      <hr className="m-0 text-[#B3B3B3]" />
      <TabbedSections
        sections={sections}
        data={tableData}
        columns={columns}
        sortValues={sortableProperties}
        accordionTitle={(u) => `${u.fName} ${u.lName}`}
        closeFilters={closeFilters}
        filterCount={filterCount}
        filters={
          <FilterPillContent
            filters={filters}
            filterCount={filterCount}
            onFiltersUpdated={setFilters}
          />
        }
        filterContent={
          <FilterTooltipContent
            onFiltersApplied={setFilters}
            filters={filters}
            applyCloseFilter={handleCloseFilters}
          />
        }
      />
    </MainContainer>
  );
};

const SearchUsersModal = ({
  onCreateUser,
  onClose,
  addUserError,
  setAddUserError,
  clearErrors,
}: {
  onCreateUser: (u: User) => void;
  onClose: () => void;
  addUserError: ShowErrorInterface;
  setAddUserError: (e: ShowErrorInterface) => void;
  clearErrors: () => void;
}) => {
  const [emailAddress, setEmailAddress] = useState<string>("");

  const {
    refetch: searchEmail,
    isLoading,
    error,
    data: foundUser,
    isError,
    isFetched,
    remove: resetSearchQuery,
  } = useSearchUserByEmail(emailAddress);
  const { isMobile } = useWindowSize();

  let width: number | undefined;
  width = isMobile ? 300 : 700;

  let footer: string | ReactElement = "";
  if (isLoading) {
    footer = <Loader />;
  }

  useEffect(() => {
    if (isError && isFetched && !addUserError.isError) {
      setAddUserError({
        title:
          "Please check the email address and try again. For all other questions, please contact Mitsubishi Power IT.",
        isError: true,
        ErrorType: "danger",
      });
    }
  }, [isError, isFetched, addUserError.isError, setAddUserError]);

  if (isFetched) {
    footer = (
      <div className="flex flex-row gap-4">
        <Button
          className="darkBlue"
          text="Add User"
          disabled={addUserError.isError}
          onClick={() => foundUser && onCreateUser(foundUser)}
        />
        <Button
          className="whiteBtn"
          style={{ borderWidth: 0 }}
          text="Cancel"
          onClick={onClose}
        />
      </div>
    );
  }

  return (
    <EmptyModal
      body={
        <div className="flex flex-col items-start gap-6">
          <span className="self-stretch">
            To add a new user to Customer Portal, please search Active Directory
            with the user&apos;s email address (provided by Mitsubishi Power
            IT).
          </span>

          <div className="flex flex-row items-start gap-6 self-stretch">
            <div className="flex flex-col items-start gap-2 grow">
              <span className="font-bold">
                Search Active Directory by Email Address
              </span>
              <div className="flex  flex-col md:flex-row grow  w-full !pb-0 items-center gap-3 wrap">
                <div className="relative flex flex-col md:flex-row w-full wrap">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none">
                    <img
                      src={magnifyGlassBlack}
                      alt="Search"
                      className="h-4 w-4 mx-1"
                    />
                  </div>
                  <input
                    type="email"
                    className="border-[#999999] border flex flex-row w-full grow block text-black text-sm rounded pl-8 p-1.5 outline-none box-border"
                    placeholder="Email address"
                    value={emailAddress}
                    onChange={(e) => {
                      clearErrors();
                      resetSearchQuery();
                      setEmailAddress(e.target.value);
                    }}
                  />
                </div>
                <div className="flex wrap">
                  <Button
                    className="whiteBtn"
                    disabled={!emailAddress.length}
                    text="Search"
                    onClick={searchEmail}
                  />
                </div>
              </div>
            </div>
          </div>
          {foundUser ? (
            <div className="flex flex-col items-start gap-4">
              <span>User found:</span>
              <span>
                {foundUser.fName} {foundUser.lName}
                <br />
                {foundUser.email}
                <br />
                {foundUser.aDuid}
              </span>
            </div>
          ) : isFetched ? (
            <span>User not found.</span>
          ) : (
            ""
          )}
          {addUserError.isError && (
            <div className="flex flex-col w-full !p-0">
              <Alert
                dismissible={false}
                type="error"
                onClose={() => {
                  resetSearchQuery();
                  clearErrors();
                }}
              >
                {addUserError.title}
              </Alert>
            </div>
          )}
        </div>
      }
      width={width}
      footer={footer}
      heading="Add New User"
      onClose={onClose}
      shown={true}
    />
  );
};

const UserCreatedModal = ({
  user,
  onClose,
  onConfigure,
}: {
  user: User;
  onClose: () => void;
  onConfigure: (u: User) => void;
}) => {
  let modalBody: ReactElement | undefined;
  let modalFooter: ReactElement | undefined;
  const { isMobile } = useWindowSize();
  let width: number | undefined;
  width = isMobile ? 300 : 564;

  modalBody = (
    <div className="flex flex-col items-center gap-4 wrap">
      <div className="text-center">{`"${user.fName} ${user.lName}" was successfully added to the Customer Portal user list. To allow this user to access the portal and site content, they require setup in the User Management page. Would you like to configure this user’s access at this time?`}</div>
    </div>
  );

  modalFooter = (
    <div className="flex gap-4" style={{ justifyContent: "center" }}>
      <Button
        className="darkBlue"
        text="Configure User"
        onClick={() => onConfigure(user)}
      />
      <Button className="whiteBtn" text="Not Now" onClick={onClose} />
    </div>
  );

  return (
    <EmptyModal
      body={modalBody}
      footer={modalFooter}
      footerClass={classes.userCreatedModalFooter}
      heading="User Successfully Added"
      width={width}
      onClose={onClose}
      shown={true}
      headingClass="text-center"
    />
  );
};

interface Filters {
  isStatus?: string[];
}
interface FilterTooltipContentProps {
  onFiltersApplied: (filters: Filters) => void;
  filters: Filters;
  applyCloseFilter: (val: boolean) => void;
}

const FilterTooltipContent: FunctionComponent<FilterTooltipContentProps> = ({
  onFiltersApplied,
  filters,
  applyCloseFilter,
}) => {
  const [filtersChanged, setFiltersChanged] = useState<boolean>(false);
  const [isStatus, setIsStatus] = useState<{ value: boolean; name: string }[]>(
    []
  );
  const [statusOptions] = useState<{ value: boolean; name: string }[]>([
    {
      value: false,
      name: "Active",
    },
    {
      value: false,
      name: "Deactivated",
    },
  ]);

  useEffect(() => {
    setIsStatus(
      statusOptions.map((s) => {
        var fil = filters.isStatus?.filter((p) => p === s.name);
        if (fil?.length && fil?.length > 0) s.value = true;
        return s;
      })
    );
  }, [filters]);

  const applyFilters = () => {
    setFiltersChanged(false);

    const filters: Filters = {};

    if (isStatus?.find((s) => s.value == true)) {
      filters.isStatus = isStatus.filter((s) => s.value).map((s) => s.name);
    }

    onFiltersApplied(filters);
    applyCloseFilter(true);
  };

  const canApplyFilters =
    filtersChanged || isStatus.find((s) => s.value === true);

  const handleIsStatus = (selectOption: string) => {
    const newState = isStatus.map((obj) => {
      if (obj.name === selectOption) {
        return { ...obj, value: !obj.value };
      }
      return obj;
    });

    setIsStatus(newState);
  };

  return (
    <div className={`${classes.flex_column} ${classes.filter_tooltip_content}`}>
      <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
        <label className="mb-2">Status: </label>
        <div className={classes.checkboxWrapper}>
          {isStatus.map((status, index) => (
            <div
              className={classes.checkboxRow}
              key={index}
              onClick={() => handleIsStatus(status.name)}
            >
              <Checkbox
                isChecked={status.value}
                label={status.name}
                onChange={() => {}}
              />
            </div>
          ))}
        </div>
      </div>
      <div className={`${classes.filter_tooltip_field}`}>
        <Button
          text="Apply"
          className="darkBlue"
          onClick={applyFilters}
          disabled={!canApplyFilters}
        />
      </div>
    </div>
  );
};

interface FilterPillContentProps {
  filters: Filters;
  filterCount: number;
  onFiltersUpdated: (filters: Filters) => void;
}

const FilterPillContent: FunctionComponent<FilterPillContentProps> = ({
  filters,
  filterCount,
  onFiltersUpdated,
}) => {
  if (filterCount > 0) {
    return (
      <div className={classes.filter_pill_container}>
        {filters.isStatus?.map((status) => (
          <Pill
            className={classes.filter_pill}
            key={status}
            dismissible={true}
            onDismiss={() =>
              onFiltersUpdated({
                ...filters,
                isStatus: filters.isStatus?.filter((d) => d !== status),
              })
            }
            type="default"
          >
            {status}
          </Pill>
        ))}
      </div>
    );
  }

  return <></>;
};
