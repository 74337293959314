import dayjs from "dayjs";
import React from "react";
import ImageImports from "../../utils/ImageImports";
import { QuoteRequestEventWithID, QuoteRequestGetAll } from "../../utils/interface";
import classes from "./QuoteRequestTracking.module.css";
import useWindowSize from "../../customHooks/useWindowSize";

const QuoteTrackingHeader = ({ event, title, status, quoteRequestList }: QuoteTrackingHeaderProps) => {
  const { downArrow, checked_icon, partial_checked_icon, checked_disabled_icon } = ImageImports;
  const greenText = { color: "#5E8800" };
  const greyText = { color: "#999" };
  const { isMobile, isTablet, isDesktop } = useWindowSize();

  let icon: string;
  let updatedOn: React.ReactNode;
  if (status === "completed") {
    icon = checked_icon;
    updatedOn = (
      <>
        {'Completed on ' + dayjs(event.modified).format('MM/DD/YYYY')}
      </>
    );
  } else if (status === "partial") {
    icon = partial_checked_icon;
    updatedOn = (
      <>
        {'Updated on ' + dayjs(event.modified).format('MM/DD/YYYY')}
      </>
    );
  } else {
    icon = checked_disabled_icon;
    updatedOn = <div className={classes.a_header_date}>Not Complete</div>;
  }
  return (
    <>
      <div className={`${classes.a_header} w-full`}>
        {!isMobile && !isTablet &&
          <div className={classes.a_header_icon}>
            <img src={icon} alt="checked icon" />
          </div>
        }
        <div className={`${classes.a_header_content} w-full`}>
          <div style={status === "disabled" ? greyText : greenText} className={`${classes.a_header_title} text-base lg:text-xl font-bold flex flex-row justify-between flex-nowrap`}>
            <span>{title}</span>
            <img className={classes.down_arrow} src={downArrow} />
          </div>
          <div style={{ fontSize: "14px" }} className={classes.a_header_date}>
            {updatedOn}
          </div>
        </div>
      </div>
    </>
  );
};

interface QuoteTrackingHeaderProps {
  title: string;
  quoteRequestList: QuoteRequestGetAll;
  status: "completed" | "partial" | "disabled";
  event: QuoteRequestEventWithID;
}

export default QuoteTrackingHeader;
