import dayjs, { Dayjs } from "dayjs";
import { FunctionComponent, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Store } from "../../Store";
import Button from "../../components/Button/Button";
import Dropdown from "../../components/Dropdown/Dropdown";
import Loader from "../../components/Loader/Loader";
import Pill from "../../components/Pill/Pill";
import { withTooltip } from "../../components/PopoutTooltip/Tooltip";
import SimpleSearch from "../../components/Search/SimpleSearch";
import DateRangePicker from "../../components/UI/BasicForm/CustomSingleDatePicker/DateRangePicker";
import { Checkbox } from "../../components/UI/Form/Checkbox/Checkbox";
import { RadioButtonGroup } from "../../components/UI/Form/RadioButtonsGrouped/RadioButtonsGrouped";
import { useOnError } from "../../customHooks/useOnError";
import usePagination from "../../customHooks/usePagination";
import useWindowSize from "../../customHooks/useWindowSize";
import { searchJsonObject } from "../../utils/DataUtils";
import ImageImports from "../../utils/ImageImports";
import { FilterDateComparisons } from "../../utils/Sortutils";
import { Permissions, QuoteRequestGetAll } from "../../utils/interface";
import { QuoteRequestEventID } from "../../utils/moduleIDs";
import classes from "./QuoteRequests.module.css";
import { useQuery_QuoteRequestItems } from "./QuoteRequestsHttpServices";
import QuoteRequestItem from "./QuoteRequestsItem";

type Props = {
  params?: { preSort?: string; preFilter?: string };
};

const QuoteRequestList = ({ params }: Props) => {
  // const { data } = data || { items: [] };
  const {selectedPlant, header: {data: {user, notifications}}} = useContext(Store);
  const [searchText, setSearchText] = useState("");
  const [filters, setFilters] = useState<Filters>({});
  const [qrList, setQrL] = useState<QuoteRequestGetAll[]>([]);
  const ButtonWithTooltip = withTooltip(Button);
  const [closeFilters, setCloseFilters] = useState<number>(0);
  const { isDesktop, isMobile, isTablet } = useWindowSize();
  const { magnifyGlassBlack, filter, Arrow } = ImageImports;
  const tooltipPortalRef = useRef(null);
  const [pagePermissions, setPagePermissions] = useState<Permissions>({
    baseOrg: null,
    canAdd: false,
    canAdmin: false,
    canDelete: false,
    canEdit: false,
    canView: false,
    orgGroup: null,
    role: ''
  });

  const canAddReports = useMemo(() => {
    return (user.portalAdmin && user.mpInternal) || pagePermissions.canAdd || pagePermissions.canAdmin;
  }, [user, pagePermissions]);

  const [enableClickWatcher, setEnableClickWatcher] = useState<boolean>(true);
  const { preSort = "", preFilter = "" } = useOutletContext<{
    quoteReqId: number;
    setQuoteReqId: React.Dispatch<React.SetStateAction<number>>;
    preSort: string;
    preFilter: string;
  }>();

  const setErrorData = useOnError();
  const navigate = useNavigate();
  const sortOptions: SortOption[] = [
    {
      id: 1,
      name: "Newest to Oldest",
      key: "submittedOn",
      direction: "desc",
    },
    {
      id: 2,
      name: "Oldest to Newest",
      key: "submittedOn",
      direction: "asc",
    },
  ];
  interface SortOption {
    id: number;
    name: string;
    key: keyof QuoteRequestGetAll;
    direction: "asc" | "desc";
  }
  const [sortSelectedOption, setSortSelectedOption] = useState<SortOption>(sortOptions[0]);

  const { isFetching } = useQuery_QuoteRequestItems(selectedPlant.plantId, {
    enabled: selectedPlant.plantId > 0,
    refetchOnWindowFocus: false,
    onSuccess: ({data, permissions}) => {
      setPagePermissions(permissions);
      setQrL(data);
    },
    onError: (error: unknown) => {
      // setQuoteRequestItems?.({...QuoteRequestItems!,success:false});
      setQrL([]);
      setErrorData(error);
    },
  });

  const filterReports = (QuoteRequest: QuoteRequestGetAll): boolean => {
    let matches = true;
    if (filters.dateStartDate) {
      matches = dayjs(QuoteRequest.submittedOn).isAfter(filters.dateStartDate);
    }
    if (matches && filters.dateEndDate) {
      matches = dayjs(QuoteRequest.submittedOn).isBefore(filters.dateEndDate);
    }

    if (matches && filters.isRequestType?.length) {
      matches = filters.isRequestType.includes(QuoteRequest.requestTypeName);
    }

    if (matches && filters.isPriority?.length) {
      matches = filters.isPriority.includes(QuoteRequest.quotePriority);
    }

    if (matches && filters.isStatus?.length && filters.isStatus.includes("In Progress")) {
      const isProgress = QuoteRequest.quoteRequestEvents?.map((e) => e.eventTypeId).indexOf(QuoteRequestEventID.QuoteClosed);
      matches = isProgress === -1;
    }
    if (matches && filters.isStatus?.length && filters.isStatus.includes("Closed")) {
      const isClosed = QuoteRequest.quoteRequestEvents?.map((e) => e.eventTypeId).indexOf(QuoteRequestEventID.QuoteClosed);
      matches = isClosed! >= 0;
    }

    return matches;
  };

  const {
    pagedData,
    setData,
    data,
    totalRecords,
    pageCount,
    setTotalRecords,
    setFilter,
    pageButtonNumbers,
    hasPreviousPage,
    previousPage,
    hasNextPage,
    nextPage,
    setCurrentPageNumber,
    currentPageNumber,
    resultsPerPage,
    setResultsPerPage,
    setSortKey,
    setSortDirection,
  } = usePagination<QuoteRequestGetAll>();

  const filterFn = (item: QuoteRequestGetAll): boolean => searchJsonObject(item, searchText);

  useEffect(() => {
    setSortDirection("desc");
    setSortKey("submittedOn");
  }, []);

  useEffect(() => {
    setFilters({});
  }, [selectedPlant.plantId]);

  useEffect(() => {
    if (!!qrList?.filter(filterReports).length) {
      setData(qrList.filter(filterReports));
      setTotalRecords(qrList.filter(filterReports).length);
    } else {
      setData([]);
      setTotalRecords(0);
    }
  }, [qrList, filters]);

  useEffect(() => {
    if (searchText.trim().length > 0 && !!qrList?.length) {
      setFilter(searchText.trim());
      setTotalRecords(data.filter(filterFn).length);
    } else {
      setFilter("");
      setTotalRecords(qrList.filter(filterReports).length);
    }
  }, [searchText]);

  useEffect(() => {
    if (!!preFilter?.length) {
      let temp = [];
      temp.push(preFilter);
      setFilters({ ...filters, isRequestType: temp, isStatus: ['In Progress'] });
    }
  }, []);

  const handleSort = (e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>, option?: any) => {
    setSortKey(option.key);
    setSortDirection(option.direction);
    setSortSelectedOption(option);
  };

  let filterCount = 0;

  if (filters.dateFilter) {
    filterCount++;
  }

  if (filters.system?.length) {
    filterCount += filters.system?.length;
  }
  if (filters.isStatus?.length) {
    filterCount += filters.isStatus?.length;
  }
  if (filters.isRequestType?.length) {
    filterCount += filters.isRequestType?.length;
  }
  if (filters.isPriority?.length) {
    filterCount += filters.isPriority?.length;
  }
  
  return (
    <div className="flex flex-col grow-1 justify-start rounded-b-lg ">
      {isDesktop && (
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
          <div style={{ flex: "2" }}>
            <h3 className={`text-base lg:text-2xl`}>
              <strong>{selectedPlant.name} Requests for Quotation</strong>
            </h3>
            <p className={"w-full pr-8 text-sm lg:mb-0"}>
              This page is the central location for users to request parts and service quotations from Mitsubishi Power Americas. If the request is made through this page of the Mitsubishi Power
              Americas Customer Portal, the response from Mitsubishi Power Americas will be documented here along with the request, and all members of the Customer Portal group for the plant site will
              receive email notifications when requests are made, and responses are posted.
            </p>
          </div>
          <div style={{ flex: "1" }}>
            {canAddReports && <Button style={{ marginLeft: "auto" }} onClick={() => navigate("add")} className={"darkBlue add-button"} text={"Request New Quote"} />}
          </div>
        </div>
      )}
      <>
        {!isDesktop && (
          <div className="flex flex-col">
            <div className={`pb-4 lg:pb-8`}>
              <h3 className={`text-base`}>
                <strong>{selectedPlant.name} Requests for Quotation</strong>
              </h3>
              {isTablet && (
                <p className={"w-full pr-8 text-sm"}>
                  This page is the central location for users to request parts and service quotations from Mitsubishi Power Americas. If the request is made through this page of the Mitsubishi Power
                  Americas Customer Portal, the response from Mitsubishi Power Americas will be documented here along with the request, and all members of the Customer Portal group for the specific
                  plant site will get notification emails from the Customer Portal when any requests are made or responses are posted.
                </p>
              )}
            </div>

            <div style={{ flex: "1" }}>{canAddReports && <Button onClick={() => navigate("add")} className={"darkBlue add-button mb-0"} text={"Request New Quote"} />}</div>
          </div>
        )}
      </>

      <hr />
      <div className={classes.qrcolumn}>
        <div className={`${!isMobile ? classes.flex_row : ""} ${classes.options_left}`}>
          <div className={` flex justify-start flex-row gap-4 lg:gap-6 md:w-3/5`}>
            {isDesktop && (
              <div className="nameplate-dropdown">
                <Dropdown
                  dropdowntype={"Sort by:"}
                  dropdownStyle={{ height: 42 }}
                  handleDropDownSelect={handleSort}
                  selectValue={"Select"}
                  selectedOption={sortSelectedOption.name}
                  options={sortOptions}
                />
              </div>
            )}
            <div className={`${classes.flex_row} ${classes.search_keyword_container}`}>
              <img src={magnifyGlassBlack} className="" alt="keyword search" />
              <input
                className={classes.search_keyword}
                value={searchText}
                placeholder="Search Quote Request"
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>

            {!isMobile && (
              <div style={{ display: "flex", alignItems: "center", height: !isDesktop ? "48px" : "initial" }}>
                <div className={`md:whitespace-nowrap  ${classes.table_icon}`}>
                  {" "}
                  {totalRecords} {totalRecords === 1 ? "Result" : "Results"}
                </div>
              </div>
            )}
          </div>

          <div className={classes.filterbutton}>
            <ButtonWithTooltip
              className="whiteBtn"
              text={
                <>
                  {filterCount > 0 ? <Pill type="primary">{filterCount}</Pill> : ""} {isDesktop ? "Filter" : "Sort/Filter"}
                </>
              }
              img={<img src={filter} alt="filter" className="bottom pl-2" />}
              textClassName="filter-text"
              forceClose={closeFilters}
              toggleClickWatcher={enableClickWatcher}
              tooltipContent={<FilterTooltipContent onFiltersApplied={setFilters} filters={filters} QuoteRequestItems={qrList} />}
            />
          </div>
        </div>

        {filterCount > 0 && (
          <div className={classes.filter_pill_container}>
            {filters.isRequestType?.map((status) => (
              <Pill
                className={classes.filter_pill}
                key={status}
                dismissible={true}
                onDismiss={() =>
                  setFilters((filters) => {
                    return {
                      ...filters,
                      isRequestType: filters.isRequestType?.filter((d) => d !== status),
                    };
                  })
                }
                type="default"
              >
                {status}
              </Pill>
            ))}
            {filters.isPriority?.map((status) => (
              <Pill
                className={classes.filter_pill}
                key={status}
                dismissible={true}
                onDismiss={() =>
                  setFilters((filters) => {
                    return {
                      ...filters,
                      isPriority: filters.isPriority?.filter((d) => d !== status),
                    };
                  })
                }
                type="default"
              >
                {status}
              </Pill>
            ))}
            {filters.isStatus?.map((status) => (
              <Pill
                className={classes.filter_pill}
                key={status}
                dismissible={true}
                onDismiss={() =>
                  setFilters((filters) => {
                    return {
                      ...filters,
                      isStatus: filters.isStatus?.filter((d) => d !== status),
                    };
                  })
                }
                type="default"
              >
                {status}
              </Pill>
            ))}

            {filters.dateFilter && (filters.dateFilter !== "datecustom" || filters.dateFilter === "datecustom") && (
              <Pill className={classes.filter_pill} dismissible={true} onDismiss={() => setFilters({ ...filters, dateFilter: undefined, dateStartDate: undefined })} type="default">
                {DateSubmitted.find((d) => d.value === filters.dateFilter)?.label}
              </Pill>
            )}
          </div>
        )}
        {isFetching ? (
          <Loader containerStyle={{ marginTop: "100px", marginBottom: "100px" }} />
        ) : isDesktop ? (
          <div className="overflow-y-auto  ">
            <table className={`text-left ${isDesktop ? classes.report_table : ""} table-auto w-full text-sm `}>
              <thead>
                <tr>
                  <th className="text-center w-[5%]">ID</th>
                  <th className=" whitespace-nowrap w-[10%]">Type</th>
                  <th className=" w-auto">Request Description</th>
                  <th className=" whitespace-nowrap w-[10%]">Equipment</th>
                  <th className=" whitespace-nowrap w-[10%]">Requestor</th>

                  <th className=" whitespace-nowrap w-[10%]">Submitted On</th>
                  <th className=" whitespace-nowrap w-[10%]">Date Quote Needed</th>
                  <th className=" whitespace-nowrap w-[5%]">Priority</th>
                  <th className=" whitespace-nowrap w-[5%]">Status</th>
                  <th className="text-center whitespace-nowrap w-[10%]">Details</th>
                </tr>
              </thead>
              <tbody>
                {qrList && qrList.length > 0 ? (
                  pagedData
                    ?.map((QuoteRequest: QuoteRequestGetAll, index: number) => (
                      <QuoteRequestItem
                        key={index}
                        unread={notifications?.filter((item) => item.itemId === QuoteRequest.globalItemId).length > 0}
                        index={index}
                        QuoteRequestItem={{ ...QuoteRequest }}
                      />
                    ))
                ) : (
                  <></>
                )}
              </tbody>
            </table>
            {qrList && qrList.length <= 0 && <div className="px-2 py-4 text-sm">No requests to display</div>}
          </div>
        ) : (
          <div className="flex flex-col gap-2 grow">
            {pagedData && pagedData.length > 0 ? (
              pagedData?.map((QuoteRequest: QuoteRequestGetAll, index: number) => (
                <QuoteRequestItem key={index} unread={notifications?.filter((item) => item.itemId === QuoteRequest.globalItemId).length > 0} index={index} QuoteRequestItem={{ ...QuoteRequest }} />
              ))
            ) : (
              <div style={{ paddingTop: "8%", paddingLeft: "10%", display: "block", width: "110%" }}>No requests to display</div>
            )}
          </div>
        )}
        {totalRecords > 0 && (
          <div className={`${classes.pagination} ${isDesktop || isTablet ? classes.flex_row : classes.flex_column}`}>
            <div className={`${classes.flex_row} ${classes.pagination_left}`}>
              <span className={classes.result_count}>
                {Math.min(resultsPerPage, pagedData.filter(filterReports).length)} of {totalRecords} Result{totalRecords !== 1 && "s"}
              </span>
              {pageCount > 1 && <Button className="whiteBtn show_all" text={isMobile || isTablet ? "Show All Results": "Show All"} onClick={() => setResultsPerPage(totalRecords)} style={{ order: isMobile ? "-1" : "unset" }} />}
            </div>

            {pageCount > 1 && (
              <div className={`${classes.flex_row} ${classes.page_numbers}`}>
                <img
                  src={Arrow}
                  className="pagination-left-arrow"
                  alt="arrow"
                  style={hasPreviousPage ? { filter: "invert(31%) sepia(21%) saturate(786%) hue-rotate(150deg) brightness(94%) contrast(83%)" } : {}}
                  onClick={() => {
                    if (hasPreviousPage) {
                      previousPage();
                    }
                  }}
                />
                {pageButtonNumbers.map((pageNumber) => (
                  <span
                    key={pageNumber}
                    className={`${classes.page_number} ${pageNumber === currentPageNumber ? classes.active : ""}`}
                    onClick={() => {
                      setCurrentPageNumber(pageNumber);
                    }}
                  >
                    {pageNumber}
                  </span>
                ))}
                <img
                  src={Arrow}
                  className="pagination-right-arrow"
                  style={hasNextPage ? { filter: "invert(31%) sepia(21%) saturate(786%) hue-rotate(150deg) brightness(94%) contrast(83%)" } : {}}
                  alt="arrow"
                  onClick={() => {
                    if (hasNextPage) {
                      nextPage();
                    }
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>

      <div ref={tooltipPortalRef} />
    </div>
  );
};

const DateSubmitted = [
  {
    label: "Last 24 Hours",
    value: "P1D", // ISO-8601 durations
  },
  {
    label: "Last 7 Days",
    value: "P7D",
  },
  {
    label: "Last 30 Days",
    value: "P30D",
  },
  {
    label: "Last 60 Days",
    value: "P60D",
  },
  {
    label: "Last 90 Days",
    value: "P90D",
  },
  {
    label: "Custom Range",
    value: "datecustom",
  },
];

interface Filters {
  dateStartDate?: Dayjs;
  dateEndDate?: Dayjs;
  dateFilter?: string;
  isStatus?: string[];
  isRequestType?: string[];
  isPriority?: string[];
  priority?: string[];
  system?: string[];
}
interface FilterTooltipContentProps {
  onFiltersApplied: (filters: Filters) => void;
  filters: Filters;
  QuoteRequestItems: QuoteRequestGetAll[]
}

const FilterTooltipContent: FunctionComponent<FilterTooltipContentProps> = ({ onFiltersApplied, filters, QuoteRequestItems: items }) => {
  const [filtersChanged, setFiltersChanged] = useState<boolean>(false);
  const [isStatus, setIsStatus] = useState<{ value: boolean; name: string }[]>([]);
  const [statusOptions] = useState<{ value: boolean; name: string }[]>([
    {
      value: false,
      name: "In Progress",
    },
    {
      value: false,
      name: "Closed",
    },
  ]);
  const [isRequestType, setIsRequestType] = useState<{ value: boolean; name: string }[]>([]);
  const [isPriority, setIsPriority] = useState<{ value: boolean; name: string }[]>([]);
  const { isMobile } = useWindowSize();
  const [dateStartDate, setDateStartDate] = useState(dayjs(null));
  const [dateEndDate, setDateEndDate] = useState(dayjs(null));
  const [dateFilter, setDateFilter] = useState<string>();
  const [showStartCalendar, setShowStartCalendar] = useState<boolean>(false);
  const [showEndCalendar, setShowEndCalendar] = useState<boolean>(false);

  useEffect(() => {
    const quotePriority = [...new Set(items?.map((i) => i.quotePriority))];
    setIsPriority([{ value: false, name: "Select system" }, ...quotePriority.map((s, index) => ({ value: false, name: s as string }))]);
  }, [items]);

  useEffect(() => {
    if (filters.dateFilter) {
      setDateFilter(filters.dateFilter);
      if (filters.dateFilter === "datecustom") {
        if (filters.dateStartDate) {
          setDateStartDate(dayjs(filters.dateStartDate).startOf("day"));
        }
        if (filters.dateEndDate) {
          setDateEndDate(dayjs(filters.dateEndDate).startOf("day"));
        }
      }
    }

    setIsStatus(
      statusOptions.map((s) => {
        var fil = filters.isStatus?.filter((p) => p === s.name);
        if (fil?.length && fil?.length > 0) s.value = true;
        return s;
      })
    );

    var requestTypeOption = items?.map((r) => {
      return { value: false, name: r.requestTypeName };
    });
    requestTypeOption = requestTypeOption?.filter((v, i, a) => a.findIndex((d) => d.name === v.name) === i);
    requestTypeOption &&
      setIsRequestType(
        requestTypeOption.map((s) => {
          var fil = filters.isRequestType?.filter((p) => p === s.name);
          if (fil?.length && fil?.length > 0) s.value = true;
          return s;
        })
      );

    var priorityOption = items?.map((r) => {
      return { value: false, name: r.quotePriority };
    });
    priorityOption = priorityOption?.filter((v, i, a) => a.findIndex((d) => d.name === v.name) === i);
    priorityOption &&
      setIsPriority(
        priorityOption.map((s) => {
          var fil = filters.isPriority?.filter((p) => p === s.name);
          if (fil?.length && fil?.length > 0) s.value = true;
          return s;
        })
      );
  }, [filters]);

  const applyFilters = () => {
    setFiltersChanged(false);

    const filters: Filters = {};

    if (dateFilter) {
      if (dateFilter === "datecustom" && dateStartDate && dateEndDate) {
        filters.dateStartDate = dayjs(dateStartDate.startOf("day"));
        filters.dateEndDate = dayjs(dateEndDate.endOf("day"));
      } else {
        filters.dateStartDate = FilterDateComparisons(dateFilter);
      }
      filters.dateFilter = dateFilter;
    }

    if (isStatus?.find((s) => s.value == true)) {
      filters.isStatus = isStatus.filter((s) => s.value).map((s) => s.name);
    }
    if (isRequestType?.find((s) => s.value == true)) {
      filters.isRequestType = isRequestType.filter((s) => s.value).map((s) => s.name);
    }
    if (isPriority?.find((s) => s.value == true)) {
      filters.isPriority = isPriority.filter((s) => s.value).map((s) => s.name);
    }
    onFiltersApplied(filters);
  };

  const canApplyFilters =
    (dateFilter && !(dateFilter === "datecustom" && (!dateStartDate || !dateEndDate))) ||
    filtersChanged ||
    isRequestType.find((s) => s.value === true) ||
    isStatus.find((s) => s.value === true) ||
    isPriority.find((s) => s.value === true);

  const handleRequestType = (selectOption: string) => {
    const newState = isRequestType.map((obj) => {
      if (obj.name === selectOption) {
        return { ...obj, value: !obj.value };
      }
      return obj;
    });

    setIsRequestType(newState);
  };
  const handleIsPriority = (selectOption: string) => {
    const newState = isPriority.map((obj) => {
      if (obj.name === selectOption) {
        return { ...obj, value: !obj.value };
      }
      return obj;
    });

    setIsPriority(newState);
  };
  const handleIsStatus = (selectOption: string) => {
    const newState = isStatus.map((obj) => {
      if (obj.name === selectOption) {
        return { ...obj, value: !obj.value };
      }
      return obj;
    });

    setIsStatus(newState);
  };
  const prioritySort = (a: {value: boolean; name: string;}, b: {value: boolean; name: string;}) => {
    if(a.name === "URGENT" && b.name !== "URGENT") {
      return -1;
    } else if(b.name === "URGENT" && a.name !== "URGENT") {
      return 1;
    } else if(a.name === "High" && b.name !== "High" && b.name !== "URGENT") {
      return -1;
    } else if (b.name === "High" && a.name !== "High" && a.name !== "URGENT") {
      return 1;
    } else if (a.name === "Normal" && b.name !== "URGENT" && b.name !== "High" && b.name !== "Normal") {
      return -1;
    } else if (b.name === "Normal" && a.name !== "URGENT" && a.name !== "High" && a.name !== "Normal") {
      return 1;
    } else {
      return -1;
    }
  }
  return (
    <div className={`${classes.flex_column} ${classes.filter_tooltip_content}`}>
      <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
        <label>Request Type:</label>
        <div className={classes.checkboxWrapper}>
          {isRequestType.map((status, index) => (
            <div className={classes.checkboxRow} key={index} onClick={() => handleRequestType(status.name)}>
              <Checkbox isChecked={status.value} label={`${status.name} Request`} onChange={() => {}} />
            </div>
          ))}
        </div>
      </div>
      <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
        <label>Priority: </label>
        <div className={classes.checkboxWrapper}>
          {isPriority.sort(prioritySort).map((status, index) => (
            <div className={classes.checkboxRow} key={index} onClick={() => handleIsPriority(status.name)}>
              <Checkbox isChecked={status.value} label={status.name} onChange={() => {}} />
            </div>
          ))}
        </div>
      </div>
      <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
        <label>Status: </label>
        <div className={classes.checkboxWrapper}>
          {isStatus.map((status, index) => (
            <div className={classes.checkboxRow} key={index} onClick={() => handleIsStatus(status.name)}>
              <Checkbox isChecked={status.value} label={status.name} onChange={() => {}} />
            </div>
          ))}
        </div>
      </div>
      <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
        <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
          <label>Date Submitted:</label>
          <RadioButtonGroup onChange={setDateFilter} name="datecustom" options={DateSubmitted} value={dateFilter} />
        </div>
        {dateFilter === "datecustom" && (
          <DateRangePicker
            startDate={dateStartDate}
            endDate={dateEndDate}
            showEndCalendar={showEndCalendar}
            showStartCalendar={showStartCalendar}
            startName="startDate"
            endName="endDate"
            startDateChange={setDateStartDate}
            endDateChange={setDateEndDate}
            setToggleEndCalendarDisplay={setShowEndCalendar}
            setToggleStartCalendarDisplay={setShowStartCalendar}
          />
        )}
      </div>

      <div className={`${classes.filter_tooltip_field}`}>
        <Button text="Apply" className="darkBlue" onClick={applyFilters} disabled={!canApplyFilters} />
      </div>
    </div>
  );
};

export default QuoteRequestList;
