import { updateInterface, PlantConfigData, PlantConfigDetailsDTO } from "../../../../utils/interface";
import { apiPlantConfig } from "../../../../endpoints/endpoints";

import axios, { AxiosResponse } from "axios";
import { useMutation } from "react-query";

interface PlantManagementMethods<T0, T1> {
	create: (data: FormData) => Promise<T0>;
	update: (data: FormData) => Promise<T1>;
}

export const PlantManagementApi: PlantManagementMethods<PlantConfigDetailsDTO, AxiosResponse<updateInterface>> = {
	create: async (data: FormData): Promise<PlantConfigDetailsDTO> => {
		const response = await axios({
			url: `${apiPlantConfig}/PostWithBaseOrg`,
			method: "POST",
			headers: {
				"Content-Type": "multipart/form-data",
			},
			data: data,
		});
		return response.data;
	},
	update: async (data: FormData): Promise<AxiosResponse<updateInterface>> => {
		const response = await axios({
			url: `${apiPlantConfig}`,
			method: "PUT",
			headers: {
				// Accept: "application/json",
				"Content-Type": "application/json",
			},
			data: data,
		});
		return response.data;
	},
};

export const useCreate_Plant = () => {
	return useMutation(PlantManagementApi.create);
};
