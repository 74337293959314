import React from "react";
import { AccountInfo } from "@azure/msal-browser";

export interface AuthorizationContextInterface {
    hasPortalAccess: boolean;
    account?: AccountInfo | undefined;
    userId?: string | undefined;
    userName?: string | undefined;
    hasCheckedForPortalAccess: boolean;
    recheckPortalAccess: () => void;
}

export const AuthorizationContext = React.createContext<AuthorizationContextInterface>({
    hasPortalAccess: false,
    hasCheckedForPortalAccess: false,
    recheckPortalAccess: () => {}
});