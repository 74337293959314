import React, { FunctionComponent } from "react";
import ImageImports from "../../utils/ImageImports";
import classes from "./SimpleSearch.module.css";

const { magnifyGlassBlack } = ImageImports;

const SimpleSearch: FunctionComponent<{
	searchText: string;
	setSearchText: (input: string) => void
	placeholder?: string;
}> = ({ searchText, setSearchText, placeholder }) => {
	return (
		<div className={`${classes.flex_row} ${classes.search_keyword_container} h-[48px] lg:h-[36px]`} >
			<img src={magnifyGlassBlack} className="" alt="keyword search" />
			<input className={`${classes.search_keyword}`} value={searchText} placeholder={placeholder} onChange={(e) => setSearchText(e.target.value)} />
		</div>
	);
};
export default SimpleSearch;
