export const queryKeys = {
    plantSiteDetails :"plantSiteDetails",
    plantFuelTypes :"plantFuelTypes",
    equipmentAttachmentType:"equipmentAttachmentType",
    associatedUnitType:"associatedUnitType",
    plantConfig:"plantConfig",
    productType:"productType",
    plantProfileType:"plantProfileType",
    manufacturerType:"manufacturerType",
    technicalDocumentation:"technicalDocumentation",
    header:"header",
    accountSettings:"accountSettings",
    unit:"unit",
    unitGetAll:"unitGetAll",
    ommanualSectionGetAll:"ommanualSectionGetAll",
    PartsCatalogSectionGetAll:"PartsCatalogSectionGetAll",
    partsAndServices:"partsAndServices",
    issueReports:"issueReports",
    issueReportItems:"issueReportItems",
    roleModulePermissions:"roleModulePermissions",
    user:"user",
    KPI:"KPI",
    QuoteRequest:"QuoteRequest",
    QuoteRequestItems:"QuoteRequestItems",
    getEquipmentAttachmentFile:"getEquipmentAttachmentFile",
    equipmentAttachment:"equipmentAttachment",
    getAllPlantEquipmentsAttachments:"getAllPlantEquipmentsAttachments",
    plantConfigImage:"plantConfigImage",
    plantEquipment:"plantEquipment",
    plantEquipments:"plantEquipments",
    plantContacts:"plantContacts",
    plantContact:"plantContact",
    plantContactAvatar:"plantContactAvatar",
    unitConfigurationTypes:"unitConfigurationTypes",
    equipmentModelNameTypes:"equipmentModelNameTypes",
    genrateReport:"genrateReport",
    technicalbulletin:"technicalbulletin",
    getAllQuoteRequestType:"getAllQuoteRequestType",
    getAllRequestPriority:"getAllRequestPriority",
    getAllPartsRequestReasons:"getAllPartsRequestReasons",
    getAllServiceRequestReasons:"getAllServiceRequestReasons",
    getAllQuoteTypes:"getAllQuoteTypes",
    getAllOutageSeasons:"getAllOutageSeasons",
    apiquoterequest:"apiquoterequest",
    File:"File",
    getQuoteRequestDocument:"getQuoteRequestDocument",
    DeleteFile:"DeleteFile",
    NamePlateFile:"File",
    PartsFile:"File",
    getFaqApi:"getFaqApi",
    getQuoteRequest:"getQuoteRequest",
    getQuoteRequestAttachments:"getQuoteRequestAttachments",
    getAllCustomerSurveys: "getAllCustomerSurveys",
}