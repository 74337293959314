import React, { CSSProperties } from "react";

import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { $createParagraphNode, $getRoot, $insertNodes, EditorState, LexicalEditor } from 'lexical';
import LexicalAutoLinkPlugin from './Plugins/AutoLinkPlugin';
import ListMaxIndentLevelPlugin from './Plugins/ListMaxIndentLevelPlugin';
import ToolbarPlugin from './Plugins/ToolbarPlugin';
import Theme from './Theme';
import './theme.css';

function Placeholder(props: { fullSize?: boolean }) {
    return <div style={{ left: props.fullSize ? '2.75%' : '3.75%' }} className="editor-placeholder">Enter some rich text...</div>;
}

interface IEditorProps {
    handleChange?: (val: string) => void;
    readonly?: boolean;
    content?: string;
    allowUploads?: boolean;
    reset?: boolean;
    uploadFile?: (attachment: File) => void;
    fullSize?: boolean;
    contentEditableStyle?: CSSProperties;
}

const Editor = ({ handleChange, content, readonly, allowUploads, reset, uploadFile, contentEditableStyle = {} }: IEditorProps) => {
    let contentJSON = '{}'
    const editorConfig = {
        namespace: "CustomRTE",
        editable: !readonly,
        theme: Theme,
        onError(error: any) {
            throw error;
        },
        editorState:
            (editor: LexicalEditor) => {
              // For autoFocus to work, the editor needs to have at least one node present in the root.
              // Adding a default node to the root also resolved the copy/paste formated text issue, 
              // whereby if you posted formated text into the editor and the editor did not have a root node, 
              // the formated text would paste as plain text
              const root = $getRoot();
              if (root.isEmpty()) {
                  const paragraph = $createParagraphNode();
                  root.append(paragraph);
              }

              try {
                  contentJSON = content && JSON.parse(content);
                  if (!allowUploads) {
                      const editorState = editor.parseEditorState(contentJSON)
                      editor.setEditorState(editorState)
                  } else {
                      if (!!content?.length) {                          
                          // changed from this bc styling is getting dropped, one could re-map each node explicitly and apply the styling
                          const parser = new DOMParser();
                          const dom = parser.parseFromString(content, 'text/html');
                          const nodes = $generateNodesFromDOM(editor, dom);
                          const p = $createParagraphNode();
                          $getRoot().append(p);
                          p.select();
                          $insertNodes(nodes);
                      }
                  }
              } catch(e) {                    
                  // if (!allowUploads) {
                  //     const editorState = editor.parseEditorState(contentJSON)
                  //     editor.setEditorState(editorState)
                  // } else {
                      if (!!content?.length) {
                          // changed from this bc styling is getting dropped, one could re-map each node explicitly and apply the styling
                          const parser = new DOMParser();
                          const dom = parser.parseFromString(content, 'text/html');
                          const nodes = $generateNodesFromDOM(editor, dom);
                          const p = $createParagraphNode();
                          $getRoot().append(p);
                          p.select();
                          $insertNodes(nodes);
                      }
                  // }
              }
            },
        nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            QuoteNode,
            AutoLinkNode,
            LinkNode
        ]
    };

	const onChange = (editorState: EditorState, editor: LexicalEditor) => {
    editorState.read(() => {
      // using allowUploads as indicator editor is for discussion boards
      // leaving message data as stringified html bc I dont have time to modify the message containers
      const root = $getRoot();
      const plain = root.__cachedText;

      let htmlString;
      if (!allowUploads) {
          htmlString = JSON.stringify(editorState)
      } else {
        if (plain && plain.length) {
          htmlString = $generateHtmlFromNodes(editor, null);
        } else {
          htmlString = "";
        }
      }
      
      handleChange && handleChange(htmlString);
    });
  };
    
  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className={readonly ? 'readonly' : "editor-container"}>
        {!readonly && <ToolbarPlugin refresh={reset} allowUploads={allowUploads && !!uploadFile} uploadFile={uploadFile} />}
        <div className={readonly ? 'readonly' : "editor-inner"}>
          <RichTextPlugin
            contentEditable={<ContentEditable style={contentEditableStyle} className="editor-input" />}
            placeholder={<Placeholder fullSize />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <ClearEditorPlugin />
          <OnChangePlugin onChange={onChange} />
          <HistoryPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <AutoFocusPlugin />
          <ListPlugin />
          <LinkPlugin />
          <LexicalAutoLinkPlugin />
        </div>
      </div>
    </LexicalComposer>
  );
}

export default Editor;