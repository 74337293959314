import { useStore } from "../../../useStore";
import { CloseAllModal } from "../../../utils/CloseAllModal";
import ModalGlobal from "../../UI/Modal/ModalGlobal";

function FormDeleteGeneric(props: FormDeleteGenericProps) {
  const { isModal, setIsModal } = useStore();

  return (
    <ModalGlobal
      modaltitle={props.modaltitle}
      modaltitlename={props.modaltitlename}
      modalSize={props.modalSize}
      isModalHeader={true}
      isTitleCenter={props.isTitleCenter}
      modalClose={props.handleCrossIcon}
    >
      <div className="flex align-center marginBot40">
        <div className="flex-grow-1">{props.comment}</div>
      </div>
      <div className="flex align-center">
        {props.button_addmore && props.button_done ? (
          <div
            className="flex-grow-1 flex"
            style={{
              justifyContent: "flex-start",
            }}
          >
            <button
              type="button"
              className="primaryBtn redDelete"
              onClick={(e) => {
                e.preventDefault();
                props.onClick && props.onClick();
              }}
            >
              {props.buttonName}
            </button>
            <button
              type="button"
              className="primaryBtn whiteBtn marginLeft16"
              onClick={() => {
                setIsModal?.({
                  ...CloseAllModal,
                });
                props.buttonDoneOnclick && props.buttonDoneOnclick();
              }}
            >
              {props.DoneButtonName}
            </button>
          </div>
        ) : props.button_addmore ? (
          <button
            type="button"
            className="primaryBtn redDelete"
            onClick={(e) => {
              e.preventDefault();
              props.onClick && props.onClick();
            }}
          >
            {props.buttonName}
          </button>
        ) : (
          <button
            type="button"
            className="primaryBtn redDelete"
            style={{ margin: "auto" }}
            onClick={() => {
              setIsModal?.({
                ...CloseAllModal,
              });
              props.buttonDoneOnclick && props.buttonDoneOnclick();
            }}
          >
            {props.DoneButtonName}
          </button>
        )}
      </div>
    </ModalGlobal>
  );
}

interface FormDeleteGenericProps {
  modaltitlename: string;
  modaltitle: string;
  comment: string;
  modalSize: "sm" | "md";
  button_done: boolean;
  buttonDoneOnclick?: () => void;
  handleCrossIcon?: () => void;
  button_addmore: boolean;
  isTitleCenter?: boolean;
  buttonName?: string;
  DoneButtonName?: string;
  onClick?: () => void;
}

FormDeleteGeneric.defaultProps = {
  DoneButtonName: "Done",
};
export default FormDeleteGeneric;
