import Button from "../../components/Button/Button";
import { MainContainer } from "../MainContainer"
import { getKpiReport } from "../Support/ESR/ESRHttpServices";
import { KPIReportData } from "../../utils/interface";
import { useOnError } from "../../customHooks/useOnError";
import { usePageRequiresAdmin } from "../../customHooks/usePageRequiresAdmin";
import { downloadFileByUrl } from "../../customHooks/FileHttpServices";

type HeadingType = {
  [Property in keyof KPIReportData]: string
};

const headings: HeadingType = {
  baseOrgId: 'Base Organization Id',
  plantName: 'Plant Name',
  averagePartsTurnaround: 'Average Parts Turnaround Time (days)',
  averageServiceTurnaround: 'Average Service Turnaround Time (days)',
  averageESRTurnaround: 'Average Engineering Support Turnaround Time (days)',
  esrDeliveredOnTimePercent: 'Engineering Support Delivered By Promise Date (percent)'
};

export const DataReporting = () => {
  usePageRequiresAdmin();

  const getAllPlantKpiData = async () => {
    downloadFileByUrl(getKpiReport());
  };

  return <MainContainer breadCrumbs={['Administration', 'Data & Reporting']} title="Data & Reporting">
    <div className="flex flex-row items-start gap-6 self-stretch">
      <div className="flex flex-col items-start gap-2 grow" style={{ flex: "2 1 0%" }}>
        <span className="font-bold text-2xl self-stretch">Data & Reporting</span>
        <span className="text-base">Click here to view system-generated reports regarding plants and other information.</span>
      </div>
      <div className="flex flex-row gap-4" style={{ flex: "1 1 0%" }}>
        <Button className="darkBlue" text="Generate Report" style={{ marginLeft: "auto" }} onClick={getAllPlantKpiData} />
      </div>
    </div>
  </MainContainer>;
};