import { ReactElement, useCallback, useContext, useMemo } from "react";
import { Store } from "../../Store";
import { Notification, NotificationType } from "../../utils/interface";
import { getSortObjectsByKeyFn } from "../../utils/Sortutils";
import { AccordionGroup } from "./AccordionGroup";
import { NotificationItem } from "./NotificationItem";
import { AccordionDataType } from "./types";

const accordionOrder = [
  'Administration', 'Account', 'Plant Information', 'Issue Reports', 'Discussions', 'Quote Requests', 'Documents'
];

type AccordionDataProps = {
  selectedType?: NotificationType;
}

export const AccordionData = ({ selectedType }: AccordionDataProps) => {
  const {header, notificationTypes} = useContext(Store);
  const accordionItems: AccordionDataType[] = [];

  const notifications = useMemo(() => header.data.notifications, [header.data]);

  const notificationsByType: {[index: NotificationType['typeId']]: Notification[]} = useMemo(() => {
    const tmp: {[index: NotificationType['typeId']]: Notification[]} = {};
    for (let i = 0; i < notifications.length; i++) {
      if (!tmp.hasOwnProperty(notifications[i].notificationTypeId)) {
        tmp[notifications[i].notificationTypeId] = [];
      }
      tmp[notifications[i].notificationTypeId].push(notifications[i])
    }
    return tmp;
  }, [notifications]);

  const createNotificationItemFn = useCallback((items: Notification[], notificationName: string) => {
    return (): ReactElement => {
      return <>
        {items.length ? 
          items.map(item => <NotificationItem key={item.notificationId} notification={item} />)
          :
          <div className="flex row p-4">
            <div className="text-gray-900">{`You have no new ${notificationName} notifications.`} </div>
          </div>
        }
      </>;
    }
  }, []);

  const createNotificationHeader = useCallback((title: NotificationType['name'], notifications: Notification[]) => {
    const hasUnread = notifications.filter(d => !d.read).length > 0;
    return <>
      <span>{`${title} (${notifications.length})`}</span>
      {hasUnread && (
        <span className="bg-[#E31F26] rounded-full h-2 w-2">&nbsp;</span>
      )}
    </>;
  }, []);

  const sortNotificationsFn = getSortObjectsByKeyFn<Notification>('date', 'desc');

  for (let i = 0; i < notificationTypes.length; i++) {
    const notifications = notificationsByType[notificationTypes[i].typeId] ?? [];
    notifications?.sort(sortNotificationsFn);
    accordionItems.push({
      content: createNotificationItemFn(notifications, notificationTypes[i].name),
      key: notificationTypes[i].name,
      title: createNotificationHeader(notificationTypes[i].name, notifications)
    });
  }

  const sortFn = useCallback((a: AccordionDataType, b: AccordionDataType) => {
    const aIndex = accordionOrder.indexOf(`${a.key}`);
    const bIndex = accordionOrder.indexOf(`${b.key}`);
    if (aIndex > bIndex) return 1;
    if (bIndex > aIndex) return -1;
    return 0;
  }, [accordionItems]);
  
  return (
    <AccordionGroup openIndex={accordionOrder.findIndex(row => row === (selectedType?.name || sessionStorage.getItem("selectedAccordionRow")))} items={accordionItems.sort(sortFn)} />
  );
}
