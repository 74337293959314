import React, { useContext, useState, useEffect } from "react";
import Button from "../Button/Button";
import issueReports from "../../assets/issue-reports.svg";
import rightArrow from "../../assets/arrow-right.svg";
import Notification from "../Notification/Notification";
import { AxiosError } from "axios";
import { IssueReportsDTO } from "../../utils/interface";
import { Store } from "../../Store";
import { useGet_IssueReports } from "../../containers/PlantInformation/DashboardHttpServices";
import "./IssueReports.css";
import useGetNotificationCount from "../../customHooks/useGetNotificationCount";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import { useIsFetching } from "react-query";
import { queryKeys } from "../../react-query/queryKeys";
import HomeCardLoader from "../HomeCardLoader/HomeCardLoader";
import MiniTooltip from "../UI/MiniTooltip/MiniTooltip";
import ImageImports from "../../utils/ImageImports";

const {help} = ImageImports;

function IssueReports() {
  const stateContext = useContext(Store);
  const isFetching = useIsFetching([queryKeys.issueReports]);
  const {
    issueReports: { data },
    notificationTypes,
    setIssueReports,
    setShowError,
  } = stateContext;
  // Fetch Plant Details Start

  const onSuccess = (data: IssueReportsDTO) => {
    setIssueReports?.(data);
  };
  const onError = (error: AxiosError<string>) => {
    setShowError?.({
      isError: true,
      title: error.response?.data ? error.response?.data : error.message,
      ErrorType: "danger",
    });
  };
  const { isLoading } = useGet_IssueReports(onSuccess, onError);
  const navigate = useNavigate();

  const notificationCount = useGetNotificationCount(notificationTypes[2]);
  return (
    <>
      {isFetching > 0 ? (
        <HomeCardLoader showBottom={true} minHeight={"400px"} />
      ) : (
        <div className="flex flex-col flex-nowrap h-full p-6">
          <div className="flex-1 row">
            <span className="title">
              <b>Issue Reports</b>
            </span>
            <Notification innerSpacingZero={false} leftAlignment={26} value={notificationCount.unread} selectedType={notificationTypes[2]}>
              <div className="">
                <img src={issueReports} className="report-icon" alt="" />
              </div>
            </Notification>
          </div>
          <div className="flex-1 row center h-6">
            <div className="notify issue">{isLoading ? <Loader containerStyle={{ margin: 0 }} iconStyle={{ width: 18 }} /> : data.newIssueReportsCount}</div>
            <div className="flex flex-row row-item text-l items-center">
              New Issue Reports
              <span className="cursor-pointer">
                <MiniTooltip text={"Issue Reports submitted in last 14 days."}>
                  <img src={help} style={{ maxWidth: "16px", display: "block", marginLeft: "6px" }} alt="Help" />
                </MiniTooltip>
              </span>
            </div>
            <div className="arrow-wrapper">
              <Link to='/issue-reports' state={{ preFilter: "new" }}>
                <img src={rightArrow} className="arrow" alt="" />
              </Link>
            </div>
          </div>
          <div className="flex-1 row center h-6">
            <div className="notify issue">{isLoading ? <Loader containerStyle={{ margin: 0 }} iconStyle={{ width: 18 }} /> : data.agedIssueReportsCount}</div>
            <div className="flex flex-row row-item text-l items-center">
              Aged Issue Reports
              <span className="cursor-pointer">
                <MiniTooltip text={"Issue Reports submitted that have been open for 6 months or more."}>
                  <img src={help} style={{ maxWidth: "16px", display: "block", marginLeft: "6px" }} alt="Help" />
                </MiniTooltip>
              </span>
            </div>
            <div className="arrow-wrapper">
              <Link to='issue-reports' state={{ preFilter: "aged" }}>
                <img src={rightArrow} className="arrow" alt="" />
              </Link>
            </div>
          </div>
          <div className="flex-1 row center h-6">
            <div className="notify issue">{isLoading ? <Loader containerStyle={{ margin: 0 }} iconStyle={{ width: 18 }} /> : data.resolvedIssueReportsCount}</div>
            <div className="flex flex-row row-item text-l items-center">
              Resolved Issue Reports
              <span className="cursor-pointer">
                <MiniTooltip text={"Issue Reports resolved YTD."}>
                  <img src={help} style={{ maxWidth: "16px", display: "block", marginLeft: "6px" }} alt="Help" />
                </MiniTooltip>
              </span>
            </div>
            <div className="arrow-wrapper">
              <Link to='issue-reports' state={{ preFilter: "resolved" }}>
                <img src={rightArrow} className="arrow" alt="" />
              </Link>
            </div>
          </div>
          <div className="flex h-full items-end mb-4">
            <span className="grow">
              <Button onClick={() => navigate('/issue-reports')} className="darkBlue" text="View All Issue Reports" />
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export default IssueReports;
