import { PlantConfigDTO, PlantConfigData, CPApiResponse, PlantSystemDetails } from "../../utils/interface";
import { apiPlantConfig, apiPlantConfigImage } from "../../endpoints/endpoints";

import axios, {  AxiosError, AxiosResponse } from "axios";
import { MutationFunction, MutationOptions, QueryFunctionContext, useMutation, useQuery, UseQueryOptions } from "react-query";
import { queryKeys } from "../../react-query/queryKeys";
import { useContext } from "react";
import { Store } from "../../Store";
import { useBaseOrgIdHeader } from "../../customHooks/useBaseOrgIdHeader";
import { handleAxiosResponse } from "../../utils/handleAxiosResponse";

export interface PlantConfigCrudMethods<T, TD, B> {
  update: (data: TD) => Promise<T>;
  create: (data: TD) => Promise<T>;
  getFile: (plantId: number) => Promise<B>;
}

export const PlantConfigApi: PlantConfigCrudMethods<AxiosResponse<PlantConfigDTO>, PlantConfigData, any> = {

  update: async (data: PlantConfigData): Promise<AxiosResponse<PlantConfigDTO>> => {
    const response = await axios({
      url: `${apiPlantConfig}/${data.plantId}`,
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
    return response.data;
  },
  create: async (data: PlantConfigData): Promise<AxiosResponse<PlantConfigDTO>> => {
    const response = await axios({
      url: `${apiPlantConfig}/${data.plantId}`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
    return response.data;
  },
  getFile: async (plantId: number): Promise<any> => {
    const response = await axios({
      url: `${apiPlantConfigImage}/${plantId}`,
      method: "GET",
      responseType: "blob",
    }); 
    return response.data;
  }  
};


declare type getSelectedPlantConfigParameters = [string, {plantId: number, baseOrgId: number}];
const getSelectedPlantConfig = async ({queryKey}: QueryFunctionContext<getSelectedPlantConfigParameters>): Promise<PlantConfigDTO> => {
    const [_key, {plantId}] = queryKey;
    if (!plantId) {
        return Promise.reject(new Error('Invalid Plant Id'));
    }
    const response: AxiosResponse<PlantConfigDTO> = await axios(`${apiPlantConfig}/${plantId}`);
    return response.data;
};
export const usePlantConfigData = (onSuccess: any, onError: any) => {
  const {selectedPlant} = useContext(Store);
  const hasBaseOrgIdForApiCalls = useBaseOrgIdHeader();
  return useQuery([queryKeys.plantConfig, {plantId: selectedPlant.plantId, baseOrgId: selectedPlant.baseOrgId}], getSelectedPlantConfig, {
    enabled: selectedPlant.plantId > 0 && hasBaseOrgIdForApiCalls,
    onSuccess,
    onError,
  });
};

const getPlantConfig = async ({queryKey}: QueryFunctionContext<getSelectedPlantConfigParameters>): Promise<PlantConfigData> => {
  const [_key, {plantId}] = queryKey;
  if (!plantId) {
      return Promise.reject(new Error('Invalid Plant Id'));
  }
  const response: AxiosResponse<CPApiResponse<PlantConfigData>> = await axios(`${apiPlantConfig}/${plantId}`);
  return handleAxiosResponse(response);
};
export const useGetPlantConfigData = (plantId: number, options: Omit<UseQueryOptions<PlantConfigData, AxiosError<string>, PlantConfigData, getSelectedPlantConfigParameters>, 'queryFn' | 'queryKey'> = {}) => {
  return useQuery<PlantConfigData, AxiosError<string>, PlantConfigData, getSelectedPlantConfigParameters>([queryKeys.plantConfig, {plantId, baseOrgId: 0}], getPlantConfig, options)
};

export const useGetFile_PlantConfigData = (plantId: number, onSuccess: any, onError: any) => {
  return useQuery([queryKeys.plantConfigImage, plantId], () => PlantConfigApi.getFile(plantId), {
    enabled: !!plantId,
    onSuccess,
    onError,
  });
};

export const useUpdate_PlantConfigData = () => {
  return useMutation(PlantConfigApi.update);
};

declare type UpdateSystemDetailsParameters = {
  plantId: number,
  plantSystemDetails: PlantSystemDetails
};
const updateSystemDetails: MutationFunction<boolean, UpdateSystemDetailsParameters> = async ({plantId, plantSystemDetails}: UpdateSystemDetailsParameters): Promise<boolean> => {
  const formData = new FormData();
  formData.append('SPLegacyID', `${plantSystemDetails.spLegacyID}`);
  formData.append('TomoniDashPlantName', plantSystemDetails.tomoniDashPlantName);
  formData.append('EsrPlantName', plantSystemDetails.esrPlantName);
  const response: AxiosResponse<CPApiResponse<boolean>> = await axios.put(`${apiPlantConfig}/SaveSystemDetails/${plantId}`, formData);
  return handleAxiosResponse(response);
};

export const useUpdateSystemDetails = (options: Omit<MutationOptions<boolean, AxiosError<string>, UpdateSystemDetailsParameters>, 'mutationFn'> = {}) => {
  return useMutation(updateSystemDetails, options);
};