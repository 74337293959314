import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { ItemList, ItemListColumnDefinition, SortOptionsDefinition } from "../../../../components/List/ItemList";
import { default as Loader } from "../../../../components/Loader/Loader";
import { default as Pill } from "../../../../components/Pill/Pill";
import { PlantConfigData, PlantManagementFilterInterface } from "../../../../utils/interface";
import PlantManagementFilter from "./PlantManagementFilter";

const defaultFilters: PlantManagementFilterInterface = {
	unitAssociations: 'any'
};

type PlantConfigDataItemList = PlantConfigData & {id: number};

function PlantList({ plants, isLoading }: { plants: PlantConfigData[], isLoading: boolean }) {
	const [appliedFilters, setAppliedFilters] = useState<PlantManagementFilterInterface>(defaultFilters);
	const [closeFilters, setCloseFilters] = useState<number>(0);
  const [tableData, setTableData] = useState<PlantConfigDataItemList[]>([]);

	const plantFilter = useCallback((plant: PlantConfigData): boolean => {
		let matches = true;
		if (appliedFilters.unitAssociations === 'none' && plant.units !== null && plant.units.length > 0) {
			matches = false;
		}
		if (appliedFilters.unitAssociations === 'some' && (plant.units === null || plant.units.length === 0)) {
			matches = false;
		}
		return matches;
	}, [appliedFilters]);

	const filterCount = useMemo(() => {
		let c = 0;
		if (appliedFilters.unitAssociations !== 'any') {
			c++;
		}
		return c;
	}, [appliedFilters]);

	const plantData: PlantConfigDataItemList[] = plants?.map(d => ({...d, id: d.plantId}));
    
    const columns: ItemListColumnDefinition<PlantConfigDataItemList>[] = [
        {
            title: 'Plant Name',
            key: 'baseOrgName',
        },
        {
            title: 'Units',
            key: 'units',
			component: ({data}) => <span>{(data.units || []).length}</span>
        },
        {
            title: 'Owner',
            key: 'owner'
        },
        {
            title: 'Operator',
            key: 'operators'
        },
        {
            title: 'Region',
            key: 'mhiRegion'
        },
        {
            title: 'Frequency',
            key: 'frequency',
			component: ({data}) => <span>{Number(data.frequency)? `${data.frequency} Hz.` : data.frequency}</span>
        },
        {
            title: 'Details',
            key: 'plantId',
			component: ({data}) => <Link className="font-bold no-underline text-dark-blue" to={`./plant/${data.plantId}`}>View</Link>
        }
    ];
    const sortableProperties: SortOptionsDefinition<PlantConfigDataItemList>[] = [
		{
			id: 1,
			name: "Name: A to Z",
			key: "baseOrgName",
			direction: 'asc'
		},
		{
			id: 2,
			name: "Name: Z to A",
			key: "baseOrgName",
			direction: 'desc'
		},
		{
			id: 3,
			name: "Operator: A to Z",
			key: "operators",
			direction: 'asc'
		},
		{
			id: 4,
			name: "Operator: Z to A",
			key: "operators",
			direction: 'desc'
		},
		{
			id: 5,
			name: "Owner: A to Z",
			key: "owner",
			direction: 'asc'
		},
		{
			id: 6,
			name: "Owner: Z to A",
			key: "owner",
			direction: 'desc'
		},
		{
			id: 9,
			name: "Units: High to Low",
			key: "units",
			direction: 'desc',
			manipulateFn: d => d !== null? (d?.length || 0) : d,
		},
		{
			id: 10,
			name: "Units: Low to High",
			key: "units",
			direction: 'asc',
			manipulateFn: d => d !== null? (d?.length || 0) : d,
		}
  ];

  useEffect(() => {
    setTableData(plantData.filter(plantFilter))
  }, [plants]);

	return (
		<div>
			{isLoading 
				? <div>
					<Loader />
				</div> 
				: (
					<ItemList 
            columns={columns}
            data={tableData}
            sortValues={sortableProperties}
            closeFilters={closeFilters}
            accordionTitle={(data) => data.baseOrgName}
            filterContent={<PlantManagementFilter appliedFilters={appliedFilters} setAppliedFilters={e => {
              setAppliedFilters(e)
              setCloseFilters(c => ++c);
            }} />}
            filterCount={filterCount}
            filters={<FilterPills filters={appliedFilters} onFiltersUpdated={setAppliedFilters} />} 
            searchBoxPlaceholder="Search plants"
          />
				)
			}
		</div>
	);
}

export default PlantList;

const FilterPills = ({filters, onFiltersUpdated}: {filters: PlantManagementFilterInterface, onFiltersUpdated: React.Dispatch<React.SetStateAction<PlantManagementFilterInterface>>}) => {
    const unitAssociationMap = {
        some: '1 ≥ Unit Associations',
        none: 'No Unit Associations'
    };
    return (
        <div className="flex flex-row gap-6 flex-wrap">
            {filters.unitAssociations !== 'any'? (
            <Pill dismissible={true}
                    onDismiss={() => onFiltersUpdated({...filters, unitAssociations: 'any'})}
                    type="default">
                {unitAssociationMap[filters.unitAssociations]}
            </Pill>
            ) : ''}
        </div>
    );
};