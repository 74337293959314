import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import ToolTip from "../../../components/ToolTip/ToolTip";
import useWindowSize from "../../../customHooks/useWindowSize";
import useScroll from "../../../customHooks/useScroll";
import Navbar from "../../../components/Navbar/Navbar";
import Sidebar from "../../../components/Sidebar/Sidebar";
import useHeaderHeight from "../../../customHooks/useHeaderHeight";
import { useRef } from "react";
import { useBaseOrgIdHeader } from '../../../customHooks/useBaseOrgIdHeader';
import NotificationPane from "../../../components/NotificationPane/NotificationPane";
import { AddToHomeScreen } from "../../../components/AddToHomeScreen/AddToHomeScreen";
import { useStore } from "../../../useStore";
import ConfirmNavigationModal from "../../../components/Modals/ConfirmNavigationModal";
import {
  unstable_useBlocker as useBlocker
} from "react-router-dom";

function Layout() {
  const { isDesktop } = useWindowSize();
  const { onScroll100Active } = useScroll();
  const AppHeaderHeight = useRef<HTMLDivElement>(null);
  const { headerHeight, headerHeightPadding } = useHeaderHeight(AppHeaderHeight);
  const [ isConfirmShown, setIsConfirmShown ] = useState<boolean>(false);

  // we need to include the BaseOrgId in the header of all api calls
  // this will tell us when we have the base org id for api calls (and it attaches it to all calls) and we can continue loading the app
  const hasBaseOrgIdForApiCalls = useBaseOrgIdHeader();
  const {mobileNavToggle, navigationIsBlocked, showConfirmCloseModal, closeModalIsBlocked} = useStore();
  const location = useLocation();
  let blocker = useBlocker(navigationIsBlocked);

  useEffect(() => {
    if (!window.history.state) {
      window.history.replaceState({idx: 0}, '', '/');
    }
  }, [location]);

  useEffect(() => {
    if (blocker.state === "blocked" && !navigationIsBlocked) {
      blocker.reset();
    }
    setIsConfirmShown((blocker.state === "blocked" && navigationIsBlocked) || (closeModalIsBlocked && showConfirmCloseModal));
  }, [blocker, navigationIsBlocked, closeModalIsBlocked, showConfirmCloseModal]);
  
  return (
    <div className="App">
      <ConfirmNavigationModal blocker={blocker} isShown={isConfirmShown} />
      <div
        className={onScroll100Active ? "App-header active sm:block sm:h-auto lg:flex" : "App-header sm:block sm:h-auto lg:flex"}
        ref={AppHeaderHeight}
      >
        {headerHeight !== null && <Navbar headerHeight={headerHeight} />}

      </div>
      {headerHeightPadding !== null && (

        <div className="App-body min-h-screen" style={mobileNavToggle ? {position: "fixed", paddingTop: headerHeightPadding } : {paddingTop: headerHeightPadding }}>
          {
            isDesktop && <Sidebar />
          }
          {/* CONTINUE LOADING THE APP WHEN WE HAVE A BASEORGID */}
          {hasBaseOrgIdForApiCalls && <Outlet />}
          <ToolTip headerHeight={headerHeightPadding} />
          <NotificationPane />
        </div>
      )}
      {/* <Footer /> */}
      <AddToHomeScreen />
    </div>
  );
}

export default Layout;
