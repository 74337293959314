import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../../../../components/Button/Button";
import { downloadFileByUrl } from "../../../../customHooks/FileHttpServices";
import useWindowSize from "../../../../customHooks/useWindowSize";
import classes from "./PlantManagement.module.css";
import { apiGenerateAccessReportUrl } from "../../../../endpoints/endpoints";
import Alert from "../../../../components/Alert/Alert";

function PlantManagementHeader() {
	const navigate = useNavigate();
	const { isDesktop, isMobile } = useWindowSize();

	const handleGenerateReport = async () => {
		downloadFileByUrl(apiGenerateAccessReportUrl);
	};
	
	const { plantId } = useParams();

	return (
		<div className={`flex flex-col gap-6`}>
			{plantId ? (
				<Link to={`./`} className={`${classes.listLink}`}>&lt;&nbsp;&nbsp; Back to Mitsubishi Power Plants</Link>
			) : (
				<div>
					<div className={`flex justify-between gap-5 ${isMobile? 'flex-col' : 'flex-row'}`}>
						<div className={"font-bold text-2xl"}>Mitsubishi Power Plants</div>
						<div className={`${classes.header_buttons} ${isMobile ? classes.flex_column : classes.flex_row}`}>
							<Button
								className={`whiteBtn manage-button`}
								// disabled={!(pagePermissions.canEdit || pagePermissions.canAdmin)}
								text="Generate Plant Access Report"
								onClick={handleGenerateReport}
							/>
							<Button
								className={`darkBlue add-button`}
								// disabled={!(pagePermissions.canAdd || pagePermissions.canAdmin)}
								text="Add Plant"
								onClick={() => navigate("./add")}
								style={{ order: !isDesktop ? "-1" : "unset" }}
							/>
						</div>
					</div>
					<div className={"gap-6 text-[font-size:var(--fs14px)]"}>See below for high-level information about Plants & Customers:</div>
				</div>
			)}
			<Alert onClose={() => { }} dismissible={false} type="warning">Please note: any modifications made to the plant list below will also apply to their instances in Users' Group Portal.</Alert>
		</div>
	);
}

export default PlantManagementHeader;
