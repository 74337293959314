import dayjs from "dayjs";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Link, generatePath } from "react-router-dom";
import Accordion from "../../components/Accordian/Accordion";
import useWindowSize from "../../customHooks/useWindowSize";
import { QuoteRequestSurvey } from "../../utils/interface";
import classes from "../QuoteRequests/QuoteRequests.module.css";

const CustomerSurveyItem: FunctionComponent<{
  data: QuoteRequestSurvey;
  index: number;
  unread: boolean;
}> = ({ data, index, unread }) => {

  const { isDesktop, isTablet, isMobile } = useWindowSize();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const tempDate = dayjs(data.dateTimeSubmitted);
    
  useEffect(() => {
    const closeAccordion = () => setIsOpen(false);
    window.addEventListener("resize", closeAccordion);
    return () => window.removeEventListener("resize", closeAccordion);
  }, []);  

  const fields = (
    <>
      <div title="Survey ID">
          {data.quoteRequestSurveyId}
      </div>
      <div title="First Name">
          {data.fName}
      </div>
      <div title="Last Name">
          {data.lName}
      </div>
      <div title="Date Completed">
          {tempDate.format('MM/DD/YYYY')}
      </div>
      <div title="Time Completed">
          {tempDate.format('h:mm:ss A')}
      </div>
      <div title="Details">
          {data?.quoteRequestSurveyId&& <Link className="text-dark-blue font-bold no-underline" to={generatePath('/parts&service/customer-survey/v/:param', { param: data.quoteRequestSurveyId.toString() })}>View</Link>}
      </div>
    </>
  );

  const Mobilefields = (
    <>
      <div title="Survey ID">
        <div>{data.quoteRequestSurveyId}</div>
      </div>
      <div title="First Name">
        <div>{data.fName}</div>
      </div>
      <div title="Last Name">
        <div>{data.lName}</div>
      </div>
      <div title="Date Completed">
        <div>{tempDate.format('MM/DD/YYYY')}</div>
      </div>
      <div title="Time Completed">
        <div>{tempDate.format('h:mm:ss A')}</div>
      </div>
      <div title="Details">
        <div>{data?.quoteRequestSurveyId&& <Link className="text-dark-blue font-bold no-underline text-xs" to={generatePath('/parts&service/customer-survey/v/:param', { param: data.quoteRequestSurveyId.toString() })}>View</Link>}</div>
      </div>
    </>
  );

  return isDesktop ? (
    <div style={{background: `${unread ? 'rgba(0, 116, 158, 0.05)' : 'inherit'}` }}>{fields}</div>
  ) : (
    <Accordion
      isDeleted={false}
      titleContent={`Survey ${data.quoteRequestSurveyId}: ${tempDate.format('MM/DD/YYYY')}`}
      isOpen={isOpen}
      headerStyle={{
        paddingLeft: 24,
        fontSize: 14,
        fontWeight: unread ? 700 : 500,
        marginBottom: "8px",
        background: unread ? "rgba(0, 116, 158, 0.05)" : "#f2f2f2",
      }}
      contentStyle={{}}
      toggleOpen={() => setIsOpen(!isOpen)}
    >
      <div className={`${classes.accordion} mb-2 ${isTablet || isMobile ? classes.isTablet : ""}`}>{Mobilefields}</div>
    </Accordion>
  );
};

export default CustomerSurveyItem;
