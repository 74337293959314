export const QuoteRequestInitialValue = {
  quoteRequestId: 0,
  globalItemId: 0,
  baseOrgId: 0,
  itemTypeId: 0,
  description: "",
  quotePriorityId: 0,
  plantSiteId: 0,
  quoteNeededDate: "",
  partsPriorityId: 0,
  partsNeededDate: "",
  partsRequestReasoningId: 0,
  serviceRequestReasoningId: 0,
  isPlannedOutage: "",
  outageSeasonId: 0,
  outageYear: 0,
  equipmentId: 0,
  equipmentName: "",
  customerContactId: 0,
  dateRequestReceivedFromCustomer: "",
  additionalComments: "",
  internalOutageId: "",
  submittedOn: "",
  quoteRequestParts: [
    {
      partNumber: "",
      description: "",
      quantity: 0,
    },
  ],
  quoteRequestEvents: [],
  quoteRequestAttachments: [],
  quoteRequestType: 0,
  quoteTypeId: 0,
  quotePriority: "",
  requestTypeName: "",
  createdBy: "",
  customerContact: "",
};

export const testing = {
  quoteRequestId: 14626,
  globalItemId: 26745,
  baseOrgId: 0,
  itemTypeId: 16,
  description: "DBC RFQ 6000544038 Misc Parts",
  quotePriorityId: 2,
  plantSiteId: 730,
  quoteNeededDate: "2022-07-29T04:00:00",
  partsPriorityId: 2,
  partsNeededDate: "2022-07-29T04:00:00",
  partsRequestReasoningId: null,
  serviceRequestReasoningId: null,
  isPlannedOutage: false,
  outageSeasonId: null,
  outageYear: 0,
  equipmentId: 256,
  equipmentName: "GT1A",
  customerContactId: null,
  dateRequestReceivedFromCustomer: null,
  additionalComments: "A new Request for Parts Quotation has been submitted.",
  internalOutageId: null,
  submittedOn: "2022-12-15T21:40:44.4833333",
  quoteRequestParts: null,
  quoteRequestEvents: [
    {
      quoteRequestEventId: 47190,
      eventTypeId: 2,
      dateValue: "2022-07-23T19:36:05",
      auxData: "Migrated from legacy system",
      modified: "2022-07-23T19:36:05",
      modifiedBy: 1,
      quoteRequestAttachment: null
    },
    {
      quoteRequestEventId: 47238,
      eventTypeId: 5,
      dateValue: "2022-07-29T15:50:28",
      auxData: "Migrated from legacy system",
      modified: "2022-07-29T15:50:28",
      modifiedBy: 1,
      quoteRequestAttachment: {
        quoteRequestAttachmentId: 16945,
        name: "DBC - RFQ 6000544038 Pins.Bolt.Nut.Reamer 20023699 QTO-22-1517 ERF-22-01595-OFG 072822.pdf",
        quoteRequestAttachmentTypeId: 3,
        description: null,
        fileType: 1,
        fileLocation: "016/26745/30948",
        created: "2022-07-29T15:50:28",
        createdBy: "CP System User 1",
        createdById: 1,
        size: 500781,
        file: null,
        fileTypeName: "Adobe Portable Document Format (PDF)",
        quoteName: null,
        quoteRequestAttachmentTypeName: "Quote Document",
        displaySize: "489.04 KB",
        fileTypeObj: {
          id: 1,
          name: "Adobe Portable Document Format (PDF)",
          description: "Adobe Acrobat PDF",
          extension: "pdf",
          maxSizeMb: 100,
          contentType: "application/pdf"
        },
        fileId: 30948,
        sapQuoteNumber: null
      }
    }
  ],
  quoteRequestAttachments: [
    {
      quoteRequestAttachmentId: 16945,
      name: "DBC - RFQ 6000544038 Pins.Bolt.Nut.Reamer 20023699 QTO-22-1517 ERF-22-01595-OFG 072822.pdf",
      quoteRequestAttachmentTypeId: 3,
      description: null,
      fileType: 1,
      fileLocation: "016/26745/30948",
      created: "2022-07-29T15:50:28",
      createdBy: "CP System User 1",
      createdById: 1,
      size: 500781,
      file: null,
      fileTypeName: "Adobe Portable Document Format (PDF)",
      quoteName: null,
      quoteRequestAttachmentTypeName: "Quote Document",
      displaySize: "489.04 KB",
      fileTypeObj: {
        id: 1,
        name: "Adobe Portable Document Format (PDF)",
        description: "Adobe Acrobat PDF",
        extension: "pdf",
        maxSizeMb: 100,
        contentType: "application/pdf"
      },
      fileId: 30948,
      sapQuoteNumber: null
    }
  ],
  quoteRequestType: 0,
  quoteTypeId: 3,
  quotePriority: "High",
  requestTypeName: "Parts Quote",
  createdBy: "CP System User 1",
  customerContact: ""
}