import { Form, Formik, FormikHelpers } from "formik";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import ErrorCard from "../../../components/ErrorCard/ErrorCard";
import DragDropFieldFormik from "../../../components/UI/Form/DragDropField/DragDropFieldFormik";
import DropDownSelect from "../../../components/UI/Form/DropDownSelect/DropDownSelect";
import MultiSelectDropDown from "../../../components/UI/Form/DropDownSelect/MultiSelectDropDown";
import { DropDownOptionItem, PlainDropDown } from "../../../components/UI/Form/DropDownSelect/PlainDropDown";
import TextInputField from "../../../components/UI/Form/TextInputField/TextInputField";

import { useStore } from "../../../useStore";
import ImageImports from "../../../utils/ImageImports";
import { PlantEquipmentsDATA, Report } from "../../../utils/interface";
import {
  useGetFile_TBReport,
  usePlantSiteUnitEquipment,
  useUnitsOfEquipmentIds,
} from "../../Reports/ReportsHttpServices";
import { AddDocumentRequiredProps } from "../TechnicalDocumentationContainer";
import classes from "./TechnicalAddDocument.module.css";
import DragDropFieldInfo from "../../../components/UI/Form/DragDropField/DragDropFieldInfo";

const { trash } = ImageImports;

  const IStatus = [
    {
      id: "Not Performed",
      name: "Not Performed",
    },
    {
      id: "Partially Performed",
      name: "Partially Performed",
    },
    {
      id: "Completed",
      name: "Completed",
    },
  ];

const TechnicalAddDocument: FunctionComponent<AddDocumentRequiredProps> = ({
  documentGroups,
  onSave,
  siteUnits,
  documentModule,
  isEdit,
  isLoading,
}) => {
  const { showError, report, plantConfig } = useStore();
  const [isEquipments, setIsEquipments] = useState<boolean>(false);
  const [singleReport, setSingleReport] = useState<Report>();
  const [siteUnit, setSiteUnit] = useState<number[]>([]);
  const [associatedEquipment, setAssociatedEquipment] = useState<{ equipmentId: number }[] | []>([]);
  const [attachment, setAttachment] = useState<File | Blob>();
  const [getUnit, setUnit] = useState<number>(0);
  const [equipment, setEquipment] = useState<number[]>([]);
  const [productTypes, setProductTypes] = useState<{ id: number; name: string }[]>([]);
  const [selectionText, setSelectionText] = useState<string>("");
  const [formReset, setFormReset] = useState<boolean>(false);

  const {groupId: urlGroupId} = useParams();
  const groupId: number = useMemo(() => {
    return Number(urlGroupId);
  }, [urlGroupId]);

  //---------------------------------------//

  useQuery(
    ["getSingleReport", report.globalId],
    () => documentModule?.documentApi.get(report.globalId),
    {
      onSuccess: (data: Report) => {
        setSingleReport && setSingleReport(data);
      },
      enabled: report.globalId > 0,
    }
  );

  const { isFetching: fileLoading } = useGetFile_TBReport(report.globalId, {
    enabled: report.globalId > 0 && isEdit,
    onSuccess: (data: Blob) => {
      setAttachment(data);
    },
    onError: (error: any) => {},
  });

  //---------------------------------------//

  useEffect(() => {
    if (isEdit && siteUnits && siteUnits.length && (getUnit === 0 || !siteUnit.length)) {
      setUnit(siteUnits[0].unitId);
      setSiteUnit(siteUnits.filter((x) => x.unitId).map((y) => y.unitId));
    }
  }, [siteUnits]);

  useEffect(() => {
    if (equipment && selectionText === "" && isEdit) getEquipmentLabel(equipment);
  }, [equipment]);

  //-------------------------------------//

  const { data: siteEquipment } = usePlantSiteUnitEquipment(siteUnit[0] || 0, {
    enabled: siteUnit.length > 0,
    onSuccess: () => {
      setIsEquipments(true);
    },
    onError: () => {
      setIsEquipments(false);
    },
    initialData: [],
  });

  const handleOnSubmit = (values: any, formikHelpers: FormikHelpers<any>) => {
    const formData = new FormData();
    for (let value in values) {
      if (value === "AssociatedEquipment") {
        const aE = values[value];
        aE.forEach((e: { equipmentId: number }, i: number) => {
          formData.append(`AssociatedEquipment[${i}].equipmentId`, `${e.equipmentId}`);
        });
      } else {
        formData.append(value, values[value]);
      }
    }

    if (!isEdit) {
      onSave(formData);
    } else {
      onSave([report.globalId, formData]);
    }
  };

  const getEquipmentLabel = (values: number[]) => {
    if (!!(values.length > 1)) {
      const eqCountByType = siteEquipment?.filter((eq) => eq.productType === values.filter((v) => v < 0)[0] * -1).length || 0;
      const valuesIncludeEqFromOutsideGroup = values.length !== eqCountByType + 1;
      if (valuesIncludeEqFromOutsideGroup) setSelectionText("Multiple equipment options selected");
      else if (!valuesIncludeEqFromOutsideGroup) setSelectionText(productTypes.filter((type) => values.includes(type.id))[0].name);
    }
    // if values includes 'All Equipment'
    if (values.includes(0)) setSelectionText(equipmentOptions[0]?.name);
    // if only 1 option selected
    if (values.length === 1) setSelectionText(equipmentOptions.filter((el) => el.id === values[0])[0]?.name);
  };

  // get all unique product type ids and names
  useEffect(() => {
    let tmpProductTypes: { [index: number]: string } = {};
    if (siteEquipment) {
      tmpProductTypes = siteEquipment?.reduce((carry: { [index: number]: string }, current: PlantEquipmentsDATA) => {
        carry[current.productType] = current.productTypeName;
        return carry;
      }, {});
    }
    const ids = Object.keys(tmpProductTypes);
    const productTypes: { id: number; name: string }[] = [];
    for (let i = 0; i < ids.length; i++) {
      let key = Number.parseInt(ids[i]);
      productTypes.push({ id: key * -1, name: `All ${tmpProductTypes[key]}` });
    }
    setProductTypes(productTypes);
  }, [siteEquipment]);

  function setAssociatedEquipmentObject(equipmentIDArray: number[]) {
    return equipmentIDArray.filter((d) => d > 0).map((d) => ({ equipmentId: d }));
  }

  useEffect(() => {
    if (isEdit) {
      const selectedEquipments = report.associatedEquipment?.map((e) => e.equipmentId);
      if (selectedEquipments) {
        setEquipment([...selectedEquipments]);
        const eq = setAssociatedEquipmentObject(selectedEquipments);
        setAssociatedEquipment([...associatedEquipment, ...eq]);
        setIsEquipments(true);
      } else {
        setIsEquipments(false);
      }
    }
  }, [report]);

  const equipmentOptions: { id: number; name: string }[] = [{ id: 0, name: "All Equipment" }, ...productTypes];

  if (siteEquipment) {
    for (let i = 0; i < siteEquipment.length; i++) {
      equipmentOptions.push({
        id: siteEquipment[i].plantEquipmentId,
        name: siteEquipment[i].equipmentName,
      });
    }
  }

  useUnitsOfEquipmentIds(equipment, {
    onSuccess: (unitArray) => {
      let temp = siteUnits.find((d) => d.unitId === unitArray[0])?.unitId;
      temp && setSiteUnit([temp]);
    },
    enabled: isEdit && !!report && equipment.length > 0,
  });

  useEffect(() => {
    // some values get set after formik reinitializes
    if (formReset) {
      setSiteUnit([]);
      setEquipment([]);
      setSelectionText("");
      setFormReset(false);
      setAttachment(undefined);
    }
  }, [formReset]);

  interface TBFormField {
    displayName: string;
    itemTypeId: number | null;
    BaseOrgId: number;
    globalItemId?: number | null;
    scope: string;
    file: string;
    summaryGuideline: string;
    implementationStatus: string;
    AssociatedEquipment: { equipmentId: number }[] | null;
  }
  let initialValues: TBFormField;
  let validationSchema;
  let validationObject = {
    //displayName: Yup.string().required("This field is required"),
    itemTypeId: Yup.number().required("This field is required"),
    scope: Yup.string().required("This field is required"),
    summaryGuideline: Yup.string().required("This field is required"),
    implementationStatus: Yup.string().required("This field is required"),
    AssociatedEquipment: Yup.array().of(Yup.object().shape({
      equipmentId: Yup.number().required("This field is required")
    })).min(1)
  };

  validationSchema = Yup.object({
    ...validationObject,
  });
  if (!isEdit) {
    initialValues = {
      displayName: "",
      BaseOrgId: plantConfig.data.baseOrgId,
      itemTypeId: groupId,
      scope: "",
      file: "",
      summaryGuideline: "",
      implementationStatus: "",
      AssociatedEquipment: associatedEquipment ? associatedEquipment : null,
    };
  } else {
    initialValues = {
      displayName: report ? (report.displayFileName || report.displayName || '') : "",
      BaseOrgId: plantConfig.data.baseOrgId,
      itemTypeId: singleReport ? singleReport?.itemTypeId : null,
      globalItemId: report ? report?.globalId : null,
      scope: report ? report?.scope : "",
      file: "",
      summaryGuideline: report ? report?.summaryGuideline : "",
      implementationStatus: report ? report?.implementationStatus : "",
      AssociatedEquipment: associatedEquipment ? associatedEquipment : null,
    };
  }

  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
      {({ values, setFieldValue, isSubmitting, dirty, isValid, errors }) => {
        const handleUnitSelection = (selectedOption: DropDownOptionItem) => {
          const selectedUnit = siteUnits.filter((u) => u.unitId === selectedOption.id).map((u) => u.unitId);
          setSiteUnit(selectedUnit);
          setFieldValue("AssociatedUnit", siteUnits.filter((x) => x.unitId === selectedUnit[0])[0]?.name);
        };

        const getEquipmentLabel = (values: number[]) => {
          if (!!(values.length > 1)) {
            const eqCountByType = siteEquipment?.filter(eq => eq.productType === (values.filter(v => v < 0)[0] * -1)).length || 0;
            const valuesIncludeEqFromOutsideGroup = values.length !== eqCountByType + 1;
            if (valuesIncludeEqFromOutsideGroup)
              setSelectionText('Multiple equipment options selected');
            else if (!valuesIncludeEqFromOutsideGroup)
              setSelectionText(productTypes.filter(type => values.includes(type.id))[0].name);
          }
          // if values includes 'All Equipment'
          if (values.includes(0)) setSelectionText(equipmentOptions[0].name);
          // if only 1 option selected
          if (values.length === 1) setSelectionText(equipmentOptions.filter(el => el.id === values[0])[0]?.name)
        }

        const handleEquipmentSelection = (values: number[], toggled: number) => {
          let tmpValues = [...values];
          let tmpSiteEquipment = (siteEquipment || []);

          /**
           * Remove all grouped options from tmpValues
           * if toggled = 0 and orig values includes 0, select all
           * if toggled = 0 and orig values does not include 0, un select all
           * 
           */

          if (toggled === 0) {
            if (values.includes(0)) {
              tmpValues = tmpSiteEquipment.map((d) => d.plantEquipmentId);
            } else {
              tmpValues = [];
            }
          }

          if (toggled < 0) {
            let productTypeId = toggled * -1;
            // a group was selected, check everything in that group
            if (values.includes(toggled)) {
              for (let i = 0; i < tmpSiteEquipment.length; i++) {
                if (tmpSiteEquipment[i].productType === productTypeId && !tmpValues.includes(tmpSiteEquipment[i].productType)) {
                  tmpValues.push(tmpSiteEquipment[i].plantEquipmentId);
                }
              }
            } else {
              // a group was deselected, un check everything in that group
              let productEquipment = tmpSiteEquipment.filter(eq => eq.productType === productTypeId).map(eq => eq.plantEquipmentId);
              tmpValues = tmpValues.filter(v => !productEquipment.includes(v));
            }
          }

          for (let i = 0; i < productTypes.length; i++) {
            let allOptionsOfTypeSelected = true;
            let productTypeId2 = +productTypes[i].id * -1;
            for (let j = 0; j < tmpSiteEquipment.length; j++) {
              if (tmpSiteEquipment[j].productType === productTypeId2) {
                allOptionsOfTypeSelected = allOptionsOfTypeSelected && tmpValues.includes(tmpSiteEquipment[j].plantEquipmentId);
              }
            }
            if (allOptionsOfTypeSelected) {
              tmpValues.push(productTypes[i].id);
            } else {
              tmpValues = tmpValues.filter(val => (val * -1) !== productTypeId2)
            }
          }

          // if everything is selected, check select all
          if (tmpValues.length > 0 && tmpValues.filter((d) => d > 0).length === tmpSiteEquipment.length) {
            tmpValues.push(0);
            for (let i = 0; i < productTypes.length; i++) {
              tmpValues.push(productTypes[i].id);
            }
          } else tmpValues = tmpValues.filter((val) => val !== 0);

          tmpValues = tmpValues.filter((v, i, a) => a.indexOf(v) === i);

          setEquipment(tmpValues);
          setFieldValue('AssociatedEquipment', tmpValues.filter(d => d > 0).map(d => ({equipmentId: d})));

          getEquipmentLabel(tmpValues);
        };


        return (
          <>
            {showError.isError && <ErrorCard ErrorMessage={showError.title} ErrorType={"danger"} />}

            <Form>
              <div>
                <div>
                  <TextInputField
                    name="displayName"
                    disabled={true}
                    details={"Document name will auto-populate to match the attachment’s filename."}
                    labelName="Document Name*"
                    placeholder="Document Name"
                    className=""
                  />
                </div>
                <div>
                  <DropDownSelect
                    name="itemTypeId"
                    options={documentGroups}
                    labelName="Document Group*"
                    placeholder={isEdit ? "" : "Select document group"}
                    defaultValue={isEdit ? singleReport?.itemTypeId : groupId}
                  />
                </div>
                <div>
                  <TextInputField
                    name="scope"
                    labelName="Scope* "
                    className="modalInput"
                    placeholder="Describe scope"
                    tooltip={{
                      text: "Add a summary explaining the content of the document here",
                    }}
                  />
                </div>

                <div>
                  <TextInputField
                    name="summaryGuideline"
                    labelName="Summary*"
                    className="modalInput"
                    placeholder="Add a summary"
                    tooltip={{
                      text: "This is a Summary of the Recommendation provided in the Technical Bulletin",
                    }}
                  />
                </div>
                <div>
                  <DropDownSelect
                    name="implementationStatus"
                    options={IStatus}
                    placeholder={isEdit ? "" : "Select implementation status"}
                    defaultValue={isEdit && report ? report.implementationStatus : ""}
                    labelName="Implementation Status*"
                  />
                </div>
                <div>
                  <div className={`${classes.form_field_row} ${classes.add_document_field}`}>
                    <span className={classes.input_label}>Associated Unit*</span>
                    <div className={classes.form_group}>
                      <PlainDropDown
                        value={siteUnit[0]}
                        className={`${classes.select_container} ${!!siteUnit[0] ? classes.selected : classes.placeholder}`}
                        selectClass={`${classes.flex_row} ${classes.select}`}
                        optionsClass={`${classes.flex_column} ${classes.select_options}`}
                        options={[
                          { id: false, name: "Select unit association" },
                          ...siteUnits.map((unit) => ({ id: unit.unitId, name: unit.name })),
                        ]}
                        onSelection={handleUnitSelection}
                      />
                    </div>
                  </div>
                  {isEquipments && (
                    <div className={`${classes.form_field_row} ${classes.add_document_field}`}>
                      <span className={classes.input_label}>Associated Equipment*</span>
                      <div className={classes.form_group}>
                        <MultiSelectDropDown
                          value={equipment}
                          className={`${classes.select_container} ${equipment.length > 0 ? classes.selected : classes.placeholder}`}
                          selectClass={`${classes.flex_row} ${classes.select}`}
                          optionsClass={`${classes.flex_column} ${classes.select_options}`}
                          options={equipmentOptions}
                          noSelectionText="Select equipment association(s)"
                          hasSelectionText={selectionText}
                          onSelection={handleEquipmentSelection}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <>
                    <div className={`${classes.form_field_row} ${classes.add_document_field}`}>
                      <span className={classes.input_label}>Attachment*</span>
                      {attachment && (
                        <div className={classes.form_group}>
                          <input type="text" name="displayName" disabled className={`${classes.input} pr-8`} style={{}} value={values.displayName} />
                          <img
                            className={classes.deleteFile}
                            onClick={() => {
                              setFieldValue("displayName", "");
                              setFieldValue("file", "");
                              setAttachment(undefined);
                            }}
                            src={trash}
                            alt="Remove Attachment"
                          />
                        </div>
                      )}
                    </div>
                    <DragDropFieldFormik name="file" onChange={setAttachment} fileLoading={fileLoading} displayName={"displayName"} attachment={attachment} isAllowOnlyPdfImage={true} />
                    <DragDropFieldInfo fileTypes=".PDF, .JPG, .PNG" containerClassName="mb-5" />
                  </>
                </div>
              </div>
              <div className="flex flex-row justify-between items-center">
                <div className="flex-grow-1 flex">
                  {isEdit ? (
                    <>
                      <button
                        type="submit"
                        className="primaryBtn darkBlue"
                        disabled={!dirty || (dirty && !isValid) || isSubmitting || values === initialValues || !attachment || isSubmitting }
                      >
                        Save Document
                      </button>
                    </>
                  ) : (
                    <>
                      <button type="submit" className="primaryBtn darkBlue" disabled={!dirty || (dirty && !isValid) || isSubmitting || !attachment}>
                        Add Document
                      </button>
                    </>
                  )}
                </div>
                <span>*Required fields</span>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default TechnicalAddDocument;
