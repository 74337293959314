import { FunctionComponent, useState } from "react";

import Button from "../../components/Button/Button";
import Loader from "../../components/Loader/Loader";
import { maxFileSizeValidator } from "../../components/UI/Form/DragDropField/DragDropFieldFormik";
import Editor from "../../components/UI/RichTextEditor/Editor";
import { useCreateMessageAttachment, useThreads } from "../../customHooks/useThreads";
import useWindowSize from "../../customHooks/useWindowSize";
import { Thread, ThreadMessage } from "../../utils/interface";
import classes from "./DiscussionBoard.module.css";


const maxCharacterCount = 2000;

const PostThreadMessage: FunctionComponent<{ thread?: Thread; fetchMessages: () => Promise<void>; messages: ThreadMessage[]; isLoadingMessages: boolean }> = ({ thread, fetchMessages, messages, isLoadingMessages }) => {
  const { postThreadMessage } = useThreads();
  const { isMobile } = useWindowSize();
  const [postContent, setPostContent] = useState("");
  const [attachment, setAttachment] = useState<File|null>(null);
  const [attachmentError, setAttachmentError] = useState<string|null>(null);
  const [clearEditor, setClearEditor] = useState<boolean>(false);
  const [maxCharacterError, setMaxCharacterError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string|null>(null);

  const { mutate: addAttachment, isLoading: loadingMsgAttachment} = useCreateMessageAttachment({
    onSettled: () => {
      fetchMessages();
      setAttachment(null);
      setClearEditor(true);
    },
  });

  const addFile = (attachment: File) => {
    const allowedFileTypes = ["application/pdf", "image/jpeg", "image/png"];

    if (attachment) {
      const fileTooLarge = maxFileSizeValidator(attachment, 1000000000); // 1GB
      if (!allowedFileTypes.includes(attachment.type)) {
        setAttachmentError("File type not allowed");
      } else if (fileTooLarge) {
        setAttachmentError("File Size is larger than 1GB");
      } else {
        setAttachmentError(null);
        setAttachment(attachment);
      }
    }
  };

  const postMessage = async () => {
    if (thread) {
      try {
        const message = await postThreadMessage({ threadId: thread.id, title: "", content: postContent, parentId: messages[0]?.id ?? 0 });
        if (attachment && message.id) {
          const messageAttachment = {
            messageId: message.id,
            originalFileName: attachment.name,
            description: attachment.name,
            attachment,
          };
          addAttachment(messageAttachment);     
        } else {
          fetchMessages();
        }
        setAttachment(null);
        setAttachmentError(null);
        setPostContent("");
        setMaxCharacterError(false);
        setErrorMsg(null);
        setClearEditor(true);
      } catch(e: any) {
        setErrorMsg(e.response.data);
      }
    }
  };

  const handleTextChange = (val: string) => {
    setClearEditor(false);
    //  setPostContent(val.replace(/<[^>]+>/g, ""));
    setMaxCharacterError(false);
    if (val.length > maxCharacterCount) {
      setMaxCharacterError(true);
    }
    setPostContent(val);
  };
  
  return (
    <>
      <div>
        <strong>Post to Discussion</strong>
      </div>
      <div>
        {!isLoadingMessages && !loadingMsgAttachment &&
          <Editor fullSize uploadFile={addFile} reset={clearEditor} allowUploads handleChange={handleTextChange} />
        }
      </div>
      <div style={{ fontSize: 12, color: "#808080" }}>Accepted file types: PDF, JPG, PNG; Max file size: 1GB</div>
      {attachment && <div className="">Attached File: {attachment.name}</div>}
      {attachmentError && <div className="text-error-800">Attachment Error: {attachmentError}</div>}
      {(maxCharacterError || errorMsg) && (
        <div className="text-error-800">
          {errorMsg 
          ? <>{errorMsg}</>
          : <>Message currently exceeds the size limit of {maxCharacterCount} characters (including formatting characters not shown on screen).</>
          }
        </div>
      )}
      {thread && (
        <div style={{ marginTop: 20 }} className={`${!isMobile ? classes.flex_row : ""}`}>
          <Button 
            className={`darkBlue`} 
            disabled={!postContent || maxCharacterError} 
            text={!loadingMsgAttachment ? "Post" : <Loader containerStyle={{marginTop:"0", marginBottom:"0"}} 
            iconStyle={{ width: 18, color: 'black' }} />} 
            onClick={postMessage} 
          />
          {!isMobile && <>&nbsp;&nbsp;&nbsp;</>}
          <Button 
            className={`whiteBtn`} 
            disabled={!postContent && !attachment} 
            text={"Clear"} 
            onClick={
              () => {
                setAttachment(null); 
                setAttachmentError(null);
                setMaxCharacterError(false);
                setErrorMsg(null);
                setClearEditor(!clearEditor);
              }
            } 
          />
        </div>
      )}
    </>
  );
};

export default PostThreadMessage;
