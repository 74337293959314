import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import DarkLinks from "../../../components/RelatedLinks/DarkLinks";
import { userGroupPortalURL } from "../../../endpoints/endpoints";
import ImageImports from "../../../utils/ImageImports";

const {remoteMonitoring, gearTime, userGroup2, logo} = ImageImports;


export const SplashPage: FunctionComponent = () => {
  return (
  <div className="">
    <div className="pt-6 pb-10 px-8 md:p-6 md:pt-32 lg:pt-8 lg:py-10 lg:px-[162px] flex flex-col gap-x-8 relative bg-primary-20 rounded-top">
      <div>
        <Link className="link_text" to="/home">
          <img src={logo} className="nopointer w-40 mb-2" alt="logo" />
        </Link>
      </div>
      <div>
          <h1 className="text-2xl lg:text-40 pt-4 font-bold">Welcome, Mitsubishi Power Customers</h1>
          <p className="text-sm lg:text-2xl mblx-2">
            Mitsubishi Power Americas has established this web portal for customers with long-term agreements.
            Its purpose is to centralize and streamline all communications between you and Mitsubishi Power,
            to most effectively manage and track transactional requests for parts/service and technical support,
            provide real-time O&M support from the TOMONI HUB and to provide an ongoing archive of such communications.
          </p>
      </div>
    </div>   
    <div className="py-6 px-8 md:py-10 lg:px-[162px] relative bg-white rounded-bottom" >
        {/* Bottom Section of the Splash Page */}
        <div className="flex flex-col justify-center">
          <div className="">
            <div className="mx-0">
                <div>
                  <h1 className="text-xl lg:text-2xl font-bold pt-4 md:pt-6">Sign into the Portal</h1>
                    <p className="text-sm lg:text-base mblx-2">You will be prompted to sign in once with your corporate account that you selected to access the Mitsubishi Power Web Portal.  If you require access, you can submit a request <Link to="/portal-access" className="no-underline" >here</Link>.</p>
                </div>
                  <DarkLinks data={relatedData} />
            </div>
          </div>
        </div>
    </div>
  </div>    
  );
};

const relatedData = {
  relatedTitle: "",
  relatedSections: [
    {
      IconUrl: remoteMonitoring,
      title: "Customer Portal",
      description: "Contains Plant Site data and contacts, TOMONI HUB reports, Parts/Service Request Tracking, Technical Information Request Tracking, Plant-Specific Shared Documents Library, etc.",
      buttonLink: "Go to Customer Portal",
      route: "/"
    }
  ]
};

export default SplashPage;
