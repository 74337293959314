import { Form, Formik, FormikHelpers } from "formik";
import React, { useContext, useState } from "react";
import { Store } from "../../Store";
import { CloseAllModal } from "../../utils/CloseAllModal";
import * as Yup from "yup";
import ImageImports from "../../utils/ImageImports";
import {
  QuoteRequestGetAll,
  quoteAttachmentFormInterface,
  QuoteRequestAttachment,
  QuoteFormInterfaceDTO,
} from "../../utils/interface";
import Loader from "../Loader/Loader";
import DragDropFieldFormik from "../UI/Form/DragDropField/DragDropFieldFormik";
import TextInputField from "../UI/Form/TextInputField/TextInputField";
import ModalGlobal from "../UI/Modal/ModalGlobal";
import classes from "./AttachPartDocumentModal.module.css";
import {
  useCreate_Attachment,
  useGet_AttachmentByid,
  useGet_FileBlob,
  useUpdate_QuoteAttachment,
  useUpdate_QuoteStatus,
} from "../../containers/PartsAndService/QuoteRequestHttpServices";
import { QuoteRequestEventID } from "../../utils/moduleIDs";
import { queryClient } from "../../react-query/useQueryClientGet";
import { queryKeys } from "../../react-query/queryKeys";
import { useOnError } from "../../customHooks/useOnError";
import DragDropFieldInfo from "../UI/Form/DragDropField/DragDropFieldInfo";

const AttachQuotePODocumentModal = ({
  quoteRequestList,
  isEdit,
  attachmentID,
  quoteRequestAttachmentTypeID,
  modaltitle,
  handleDeleteAttachment,
}: quoteProps) => {
  const { isModal, setIsModal } = useContext(Store);
  const [previewBase24, setPreviewBase24] = useState<string>("");
  const [attachment, setAttachment] = useState<File|Blob>();
  const setErrorData = useOnError();
  const [quoteRequestAttachment, setQuoteRequestAttachment] = useState<QuoteRequestAttachment>({
    quoteRequestAttachmentId: 0,
    name: "",
    quoteRequestAttachmentTypeId: 0,
    fileType: 0,
    fileLocation: "",
    created: "",
    createdBy: "",
    createdById: 0,
    size: 0,
    file: null,
    fileTypeName: "",
    quoteRequestAttachmentTypeName: "",
    displaySize: "",
    fileTypeObj: { id: 0, name: "", description: "", extension: "", maxSizeMb: 0, contentType: "" },
    fileId: 0,
    sapQuoteNumber: "",
  });

  const { trash } = ImageImports;

  const { isLoading: isLoadingFile } = useGet_AttachmentByid(attachmentID, {
    enabled: attachmentID > 0,
    onSuccess(data) {
      setQuoteRequestAttachment(data.data);
    },
    onError(err) {
      setErrorData(err);
    },
  });

  const { isFetching: isFetchingAttachment } = useGet_FileBlob(quoteRequestAttachment.fileId, {
    enabled: quoteRequestAttachment.fileId > 0,
    onSuccess(data: Blob) {
      setAttachment(data)
    },
    onError(err) {
      setErrorData(err);
    },
  });

  const { mutate: AddAttachment, isLoading: isAdding } = useCreate_Attachment();
  const { mutate: EditAttachment, isLoading: isEditing } = useUpdate_QuoteAttachment();
  const { mutate: updateQuoteStatus } = useUpdate_QuoteStatus();

  const handleOnSubmit = (values: quoteAttachmentFormInterface, formikHelpers: FormikHelpers<quoteAttachmentFormInterface>) => {
    if (isEdit) {
      EditAttachment(values, {
        onSuccess(res: any) {
          updateQuoteStatus(
            { quoteRequestId: values.quoteRequestId, quotestatusTypeId: QuoteRequestEventID.PurchaseOrderUploaded },
            {
              onSuccess: (quoteResponse: QuoteFormInterfaceDTO) => {
                queryClient.invalidateQueries([queryKeys.getQuoteRequest, quoteRequestList.globalItemId]);
                setIsModal &&
                  setIsModal({
                    ...CloseAllModal,
                    isEditedSuccess: { ...isModal.isEditedSuccess, isPODocument: true },
                  });
              },
              onError: (error: unknown) => {
                setErrorData(error);
              },
            }
          );
        },
        onError(error: unknown) {
          formikHelpers.setSubmitting(false);
        },
      });
    } else {
      AddAttachment(values, {
        onSuccess(res: any) {
          updateQuoteStatus(
            { quoteRequestId: values.quoteRequestId, quotestatusTypeId: QuoteRequestEventID.PurchaseOrderUploaded  },
            {
              onSuccess: (quoteResponse: QuoteFormInterfaceDTO) => {
                queryClient.invalidateQueries([queryKeys.getQuoteRequest, quoteRequestList.globalItemId]);
                setIsModal &&
                  setIsModal({
                    ...CloseAllModal,
                    isAddedSuccess: { ...isModal.isAddedSuccess, isPODocument: true },
                  });
              },
              onError: (error: unknown) => {
                setErrorData(error);
              },
            }
          );
        },
        onError(error: unknown) {
          formikHelpers.setSubmitting(false);
        },
      });
    }
  };
  let initailValues: quoteAttachmentFormInterface;
  let validationSchema;

  initailValues = {
    quoteRequestId: quoteRequestList.quoteRequestId,
    quoteRequestAttachmentTypeId: quoteRequestAttachmentTypeID,
    name: "",
    file: "",
  };

  validationSchema = Yup.object({
    quoteRequestId: Yup.number().positive().min(0).required(),
    quoteRequestAttachmentTypeId: Yup.number().positive().min(1).required(),
    // name: Yup.string().required(),
    file: Yup.string().required(),
  });
  if (isEdit) {
    initailValues = {
      quoteRequestAttachmentId: quoteRequestAttachment.quoteRequestAttachmentId,
      quoteRequestId: quoteRequestList.quoteRequestId,
      quoteRequestAttachmentTypeId: quoteRequestAttachment?.quoteRequestAttachmentTypeId,
      name: quoteRequestAttachment?.name,
      file: "",
    };

    validationSchema = Yup.object({
      quoteRequestId: Yup.number().positive().min(0).required(),
      quoteRequestAttachmentTypeId: Yup.number().positive().min(1).required(),
      name: Yup.string().required(),
    });
  }
  return (
    <ModalGlobal modaltitle={modaltitle} modalSize={"md"} isModalHeader={true}>
      <Formik enableReinitialize initialValues={initailValues} onSubmit={handleOnSubmit} validationSchema={validationSchema}>
        {(formikProps) => {
          
          return (
            <Form>
              {(isAdding || isEditing) ? (
                <Loader containerStyle={{marginTop: "50px"}} />
              ) : (
                <>
                  <div>
                    <TextInputField
                      name="name"
                      disabled
                      details="Document name will auto-populate to match the attachment’s filename."
                      labelName="Document Name* "
                      className=""
                      placeholder=""
                    />
                  </div>

                  <div className={`${classes.form_field_row} ${classes.add_document_field}`}>
                    <span className={classes.input_label}>Attachment*</span>
                    {(!!attachment || !!previewBase24) && (
                      <div className={classes.form_group}>
                        <input type="text" name="name" disabled className={`${classes.input} pr-8`} style={{}} value={formikProps.values.name} />
                        <img
                          className={classes.deleteFile}
                          onClick={() => {
                            formikProps.setFieldValue("name", "");
                            formikProps.setFieldValue("file", "");
                            setPreviewBase24("");
                            setAttachment(undefined);
                          }}
                          src={trash}
                          alt="Remove Attachment"
                        />
                      </div>
                    )}
                  </div>
                  {isLoadingFile ? (
                    <Loader containerStyle={{marginTop: "50px", marginBottom:"100px"}} />
                  ) : (
                    <DragDropFieldFormik
                      name="file"
                      onChange={setAttachment}
                      attachment={attachment}
                      displayName={"name"}
                      fileLoading={!!isFetchingAttachment}
                      previewBase24={previewBase24}
                    />
                  )}

                  <div className={`${classes.form_field_row}`}>
                    <DragDropFieldInfo fileTypes=".PDF, .DOC, .XLSX, .JPG, .PNG" />
                  </div>
                </>
              )}
              <div className="flex align-center">
                <div className="flex-grow-1 flex">
                  {isEdit ? (
                    <>
                      <button type="submit" className="primaryBtn darkBlue" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                        Save Changes
                      </button>
                      <button type="button" className="primaryBtn redDelete marginLeft16" onClick={() => handleDeleteAttachment!(attachmentID)}>
                        Delete
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        type="submit"
                        className="primaryBtn darkBlue"
                        disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}
                      >
                        Add Document
                      </button>
                      <button
                        type="button"
                        className="primaryBtn whiteBtn marginLeft16"
                        disabled={formikProps.isSubmitting}
                        onClick={() =>
                          setIsModal!({
                            ...CloseAllModal,
                          })
                        }
                      >
                        Cancel
                      </button>
                    </>
                  )}
                </div>
                <span>*Required fields</span>
              </div>
            </Form>
          );
        }}
      </Formik>
    </ModalGlobal>
  );
};

interface quoteProps {
  quoteRequestList: QuoteRequestGetAll;
  isEdit: boolean;
  attachmentID: number;
  quoteRequestAttachmentTypeID: number;
  handleDeleteAttachment?(id: number): void;
  modaltitle: string;
}
export default AttachQuotePODocumentModal;
