import { useContext } from "react";
import { ErrorMessage, Field, Form, Formik, useField } from "formik";
import classes from "./TextInputField.module.css";
import ImageImports from "../../../../utils/ImageImports";
import { Store } from "../../../../Store";
import React from "react";
import MiniTooltip from "../../../UI/MiniTooltip/MiniTooltip";

const TextInputField = (props: TextInputField) => {
  const [field,meta] = useField(props);
  const stateContext = useContext(Store);
  const { tooltip } = ImageImports;

  let styles ;
  if(props.className === "marginbottom0"){
    styles = {
      marginBottom:0
    }
  }
  return (
    <div className={`${classes.form_field_row}`} style={styles}>
      {
        props.labelName && <label htmlFor={props.name} className={classes.input_label}>
        {props.labelName}
        {props.tooltip?.text.length && (
          <div className={classes.cursor_pointer}>
            <MiniTooltip text={props.tooltip.text} space={props.tooltip.space} placement={props.tooltip.placement}>
              <img src={tooltip} className={classes.tooltip_icon} alt="tool tip" />
            </MiniTooltip>
          </div>
        )}
      </label>
      }
      <div className={`${classes.form_group}` }>
        <Field
          id={props.name}
          type={props.type}
          className={meta.touched && meta.error ? `${classes.input} ${classes.errorField} ${classes.error_text}` : `${classes.input}`}
          name={props.name}
          disabled={props.disabled}
          placeholder={props.placeholder}
          autoComplete="off"
        />
        {
          props.details && <small className={classes.small} style={{marginTop:"8px"}}>{props.details}</small>
        }
        
        {/* <ErrorMessage name={props.name}>
          {(msg) => <div className={classes.error_text}>{msg}</div>}
        </ErrorMessage> */}
      </div>
    </div>
  );
};

interface TextInputField {
  name: string;
  labelName: string;
  placeholder?: string;
  disabled?:boolean;
  className:string | "";
  details?:string;
  type: "text" | "date" | "number" |"email" | "tel" | "textarea";
  tooltip?:{   
    placement?: "bottom" | "top" | "right" | "left";
    isDisabled?: 0 | 1;
    space?:number;
    text:string;
  };
}

TextInputField.defaultProps = {
  disabled:false,
  type:"text"
};

export default TextInputField;
