import dayjs, { Dayjs } from "dayjs";
import { EquipmentAttachmentModelDTO, PlantContact, Report } from "../utils/interface";

const Moment = require('moment');

export const SortContactsArray = (sortType: string, Data: PlantContact[]) => {

  switch (sortType) {
    case "1":
      return Data.sort((a, b) =>
        a.firstName.toLowerCase() >
          b.firstName.toLowerCase()
          ? 1
          : -1
      );
    case "2":
      return Data.sort((a, b) =>
        a.firstName.toLowerCase() >
          b.firstName.toLowerCase()
          ? -1
          : 1
      );
    case "3":
      return Data.sort((a, b) =>
        a.lastName.toLowerCase() >
          b.lastName.toLowerCase()
          ? 1
          : -1
      );
    case "4":
      return Data.sort((a, b) =>
        a.lastName.toLowerCase() >
          b.lastName.toLowerCase()
          ? -1
          : 1
      );
    default:
      return Data;
  }
}

export const FilterDateComparisons = (dateCode: string) => {
  const today = dayjs();
  switch (dateCode) {
    case 'P1D':
      return today.subtract(1, 'day').startOf('day');
    case 'P7D':
      return today.subtract(7, 'day').startOf('day');
    case 'P30D':
      return today.subtract(30, 'day').startOf('day');
    case 'P60D':
      return today.subtract(60, 'day').startOf('day');
    case 'P90D':
      return today.subtract(90, 'day').startOf('day');
    default: 
      return today;
  }

}

// const array = [
//   { date: "2022-08-01T12:41:42.5050873" },
//   { date: "2022-08-01T11:04:45.5030645" },
//   { date: "2022-08-01T11:10:44.5951238" },
// ];
// const sortedArray = array.sort(
//   (a, b) =>
//   Moment(b.date).valueOf() - Moment(a.date).valueOf());
// console.log(sortedArray);

export const SortAttachmentArray = (sortType: string, Data: EquipmentAttachmentModelDTO[]) => {
  if (Data) {
    switch (sortType) {
      case "1":
        return Data.sort((a,b) =>  Moment(b.updated).valueOf() - Moment(a.updated).valueOf());   
      case "2":
        return Data.sort((a,b) =>  Moment(b.created).valueOf() - Moment(a.created).valueOf());  
      case "3":
        return Data.sort((a, b) => a.name.toLowerCase().slice(0, 1) > b.name.toLowerCase().slice(0, 1) ? 1 : -1);
      case "4":
        return Data.sort((a, b) => a.name.toLowerCase().slice(0, 1) > b.name.toLowerCase().slice(0, 1) ? -1 : 1);
      default:
        return Data;
    }
  }
  return Data;
}
export const SortReportArray = (sortType: string, Data: Report[]) => {
  if (Data) {
    switch (sortType) {
      case "1":
        return Data.sort((a,b) =>  Moment(b.created).valueOf() - Moment(a.created).valueOf());   
      case "2":
        return Data.sort((a,b) =>  Moment(a.created).valueOf() - Moment(b.created).valueOf());
      default:
        return Data;
    }
  }
}

/**
 * 
 * @param key 
 * @param direction 
 * @param manipulationFn 
 * @returns a sorting function to pass to [].sort
 * 
 * ***
 * const sortFn = getSortObjectsByKeyFn<Report>('globalId', 'asc');\
 * reports.sort(sortFn);
 * ***
 * 
 * ***
 * const sortFn = getSortObjectsByKeyFn<Report>('created', 'asc', (date) => moment(date).toDate());\
 * reports.sort(sortFn);
 * ***
 */


export const getSortObjectsByKeyFn = <T, K extends keyof T = keyof T> (key: K, direction: "asc" | "desc", manipulationFn: (d: T[K]) => any = d => d) => {
  return ({[key]: a}: T, {[key]: b}: T) => {
    let s = 0;
    a = manipulationFn(a);
    b = manipulationFn(b);
    if (a > b) s = 1;
    if (b > a) s = -1;
    if (direction === 'desc') s = s * -1;
    return s;
  };
};


// some usage examples
// const sortFn = getSortObjectsByKeyFn<Report>('globalId', 'asc');
// reports.sort(sortFn);

// const sortFn = getSortObjectsByKeyFn<Report>('created', 'asc', (date) => moment(date).toDate());
// reports.sort(sortFn);

// const sortFn = getSortObjectsByKeyFn<Report>('associatedEquipment', 'asc', (eq) => (eq || []).length);