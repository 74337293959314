import { CPApiResponse, KPI } from "../../../utils/interface";
import { KPIsApiUrl } from "../../../endpoints/endpoints";

import axios, { AxiosError, AxiosResponse } from "axios";
import { QueryFunctionContext, useQuery, UseQueryOptions } from "react-query";
import { handleAxiosResponse } from "../../../utils/handleAxiosResponse";
import { useContext } from "react";
import { Store } from "../../../Store";


declare type GetPlantKPIParameters = ['KPI', {baseOrgId: number}];
const getPlantKPIs = async ({queryKey}: QueryFunctionContext<GetPlantKPIParameters>): Promise<KPI> => {
	const [_key, {baseOrgId}] = queryKey;
	const response: AxiosResponse<CPApiResponse<KPI>> = await axios({
		url: `${KPIsApiUrl}/${baseOrgId}`,
		method: "GET",
	});
	return handleAxiosResponse(response);
};
declare type GetAllPlantKPIParameters = ['KPI'];
const getAllPlantsKPIs = async (): Promise<KPI> => {
	const response: AxiosResponse<CPApiResponse<KPI>> = await axios({
		url: `${KPIsApiUrl}/GetAll`,
		method: "GET",
	});
	return handleAxiosResponse(response);
};

export const useQuery_PlantKPIs = (options: Omit<UseQueryOptions<KPI, AxiosError<string>, KPI, GetPlantKPIParameters>, 'queryKey' | 'queryFn'> = {}) => {
	const { selectedPlant } = useContext(Store);
	return useQuery<KPI, AxiosError<string>, KPI, GetPlantKPIParameters>(['KPI', {baseOrgId: selectedPlant.baseOrgId}], getPlantKPIs, options);
};

export const useQuery_AllPlantsKPIs = (options: Omit<UseQueryOptions<KPI, AxiosError<string>, KPI, GetAllPlantKPIParameters>, 'queryKey' | 'queryFn'> = {}) => {
	return useQuery<KPI, AxiosError<string>, KPI, GetAllPlantKPIParameters>(['KPI'], getAllPlantsKPIs, options);
};

export const getKpiReport = (): string => {
	return `${KPIsApiUrl}/GetAllPlantsKPIData`;
};