import { ReactNode, useContext } from 'react';
import { Store } from '../../Store';
import { NotificationType } from '../../utils/interface';
import { AccordionData } from '../NotificationPane/AccordionData';
import "./Notification.css";

const Notification = ({ children, value, innerSpacingZero, leftAlignment, verticalAlignment, selectedType, disabled, shade, tableVariant }: NotificationProps) => {
  const { notificationPaneOpen, toggleNotificationPane, setNotificationContent } = useContext(Store);

  const showNotifications = () => {
    setNotificationContent && setNotificationContent(<AccordionData selectedType={selectedType} />);

    if (!disabled) {
      toggleNotificationPane && toggleNotificationPane(!notificationPaneOpen, false);
    };
  }

  return (
    <div style={{ opacity: shade ? '40%' : 'inherit', cursor: disabled ? 'default' : 'pointer' }} className={`${innerSpacingZero ? 'notification inner_spacing_zero' : 'notification'} `} onClick={showNotifications} >
      {value > 0 && 
        <>
          {tableVariant 
            ? <div className=" ml-4 text-white text-xs rounded-full bg-[#E31F26] flex items-center justify-center h-7 w-7">{value}</div>
            : <div style={{ top: verticalAlignment, left: leftAlignment }} className='notification__value'>{value}</div>}
        </>
      }
      {children}
    </div>
  )
}

interface NotificationProps {
  children: ReactNode;
  value: number;
  innerSpacingZero?: boolean;
  leftAlignment?: number;
  verticalAlignment?: number;
  selectedType?: NotificationType;
  disabled?: boolean;
  shade?: boolean;
  tableVariant?: boolean;
}
Notification.defaultProps = {
  innerSpacingZero: false,
  leftAlignment: 16,
  verticalAlignment: undefined,
  disabled: false,
  shade: false,
  tableVariant: false,
}

export default Notification