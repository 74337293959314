
import classes from '../TechnicalDocumentation.module.css';
import headerClasses from './OmManualHeader.module.css';
import ImageImports from '../../../utils/ImageImports';
import HeaderButtons from '../HeaderButtons';
import useWindowSize from '../../../customHooks/useWindowSize';
import { Store } from '../../../Store';
import { useContext } from 'react';
import { TakasagoTechBulletinWebsiteURL, tbwApplicationFileLink, tbwApplicationItemId } from '../../../endpoints/endpoints';
import { Link } from 'react-router-dom';
import { Permissions } from '../../../utils/interface';
import { downloadStaticFile } from "../../../customHooks/FileHttpServices";

const { linkout } = ImageImports;

function OmManualHeader({ groupPermissions, reportPermissions }: { groupPermissions: Permissions, reportPermissions: Permissions }) {
    const { selectedPlant } = useContext(Store);
    const { isMobile, isTablet } = useWindowSize();
    return (
        <div className={`${classes.flex_row} ${headerClasses.header}  w-[100%]`}>
            <div className={`${headerClasses.header_content} ${classes.flex_column} grow`}>
                <div className={headerClasses.header_title}>
                    {selectedPlant.name} Manuals
                </div>
                {
                    !isMobile && <>
                <div className={headerClasses.group_header_text}>
                    Available electronic versions of the O&amp;M Manual for your plant are linked from this page. Also linked is the Mitsubishi Power
                    Takasago Technical Bulletin Website, which contains supplements to the O&amp;M Manuals.
                </div>
                <div className={`${headerClasses.group_header_text} ${classes.flex_column}`}>
                    <div className="inline-flex">
                        <a href={TakasagoTechBulletinWebsiteURL} className={`${headerClasses.external_link} ${classes.flex_row}`} target="_blank" rel="noreferrer">
                            <div className={`${headerClasses.image_wrapper} ${classes.flex_row}`}>
                                <img src={linkout} alt="Go to Takasago Technical Bulletin Website (TBW)" />
                            </div>
                            Go to Takasago Technical Bulletin Website (TBW)
                        </a>
                    </div>
                    <p>The Gas Turbine and Gas Turbine Generator Technical Bulletins are on the Technical Bulletin Website administered by Gas Turbine
                        Service Engineering in Takasago, and requires a separate username and password. If you do not have those separate log-on
                        credentials, you can apply for them by completing the form linked <span className="no-underline text-ocean cursor-pointer" onClick={() => { downloadStaticFile(tbwApplicationItemId) }} >here</span> and sending it to your LTSA Program Manager or Sales Manager.
                    </p>
                </div>
                    </>
                }
            {(isMobile || isTablet) && <HeaderButtons addButtonText='Add O&amp;M Manual' groupPermissions={groupPermissions} reportPermissions={reportPermissions} />}
            </div>
            {!isMobile && !isTablet && <HeaderButtons addButtonText='Add O&amp;M Manual' groupPermissions={groupPermissions} reportPermissions={reportPermissions} />}
        </div>
    );
}

export default OmManualHeader;