import React, { useContext } from "react";
import { Store } from "../../Store";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import ErrorCard from "../../components/ErrorCard/ErrorCard";
import classes from "./PlantInformation.module.css";

const relatedData = {
  relatedTitle: "Related Plant Information",
  relatedSections: [
    {
      title: "Quote Requests",
      description: "This page is the central location for users to request parts and service quotations.",
      buttonLink: "View Quote Requests",
      route: "/parts&service/QuoteRequests"
    },
    {
      title: "Issue Reports",
      description: "This page contains the reports issued by the Mitsubishi Power TOMONI HUB.",
      buttonLink: "View Issue Reports",
      route: "/issue-reports"
    }
  ]
};

function PlantInformation() {
  const storeContext = useContext(Store);
  const { setActiveLocation, showError } = storeContext;
  const location = useLocation();

  const handleRefresh = () => {
    window.location.reload();
  };



  return (
    <div className="column flex-grow-1">
      <div className="content">
        <div className="path breadcrumb">
          <Link
            to={"/"}
            className="link_text"
            onClick={() => {
              if (storeContext.toggleSidebar) {
                storeContext.toggleSidebar(false);
              }
              setActiveLocation && setActiveLocation("/");
            }}
          >
            Home
          </Link>{" "}
          / Plant Information
        </div>
        <div className="login-header">Plant Information</div>
        {showError.isError && <ErrorCard ErrorMessage={showError.title} onRefresh={handleRefresh} ErrorType={showError.ErrorType} />}
        <div className={` ${classes.tab} tab col-to-row flex-start`}>
          <NavLink
            to="plant-site-information"
            end
            className={() =>
              (location.pathname.includes("/equipmentAttachments/") || location.pathname.toLowerCase().endsWith("/plant-information") || location.pathname.toLowerCase().endsWith("/plant-information/plant-site-information")) ? `${classes.tabItem}  ${classes.marginRight36} ${classes.activeTab}` : `${classes.tabItem}  ${classes.marginRight36} `
            }
          >
            Plant Site Details
          </NavLink>
          <NavLink to="site-contacts" className={({ isActive }) => (isActive ? `${classes.tabItem} ${classes.activeTab}` : `${classes.tabItem}`)}>
            Site Contacts
          </NavLink>
        </div>
        <br />
        <Outlet context={relatedData} />
      </div>
      <div className="content flex-grow-0 flex-end">
        <Footer />
      </div>
    </div>
  );
}

export default PlantInformation;
