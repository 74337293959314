import { useEffect, useRef, useState } from "react";
import { AccordionDataType } from "./types";
import "./Accordion.css";
import ImageImports from "../../utils/ImageImports";

const { downArrow } = ImageImports;
function AccordionItem({
    data,
    isOpen,
    btnOnClick
  }: {
    data: AccordionDataType;
    isOpen: boolean;
    btnOnClick: () => void;
  }) {
    const contentRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(0);
  
    useEffect(() => {
      if (isOpen) {
        const contentEl = contentRef.current as HTMLDivElement;
  
        setHeight(contentEl.scrollHeight);
      } else {
        setHeight(0);
      }
    }, [isOpen, data]);

    return (
      <li className={`Accordion-item ${isOpen ? "active" : ""}`}>
        <div className="Accordion-item-title text-xl text-gray-900 font-extrabold cursor-pointer" onClick={btnOnClick}>
          <div className="flex row mt-2 mb-2 p-4 bg-header rounded">
            <span className="grow flex justify-between items-center pr-2">
              {typeof data.title === 'function'? data.title() : data.title}
            </span>
            <img src={downArrow} alt="open/close" className={`transition duration-200 ease-in-out ${isOpen? 'rotate-180' : ''}`} style={{filter: 'var(--svgFilterDarkBlueButton)'}} />
          </div>
        </div>
        <div className="accordion-item-container" style={{ height }}>
          <div ref={contentRef} className="Accordion-item-content">
            {isOpen && (typeof data.content === 'function'? data.content() : data.content)}
          </div>
        </div>    
      </li>
    );
  }
  
  export default AccordionItem;
  