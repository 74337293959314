import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { QueryFunctionContext, useQuery, UseQueryOptions } from "react-query";
import { getSearchResults } from "../endpoints/endpoints";
import { Store } from "../Store";
import { handleAxiosResponse } from "../utils/handleAxiosResponse";
import { CPApiResponse, GlobalSearch } from "../utils/interface";


const CancelToken = axios.CancelToken;
const source = CancelToken.source();
declare type FetchSearchTextParameters = ['fetchSearchText', {searchText: string, selectedPlantId: number}];
const fetchSearchText = async ({queryKey}: QueryFunctionContext<FetchSearchTextParameters>): Promise<GlobalSearch[]> => {
  const [_key, {searchText, selectedPlantId}] = queryKey;
  const response: AxiosResponse<CPApiResponse<GlobalSearch[]>> = await axios.get(`${getSearchResults}?plantId=${selectedPlantId}&searchText=${searchText}`, {
    cancelToken: source.token,
  });
  return handleAxiosResponse(response);
};

export const useFetchSearchText = (searchText: string, options: Omit<UseQueryOptions<GlobalSearch[], {message: string}, GlobalSearch[], FetchSearchTextParameters>, 'queryKey' | 'queryFn'> = {}) => {
    const {selectedPlant} = useContext(Store);
    return useQuery<GlobalSearch[], {message: string}, GlobalSearch[], FetchSearchTextParameters>(["fetchSearchText", {searchText, selectedPlantId: selectedPlant.plantId}], fetchSearchText, options);
}