import { FunctionComponent, useContext } from "react";
import Button from "../../components/Button/Button";
import ModalGlobal from "../../components/UI/Modal/ModalGlobal";
import { Store } from "../../Store";
import { Report } from "../../utils/interface";
import { DocumentModuleInterface } from "../TechnicalDocumentation/TechnicalDocumentationContainer";
import classes from './Reports.module.css';
import {CloseAllModal} from "../../utils/CloseAllModal";

interface Props {
    documentModule: DocumentModuleInterface;
    documents: Report[];
    onClose: () => void;
    confirm: () => void;
}

const DeleteDocumentModal: FunctionComponent<Props> = ({documentModule, documents, onClose, confirm}) => {
    const {selectedPlant, setIsModal} = useContext(Store);
    const close = () => {        
        setIsModal && setIsModal!({ ...CloseAllModal });
    };

    return (
        <ModalGlobal modaltitle={`Delete (${documents.length}) Document(s)?`} isModalHeader={true} modalClose={() => {
            close();
            onClose();
        }}>
            <div className={`${classes.delete_modal_body} ${classes.flex_column}`}>
                <p>Are you sure you want to delete the selected document(s) from {documentModule.name}? This document(s) will no longer be accessible to {selectedPlant.name} users. This cannot be undone.</p>
                <ul>
                    {documents.map(doc => (
                        <li key={doc.globalId}>{doc.displayFileName}</li>
                    ))}
                </ul>
                <div className={classes.flex_row}>
                    <Button className={`redDelete`} disabled={false} text={"Yes, Delete"} onClick={() => {
                        close();
                        confirm();
                    }} />
                    <Button className={`whiteBtn`} disabled={false} text={"Cancel"} onClick={() => {
                        close();
                        onClose();
                    }} />
                </div>
            </div>
        </ModalGlobal>
    );
}

export default DeleteDocumentModal;