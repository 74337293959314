import React, { useContext } from "react";
import { Store } from "../../../Store";

import * as Yup from "yup";

import ModalGlobal from "../../UI/Modal/ModalGlobal";
import { Formik, Form } from "formik";
import TextAreaField from "../../UI/Form/TextAreaField/TextAreaField";

import { useQueryClient } from "react-query";
import { CloseAllModal } from "../../../utils/CloseAllModal";
import Loader from "../../Loader/Loader";
import { queryKeys } from "../../../react-query/queryKeys";
import { useDelete_PlantEquipment } from "../../../containers/PlantInformation/EquipmentsHttpServices";
import { useOnError } from "../../../customHooks/useOnError";
import ErrorCard from "../../ErrorCard/ErrorCard";

function FormDelete({ selectedEquipmentId, modaltitle, modaltitlename }: FormIdObj) {
  const { isModal, setIsModal, showError } = useContext(Store);
  const queryClient = useQueryClient();
  const setErrorData = useOnError();

  const { mutate: deletePlantEquipment, isLoading: isDeleting, isIdle } = useDelete_PlantEquipment();

  interface deleteEquipmentProps {
    plantEquipmentId: number;
    deletionReason: string;
  }

  const handleDeleteEquipmentForm = (values: deleteEquipmentProps) => {
    deletePlantEquipment(values, {
      onSuccess: () => {
        setIsModal &&
          setIsModal({
            ...CloseAllModal,
            isDeleteSuccess: { ...isModal.isDeleteSuccess, isEquipment: true },
          });
        queryClient.invalidateQueries(queryKeys.plantEquipments);
      },
      onError: (error: unknown) => {
        setErrorData(error);
      },
    });
  };

  return (
    <ModalGlobal modaltitle={modaltitle} modaltitlename={modaltitlename} isModalHeader={true}>
      <Formik
        enableReinitialize
        initialValues={{
          plantEquipmentId: selectedEquipmentId,
          deletionReason: "",
        }}
        onSubmit={handleDeleteEquipmentForm}
        validationSchema={Yup.object({})}
      >
        {(formikProps) => (
          <>
            {showError.isError && <ErrorCard ErrorMessage={showError.title} ErrorType={"danger"} />}
            <Form>
              {isDeleting ? (
                <Loader />
              ) : (
                <>
                  <div className="flex align-center marginBot30">
                    <div className="flex-grow-1">
                      Are you sure you want to delete the selected equipment from Brunswick County? Deleting this equipment will also delete any
                      associated attachments. This cannot be undone.
                    </div>
                  </div>
                  <TextAreaField
                    name="deletionReason"
                    placeholder="Type here.."
                    className="input"                   
                    type="textarea"
                    labelName="Reason For Deletion*"
                    display="block"                    
                  />
                </>
              )}
              <div className="flex align-center">
                <div className="flex-grow-1 flex">
                  <button type={"submit"} className="primaryBtn redDelete " disabled={!formikProps.dirty || formikProps.isSubmitting}>
                    Yes, Delete
                  </button>
                  <button
                    type={"button"}
                    className="primaryBtn whiteBtn marginLeft16"
                    onClick={() => {
                      setIsModal!({
                        ...CloseAllModal,
                      });
                    }}
                  >
                    Cancel
                  </button>
                </div>
                <span>*Required fields</span>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </ModalGlobal>
  );
}

interface FormIdObj {
  modaltitle: string;
  modaltitlename: string;
  selectedEquipmentId: number;
}
export default FormDelete;
