import { FunctionComponent, useContext, useEffect, useState } from "react";
import { Store } from "../../../Store";
import Button from "../../../components/Button/Button";
import ErrorCard from "../../../components/ErrorCard/ErrorCard";
import PlainDragDropField from "../../../components/UI/Form/DragDropField/PlainDragDropField";
import MultiSelectDropDown from "../../../components/UI/Form/DropDownSelect/MultiSelectDropDown";
import { DropDownOptionItem, PlainDropDown } from "../../../components/UI/Form/DropDownSelect/PlainDropDown";
import ImageImports from "../../../utils/ImageImports";
import { DocumentGroup, PlantEquipmentsDATA, SiteUnit } from "../../../utils/interface";
import { useGetFile_TBReport, usePlantSiteUnitEquipment, useUnitsOfEquipmentIds } from "../../Reports/ReportsHttpServices";
import { AddDocumentRequiredProps } from "../TechnicalDocumentationContainer";
import classes from './PowerReport.module.css';

const { trash } = ImageImports;

const AddPartsCatalog: FunctionComponent<AddDocumentRequiredProps> = ({ documentModule, documentGroups, onSave, siteUnits, isEdit }) => {
    const [reportName, setReportName] = useState<string>('');
    const [documentGroup, setDocumentGroup] = useState<DocumentGroup>();
    const [siteUnit, setSiteUnit] = useState<SiteUnit>();
    const [attachment, setAttachment] = useState<File>();
    const [equipment, setEquipment] = useState<number[]>([]);
    const [productTypes, setProductTypes] = useState<{ id: number, name: string }[]>([]);
    const { plantConfig, report, showError } = useContext(Store);
    const [selectionText, setSelectionText] = useState<string>('');
    const [editedFlag, setEditedFlag] = useState<boolean>(false);
    const [attachmentEdited, setAttachmentEdited] = useState<boolean>(false);
    const { data: siteEquipment } = usePlantSiteUnitEquipment((siteUnit?.unitId || 0), {
        enabled: !!siteUnit,
        initialData: []
    });

    useEffect(() => {
        setReportName(attachment?.name || '');
    }, [attachment]);

    useEffect(() => {
        if (report && isEdit) {
            const selectedEquipment = (report.associatedEquipment || []);
            if (selectedEquipment.length > 0) {
                const selectedEquipmentIds = selectedEquipment.map(d => d.equipmentId);
                handleEquipmentSelection(selectedEquipmentIds, selectedEquipmentIds[0]);
            }
            setDocumentGroup(documentGroups.find(dg => dg.id === report.itemTypeId));
            setSiteUnit(siteUnits[0])
        }
    }, [report]);

    useUnitsOfEquipmentIds(equipment, {
        onSuccess: (unitArray) => {
            setSiteUnit(siteUnits.find(d => d.unitId === unitArray[0]));
        },
        enabled: isEdit && !!report && equipment.length > 0
    });

    const { isFetching: fileLoading } = useGetFile_TBReport(report.globalId, {
        enabled: report.globalId > 0 && isEdit,
        onSuccess: (data: Blob) => {
            setAttachment(new File([data], report.originalFileName || report.displayFileName, { type: data.type }));
        },
        onError: (error: any) => { },
    });

    const handleDocumentGroupSelection = (selectedOption: DropDownOptionItem) => {
        setDocumentGroup(documentGroups.find(dg => dg.id === selectedOption.id));
        setEditedFlag(true);
    };

    const handleUnitSelection = (selectedOption: DropDownOptionItem) => {
        setSiteUnit(siteUnits.find(su => su.unitId === selectedOption.id));
        setEditedFlag(true);
    };

    const saveReport = () => {
        if (attachment && documentGroup) {
            const formData = new FormData();
            formData.append('BaseOrgId', `${plantConfig.data.baseOrgId}`);
            if (!isEdit || attachmentEdited) {
                formData.append('File', attachment);
            }
            formData.append('ItemTypeId', `${documentGroup.id}`);
            formData.append('DisplayName', reportName);
            const selectedEquipment = equipment.filter(d => d > 0);
            for (let i = 0; i < selectedEquipment.length; i++) {
                formData.append(`AssociatedEquipment[${i}].equipmentId`, `${selectedEquipment[i]}`);
            }
            if (isEdit && report) {
                onSave([report.globalId, formData]);
            } else {
                onSave(formData);
            }
        }
    };

    // get all unique product type ids and names
    useEffect(() => {
        let tmpProductTypes: { [index: number]: string } = {};
        if (siteEquipment) {
            tmpProductTypes = siteEquipment?.reduce((carry: { [index: number]: string }, current: PlantEquipmentsDATA) => {
                carry[current.productType] = current.productTypeName;
                return carry;
            }, {});
        }
        const ids = Object.keys(tmpProductTypes);
        const productTypes: { id: number, name: string }[] = [];
        for (let i = 0; i < ids.length; i++) {
            let key = Number.parseInt(ids[i]);
            productTypes.push({ id: key * -1, name: `All ${tmpProductTypes[key]}` });
        }
        setProductTypes(productTypes);
    }, [siteEquipment]);

    const equipmentOptions: { id: number, name: string }[] = [
        { id: 0, name: 'All Equipment' },
        ...productTypes
    ];

    if (siteEquipment) {
        for (let i = 0; i < siteEquipment.length; i++) {
            equipmentOptions.push({
                id: siteEquipment[i].plantEquipmentId,
                name: siteEquipment[i].equipmentName
            })
        }
    }

    const getEquipmentLabel = (values: number[]) => {
        if (!!(values.length > 1)) {
            const eqCountByType = siteEquipment?.filter(eq => eq.productType === (values.filter(v => v < 0)[0] * -1)).length || 0;
            const valuesIncludeEqFromOutsideGroup = values.length !== eqCountByType + 1;
            if (valuesIncludeEqFromOutsideGroup)
                setSelectionText('Multiple equipment options selected');
            else if (!valuesIncludeEqFromOutsideGroup)
                setSelectionText(productTypes.filter(type => values.includes(type.id))[0].name);
        }
        // if values includes 'All Equipment'
        if (values.includes(0)) setSelectionText(equipmentOptions[0].name);
        // if only 1 option selected
        if (values.length === 1) setSelectionText(equipmentOptions.filter(el => el.id === values[0])[0]?.name)
    }

    const handleEquipmentSelection = (values: number[], toggled: number) => {
        let tmpValues = [...values];
        let tmpSiteEquipment = (siteEquipment || []);

        /**
         * Remove all grouped options from tmpValues
         * if toggled = 0 and orig values includes 0, select all
         * if toggled = 0 and orig values does not include 0, un select all
         * 
         */

        if (toggled === 0) {
            if (values.includes(0)) {
                tmpValues = tmpSiteEquipment.map((d) => d.plantEquipmentId);
            } else {
                tmpValues = [];
            }
        }

        if (toggled < 0) {
            let productTypeId = toggled * -1;
            // a group was selected, check everything in that group
            if (values.includes(toggled)) {
                for (let i = 0; i < tmpSiteEquipment.length; i++) {
                    if (tmpSiteEquipment[i].productType === productTypeId && !tmpValues.includes(tmpSiteEquipment[i].productType)) {
                        tmpValues.push(tmpSiteEquipment[i].plantEquipmentId);
                    }
                }
            } else {
                // a group was deselected, un check everything in that group
                let productEquipment = tmpSiteEquipment.filter(eq => eq.productType === productTypeId).map(eq => eq.plantEquipmentId);
                tmpValues = tmpValues.filter(v => !productEquipment.includes(v));
            }
        }

        for (let i = 0; i < productTypes.length; i++) {
            let allOptionsOfTypeSelected = true;
            let productTypeId2 = +productTypes[i].id * -1;
            for (let j = 0; j < tmpSiteEquipment.length; j++) {
                if (tmpSiteEquipment[j].productType === productTypeId2) {
                    allOptionsOfTypeSelected = allOptionsOfTypeSelected && tmpValues.includes(tmpSiteEquipment[j].plantEquipmentId);
                }
            }
            if (allOptionsOfTypeSelected) {
                tmpValues.push(productTypes[i].id);
            } else {
                tmpValues = tmpValues.filter(val => (val * -1) !== productTypeId2)
            }
        }

        // if everything is selected, check select all
        if (tmpValues.length > 0 && tmpValues.filter((d) => d > 0).length === tmpSiteEquipment.length) {
            tmpValues.push(0);
            for (let i = 0; i < productTypes.length; i++) {
                tmpValues.push(productTypes[i].id);
            }
        } else tmpValues = tmpValues.filter((val) => val !== 0);

        tmpValues = tmpValues.filter((v, i, a) => a.indexOf(v) === i);

        setEquipment(tmpValues);        
        getEquipmentLabel(tmpValues);
    };

    return (

        <div className={`${classes.flex_column} ${classes.add_document_form}`}>
            {showError.isError && <ErrorCard ErrorMessage={showError.title} ErrorType={"danger"} />}
            <div className={`${classes.flex_row} ${classes.add_document_field}`}>
                <span className={classes.field_label}>Document Name*</span>
                <input type="text" value={reportName} disabled onChange={e => setReportName(e.target.value)} />
            </div>
            <div className={`${classes.flex_row} ${classes.add_document_field}`}>
                <span className={classes.field_label}>Document Group*</span>

                <PlainDropDown value={documentGroup?.id}
                    className={`${classes.select_container} ${!!documentGroup?.id ? classes.selected : classes.placeholder}`}
                    selectClass={`${classes.flex_row} ${classes.select}`}
                    optionsClass={`${classes.flex_column} ${classes.select_options}`}
                    options={[{ id: false, name: 'Select document group' }, ...documentGroups]}
                    onSelection={handleDocumentGroupSelection} />
            </div>
            <div className={`${classes.flex_row} ${classes.add_document_field}`}>
                <span className={classes.field_label}>Associated Unit</span>

                <PlainDropDown value={siteUnit?.unitId}
                    className={`${classes.select_container} ${!!siteUnit?.unitId ? classes.selected : classes.placeholder}`}
                    selectClass={`${classes.flex_row} ${classes.select}`}
                    optionsClass={`${classes.flex_column} ${classes.select_options}`}
                    options={[{ id: false, name: 'Select site unit' }, ...siteUnits.map(unit => ({ id: unit.unitId, name: unit.name }))]}
                    onSelection={handleUnitSelection} />
            </div>
            {siteEquipment && siteEquipment.length > 0 && (
                <div className={`${classes.flex_row} ${classes.add_document_field}`}>
                    <span className={classes.field_label}>Associated Equipment</span>
                    <MultiSelectDropDown value={equipment}
                        className={`${classes.select_container} ${!!equipment.length ? classes.selected : classes.placeholder}`}
                        selectClass={`${classes.flex_row} ${classes.select}`}
                        optionsClass={`${classes.flex_column} ${classes.select_options}`}
                        options={equipmentOptions}
                        noSelectionText="Select equipment association(s)"
                        hasSelectionText={selectionText}
                        onSelection={(values: number[], toggled: number) => {
                            handleEquipmentSelection(values, toggled)
                            setEditedFlag(true);
                        }} />
                </div>
            )}
            <div className={`${classes.flex_row} ${classes.add_document_field}`}>
                <span className={classes.field_label}>Attachment*</span>
                {attachment && (
                    <>
                        <input type="text" disabled className={classes.attachment_name} value={reportName} />
                        <img className={classes.delete_file}
                            onClick={() => setAttachment(undefined)}
                            src={trash}
                            alt="Remove Attachment" />
                    </>
                )}
            </div>
            <PlainDragDropField onChange={(e) => {setAttachment(e); setEditedFlag(true); setAttachmentEdited(true);}} fileLoading={fileLoading} attachment={attachment} />
            <div className={`text-sm font-black-200 -mt-5`}>
                <p className="m-0 text-sm font-black-200">Accepted file types: .PDF, .DOC, .XLSX, .JPG, .PNG</p>
                <p className="m-0 text-sm font-black-200">Maximum file upload size: 1 GB*</p>
                <p className="m-0 text-ocean text-sm">Please Note: The max file size for users outside of the Mitsubishi Network may be less than 1GB.</p>
                {/* <p>WARNING: The document filename will automatically populate the Report Name field. This CANNOT be modified after clicking on Add Document</p> */}
            </div>
            <div className={classes.flex_row} style={{alignItems: "center", justifyContent: "space-between"}}>
                {isEdit ? (
                    <>
                        <Button className={`darkBlue`} disabled={!editedFlag || !(reportName.length > 0 && documentGroup && siteUnit && attachment && equipment.length > 0)} text="Save Document" onClick={saveReport} />
                    </>
                ) : (
                    <Button className={`darkBlue`} disabled={!(reportName.length > 0 && documentGroup && attachment)} text="Add Document" onClick={saveReport} />
                )}
                <span>*Required fields</span>
            </div>
        </div>
    );
}

export default AddPartsCatalog;
