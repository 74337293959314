import { useFormikContext } from "formik";
import React, { ChangeEvent, useContext, useState } from "react";
import { Store } from "../../../../Store";
import { toBase64 } from "../../../../utils/FileUtils";
import ImageImports from "../../../../utils/ImageImports";
import MiniTooltip from "../../MiniTooltip/MiniTooltip";

import classes from "./ImageUploadField.module.css";
const ImageUploadField = (props: ImageUploadFieldProps) => {
	const stateContext = useContext(Store);
	const { tooltip } = ImageImports;
	const { values, setFieldValue } = useFormikContext<any>();
	const [imgBase64, setImgBase64] = useState("");
	const [defaultImagePath, setDefaultImagePath] = useState(props.defaultImagePath);
	const [attachmentError, setAttachmentError] = useState<string>();
	const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      const file = event.currentTarget.files[0];
      if (file) {
		const allowedFileTypes = ["image/gif", "image/jpeg", "image/png", "image/bmp"];
		if (!allowedFileTypes.includes(file.type)){
			setAttachmentError("File type not allowed");
			setImgBase64("");
			setFieldValue(props.name, undefined);
		}
		else {
			setAttachmentError(undefined);
			toBase64(file)
			.then((base64Image: string) => 
			{
				if (base64Image.includes('data:image')) {
					setImgBase64(base64Image);
					setFieldValue(props.name, file);
					setDefaultImagePath("");
				}
				else {
					event.currentTarget.value = '';
					setImgBase64("");
					setDefaultImagePath(values.imageURL);
					return;
				}
			}
			)
			.catch((err) => console.error(err));
		}
      } else {
        setImgBase64("");
        setDefaultImagePath(values.imageURL);
      }
    }
  };

  const style = {
    width: "100%",
    maxWidth: "260px",
  };

  return (
    <div className={classes.form_field_row}>
      <label htmlFor={props.name} className={classes.input_label}>
        {props.labelName}
        {props.tooltip?.text.length && (
          <div className={classes.cursor_pointer}>
            <MiniTooltip text={props.tooltip.text} space={props.tooltip.space} placement={props.tooltip.placement}>
              <img src={tooltip} className={classes.tooltip_icon} alt="tool tip" />
            </MiniTooltip>
          </div>
        )}
      </label>
      <div className={classes.form_group}>
        <div className="modal-map">
          <div className={classes.map}>
            {imgBase64 ? (
              <div style={style}>
                <img className={classes.img_fluid} src={imgBase64} alt="map sda" />
              </div>
            ) : null}
            {defaultImagePath ? (
              <div style={style}>
                <div
                  className="google-map map"
                  style={{
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url('${defaultImagePath}')`,
                  }}
                ></div>
              </div>
            ) : null}
          </div>
          <div className={classes.change_photo}>
            <label htmlFor={props.name} style={{ cursor: "pointer" }}>
              Change Photo
            </label>
            <input id={props.name} type="file" style={{ display: "none" }} onChange={handleFileUpload} accept={"image/*"}/>
			{attachmentError && <div style={{ color: "red" }}>Attachment Error: {attachmentError}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

interface ImageUploadFieldProps {
  labelName: string;
  name: string;  
  defaultImagePath: string;
  tooltip?:{   
    placement?: "bottom" | "top" | "right" | "left";
    isDisabled?: 0 | 1;
    space?:number;
    text:string;
  };
}
ImageUploadField.defaultProps = {
  defaultImagePath: "",
};

export default ImageUploadField;
