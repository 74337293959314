import { useResizeObserver } from "@dnd-kit/core/dist/hooks/utilities";
import ImageImports from "../../utils/ImageImports";
import classes from "../DiscussionBoard/DiscussionBoard.module.css";

interface ParticipantListProps {
  users: Array<string | undefined>;
}

function ParticipantList(props: ParticipantListProps) {
  const { userGroupIcon } = ImageImports;

  return (
    <div>
      <div className={classes.paticipantList} style={{ color: "#000", cursor: "pointer" }}>
        <img src={userGroupIcon} alt="" />
        &nbsp;{props?.users.length} participant{props?.users.length === 1 ? '' : 's'}
      </div>
      {props.users.map((user, i) => {
        if (!user) return "";
        const [firstname, lastname] = user.split(" ");

        return (
          <div className={`${classes.userList} mb-4 lg:mb-6`} key={i}>
            <span className={classes.avatar}>{firstname.charAt(0)} {lastname.charAt(0)}</span>
            {user}
          </div>
        );
      })}
    </div>
  );
}

export default ParticipantList;
