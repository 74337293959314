import React, { useEffect, useState } from "react";
import classes from "./QuoteRequestForm.module.css";
import DatePickerFields from "../UI/BasicForm/DatePickerField/DatePickerFields";
import SelectField from "../UI/BasicForm/SelectField/SelectField";
import { useFormikContext } from "formik";
import { CPApiResponse, RequestQuoteFormInterface, User } from "../../utils/interface";
import { useGetAllByBaseOrg_Users } from "../../containers/Administration/ListApiMethods";
import { useOnError } from "../../customHooks/useOnError";
import { useStore } from "../../useStore";

const InternalUserField = () => {
  const { values, setFieldValue } = useFormikContext<RequestQuoteFormInterface>();
  const [contactType, setContactType] = useState<{ id: number | string; name: string }[]>([{ id: "", name: "Select Customer" }]);
  const setErrorData = useOnError();
  const { selectedPlant } = useStore();

  //{***************************************************************************************************************************}

  const onSuccessGetAllByBaseOrg = (users: CPApiResponse<User[]>) => {
    let allUsers = users.data.filter((a) => !a.mpInternal).map((contact) => ({ id: contact.id, name: `${contact.fName} ${contact.lName}` }));
    allUsers.sort((a, b) => {
      return a.name.toLowerCase().slice(0, 1) > b.name.toLowerCase().slice(0, 1) ? 1 : -1;
    });
    // console.log("🚀 ~ file: InternalUserField.tsx:22 ~ onSuccessGetAllByBaseOrg ~ allUsers", allUsers);
    if (allUsers.length > 0) {
      setContactType([{ id: 0, name: "Select Customer" }, ...allUsers]);
    } else {
      setContactType([{ id: 0, name: "No designated customer contact found" }]);
    }
  };

  const onErrorGetAllByBaseOrg = (error: unknown) => {
    setErrorData(error);
  };

  const { isLoading } = useGetAllByBaseOrg_Users(selectedPlant.baseOrgId, onSuccessGetAllByBaseOrg, onErrorGetAllByBaseOrg);

  //{***************************************************************************************************************************}

  useEffect(() => {
    return () => {
      setFieldValue("quoteRequest.dateRequestReceivedFromCustomer", "");
      setFieldValue("quoteRequest.customerContactId", 0);
    };
  }, [values.quoteRequest.isCustomer]);

  return (
    <div className={classes.flex}>
      <div className={`${classes.flexCol}`}>
        <DatePickerFields labelName={"Date Request Received by Customer*"} name="quoteRequest.dateRequestReceivedFromCustomer" />
      </div>
      <div className={`${classes.flexCol}`}>
        {!isLoading && (
          <SelectField defaultValue={0} options={contactType} name={"quoteRequest.customerContactId"} labelName={"Designated Customer Contact*"} />
        )}
      </div>
    </div>
  );
};

export default InternalUserField;
