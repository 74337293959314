import { useContext } from "react";
import ModalGlobal from "../../UI/Modal/ModalGlobal";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import TextInputField from "../../UI/Form/TextInputField/TextInputField";
import DropDownSelect from "../../UI/Form/DropDownSelect/DropDownSelect";
import ImageUploadField from "../../UI/Form/ImageUploadField/ImageUploadField";
import { Store } from "../../../Store";

import { useUpdate_PlantConfigData } from "../../../containers/PlantInformation/PlantConfigHttpServices";
import { useQueryClient } from "react-query";
import Loader from "../../Loader/Loader";
import { CloseAllModal } from "../../../utils/CloseAllModal";
import { queryKeys } from "../../../react-query/queryKeys";
import TextAreaField from "../../UI/Form/TextAreaField/TextAreaField";
import { useOnError } from "../../../customHooks/useOnError";
import ErrorCard from "../../ErrorCard/ErrorCard";
import DatePickerFields from "../../UI/BasicForm/DatePickerField/DatePickerFields";
import classes from './FormPlantSiteAddEdit.module.css';
import dayjs from "dayjs";

const FormPlantSiteAddEdit = ({ modaltitle, isEdit, modaltitlename }: FormPlantSiteAddEditProps) => {
  const {
    plantConfig: {
      data: {
        plantId,
        baseOrgId,
        baseOrgName,
        operators,
        websiteURL,
        owner,
        parentGroup,
        address1,
        address2,
        address3,
        mainPhone,
        frequency,
        mhiRegion,
        notesComments,
        file,
        units,
        lastOutage,
        nextPlannedOutage,
      },
    },
    setIsModal,
    showError,
    isModal,
    plantConfigImage,
    fuelType: { data: fuelTypeData },
  } = useContext(Store);
  const queryClient = useQueryClient();
  const setErrorData = useOnError();

  const { mutate: Update_PlantConfigData, isLoading: isEditing, isSuccess: isEdited, data: editResponseData } = useUpdate_PlantConfigData();

  const handleOnSubmit = (values: any, formikHelpers: FormikHelpers<any>) => {
    if (dayjs(values.lastOutage).isValid()) {
      values.lastOutage = dayjs(values.lastOutage).format("MM/DD/YYYY");
    }
    if (dayjs(values.nextPlannedOutage).isValid()) {
      values.nextPlannedOutage = dayjs(values.nextPlannedOutage).format("MM/DD/YYYY");
    }
    Update_PlantConfigData(values, {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.plantConfig);
        queryClient.invalidateQueries(queryKeys.plantConfigImage);
        queryClient.invalidateQueries(queryKeys.plantSiteDetails);
        setIsModal!({
          ...CloseAllModal,
          isEditedSuccess: {
            ...isModal.isEditedSuccess,
            isPlantConfig: true,
          },
        });
      },
      onError: (error: unknown) => {
        setErrorData(error);
        formikHelpers.setSubmitting(false);
      },
    });
  };

  const frequencyType = [
    {
      id: "",
      name: "Select Frequency"
    },
    {
      id: "50 Hz",
      name: "50 Hz",
    },
    {
      id: "60 Hz",
      name: "60 Hz",
    },
  ];

  let initialValues;

  if (!isEdit) {
    initialValues = {
      plantId: 0,
      baseOrgId: 0,
      operators: "",
      websiteURL: "",
      owner: "",
      parentGroup: "",
      address1: "",
      address2: "",
      address3: "",
      mainPhone: "",
      frequency: "",
      mhiRegion: "",
      notesComments: "",
      lastOutage: "",
      nextPlannedOutage: "",
      file: null,
    };
  } else {
    initialValues = {
      plantId: plantId,
      baseOrgId: baseOrgId,
      operators: operators,
      websiteURL: websiteURL,
      owner: owner,
      parentGroup: parentGroup,
      address1: address1,
      address2: (address2 || ''),
      address3: (address3 || ''),
      mainPhone: mainPhone,
      frequency: frequency == null ? "" : frequency,
      mhiRegion: mhiRegion,
      notesComments: (notesComments || ''),
      lastOutage: lastOutage,
      nextPlannedOutage: nextPlannedOutage,
      file: null,
    };
  }
  return (
    <ModalGlobal modaltitle={modaltitle} modaltitlename={modaltitlename} isModalHeader={true}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        validationSchema={Yup.object({
          address1: Yup.string().required("This field is required"),
          operators: Yup.string().required("This field is required"),
          mhiRegion: Yup.string().required("This field is required"),
          owner: Yup.string().required("This field is required"),
          websiteURL: Yup.string().url().required("Website url is not valid"),
        })}
        enableReinitialize
      >
        {(formikProps) => (
          <>
            {showError.isError && <ErrorCard ErrorMessage={showError.title} ErrorType={"danger"} />}
            <Form>
              {isEditing ? (
                <Loader />
              ) : (
                <>
                  <div>
                    <ImageUploadField defaultImagePath={plantConfigImage.file} name="file" labelName="Plant Photo" />
                  </div>
                  <div>
                    <TextInputField
                      name="owner"
                      labelName="Owner*"
                      className=""
                      placeholder="Enter Owner"
                      // showTooltip={true}
                    />
                  </div>
                  <div>
                    <TextInputField name="operators" labelName="Operator*" className="" placeholder="Enter Operator" />
                  </div>
                  <div className="flex flex-col gap-1">
                    <TextInputField name="address1" labelName="Address*" placeholder="Street Address" className="marginbottom0" />
                    <TextInputField name="address2" labelName="" className="marginbottom0" placeholder="City, State Zip" />
                    <TextInputField name="address3" labelName="" className="" placeholder="Country" />
                  </div>
                  <div>
                    <TextInputField name="mhiRegion" labelName="Region*" className="" placeholder="Enter Region" />
                  </div>
                  <div className={`mb-8 ${classes.datepicker_container}`}>
                    <DatePickerFields
                      containerClassName={classes.datepicker_wrapper}
                      dateFormat="MM/dd/yyyy"
                      labelName={"Last Planned Outage"}
                      name="lastOutage"
                    />
                  </div>
                  <div className={`mb-8 ${classes.datepicker_container}`}>
                    <DatePickerFields
                      containerClassName={classes.datepicker_wrapper}
                      dateFormat="MM/dd/yyyy"
                      labelName={"Next Planned Outage"}
                      name="nextPlannedOutage"
                    />
                  </div>
                  <div>
                    <TextAreaField
                      type={"textarea"}
                      name="notesComments"
                      labelName="Notes"
                      className=""
                      placeholder="Notes"
                      display={"inline"}
                      height={50}
                      // showTooltip={true}
                    />
                  </div>
                  <div>
                    <DropDownSelect                      
                      name="frequency"
                      options={frequencyType}
                      labelName="Frequency"
                      defaultValue={frequency}
                      placeholder="Select Frequency"
                    />
                  </div>
                  <div>
                    <TextInputField
                      name="websiteURL"
                      labelName="Website URL*"
                      className=""
                      placeholder="Enter website URL"
                      details="URL must include http:// or https://"
                      // showTooltip={true}
                    />
                  </div>
                </>
              )}

              <div className="flex align-center">
                <div className="flex-grow-1 flex">
                  <button type="submit" className="primaryBtn darkBlue" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                    Save Changes
                  </button>
                </div>
                <span>*Required fields</span>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </ModalGlobal>
  );
};

interface FormPlantSiteAddEditProps {
  modaltitle: string;
  modaltitlename: string;
  isEdit: boolean;
}

export default FormPlantSiteAddEdit;
