import React, { useContext, useEffect, useRef } from "react";

import classes from "./ErrorCard.module.css";
import ImageImports from "../../utils/ImageImports";
import { Link } from "react-router-dom";
import { Store } from "../../Store";

const ErrorCard = (props: ErrorCardProps) => {
  const { setShowError,showError } = useContext(Store);
  const { dangerIcon, closeError, alarmWarning,success,info } = ImageImports;
  const loadOnce = useRef<boolean>(true);

  
  


  const handleCloseError = () => {
    setShowError &&
      setShowError({
        title: "",
        isError: false,
        ErrorType: null,
      });
  };
 
  return (
    <div
      className={`${classes.errorCard} ${
        props.ErrorType === "danger"
          ? classes.danger
          : props.ErrorType === "info"
          ? classes.info
          : props.ErrorType === "warning"
          ? classes.warning
          : classes.success
      }`}
    >
      <div className={classes.errorCardInner}>
        {" "}
        <span className={classes.icon}>
          <img
            src={
                props.ErrorType === "danger"
                ? dangerIcon
                : props.ErrorType === "info"
                ? info
                : props.ErrorType === "warning"
                ? alarmWarning
                : success
            
            }
            alt=""
          />
        </span>{" "}
        <span className={classes.errorMsg}>{`${props.ErrorMessage}`}</span>
        {props.onRefresh && props.ErrorType === "danger" && (
          <Link className={classes.link} onClick={props.onRefresh} to={"#"}>
            Try Refresh
          </Link>
        )}
      </div>
      {props.closeBtn && (
        <Link to={"#"} onClick={handleCloseError} className={classes.link}>
          <img src={closeError} alt="" />
        </Link>
      )}
    </div>
  );
};

interface ErrorCardProps {
  ErrorType: "danger" | "warning" | "success" | "info" | null;
  ErrorMessage: string;
  closeBtn: boolean;
  onRefresh?(): void;
}

ErrorCard.defaultProps = {
  closeBtn: true,
};
export default ErrorCard;
