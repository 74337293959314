import React, { useContext, useEffect, useState } from "react";
import { useParams, Outlet, useNavigate, useLocation, generatePath } from "react-router-dom";
import { Store } from "../../Store";
import RelatedLinks from "../../components/RelatedLinks/RelatedLinks";
import Footer from "../../components/Footer/Footer";
import Link from "../../components/Link/Link";
import classes from "./IssueReports.module.css";
import useWindowSize from "../../customHooks/useWindowSize";
import { DiscussionItem } from "../../utils/interface";
import IssueReportList from "./IssueReportList";
import ErrorCard from "../../components/ErrorCard/ErrorCard";

const relatedData = {
  relatedTitle: "Related Information",
  relatedSections: [
    {
      title: "Engineering Support Requests",
      description:
        "Request technical information or send questions to Mitsubishi Power Americas on technical topics. Responses will be documented here along with the requests.",
      buttonLink: "View ESRs",
      route: generatePath("/support/esr/:supportModuleName", { supportModuleName: "Engineering Support Requests" }),
    },
    {
      title: "Frequently Asked Questions",
      description: "This page is the central location for users to review Frequently Asked Questions.",
      buttonLink: "View Frequently Asked Questions",
      route: generatePath("/support/faq/:supportModuleName", { supportModuleName: "Frequently Asked Questions" }),
    },
  ],
};

function IssueReportContainer({ title }: { title: string }) {
  const { showError } = useContext(Store);

  const [discussionItem, setDiscussionItem] = useState<DiscussionItem>();

  const { globalId } = useParams();
  let location = useLocation();

  const { isMobile } = useWindowSize();

  const navigate = useNavigate();

  useEffect(() => {
    if (discussionItem) {
      navigate(`./${discussionItem?.globalId}/discussion-board`);
    }
  }, [discussionItem]);

  return (
    <div className={`${classes.flex_column} ${classes.issue_reports_container} ${isMobile ? classes.is_mobile : ""}`}>
      <div className="content">
        <div className="path breadcrumb">
          <Link to={"/"} className="link_text">
            Home
          </Link>{" "}
          / {title}
        </div>
        <div className="login-header">{title}</div>
        {showError.title && <ErrorCard ErrorMessage={showError.title} ErrorType={showError.ErrorType} />}
        <div className={classes.card}>
          {!globalId || (!!globalId && discussionItem === undefined) ? (
            <IssueReportList
              params={{ preSort: location?.state?.preSort, preFilter: location?.state?.preFilter }}
              setDiscussionItem={setDiscussionItem}
            />
          ) : (
            <Outlet context={{ discussionItem }} />
          )}
        </div>
        <RelatedLinks data={relatedData} />
      </div>

      <div className="content flex-grow-0 flex-end">
        <Footer />
      </div>
    </div>
  );
}

export default IssueReportContainer;
