import React, { useContext, useEffect, useRef, useState } from "react";

import { useFormikContext } from "formik";
import Skeleton from "react-loading-skeleton";
import { useIsFetching } from "react-query";
import { useGet_FileBlob } from "../../../containers/PartsAndService/QuoteRequestHttpServices";
import { useOnError } from "../../../customHooks/useOnError";
import { Store } from "../../../Store";
import { downloadInBrowser, PrintPDF } from "../../../utils/FileUtils";
import { supportedFileTypes } from "../../../utils/getSupportedFileTypes";
import ImageImports from "../../../utils/ImageImports";
import { QuoteRequestAttachment, RequestQuoteFormInterface } from "../../../utils/interface";
import classes from "../AttachmentCard.module.css";
import QuoteAttachmentOptions from "../QuoteAttachmentOptions";
import dayjs from "dayjs";

interface AttachmentCardDisabledProps {
  id: number;
  fileName: string;
  data: QuoteRequestAttachment;
  fileType: string;
  lastUpdated: string;
  ext: string;
  isExternal: boolean;
  isEventClosed: boolean;
  image: string;
  disabled?: boolean;
}

function AttachmentCardDisabled(props: AttachmentCardDisabledProps) {
  // Image imports;
  const { ellipsis, trash, file_edit, download, print, spinner ,excelDummy, docDummy} = ImageImports;
  const { values, setFieldValue, resetForm, errors, touched } = useFormikContext<RequestQuoteFormInterface>();
  const [attachmentBlob, setAttachmentBlob] = useState<Blob | null>(null);
  const { isModal, setIsModal, unitTypes, setUnitTypes } = useContext(Store);
  const setErrorData = useOnError();
  const [imgBase64, setImgBase64] = useState<string>("");
  const { docTypes, excelTypes, excelTypesObject, docTypesObject } = supportedFileTypes();
  const isFetching = useIsFetching();

  useGet_FileBlob(props.data.fileId, {
    enabled: props.data.fileId > 0,
    onSuccess(blob: Blob) {
      setAttachmentBlob(blob);
    },
    onError(err: unknown) {
      setAttachmentBlob(null);
    },
  });

  const popoutToolTipListItems = [
    {
      img: download,
      text: "Download",
      onclick: (id: number) => {
        if (attachmentBlob) {
          downloadInBrowser(attachmentBlob, props.data.name);
        }
      },
    },
    {
      img: print,
      text: "Print",
      onclick: (id: number) => {
        if (attachmentBlob) {
          PrintPDF(attachmentBlob);
        }
      },
    },
  ];


  const ActionToolTipRef = useRef<HTMLImageElement>(null);
  const [actionToolTip, setActionToolTip] = useState(false);

  useEffect(() => {
    const handleCloseDropDown = (event: any) => {
      var path = event.path || (event.composedPath && event.composedPath());
      if (path[0] !== ActionToolTipRef.current) {
        setActionToolTip(false);
      }
    };
    document.body.addEventListener("click", handleCloseDropDown);
    //Cleanup
    return () => document.body.removeEventListener("click", handleCloseDropDown);
  }, []);

  useEffect(() => {
    setImgBase64(props.image);
  }, [props.image]);

  let previewContainer;  
  if (imgBase64) {
    if (imgBase64.includes("data:image")) {   
      previewContainer = <div className={`${classes.col_item} ${classes.imgPrevWrapper}`} onLoad={() => URL.revokeObjectURL(imgBase64 || "")} style={{ backgroundImage: `url(${imgBase64})` }}></div>;
    }
    if (imgBase64.includes("data:application")) {
      if (imgBase64.includes("data:application/pdf")) {
        previewContainer = <iframe src={imgBase64}></iframe>;
      } else {
        docTypes.forEach((type) => {
          if (imgBase64.includes(type)) {        
            previewContainer = <div className={`${classes.col_item} ${classes.imgPrevWrapper}`} onLoad={() => URL.revokeObjectURL(docDummy || "")} style={{ backgroundImage: `url(${docDummy})` }}></div>;
          }
        });
        excelTypes.forEach((type) => {
          if (imgBase64.includes(type)) {            
            previewContainer = <div className={`${classes.col_item} ${classes.imgPrevWrapper}`} onLoad={() => URL.revokeObjectURL(excelDummy || "")} style={{ backgroundImage: `url(${excelDummy})` }}></div>;
          }
        });
      }
    }
  } 

  return (
    <>
      <div className={classes.nameplate_title}>
        {
          <div className={classes.nameplate_content}>
            <div className={classes.nameplate_ellipse}>
              <img
                ref={ActionToolTipRef}
                src={ellipsis}
                className="ellipsis"
                alt="ellipsis"
                style={{filter: 'var(--svgFilterDarkBlueButton)'}}
                onClick={() => {
                  !props.disabled && setActionToolTip(!actionToolTip);
                }}
                tabIndex={1}
              />
              <QuoteAttachmentOptions listItems={popoutToolTipListItems} displayTrigger={actionToolTip} id={props.id} />
            </div>
            {isFetching > 0 ? <Skeleton height={200} /> : previewContainer}

            <div className={`${classes.flex_grow1} ${classes.col_item} ${classes.nameplate_text_container}`}>
              <div className="col-item flex-grow-1">{props.fileName}</div>
              <div className={`${classes.flex_grow1} ${classes.col_item} text-gray-50 text-sm`}>
                Last Modified: {`${dayjs(props.lastUpdated).format("MMM DD YYYY")}`}
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
}

export default AttachmentCardDisabled;
