import { FunctionComponent, useContext } from "react";
import { Store } from "../../Store";
import Button from "../../components/Button/Button";
import ModalGlobal from "../../components/UI/Modal/ModalGlobal";
import { CloseAllModal } from "../../utils/CloseAllModal";

interface Props {
  moveConfirmationMeta: {count: number, from: string, to: string};
  onClose: () => void;
}

const MoveDocumentConfirmation: FunctionComponent<Props> = ({moveConfirmationMeta, onClose}) => {
  const {setIsModal} = useContext(Store);
  const close = () => {        
    setIsModal && setIsModal!({ ...CloseAllModal });
    onClose();
  };

  return (
    <ModalGlobal modaltitle={`(${moveConfirmationMeta.count}) Document(s) Successfully Moved`} isModalHeader={true} isTitleCenter={true} modalClose={close} modalSize="sm" modalClass="max-w-[536px]">
      <div className={`flex flex-col gap-8`}>
        <div className="flex align-center" tabIndex={0}>
          <div className={`flex-grow-1 text-center`}>
            <p className="p-0 -mt-2">You successfully moved document(s) from the "{moveConfirmationMeta.from}" group to the "{moveConfirmationMeta.to}" group.</p>
          </div>
        </div>
        <div className={`align-center flex justify-center`}>
          <Button className={`darkBlue`} disabled={false} text={"Done"} onClick={close} />
        </div>
      </div>
    </ModalGlobal>
  );
}

export default MoveDocumentConfirmation;