
import classes from './TechnicalDocumentation.module.css';
import Button from '../../components/Button/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import useWindowSize from '../../customHooks/useWindowSize';
import ImageImports from '../../utils/ImageImports';
import { Permissions } from '../../utils/interface';
import { useContext, useMemo } from 'react';
import { Store } from '../../Store';

const {gear} = ImageImports;

interface HeaderButtonsInterface {
    addButtonText: string;
    customClass: string;
    addButtonClass: string;
    manageButtonClass: string;
    groupPermissions: Permissions;
    reportPermissions: Permissions
}

function HeaderButtons(props: HeaderButtonsInterface) {
    const navigate = useNavigate();
    const {isMobile, isTablet} = useWindowSize();
	const location = useLocation();
    const {header: {data: {user}}} = useContext(Store);

    const canEditGroups = useMemo(() => {
        return (user.portalAdmin && user.mpInternal) || props.groupPermissions.canEdit || props.groupPermissions.canAdmin;
    }, [user, props.groupPermissions]);

    const canAddReports = useMemo(() => {
        return (user.portalAdmin && user.mpInternal) || props.reportPermissions.canAdd || props.reportPermissions.canAdmin;
    }, [user, props.reportPermissions]);

    return (
        <div className={`${classes.header_buttons} ${isMobile? classes.flex_column : classes.flex_row} ${props.customClass} w-[100%] lg:w-auto lg:self-start`}>
            {canEditGroups && <Button className={`whiteBtn manage-button ${props.manageButtonClass} md:w-[50%] lg:w-auto`} text={<div className={classes.manageButtonImage}><img src={gear} alt="Manage Document Groups" /> Manage Document Groups</div>} onClick={() => navigate('./manage-groups')} />}
            {canAddReports && <Button className={`darkBlue add-button ${props.addButtonClass} md:w-[50%] lg:w-auto mb-[0px]`} text={props.addButtonText} onClick={() => navigate(`${location.pathname}/add`)} style={{ order: (isMobile || isTablet) ? '-1' : 'unset' }} />}
        </div>
    );
}

HeaderButtons.defaultProps = {
    addButtonText: "Add Document",
    customClass: '',
    addButtonClass: '',
    manageButtonClass: ''
};

export default HeaderButtons;