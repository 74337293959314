import { AxiosError } from "axios";
import dayjs from "dayjs";
import moment from "moment";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { useQueryClient } from "react-query";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Store } from "../../../../Store";
import Button from "../../../../components/Button/Button";
import ErrorCard from "../../../../components/ErrorCard/ErrorCard";
import tableClasses from "../../../../components/Tables/DisplayLikeTable/DisplayLikeTable.module.css";
import { PlainDropDown } from "../../../../components/UI/Form/DropDownSelect/PlainDropDown";
import ModalGlobal from "../../../../components/UI/Modal/ModalGlobal";
import { useOnError } from "../../../../customHooks/useOnError";
import { queryKeys } from "../../../../react-query/queryKeys";
import { PlantConfigData } from "../../../../utils/interface";
import { useUpdate_PlantConfigData } from "../../../PlantInformation/PlantConfigHttpServices";
import classes from "./PlantManagement.module.css";
import { useCreate_Plant } from "./PlantManagementHttpServices";

function AddAndEditPlant({ edit }: { edit: boolean }) {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { showError, setShowError } = useContext(Store);

	const { plant, plantImage, setPlantImage } = useOutletContext<{
		plant: PlantConfigData;
		plantImage: string;
		setPlantImage: React.Dispatch<React.SetStateAction<string | undefined>>;
	}>();

	const [img, setImg] = useState<string | ArrayBuffer | null | undefined>("");
	const [frequency, setFrequency] = useState<string>('0');
	const [lastOutage, setLastOutage] = useState<string>('');
	const [nextPlannedOutage, setNextPlannedOutage] = useState<string>('');

	const formRef = useRef<HTMLFormElement>(null);
	const [isValidForm, setIsValidForm] = useState<boolean>(false);

	useEffect(() => {
		if (plant) {
			setFrequency(plant.frequency);
			if (plant.lastOutage) {
				setLastOutage(moment(plant.lastOutage, 'MM/DD/YYYY').format('YYYY-MM-DD'));
			}
			if (plant.nextPlannedOutage) {
				setNextPlannedOutage(moment(plant.nextPlannedOutage, 'MM/DD/YYYY').format('YYYY-MM-DD'));
			}
		}
	}, [plant]);

	const reportFormValidity = useCallback(() => {
		let isValid = false;
		if (formRef.current) {
			isValid = formRef.current.checkValidity();
		}
		setIsValidForm(isValid);
	}, [plant, formRef]);

	if (plantImage && !img) setImg(plantImage);

	const setErrorData = useOnError();

	const { mutate: addPlant } = useCreate_Plant();
	const { mutate: updatePlant } = useUpdate_PlantConfigData();

	const handleSubmit = (e: any) => {
		e.preventDefault();
		const {
			target: {
				elements
			},
		} = e;

		const {
			baseOrgName: { value: baseOrgName }, // required
			operators: { value: operators }, // required
			websiteURL: { value: websiteURL }, // required
			owner: { value: owner }, // required
			address1: { value: address1 }, // required
			address2: { value: address2 },
			address3: { value: address3 },
			frequency: { value: frequency },
			mhiRegion: { value: mhiRegion }, // required
			notesComments: { value: notesComments },
			file: {
				files: [file], // required
			}
		} = elements;

		if (plant) {
			updatePlant(
				{
					plantId: plant.plantId,
					baseOrgId: plant.baseOrgId,
					baseOrgName,
					operators,
					websiteURL,
					owner,
					parentGroup: "",
					address1,
					address2,
					address3,
					mainPhone: "",
					frequency,
					mhiRegion,
					notesComments,
					lastOutage: dayjs(lastOutage).isValid()? dayjs(lastOutage).format('MM/DD/YYYY') : lastOutage,
					nextPlannedOutage: dayjs(nextPlannedOutage).isValid()? dayjs(nextPlannedOutage).format('MM/DD/YYYY') : nextPlannedOutage,
					file,
				} as PlantConfigData,
				{
					onSuccess: () => {
						queryClient.invalidateQueries([queryKeys.plantConfigImage]);
						queryClient.invalidateQueries([queryKeys.plantConfig, {plantId: plant.plantId}]);
						queryClient.invalidateQueries([queryKeys.plantSiteDetails]);
						navigate("..");
					},
					onError: (error: unknown) => {
						var err = (error as AxiosError);
						if (setShowError && err) {
							setShowError({
								title: `${(error as AxiosError).response?.data}`,
								isError: true,
								ErrorType: "danger"
							});
						}
						else {
							setErrorData(error);
						}
					},
				}
			);
		} else {
			const formData = new FormData();
			formData.append("BaseOrgName", `${baseOrgName}`);
			formData.append("Operators", `${operators}`);
			formData.append("WebsiteURL", `${websiteURL}`);
			formData.append("Owner", `${owner}`);
			formData.append("ParentGroup", `${"--"}`);
			formData.append("Address1", `${address1}`);
			formData.append("Address2", `${address2}`);
			formData.append("Address3", `${address3}`);
			formData.append("MainPhone", `${"--"}`);
			formData.append("Frequency", `${frequency}`);
			formData.append("MHIRegion", `${mhiRegion}`);
			formData.append("NotesComments", `${notesComments}`);
			formData.append("LastOutage", `${dayjs(lastOutage).isValid()? dayjs(lastOutage).format('MM/DD/YYYY') : lastOutage}`);
			formData.append("NextPlannedOutage", `${dayjs(nextPlannedOutage).isValid()? dayjs(nextPlannedOutage).format('MM/DD/YYYY') : nextPlannedOutage}`);
			formData.append("File", file, file.name);

			addPlant(formData, {
				onSuccess(result) {
					queryClient.invalidateQueries([queryKeys.plantConfigImage]);
					queryClient.invalidateQueries([queryKeys.plantConfig]);
					queryClient.invalidateQueries([queryKeys.plantSiteDetails]);
					navigate({
						pathname: "../saved",
						search: `?plantName=${result.data.baseOrgName}&plantId=${result.data.plantId}`,
					});
				},
				onError(error) {
					var err = (error as AxiosError);
					if (setShowError && err) {
						setShowError({
							title: `${(error as AxiosError).response?.data}`,
							isError: true,
							ErrorType: "danger"
						});
					}
					else {
						setErrorData(error);
					}
				},
			});
		}
	};

	return (
		<ModalGlobal modaltitle={edit ? `Edit Plant Details for ${plant.baseOrgName}` : "Add Plant"} isModalHeader={true} modalClose={() => navigate("../")} modalSize="md">
			<div style={{ textAlign: "left" }}>
				<h5>Plant Details</h5>
				{showError.isError && <ErrorCard ErrorMessage={showError.title} ErrorType={"danger"} />}
				<p>Once a plant is added, its details can be managed anytime from the Plant Management page.</p>
				<form ref={formRef} className={classes.addEditForm} onSubmit={handleSubmit} onChange={reportFormValidity}>
					<div className={`${tableClasses.table} ${classes.table}`}>
						<div></div>
						<div>
							<div>
								<div>Plant Name*</div>
								<div>
									<input placeholder="Plant Name" name="baseOrgName" defaultValue={plant?.baseOrgName} required />
								</div>
							</div>
							<div>
								<div style={{ verticalAlign: "top", paddingTop: 12 }}>Plant Photo*</div>
								<div className={`${classes.filecontainer}`}>
									{img && (
										<div>
											<img src={img.toString()} style={{ height: 116 }} alt=""></img>
										</div>
									)}
									<div>
										<label>
											<span>{img ? "Change Photo" : "Add Plant Photo"}</span>
											<input
												type="file"
												accept=".jpg, .png, .jpeg, .gif, .bmp"
												name="file"
												required={!plant}
												style={{ opacity: 0 }}
												onChange={({ target: { files } }) => {
													if (files?.[0]) {
														const reader = new FileReader();
														reader.onload = (e) => setImg(e.target?.result);
														reader.readAsDataURL(files[0]);
													}
												}}
											/>
										</label>
									</div>
								</div>
							</div>
							<div>
								<div>Owner*</div>
								<div>
									<input placeholder="Owner" name="owner" defaultValue={plant?.owner} required />
								</div>
							</div>
							<div>
								<div>Operator*</div>
								<div>
									<input placeholder="Operator" name="operators" defaultValue={plant?.operators} required />
								</div>
							</div>
							<div>
								<div>Address*</div>
								<div>
									<input placeholder="Street Address" name="address1" defaultValue={plant?.address1} required />
								</div>
							</div>
							<div>
								<div></div>
								<div>
									<input placeholder="City, State Zip" name="address2" defaultValue={plant?.address2} />
								</div>
							</div>
							<div>
								<div></div>
								<div>
									<input placeholder="Country" name="address3" defaultValue={plant?.address3} />
								</div>
							</div>
							{false && (
								<div>
									<div>Phone*</div>
									<div>
										<input placeholder="Phone" name="mainPhone" defaultValue={plant?.mainPhone} required />
									</div>
								</div>
							)}
							<div>
								<div>Region*</div>
								<div>
									<input placeholder="Region" name="mhiRegion" defaultValue={plant?.mhiRegion} required />
								</div>
							</div>
							<div>
								<div>Last Planned Outage</div>
								<div className={classes.hasHelperText}>
									<DatePicker
										placeholderText="MM/DD/YYYY"
										selected={(lastOutage && dayjs(lastOutage).isValid())? dayjs(lastOutage).toDate() : null}
										dateFormat="MM/dd/yyyy"
										onChange={(val) => {
											if (val) {
												setLastOutage(dayjs(val).format('MM/DD/YYYY'));
											}
											reportFormValidity();
										}}
									/>
									<small>Program manager can provide last planned outage information</small>
								</div>
							</div>
							<div>
								<div>Next Planned Outage</div>
								<div className={classes.hasHelperText}>
									<DatePicker
										placeholderText="MM/DD/YYYY"
										selected={(nextPlannedOutage && dayjs(nextPlannedOutage).isValid())? dayjs(nextPlannedOutage).toDate() : null}
										dateFormat="MM/dd/yyyy"
										onChange={(val) => {
											if (val) {
												setNextPlannedOutage(dayjs(val).format('MM/DD/YYYY'));
											}
											reportFormValidity();
										}}
									/>
									<small>Program manager can provide next planned outage information</small>
								</div>
							</div>
							<div>
								<div>Notes</div>
								<div>
									<input placeholder="Notes" name="notesComments" defaultValue={plant?.notesComments} />
								</div>
							</div>
							<div>
								<div>Frequency</div>
								<div>
									{/* <input placeholder="Frequency" name="frequency" defaultValue={plant?.frequency} /> */}
									<input type="hidden" name="frequency" defaultValue={frequency} />
									<PlainDropDown selectClass="flex flex-row items-center mt-2 py-2 pr-2 text-sm justify-between rounded w-full hover:cursor-pointer"
													optionsClass="flex flex-col p-1 bg-white rounded max-h-80"
													dropdownStyles={{overflow: 'auto'}}
													itemClass="py-[14px] px-6 cursor-pointer hover:bg-primary-20"
													options={[
														{id: 0, name: 'Select Frequency'},
														{id: 50, name: '50 Hz'},
														{id: 60, name: '60 Hz'},
													]}
													onSelection={item => {
														setFrequency(`${item.id}`);
														reportFormValidity();
													}}
													value={Number(frequency)}
													defaultText="Select Frequency" />
								</div>
							</div>
							<div>
								<div>Website URL*</div>
								<div className={classes.hasHelperText}>
									<input placeholder="Website URL" name="websiteURL" type="url" defaultValue={plant?.websiteURL} pattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)" required />
									<small>URL must include http:// or https://</small>
								</div>
							</div>
						</div>
					</div>
					<div style={{ marginTop: 40, display: "flex", justifyContent: "space-between" }} className={classes.flex_row}>
						<div style={{ display: "flex", flexWrap: "nowrap" }}>
							<button type="submit" className="primaryBtn darkBlue" disabled={!isValidForm}>{`${edit ? "Save Changes" : "Add Plant"}`}</button>&nbsp;&nbsp;&nbsp;
							<Button className={`whiteBtn`} disabled={false} text={"Cancel"} onClick={() => navigate("../")} />
						</div>
						<div style={{ fontSize: 14 }}>*Required fields</div>
					</div>
				</form>
			</div>
		</ModalGlobal>
	);
}

export default AddAndEditPlant;
