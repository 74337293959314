import React, { useCallback, useContext, useMemo } from 'react';
import { Link, generatePath, useLocation } from 'react-router-dom';
import { Store } from '../../Store';
import './Sidebar.css';

import { useIsInternalAdmin } from '../../customHooks/useIsInternalAdmin';
import { useIsInternalOrExternal } from '../../customHooks/useIsInternalOrExternal';
import ImageImports from "../../utils/ImageImports";

const { documentSearch, partsNservices, documentWarn, plant, home, support, openClose, privacy_icon, Arrow } = ImageImports;
;
interface sidebarRouteObj {
  id: string,
  title: string,
  icon: string,
  subRoutes: Array<{ route: string, title: string, requiresAdmin?: boolean, requiresInternal?: boolean }>,
  requiresAdmin?: boolean,
  highlightIfRouteMatches: RegExp,
};

const sidebarRoutes: sidebarRouteObj[] = [
  {
    id: "home",
    title: "Home",
    icon: home,
    subRoutes: [{ route: "/", title: "Home" }],
    highlightIfRouteMatches: /^\/(home)?$/
  },
  {
    id: "plantSite",
    title: "Plant Information",
    icon: plant,
    subRoutes: [{ route: '/plant-information', title: "Plant Information" }],
    highlightIfRouteMatches: /^\/plant-information/,
  },
  {
    id: "issueReports",
    title: "Issue Reports",
    icon: documentWarn,
    subRoutes: [{ route: "/issue-reports", title: "Issue Reports" }],
    highlightIfRouteMatches: /^\/issue-reports/
  },
  {
    id: "partNServ",
    title: "Parts & Service",
    icon: partsNservices,
    subRoutes: [
      // { route: generatePath('/parts&service/:moduleName', {moduleName: 'Parts & Service'}), title: "Parts & Service"},
      { route: generatePath('/parts&service/:moduleName', { moduleName: 'QuoteRequests' }), title: "Quote Requests" },
      { route: generatePath('/parts&service/:moduleName', { moduleName: 'Parts Catalog' }), title: "Parts Catalog" },
      { route: generatePath('/parts&service/:param', {param: 'customer-survey'}), title: "Voice of Customer Responses", requiresInternal: true },
    ],
    highlightIfRouteMatches: /^\/parts&service\//
  },
  {
    id: "techDoc",
    title: "Technical Documentation",
    icon: documentSearch,
    subRoutes: [
      { route: generatePath('/technical-documentation/:moduleName', { moduleName: 'Mitsubishi Power Reports' }), title: "Mitsubishi Power Reports" },
      { route: generatePath('/technical-documentation/:moduleName', { moduleName: 'Technical Bulletins' }), title: "Technical Bulletins" },
      { route: generatePath('/technical-documentation/:moduleName', { moduleName: 'O&M Manuals' }), title: "O&M Manuals" },
    ],
    highlightIfRouteMatches: /^\/technical-documentation\//
  },
  {
    id: "support",
    title: "Support",
    icon: support,
    subRoutes: [
      { route: generatePath('/support/esr/:supportModuleName', { supportModuleName: "Engineering Support Requests" }), title: "Engineering Support Requests" },
      { route: generatePath('/support/faq/:supportModuleName', { supportModuleName: "Frequently Asked Questions" }), title: "Frequently Asked Questions" },
    ],
    highlightIfRouteMatches: /^\/support\//
  },
  {
    id: "administration",
    title: "Administration",
    icon: privacy_icon,
    subRoutes: [
      // {route: generatePath('/support/:supportModuleName', {supportModuleName:"Support"}), title: "Support"},
      { route: '/administration/organization-management', title: "Organization Management" },
      { route: generatePath('/administration/:param', { param: "Plant-Management" }), title: "Plant Management" },
      { route: generatePath('/administration/:param', { param: "Role-Configuration" }), title: "Role Configuration" },
      { route: generatePath('/administration/:param', { param: "Email-Alert-&-Notification-Management" }), title: "Email Alert & Notification Management" },
      { route: generatePath('/administration/:param', { param: "user-management" }), title: "User Management" },
      { route: generatePath('/administration/:param', { param: "Data-Reporting" }), title: "Data & Reporting" },
    ],
    requiresAdmin: true,
    highlightIfRouteMatches: /^\/administration\//
  }
];

function Sidebar() {
  const stateContext = useContext(Store);
  const { sidebarToggle: isSidebarOpen, toggleSidebar } = stateContext;
  
  
  const isInternalAdmin = useIsInternalAdmin();
  const adminFilter = useCallback(({requiresAdmin}: {requiresAdmin?: boolean}): boolean => {
    if (requiresAdmin === undefined || requiresAdmin === false) {
      return true;
    }
    return isInternalAdmin;
  }, [isInternalAdmin]);

  const isInternalOrExternal = useIsInternalOrExternal();
  const internalFilter = useCallback(({requiresInternal}: {requiresInternal?: boolean}): boolean => {
    if (requiresInternal === undefined || requiresInternal === false) {
      return true;
    }
    return isInternalOrExternal;
  }, [isInternalOrExternal]);
  

  return (
    <div className='sidebar-wrapper'>
      <div className={isSidebarOpen ? "sidebar active" : "sidebar"}>
        <nav className={`sidebar-menu ${isSidebarOpen ? "active" : ""}`}>
          <ul className='sidebar-menu-items'>
            <li className={`sidebar-item ${isSidebarOpen? 'active' : ''}`}
                onClick={() => {
                  toggleSidebar && toggleSidebar(!isSidebarOpen);
                }}
              >
                <Link to="#" className='menu-toggle open-close a-close'>
                  <img src={openClose} className={`sidebar-icon ${isSidebarOpen? 'flip' : ''}`} alt="" />
                  {isSidebarOpen? <span className='toggle-text'>Close</span> : undefined}
                </Link>
              </li>
            {sidebarRoutes.filter(adminFilter).map((route: sidebarRouteObj) => isSidebarOpen? <RouteMenuItemOpen key={route.id} route={route} adminFilter={adminFilter} internalFilter={internalFilter} /> : <RouteMenuItemClosed key={route.id} route={route} adminFilter={adminFilter} internalFilter={internalFilter} />)}
          </ul>
        </nav>
      </div>

    </div>
  );
}

export default Sidebar;


const RouteMenuItemOpen = ({route, adminFilter, internalFilter}: {route: sidebarRouteObj, adminFilter: (route: {requiresAdmin?: boolean}) => boolean, internalFilter: (route: {requiresInternal?: boolean}) => boolean}) => {
  const {pathname} = useLocation();
  const stateContext = useContext(Store);
  const { toggleSidebar } = stateContext;
  const matched = route.highlightIfRouteMatches.test(pathname);

  const subRoutes = useMemo(() => {
    return route.subRoutes.filter(adminFilter).filter(internalFilter);
  }, [route.subRoutes, adminFilter, internalFilter]);

  return (
    <li key={route.id}
        className={`flex flex-row items-center expanded sidebar-item ${matched ? " active-location noHover" : ''} overflow-hidden ${subRoutes.length === 1? '' : 'has-sub-routes'}`}
        tabIndex={1}>
      {subRoutes.length === 1? (
        <>
        <Link to={subRoutes[0].route} className="text-white flex flex-row items-center whitespace-nowrap no-underline" onClick={() => toggleSidebar?.(false)}>
          <img src={route.icon}
              className={`sidebar-icon ${matched ? "active-location-icon" : ""}`} alt={route.title} />
          <span className={`sidebar-menu-txt inline-flex flex-row justify-between items-center active ${matched ? "active-text" : "text"}`}>
            {route.title}
          </span>
        </Link>
        </>
      ) : (
        <>
          <img src={route.icon}
              className={`sidebar-icon ${matched ? "active-location-icon" : ""}`} alt={route.title} />
          <span className={`sidebar-menu-txt flex flex-row justify-between items-center active ${matched ? "active-text" : "text"}`}>
            {route.title}
            <img src={Arrow} alt="open" style={{filter: matched? 'var(--svgFilterWhite)' : 'var(--svgFilterGrey)'}} />
          </span>
          <div className="sub-route-wrapper active">
            <div className="inner-wrapper">
              {subRoutes.map((subRoute, i: number) => {
                return (
                  <div className="" key={i}>
                    <Link to={subRoute.route} className="sub-route"
                      onClick={(e) => {
                        if (toggleSidebar) {
                          toggleSidebar(false);
                        }
                        // This will hide the menu when you click on a link
                        const el = e.currentTarget.closest<HTMLDivElement>('.sub-route-wrapper');
                        if (el) {
                          el.style.display = 'none';
                          setTimeout(() => {
                            el.style.display = '';
                          }, 0);
                        }
                      }}
                    >
                      {subRoute.title}
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )}
    </li>
  );
};

const RouteMenuItemClosed = ({route, adminFilter, internalFilter}: {route: sidebarRouteObj, adminFilter: (route: {requiresAdmin?: boolean}) => boolean, internalFilter: (route: {requiresInternal?: boolean}) => boolean}) => {
  const {pathname} = useLocation();
  const stateContext = useContext(Store);
  const { toggleSidebar } = stateContext;
  const matched = route.highlightIfRouteMatches.test(pathname);

  return (
    <li key={route.id}
        className={`flex flex-row items-center sidebar-item ${matched ? "active-location noHover" : ''}`}
        tabIndex={1}>
      <img src={route.icon}
           className={matched ? "sidebar-icon active-location-icon" : "sidebar-icon"} alt={route.title} />
      <div className="sub-route-wrapper">
        <div className="inner-wrapper">
          {route.subRoutes.length > 1 && (
            <div className="">
              <div className="sidebar-parent-nonroute">
                {route.title}
              </div>
            </div>
          )}

          {route.subRoutes.filter(adminFilter).filter(internalFilter).map((subRoute, i: number) => {

            return (
              <div className="" key={i}>
                <Link to={subRoute.route} className="sub-route"
                  onClick={(e) => {
                    if (toggleSidebar) {
                      toggleSidebar(false);
                    }
                    // This will hide the menu when you click on a link
                    const el = e.currentTarget.closest<HTMLDivElement>('.sub-route-wrapper');
                    if (el) {
                      el.style.display = 'none';
                      setTimeout(() => {
                        el.style.display = '';
                      }, 0);
                    }
                  }}
                >
                  {subRoute.title}
                </Link>
              </div>
            )
          })}
        </div>
      </div>
    </li>
  );
};