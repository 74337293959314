import { ChangeEventHandler } from "react";

import ImageImports from "../../../../utils/ImageImports";
const { attachment } = ImageImports;

interface IToolbarImageUploadProps {
    addFile?: (attachment: File) => void;
}

const ToolbarImageUpload = ({ addFile }: IToolbarImageUploadProps) => {
    const attachFile: ChangeEventHandler<HTMLInputElement> = (e) => {
        const el = e.currentTarget;
        if (el.files) {
            const item = el.files.item(0);
            if (item) {
                addFile && addFile(item);
            }
        }
    }

    return (<>
        <button onClick={() => document.getElementById('inputFile')?.click()} className={`toolbar-item`} aria-label="Upload File">
            <img src={attachment} alt="Upload File" className={"icon"} style={{ height: '80%' }} />
            <input id='inputFile' type="file" style={{ display: "none" }} onChange={attachFile} />
        </button>
    </>
    );
};

export default ToolbarImageUpload;
