import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { MutationFunction, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "react-query";
import {
  apiGetAllEquipmentsByPlantID,
  apiMitsubishiPowerReport,
  apiPlantEquipment,
  apiReportDownloadFile,
  apiSiteUnits,
  technicalBulletinApiUrl,
  omManualApiUrl,
  apiPartsCatalog,
  technicalBulletinShareWithUG,
} from "../../endpoints/endpoints";
import { queryKeys } from "../../react-query/queryKeys";
import { Store } from "../../Store";
import { CPApiResponse, PlantEquipmentsDATA, Report, SiteUnit } from "../../utils/interface";

function handleAxiosResponse<T = any>(response: AxiosResponse<CPApiResponse<T>>): T {
  if (response.status >= 400) throw response.statusText;
  if (response.data.success !== true) throw response.data.message;
  return response.data.data;
}

const fetchPlantSiteUnits = async (plantId: number): Promise<SiteUnit[]> => {
  const response: AxiosResponse<CPApiResponse<SiteUnit[]>> = await axios(`${apiSiteUnits}/GetAllByPlantId?PlantId=${plantId}`);
  return handleAxiosResponse(response);
};

export const usePlantSiteUnits = (options: Omit<UseQueryOptions<SiteUnit[]>, "queryKey" | "queryFn"> = {}) => {
  const { selectedPlant } = useContext(Store);
  const plantId = selectedPlant.plantId;
  return useQuery<SiteUnit[]>(["plantSiteUnits", plantId], () => fetchPlantSiteUnits(plantId), {
    ...options,
  });
};

const fetchPlantSiteUnitEquipment = async (unitId: number): Promise<PlantEquipmentsDATA[]> => {
  const response: AxiosResponse<CPApiResponse<PlantEquipmentsDATA[]>> = await axios(`${apiPlantEquipment}/GetAllByUnitId?UnitId=${unitId}`);
  return handleAxiosResponse(response);
};

export const usePlantSiteUnitEquipment = (unitId: number, options: Omit<UseQueryOptions<PlantEquipmentsDATA[]>, "queryKey" | "queryFn"> = {}) => {
  return useQuery<PlantEquipmentsDATA[]>(["plantSiteUnitEquipments", unitId], () => fetchPlantSiteUnitEquipment(unitId), options);
};
// mitsubishi Power Reports  Create New | Use Create New -  Update  | Use Create Update
const createNewMitsubishiPowerReport: MutationFunction<Report, FormData> = async (formData: FormData): Promise<Report> => {
  const response: AxiosResponse<CPApiResponse<Report>> = await axios.post(apiMitsubishiPowerReport, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return handleAxiosResponse(response);
};
export const useCreateNewMitsubishiPowerReport = (
  options: Omit<UseMutationOptions<Report, unknown, FormData, unknown>, "mutationKey" | "mutationFn"> = {}
) => {
  return useMutation("", createNewMitsubishiPowerReport, options);
};

const updateMitsubishiPowerReport: MutationFunction<Report, [number, FormData]> = async ([globalId, formData]): Promise<Report> => {
  const response: AxiosResponse<CPApiResponse<Report & { displayName: string }>> = await axios.put(
    `${apiMitsubishiPowerReport}/${globalId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  const data = handleAxiosResponse(response);
  data.displayFileName = data.displayName;
  return data;
};
export const useUpdateMitsubishiPowerReport = (
  options: Omit<UseMutationOptions<Report, unknown, [number, FormData], unknown>, "mutationKey" | "mutationFn"> = {}
) => {
  return useMutation("", updateMitsubishiPowerReport, options);
};

// Create New OmmManual     Create New | Use Create New -  Update |  Use update
const createNewOMManual: MutationFunction<Report, FormData> = async (formData: FormData): Promise<Report> => {
  const response: AxiosResponse<CPApiResponse<Report>> = await axios.post(omManualApiUrl, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return handleAxiosResponse(response);
};
export const useCreateNewOMManual = (options: Omit<UseMutationOptions<Report, unknown, FormData, unknown>, "mutationKey" | "mutationFn"> = {}) => {
  return useMutation("", createNewOMManual, options);
};

const updateOMManual: MutationFunction<Report, [number, FormData]> = async ([globalId, formData]): Promise<Report> => {
  const response: AxiosResponse<CPApiResponse<Report & { displayName: string }>> = await axios.put(`${omManualApiUrl}/${globalId}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  const data = handleAxiosResponse(response);
  data.displayFileName = data.displayName;
  return data;
};
export const useUpdateOMManual = (
  options: Omit<UseMutationOptions<Report, unknown, [number, FormData], unknown>, "mutationKey" | "mutationFn"> = {}
) => {
  return useMutation("", updateOMManual, options);
};

// Parts Catalog (moduleID = 4) section   Create new | Use Create New  -  update  |  Use update

const createNewPartsCatalog: MutationFunction<Report, FormData> = async (formData: FormData): Promise<Report> => {
  const response: AxiosResponse<CPApiResponse<Report>> = await axios.post(apiPartsCatalog, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return handleAxiosResponse(response);
};
export const useNewPartsCatalog = (options: Omit<UseMutationOptions<Report, unknown, FormData, unknown>, "mutationKey" | "mutationFn"> = {}) => {
  return useMutation("", createNewPartsCatalog, options);
};

const updateNewPartsCatalog: MutationFunction<Report, [number, FormData]> = async ([globalId, formData]): Promise<Report> => {
  const response: AxiosResponse<CPApiResponse<Report & { displayName: string }>> = await axios.put(`${apiPartsCatalog}/${globalId}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  const data = handleAxiosResponse(response);
  data.displayFileName = data.displayName;
  return data;
};
export const useUpdateNewPartsCatalog = (
  options: Omit<UseMutationOptions<Report, unknown, [number, FormData], unknown>, "mutationKey" | "mutationFn"> = {}
) => {
  return useMutation("", updateNewPartsCatalog, options);
};

// Get units by Equipment section
const getUnitIdsByEquipmentIds = async (equipmentIds: number[], plantId: number): Promise<number[]> => {
  const response: AxiosResponse<CPApiResponse<PlantEquipmentsDATA[]>> = await axios(`${apiGetAllEquipmentsByPlantID}?PlantId=${plantId}`);
  const plantEquipment = handleAxiosResponse(response);
  return plantEquipment
    .filter((d) => equipmentIds.includes(d.plantEquipmentId))
    .map((d) => d.unitID)
    .filter((v: number, i: number, a: number[]) => a.indexOf(v) === i);
};

export const useUnitsOfEquipmentIds = (
  equipmentIds: number[],
  options: Omit<UseQueryOptions<number[]>, "queryKey" | "queryFn"> = { initialData: [] }
) => {
  const { selectedPlant } = useContext(Store);
  const { plantId } = selectedPlant;
  return useQuery<number[]>(["plantEquipments", plantId, ...equipmentIds.sort()], () => getUnitIdsByEquipmentIds(equipmentIds, plantId), options);
};

// TBReports Crud Method Section
export interface TBReportCrudMethods<T, TD, R> {
  update: (data: T) => Promise<R>;
  create: (data: TD) => Promise<R>;
  getFile: (globalItemId: number) => Promise<Blob>;
  shareWithUG: (globalItemId: number) => Promise<string>;
}

export const TBReportAPI: TBReportCrudMethods<[number, FormData], FormData, Report> = {
  // --------------------------------------------------------------------------//

  update: async (data: [number, FormData]): Promise<Report> => {
    const [globalItemId, formdata] = data;
    const response = await axios({
      url: `${technicalBulletinApiUrl}/${globalItemId}`,
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formdata,
    });
    return handleAxiosResponse(response);
  },

  // --------------------------------------------------------------------------//

  create: async (data: FormData): Promise<Report> => {
    const response = await axios({
      url: `${technicalBulletinApiUrl}`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
    return handleAxiosResponse(response);
  },

  // --------------------------------------------------------------------------//

  getFile: async (globalItemId: number): Promise<Blob> => {
    const response = await axios({
      url: `${apiReportDownloadFile}/${globalItemId}`,
      method: "GET",
      responseType: "blob",
    });
    return response.data;
  },

  // --------------------------------------------------------------------------//

  shareWithUG: async (globalItemId: number): Promise<string> => {
    const response = await axios({
      url: `${technicalBulletinShareWithUG}/${globalItemId}`,
      method: "POST"
    });
    return response.data;
  },
};

// --------------------------------------------------------------------------//

export const useUpdate_TBReport = () => {
  return useMutation(TBReportAPI.update);
};

export const useGetFile_TBReport = (globalItemId: number, options: Omit<UseQueryOptions<Blob>, "queryKey" | "queryFn">) => {
  return useQuery([queryKeys.File, globalItemId], () => TBReportAPI.getFile(globalItemId), options);
};

export const useCreate_TBReport = () => {
  return useMutation(TBReportAPI.create);
};
export const useShareWithUG_TBReport = () => {
  return useMutation(TBReportAPI.shareWithUG);
};

// --------------------------------------------------------------------------//
