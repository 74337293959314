import { CPApiResponse, NotificationTrigger } from "../../../../utils/interface";
import { emailAlertTriggerApiUrl, notificationTriggerApiUrl } from "../../../../endpoints/endpoints";

import axios, { AxiosResponse } from "axios";
import { useMutation, useQuery, UseQueryOptions } from "react-query";
import { handleAxiosResponse } from "../../../../utils/handleAxiosResponse";

interface NotificationManagementCrudMethods<T1, T2, T3, T4, T5> {
	queryNotificationTriggers: () => Promise<T1>;
	updateNotificationTriggers: (notificationTriggers: Array<NotificationTrigger>) => Promise<T5>;
	queryEmailAlertTriggers: () => Promise<T3>;
	updateEmailAlertTriggers: (notificationTriggers: Array<NotificationTrigger>) => Promise<T5>;
	getNotificationTriggers: () => Promise<T5>;
	getEmailTriggers: () => Promise<T5>;
	
}

export const NotificationManagementApi: NotificationManagementCrudMethods<
	AxiosResponse<CPApiResponse<Array<NotificationTrigger>>>,
	AxiosResponse<CPApiResponse<Array<NotificationTrigger>>>,
	AxiosResponse<CPApiResponse<Array<NotificationTrigger>>>,
	AxiosResponse<CPApiResponse<Array<NotificationTrigger>>>,
	CPApiResponse<Array<NotificationTrigger>>
> = {
	queryNotificationTriggers: async (): Promise<AxiosResponse<CPApiResponse<Array<NotificationTrigger>>>> => {
		return await axios({
			url: `${notificationTriggerApiUrl}/GetAllNotificationTriggers`,
			method: "GET",
		});
	},
	getNotificationTriggers: async (): Promise<CPApiResponse<Array<NotificationTrigger>>> => {
		const response = await axios({
			url: `${notificationTriggerApiUrl}/GetAllNotificationTriggers`,
		  	method: "GET",
		});
			
		return response.data;
	},
	getEmailTriggers: async (): Promise<CPApiResponse<Array<NotificationTrigger>>> => {
		const response = await axios({
			url: `${emailAlertTriggerApiUrl}/GetAllEmailTriggers`,
		  	method: "GET",
		});
			
		return response.data;
	},
	updateNotificationTriggers: async (notificationTriggers: Array<NotificationTrigger>): Promise<CPApiResponse<Array<NotificationTrigger>>> => {
		const response = await axios({
			url: `${notificationTriggerApiUrl}/UpdateAllNotificationTriggers`,
			method: "PUT",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			data: notificationTriggers,
		});

		return response.data;
	},
	queryEmailAlertTriggers: async (): Promise<AxiosResponse<CPApiResponse<Array<NotificationTrigger>>>> => {
		return await axios({
			url: `${emailAlertTriggerApiUrl}/GetAllEmailTriggers`,
			method: "GET",
		});
	},
	updateEmailAlertTriggers: async (notificationTriggers: Array<NotificationTrigger>): Promise<CPApiResponse<Array<NotificationTrigger>>> => {
		const response =  await axios({
			url: `${emailAlertTriggerApiUrl}/UpdateAllEmailTriggers`,
			method: "PUT",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			data: notificationTriggers,
		});

		return response.data;
	},
};



export const useUpdateEmailAlertTriggers = () => {
    return useMutation(NotificationManagementApi.updateEmailAlertTriggers);
};

export const useUpdateNotificationTriggers = () => {
    return useMutation(NotificationManagementApi.updateNotificationTriggers);
};

export const useGetNotificationTriggers = (
	options: Omit<UseQueryOptions<CPApiResponse<Array<NotificationTrigger>>>, "queryKey" | "queryFn"> = {}
) => {
	return useQuery<CPApiResponse<Array<NotificationTrigger>>>(['getAllNotificationTriggers'], () => NotificationManagementApi.getNotificationTriggers(), {
		...options,
	});
};

export const useGetEmailTriggers = (
	options: Omit<UseQueryOptions<CPApiResponse<Array<NotificationTrigger>>>, "queryKey" | "queryFn"> = {}
) => {
	return useQuery<CPApiResponse<Array<NotificationTrigger>>>(['getAllEmailTriggers'], () => NotificationManagementApi.getEmailTriggers(), {
		...options,
	});
};
