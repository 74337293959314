import { useCallback, useState, useMemo } from "react";
import { useGetRoles } from "../../customHooks/UserHttpServices";
import { Role } from "../../utils/interface";
import { MainContainer } from "../MainContainer";
import { useCreateRole, useDeleteRole, useReorderRoles, useUpdateRole } from "../../customHooks/RoleConfigurationHttpServices";
import { ManageRoles } from "../../components/ManageRoles/ManageRoles";
import { Link } from "react-router-dom";
import EditDeleteRoleSuccessModal from "../../components/ManageRoles/EditDeleteRoleSuccessModal";
import { usePageRequiresAdmin } from "../../customHooks/usePageRequiresAdmin";

type ModalType = "edit" | "delete" | "";
export const ManageRolesContainer = () => {
    usePageRequiresAdmin();
    const [roleData, setRoleData] = useState<Role[]>([]);
    const [showEditDeleteRoleSuccessModal, setShowEditDeleteRoleSuccessModal] = useState<Role|null>(null);
    const [successModalType, setSuccessModalType] = useState<ModalType>('');

    const {mutate: createRole} = useCreateRole({
        onSuccess: () => getRoles()
    });
    const {mutate: updateRole} = useUpdateRole({
        onSuccess: () => getRoles()
    });
    const {mutate: deleteRole} = useDeleteRole({
        onSuccess: () => getRoles()
    });

    const {mutate: reorderRoles} = useReorderRoles({
        onSuccess: () => getRoles()
    });

    const {data: roles, refetch: getRoles} = useGetRoles({
        onSuccess: roles => {
            setRoleData(roles.sort(function(a, b){return a.displayOrder - b.displayOrder}));
        }
    });

    const addNewRole = useCallback((name: string, mpInternal: boolean) => {
        createRole({name, mpInternal});
    }, []);

    const editRole = useCallback((role: Role) => {
        updateRole(role);
        setShowEditDeleteRoleSuccessModal(role);
        setSuccessModalType('edit');
    }, []);

    type ReorderParams = {id: number, displayOrder: number};
    
    const onReorder = useCallback((params: ReorderParams[]) => {
        reorderRoles(params);
    }, []);

    const handleDeleteRole = useCallback((role: Role) => {
        deleteRole(role.id);
        setShowEditDeleteRoleSuccessModal(role);
        setSuccessModalType('delete');
    }, []);

    const userManagementLink = useMemo(() => {
        return (
            <div className="flex flex-col">
                <div>A role cannot be deleted until all assignments within it have been re-assigned to another role.</div>
                <div>Individual user role assignments can be configured on the <Link to="/administration/user-management" className="no-underline text-[#00749E] hover:text-[#00749E] visited:text-[#00749E] hover:underline">User Management</Link> page.</div>
            </div>
        );
    }, []);

    return (
        <MainContainer title="Role Configuration" breadCrumbs={['Administration', 'Role Configuration']}>
            {showEditDeleteRoleSuccessModal && (
                <EditDeleteRoleSuccessModal
                    shown={true}
                    role={showEditDeleteRoleSuccessModal}
                    type={successModalType}
                    onClose={() => setShowEditDeleteRoleSuccessModal(null)}
                />
            )}
            {roleData && <ManageRoles addButtonText="Add Role"
                                       backLabel="Back to Customer Portal Role Definitions"
                                       items={roleData}
                                       onAdd={addNewRole}
                                       onDelete={handleDeleteRole}
                                       onEdit={editRole}
                                       onReorder={onReorder}
                                       pageDescription={userManagementLink}
                                       pageSubHeading="Manage Roles (All Plants)" />
            }
        </MainContainer>
    );
};