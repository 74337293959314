import React, { ReactElement } from 'react';
// import { Link } from 'react-router-dom';
import './SectionCard.css';

interface sectionCardProps {
  children: ReactElement
};

function SectionCard(props: sectionCardProps) {
  return (
    <div className="section-card">{props.children}</div>
  );
}

export default SectionCard;
