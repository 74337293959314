import { $generateHtmlFromNodes } from "@lexical/html";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { AxiosError } from "axios";
import { createEditor, $createTextNode } from "lexical";
import { useContext, useEffect, useState } from "react";
import { useIsFetching } from "react-query";
import { useQuery_FAQ } from "../../containers/Support/SupportHttpServices";
import useWindowSize from "../../customHooks/useWindowSize";
import { queryKeys } from "../../react-query/queryKeys";
import { Store } from "../../Store";
import { CloseAllModal } from "../../utils/CloseAllModal";
import ImageImports from "../../utils/ImageImports";
import { CPApiResponse, FaqInterfaceDTO } from "../../utils/interface";
import Button from "../Button/Button";
import FormSuccess from "../FormsPages/FormSuccess/FormSuccess";
import Loader from "../Loader/Loader";
import SearchField from "../UI/Form/SearchField/SearchField";
import ModalGlobal from "../UI/Modal/ModalGlobal";
import Editor from "../UI/RichTextEditor/Editor";
import Theme from "../UI/RichTextEditor/Theme";
import classes from "./Faq.module.css";
import FormFaq from "./SupportForms/FormFaq";

const Faq = () => {
  const { pencilWhite } = ImageImports;
  const {
    setFaq,
    faq,
    isModal,
    header: {
      data: {
        user: { portalAdmin },
      },
    },
    setIsModal,
  } = useContext(Store);
  const [searchText, setSearchText] = useState("");
  const [searchCount, setSearchCount] = useState(-1);
  const [displayText, setDisplayText] = useState("");
  const [initText, setInitText] = useState("");

  const { isMobile } = useWindowSize();
  const [editFaq, setEditFaq] = useState(false);
  const [isDefaultValue, setIsDefaultValue] = useState(false);

  const checkIfContent = (val: string) => {
    const tags = ["h1", "h2", "h3", "p", "ul", "ol", "div", "span"];
    tags.map((tag) => {
      const regex = new RegExp(`(<${tag}>)([^<]+)(</${tag}>)`, "g");
      const tagsInDoc = val.match(regex);
      if (!!tagsInDoc?.length) tagsInDoc.map((el) => setIsDefaultValue(!new DOMParser().parseFromString(el, "text/html")));
      else setIsDefaultValue(true);
    });
  };

  const isFetching = useIsFetching([queryKeys.getFaqApi]);

  useEffect(() => {
    if (searchText.length === 0) {
      setSearchCount(-1);
      let text = document.getElementById("FAQ_ReadOnly");
      if (text && text.innerHTML) {
        if (initText && initText !== text.innerHTML) {
          text.innerHTML = initText;
        }
      }
    } else {
      let text = document.getElementById("FAQ_ReadOnly");  
      
      if (initText && text && text.innerHTML) {
        const procText = getHighlightedText(initText, searchText, classes.Highlight);       
        if(procText){
          text.innerHTML = procText;
          setSearchCount((procText.match(/<\/mark>/gi) || []).length);
        }
        
      }
    }
  }, [searchText,initText]);

  const handleSearchText = (searchtext: string) => {
    setSearchText(searchtext);
  };

  const handleEditFaq = () => {
    setIsModal?.({
      ...isModal,
      isEdit: {
        ...isModal.isEdit,
        isFaq: true,
      },
    });
  };

  const readOnlyConfig = {
    namespace: "readonlyEditor",
    editable: false,
    theme: Theme,
    onError(error: any) {
      throw error;
    },
    nodes: [HeadingNode, ListNode, ListItemNode, QuoteNode, AutoLinkNode, LinkNode],
  };

  const editor = createEditor(readOnlyConfig);

  useEffect(() => {
    if (faq) {
      if (faq.faqText) {
        const contentJSON = JSON.parse(faq.faqText);
          editor.update(() => {
            const tempState = editor.parseEditorState(contentJSON);
            if (tempState !== undefined) {
              editor.setEditorState(tempState);
            }
          });

        const currentState = editor.getEditorState();
        currentState.read(() => {
          // this method is missing some stylings due to Lexical inprogress work, works for just grabbing text content
          const htmlString = $generateHtmlFromNodes(editor, null);
          const procText = getHighlightedText(htmlString, searchText, classes.Highlight);
          let text = document.getElementById("FAQ_ReadOnly");       
          if (text && !initText.length) {
            setInitText(text.innerHTML);
          }
          setDisplayText(procText);
          checkIfContent(htmlString);
        });
      }
    }
  }, [faq,displayText]);

  useEffect(() => {
    let text = document.getElementById("FAQ_ReadOnly");
    if (text && initText.length) {
      setInitText(text.innerHTML);
      setSearchCount(-1);
      setSearchText("");
    }
  }, [displayText]);

  const FaqSuccess = (responsedata: CPApiResponse<FaqInterfaceDTO>) => {
    if (responsedata?.data) {
      setFaq?.(responsedata?.data);
    }
  };

  const FaqError = (error: AxiosError<any>) => {};
  useQuery_FAQ(FaqSuccess, FaqError);

  const getHighlightedText = (text: string, highlight: string, highlightClass: string) => {
    if (!highlight.trim()) {
      return text;
    }

    highlight = highlight.trim();
    highlight = highlight.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");

    text = text.replaceAll("\\n", "").replaceAll("&nbsp;", "!!!");
    const tagRegExe = new RegExp(/>(.*?)</g);
    const highRegExe = new RegExp(highlight, "gi");
    var match = null;
    var startIndex = 0;
    var procText = "";
    var occurance = 0;

    while ((match = tagRegExe.exec(text))) {
      procText += text.substring(startIndex, match.index);
      var subMatch = null;
      var subStartIndex = 0;
      var subProcText = "";
      while ((subMatch = highRegExe.exec(match[0]))) {
        subProcText += match[0].substring(subStartIndex, subMatch.index);
        subProcText += "<mark class='" + highlightClass + "'>" + subMatch[0] + "</mark>";
        subStartIndex = subMatch[0].length + subMatch.index;
        occurance++;
      }

      if (subStartIndex < match[0].length) subProcText += match[0].substring(subStartIndex, match[0].length);

      procText += subProcText;
      startIndex = match[0].length + match.index;
    }
    if (startIndex < text.length) procText += text.substring(startIndex, text.length);

    return procText.replace(/!!!/g, "&nbsp;");
  };



  return (
    <>
      {isFetching > 0 ? (
        <Loader containerStyle={{ marginTop: "0" }} />
      ) : (
        <>
          <div className={`${isMobile ? classes.flex_column : classes.flex_row} ${classes.options}`}>
            {faq.faqText.length > 0 && !isDefaultValue ? (
              <div className={classes.search_keyword_container}>
                <SearchField value={searchText} placeholder="Search frequently asked questions" handleSearchText={handleSearchText} showClose={true} />
              </div>
            ) : (
              <></>
            )}
            {searchText.length > 0 ? (
              searchCount === 1 ? (
                <div className={classes.results}>
                  {searchCount} Search result for '{searchText}'
                </div>
              ) : (
                <div className={classes.results}>
                  {searchCount} Search results for '{searchText}'
                </div>
              )
            ) : (
              <></>
            )}
          </div>
          {faq.faqText.length > 0 ? (
            <div id="FAQ_ReadOnly">
              <Editor readonly content={faq?.faqText} />
            </div>
          ) : (
            <p className="p-0 m-0 pb-16 pt-8">No frequently asked questions to display.</p>
          )}

          {portalAdmin && <Button className={"darkBlue"} text={"Edit Content"} preImg={<img src={pencilWhite} style={{ marginRight: "10px" }} alt="" />} onClick={handleEditFaq}></Button>}
          {isModal.isEdit.isFaq && !isModal.isEditedSuccess.isFaq && (
            <ModalGlobal modalSize="RTE" modaltitle={"Edit Content"} isModalHeader={true} confirmBeforeClose={true} modalClose={() => setIsModal?.({ ...CloseAllModal })}>
              <FormFaq modalClose={() => setIsModal?.({ ...CloseAllModal })} />
            </ModalGlobal>
          )}
          {isModal.isEditedSuccess.isFaq && (
            <FormSuccess
              modaltitlename={"Changes Saved"}
              modaltitle={""}
              comment={"Content has been successfully updated."}
              isTitleCenter={true}
              modalSize={"sm"}
              button_done={true}
              button_addmore={false}
            />
          )}
        </>
      )}
    </>
  );
};

export default Faq;
