import React from "react";
import { PlantContact } from "../../../utils/interface";
import "./SiteContactAccordian.css";
import SiteContactAccordianPanel from "./SiteContactAccordianPanel/SiteContactAccordianPanel";

const SiteContactAccordian = ({
  panels,
  handleOpenEditContact,
}: SiteContactAccordianProps) => {
  
  return (
    <div className="accordions" role="tablist">
      {panels &&
        panels.map((panel, index) => (
          <SiteContactAccordianPanel
            key={panel.id}
            index={index + 1}
            handleOpenEditContact={handleOpenEditContact}
            {...panel}
          />
        ))}
    </div>
  );
};

interface SiteContactAccordianProps {
  panels:
    | {
        id: number;
        contact_type: string;
        contact_list: PlantContact[];
      }[]
    | null;
  handleOpenEditContact(id: number): void;
}

export default SiteContactAccordian;
