
import { FunctionComponent, HTMLProps } from 'react';
import classes from './Checkbox.module.css';
import ImageImports from "../../../../utils/ImageImports";

const { checkbox, unchecked_checkbox } = ImageImports

interface CheckboxProps extends HTMLProps<HTMLDivElement> {
    isChecked: boolean;
    onChange: () => void;
    label?: string;
    labelClass?: string;
}

export const Checkbox: FunctionComponent<CheckboxProps> = ({ isChecked, onChange, label, labelClass, ...props }) => (
    <div className={classes.checkmark_container} {...props}>
        <div className={classes.checkbox}>
            <img src={checkbox} className={"cursor-pointer"} alt="checkbox" onClick={onChange} style={isChecked ? {display: "inherit"} : {display: "none"}} />
            <img src={unchecked_checkbox} className={"cursor-pointer"} alt="checkbox" onClick={onChange} style={!isChecked ? {display: "inherit"} : {display: "none"}} />
            {label && (<span className={`${classes.label} ${labelClass}`} onClick={onChange}>{label}</span>)}
        </div>
    </div>
);