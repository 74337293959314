import React, { FunctionComponent, useState } from "react";
import { Link } from "react-router-dom";
import { GlobalSearch } from "../../utils/interface";
// import PaginationBasic from "../UI/Pagination/PaginationBasic/PaginationBasic";
import SearchItem from "./SearchItem/SearchItem";
import classes from "./SearchItems.module.css";
import ImageImports from '../../utils/ImageImports';

const { Arrow } = ImageImports;

interface SearchItemsProps {
  filteredItems: GlobalSearch[];
  paginationData: {
    totalPosts: number;
    perPagePost: number;
    postsPerPage: number;
    currentPage: number;
    paginate(a: number): void;
    next_paginate(): void;
    prev_paginate(): void;
  };
  pageButtonNumbers: number[];
  hasPreviousPage: boolean;
  previousPage(): void;
  hasNextPage: boolean;
  nextPage(): void;
  setCurrentPageNumber(a: number): void;
  currentPageNumber: number;
}

const SearchItems: FunctionComponent<SearchItemsProps> = ({ filteredItems, paginationData, pageButtonNumbers, 
  hasPreviousPage, previousPage, hasNextPage, nextPage, setCurrentPageNumber, currentPageNumber }) => {

  const pageNumbers = [] as number[];

  for (let i = 1; i <= Math.ceil(paginationData.totalPosts / paginationData.postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className={`${classes.searchItemWrapper}`}>
      {filteredItems && filteredItems.length > 0 ? (
        filteredItems.map((searshResult, i) => (
          <Link key={searshResult.fileId} to={`/shared-document/${searshResult.fileId}`} className={classes.searchItem} target="_blank">
            <SearchItem searchItem={searshResult} />
          </Link>
        ))
      ) : (
        <div className="card" style={{ padding: "24px", fontSize: "14px" }}>
          Apologies, we couldn’t find any matches for that keyword. Please check your spelling or try a new search.
        </div>
      )}

      {filteredItems.length > 0 && (
        <div className={`col-to-row font14 ${classes.paginationWrapper}`}>
          <div className={`row-item ${classes.rowItem}`}>
            <div className="inline-flex">
              Displaying {Math.min(paginationData.postsPerPage,
                paginationData.totalPosts - (paginationData.currentPage - 1) * paginationData.postsPerPage)} of {paginationData.totalPosts} Results
            </div>
          </div>

          <div className={`${classes.paginationFlex} align-center`}>

            <div className={`flex flex-row items-start`}>
              <img
                src={Arrow}
                className={`pagination-left-arrow flex flex-col justify-center padding-3 h-9 `}
                alt="arrow"
                style={hasPreviousPage ? { filter: "invert(31%) sepia(21%) saturate(786%) hue-rotate(150deg) brightness(94%) contrast(83%)", width: "1.5rem" } : { width: "1.5rem" }}
                onClick={() => {
                  if (hasPreviousPage) {
                    previousPage();
                  }
                }}
              />
              {pageButtonNumbers.map((pageNumber) => (
                <span
                  key={pageNumber}
                  className={`flex flex-col justify-center p-3 text-dark-blue relative cursor-pointer`}
                  onClick={() => {
                    setCurrentPageNumber(pageNumber);
                  }}
                >
                  <span className={`absolute top-[6px] left-[2px] h-6 w-6 rounded flex items-center justify-center ${pageNumber === currentPageNumber ? 'font-bold bg-primary-20' : ""}`}>
                    {pageNumber}
                  </span>
                </span>
              ))}
              <img
                src={Arrow}
                className="pagination-right-arrow flex flex-col justify-center padding-3 h-9"
                alt="arrow"
                style={hasNextPage ? { filter: "invert(31%) sepia(21%) saturate(786%) hue-rotate(150deg) brightness(94%) contrast(83%)", width: "1.5rem" } : { width: "1.5rem" }}
                onClick={() => {
                  if (hasNextPage) {
                    nextPage();
                  }
                }}
              />
            </div>

            {/* <PaginationBasic
              totalPosts={paginationData.totalPosts}
              postsPerPage={paginationData.postsPerPage}
              currentPage={paginationData.currentPage}
              paginate={paginationData.paginate}
              next_paginate={paginationData.next_paginate}
              prev_paginate={paginationData.prev_paginate}
            /> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchItems;
