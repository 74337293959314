import {
  PlantSiteDetailsDTO,
  TechnicalDocumentationDTO,
  PartsAndServicesDTO,
  IssueReportsDTO,
  HeaderDTO,
  AccountSettingsDTO,
  AccountSettingsData,
} from "../../utils/interface";
import {
  apiPlantSiteDetails,
  apiTechnicalDocumentation,
  apiPartsAndServices,
  apiIssueReports,
  apiHeader,
  apiAccountSettings,
} from "../../endpoints/endpoints";

import axios, { AxiosResponse } from "axios";
import { QueryFunctionContext, useMutation, useQuery } from "react-query";
import { queryKeys } from "../../react-query/queryKeys";
import { Store } from "../../Store";
import { useContext } from "react";

declare type GetHeaderParameters = [typeof queryKeys.header, { baseOrg: number | undefined }];
const getHeader = async ({ queryKey }: QueryFunctionContext<GetHeaderParameters>): Promise<AxiosResponse<HeaderDTO>> => {
  const [_key, { baseOrg }] = queryKey;
  const response = await axios(`${apiHeader}`, {
    headers: {
      BaseOrg: (baseOrg ?? "") as string,
    },
  });
  return response.data;
};

const getAccountSettings = async (): Promise<AxiosResponse<AccountSettingsDTO>> => {
  const response = await axios(`${apiAccountSettings}`);
  return response.data;
};

const getTD = async (plantId: number): Promise<AxiosResponse<TechnicalDocumentationDTO>> => {
  const response = await axios(`${apiTechnicalDocumentation}?PlantId=${plantId}`);
  return response.data;
};

const getPS = async (plantId: number): Promise<AxiosResponse<PartsAndServicesDTO>> => {
  const response = await axios(`${apiPartsAndServices}?PlantId=${plantId}`);
  return response.data;
};

const getIR = async (plantId: number): Promise<AxiosResponse<IssueReportsDTO>> => {
  const response = await axios(`${apiIssueReports}?PlantId=${plantId}`);
  return response.data;
};

export const updateAccountSettings = async (data: AccountSettingsData): Promise<AccountSettingsDTO> => {
  const response = await axios({
    url: `${apiAccountSettings}`,
    method: "PUT",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: data,
  });
  return response.data;
};

export const useUpdate_AccountSettings = () => {
  return useMutation(updateAccountSettings);
};
export const useGet_HeaderData = (onSuccess: any, onError: any) => {
  const { selectedPlant } = useContext(Store);
  return useQuery<AxiosResponse<HeaderDTO>, unknown, boolean, GetHeaderParameters>(
    [queryKeys.header, { baseOrg: selectedPlant.baseOrgId }],    
    getHeader,
    {
      refetchOnWindowFocus:true,
      onSuccess,
      onError,
    }
  );
};

export const useGet_AccountSettings = (onSuccess: any, onError: any) => {
  return useQuery(queryKeys.accountSettings, getAccountSettings, {
    onSuccess,
    onError,
  });
};

export const useGet_IssueReports = (onSuccess: any, onError: any) => {
  const { selectedPlant: { plantId } } = useContext(Store);
  return useQuery([queryKeys.issueReports, { plantId: plantId }], () => getIR(plantId), {
    onSuccess,
    onError,
  });
};

export const useGet_PartsAndServices = (onSuccess: any, onError: any) => {
  const { selectedPlant: { plantId } } = useContext(Store);
  return useQuery([queryKeys.partsAndServices, { plantId: plantId }], () => getPS(plantId), {
    onSuccess,
    onError,
  });
};

export const useGet_TechnicalDocumentation = (onSuccess: any, onError: any) => {
  const { selectedPlant: { plantId } } = useContext(Store);
  return useQuery([queryKeys.technicalDocumentation, { plantId: plantId }], () => getTD(plantId), {
    onSuccess,
    onError,
  });
};

export type getPlantDetailsParameters = [string, { plantId: number }];
export const getPlantDetails = async ({ queryKey }: QueryFunctionContext<getPlantDetailsParameters>): Promise<PlantSiteDetailsDTO> => {
  const [_key, { plantId }] = queryKey;
  if (!plantId) {
    return Promise.reject(new Error("Invalid Plant Id"));
  }
  const response: AxiosResponse<PlantSiteDetailsDTO> = await axios(`${apiPlantSiteDetails}/${plantId}`);
  return response.data;
};

export const useSelectedPlantDetails = (onSuccess: any, onError: any) => {
  const { selectedPlant } = useContext(Store);

  return useQuery<PlantSiteDetailsDTO, unknown, PlantSiteDetailsDTO, getPlantDetailsParameters>(
    [queryKeys.plantSiteDetails, { plantId: selectedPlant.plantId }],
    getPlantDetails,
    {
      onSuccess,
      onError,
      enabled: selectedPlant.plantId > 0,
      
    }
  );
};
