
import React, {ReactElement} from 'react';
import classes from "./ManageDocumentGroups.module.css";
import EmptyModal from '../../../components/Modals/EmptyModal';
import { DocumentGroup } from '../../../utils/interface';
import Button from '../../../components/Button/Button';
import useWindowSize from '../../../customHooks/useWindowSize';
import { TechnicalDocumentationModuleOutletInterface } from '../../TechnicalDocumentation/TechnicalDocumentationModuleContainer';
import { useOutletContext } from 'react-router-dom';

interface DeletedGroupConfirmationModalProps {
    shown: boolean;
    reportType: DocumentGroup|null;
    onClose: () => void;
}

function DeletedGroupConfirmationModal(props: DeletedGroupConfirmationModalProps) {
    const {isMobile} = useWindowSize();
    const {documentModule} = useOutletContext<TechnicalDocumentationModuleOutletInterface>();

    let width: number = 564;
    if (isMobile) {
        width = 300;
    }

    let modalBody: ReactElement|undefined;
    let modalFooter: ReactElement|undefined;
    if (props.shown) {
        modalBody = (
            <>
                <span>You successfully deleted the document group "{props.reportType?.name}" from the {documentModule.name} page for all plants in the Customer Portal.</span>
            </>
        );

        modalFooter = (
            <>
                <div className={`${classes.deleted_group_modal_button_group} ${classes.flex_row}`}>
                    <Button className="darkBlue" text="Done" onClick={props.onClose} />
                </div>
            </>
        );
    }

    return (
        <EmptyModal shown={props.shown} body={modalBody} footer={modalFooter} heading="Document Group Successfully Deleted" onClose={props.onClose} width={width} />
    );
}

export default DeletedGroupConfirmationModal;