import { useContext, useEffect } from "react";
import { Outlet, useNavigate, useOutletContext, useParams } from "react-router-dom";
import classes from "./ListApiMethods.module.css";
import { ListGroup, ListModuleInterface } from "../../utils/interface";
import useWindowSize from "../../customHooks/useWindowSize";
import { AdministrationListOutletInterface } from "./AdministrationListContainer";
import { Store } from "../../Store";

export interface AdministrationListModuleOutletInterface extends AdministrationListOutletInterface {
	module: ListModuleInterface;
	groups: ListGroup[];
}
function AdministrationListModuleContainer() {
	const outletContext = useOutletContext<AdministrationListModuleOutletInterface>();
	const { module, module: { groups } } = outletContext;
	const navigate = useNavigate();
	const { groupId } = useParams();
	const { isMobile, isTablet } = useWindowSize();

	useEffect(() => {
		if (groups instanceof Array<ListGroup> && groups?.length) {
			if (!groupId || !groups.find(g => g.name === groupId)) {
				navigate(`${groups[0].name}`, {
					replace: true
				});
			}
			else {
				navigate(`${groupId}`, {
					replace: true
				});
			}
		}
		else {
			navigate(`./`);
		}
	}, [groupId, groups]);

	return (
		<div className={`${classes.module_container} ${classes.flex_column} ${isMobile ? classes.is_mobile : ""}`}>
			{!groups?.length ? (
				<>
					<div className={`${classes.top_section} ${classes.flex_column}`}>{module.moduleHeaderContent}</div>
					<div className={`${classes.line}`} />
					<div className={`${isMobile || isTablet ? classes.flex_column : classes.flex_row} ${classes.module_information}`}>
						{module.moduleBodyContainer}
						<Outlet context={outletContext} />
					</div>
				</>
			) : (
				<Outlet context={{ ...outletContext, groups }} />
			)}
		</div>
	);
}

export default AdministrationListModuleContainer;
