import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PlantSystemDetails } from "../../utils/interface";
import Alert from "../Alert/Alert";
import Button from "../Button/Button";
import EmptyModal from "../Modals/EmptyModal";


export const EditPlantSystemDetails = ({systemDetails, onClose, onSave}: {systemDetails: PlantSystemDetails, onClose: () => void, onSave: (systemDetails: PlantSystemDetails) => void}) => {
	const [spId, setSpId] = useState<string>('');
	const [name, setName] = useState<string>('');
	const [tomName, setTomName] = useState<string>('');
	const [error, setError] = useState<string>();
	const ref = useRef<HTMLFormElement>(null);

	useEffect(() => {
		if (systemDetails) {
			setSpId(`${systemDetails.spLegacyID}`);
			setName(systemDetails.esrPlantName || '');
			setTomName(systemDetails.tomoniDashPlantName || '');
		}
	}, [systemDetails]);

	const saveForm = useCallback(() => {
		onSave({
			spLegacyID: Number(spId),
			tomoniDashPlantName: tomName,
			esrPlantName: name
		});
		return false;
	}, [spId, name, tomName]);

	const reportFormValidity = useCallback(() => {
		if (ref.current && ref.current.checkValidity()) {
			setError(undefined);
		} else {
			setError('Value exceeds character limit.');
		}
	}, [ref.current]);

	const canSave = useMemo(() => {
		return error === undefined && Object.entries({
			spLegacyID: spId,
			tomoniDashPlantName: tomName,
			esrPlantName: name
		}).toString() !== Object.entries({
			spLegacyID: systemDetails.spLegacyID,
			tomoniDashPlantName: systemDetails.tomoniDashPlantName,
			esrPlantName: systemDetails.esrPlantName,
		}).toString();
	}, [spId, name, tomName, systemDetails, error]);

	return <EmptyModal body={<form ref={ref} onChange={reportFormValidity} className="w-full">
		{error && <Alert onClose={() => {}} type="error" dismissible={false}>{error}</Alert>}
		<div className="flex flex-col items-stretch gap-6 ">
			<div className="flex flex-row items-start gap-6">
				<span className="basis-2/5">Legacy SharePoint ID</span>
				<input type="number"
						className={`pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base shadow-[0_1px_0_#B3B3B3]`}
						value={spId}
						placeholder="100"
						onChange={e => setSpId(e.target.value)} />
			</div>
			<div className="flex flex-row items-start gap-6">
				<span className="basis-2/5">ESR Plant Name</span>
				<div className="flex flex-col gap-2 grow">
					<input type="text"
							className={`pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base shadow-[0_1px_0_#B3B3B3]`}
							value={name}
							placeholder="Brunswick County (VA)"
							maxLength={50}
							onChange={e => setName(e.target.value)} />
					<span className="text-xs">Limit to 50 characters</span>
				</div>
			</div>
			<div className="flex flex-row items-start gap-6">
				<span className="basis-2/5">TOMONI Dashboard Plant Name</span>
				<div className="flex flex-col gap-2 grow">
					<input type="text"
							className={`pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base shadow-[0_1px_0_#B3B3B3]`}
							value={tomName}
							placeholder="Brunswick County (VA)"
							maxLength={50}
							onChange={e => setTomName(e.target.value)} />
					<span className="text-xs">Limit to 50 characters</span>
				</div>
			</div>
		</div>
	</form>} footer={<Button className="darkBlue" text="Save Changes" onClick={saveForm} disabled={!canSave} />} heading="Edit System Details" onClose={onClose} shown={true} width={568} />
};