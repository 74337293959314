import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { UseQueryResult, useIsFetching } from "react-query";
import { Store } from "../../Store";
import { useGet_PartsFileByIDS } from "../../containers/PartsAndService/QuoteRequestHttpServices";
import { queryKeys } from "../../react-query/queryKeys";
import { CloseAllModal } from "../../utils/CloseAllModal";
import { QuoteRequestAttachment, QuoteRequestGetAll, base64Interface } from "../../utils/interface";
import { QuoteRequestAttachmentTypeID } from "../../utils/moduleIDs";
import FormSuccess from "../FormsPages/FormSuccess/FormSuccess";
import AttachQuotePODocumentModal from "./AttachQuotePODocumentModal";
import AttachmentCardPlain from "./AttachmentCardPlain";
import DeleteModal from "./DeleteModal";
import classes from "./QuoteRequestForm.module.css";

const PurchaseOrderDocuments = ({ quoteRequestItem, isEventClosed, disabled }: quoteProps) => {
  const { setIsModal, isModal } = useContext(Store);
  const isFetching = useIsFetching([queryKeys.getQuoteRequest]);
  const [quoteRequestAttachmentIds, setQuoteRequestAttachmentIds] = useState<QuoteRequestAttachment[] | []>([]);
  const [attachmentID, setAttachmentID] = useState<number>(0);
  const [quoteRequestList, setQuoteRequestList] = useState(quoteRequestItem);

  useEffect(() => {
    setQuoteRequestList(quoteRequestItem);
  }, [quoteRequestItem]);

  useEffect(() => {
    if (quoteRequestList.quoteRequestAttachments.length > 0) {
      const filteredAttachments = quoteRequestList.quoteRequestAttachments.filter((attachment) => {
        if (attachment) {
          return attachment.quoteRequestAttachmentTypeId === QuoteRequestAttachmentTypeID.PODocument;
        } else {
          return null;
        }
      });

      setQuoteRequestAttachmentIds(filteredAttachments);
    } 
    else {
      setQuoteRequestAttachmentIds([]);
    }
  }, [quoteRequestList]);

  const getAttachmentID = (id: number) => {
    setAttachmentID(id);
  };
  const handleDeleteAttachment = (id: number) => {
    setAttachmentID(id);    
      setIsModal?.({
        ...CloseAllModal,
        isDelete: { ...isModal.isDelete, isPODocument: true },
      });
  };

  const queryResult = useGet_PartsFileByIDS(quoteRequestAttachmentIds);

  const isLoadingAttachments = queryResult.some((result) => result.isLoading);
  //const data = queryResult.some((result) => result.data);

  const handleAnotherDocument = () => {
      setIsModal?.({
        ...CloseAllModal,
        isAdd: { ...isModal.isAdd, isPODocument: true },
      });
  };

  return (
    <>
      <div className={`${classes.mb32}`}>
        <div className={`${classes.subtitle}`}>
          <strong>Add a Relevant Purchase Order Document(s)</strong>
        </div>
        <p>
          If you are able to upload a purchase order document(s) for the customer to review, please do so below. Attaching a document will notify the
          user(s) associated with this request.
        </p>
      </div>
      <div className={`${classes.mb32}`}>
        <button
          type="button"
          className={`primaryBtn whiteBtn ${classes.buttonMargin} ${classes.addMoreBtn}`}
          disabled={disabled}          
          onClick={() =>            
            setIsModal?.({
              ...CloseAllModal,
              isAdd: { ...isModal.isAdd, isPODocument: true },
            })
          }
        >
          Attach Document
        </button>
      </div>

      {queryResult.length > 0 && (
        <div className={`${classes.mb24}`}>
          <div className={`${classes.subtitle}`}>
            <strong>Uploaded Document(s)</strong>
          </div>
        </div>
      )}

      <div className={classes.attachmentwrapper}>
        {queryResult.length > 0 ? (
          queryResult.map((pd: UseQueryResult<base64Interface, unknown>) => {
            if (pd.data) {
              return (
                <AttachmentCardPlain
                  key={pd.data.fileId}
                  isEventClosed={false}
                  data={pd.data}
                  fileType={"sdsdasd"}
                  ext={`.${"pd.data.fileTypeObj.extension"}`}
                  lastUpdated={`${moment(pd.data?.created).format("MM/DD/YYYY")}`}
                  getAttachmentID={getAttachmentID}
                  disabled={!!isFetching || !!isLoadingAttachments}
                  isDocumentType={"isPODocument"}
                />
              );
            } else {
              return null;
            }
          })
        ) : (
          <div style={{ marginLeft: "1%" }}>
            <p>No documents have been uploaded.</p>
          </div>
        )}
      </div>

      {isModal.isAdd.isPODocument && (
        <AttachQuotePODocumentModal
          modaltitle={`Add a Relevant Purchase Order Document(s)`}
          quoteRequestAttachmentTypeID={QuoteRequestAttachmentTypeID.PODocument}
          attachmentID={0}
          quoteRequestList={quoteRequestList}
          isEdit={false}
        />
      )}
      {isModal.isEdit.isPODocument && (
        <AttachQuotePODocumentModal
          modaltitle={`Edit a Relevant Purchase Order Document(s)`}
          quoteRequestAttachmentTypeID={QuoteRequestAttachmentTypeID.PODocument}
          attachmentID={attachmentID}
          handleDeleteAttachment={handleDeleteAttachment}
          quoteRequestList={quoteRequestList}
          isEdit={true}
        />
      )}
      {isModal.isDelete.isPODocument && <DeleteModal modaltitle={""} modaltitlename={`Delete Quote Request Document?`} id={attachmentID} />}
      {isModal.isDeleteSuccess.isPODocument && (
        <FormSuccess
          comment={"Successfully Deleted."}
          modalSize={"sm"}
          isTitleCenter={true}
          button_done={false}
          button_addmore={false}
          modaltitlename={"Document Deleted Successfully "}
          modaltitle={""}
        />
      )}
      {isModal.isAddedSuccess.isPODocument && (
        <FormSuccess
          comment={
            "Your quote has been submitted successfully. If there are any questions with your order, a Mitsubishi Power representative will contact you."
          }
          modalSize={"sm"}
          button_done={false}
          button_addmore={false}
          modaltitlename={"PO Document Added Successfully "}
          isTitleCenter={true}
          modaltitle={""}
          onClick={handleAnotherDocument}
        />
      )}
    </>
  );
};

interface quoteProps {
  quoteRequestItem: QuoteRequestGetAll;
  isEventClosed: boolean;
  disabled: boolean;
}

export default PurchaseOrderDocuments;
