// the other DragDropField in this directory has a bunch of code relating to avatar images..

import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toBase64 } from "../../../../utils/FileUtils";
import ImageImports from "../../../../utils/ImageImports";
import { supportedFileTypes } from "../../../../utils/getSupportedFileTypes";
import Alert from "../../../Alert/Alert";
import Loader from "../../../Loader/Loader";
import classes from "./DragDropField.module.css";
import { formatFileSize, maxFileSizeValidator } from "./DragDropFieldFormik";


const PlainDragDropField = ({ onChange, attachment, fileLoading = false }: PlainDragDropFieldProps) => {
  const [preview, setPreview] = useState<string>();
  const [objUrl, setObjUrl] = useState<string>();
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [attachmentType, setAttachmentType] = useState<string|null>(null);
  const { excelDummy, docDummy } = ImageImports;
  const { docTypes, excelTypes, excelTypesObject, docTypesObject } = supportedFileTypes();
  
  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "application/pdf": [],
      ...docTypesObject,
      ...excelTypesObject,
    },
    validator: maxFileSizeValidator,
    multiple: false,
    onDrop: (acceptedFiles: any) => {
      acceptedFiles.map((file: File) => {
        setObjUrl(URL.createObjectURL(file));
        return onChange(file);
      });
    },
    onDropAccepted: (acceptedFiles: any) => {
      setShowLoading(true);
    },
    onDropRejected: (rejectedFiles: any) => {
      setShowLoading(false);
    }
  });
  
  useEffect(() => {
    setShowLoading(true);
    
    if (attachment) {
      setObjUrl(URL.createObjectURL(attachment));
      setAttachmentType(attachment.type);
      toBase64(attachment).then((base64Image: string) => {
        setPreview(base64Image);
      }).catch((err: Error) => {
        setPreview(undefined);
      });
    } else {
      setPreview(undefined);
      setAttachmentType(null);
    }
    
    setShowLoading(false);
    
  }, [attachment]);

  const PreviewContainer = useCallback(() => {
    if (showLoading || fileLoading) {
      return <Loader containerStyle={{marginTop:"50%", marginBottom:"50%"}} />;
    }
    
    if (isDragActive) {
      return <p>Drop file here</p>;
    }

    if (preview && attachmentType) {
      if (preview.includes('data:image')) {          
        return <img src={preview} alt="Document Preview" style={{ width: "100%" }} onLoad={() => URL.revokeObjectURL(preview || '')} />;
      } else if (preview.includes("data:application")) {
          if (preview.includes("data:application/pdf")) {
              return <iframe title="Document Preview" src={objUrl !== undefined ? `${objUrl}#toolbar=0&navpanes=0&scrollbar=0&&scrolling=0&view=FitH&#page=1&pagemode=thumbs` : `${preview}#toolbar=0&navpanes=0&scrollbar=0&&scrolling=0&view=FitH&#page=1&pagemode=thumbs`}></iframe>;
          } else {
            if (docTypes.find(type => type === attachmentType)) {
              return <img src={docDummy} alt="Word Document" className="w-full" onLoad={() => URL.revokeObjectURL(docDummy || "")} />;
            }

            if (excelTypes.find(type => type === attachmentType)) {
              return <img src={excelDummy} alt="Excel Document" style={{ width: "100%" }} onLoad={() => URL.revokeObjectURL(excelDummy || "")} />;
            }
          }
      } else {
        return (
          <div style={{ textAlign: "center", color: "#3C5D69" }}>
            <p>File preview not available.</p>
          </div>
        );
      }
    } else {
      return (
        <div style={{ textAlign: "center", color: "#3C5D69" }}>
          <p>Drag &amp; drop file here or</p>
          <strong>Browse for File</strong>
        </div>
      );
    }
    return (
      <div style={{ textAlign: "center", color: "#3C5D69" }}>
        <p>Drag &amp; drop file here or</p>
        <strong>Browse for File</strong>
      </div>
    );
  }, [preview, isDragActive, showLoading, fileLoading, objUrl]);

  const FileRejectionItems = () => {
    if (fileRejections.length > 0) {
      return (
        <>
          {fileRejections.map(({ file, errors }) => (
            <div className="flex flex-col w-full" key={file.name}>
              <Alert type="error" onClose={() => { }} dismissible={false}>
                {`Upload Error: ${file.name} - ${formatFileSize(file.size)}`}
              </Alert>

              <ul>
                {errors.map(e => (
                  <li key={e.code} className="text-[#991B1B]">{e.message}</li>
                ))}
              </ul>
            </div>
          ))}
        </>
      );
    }

    return <></>;
  };

  return (
    <div className={`${classes.form_group}`}>
      <FileRejectionItems />
      <div {...getRootProps({
          className: `${(classes.touched && classes.error) || fileRejections.length ? `${classes.dropzone} ${classes.errorField} ${classes.error_text}` : `${classes.dropzone}`}`
        })}
      >
        <input {...getInputProps()} />
        <PreviewContainer /> 
      </div>
    </div>
  );
};

interface PlainDragDropFieldProps {
  onChange: (file: File | undefined) => void;
  attachment: File | Blob | undefined;
  maxFileSize?: (file: File, maxFileSize?: number) => { code: string, message: string } | null;
  fileLoading?: boolean;
}

export default PlainDragDropField;
