import { useContext, useEffect, useState } from "react";
import { useUpdate_FAQ } from "../../../containers/Support/SupportHttpServices";
import useHandleBlocker from "../../../customHooks/useHandleBlocker";
import { useOnError } from "../../../customHooks/useOnError";
import { queryKeys } from "../../../react-query/queryKeys";
import { queryClient } from "../../../react-query/useQueryClientGet";
import { Store } from "../../../Store";
import { useStore } from "../../../useStore";
import { CloseAllModal } from "../../../utils/CloseAllModal";
import ImageImports from "../../../utils/ImageImports";
import { CPApiResponse, FaqInterfaceDTO } from "../../../utils/interface";
import Editor from "../../UI/RichTextEditor/Editor";

const { spinner } = ImageImports;

const FormFaq  = (props: FormFaqProps) => {
  const [touched, setTouched] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const { setFaq, setIsModal, isModal, faq } = useContext(Store);
  const { setShowConfirmCloseModal } = useStore();
  const { mutate: UpdateFaq, isLoading: isUpdatingFaq } = useUpdate_FAQ();

  const setErrorData = useOnError();

  const [faqText, setFaqText] = useState<string>("");

  const handleUpdateFaq = () => {
    setUpdating(true);
    UpdateFaq({faqText: faqText}, {
      onSuccess: (data: CPApiResponse<FaqInterfaceDTO>) => {
        queryClient.invalidateQueries(queryKeys.getFaqApi);
        setIsModal?.({
          ...isModal,
          isEdit: {
            ...isModal.isEdit,
            isFaq: false,
          },
        });
        setIsModal?.({
          ...isModal,
          isEditedSuccess: {
            ...isModal.isEditedSuccess,
            isFaq: true,
          },
        });
        setUpdating(false);
      },
      onError: (error:unknown) => {
        setErrorData(error);
        setUpdating(false);
      }
    });
  }

  const { setIsBlocked, setIsModalFormDirty,  setCloseModalBlocked } = useHandleBlocker();
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  
  const handleContentChange = (val: string) => {
    if (val !== faq.faqText) {
      setFaqText(val);
      setTouched(true);
      setIsBlocked(true);
      setIsModalFormDirty(true);
      setIsFormDirty(true);
    } else {
      setTouched(false);
      setIsBlocked(false);
      setIsModalFormDirty(false);
      setIsFormDirty(false);
    }
  }

  const handleCancelClose = () => {
    if (isFormDirty) {
      setShowConfirmCloseModal?.(true);
      setCloseModalBlocked(true);
    } else {
      return props.modalClose ? props.modalClose() : setIsModal?.({ ...CloseAllModal });
    }
  }

  return (
    <div>
      <Editor handleChange={handleContentChange} content={faq?.faqText}/>

      <div className="flex align-center">

        <div className="flex-grow-1 flex">
          <button
            type="button"
            className="primaryBtn darkBlue"
            onClick={handleUpdateFaq}
            disabled={!touched}
            style={(updating ? { pointerEvents: "none", backgroundImage: `linear-gradient(88.69deg, rgba(205, 217, 221, 0.6) 0%, rgba(205, 217, 221, 0.3) 100%), url(${spinner})`, backgroundRepeat: "no-repeat", backgroundPosition: "center center", backgroundSize: "100% 100%, auto 85%", backgroundColor: "rgba(0, 0, 0, 0)", color: "rgba(0, 0, 0, 0)" } : { })}
          >Save Changes
          </button>
          <button
            type="button"
            className="primaryBtn whiteBtn ml-0 lg:ml-[16px]"
            onClick={handleCancelClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

interface FormFaqProps {
  modalClose?: () => void;
}

export default FormFaq;
