import classes from "./QuoteRequestForm.module.css";
import ImageImports from "../../utils/ImageImports";
import QuoteRequestFormStepOne from "./QuoteRequestFormStepOne";
import ErrorCard from "../ErrorCard/ErrorCard";
import { useStore } from "../../useStore";
import { Link } from "react-router-dom";

const QuoteRequestForm = () => {
  const { showError} = useStore();
  const { Arrow } = ImageImports;

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div>
      {showError.isError && <ErrorCard ErrorMessage={showError.title} onRefresh={handleRefresh} ErrorType={showError.ErrorType} />}
      <div>
        <div className="flex flex-row !pb-0 gap-[10px] items-center">
          <Link to={".."} className={`${classes.backLink}`}>
            <img className={`back-arrow`} src={Arrow} alt="back" />
            Back to All Quote Requests
          </Link>
        </div>
        <div className={`${classes.qrwrpper}`}>
          <div className={`${classes.title}`}>
            <h2>
              <strong>Begin New Request for Quote</strong>
            </h2>
          </div>
          <p className={classes.para}>
            This page is the central location for users to request parts and service quotations. It documents requests made through the page and
            Mitsubishi Power Americas' responses. All members of the Customer Portal group for the plant site will receive email notifications when
            requests are made, and responses are posted.
          </p>

          <QuoteRequestFormStepOne />
        </div>
      </div>
    </div>
  );
};

export default QuoteRequestForm;
