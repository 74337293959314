export enum ActionType {
  HEADER = "HEADER",
  NOTIFICATION_TYPES = "NOTIFICATION_TYPES",
  NOTIFICATION_CONTENT = "NOTIFICATION_CONTENT",
  ACCOUNT_SETTINGS = "ACCOUNT_SETTINGS",
  PLANTS = "PLANTS",
  SELECTED_PLANT = "SELECTED_PLANT",
  TECHNICAL_DOCUMENTATION = "TECHNICAL_DOCUMENTATION",
  PRODUCT_TYPE = "PRODUCT_TYPE",
  CONTRACT_BASIS_TYPES = "CONTRACT_BASIS_TYPES",
  FUEL_TYPE = "FUEL_TYPE",
  FAQ_CONTENT = "FAQ_CONTENT",
  MANUFACTURER_TYPE = "MANUFACTURER_TYPE",
  EQUIPMENT_ATTACHMENT_TYPE = "EQUIPMENT_ATTACHMENT_TYPE",
  NAMEPLATE = "NAMEPLATE",
  CONTACT_AVATAR_IMAGE = "CONTACT_AVATAR_IMAGE",
  PLANT_CONTACTS = "PLANT_CONTACTS",
  TYPE_PLANT_CONTACTS = "TYPE_PLANT_CONTACTS",
  TYPE_MITSUBISHI_CONTACTS = "TYPE_MITSUBISHI_CONTACTS",
  CONTACT_ACCORDIAN_DATA = "CONTACT_ACCORDIAN_DATA",
  SHOW_ERROR = "SHOW_ERROR",
  REPORT = "REPORT",
  PARTS_AND_SERVICES = "PARTS_AND_SERVICES",
  ISSUE_REPORTS = "ISSUE_REPORTS",
  SELECTED_PLANT_DETAILS = "SELECTED_PLANT_DETAILS",
  PLANT_CONFIG = "PLANT_CONFIG",
  UNIT = "UNIT",
  UNIT_TYPES = "UNIT_TYPES",
  UNIT_CONFIGURATION_TYPES = "UNIT_CONFIGURATION_TYPES",
  UNIT_GET_ALL = "UNIT_GET_ALL",
  OMMANUAL_SECTION_GET_ALL = "OMMANUAL_SECTION_GET_ALL",
  EQUIPMENT_MODAL_NAME_TYPES = "EQUIPMENT_MODAL_NAME_TYPES",
  PLANT_EQUIPMENT = "PLANT_EQUIPMENT",
  PLANT_EQUIPMENT_TYPES = "PLANT_EQUIPMENT_TYPES",
  PLANT_EQUIPMENTS = "PLANT_EQUIPMENTS",
  PLANT_EQUIPMENTS_ID = "PLANT_EQUIPMENTS_ID",
  PLANT_EQUIPMENT_ATTACHMENTS = "PLANT_EQUIPMENT_ATTACHMENTS",
  PLANT_EQUIPMENT_ATTACHMENT = "PLANT_EQUIPMENT_ATTACHMENT",
  PLANT_CONFIG_IMAGE = "PLANT_CONFIG_IMAGE",
  TOOLTIP = "TOOLTIP",
  TOOLTIP_CONTENT = "TOOLTIP_CONTENT",
  SIDEBAR_TOGGLE = "SIDEBAR_TOGGLE",
  MOBILE_NAV_TOGGLE = "MOBILE_NAV_TOGGLE",
  TRACK_LOCATION = "TRACK_LOCATION",
  ACCORDIAN_TAB = "ACCORDIAN_TAB",
  ADMINISTRATION_GROUP_ID = "ADMINISTRATION_GROUP_ID",
  IS_MODAL = "IS_MODAL",
  GLOBAL_UNIQUE_IDS = "GLOBAL_UNIQUE_IDS",
  NOTIFICATION_PANE = 'NOTIFICATION_PANE',
  A2HS = 'A2HS',
  USER = 'USER',
  NAVIGATION_BLOCKED = 'NAVIGATION_BLOCKED',
  SHOW_CLOSE_MODAL = 'SHOW_CLOSE_MODAL',
  CLOSE_MODAL_BLOCKED = 'CLOSE_MODAL_BLOCKED',
  MODAL_FORM_DIRTY = 'MODAL_FORM_DIRTY',
}