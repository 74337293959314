import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate, Link, Outlet } from "react-router-dom";
import classes from "../../components/QuoteRequestSurvey/QuoteRequestForm.module.css";
import { useOnError } from "../../customHooks/useOnError";
import useWindowSize from "../../customHooks/useWindowSize";
import { useStore } from "../../useStore";
import { PlantEquipmentsDTO } from "../../utils/interface";
import { useGetAllByPlantID_PlantEquipments } from "../PlantInformation/EquipmentsHttpServices";

const QuoteRequests = () => {
  const moduleName = "Quote Requests";
  const [quoteReqId, setQuoteReqId] = useState<number>(0);
  const { quoteRequestId } = useParams();
  const { setPlantEquipments, selectedPlant } = useStore();
  const setErrorData = useOnError();
  let location = useLocation();

  useEffect(() => {
    if (quoteRequestId?.length) {
      setQuoteReqId(parseInt(quoteRequestId));
    }
  }, [quoteRequestId]);

  const onSuccess = (equipments: PlantEquipmentsDTO) => {
    setPlantEquipments?.(equipments);
  };
  const onError = (error: unknown) => {
    setErrorData(error);
  };

  useGetAllByPlantID_PlantEquipments(selectedPlant.plantId, onSuccess, onError);

  return (
    <>
      <div className="path breadcrumb">
        <Link to={"/"} className="link_text">
          Home
        </Link>{" "}
        / Parts &amp; Service / {moduleName}
      </div>
      <div className="login-header">{moduleName}</div>
      <div className={classes.card}>
        <Outlet context={{ quoteReqId, setQuoteReqId, preSort: location?.state?.preSort, preFilter: location?.state?.preFilter }} />
      </div>
    </>
  );
};

export default QuoteRequests;
