import FocusTrap from "focus-trap-react";
import React, { Fragment, MouseEventHandler, ReactNode, useContext, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { Store } from "../../../Store";
import { CloseAllModal } from "../../../utils/CloseAllModal";
import classes from "./ModalGlobal.module.css";

import useHandleBlocker from "../../../customHooks/useHandleBlocker";
import { useStore } from "../../../useStore";
import ImageImport from "../../../utils/ImageImports";

const Backdrop = (props: BackdropProps) => {
  return <div className={classes.backdrop} onClick={props.handleModalClose} />;
};

const ModalOverLay = (props: ModalOverLayProps) => {
  const { closeImg } = ImageImport;
  const refDivElement = useRef<HTMLDivElement>(null);
  
  const funcCalcSize = () => {
    let size = { maxWidth: "700px" } as any;
    if (props.modalSize === "md") {
      size = { maxWidth: "700px" };
    } else if (props.modalSize === "sm") {
      size = { maxWidth: "500px", textAlign: "center" };
    } else if (props.modalSize === "lg") {
      size = { maxWidth: "1392px" };
    } else if (props.modalSize === "special") {
      size = { maxWidth: "700px", width: "564px" };
    } else if (props.modalSize === "RTE") {
      size = { maxWidth: "700px", maxHeight: "895px" };
      return size;
    }
  };
  const defaultOptions = {
    fallbackFocus: '.fallback-focus',
    allowOutsideClick: true,
    escapeDeactivates: false
  }; 

  if (props.useFallbackInitialFocus) {
    Object.assign(defaultOptions, {initialFocus: '.fallback-focus'});
  }
  
  return (
    <FocusTrap 
      active={true}
      focusTrapOptions={defaultOptions}
    >
      <div className={`${classes.modal} ${props.modalClass} focus:outline-none focus-visible:outline-none fallback-focus`} style={funcCalcSize()} ref={refDivElement} tabIndex={-1}>
        {props.isModalHeader && (
          <div className={classes.modalTitle}>
            <div className={props.isTitleCenter ? `${classes.flexGrow1} ${props.titleClassName}` : `${classes.flexGrow2} ${props.titleClassName}`}>
              {props.modaltitle}
              <span className="modal__title_name"> {props.modaltitlename} </span>{" "}
            </div>
            <span className={classes.close_modal} onClick={props.handleModalClose}>
              <img src={closeImg} className="close-modal-img" alt="" />
            </span>
          </div>
        )}        
        <div style={{ textAlign: props.isContentCentered ? "center" : "inherit" }} className={classes.content}>
          {props.children}
        </div>
      </div>
    </FocusTrap>
  );
};

const portalElement = document.getElementById("overlays")!;

const ModalGlobal = (props: ModalGlobalProps) => {
  const storeContext = useContext(Store);
  const { setIsModal, setShowError } = storeContext;
  
  useEffect(() => {
    document.body.classList.add("noScroll");
    setShowError?.({
      isError: false,
      title: "",
      ErrorType: "danger",
    });

    return () => {
      document.body.classList.remove("noScroll");
    };
  }, []);

  
  const { modalFormDirty, setShowConfirmCloseModal, setModalFormDirty, setCloseModalIsBlocked } = useStore();
  const { setCloseModalBlocked } = useHandleBlocker();

  const handleClose = () => {
    if (props.confirmBeforeClose && modalFormDirty) {
      setShowConfirmCloseModal?.(true);
      setCloseModalBlocked(true);
    } else {
      setShowConfirmCloseModal?.(false);
      setCloseModalIsBlocked?.(false);
      setModalFormDirty?.(false);
      
      props.modalClose ? props.modalClose() : setIsModal?.({ ...CloseAllModal });
    }
  };

  return (
    <Fragment>
      
      {ReactDOM.createPortal(
        <Backdrop
          handleModalClose={handleClose}
        />,
        portalElement
      )}
      {ReactDOM.createPortal(
          <div>
            <ModalOverLay
              modaltitle={props.modaltitle}
              titleClassName={props.titleClassName}
              modalClass={props.modalClass}
              isContentCentered={props.isContentCentered}
              modaltitlename={props.modaltitlename}
              isTitleCenter={props.isTitleCenter}
              modalSize={props.modalSize ? props.modalSize : "md"}
              handleModalClose={handleClose}
              isModalHeader={props.isModalHeader}
              confirmBeforeClose={props.confirmBeforeClose}
              useFallbackInitialFocus={props.useFallbackInitialFocus}
            >
              {props.children}
            </ModalOverLay>
          </div>,
        portalElement
      )}
    </Fragment>
  );
};

interface BackdropProps {
  handleModalClose: MouseEventHandler<HTMLDivElement> | undefined;
}

interface ModalOverLayProps {
  children: ReactNode;
  handleModalClose: MouseEventHandler<HTMLDivElement> | undefined;
  modaltitle: string;
  titleClassName?: string;
  modalClass?: string;
  modaltitlename: string;
  modalSize: string;
  isModalHeader: boolean;
  isTitleCenter: boolean;
  isContentCentered?: boolean;
  confirmBeforeClose?: boolean;
  useFallbackInitialFocus?: boolean;
}
interface ModalGlobalProps {
  children: ReactNode;
  modaltitle: string;
  titleClassName?: string;
  modalClass?: string;
  modaltitlename: string;
  modalSize?: string;
  modalClose?: () => void;
  isModalHeader: boolean;
  isTitleCenter: boolean;
  isContentCentered?: boolean;
  confirmBeforeClose?: boolean;
  useFallbackInitialFocus?: boolean;
}

ModalGlobal.defaultProps = {
  modaltitlename: "",
  modalSize: "",
  isTitleCenter: false,
  isContentCentered: false,
  confirmBeforeClose: false,
  useFallbackInitialFocus: false,
};

export default ModalGlobal;
