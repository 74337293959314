import { FunctionComponent, useContext, useMemo, useState } from "react";
import Button from "../../components/Button/Button";
import ModalGlobal from "../../components/UI/Modal/ModalGlobal";
import { Store } from "../../Store";
import { DocumentGroup, Report } from "../../utils/interface";
import classes from './Reports.module.css';
import {CloseAllModal} from "../../utils/CloseAllModal";
import { PlainDropDown, DropDownOptionItem } from "../../components/UI/Form/DropDownSelect/PlainDropDown";

import { pick } from "../../utils/pick";

interface Props {
    documentGroupName: string;
    documentGroups: DocumentGroup[]
    documents: Report[];
    onClose: () => void;
    move: (from: string, to: DocumentGroup) => void;
}

const MoveDocumentModal: FunctionComponent<Props> = ({documentGroupName, documentGroups, documents, onClose, move}) => {
    const {selectedPlant, setIsModal} = useContext(Store);
    const [selectedGroup, setSelectedGroup] = useState<DocumentGroup>();
    const close = () => {        
        setIsModal && setIsModal!({ ...CloseAllModal });
    };

    const documentGroupOptions: DropDownOptionItem[] = useMemo(() => {
		if (documentGroups) {
			return documentGroups.filter(d => d.name !== documentGroupName).map(d => pick(d, ['id', 'name']));
		}
		return [];
	}, [documentGroups]);
    

    const handleGroupSelection = (e: DropDownOptionItem) => {
        const selectedGroup = documentGroups.find(d => d.name === e.name);
        setSelectedGroup(selectedGroup);
    };

    return (
        <ModalGlobal modalClass="overflow-visible" modaltitle={`Move (${documents.length}) Document(s)?`} isModalHeader={true} modalClose={() => {
            close();
            onClose();
        }}>
            <div className={`${classes.move_modal_body} gap-6 ${classes.flex_column}`}>
                <p>Please select a new destination document group for the following {selectedPlant.name} document(s):</p>
                <ul>
                    {documents.map(doc => (
                        <li key={doc.globalId}>{doc.displayFileName}</li>
                    ))}
                </ul>
                
                <div className={`flex flex-row gap-x-10 !gap-y-2 ${classes.add_document_field}`}>
                    <span className={`w-full md:w-1/4`}>Move From</span>
                    <select value={documentGroupName} disabled style={{ padding: 0 }} className={`${classes.plainselect_container}`}>
                        <option value={documentGroupName}>{documentGroupName}</option>
                    </select>
                </div>
                <div className={`flex flex-row gap-x-10 !gap-y-2 ${classes.add_document_field}`}>
                    <span className={`w-full md:w-1/4`}>Move To*</span>
                    <PlainDropDown   
                        value={selectedGroup?.id}
                        className={`${classes.select_container} ${!!selectedGroup?.id ? classes.selected : classes.placeholder}`}
                        selectClass={`${classes.flex_row} ${classes.select}`}
                        optionsClass={`${classes.flex_column} max-w-[300px] sm:max-w-[384px] md:max-w-[460px] ${classes.select_options}`}
                        options={[{ id: false, name: 'Select destination document group' }, ...documentGroupOptions]}
                        onSelection={handleGroupSelection} 
                    />
                </div>
                
                <div className="flex flex-col md:flex-row justify-between">
                    <div className="flex flex-col gap-4 md:flex-row">
                        <Button className={`darkBlue mb-0`} disabled={!selectedGroup} text={"Move Documents"} onClick={() => {
                            close();
                            if (selectedGroup) {
                                move(documentGroupName, selectedGroup);
                            }
                        }} />
                        <Button className={`whiteBtn`} disabled={false} text={"Cancel"} onClick={() => {
                            close();
                            onClose();
                        }} />
                    </div>
                    <div className="flex flex-row items-center">
                        <span className="grow text-center text-sm">*Required Fields</span>
                    </div>
                </div>
            </div>
        </ModalGlobal>
    );
}

export default MoveDocumentModal;