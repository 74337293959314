import React, { useContext, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import useWindowSize from "../../customHooks/useWindowSize";
import { ListGroup, ListModuleInterface, SiteUnit } from "../../utils/interface";
import { Store } from "../../Store";
import usePlantSiteDetailsApiCalls from "../../components/PlantSiteDetails/usePlantSiteDetailsApiCalls";
import NotificationHeader from "./ModuleSpecific/NotificationHeader";
import RoleConfigurationHeader from "./ModuleSpecific/RoleConfiguration/RoleConfigurationHeader";
import RoleConfigurationBody from "./ModuleSpecific/RoleConfiguration/RoleConfigurationBody";
import { getRoles } from "../../customHooks/UserHttpServices";
import NotificationManagementBody from "./ModuleSpecific/NotificationManagement/NotificationManagementBody";
import PlantManagementHeader from "./ModuleSpecific/PlantManagement/PlantManagementHeader";
import PlantManagementBody from "./ModuleSpecific/PlantManagement/PlantManagementBody";
import { MainContainer } from "../MainContainer";
import { usePageRequiresAdmin } from "../../customHooks/usePageRequiresAdmin";

const modules: ListModuleInterface[] = [
	{
		name: "Role Configuration",
		route: "Role-Configuration",
		addEditHeaderName: "Role Configuration",
		moduleHeaderContent: <RoleConfigurationHeader />,
		groups: getRoles,
		groupTitleProperty: "name",
		groupNameProperty: "id",
		groupOrderProperty: "displayOrder",
		get moduleBodyContainer() {
			return <RoleConfigurationBody />;
		},
		addModalHeaderContent: [],
	},
	{
		name: "Plant Management",
		route: "Plant-Management",
		addEditHeaderName: "Plant Management",
		moduleHeaderContent: <PlantManagementHeader />,
		get moduleBodyContainer() {
			return <PlantManagementBody />;
		},
		addModalHeaderContent: [],
	},
	{
		name: "Email Alert & Notification Management",
		route: "Email-Alert-&-Notification-Management",
		addEditHeaderName: "Email Alert & Notification Management",
		moduleHeaderContent: <NotificationHeader />,
		groups: [
			{
				name: "notification-settings",
				title: "Notification Settings",
			},
			{
				name: "email-alert-settings",
				title: "Email Alert Settings",
			},
		],
		get moduleBodyContainer() {
			return <NotificationManagementBody />;
		},
		addModalHeaderContent: [],
	},
];

export interface AddDocumentRequiredProps {
	onSave: (data: any) => void;
	groups: ListGroup[];
	siteUnits: SiteUnit[];
	module?: ListModuleInterface;
	isEdit: boolean;
}

export interface AdministrationListOutletInterface {
	modules: ListModuleInterface[];
	module?: ListModuleInterface;
}

interface AdministrationListContainerProps {}

function AdministrationListContainer(props: AdministrationListContainerProps) {
    usePageRequiresAdmin();
	const { isMobile } = useWindowSize();
	const { moduleName } = useParams();

	const { showError, module, setModule } = useContext(Store);
	usePlantSiteDetailsApiCalls();

	useEffect(() => {
		(async () => {
			const module = modules.find((module) => module.route === moduleName);
			if (module?.groups && module?.groups instanceof Function && module.groupNameProperty) {
				const groups = (await module?.groups?.()).map(
					(g: any) =>
						({
							title: g[module.groupTitleProperty || "name"],
							name: g[module.groupNameProperty || "id"].toString(),
							order: g[module.groupOrderProperty || "order"],
						} as ListGroup)
				);
				setModule?.({ ...module, groups });
			} else if (module) {
				setModule?.(module);
			}
		})();
	}, [moduleName, setModule]);

	return (
		<>
			{module && module.name.length > 0 && (
				<MainContainer breadCrumbs={['Administration', module.name]} title={`${module.name}`}>
					<Outlet context={{ modules, module }} />	
				</MainContainer>
			)}
		</>		
	);
}

export default AdministrationListContainer;
