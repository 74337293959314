import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import ErrorCard from "../../components/ErrorCard/ErrorCard";
import Loader from "../../components/Loader/Loader";
import ModalGlobal from "../../components/UI/Modal/ModalGlobal";
import { useStore } from "../../useStore";
import { CloseAllModal } from "../../utils/CloseAllModal";
import { Report } from "../../utils/interface";
import classes from "./TechnicalDocumentation.module.css";

interface copyDocumentInterface {
  isSharing: boolean;
  isShared: boolean;
  currentReport: Report | undefined;
  handleCloseModal():void;
}
const CopyDocumentModal = ({ isSharing, isShared, currentReport,handleCloseModal }: copyDocumentInterface) => {
  const { selectedPlant, setIsModal, showError } = useStore();
  const navigate = useNavigate();


  return (
    <ModalGlobal
      modaltitle={isSharing ? "Please wait copying document...." : isShared ? "Document Successfully Copied" : "Document Coping Failed"}
      isModalHeader={true}
      isTitleCenter
      isContentCentered={true}
      modalClose={handleCloseModal}
      modalSize={"sm"}
    >
      <>
        {isSharing ? (
          <Loader containerStyle={{marginTop:"100px", marginBottom:"100px"}} />
        ) : (
          <div>
            {isShared ? (
              <>
                <p>
                  “{currentReport?.displayFileName || currentReport?.displayName}” was successfully copied and is now available to any Users’ Groups that are associated to "
                  {selectedPlant.name}" in the Users’ Group Portal.
                </p>
                <div className={`${classes.flex_row} ${classes.add_document_confirmation_buttons}`}>
                  <Button className={`darkBlue`} text="Done" onClick={handleCloseModal} />
                </div>
              </>
            ) : (
              <>
                <p style={{ color: "#991B1B" }}>{showError.title}</p>
              </>
            )}
          </div>
        )}
      </>{" "}
    </ModalGlobal>
  );
};

export default CopyDocumentModal;
