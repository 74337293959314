import React, { ReactNode, useEffect, useState } from "react";
import "react-accessible-accordion/dist/fancy-example.css";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import ErrorCard from "../../components/ErrorCard/ErrorCard";

import ImageImports from "../../utils/ImageImports";

import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from "react-accessible-accordion";
import Loader from "../../components/Loader/Loader";
import { useMarkItemNotificationAsRead } from "../../components/NotificationPane/NotificationHttpServices";
import Pill from "../../components/Pill/Pill";
import PurchaseOrderDocuments from "../../components/QuoteRequestSurvey/PurchaseOrderDocuments";
import QuoteDocumentUpload from "../../components/QuoteRequestSurvey/QuoteDocumentUpload";
import QuoteRequestDateEmailManager from "../../components/QuoteRequestSurvey/QuoteRequestDateEmailManager";
import QuoteRequestFormDisabled from "../../components/QuoteRequestSurvey/QuoteRequestFormDisabled/QuoteRequestFormDisabled";
import QuoteRequestReceivedFromCustomer from "../../components/QuoteRequestSurvey/QuoteRequestReceivedFromCustomer";
import QuoteRequestSubmittedOn from "../../components/QuoteRequestSurvey/QuoteRequestSubmittedOn";
import useHandleBlocker from "../../customHooks/useHandleBlocker";
import { useOnError } from "../../customHooks/useOnError";
import { usePermissions } from "../../customHooks/useUserPermissions";
import { useStore } from "../../useStore";
import { getSortObjectsByKeyFn } from "../../utils/Sortutils";
import { CPApiResponse, QuoteRequestEventWithID, QuoteRequestEvents, QuoteRequestGetAll } from "../../utils/interface";
import { ItemTypeCatalogIds, QuoteRequestAttachmentTypeID, QuoteRequestEventID } from "../../utils/moduleIDs";
import { QuoteRequestInitialValue } from "./FormInitialValue";
import classes from "./QuoteRequestTracking.module.css";
import { useGet_QuoteRequestItem } from "./QuoteRequestsHttpServices";
import QuoteTrackingHeader from "./QuoteTrackingHeader";
import useIsEventClosed from "./useIsEventClosed";

type EventTypePanelObject = {
  heading: ReactNode;
  uuid: string | number;
  panel: ReactNode;
  isDisabled: boolean;
};

const QuoteRequestTracking = () => {
  const { quoteReqId, setQuoteReqId } = useOutletContext<{
    quoteReqId: number;
    setQuoteReqId: React.Dispatch<React.SetStateAction<number>>;
    preSort: any;
    preFilter: any;
  }>();

  const [quoteRequestList, setQuoteRequestList] = useState<QuoteRequestGetAll>(QuoteRequestInitialValue);
  const [allInitialEvents, setAllInitialEvents] = useState<QuoteRequestEventWithID[]>([
    {
      id: 1,
      quoteRequestEventId: 0,
      eventTypeId: 0,
      dateValue: "",
      auxData: "",
      modified: "",
      modifiedBy: 0,
    },
    {
      id: 2,
      quoteRequestEventId: 0,
      eventTypeId: 0,
      dateValue: "",
      auxData: "",
      modified: "",
      modifiedBy: 0,
    },
    {
      id: 3,
      quoteRequestEventId: 0,
      eventTypeId: 0,
      dateValue: "",
      auxData: "",
      modified: "",
      modifiedBy: 0,
    },
    {
      id: 4,
      quoteRequestEventId: 0,
      eventTypeId: 0,
      dateValue: "",
      auxData: "",
      modified: "",
      modifiedBy: 0,
    },
    {
      id: 5,
      quoteRequestEventId: 0,
      eventTypeId: 0,
      dateValue: "",
      auxData: "",
      modified: "",
      modifiedBy: 0,
    },
    {
      id: 6,
      quoteRequestEventId: 0,
      eventTypeId: 0,
      dateValue: "",
      auxData: "",
      modified: "",
      modifiedBy: 0,
    },
  ]);

  const { 
    showError, 
    header, 
    header: {
      data: { user }
    }, 
    selectedPlant 
  } = useStore();

  
  const setErrorData = useOnError();

  const [isDirtyOne, setIsDirtyOne] = useState(false);
  const [isDirtyTwo, setIsDirtyTwo] = useState(false);
  const [isDirtyThree, setIsDirtyThree] = useState(false);
  const [isDirtyFour, setIsDirtyFour] = useState(false);
  const { setIsBlocked } = useHandleBlocker();
  const navigate = useNavigate();
  const isEventClosed = useIsEventClosed(quoteRequestList?.quoteRequestEvents);
  const [items, setItems] = useState<EventTypePanelObject[]>([]);
  const { hasOnePermission } = usePermissions();

  let disableForms = isEventClosed || (!user.mpInternal && !hasOnePermission('QuoteRequestStatus', ['admin',  'edit']));


  const { Arrow } = ImageImports;
  
  const sortFn = getSortObjectsByKeyFn<QuoteRequestEvents>("quoteRequestEventId", 'desc');

  // ///////-----------------------------------------------------------/////

  const { isLoading } = useGet_QuoteRequestItem(quoteReqId, {
    enabled: !!quoteReqId,
    refetchOnWindowFocus: false,
    onSuccess: (QuoteRequestGetAll: CPApiResponse<QuoteRequestGetAll>) => {
      setQuoteRequestList(QuoteRequestGetAll.data);
    },
    onError: (error: unknown) => {
      setErrorData(error);
    },
  });

  // ///////-----------------------------------------------------------/////

  useEffect(() => {
    const allInitialEventsData = allInitialEvents.map((obj) => {
      var eventObj = quoteRequestList?.quoteRequestEvents?.find((o) => o.eventTypeId === obj.id) || obj;
      if (eventObj.eventTypeId === 5 || (eventObj.eventTypeId === 0 && obj.id === 5)) {
        var closedEventObj = quoteRequestList?.quoteRequestEvents?.find((o)=> o.eventTypeId === 6);
        if (closedEventObj) {
          eventObj.modified = closedEventObj.modified;
          eventObj.modifiedBy = closedEventObj.modifiedBy;
        }
        else {
          var lastUpdatedEventObj = quoteRequestList?.quoteRequestEvents?.sort(sortFn).find((o)=> o.eventTypeId === 5);
          if (lastUpdatedEventObj) {
            eventObj.modified = lastUpdatedEventObj.modified;
            eventObj.modifiedBy = lastUpdatedEventObj.modifiedBy;
          }
        }
      }
      if (eventObj.eventTypeId === 7 || (eventObj.eventTypeId === 0 && obj.id === 6)) {
        var poEventObj = quoteRequestList?.quoteRequestEvents?.sort(sortFn).find((o)=> o.eventTypeId === 7);
        if (poEventObj) {
          console.log(poEventObj);
          eventObj = poEventObj;
        }
      }
      return eventObj;
    });
    setAllInitialEvents(allInitialEventsData);
  }, [quoteRequestList, isLoading]);

  const getEventStatus = (AttachmentTypeID: number): { QuoteDocumentStatus: "disabled" | "completed" | "partial"; StatusText: string } => {
    const isPresent = quoteRequestList.quoteRequestAttachments
      .filter((e) => (e ? e : null))
      .map((e) => e.quoteRequestAttachmentTypeId)
      .indexOf(AttachmentTypeID);

    let QuoteDocumentStatus: "disabled" | "completed" | "partial" = "disabled";
    let StatusText: string = "Request Closed";
    if (isPresent !== -1 && isEventClosed) {
      QuoteDocumentStatus = "completed";
      StatusText = "Request Closed";
    } else if (isPresent !== -1) {
      QuoteDocumentStatus = "partial";
      StatusText = "Partially Complete";
    }
    return { QuoteDocumentStatus: QuoteDocumentStatus, StatusText };
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const getDirtyFlagOne = (isDirty: boolean) => {
    setIsDirtyOne(isDirty);
  };
  const getDirtyFlagTwo = (isDirty: boolean) => {
    setIsDirtyTwo(isDirty);
  };
  const getDirtyFlagThree = (isDirty: boolean) => {
    setIsDirtyThree(isDirty);
  };
  const getDirtyFlagFour = (isDirty: boolean) => {
    setIsDirtyFour(isDirty);
  };

  useEffect(() => {
    const sessionStoragePlantId = sessionStorage.getItem("plantId");
    if (sessionStoragePlantId && quoteRequestList.plantSiteId) {
      if (parseInt(sessionStoragePlantId) !== quoteRequestList.plantSiteId) {
        setQuoteReqId(0);
        navigate("..");
      }
    }
  }, [selectedPlant.plantId]);

  const markNotificationAsRead = useMarkItemNotificationAsRead();

  useEffect(() => {
      markNotificationAsRead(quoteRequestList.globalItemId);
  }, [quoteRequestList]);

  useEffect(() => {
    if (isDirtyOne || isDirtyTwo || isDirtyThree || isDirtyFour) {
      setIsBlocked(true);
    } else setIsBlocked(false)
  }, [isDirtyOne, isDirtyTwo, isDirtyThree, isDirtyFour, setIsBlocked]);


  const getStatus = (event: QuoteRequestEventWithID, eventId: number): "disabled" | "completed" | "partial" => {
    if (event.eventTypeId === eventId) {
      return "completed";
    } else if (event.eventTypeId === QuoteRequestEventID.QuoteClosed) {
      return "completed";
    }
    return "disabled";
  };

  let serviceType: string;
  if (quoteRequestList.itemTypeId === ItemTypeCatalogIds.PartsQuoteRequest) {
    serviceType = "Parts";
  } else {
    serviceType = "Service";
  }

  useEffect(() => {
    if (user.portalAdmin || hasOnePermission('QuoteRequestStatus', ['admin',  'edit'])) {
      const events: (EventTypePanelObject|undefined)[] = allInitialEvents.map((event, i) => {
        if (i === 0) {
          return {
            heading: (
              <QuoteTrackingHeader
                title={`Request Received from Customer`}
                quoteRequestList={quoteRequestList}
                status={getStatus(event, 1)}
                event={event}
              />
            ),
            uuid: "a",
            panel: <QuoteRequestReceivedFromCustomer disabled={disableForms} getDirtyFlagOne={getDirtyFlagOne} quoteRequestItem={quoteRequestList} />,
            isDisabled: false,
          };
        } else if (i === 1) {
          return {
            heading: (
              <QuoteTrackingHeader
                title={`Quote Request Entered into the Portal`}
                quoteRequestList={quoteRequestList}
                status={"completed"}
                event={event}
              />
            ),
            uuid: "b",
            panel: (
              <QuoteRequestFormDisabled
                isExternal={false}
                getDirtyFlagTwo={getDirtyFlagTwo}
                isEventClosed={isEventClosed}
                quoteRequestItem={quoteRequestList}
              />
            ),
            isDisabled: false,
          };
        } else if (i === 2) {
          return {
            heading: (
              <QuoteTrackingHeader
                title={`Submitted to the ${serviceType} Team`}
                quoteRequestList={quoteRequestList}
                status={getStatus(event, 3)}
                event={event}
              />
            ),
            uuid: "c",
            panel: <QuoteRequestSubmittedOn disabled={disableForms} getDirtyFlagThree={getDirtyFlagThree} quoteRequestItem={quoteRequestList} />,
            isDisabled: false,
          };
        } else if (i === 3) {
          return {
            heading: (
              <QuoteTrackingHeader
                title={`${serviceType} Team Completed Quote`}
                quoteRequestList={quoteRequestList}
                status={getStatus(event, 4)}
                event={event}
              />
            ),
            uuid: "d",
            panel: <QuoteRequestDateEmailManager disabled={disableForms} getDirtyFlagFour={getDirtyFlagFour} quoteRequestItem={quoteRequestList} />,
            isDisabled: false,
          };
        } else if (i === 4) {
          let { QuoteDocumentStatus, StatusText } = getEventStatus(QuoteRequestAttachmentTypeID.QuoteDocument);
  
          return {
            heading: (
              <QuoteTrackingHeader
                title={`Quote Document(s) Uploaded - ${StatusText}`}
                quoteRequestList={quoteRequestList}
                status={QuoteDocumentStatus}
                event={event}
              />
            ),
            uuid: "e",
            panel: <QuoteDocumentUpload  isExternal={false} quoteRequestItem={quoteRequestList} isEventClosed={isEventClosed} />,
            isDisabled: false,
          };
        } else if (i === 5) {
          let { QuoteDocumentStatus } = getEventStatus(QuoteRequestAttachmentTypeID.PODocument);
  
          return {
            heading: (
              <QuoteTrackingHeader
                title={`Purchase Order Uploaded (Optional)`}
                quoteRequestList={quoteRequestList}
                status={QuoteDocumentStatus}
                event={event}
              />
            ),
            uuid: "f",
            panel: <PurchaseOrderDocuments disabled={false} quoteRequestItem={quoteRequestList} isEventClosed={isEventClosed} />,
            isDisabled: !isEventClosed,
          };
        }
      }).filter(d => d !== undefined);
      
      setItems(events as EventTypePanelObject[]);
    } else {
      const events: (EventTypePanelObject|undefined)[] = allInitialEvents.map((event, i) => {
        if (i === 1) {
          return {
            heading: (
              <QuoteTrackingHeader
                title={`Quote Request Entered into the Portal`}
                quoteRequestList={quoteRequestList}
                status={"completed"}
                event={event}
              />
            ),
            uuid: `${"g"}`,
            panel: <QuoteRequestFormDisabled isEventClosed={isEventClosed} isExternal={true} quoteRequestItem={quoteRequestList} />,
            isDisabled: false,
          };
        } else if (i === 5) {
          let { QuoteDocumentStatus, StatusText } = getEventStatus(QuoteRequestAttachmentTypeID.QuoteDocument);        
          return {
            heading: (
              <QuoteTrackingHeader
                title={`Quote Document(s) Uploaded - ${StatusText}`}
                quoteRequestList={quoteRequestList}
                status={QuoteDocumentStatus}
                event={event}
              />
            ),
            uuid: `${"h"}`,
            panel: <QuoteDocumentUpload quoteRequestItem={quoteRequestList} isExternal={true} isEventClosed={isEventClosed} />,
            isDisabled: false,
          };
        }
      });
  
      
      setItems(events.filter(d => d !== undefined) as EventTypePanelObject[]);
    }
  }, [user, allInitialEvents, quoteRequestList, disableForms, isEventClosed, serviceType]);

  return (
    <div>
      {showError.isError && <ErrorCard ErrorMessage={showError.title} onRefresh={handleRefresh} ErrorType={showError.ErrorType} />}
      <div>
        <div className="flex flex-row !pb-0 gap-[10px] items-center">
          <Link to={".."} className={`${classes.backLink}`}>
            <img className={`back-arrow`} src={Arrow} alt="back" />
            Back to All Quote Requests
          </Link>
        </div>
        {(quoteRequestList.quoteRequestId > 0) ? (
          <div className={`${classes.qrwrpper}`}>
            <div className={`${classes.title}`}>
              <h2 style={{ marginRight: "20px" }}>
                <strong>
                  {quoteRequestList.requestTypeName} Request #{quoteRequestList.quoteRequestId}
                </strong>
              </h2>{" "}
              {quoteRequestList?.quoteRequestEvents && quoteRequestList?.quoteRequestEvents?.length > 0 && isEventClosed ? (
                <Pill type={"success"}>Closed</Pill>
              ) : (
                <Pill type={"warning"}>In Progress</Pill>
              )}
            </div>
            <p className={classes.para}>Quote Entered into the Portal</p>

            <Accordion className={classes.accordian} allowZeroExpanded preExpanded={["a", "g"]}>
              {items.map((item, i) => {
                return (
                  <AccordionItem className={classes.accordianItem} uuid={item.uuid} key={item.uuid}>
                    <AccordionItemHeading>
                      <AccordionItemButton
                        className={classes.customAccordianBtn}
                        style={item.uuid === "f" && item.isDisabled ? { pointerEvents: "none" } : { pointerEvents: "auto", cursor:"pointer"  }}
                      >
                        {item.heading}
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    {item.isDisabled ? "" : <AccordionItemPanel>{item.panel}</AccordionItemPanel>}
                  </AccordionItem>
                );
              })}
            </Accordion>
          </div>
        ) : (
          <div className="my-20 flex justify-center" ><Loader containerStyle={{marginBottom: "0"}} /></div>
        )}
      </div>
    </div>
  );
};

export default QuoteRequestTracking;
