import { NotificationTrigger } from "../../../../utils/interface";
import tableClasses from "../../../../components/Tables/DisplayLikeTable/DisplayLikeTable.module.css";
import classes from "./Triggers.module.css";
import { useParams } from "react-router-dom";

function ModuleNotificationTriggers({
	moduleNotificationTriggers,
	notificationTriggers,
	setNotificationTriggers,
}: {
	moduleNotificationTriggers: Array<NotificationTrigger>;
	notificationTriggers: Array<NotificationTrigger>;
	setNotificationTriggers: React.Dispatch<React.SetStateAction<Array<NotificationTrigger> | undefined>>;
}) {
	const { groupId } = useParams();
	const handleChange = (newTriggerValue: boolean, targetProperty: string, updatedTrigger: NotificationTrigger) => {
		switch (groupId) {
			case "notification-settings":
				setNotificationTriggers(
					notificationTriggers.map((trigger: any) => ({
						...trigger,
						[targetProperty]: trigger.notificationTriggerId === updatedTrigger.notificationTriggerId ? newTriggerValue : trigger[targetProperty],
					}))
				);
				
				break;
			case "email-alert-settings":
				setNotificationTriggers(
					notificationTriggers.map((trigger: any) => ({
						...trigger,
						[targetProperty]: trigger.emailTriggerId === updatedTrigger.emailTriggerId ? newTriggerValue : trigger[targetProperty],
					}))
				);
				
				break;
		}
	};

	return (
		<div className={`${tableClasses.table} ${classes.triggers}`}>
			<div>
				<div>
					<div>{`${groupId === 'notification-settings' ? 'Notify' : 'Alert'} When:`}</div>
					<div>Internal Users</div>
					<div>External Users</div>
				</div>
			</div>
			<div>
				{moduleNotificationTriggers
				?.sort((a, b) => (a.operationTypeId > b.operationTypeId ? 1 : -1))
				.map((notificationTrigger, index) => (
					<div key={index}>
						<div>{notificationTrigger.operationTypeName ? `Content is ${notificationTrigger.operationTypeName.toLocaleLowerCase() === "delete" ? "deleted" : notificationTrigger.operationTypeName.toLocaleLowerCase() === "reply" ? "replied" : notificationTrigger.operationTypeName.toLocaleLowerCase()}`  : <span>Op. ID: {notificationTrigger.operationTypeId} (nameless)</span>}</div>
						<div>
							<input type="checkbox" checked={notificationTrigger.forInternalUsers} onChange={(e) => handleChange(e.target.checked, "forInternalUsers", notificationTrigger)} />
						</div>
						<div>
							<input type="checkbox" checked={notificationTrigger.forExternalUsers} onChange={(e) => handleChange(e.target.checked, "forExternalUsers", notificationTrigger)} />
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default ModuleNotificationTriggers;
