import axios, { AxiosRequestConfig } from "axios";
import { useContext, useEffect, useState } from "react";
import { Store } from "../Store";

export const useBaseOrgIdHeader = () => {
    const {selectedPlant} = useContext(Store);
    const [hasBaseOrgIdForApiCalls, setHasBaseOrgIdForApiCalls] = useState<boolean>(false);

    const setBaseOrgHeader = (config: AxiosRequestConfig) => {
        config.headers = {
            ...config.headers,
            BaseOrg: selectedPlant.baseOrgId
        };
        return config;
    };
    
    useEffect(() => {
        if (selectedPlant.baseOrgId) {
            setHasBaseOrgIdForApiCalls(true);
        }
        const requestInterceptorId = axios.interceptors.request.use(setBaseOrgHeader);
        return () => {
            axios.interceptors.request.eject(requestInterceptorId);
        };
    }, [selectedPlant.baseOrgId]);

    return hasBaseOrgIdForApiCalls;
};