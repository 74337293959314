import { Field, FieldArray, useFormikContext } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Store } from "../../../Store";
import {
  useGet_NamePlateFileByID,
  useGet_PartsFileByID,
} from "../../../containers/PartsAndService/QuoteRequestHttpServices";
import { useGetAllByPlantID_PlantEquipments, useGetAllByUnitID_PlantEquipments } from "../../../containers/PlantInformation/EquipmentsHttpServices";
import { usePlantSiteUnits } from "../../../containers/Reports/ReportsHttpServices";
import { useOnError } from "../../../customHooks/useOnError";
import useWindowSize from "../../../customHooks/useWindowSize";
import {
  GetQR_AttachmentListInterface,
  PlantEquipmentsDATA,
  PlantEquipmentsDTO,
  QuoteRequestAttachment,
  QuoteRequestGetAll,
  RequestQuoteFormInterface,
  SiteUnit,
} from "../../../utils/interface";
import { QuoteRequestAttachmentTypeID } from "../../../utils/moduleIDs";
import TextAreaField from "../../UI/BasicForm/TextAreaField/TextAreaField";
import { PartsListTemplateLink } from "../PartsListTemplateLink";
import classes from "../QuoteRequestForm.module.css";
import AttachmentCardDisabled from "./AttachmentCardDisabled";
import dayjs from "dayjs";

interface quoteProps {
  quoteRequestList: QuoteRequestGetAll;
  isExternal: boolean;
  isEventClosed: boolean;
  isServiceRequest: boolean;
}
const QuoteRequestFormStepThreeDisabled = ({ quoteRequestList, isServiceRequest, isExternal, isEventClosed }: quoteProps) => {
  const { values, setFieldValue } = useFormikContext<RequestQuoteFormInterface>();
  const [Quantity, setQuantity] = useState<any[]>([]);
  const { unitTypes, setUnitTypes, selectedPlant, plantEquipments, setPlantEquipments } = useContext(Store);
  const { isMobile } = useWindowSize();
  const { data: Units } = usePlantSiteUnits();

  const [selectedProductType, setSelectedProductType] = useState<{ id: number; name: string }>({ id: 0, name: "Select a product type" });
  const [selectedEquipmentType, setSelectedEquipmentType] = useState<{ id: number; name: string }>({ id: 0, name: "Select an equipment" });

  const [equipmentsByUnit, setEquipmentsByUnit] = useState<{ id: number; name: string; productType: number }[]>([]);
  const [productType, setProductType] = useState<{ id: number; name: string }[]>([]);
  const [equipmentByProductType, setEquipmentByProductType] = useState<{ id: number; name: string }[]>([]);
  const [QuoteRequestAttachment, setQuoteRequestAttachment] = useState<QuoteRequestAttachment[] | []>([]);
  const [namePlateAttachmentData, setNamePlateAttachmentData] = useState<GetQR_AttachmentListInterface[] | []>([]);

  const [siteUnits, setSiteUnits] = useState<SiteUnit[]>([]);
  const [namePlateAttachmentID, setNamePlateAttachmentID] = useState<number>(0);
  const [partDocumentAttachmentID, setPartDocumentAttachmentID] = useState<number>(0);
  const [modifiedDate, setModifiedDate] = useState<string>("");
  const setErrorData = useOnError();

  // Api fetch start-----------------------------------------------------//

  const onSuccessRP = (plantEquipments: PlantEquipmentsDTO) => {
    const filteredData = plantEquipments.data.filter((pE) => pE.plantEquipmentId === quoteRequestList.equipmentId);
    if (filteredData.length > 0) {
      setPlantEquipments && setPlantEquipments({ ...plantEquipments, data: filteredData });
      setEquipmentByProductType([{ id: filteredData[0].plantEquipmentId, name: filteredData[0].equipmentName }]);
    }
  };
  const onErrorRP = (error: any) => {
    setProductType && setProductType([]);
    setSelectedProductType && setSelectedProductType({ id: 0, name: "Select a product type" });
    setSelectedEquipmentType && setSelectedEquipmentType({ id: 0, name: "Select a equipment" });
    setEquipmentsByUnit([]);
  };
  useGetAllByPlantID_PlantEquipments(selectedPlant.plantId, onSuccessRP, onErrorRP);
  // Api fetch end-------------------------------------------------------//

  // Api fetch start-----------------------------------------------------//
  const onSuccess = (plantEquipments: PlantEquipmentsDTO) => {
    setEquipmentsByUnit(
      plantEquipments.data.map((option: PlantEquipmentsDATA) => ({
        id: option.plantEquipmentId,
        name: option.equipmentName,
        productType: option.productType,
      }))
    );

    setProductType(
      plantEquipments.data
        .map((option: PlantEquipmentsDATA) => ({ id: option.productType, name: option.productTypeName }))
        .filter((v, i, a) => a.findIndex((d) => d.id === v.id) === i)
    );
  };
  const onError = (error: any) => {
    setProductType && setProductType([]);
    setSelectedProductType && setSelectedProductType({ id: 0, name: "Select a product type" });
    setSelectedEquipmentType && setSelectedEquipmentType({ id: 0, name: "Select a equipment" });
    setEquipmentsByUnit([]);
  };
  let unitId = 0;
  if (plantEquipments.data.length > 0) {
    unitId = plantEquipments.data[0].unitID;
  }
  useGetAllByUnitID_PlantEquipments(unitId, onSuccess, onError);

  // Api fetch end-------------------------------------------------------//

  useEffect(() => {
    setQuantity([...new Array(50)]);
  }, []);

  useEffect(() => {
    if (Units) {
      setSiteUnits(Units);
      const type = Units.map((unit) => ({ id: unit.unitId, name: unit.name }));
      setUnitTypes && setUnitTypes({ data: [...type] });
    } else {
      setSiteUnits([]);
      setUnitTypes && setUnitTypes({ data: [{ id: 0, name: "Select a unit " }] });
    }
  }, [Units]);

  // console.log("partDocumentData", partDocumentData);

  // Api fetch Start-------------------------------------------------------//

  useEffect(() => {
    if (quoteRequestList.quoteRequestAttachments.length > 0) {
      const quoteRequestAttachments = quoteRequestList.quoteRequestAttachments.filter((attachment) => {
        if (attachment) {
          return attachment;
        } else {
          return null;
        }
      });

      quoteRequestAttachments
        .filter((a) => (a ? a : null))
        .map((a) => {
          if (a.quoteRequestAttachmentTypeId === QuoteRequestAttachmentTypeID.PartsDocument) {
            setPartDocumentAttachmentID(a.fileId);
          } else if (a.quoteRequestAttachmentTypeId === QuoteRequestAttachmentTypeID.NameplateAsset) {
            setNamePlateAttachmentID(a.fileId);
          }
          return a;
        });

      setQuoteRequestAttachment(quoteRequestAttachments);
    }

    if (quoteRequestList.quoteRequestEvents && quoteRequestList.quoteRequestEvents.length > 0) {
      const modifiedDate = quoteRequestList.quoteRequestEvents.filter((x) => x.eventTypeId === 2).map((a) => a.modified);
      setModifiedDate(modifiedDate.toString());
    }
  }, [quoteRequestList]);

  useGet_PartsFileByID(partDocumentAttachmentID, {
    enabled: partDocumentAttachmentID > 0,
    onSuccess(data) {
      // console.log("partsDocumentBase64", data);
      setFieldValue("partsDocumentBase64", data);
    },
    onError(err: unknown) {
      setFieldValue("partsDocumentBase64", "");
      setErrorData(err);
    },
  });

  useGet_NamePlateFileByID(namePlateAttachmentID, {
    enabled: namePlateAttachmentID > 0,
    onSuccess(data) {
      setFieldValue("nameplateBase64", data);
    },
    onError(err: unknown) {
      setFieldValue("nameplateBase64", "");
      setErrorData(err);
    },
  });

  // Api fetch end-------------------------------------------------------//
  return (
    <>
      <div className={`${classes.qrwrpper}`}>
        {quoteRequestList?.equipmentId && (
          <>
            <div>
              <h4>Equipment & Part Details</h4>
            </div>
            <p className={classes.para}>
              Please provide part(s) information ONLY for the unit selected above. Please create a separate quote for each unit.
            </p>
            <div className={classes.flex}>
              <div className={classes.flexCol}>
                <div style={{ height: "32px" }}>
                  <label>Site Unit*</label>
                </div>
                <Field as={"select"} disabled name={"unit"}>
                  {unitTypes.data.map((ct) => (
                    <option key={ct.id + ct.name} value={ct.id}>
                      {ct.name}
                    </option>
                  ))}
                </Field>
              </div>
            </div>
            <div className={classes.flex}>
              <div className={classes.flexCol}>
                <div style={{ height: "32px" }}>
                  <label>Product Type*</label>
                </div>
                <Field as={"select"} disabled name={"ProductType"}>
                  {productType.map((ct) => (
                    <option key={ct.id + ct.name} value={ct.id}>
                      {ct.name}
                    </option>
                  ))}
                </Field>
              </div>
              <div className={classes.flexCol}>
                <div style={{ height: "32px" }}>
                  <label>Equipment Name*</label>
                </div>
                <Field as={"select"} disabled name={"quoteRequest.equipmentId"}>
                  <option>{quoteRequestList.equipmentName}</option>
                </Field>
              </div>
            </div>
            <div>
              <p>
                Note: If the third-party equipment you are looking for does not appear in the dropdown list above, please navigate to your plant’s
                equipment list in the{" "}
                <Link target={"_blank"} to={"/plant-information"}>
                  Plant Information
                </Link>{" "}
                page and add it. If you require Mitsubishi Power equipment to be added, please contact your Mitsubishi Power representative.
              </p>
            </div>
          </>
        )}

        {values?.quoteRequest?.quoteRequestParts && values.quoteRequest.quoteRequestType === 0 && (
          <div>
            <FieldArray name="quoteRequest.quoteRequestParts">
              {({ insert, remove, push }) => (
                <div>
                  {!isMobile && (
                    <div className={classes.quoteRequestPartsWrp}>
                      <div className={classes.col1}>Part Number</div>
                      <div className={classes.col2}>Part Description</div>
                      <div className={classes.col3}>Quantity</div>
                      <div className={classes.col4}></div>
                    </div>
                  )}

                  {values?.quoteRequest?.quoteRequestParts.length > 0 &&
                    values.quoteRequest.quoteRequestParts.map((friend, index) => (
                      <div className={classes.quoteRequestPartsWrp} key={index}>
                        <div className={classes.col1}>
                          {isMobile && <div className={classes.col1}>Part Number</div>}
                          <Field
                            name={`quoteRequest.quoteRequestParts.${index}.partNumber`}
                            disabled
                            className={`${classes.input}`}
                            placeholder="Ex. G1-23524-07"
                            type="text"
                          />
                        </div>
                        <div className={classes.col2}>
                          {isMobile && <div className={classes.col2}>Part Description</div>}
                          <Field
                            name={`quoteRequest.quoteRequestParts.${index}.description`}
                            disabled
                            className={`${classes.input}`}
                            placeholder="Ex. Gasket"
                            type="text"
                          />
                        </div>
                        <div className={classes.col3}>
                          {isMobile && <div className={classes.col3}>Quantity</div>}
                          <Field name={`quoteRequest.quoteRequestParts.${index}.quantity`} disabled as="select">
                            {Quantity.length > 0 &&
                              Quantity.map((a, i) => {
                                return (
                                  <option key={`${i}-qty`} value={i}>
                                    {i}
                                  </option>
                                );
                              })}
                            {/* <option key={index} value={"1"}>1</option>; */}
                          </Field>
                        </div>
                        {/* {values.quoteRequest.quoteRequestParts.length > 1 && (
                          <div className={classes.col4}>
                            <div className={classes.clear} onClick={() => remove(index)}>
                              clear
                            </div>
                          </div>
                        )} */}
                      </div>
                    ))}
                </div>
              )}
            </FieldArray>
          </div>
        )}

        {!isServiceRequest && (
          <>
            {QuoteRequestAttachment?.findIndex((x) => x.quoteRequestAttachmentTypeId === QuoteRequestAttachmentTypeID.PartsDocument) !== -1 && (
              <>
                <hr />
                <div>
                  <h4>Attach a Parts Document (Optional)</h4>
                </div>
                <p className={classes.para}>
                  Please use this space to upload a parts list document with details such as part numbers, descriptions and quantity needed. You may
                  organize your parts list details using the Mitsubishi Power-provided <PartsListTemplateLink text="Parts List Template" />.
                </p>
                {QuoteRequestAttachment?.filter((pd) => pd.quoteRequestAttachmentTypeId === QuoteRequestAttachmentTypeID.PartsDocument).map((pd) => {
                  if (pd) {
                    return (
                      <div key={pd.fileId}>
                        <AttachmentCardDisabled
                          fileName={pd.name}
                          data={pd}
                          image={values.partsDocumentBase64}
                          isExternal={isExternal}
                          isEventClosed={isEventClosed}
                          fileType={""}
                          ext={`.${pd.fileTypeObj.extension}`}
                          lastUpdated={`${dayjs(pd.created).format("MM/DD/YYYY")}`}
                          id={1}
                        />
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </>
            )}
          </>
        )}

        {QuoteRequestAttachment?.findIndex((x) => x.quoteRequestAttachmentTypeId === QuoteRequestAttachmentTypeID.NameplateAsset) !== -1 && (
          <>
            <hr />
            <div>
              <h4>Attach a Nameplate Asset (Required for Third-Party Equipment)</h4>
              <p className={classes.para}> For third-party equipment configurations where multiple nameplate assets are available, please combine multiple nameplate images into one file before attaching below.</p>
            </div>

            {QuoteRequestAttachment?.filter((pd) => pd.quoteRequestAttachmentTypeId === QuoteRequestAttachmentTypeID.NameplateAsset).map((pd) => {
              if (pd) {
                return (
                  <div key={pd.fileId}>
                    <AttachmentCardDisabled
                      fileName={pd.name}
                      data={pd}
                      isExternal={isExternal}
                      isEventClosed={isEventClosed}
                      image={values.nameplateBase64}
                      fileType={""}
                      ext={`.${pd.fileTypeObj.extension}`}
                      lastUpdated={`${dayjs(pd.created).format("MM/DD/YYYY")}`}
                      id={2}
                    />
                  </div>
                );
              } else {
                return null;
              }
            })}
          </>
        )}

        <hr />

        <div>
          <TextAreaField
            name="quoteRequest.additionalComments"
            height={126}
            labelName="Additional Comment(s):"
            placeholder="Add comment(s)"
            className=""
            disabled={isExternal}
          />
          {modifiedDate && (
            <small className="text-gray-50">
              Last updated on {dayjs(modifiedDate).format('MM/DD/YYYY')}
            </small>
          )}
        </div>
      </div>
    </>
  );
};

export default QuoteRequestFormStepThreeDisabled;
