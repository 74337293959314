import { CSSProperties, FunctionComponent, HTMLAttributes, useEffect, useId, useState, useCallback } from "react";
import { Checkbox } from "../Checkbox/Checkbox";
import classes from './DropDownCustom.module.css';
import ImageImports from "../../../../utils/ImageImports";

const {downArrow} = ImageImports;

interface MultiSelectDropDownProps extends HTMLAttributes<HTMLDivElement> {
    options: {id: number, name: string}[];
    onSelection: (items: number[], toggled: number) => void;
    value: number[];
    selectClass?: string;
    optionsClass?: string;
    hasSelectionText?: string;
    noSelectionText?: string;
    disabled?: boolean;
    disabledClass?: string;
    dropdownStyles?: CSSProperties;
    itemClass?: string;
}
const MultiSelectDropDown: FunctionComponent<MultiSelectDropDownProps> = ({options, onSelection, value, selectClass, optionsClass, hasSelectionText, noSelectionText, disabled, disabledClass, itemClass, dropdownStyles, ...props}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [zIndex, setZIndex] = useState<string|undefined>('-1');
    const id = useId().replaceAll(":", "_");

    const selectOption = (selectOption: number) => {
        const index = value.indexOf(selectOption);
        const selectedOptions = [...value];
        if (index < 0) {
            selectedOptions.push(selectOption);
        } else {
            selectedOptions.splice(index, 1);
        }
        onSelection(selectedOptions, selectOption);
    }

    useEffect(() => {
        const handleCloseDropDown = (e: any) => {
            if (id && !e.target.closest(`.${id}`)) {
                setIsOpen(false);
            }
        }

        document.body.addEventListener("click", handleCloseDropDown)
        return () => document.body.removeEventListener("click", handleCloseDropDown);
    }, [id]);

    useEffect(() => {
        if (isOpen) {
            setZIndex(undefined);
        }
    }, [isOpen]);

    const transitionEnd = useCallback(() => {
        if (!isOpen) {
            setZIndex('-1');
        }
    }, [isOpen]);

    return (
        <div {...props}>
            <div className={`select-container ${id}`}>
                <div className={`${selectClass} ${disabled? disabledClass : ''}`} onClick={() => { setIsOpen(!disabled && !isOpen)}} tabIndex={0}>
                    {value.length === 1 ? options.find(o => o.id === value[0])?.name : value.length > 0 ? hasSelectionText : noSelectionText}
                    <img src={downArrow} alt="Open/Close" style={{
                        transition: 'transform 0.35s ease-out',
                        transform: isOpen? 'rotateX(180deg)' : 'none'
                    }} />
                </div>
                <div className={`${optionsClass} ${classes.select_options}`} style={{
                    position: 'absolute',
                    overflow: 'auto',
                    maxHeight: isOpen? '' : '0',
                    padding: isOpen? undefined : '0',
                    transition:'max-height 0.15s ease-out, padding 0.15s ease-out',
                    boxShadow: '0px 25px 50px -12px rgba(0, 0, 0, 0.1)',
                    marginTop: isOpen? '3px' : undefined,
                    zIndex,
                    ...dropdownStyles
                }} onTransitionEnd={transitionEnd}>
                    {options.map(opt => (
                        <div key={`${opt.id}`} onClick={() => selectOption(opt.id)} className={itemClass}>
                            <Checkbox isChecked={value.includes(opt.id)} onChange={() => {}} label={opt.name} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

MultiSelectDropDown.defaultProps = {
    value: [],
    selectClass: '',
    optionsClass: '',
    noSelectionText: 'Select Items',
    hasSelectionText: 'Multiple Items Selected',
    disabled: false,
    disabledClass: ''
};

export default MultiSelectDropDown;