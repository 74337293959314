import axios, { AxiosError, AxiosResponse } from "axios";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { QueryFunctionContext, useQuery, UseQueryOptions } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { apiFile } from "../../endpoints/endpoints";
import { queryKeys } from "../../react-query/queryKeys";
import { queryClient } from "../../react-query/useQueryClientGet";
import { Store } from "../../Store";
import ErrorCard from "../ErrorCard/ErrorCard";
import Loader from "../Loader/Loader";
import { useBulkUpdateNotificationAsRead, useMarkItemNotificationAsRead } from "../NotificationPane/NotificationHttpServices";
import classes from "./FileReader.module.css";

declare type GetFileContentsParameters = ['fileContents', { url: string | undefined }];
declare type E = AxiosError | Error;
const getFileContents = async ({ queryKey }: QueryFunctionContext<GetFileContentsParameters>): Promise<AxiosResponse<Blob>> => {
  const [, { url }] = queryKey;
  if (!url) {
    return Promise.reject(new Error('Invalid URL'));
  }
  const response: AxiosResponse<Blob> = await axios(url, {
    responseType: 'blob'
  });

  return response;
};

const useGetFileContents = (url: string, options: Omit<UseQueryOptions<AxiosResponse<Blob>, E, AxiosResponse<Blob>, GetFileContentsParameters>, "queryKey" | "queryFn"> = {}) => {
  return useQuery<AxiosResponse<Blob>, E, AxiosResponse<Blob>, GetFileContentsParameters>(['fileContents', { url }], getFileContents, {
    ...options,
    enabled: !!url
  });
}

function FileReader() {
  const [content, setContent] = useState<ReactElement>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setShowError, showError } = useContext(Store);
  const markItemNotificationAsRead = useMarkItemNotificationAsRead();
  const navigate = useNavigate();
  const [searchParams, ] = useSearchParams();
  const {mutate: markNotificationRead} = useBulkUpdateNotificationAsRead();

  const { fileId, fileURI, globalItemId, staticDocumentGlobalItemId } = useParams();

  let url = `${apiFile}/DisplayFileByFileId/${fileId}`;
  let downloadUrl = `${apiFile}/DownloadFileByFileId/${fileId}`
  if (fileURI) {
    url = decodeURIComponent(fileURI);
  }
  if (globalItemId) {
    url = `${apiFile}/DisplayFile/${globalItemId}`;
    downloadUrl = `${apiFile}/DownloadFile/${globalItemId}`;
  }
  if (staticDocumentGlobalItemId) {
    url = `${apiFile}/DownloadStaticFile/${staticDocumentGlobalItemId}`;
  }
  
  useGetFileContents(url, {
    onSuccess: async (response) => {
      
      if(response.headers["content-type"].indexOf("application/json") > -1) {
        const permissionResp = JSON.parse(await response.data.text());
        if (!permissionResp.success) {
          navigate('/not-found');
          return;
        }
      }
      
      let contentType = response.headers["content-type"];
      setIsLoading(false);
      // Migrated files appear to be zip files
      if (contentType === 'application/x-7z-compressed')
        navigate('/something-went-wrong')

      if (globalItemId && !isNaN(+globalItemId)) {
        markItemNotificationAsRead(+globalItemId);
        if (searchParams.has('notificationId')) {
          markNotificationRead([+(searchParams.get('notificationId') as string)]);
        }
        queryClient.invalidateQueries(queryKeys.header);
        queryClient.clear();
      }

      window.location.replace(URL.createObjectURL(response.data));
      navigate('/', {
        state: {
          successMessage: 'Browser is unable to display document preview. Document has been downloaded.'
        }
      });
    },
    onError: (error) => {
      setShowError &&
        setShowError({
          title: error.message,
          isError: true,
          ErrorType: "danger",
        });
      setIsLoading(false);
      navigate('/something-went-wrong');
    }
  });

  useEffect(() => {
    return () => setShowError && setShowError({
      title: '',
      isError: false,
      ErrorType: null
    });
  }, [setShowError]);

  return (
    <>
      <div className="content" style={{ display: "flex" }}>
        {showError.isError && <ErrorCard ErrorMessage={showError.title} ErrorType={showError.ErrorType} />}
        <div
          className={` ${classes.card}`}
          style={isLoading ? { display: "flex", height: "400px", justifyContent: "center", padding: "25px" } : { padding: "25px", minHeight: "100px", display: "block" }}
        >
          {isLoading ? <Loader /> : showError.isError ? <p>Something went wrong</p> : content}

        </div>
      </div>
    </>
  );
}
export default FileReader;
