import { FunctionComponent, ReactNode } from "react";
import { AuthorizationContext } from "./AuthorizationContext";

interface AuthorizedTemplateProps {
    children: ReactNode;
}

export const AuthorizedTemplate: FunctionComponent<AuthorizedTemplateProps> = ({children}) => (
    <AuthorizationContext.Consumer>
        {({hasPortalAccess}) => hasPortalAccess && children}
    </AuthorizationContext.Consumer>
);
