import { FunctionComponent, HTMLAttributes } from "react";
import { downloadFileByUrl } from "../../customHooks/FileHttpServices";

export const StaticFileLink: FunctionComponent<HTMLAttributes<HTMLAnchorElement> & {StaticFileUrl: string, text: string}> = ({StaticFileUrl, text, ...props}) => {
  const handleLinkClick = () => {
    if (StaticFileUrl !== undefined && StaticFileUrl !== '') {
        downloadFileByUrl(StaticFileUrl);
    }
  };
    return (
        <span onClick={handleLinkClick}>
            <a {...props} className="text-ocean cursor-pointer">{text}</a>
        </span>
    );
}