import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useOnError } from "../../customHooks/useOnError";
import { QuoteFormInterfaceDTO, QuoteRequestEvent, QuoteRequestGetAll, QuoteStatusInterface } from "../../utils/interface";
import classes from "./QuoteRequestForm.module.css";

import Loader from "../Loader/Loader";

import { useIsFetching } from "react-query";

import dayjs from "dayjs";
import { useUpdate_QuoteStatus } from "../../containers/PartsAndService/QuoteRequestHttpServices";
import { queryKeys } from "../../react-query/queryKeys";
import { queryClient } from "../../react-query/useQueryClientGet";
import { getSortObjectsByKeyFn } from "../../utils/Sortutils";
import { QuoteRequestEventID } from "../../utils/moduleIDs";
import DatePickerFields from "../UI/BasicForm/DatePickerField/DatePickerFields";

interface quoteProps {
  quoteRequestItem: QuoteRequestGetAll;
  getDirtyFlagThree(value: boolean): void;
  disabled:boolean;
}
const QuoteRequestSubmitedOn = ({ quoteRequestItem, getDirtyFlagThree,disabled }: quoteProps) => {
  const setErrorData = useOnError();
  const isFetching = useIsFetching([queryKeys.getQuoteRequest]);
  const [quoteRequestList, setQuoteRequestList] = useState(quoteRequestItem);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setQuoteRequestList(quoteRequestItem);
  }, [quoteRequestItem]);

  const { mutate: updateQuoteStatus } = useUpdate_QuoteStatus();

  const handleOnSubmit = (values: QuoteStatusInterface, formikHelpers: FormikHelpers<QuoteStatusInterface>) => {
    updateQuoteStatus(values, {
      onSuccess: (quoteResponse: QuoteFormInterfaceDTO) => {
        queryClient.invalidateQueries([queryKeys.getQuoteRequest, quoteResponse.data[0].globalItemId]);
		setValues({ ...values });
      },
      onError: (error: unknown) => {
        setErrorData(error);
      },
    });
  };
    
  useEffect(() => {
    getDirtyFlagThree(isDirty);
  }, [isDirty]);

  const [values, setValues] = useState<QuoteStatusInterface>();
  const sortQuoteRequestEvents = getSortObjectsByKeyFn<QuoteRequestEvent>('quoteRequestEventId', 'asc');
  
  useEffect(() => { 
    const matchingEvents: QuoteRequestEvent[] = quoteRequestItem.quoteRequestEvents?.filter((event: QuoteRequestEvent) =>
      event.eventTypeId === QuoteRequestEventID.SubmittedToQuoteTeam) || [];
    const fieldValue = matchingEvents.length? dayjs(matchingEvents.sort(sortQuoteRequestEvents).pop()?.dateValue).format('MM/DD/YYYY') : '';

    setValues({
      quoteRequestId: quoteRequestList.quoteRequestId,
      quotestatusTypeId: QuoteRequestEventID.SubmittedToQuoteTeam,
      timestamp: fieldValue,
    });
  }, [quoteRequestItem, quoteRequestList]);

  return (
    <div>
      {values && <Formik
          enableReinitialize
          initialValues={values}
          validationSchema={Yup.object().shape({
            quoteRequestId: Yup.number().positive().required(),
            quotestatusTypeId: Yup.number().positive().required(),
            timestamp: Yup.string().required(),
          })}
          onSubmit={handleOnSubmit}
        >
          {(formikProps) => {
            setTimeout(() => setIsDirty(formikProps.dirty), 0);

            return (
              <Form>
                {isFetching > 0 ? (
                  <Loader containerStyle={{ marginTop: "50px" }} />
                ) : (
                  <div className={`${classes.mb32}`}>
                    <div className={` ${classes.flex}`}>
                      <div className={classes.flexCol}>
                        <DatePickerFields
                          labelName={"Date Submitted"}
                          name="timestamp"
                          disabled={disabled}
                          placeholder="Select a date"
                        />
                      </div>
                      <div className={classes.flexCol}></div>
                    </div>
                    <div className={` ${classes.flex} ${classes.mb32}`}>
                      <div className={classes.flexCol}>
                        Note: Publishing this date will notify the user(s) associated with this request and update the status of this step to Completed.
                      </div>
                    </div>
                    <div className="flex align-center">
                      <div className="flex-grow-1 flex">
                        <button type="submit" className="primaryBtn darkBlue" disabled={!formikProps.dirty || formikProps.isSubmitting || !formikProps.isValid}>
                          Publish Submission Date
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      }
    </div>
  );
};

export default QuoteRequestSubmitedOn;
