import { Field, Form, Formik, FormikHelpers } from "formik";
import React, { useContext, useEffect, useId, useState } from "react";
import * as Yup from "yup";
import { useOnError } from "../../customHooks/useOnError";
import { QuoteFormInterfaceDTO, QuoteRequestEvent, QuoteRequestGetAll, QuoteStatusInterface } from "../../utils/interface";
import TextInputField from "../UI/BasicForm/TextInputField/TextInputField";
import classes from "./QuoteRequestForm.module.css";

import Loader from "../Loader/Loader";

import moment from "moment";
import { useIsFetching } from "react-query";

import dayjs from "dayjs";
import { useUpdate_QuoteStatus } from "../../containers/PartsAndService/QuoteRequestHttpServices";
import { queryKeys } from "../../react-query/queryKeys";
import { queryClient } from "../../react-query/useQueryClientGet";
import { getSortObjectsByKeyFn } from "../../utils/Sortutils";
import { QuoteRequestEventID } from "../../utils/moduleIDs";
import DatePickerFields from "../UI/BasicForm/DatePickerField/DatePickerFields";

interface quoteProps {
  quoteRequestItem: QuoteRequestGetAll;
  getDirtyFlagFour(value: boolean): void;
  disabled:boolean;
}
const QuoteRequestDateEmailManager = ({ quoteRequestItem, getDirtyFlagFour,disabled }: quoteProps) => {
  const setErrorData = useOnError();
  const isFetching = useIsFetching([queryKeys.getQuoteRequest]);
  const [quoteRequestList, setQuoteRequestList] = useState(quoteRequestItem);
  const [isDirty, setIsDirty] = useState(false);
  const [initialValues, setInitialValues] = useState<QuoteStatusInterface>();

  useEffect(() => {
    setQuoteRequestList(quoteRequestItem);
  }, [quoteRequestItem]);

  useEffect(() => {
    getDirtyFlagFour(isDirty);
  }, [isDirty]);

  const { mutate: updateQuoteStatus } = useUpdate_QuoteStatus();

  const handleOnSubmit = (values: QuoteStatusInterface, formikHelpers: FormikHelpers<QuoteStatusInterface>) => {
    updateQuoteStatus(values, {
      onSuccess: (quoteResponse: QuoteFormInterfaceDTO) => {
        queryClient.invalidateQueries([queryKeys.getQuoteRequest, quoteResponse.data[0].globalItemId]);
      },
      onError: (error: unknown) => {
        setErrorData(error);
      }
    })
  };

  const sortQuoteRequestEvents = getSortObjectsByKeyFn<QuoteRequestEvent>('quoteRequestEventId', 'asc');
  useEffect(() => {
    const matchingEvents: QuoteRequestEvent[] = quoteRequestItem.quoteRequestEvents?.filter((event: QuoteRequestEvent) =>
      event.eventTypeId === QuoteRequestEventID.QuoteTeamCompleted) || [];
    const fieldValue = matchingEvents.length? dayjs(matchingEvents.sort(sortQuoteRequestEvents).pop()?.dateValue).format('MM/DD/YYYY') : '';
  
    setInitialValues({
      quoteRequestId: quoteRequestList.quoteRequestId,
      quotestatusTypeId: QuoteRequestEventID.QuoteTeamCompleted,
      timestamp: fieldValue,
    });
  }, [quoteRequestItem, quoteRequestList]);

  return (
    <div>
      {initialValues && <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            quoteRequestId: Yup.number().positive().required(),
            quotestatusTypeId: Yup.number().positive().required(),
            timestamp: Yup.string().required(),
          })}
          onSubmit={handleOnSubmit}
        >
          {(formikProps) => {
            setTimeout(() => setIsDirty(formikProps.dirty), 0);
            return (
              <Form>
                {isFetching > 0 ? (
                  <Loader containerStyle={{ marginTop: "50px" }} />
                ) : (
                  <div className={`${classes.mb32}`}>
                    <div className={` ${classes.flex}`}>
                      <div className={classes.flexCol}>
                        <DatePickerFields
                          labelName={"Date Quote Emailed to Program Manager"}
                          name="timestamp"
                          dateFormat={'MM/dd/yyyy'}
                          placeholder="Select a date"
                          disabled={disabled}
                        />
                      </div>
                      <div className={classes.flexCol}></div>
                    </div>
                    <div className={` ${classes.flex} ${classes.mb32}`}>
                      <div className={classes.flexCol}>
                        Note: Publishing this date will notify the user(s) associated with this request and update the status of this step to Completed.
                      </div>
                    </div>
                    <div className="flex align-center">
                      <div className="flex-grow-1 flex">
                        <button type="submit" className="primaryBtn darkBlue" disabled={!formikProps.isValid || !formikProps.dirty || formikProps.isSubmitting}>
                          Publish Email Date
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      }
    </div>
  );
};

export default QuoteRequestDateEmailManager;
