import { FunctionComponent, useEffect } from "react";
import { generatePath, Params, useNavigate, useParams, useSearchParams } from "react-router-dom";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import { useBulkUpdateNotificationAsRead } from "../../components/NotificationPane/NotificationHttpServices";
import { ModuleIDs } from "../../utils/moduleIDs";

const moduleRoutes = {
    [ModuleIDs.PlantInformation]: '/plant-information/*',
    [ModuleIDs.PlantDetails]: '/plant-information/*',
    [ModuleIDs.MitsubishiPowerEquipment]: '/plant-information/*',
    [ModuleIDs.ThirdPartyEquipment]: '/plant-information/*',
    [ModuleIDs.EquipmentAttachments]: '/plant-information/*',
    [ModuleIDs.MitsubishiPowerContacts]: '/plant-information/site-contacts/*',
    [ModuleIDs.CustomerContacts]: '/plant-information/site-contacts/*',
    [ModuleIDs.IssueReports]: '/issue-reports/*',
    [ModuleIDs.DiscussionThreads]: '/issue-reports/*',
    [ModuleIDs.DiscussionMessages]: '/issue-reports/*',
    [ModuleIDs.PartsServices]: '/parts&service/QuoteRequests/*',
    [ModuleIDs.PartsServiceQuotes]: '/parts&service/QuoteRequests/*',
    [ModuleIDs.QuoteRequestStatus]: '/parts&service/QuoteRequests/*',
    [ModuleIDs.PartsCatalog]: '/parts&service/Parts%20Catalog/*',
    [ModuleIDs.PartsCatalogDocumentGroups]: '/parts&service/Parts%20Catalog/*',
    [ModuleIDs.VoiceofCustomerResponses]: '/parts&service/customer-survey/*',
    [ModuleIDs.TechnicalDocumentation]: '/technical-documentation/Mitsubishi%20Power%20Reports/*',
    [ModuleIDs.MitsubishiPowerReports]: '/technical-documentation/Mitsubishi%20Power%20Reports/*',
    [ModuleIDs.MitsubishiPowerReportsDocumentGroups]: '/technical-documentation/Mitsubishi%20Power%20Reports/*',
    [ModuleIDs.TechnicalBulletins]: '/technical-documentation/Technical Bulletins/*',
    [ModuleIDs.TechnicalBulletinDocumentGroups]: '/technical-documentation/Technical Bulletins/*',
    [ModuleIDs.OMManuals]: '/technical-documentation/O&M Manuals/*',
    [ModuleIDs.OMManualDocumentGroups]: '/technical-documentation/O&M Manuals/*',
    [ModuleIDs.Support]: '/support/esr/Engineering%20Support%20Requests/*',
    [ModuleIDs.CustomerRequestedTechnicalInfo]: '/support/esr/Engineering%20Support%20Requests/*',
    [ModuleIDs.CustomerSupport]: '/support/esr/Engineering%20Support%20Requests/*',
    [ModuleIDs.OrganizationManagement]: '/administration/organization-management/*',
    [ModuleIDs.PlantManagement]: '/administration/Plant-Management/*',
    [ModuleIDs.UnitManagement]: '/administration/Plant-Management/*',
    [ModuleIDs.RoleConfiguration]: '/administration/Role-Configuration/*',
    [ModuleIDs.UserManagement]: '/administration/user-management/*',
    [ModuleIDs.EmailAlertNotificationManagement]: '/administration/Email-Alert-&-Notification-Management/*',
};

type ModuleIdType = keyof typeof moduleRoutes;

export const ModuleRedirector: FunctionComponent = () => {
    const navigate = useNavigate();
    const {moduleId, ...params} = useParams() as Readonly<Params<string>> & {moduleId: ModuleIdType};
    const check = useParams() as Readonly<Params<string>> & {moduleId: ModuleIdType};

    const [searchParams, ] = useSearchParams();
    const {mutate: markNotificationRead} = useBulkUpdateNotificationAsRead();
    const urlParams = new URLSearchParams(window.location.search);
    const plantId = urlParams.get('plantId');
    
    let element = <></>;
    useEffect(() => {
        try {
            if (!moduleRoutes.hasOwnProperty(moduleId)) {
                throw 'Invalid moduleId'
            }
            if (searchParams.has('notificationId')) {
                markNotificationRead([+(searchParams.get('notificationId') as string)]);
            }
            const p = generatePath(moduleRoutes[moduleId], params);
            if((ModuleIDs.MitsubishiPowerEquipment === moduleId || 
                ModuleIDs.ThirdPartyEquipment === moduleId || 
                ModuleIDs.EquipmentAttachments === moduleId) && plantId) {
                navigate({ pathname: p, search: `?plantId=${plantId}`});
            } else {
                navigate(p);
            }
            
        } catch (err) {
            element = <ErrorPage />;
        }
    }, [moduleId]);

    return element;
};