import { useEffect, useState } from "react";
import "./Accordion.css";
import AccordionItem from "./AccordionItem";
import { AccordionDataType } from "./types";

interface AccordionGroupParameters {
  items: AccordionDataType[];
  openIndex: number;
}

export const AccordionGroup = ({ items, openIndex }: AccordionGroupParameters) => {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const btnOnClick = (index: number, key: string) => {
    setCurrentIndex((currentValue) => (currentValue !== index ? index : -1));
	sessionStorage.setItem("selectedAccordionRow", key);
  };

  useEffect(() => {
    if (openIndex >= 0) {
      setCurrentIndex(openIndex);
    }
  }, [])

  return (
    <ul className="accordion border-none">
      {items.map((item, index) => (
        <AccordionItem
          key={item.key}
          data={item}
          isOpen={index === currentIndex}
          btnOnClick={() => btnOnClick(index, item.key.toString())}
        />
      ))}
      
    </ul>
  );
}
  