import { MouseEvent, useCallback, useContext, useEffect, useMemo } from 'react';
import Moment from 'react-moment';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import classes from "../../containers/Reports/ReportsToolTipData/ReportsToolTipData.module.css";
import useWindowSize from '../../customHooks/useWindowSize';
import { queryKeys } from '../../react-query/queryKeys';
import { Store } from '../../Store';
import "../../UtilityFirst.css";
import ImageImports from "../../utils/ImageImports";
import "./Accordion.css";
import { useDismissNotifications } from './NotificationHttpServices';

const { menuCloseIcon } = ImageImports;


export default function NotificationPane() {
  const { header, report, selectedPlant, notificationPaneOpen, toggleNotificationPane, notificationContent, notificationContentType } = useContext(Store);
  const { isDesktop } = useWindowSize();
  const queryClient = useQueryClient();
  const { mutate: dismissNotifications } = useDismissNotifications({
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.header);
    }
  });

  const dismiss = useCallback((e: MouseEvent) => {
    dismissNotifications(header.data.notifications.map(n => n.notificationId));
    e.preventDefault();
  }, [dismissNotifications, header.data.notifications]);

  useEffect(() => {
    if (notificationPaneOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = ''
    };
  }, [notificationPaneOpen]);

  const NotificationPaneContent = useCallback(() => {
    return (
      <>
        <div className="text-gray-300 w-full border-0 border-solid border-t mt-4">
          <div className="text-2xl text-gray-900 font-extrabold w-full my-4 pt-4">Notifications</div>
        </div>
        <div className="flex text-sm text-gray-900 justify-between py-2 font-bold">
          <span >{selectedPlant.name}</span>
          <a href="#/dismiss" className="no-underline text-dark-blue" onClick={dismiss}>Dismiss All</a>
        </div>
        {notificationContent}
      </>
    )
  }, [notificationContent]);

  const ViewDocPaneContent = () => {
    return (
      <div className={classes.wrp}>
        <div className={classes.row}>
          <h2 className={classes.title}> Details for {report.displayFileName}</h2>
        </div>
        <div className={classes.row}>
          <p>Please find the details for “{report.displayFileName}” below.</p>
        </div>
        <div className={`${classes.row} ${classes.mb26}`}>
          <div className={classes.badgeLabel}>Implementation Status</div>
          {
            report.implementationStatus.toLowerCase() === "completed" ?
              <div className={classes.badgeCompleted}>{report.implementationStatus}</div>
              : report.implementationStatus.toLowerCase() === "not performed" ?
                <div className={classes.badgeNotPerformed}>{report.implementationStatus}</div> :
                <div className={classes.badgePartiallyPerformed}>{report.implementationStatus}</div>
          }
        </div>
        <div className={`${classes.row} ${classes.mb26}`}>
          <div className={classes.label}>Scope</div>
          <div className={classes.details}>{report.scope} </div>
        </div>
        <div className={`${classes.row} ${classes.mb26}`}>
          <div className={classes.label}>Summary</div>
          <div className={classes.details}> {report.summaryGuideline} </div>
        </div>
        <div className={`${classes.row} ${classes.mb26}`}>
          <div className={classes.label}>Document Group</div>
          <div className={classes.details}>{report.reportType}</div>
        </div>
        <div className={`${classes.row} ${classes.mb26}`}>
          <div className={classes.label}>Last Modified</div>
          <div className={classes.details}>
            <Moment date={report.updated} format="MM/DD/YYYY" />
          </div>
        </div>
        <div className={classes.row}>
          <Link to={`shared-document/${report?.fileId}`} style={{ color: "#00749E", textDecoration: "none" }} target="_blank" rel="noreferrer">View the full document</Link>
        </div>
      </div>
    )
  }

  const handleClosePanel = () => {
    sessionStorage.removeItem("selectedAccordionRow");
    toggleNotificationPane?.(false, notificationContentType);
  }

  const width: string = useMemo(() => {
    if (!isDesktop) return '100%';
    if (notificationContentType) return '300px';
    return '500px';
  }, [isDesktop, notificationContentType]);

  return (
    <SlidingPane
      className="sliding-pane"
      isOpen={notificationPaneOpen}
      width={width}
      onRequestClose={handleClosePanel}
      hideHeader
    >
      <div tabIndex={1} style={{ opacity: 'inherit' }}>
        <div className="NotificationPane__info right bg-gray rounded-full p-2">
          <div>
            <div className="flex justify-end">
              <img src={menuCloseIcon} alt="Expand/Collapse" className="bg-header rounded-full no-underline cursor-pointer" onClick={handleClosePanel} />
            </div>
            {!notificationContentType && <NotificationPaneContent />}
            {notificationContentType && <ViewDocPaneContent />}
          </div>
        </div>
      </div>
    </SlidingPane>
  );
}
