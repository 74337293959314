import { OMManualSectionGetAllDTO, OMManualSectionDATA, OMManualSectionDTO } from "../../utils/interface";
import { omManualApiUrl } from "../../endpoints/endpoints";

import axios, { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { queryKeys } from "../../react-query/queryKeys";

export interface OMManualSectionCrudMethods<T, TD, TGA> {
	getAll: () => Promise<TGA>;
}

export const OMManualSectionAPI: OMManualSectionCrudMethods<AxiosResponse<OMManualSectionDTO>, OMManualSectionDATA, AxiosResponse<OMManualSectionGetAllDTO>> = {
	getAll: async (): Promise<AxiosResponse<OMManualSectionGetAllDTO>> => {
		const response = await axios({
			url: `${omManualApiUrl}/GetAllSections`,
			method: "GET",
			params: {
			},
		});
		return response.data;
	},
};

export const useGetAll_OMManualSectionData = (onSuccess: any, onError: any) => {
	return useQuery([queryKeys.ommanualSectionGetAll], () => OMManualSectionAPI.getAll(), {
		enabled: true,
		onSuccess,
		onError,
	});
};
