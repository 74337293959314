import { ReactElement, useEffect, useMemo, useState } from "react";
import { DropDownOptionItem } from "../../../../components/UI/BasicForm/DropDownSelect/PlainDropDown";
import { PlainDropDown } from "../../../../components/UI/Form/DropDownSelect/PlainDropDown";
import ImageImports from "../../../../utils/ImageImports";
import { UnitDATA, UnitGetAllDTO } from "../../../../utils/interface";

const {pencil} = ImageImports;

export const UnitDetailsContainer = ({plantUnits, onEdit}: {plantUnits: UnitGetAllDTO, onEdit: (unit: UnitDATA) => void}) => {
    const [selectedUnit, setSelectedUnit] = useState<UnitDATA>();

    useEffect(() => {
        const foundSelectedUnit = plantUnits.data.find(d => d.unitId === selectedUnit?.unitId);
        if (foundSelectedUnit === undefined) {
            setSelectedUnit(plantUnits.data[0]);
        } else {
            setSelectedUnit(foundSelectedUnit);
        }
    }, [plantUnits]);

    const selectOptions: DropDownOptionItem[] = useMemo(() => {
        return (plantUnits?.data || []).map(d => ({
            ...d,
            id: d.unitId,
            name: d.name
        }))
    }, [plantUnits]);
    
    return (
        <div className="flex flex-col items-start gap-4 self-stretch">
            <div className="flex flex-row items-start gap-6">
                <div className="flex flex-col items-start gap-2">
                    <span>Unit Name</span>
					<PlainDropDown onSelection={(item: (DropDownOptionItem | UnitDATA)) => {
                            setSelectedUnit(item as UnitDATA);
                        }}
						className="grow hover:cursor-pointer"
						options={selectOptions}
						value={selectedUnit?.unitId}
						defaultText="Select a unit"
						selectClass="flex flex-row pt-1 pr-2 !pb-1 pl-1 border-0 lg:min-w-[240px] justify-between border border-solid border-[#999] w-full rounded"
						optionsClass="p-4 bg-white rounded" itemClass="py-[14px] px-6 cursor-pointer hover:bg-primary-20" />
                </div>
                <div className="flex flex-col justify-end items-start self-stretch">
                    {selectedUnit && (
                        <div className="flex flex-row !pb-2 items-center gap-[10px] cursor-pointer" onClick={() => onEdit(selectedUnit)}>
                            <img src={pencil} alt="Edit" />
                            <span className="text-dark-blue font-bold text-sm">Edit Details</span>
                        </div>
                    )}
                </div>
            </div>
            {selectedUnit && <UnitDetails unit={selectedUnit} />}
        </div>
    );
};

const UnitDetails = ({unit}: {unit: UnitDATA}) => {
    return (
        <>
            <div className="flex flex-col items-start gap-1 grow">
                <span className="font-bold text-sm">Notes</span>
                <span className="text-sm">{unit.notes}</span>
            </div>
            <div className="flex flex-col p-6 bg-[#F2F2F2] rounded w-full items-stretch">
                <div className="flex flex-row items-start gap-4">
                    <UnitDetailItem label="Unit Configuration" value={unit.unitConfigurationName} />
                    <UnitDetailItem label="Output Potential" value={`${unit.totalMW} Mw`} />
                    <UnitDetailItem label="Fuel Type" value={unit.fuelTypeName} />
                    <UnitDetailItem label="Direct Ownership" value={unit.directOwnership} />
                    <UnitDetailItem label="Parent/Group Owner" value={unit.parentGroupOwnership} />
                    <UnitDetailItem label="CO Date" value={unit.coDate} />
                </div>
            </div>
        </>
    );
};

const UnitDetailItem = ({label, value}: {label: string, value: string|ReactElement}) => (
    <div className="flex flex-col items-start gap-1 grow">
        <span className="text-sm font-bold">{label}</span>
        <span className="text-sm">{value}</span>
    </div>
);