import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { usePageRequiresInternal } from "../../customHooks/usePageRequiresInternal";
import CustomerSurveyList from "./CustomerSurveyList";
import SurveyWizard from './SurveyWizard';

interface CustomerSurveyProps {
	viewOnly?: boolean;
}

const CustomerSurvey = ({viewOnly = false}: CustomerSurveyProps) => {
	usePageRequiresInternal();

	let location = useLocation();
	const [idFromUrl, setIdFromUrl] = useState<string>(location?.pathname?.substring(location.pathname.lastIndexOf('/') + 1));

	useEffect(() => {
		setIdFromUrl(location?.pathname?.substring(location.pathname.lastIndexOf('/') + 1));
	}, [location])

	return (
		<>
			<div className="path breadcrumb">
				<Link to={"/"} className="link_text">
					Home
				</Link>{" "}
				/ Parts & Service
				{" "}
				/ Voice of Customer Responses
				{" "}
			</div>			
			{!!(+idFromUrl) ? <SurveyWizard viewOnly={viewOnly} /> : <CustomerSurveyList />}
		</>
	);
}

export default CustomerSurvey;

