import {Outlet, useLocation, useOutletContext} from 'react-router-dom';
import {TechnicalDocumentationOutletInterface} from './TechnicalDocumentationContainer';
import classes from './TechnicalDocumentation.module.css';
import {DocumentModuleInterface} from './TechnicalDocumentationContainer';
import { DocumentGroup, NotificationType, Notification, Permissions, Report } from '../../utils/interface';
import useWindowSize from '../../customHooks/useWindowSize';
import { Store } from '../../Store';
import { useContext } from 'react';

export interface TechnicalDocumentationModuleOutletInterface extends TechnicalDocumentationOutletInterface {
    documentModule: DocumentModuleInterface;
    documentGroups: DocumentGroup[];
    setDocumentGroups: React.Dispatch<React.SetStateAction<DocumentGroup[]>>;
    documentAdded: Report|undefined;
    setDocumentAdded: React.Dispatch<React.SetStateAction<Report | undefined>>;
    notificationTypes: NotificationType[];
    notifications: Notification[];
    refetch: any;
    groupPermissions: Permissions;
    reportPermissions: Permissions;
    setReportPermissions: React.Dispatch<React.SetStateAction<Permissions>>
}
function TechnicalDocumentationModuleContainer () {
    const outletContext = useOutletContext<TechnicalDocumentationModuleOutletInterface>();
    const { notificationTypes, 
        header: {
            data: {
                notifications,
            }
        },
     } = useContext(Store);
    const {documentGroups, setDocumentGroups, refetch, documentAdded, setDocumentAdded} = outletContext;
    const {isMobile} = useWindowSize();
	const location = useLocation();

    const {groupPermissions, reportPermissions} = outletContext;

    const HeaderContent = outletContext.documentModule.ModuleHeaderContent;

    return (
        <div className={`${classes.module_container} ${classes.flex_column} ${isMobile? classes.is_mobile : ''}`}>
			{outletContext.documentModule && !location.pathname.endsWith("manage-groups") && (
				<>
					<div className={`${classes.top_section} ${classes.flex_column}`}>
                        <HeaderContent groupPermissions={groupPermissions} reportPermissions={reportPermissions} />
                    </div>
					<div className={`${classes.line}`} />
				</>
			)}
            <Outlet context={{...outletContext, notificationTypes, notifications, documentAdded, setDocumentAdded}} />
        </div>
    );
}

export default TechnicalDocumentationModuleContainer;