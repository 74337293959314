import axios, { AxiosResponse } from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import { queryKeys } from "../../react-query/queryKeys";
import { CPApiResponse, DataWithPermissions, deleteInterface, ModulePermissionResponseInterface, QuoteRequestGetAll, QuoteRequestGetAllDTO } from "../../utils/interface";
import { apiGetQuoteRequest, apiquoterequest } from "../../endpoints/endpoints";
import { handleModulePermissionResponse, handleModulePermissionResponseMulti } from "../../utils/handleAxiosResponse";

interface QuoteRequestCrudMethods<T1, T2, T3> {
  getAll: (plantId: number) => Promise<T1>;
  get: (globalItemId: number) => Promise<T2>;
  delete: (globalId: number) => Promise<T3>;
}

export const QuoteRequestApi: QuoteRequestCrudMethods<
  DataWithPermissions<QuoteRequestGetAll[]>,
  CPApiResponse<QuoteRequestGetAll>,
  AxiosResponse<deleteInterface>
> = {
  get: async (globalItemId: number): Promise<CPApiResponse<QuoteRequestGetAll>> => {
    const response = await axios({
      url: `${apiGetQuoteRequest}/${globalItemId}`,
      method: "GET",
    });
        
    return response.data;
  },
  getAll: async (plantid: number): Promise<DataWithPermissions<QuoteRequestGetAll[]>> => {
    const response: AxiosResponse<CPApiResponse<ModulePermissionResponseInterface<QuoteRequestGetAll[]>>> = await axios({
      url: `${apiquoterequest}/${plantid}`,
      method: "GET",
    });
    return handleModulePermissionResponse(response);
  },
  delete: async (globalId: number): Promise<AxiosResponse<deleteInterface>> => {
    const response = await axios({
      url: `${apiquoterequest}/DeleteByGlobalItemId/${globalId}`,
      method: "DELETE",
    });
    return response.data;
  },
};

export const useQuery_QuoteRequestItems = (
  plantid: number,
  options: Omit<UseQueryOptions<DataWithPermissions<QuoteRequestGetAll[]>>, "queryKey" | "queryFn"> = {}
) => {
  return useQuery<DataWithPermissions<QuoteRequestGetAll[]>>([queryKeys.QuoteRequestItems, plantid], () => QuoteRequestApi.getAll(plantid), {
    ...options,
  });
};

export const useGet_QuoteRequestItem = (
  globalItemId: number,
  options: Omit<UseQueryOptions<CPApiResponse<QuoteRequestGetAll>>, "queryKey" | "queryFn"> = {}
) => {
  return useQuery<CPApiResponse<QuoteRequestGetAll>>([queryKeys.getQuoteRequest, globalItemId], () => QuoteRequestApi.get(globalItemId), {
    ...options,
  });
};
