import { UnitDTO, UnitDATA, UnitGetAllDTO, CPApiResponse } from "../../utils/interface";
import { apiUnit, apiUnitGetAll } from "../../endpoints/endpoints";

import axios, { AxiosError, AxiosResponse } from "axios";
import { MutationOptions, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "react-query";
import { queryKeys } from "../../react-query/queryKeys";
import { handleAxiosResponse } from "../../utils/handleAxiosResponse";

export interface UnitCrudMethods<T,TD,TGA> {
  get: (id: number) => Promise<T>;
  getAll: (id: number) => Promise<TGA>;
  update: (data: TD) => Promise<UnitDATA>;
  create: (data: TD) => Promise<T>;
  delete: (data: {unit: UnitDATA, reason: string}) => Promise<boolean>
}

export const UnitApi: UnitCrudMethods<AxiosResponse<UnitDTO>, UnitDATA,UnitGetAllDTO> = {
  get: async (plantId: number): Promise<AxiosResponse<UnitDTO>> => {
    const response = await axios({
      url: `${apiUnit}/${plantId}`,
      method: "GET",
    });
    return response.data;
  },
  getAll: async (plantId: number): Promise<UnitGetAllDTO> => {
    const response = await axios({
      url: `${apiUnitGetAll}`,
      method: "GET",
      params:{
        plantId: plantId,
      }
    });
    return response.data;
  },
  update: async (data: UnitDATA): Promise<UnitDATA> => {
    const response: AxiosResponse<CPApiResponse<UnitDATA>> = await axios({
      url: `${apiUnit}/${data.unitId}`,
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
    return handleAxiosResponse(response);
  },
  create: async (data: UnitDATA): Promise<AxiosResponse<UnitDTO>> => {
    const response = await axios({
      url: `${apiUnit}`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
    return response.data;
  },
  delete: async ({unit: {unitId}, reason}: {unit: UnitDATA, reason: string}) => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${apiUnit}/${unitId}?DeletionReason=${reason}`, {
      method: 'DELETE'
    });
    return handleAxiosResponse(response);
  }
 
};

export const useGet_UnitData = (unitID: number, onSuccess: any, onError: any) => {
  return useQuery([queryKeys.unit, unitID], () => UnitApi.get(unitID), {
    enabled: !!unitID,
    onSuccess,
    onError,
  });
};

export const useGetAll_UnitData = (plantId: number, onSuccess: any, onError: any) => {
  return useQuery([queryKeys.unitGetAll, plantId], () => UnitApi.getAll(plantId), {
    enabled: !!plantId,
    onSuccess,
    onError,
  });
};

export const useUpdate_UnitData = (options: Omit<UseMutationOptions<UnitDATA, AxiosError<string>, UnitDATA>, 'mutationFn'> = {}) => {
  return useMutation(UnitApi.update, options);
};

export const useCreate_UnitData = (options: Omit<UseMutationOptions<AxiosResponse<UnitDTO>, AxiosError<string>, UnitDATA>, 'mutationFn'> = {}) => {
  return useMutation(UnitApi.create, options);
};

export const useDeleteUnitData = (options: Omit<MutationOptions<boolean, AxiosError, {unit: UnitDATA, reason: string}>, 'mutationFn'> = {}) => {
  return useMutation<boolean, AxiosError<string>, {unit: UnitDATA, reason: string}>(UnitApi.delete, options);
}