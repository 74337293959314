import React, { useState, useEffect, useContext, useMemo } from "react";
import ImageImports from "../../../utils/ImageImports";
import classes from "./ManageDocumentGroups.module.css";
import Alert from "../../../components/Alert/Alert";
import Button from "../../../components/Button/Button";
import { useItemTypeCatalog } from "../../../customHooks/useItemTypeCatalog";
import { DocumentGroup } from "../../../utils/interface";
import ReportTypeListItem from "./ReportTypeListItem";
import AddNewDocumentGroupModal from "./AddNewDocumentGroupModal";
import NewGroupConfirmationModal from "./NewGroupConfirmationModal";
import DeletedGroupConfirmationModal from "./DeletedGroupConfirmationModal";
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy, sortableKeyboardCoordinates, arrayMove } from "@dnd-kit/sortable";
import { restrictToVerticalAxis, restrictToParentElement } from "@dnd-kit/modifiers";
import useWindowSize from "../../../customHooks/useWindowSize";
import Link from "../../../components/Link/Link";
import { Navigate, useOutletContext } from "react-router-dom";
import { TechnicalDocumentationModuleOutletInterface } from "../../TechnicalDocumentation/TechnicalDocumentationModuleContainer";
import { downloadFileByUrl } from "../../../customHooks/FileHttpServices";
import { useNavigate } from 'react-router-dom';
import { Store } from "../../../Store";

const { Arrow } = ImageImports;

function ManageDocumentGroups() {
  const navigate = useNavigate();
  const { isMobile, isTablet } = useWindowSize();
  const [showWarning, setShowWarning] = useState<boolean>(true);
  const [showAddNewGroupModal, setShowAddNewGroupModal] = useState<boolean>(false);
  const [showNewGroupConfirmationModal, setShowNewGroupConfirmationModal] = useState<boolean>(false);
  const [showDeletedGroupConfirmationModal, setShowDeletedGroupConfirmationModal] = useState<DocumentGroup | null>(null);
  const [reportTypes, setReportTypes] = useState<DocumentGroup[]>([]);
  const [selectedReportType, setSelectedReportType] = useState<DocumentGroup | null>(null);
  const [openEdits, setOpenEdits] = useState<number>(0);
  const [addGroupError, setAddGroupError] = useState<boolean>(false);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const {header: {data: {user}}} = useContext(Store);
  const { documentModule, documentGroups, setDocumentGroups, groupPermissions, reportPermissions } = useOutletContext<TechnicalDocumentationModuleOutletInterface>();

  const canAddGroups = useMemo(() => {
    return (user.portalAdmin && user.mpInternal) || groupPermissions.canAdd || groupPermissions.canAdmin;
  }, [user, groupPermissions]);

  const { setShowError } = useContext(Store);

  const { reorder: reorderDocumentGroups } = useItemTypeCatalog();
  const {
    create: createPowerReportType,
    update: updatePowerReportType,
    del: deletePowerReportType,
    getGenerateReportLink,
  } = documentModule.documentGroupApi;

  useEffect(() => {
    setReportTypes(documentGroups);
    setAddGroupError(false);
  }, [documentGroups]);

  const addNewDocumentGroup = async (name: string) => {
    try {
      const newReportType = await createPowerReportType({
        name,
      });
      setReportTypes([...reportTypes, newReportType]);
      setDocumentGroups([...reportTypes, newReportType]);
      setSelectedReportType(newReportType);
      setShowNewGroupConfirmationModal(true);
      setShowAddNewGroupModal(false);
      setAddGroupError(false);
    } catch (e) {
      setAddGroupError(true);
    }
  };

  const savePowerReportType = async (reportType: DocumentGroup): Promise<void> => {
 
    const savedReportType = await updatePowerReportType(reportType);
    // replace this the corresponding reportType in the state
    // get the index of the saved reportType by the itemTypeId
    const index = reportTypes.findIndex((reportType) => reportType.id === savedReportType.id);
    // if we found the itemTypeId in reportTypes, remove 1 element at that index and add the saved reportType at index spot
    // then reset reportTypes in the state
    if (index > -1) {
      let tmpReportTypes = [...reportTypes];
      tmpReportTypes.splice(index, 1, savedReportType);
      setReportTypes(tmpReportTypes);
    }
  };

  const handleGenrateReport = async (ItemTypeId:number) => {
    downloadFileByUrl(getGenerateReportLink(ItemTypeId));
  };

  const deleteReportType = async (deleteReportType: DocumentGroup): Promise<void> => {
	try {   
		const wasSuccessfull = await deletePowerReportType(deleteReportType);
		// remove this report type from the ui
		if (wasSuccessfull) {
		const index = reportTypes.findIndex((reportType) => reportType.id === deleteReportType.id);

		// if we found the itemTypeId in reportTypes, remove 1 element at that index
		// then reset reportTypes in the state
		if (index > -1) {
			let tmpReportTypes = [...reportTypes];
			tmpReportTypes.splice(index, 1);
			setReportTypes(tmpReportTypes);
			setDocumentGroups(tmpReportTypes);
		}
		setShowDeletedGroupConfirmationModal(deleteReportType);
		}
	}
	catch(error: any) {
		setShowError?.({
			isError: true,
			title: error.response?.data ? error.response?.data : error.message,
			ErrorType: "danger",
		});
	}
  };


  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = reportTypes.findIndex(({ id }) => id === active.id);
      const newIndex = reportTypes.findIndex(({ id }) => id === over.id);
      let tmpReportTypes: DocumentGroup[] = arrayMove(reportTypes, oldIndex, newIndex);

      // now we need to update the orders..
      // loop through report types and reassign orders
      for (let i = 0, displayOrder = 1; i < tmpReportTypes.length; i++, displayOrder++) {
        tmpReportTypes[i].displayOrder = displayOrder;
      }
      // update the database too
      reorderDocumentGroups(tmpReportTypes.map(({ id, displayOrder }) => ({ id, displayOrder })));
      setReportTypes(tmpReportTypes);
    }
  };

  return (
    <div
      className={`${classes.manage_document_groups} ${classes.flex_column} ${isMobile ? classes.is_mobile : ""} ${isTablet ? classes.is_tablet : ""}`}
    >
      <AddNewDocumentGroupModal shown={showAddNewGroupModal} onCreate={addNewDocumentGroup} onClose={() => setShowAddNewGroupModal(false)} error={addGroupError} />

        <NewGroupConfirmationModal
          shown={showNewGroupConfirmationModal}
          onClose={() => {
            setShowNewGroupConfirmationModal(false);
            setSelectedReportType(null);
          }}
          addNewGroup={() => {
            setShowNewGroupConfirmationModal(false);
            setShowAddNewGroupModal(true);
          }}
          addNewDocument={() => {
            setShowNewGroupConfirmationModal(false);
            navigate(`../${selectedReportType?.id}/add`);
          }}
          reportType={selectedReportType}
        />      
        <DeletedGroupConfirmationModal
          shown={!!(showDeletedGroupConfirmationModal !== null)}
          reportType={showDeletedGroupConfirmationModal}
          onClose={() => setShowDeletedGroupConfirmationModal(null)}
        />
      
      <div className={`${classes.intro} ${classes.flex_column}`}>
        <Link className={`${classes.back_container} ${classes.flex_row}`} to="..">
          <img src={Arrow} className="back-arrow" alt={`Back to ${documentModule.name}`} />
          Back to {documentModule.name}
        </Link>
        {showWarning && (
          <Alert onClose={() => setShowWarning(false)} type={"warning"}>
            Please note: any modifications made to report groups below will be applied to all users across all plant locations in the Customer Portal.
          </Alert>
        )}
        <div className={`${classes.section_title_cta} ${classes.flex_row}`}>
          <div className={`${classes.section_title}`}>
            <span className={`${classes.section_heading}`}>Manage Report Groups (All Plants)</span>
            <span className={`${classes.section_description}`}>
              Report groups cannot be deleted until the files within it have been moved to another group. Files can be moved on the previous{" "}
              {documentModule.name} page.
            </span>
          </div>
          {canAddGroups && 
            <div className={`${classes.cta_container}`}>
              <Button className={`darkBlue`} disabled={openEdits > 0} text="Add Report Group" onClick={() => setShowAddNewGroupModal(true)} />
            </div>
          }
        </div>
      </div>

      <div className={`${classes.report_types_container} ${classes.flex_column}`}>
        <DndContext
          onDragEnd={handleDragEnd}
          sensors={sensors}
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
          <SortableContext strategy={verticalListSortingStrategy} items={reportTypes}>
            {reportTypes.map((reportType) => (
              <ReportTypeListItem
                reportType={reportType}
                key={reportType.id}
                authorized={!!(user?.portalAdmin)}
                onSave={savePowerReportType}
                onGenrateReport={handleGenrateReport}
                editToggled={(isEditing) => {
                  isEditing ? setOpenEdits(openEdits + 1) : setOpenEdits(openEdits - 1);
                }}
                onDelete={deleteReportType}
                groupPermissions={groupPermissions} 
                reportPermissions={reportPermissions}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
}

export default ManageDocumentGroups;
