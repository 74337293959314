import axios, {AxiosResponse} from "axios";
import {apiItemTypeCatalog} from "../endpoints/endpoints";
import {CPApiResponse} from '../utils/interface';

export const useItemTypeCatalog = () => {

    const reorder = async (data: Array<{id: number, displayOrder: number}>): Promise<boolean> => {
        const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${apiItemTypeCatalog}/ReOrder`, {
            method: 'POST',
            data
        });
        return response.data.data;
    };

    return {reorder};
};