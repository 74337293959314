import { Form, Formik, FormikHelpers } from "formik";
import { useContext, useState } from "react";
import * as Yup from "yup";
import DropDownSelect from "../../UI/Form/DropDownSelect/DropDownSelect";
import TextInputField from "../../UI/Form/TextInputField/TextInputField";
import ModalGlobal from "../../UI/Modal/ModalGlobal";
import { Store } from "../../../Store";
import TextAreaField from "../../UI/Form/TextAreaField/TextAreaField";
import { AxiosError } from "axios";
import { useQueryClient } from "react-query";
import {
  useCreate_PlantEquipmentAttachment,
  useGetImage_PlantEquipmentAttachment,
  useGet_PlantEquipmentAttachment,
  useUpdate_PlantEquipmentAttachment,
} from "../../../containers/PlantInformation/EquipmentAttachmentsHttpServices";
import { useApiEquipmentAttachmentType } from "../../../containers/PlantInformation/TypesHttpServices";
import { useOnError } from "../../../customHooks/useOnError";
import { queryKeys } from "../../../react-query/queryKeys";
import { CloseAllModal } from "../../../utils/CloseAllModal";
import { toBase64 } from "../../../utils/FileUtils";
import { IdObj, PlantEquipmentAttachmentDTO, TypeDTO } from "../../../utils/interface";
import ErrorCard from "../../ErrorCard/ErrorCard";
import Loader from "../../Loader/Loader";
import DragDropFieldFormik from "../../UI/Form/DragDropField/DragDropFieldFormik";
import DragDropFieldInfo from "../../UI/Form/DragDropField/DragDropFieldInfo";

const FormNamePlatesAddEdit = ({ modaltitle, isEdit, editNamePlateData, attachmentID, handleAttachmentDelete }: FormAddNamePlatesProps) => {
  const {
    plantEquipment,
    setPlantEquipmentsAttachment,
    setEquipmentAttachmentType,
    equipmentTypes,
    unitTypes,
    plantEquipmentsAttachment,
    showError,
    //setShowError,
    equipmentAttachmentType: { data: attachmentTypes },
    isModal,
    setIsModal,
  } = useContext(Store);
  
  const [imgBase64, setImgBase64] = useState("");
  const [attachment, setAttachment] = useState<File | Blob>();
  const queryClient = useQueryClient();
  const setErrorData = useOnError();

  //{***************************************************************************************************************************}

  ///////////////////////////////////////////
  // Fetch useGetImage_PlantEquipmentAttachment Start
  ///////////////////////////////////////////

  const onSuccess = (data: Blob) => {
    setAttachment(data);
    toBase64(data)
      .then((base64Image: string) => setImgBase64(base64Image))
      .catch((err) => setErrorData(err));
  };

  const onError = (error: AxiosError<string>) => {
      if(isEdit || error.response?.status !== 400) {
        setErrorData(error);
      }
  };
  
  const { isLoading: attachmentIsLoading, isFetching: isFetchingAT } = useGetImage_PlantEquipmentAttachment(attachmentID, onSuccess, onError);  

  ///////////////////////////////////////////
  // Fetch useGetImage_PlantEquipmentAttachment End
  ///////////////////////////////////////////

  //{***************************************************************************************************************************}

  //{***************************************************************************************************************************}

  const onSuccessEquipmentAttachmentType = (data: TypeDTO) => {
    setEquipmentAttachmentType && setEquipmentAttachmentType(data);
  };
  const onErrorEquipmentAttachmentType = (error: AxiosError<string>) => {
    setErrorData(error);
  };
  const { isLoading: isLoading_AT } = useApiEquipmentAttachmentType(onSuccessEquipmentAttachmentType, onErrorEquipmentAttachmentType);

  //{***************************************************************************************************************************}

  const onSuccessEquipmentsAttachment = (data: PlantEquipmentAttachmentDTO) => {
    setPlantEquipmentsAttachment && setPlantEquipmentsAttachment(data);
  };
  const onErrorEquipmentsAttachment = (error: any) => {
      setErrorData(error);
  };
  useGet_PlantEquipmentAttachment(attachmentID, isEdit, onSuccessEquipmentsAttachment, onErrorEquipmentsAttachment);

  //{***************************************************************************************************************************}

  const { mutate: AddEquipmentAttachment, isLoading: isLoadingAdd } = useCreate_PlantEquipmentAttachment();
  const { mutate: EditEquipmentAttachment, isLoading: isLoadingEdit } = useUpdate_PlantEquipmentAttachment();

  //{***************************************************************************************************************************}

  const handleOnSubmit = (values: initialValuesProps, formikHelpers: FormikHelpers<initialValuesProps>) => {
    if (isEdit) {
      EditEquipmentAttachment(values, {
        onError: (error: unknown) => {
          setErrorData(error);
          formikHelpers.setSubmitting(false);
        },
        onSuccess: (responaseData) => {
          queryClient.invalidateQueries([queryKeys.plantEquipment, responaseData.data.equipmentId]);
          queryClient.invalidateQueries([queryKeys.getEquipmentAttachmentFile, attachmentID]);
          setPlantEquipmentsAttachment && setPlantEquipmentsAttachment(responaseData);
          setIsModal!({
            ...CloseAllModal,
            isEditedSuccess: {
              ...isModal.isEditedSuccess,
              isNameplate: true,
            },
          });
        },
      });
    } else {
      AddEquipmentAttachment(values, {
        onError: (error: unknown) => {
          setErrorData(error);
          formikHelpers.setSubmitting(false);
        },
        onSuccess: (responaseData) => {
          if (responaseData.success) {
            queryClient.invalidateQueries([queryKeys.plantEquipment, responaseData.data.equipmentId]);
            queryClient.invalidateQueries(queryKeys.plantEquipments);
            setPlantEquipmentsAttachment && setPlantEquipmentsAttachment(responaseData);
          }
          setIsModal!({
            ...CloseAllModal,
            isAddedSuccess: {
              ...isModal.isAddedSuccess,
              isNameplateSamePage: true,
              isNameplate: true,
            },
          });
        },
      });
    }
  };

  let initialValues;

  let validationSchema;

  validationSchema = Yup.object({
    name: Yup.string().required("This field is required"),
    file: Yup.mixed().notRequired(),
  });

  if (!isEdit) {
    initialValues = {
      equipmentId: plantEquipment.data.plantEquipmentId,
      name: "",
      EquipmentAttachmentId: 0,
      equipmentAttachmentTypeId: 1,
      description: "",
      associatedUnit: 1,
      file: "",
    };

    validationSchema = Yup.object({
      name: Yup.string().required("This field is required"),
      file: Yup.mixed().required(),
    });
  } else {
    initialValues = {
      equipmentId: plantEquipment.data.plantEquipmentId,
      name: plantEquipmentsAttachment.data.name,
      EquipmentAttachmentId: plantEquipmentsAttachment.data.equipmentAttachmentId,
      equipmentAttachmentTypeId: plantEquipmentsAttachment.data.equipmentAttachmentTypeId,
      description: plantEquipmentsAttachment.data.description ? plantEquipmentsAttachment.data.description : "",
      associatedUnit: 1,
      file: "",
    };
  }

  return (
    <ModalGlobal modaltitle={modaltitle} isModalHeader={true}>
      {
        <Formik enableReinitialize initialValues={initialValues} onSubmit={handleOnSubmit} validationSchema={validationSchema}>
          {(formikProps) => {
            return (
              <>
                {showError.isError && <ErrorCard ErrorMessage={showError.title} ErrorType={"danger"} />}
                <Form>
                  {isLoading_AT || isLoadingAdd || isLoadingEdit ? (
                    <Loader />
                  ) : (
                    <>
                      <div>
                        <TextInputField name="name" labelName="Name*" className="" placeholder="ex Nameplate" />
                      </div>
                      <div>
                        <DropDownSelect
                          name="equipmentAttachmentTypeId"
                          disabled={true}
                          options={attachmentTypes}
                          labelName="Attachment Type*"
                          defaultValue={1}
                        />
                      </div>

                      <div>
                        <TextAreaField
                          name="description"
                          placeholder="ex. Nameplate for specific unit"
                          className="input"
                          type="textarea"
                          labelName="Description"
                          display={"inline"}
                          height={64}
                        />
                      </div>
                      <div>
                        <DropDownSelect
                          disabled={true}
                          name="associatedunit"
                          options={unitTypes.data}
                          labelName="Associated Unit*"
                          defaultValue={plantEquipment.data.unitID}
                        />
                      </div>
                      <div>
                        <DropDownSelect
                          disabled={true}
                          name="equipmentId"
                          options={equipmentTypes.data}
                          labelName="Associated Equipment*"
                          defaultValue={plantEquipment.data.plantEquipmentId}
                        />
                      </div>
                      <div>
                        <DragDropFieldFormik
                          name="file"
                          displayName="Attachment*"
                          onChange={setAttachment}
                          fileLoading={attachmentIsLoading || isLoading_AT || isFetchingAT}
                          attachment={attachment}   
                          previewBase24={imgBase64}
                        />
                      </div>
                      <DragDropFieldInfo fileTypes=".PDF, .DOC, .XLSX, .JPG, .PNG" />                      
                    </>
                  )}

                  <div className="flex align-center">
                    <div className="flex-grow-1 flex gap-x-4">
                      {isEdit ? (
                        <>
                          <button type="submit" className="primaryBtn darkBlue" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                            Save Changes
                          </button>
                          <button
                            type="button"
                            className="primaryBtn redDelete"
                            disabled={formikProps.isSubmitting}
                            onClick={() =>
                              handleAttachmentDelete!({
                                equipmentid: plantEquipmentsAttachment.data.equipmentId,
                                attachmentid: plantEquipmentsAttachment.data.equipmentAttachmentId,
                              })
                            }
                          >
                            Delete
                          </button>
                        </>
                      ) : (
                        <>
                          <button type="submit" className="primaryBtn darkBlue" disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                            Add Nameplate
                          </button>
                          <button
                          type="button"
                          className="primaryBtn whiteBtn"
                          disabled={formikProps.isSubmitting}
                          onClick={() =>
                            setIsModal!({
                              ...CloseAllModal,
                            })
                          }
                        >
                          Cancel
                        </button>
                        </>
                      )}
                    </div>
                    <span>*Required fields</span>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      }
    </ModalGlobal>
  );
};

interface FormAddNamePlatesProps {
  modaltitle: string;
  isEdit: boolean;
  attachmentID: number;
  handleAttachmentDelete?(IdObj: IdObj): void;
  editNamePlateData?: {
    name: string;
    fileTypeName: string;
    description: string;
    attachments: string;
  };
}

interface initialValuesProps {
  equipmentId: number;
  name: string;
  EquipmentAttachmentId: number;
  equipmentAttachmentTypeId: number;
  description: string;
  associatedUnit: number;
  file: string;
}

export default FormNamePlatesAddEdit;
