
import axios, { AxiosError, AxiosResponse } from 'axios';
import { MutationFunction, MutationOptions, QueryFunctionContext, useMutation, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { apiBaseOrgUrl, apiPlantConfig, orgGroupApiUrl } from '../endpoints/endpoints';
import { handleAxiosResponse } from '../utils/handleAxiosResponse';
import { BaseOrg, BaseOrgGroup, CPApiResponse, PlantConfigData } from '../utils/interface';

declare type GetAllPlantsConfigParameters = ['plantConfig'];
export const getAllPlantsConfig = async (): Promise<PlantConfigData[]> => {
    const response: AxiosResponse<CPApiResponse<PlantConfigData[]>> = await axios(`${apiPlantConfig}`);
    const assignments = handleAxiosResponse(response);
    return assignments;
};
export const useGetAllPlantsConfig = (options: Omit<UseQueryOptions<PlantConfigData[], unknown, PlantConfigData[], GetAllPlantsConfigParameters>, 'queryKey' | 'queryFn'> = {}) => {
    return useQuery<PlantConfigData[], unknown, PlantConfigData[], GetAllPlantsConfigParameters>(['plantConfig'], getAllPlantsConfig, options);
}

declare type GetAllBaseOrgGroupsParameters = ['baseOrgGroups'];
const getAllBaseOrgGroups = async (): Promise<BaseOrgGroup[]> => {
    const response: AxiosResponse<CPApiResponse<BaseOrgGroup[]>> = await axios(orgGroupApiUrl);
    return handleAxiosResponse(response);
}

export const useGetAllBaseOrgGroups = (options: Omit<UseQueryOptions<BaseOrgGroup[], unknown, BaseOrgGroup[], GetAllBaseOrgGroupsParameters>, 'queryKey' | 'queryFn'> = {}) => {
    return useQuery<BaseOrgGroup[], unknown, BaseOrgGroup[], GetAllBaseOrgGroupsParameters>(['baseOrgGroups'], getAllBaseOrgGroups, {
        initialData: [],
        ...options
    });
}


export const createBaseOrgGroup: MutationFunction<BaseOrgGroup, Pick<BaseOrgGroup, 'name' | 'description'>> = async (data: Pick<BaseOrgGroup, 'name' | 'description'>): Promise<BaseOrgGroup> => {
    const response: AxiosResponse<CPApiResponse<BaseOrgGroup>> = await axios(orgGroupApiUrl, {
        method: 'POST',
        data
    });
    return handleAxiosResponse(response);
};

export const updateBaseOrgGroup: MutationFunction<BaseOrgGroup, BaseOrgGroup> = async (data: BaseOrgGroup): Promise<BaseOrgGroup> => {
    const response: AxiosResponse<CPApiResponse<BaseOrgGroup>> = await axios(`${orgGroupApiUrl}/${data.id}`, {
        method: 'PUT',
        data
    });
    return handleAxiosResponse(response);
};

declare type GetBaseOrgParameters = ['baseOrg', {baseOrgId: number}];
const getBaseOrg = async ({queryKey}: QueryFunctionContext<GetBaseOrgParameters>): Promise<BaseOrg> => {
    const [_key, {baseOrgId}] = queryKey;
    const response: AxiosResponse<CPApiResponse<BaseOrg>> = await axios(`${apiBaseOrgUrl}/${baseOrgId}`);
    return handleAxiosResponse(response);
};
export const useGetBaseOrg = (baseOrgId: number, options: Omit<UseQueryOptions<BaseOrg, AxiosError<string>, BaseOrg, GetBaseOrgParameters>, 'queryFn' | 'queryKek'> = {}) => {
    return useQuery<BaseOrg, AxiosError<string>, BaseOrg, GetBaseOrgParameters>(['baseOrg', {baseOrgId}], getBaseOrg, options);
};

export const deleteBaseOrgGroup: MutationFunction<boolean, number> = async (orgGroupId: number): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${orgGroupApiUrl}/${orgGroupId}`, {
        method: 'DELETE'
    });
    return handleAxiosResponse(response);
};

declare type AddBaseOrgToGroupParameters = {
    baseOrgId: number;
    orgGroupId: number;
};
const addBaseOrgToGroup: MutationFunction<boolean, AddBaseOrgToGroupParameters> = async (data: AddBaseOrgToGroupParameters): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${orgGroupApiUrl}/add-baseorg`, {
        method: 'POST',
        data
    });
    return handleAxiosResponse(response);
};

export const useAddBaseOrgToGroup = (options: Omit<MutationOptions<boolean, AxiosError<string>, AddBaseOrgToGroupParameters>, 'mutateFn'> = {}) => {
    return useMutation(addBaseOrgToGroup, options);
};

const removeBaseOrgFromGroup: MutationFunction<boolean, AddBaseOrgToGroupParameters> = async (data: AddBaseOrgToGroupParameters): Promise<boolean> => {
    const response: AxiosResponse<CPApiResponse<boolean>> = await axios(`${orgGroupApiUrl}/BaseOrgGroup/DeleteByBaseOrgIdOrgGroupId?BaseOrgId=${data.baseOrgId}&OrgGroupId=${data.orgGroupId}`, {
        method: 'DELETE'
    });
    return handleAxiosResponse(response);
};

export const useRemoveBaseOrgFromGroup = (options: Omit<MutationOptions<boolean, AxiosError<string>, AddBaseOrgToGroupParameters>, 'mutateFn'> = {}) => {
    return useMutation(removeBaseOrgFromGroup, options);
};