import React, { useEffect, useRef, useState, useCallback } from "react";

import QuoteAttachmentOptions from "./QuoteAttachmentOptions";
import classes from "./AttachmentCard.module.css";
import ImageImports from "../../utils/ImageImports";
import { base64Interface } from "../../utils/interface";
import { downloadInBrowser, PrintPDF } from "../../utils/FileUtils";
import Skeleton from "react-loading-skeleton";
import { CloseAllModal } from "../../utils/CloseAllModal";
import { useGet_FileBlob } from "../../containers/PartsAndService/QuoteRequestHttpServices";
import { supportedFileTypes } from "../../utils/getSupportedFileTypes";
import { useStore } from "../../useStore";
import dayjs from "dayjs";
import Loader from "../Loader/Loader";

interface AttachmentCardProps {
  key: number;
  data: base64Interface;
  fileType: string;
  lastUpdated: string;
  ext: string;
  disabled?: boolean;
  isExternal?: boolean;
  isEventClosed: boolean;
  getAttachmentID(id: number): void;
  isDocumentType: "isPODocument" | "isQuoteDocument" | "isQuoteRequest";
}

function AttachmentCardPlain(props: AttachmentCardProps) {
  // Image imports;
  const { ellipsis, trash, file_edit, download, print } = ImageImports;
  const [attachmentBlob, setAttachmentBlob] = useState<Blob | null>(null);
  const { isModal, setIsModal } = useStore();
  const [imgBase64, setImgBase64] = useState<string>("");
  
  const {isFetching, isLoading} = useGet_FileBlob(props.data.fileId, {
    enabled: props.data.fileId > 0,
    onSuccess(blob: Blob) {
      setAttachmentBlob(blob);
    },
    onError(err: unknown) {
      setAttachmentBlob(null);
    },
  });

  const popoutToolTipListItems = [
    {
      img: download,
      text: "Download",
      onclick: (id: number) => {
        if (attachmentBlob) {
          downloadInBrowser(attachmentBlob, props.data.name);
        }
      },
    },
    {
      img: print,
      text: "Print",
      onclick: (id: number) => {
        if (attachmentBlob) {
          PrintPDF(attachmentBlob);
        }
      },
    },
  ];

  if (!props.isEventClosed && !props.isExternal) {
    popoutToolTipListItems.push(
      {
        img: file_edit,
        text: "Edit",
        onclick: (id: number) => {
          props.getAttachmentID(id);
          if (props.isDocumentType === "isQuoteDocument") {
            setIsModal?.({
              ...CloseAllModal,
              isEdit: { ...isModal.isEdit, isQuoteDocument: true },
            });
          } else if (props.isDocumentType === "isPODocument") {
            setIsModal?.({
              ...CloseAllModal,
              isEdit: { ...isModal.isEdit, isPODocument: true },
            });
          } else if (props.isDocumentType === "isQuoteRequest") {
            setIsModal?.({
              ...CloseAllModal,
              isEdit: { ...isModal.isEdit, isQuoteRequest: true },
            });
          }
        },
      },
      {
        img: trash,
        text: "Delete",
        onclick: (id: number) => {
          props.getAttachmentID(id);
          if (props.isDocumentType === "isQuoteDocument") {
            setIsModal?.({
              ...CloseAllModal,
              isDelete: { ...isModal.isDelete, isQuoteDocument: true },
            });
          } else if (props.isDocumentType === "isPODocument") {
            setIsModal?.({
              ...CloseAllModal,
              isDelete: { ...isModal.isDelete, isPODocument: true },
            });
          }
        },
      }
    );
  }

  // const stateContext = useContext(Store);
  const ActionToolTipRef = useRef<HTMLImageElement>(null);
  const [actionToolTip, setActionToolTip] = useState(false);

  useEffect(() => {
    const handleCloseDropDown = (event: any) => {
      var path = event.path || (event.composedPath && event.composedPath());
      if (path[0] !== ActionToolTipRef.current) {
        setActionToolTip(false);
      }
    };
    document.body.addEventListener("click", handleCloseDropDown);
    //Cleanup
    return () => document.body.removeEventListener("click", handleCloseDropDown);
  }, []);

  useEffect(() => {
    setImgBase64(props.data.fileBase64);
  }, [props.data.fileBase64]);

  const PreviewContainer = useCallback(() => {
    const { docTypes, excelTypes, docTypeExtensions, excelTypeExtensions } = supportedFileTypes();
    const { excelDummy, docDummy, pdfDummy } = ImageImports;
    if (isLoading || isFetching) {
      return <Loader containerStyle={{marginTop:"50%", marginBottom:"50%"}} />;
    }

    if (props.data && imgBase64) {
      if (props.data.fileTypeObj.contentType.includes('image')) {
        return <img src={imgBase64} alt={`${props.data.name}`} className="object-contain w-full h-[200px]" onLoad={() => URL.revokeObjectURL(imgBase64 || '')} />;
      }  else if (props.data.fileTypeObj.contentType.includes("application")) {
        if (props.data.fileTypeObj.contentType.includes("application/pdf")) {
          return <img src={pdfDummy} alt={`${props.data.name}`} className="object-contain w-full h-[200px]" onLoad={() => URL.revokeObjectURL(pdfDummy || "")} />;
        } else {
          if (docTypes.find(type => type === `${props.data.fileTypeObj.contentType}`) || docTypeExtensions.find(type => type === `${props.data.fileTypeObj.extension}`)) {
            return <img src={docDummy} alt={`${props.data.name}`} className="object-contain w-full h-[200px]" onLoad={() => URL.revokeObjectURL(docDummy || "")} />;
          }
          if (excelTypes.find(type => type === `${props.data.fileTypeObj.contentType}`) || excelTypeExtensions.find(type => type === `${props.data.fileTypeObj.extension}`)) {
            return <img src={excelDummy} alt={`${props.data.name}`} className="object-contain w-full h-[200px]" onLoad={() => URL.revokeObjectURL(excelDummy || "")} />;
          }
        }
      }
    }

    return (
      <div style={{ textAlign: "center", color: "#3C5D69" }}>
        <p>File preview not available.</p>
      </div>
    );
  }, [isLoading, isFetching, props.data, imgBase64]);

  return (
    <>
      <div className={classes.nameplate_title}>
        {
          <div className={classes.nameplate_content} title={props.data.name}>
            <div className={classes.nameplate_ellipse} >
              <img
                ref={ActionToolTipRef}
                src={ellipsis}
                className="ellipsis"
                alt="ellipsis"
                style={{ filter: "var(--svgFilterDarkBlueButton)" }}
                onClick={() => {
                  !props.disabled && setActionToolTip(!actionToolTip);
                }}
                tabIndex={1}
              />
              <QuoteAttachmentOptions listItems={popoutToolTipListItems} displayTrigger={actionToolTip} id={props.data.quoteRequestAttachmentId} />
            </div>
            {(isFetching || isLoading) ? <Skeleton height={200} /> : <PreviewContainer />}

            <div className={`${classes.flex_grow1} ${classes.col_item} ${classes.nameplate_text_container}`}>
              <div className="col-item flex-grow-1 truncate block" >{props.data.name}</div>
              <div className={`${classes.flex_grow1} ${classes.col_item} ${classes.grey_font}`}>
                Last Modified: {`${dayjs(props.lastUpdated).format("MMM DD YYYY")}`}
              </div>
              {props.data.sapQuoteNumber && (
                <div className={`${classes.flex_grow1} ${classes.col_item} ${classes.grey_font}`}>
                  SAP Quote Number: {props.data.sapQuoteNumber ? props.data.sapQuoteNumber : "---"}
                </div>
              )}
            </div>
          </div>
        }
      </div>
    </>
  );
}

export default AttachmentCardPlain;
