import ImageImports from "../../../../utils/ImageImports";
import Button from "../../../../components/Button/Button";
import { useNavigate } from "react-router-dom";

function RoleConfigurationHeader() {
	const { settings } = ImageImports;
	const navigate = useNavigate();

	return (
		<div style={{ justifyContent: "space-between" }} className={`flex flex-row gap-6 `}>
			<div className={`flex flex-col gap-6`}>
				<div className={"font-bold text-2xl"}>Customer Portal Role Definitions</div>
				<div className={"gap-6 text-[font-size:var(--fs14px)]"}>Configure the permissions each role has for each Customer Portal site sub-module.</div>
			</div>
			<Button preImg={<img src={settings} style={{ filter: "var(--svgFilterWhite)" }} alt="" />} className={`darkBlue`} text="Manage Roles" onClick={() => navigate('/administration/role-configuration/manage-roles')} style={{ height: 36 }} />
		</div>
	);
}

export default RoleConfigurationHeader;
