import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { Store } from "../../Store";
import useWindowSize from "../../customHooks/useWindowSize";
import { useStore } from "../../useStore";
import ImageImports from "../../utils/ImageImports";
import { DiscussionItem } from "../../utils/interface";
import classes from "./DiscussionBoard.module.css";
import ThreadContainer from "./ThreadContainer";

type DiscussionOutletContext = {
  discussionItem: DiscussionItem;
}

function DiscussionBoardContainer() {
  const { setShowError, selectedPlant } = useStore();
  const { isDesktop } = useWindowSize();
  const navigate = useNavigate();
  const [currentPlantId, setCurrentPlantId] = useState<number>();
  const { header: {data: {user}} } = useContext(Store);
  const {discussionItem} = useOutletContext<DiscussionOutletContext>();

  const { Arrow } = ImageImports;

  useEffect(() => {
    setShowError?.({ title: "", ErrorType: null, isError: false });
  }, []);

  useEffect(() => {
    if (discussionItem && discussionItem.isLocked) {
      setShowError?.({
        title: `This issue report has been closed for more than 30 days and discussion has been locked.`,
        ErrorType: "info",
        isError: false,
      });
    }
    return () => setShowError?.({
      title: ``,
      ErrorType: "info",
      isError: false,
    });
  }, [discussionItem]);

  useEffect(() => {
    if (selectedPlant.plantId) {
      setCurrentPlantId(selectedPlant.plantId);
    }
  }, []);

  useEffect(() => {
    let sessionStoragePlantId = sessionStorage.getItem("plantId");
    if (sessionStoragePlantId) {
      if (currentPlantId && currentPlantId !== parseInt(sessionStoragePlantId)) {
        navigate("/issue-reports");
      }
    }
  }, [selectedPlant.plantId]);

  return (
    <div>
      {discussionItem && (
        <div>
          <div>
            <div style={{ marginBottom: 20 }}>
              <Link
                to="/issue-reports"
                style={{ color: "#3C5D69", textDecoration: "none", fontWeight: 700, fontSize: 14, display: "flex", alignItems: "center" }}
              >
                <img className={`back-arrow`} src={Arrow} alt="back" />
                Back to All {discussionItem.topic}s
              </Link>
            </div>
            <h3 className={`${classes.title} ${isDesktop ? classes.flex_row : ""}`}>
              <div>
                Discussion for {discussionItem.topic} #{discussionItem.id}: {discussionItem.description}
              </div>
              {discussionItem.statusBadge && (
                <div style={{ marginLeft: isDesktop ? 10 : 0 }}>
                  <span
                    style={{
                      backgroundColor: discussionItem.statusBadge.color,
                      fontSize: 14,
                      height: 24,
                      borderRadius: 12,
                      padding: "2px 16px",
                      color: "white",
                    }}
                  >
                    {discussionItem.statusBadge.label}
                  </span>
                </div>
              )}
            </h3>
            <div>
              <p className={classes.headerCopy}>
                This page contains the reports issued by the Mitsubishi Power TOMONI HUB (Remote Monitoring Center) related to the plant site(s)
                indicated. Access is also provided to the TOMONI HUB PI data summaries for those Users permissioned to view that data.
              </p>
            </div>
          </div>
          <hr />
          <ThreadContainer discussionItem={discussionItem} />
        </div>
      )}
    </div>
  );
}

export default DiscussionBoardContainer;
