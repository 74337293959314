import axios from "axios";
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { MutationFunction, UseMutationOptions, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import { Checkbox } from "../../components/UI/Form/Checkbox/Checkbox";
import { PlainDropDown } from "../../components/UI/Form/DropDownSelect/PlainDropDown";
import MiniTooltip from "../../components/UI/MiniTooltip/MiniTooltip";
import { useGetAllCountriesOfCitizenship } from "../../customHooks/CountryOfCitizenshipHttpServices";
import { controllerApiUrl } from "../../endpoints/endpoints";
import ImageImports from "../../utils/ImageImports";
import {
  AdminContactEmail,
  PrivacyPolicy,
  TermsOfUse,
  UgpPrivacyPolicy,
  UgpTermsOfUse,
} from "../../utils/globalVariables";
import { handleAxiosResponse } from "../../utils/handleAxiosResponse";
import { Country } from "../../utils/interface";
import { siteKey, useCaptcha } from "../../utils/reCaptchaConfig";

const { help, logo } = ImageImports;

interface IPortalAccessRequest {
  fName: string;
  lName: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  jobTitle: string;
  plantAffiliation: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  countryId: Country["id"];
  captcha: string;
  accessNeeded: { id: number };
  agreeTerms: boolean;
  countryOfCitizenshipId: Country["id"];
}

const createPortalAccessRequest: MutationFunction<
  boolean,
  IPortalAccessRequest
> = async (req: IPortalAccessRequest): Promise<boolean> => {
  const response = await axios(
    `${controllerApiUrl}/CreatePortalAccessRequest`,
    {
      method: "POST",
      data: req,
    }
  );
  return handleAxiosResponse(response);
};

const useCreatePortalAccessRequest = (
  options: Omit<
    UseMutationOptions<boolean, unknown, IPortalAccessRequest, unknown>,
    "mutationKey" | "mutationFn"
  > = {}
) => {
  return useMutation("", createPortalAccessRequest, options);
};

const defaultFormState = {
  fName: "",
  lName: "",
  email: "",
  phoneNumber: "",
  companyName: "",
  jobTitle: "",
  plantAffiliation: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  postalCode: "",
  countryId: 0,
  captcha: "",
  accessNeeded: { id: 1 },
  agreeTerms: false,
  countryOfCitizenshipId: 0,
};

const PortalAccessRequest: FunctionComponent = () => {
  const navigate = useNavigate();
  const formReference = useRef<HTMLFormElement>(null);
  const [isFormValid, setFormValidity] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<IPortalAccessRequest>({
    ...defaultFormState,
  });
  const { data: countries } = useGetAllCountriesOfCitizenship();
  const ref = useRef<HTMLDivElement>(null);
  const countryRef = useRef<HTMLDivElement>(null);

  const { mutate: sendRequest, isSuccess } = useCreatePortalAccessRequest({
    onSuccess: () => {
      setFormValues({ ...defaultFormState });
      navigate("./submitted");
    },
  });

  const checkCaptcha = useCallback((f: HTMLFormElement): boolean => {
    if (!useCaptcha) {
      return true;
    }
    const capField = "g-recaptcha-response";
    const fd = new FormData(f);
    return fd.has(capField) && !!fd.get(capField);
  }, []);

  const formChange = useCallback(() => {
    const f = formReference.current;
    if (f) {
      setFormValidity(
        f.checkValidity() &&
          checkCaptcha(f) &&
          formValues.agreeTerms &&
          formValues.countryOfCitizenshipId > 0 &&
          formValues.countryId > 0
      );
    }
  }, [formValues, checkCaptcha]);

  useEffect(formChange, [formChange, formValues]);

  const submitForm: React.FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      sendRequest(formValues);
      e.preventDefault();
      return false;
    },
    [formValues, sendRequest]
  );

  const successMsg =
    "Mitsubishi Power will contact you if there are any issues with or questions about your request; otherwise, you will be notified via email with your login credentials.";

  return (
    <div className="flex flex-col flex-nowrap grow min-h-[calc(100vh-176px)] bg-primary-20">
      <div className="content">
        {isSuccess ? (
          <div className="flex flex-col p-8 gap-6 relative bg-white z-10 max-w-full md:w-2/3 lg:w-1/2 xl:w-1/3 mx-auto h-fit rounded-2xl justify-center items-center shadow-2xl shadow-black/10">
            <div className="!pb-4 gap-6 flex flex-row justify-center items-start self-stretch text-xl font-bold">
              Customer Portal Access Request Submitted
            </div>
            <span className="text-center">{successMsg}</span>
          </div>
        ) : (
          <div className="flex flex-1 flex-col p-6 gap-6 relative bg-white z-10 w-full max-w-full md:max-w-3xl mx-auto my-20">
            <div>
              <img src={logo} className="nopointer h-8 mb-2" alt="logo" />
            </div>

            <div className="flex flex-col items-start gap-2">
              <span className="text-base md:text-xl font-bold">
                Customer Portal Access Request Form
              </span>
              <span className="text-sm md:text-base mb-4">
                Complete the form below by providing the required information to
                apply for registration. You must be an employee of an owner or
                operator of Mitsubishi-supplied equipment or services to be
                granted access to this website. Please use your company e-mail
                address for your registration. You will also be registered with
                the applicable Users' Groups representing your plant.
              </span>

              <span className="text-sm md:text-base">
                Collected personal information is used to verify your
                eligibility for access to this website and the Users' Group
                website and will be made available on the website to other
                members in the Users' Group(s) for which you are registered, and
                used by Mitsubishi to send notifications and keep their User
                contact databases updated.
              </span>
            </div>

            <form
              ref={formReference}
              onSubmit={submitForm}
              className="w-full md:max-w-3xl"
            >
              <h4 className="pb-4">Plant Affiliation</h4>
              <div className={`flex flex-wrap mb-6 gap-6`}>
                <div className="flex flex-1 flex-col gap-2 w-full">
                  <label
                    htmlFor="plantaffiliation"
                    className="flex flex-row gap-1 items-center !pb-0"
                  >
                    Plant Name(s)*
                    <MiniTooltip text="Please list the Plant site(s) that you are requesting access to in the Portal">
                      <img src={help} alt="help" height="16" width="16" />
                    </MiniTooltip>
                  </label>
                  <input
                    id="plantaffiliation"
                    name="plantaffiliation"
                    type="text"
                    placeholder="Brunswick County and Greensville"
                    required
                    value={formValues.plantAffiliation}
                    onChange={(e) =>
                      setFormValues((v) => ({
                        ...v,
                        plantAffiliation: e.target.value,
                      }))
                    }
                    className="appearance-none px-4 h-12 text-base md:text-sm md:h-auto py-1.5 border border-dark-gray rounded shadow-sm placeholder-dark-gray focus:outline-none"
                  />
                </div>
              </div>

              <h4 className="pb-4">Contact Information</h4>
              <div className={`flex flex-wrap mb-6 gap-6`}>
                <div className="flex flex-1 flex-col gap-2 w-full md:!w-1/2">
                  <label htmlFor="firstname">First Name*</label>
                  <input
                    id="firstname"
                    name="firstname"
                    type="text"
                    placeholder="First name"
                    required
                    value={formValues.fName}
                    onChange={(e) =>
                      setFormValues((v) => ({ ...v, fName: e.target.value }))
                    }
                    className="appearance-none px-4 h-12 text-base md:text-sm md:h-auto py-1.5 border border-dark-gray rounded shadow-sm placeholder-dark-gray focus:outline-none"
                  />
                </div>
                <div className="flex flex-1 flex-col gap-2 !w-full md:!w-1/2">
                  <label htmlFor="lastname">Last Name*</label>
                  <input
                    id="lastname"
                    name="lastname"
                    type="text"
                    placeholder="Last name"
                    required
                    value={formValues.lName}
                    onChange={(e) =>
                      setFormValues((v) => ({ ...v, lName: e.target.value }))
                    }
                    className="appearance-none px-4 h-12 text-base md:text-sm md:h-auto py-1.5 border border-dark-gray rounded shadow-sm placeholder-dark-gray focus:outline-none"
                  />
                </div>
              </div>

              <div className={`flex flex-wrap mb-6 gap-6`}>
                <div className="flex flex-1 flex-col gap-2 w-full md:w-1/2">
                  <label htmlFor="companyName">Company*</label>
                  <input
                    id="companyName"
                    name="companyName"
                    type="text"
                    placeholder="Company Name"
                    required
                    value={formValues.companyName}
                    onChange={(e) =>
                      setFormValues((v) => ({
                        ...v,
                        companyName: e.target.value,
                      }))
                    }
                    className="appearance-none px-4 h-12 text-base md:text-sm md:h-auto py-1.5 border border-dark-gray rounded shadow-sm placeholder-dark-gray focus:outline-none"
                  />
                </div>

                <div className="flex flex-1 flex-col gap-2 w-full md:w-1/2">
                  <label htmlFor="jobtitle">Business Job Title*</label>
                  <input
                    id="jobtitle"
                    name="jobtitle"
                    type="text"
                    required
                    value={formValues.jobTitle}
                    onChange={(e) =>
                      setFormValues((v) => ({ ...v, jobTitle: e.target.value }))
                    }
                    placeholder="Job title or description"
                    className="appearance-none px-4 h-12 text-base md:text-sm md:h-auto py-1.5 border border-dark-gray rounded shadow-sm placeholder-dark-gray focus:outline-none"
                  />
                </div>
              </div>

              <div className={`flex flex-wrap mb-6 gap-6`}>
                <div className="flex flex-1 flex-col gap-2 w-full md:w-1/2">
                  <label htmlFor="email">Business Email*</label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="example@domain.com"
                    required
                    value={formValues.email}
                    onChange={(e) =>
                      setFormValues((v) => ({ ...v, email: e.target.value }))
                    }
                    className="appearance-none px-4 h-12 text-base md:text-sm md:h-auto py-1.5 border border-dark-gray rounded shadow-sm placeholder-dark-gray focus:outline-none"
                  />
                </div>
                <div className="flex flex-1 flex-col gap-2 w-full md:w-1/2">
                  <label htmlFor="phone">Business Phone Number*</label>
                  <input
                    id="phone"
                    name="phone"
                    type="tel"
                    placeholder="(000) 000-0000"
                    required
                    value={formValues.phoneNumber}
                    onChange={(e) =>
                      setFormValues((v) => ({
                        ...v,
                        phoneNumber: e.target.value,
                      }))
                    }
                    className="appearance-none px-4 h-12 text-base md:text-sm md:h-auto py-1.5 border border-dark-gray rounded shadow-sm placeholder-dark-gray focus:outline-none"
                  />
                </div>
              </div>

              <div className={`flex flex-wrap mb-6 gap-6`}>
                <div className="flex flex-1 flex-col gap-2 w-full" ref={ref}>
                  <label htmlFor="countryOfCitizenship">
                    Country of Citizenship*
                  </label>
                  <PlainDropDown
                    className="w-full"
                    selectClass="flex flex-row justify-between px-4 h-12 text-base md:text-sm md:h-auto py-1 border border-dark-gray rounded shadow-sm placeholder-dark-gray items-center cursor-pointer"
                    optionsClass="flex flex-col !flex-nowrap p-1 bg-white rounded max-h-80"
                    dropdownStyles={{
                      overflow: "auto",
                      width: ref.current
                        ? ref.current.getBoundingClientRect().width
                        : undefined,
                    }}
                    defaultText="Country of Citizenship"
                    value={formValues.countryOfCitizenshipId}
                    onSelection={(e) => {
                      setFormValues((v) => ({
                        ...v,
                        countryOfCitizenshipId: e.id || 0,
                      }));
                    }}
                    itemClass="py-[14px] px-6 cursor-pointer hover:bg-primary-20"
                    options={countries || []}
                  />
                </div>
              </div>

              <h4 className="pb-4">Address</h4>
              <div className={`flex flex-wrap mb-6 gap-6`}>
                <div className="flex flex-1 flex-col gap-2 w-full">
                  <label htmlFor="address1">Address 1*</label>
                  <input
                    id="address1"
                    name="address1"
                    type="text"
                    placeholder="Address"
                    required
                    value={formValues.address1}
                    onChange={(e) =>
                      setFormValues((v) => ({ ...v, address1: e.target.value }))
                    }
                    className="appearance-none px-4 h-12 text-base md:text-sm md:h-auto py-1.5 border border-dark-gray rounded shadow-sm placeholder-dark-gray focus:outline-none"
                  />
                </div>
              </div>

              <div className={`flex flex-wrap mb-6 gap-6`}>
                <div className="flex flex-1 flex-col gap-2 w-full">
                  <label htmlFor="address2">Address 2</label>
                  <input
                    id="address2"
                    name="address2"
                    type="text"
                    placeholder="Suite 300"
                    value={formValues.address2}
                    onChange={(e) =>
                      setFormValues((v) => ({
                        ...v,
                        address2: e.target.value,
                      }))
                    }
                    className="appearance-none px-4 h-12 text-base md:text-sm md:h-auto py-1.5 border border-dark-gray rounded shadow-sm placeholder-dark-gray focus:outline-none"
                  />
                </div>
              </div>

              <div className={`flex flex-wrap mb-6 gap-6`}>
                <div className="flex flex-1 flex-col gap-2 w-full md:w-1/2">
                  <label htmlFor="city">City*</label>
                  <input
                    id="city"
                    name="city"
                    type="text"
                    placeholder="City"
                    required
                    value={formValues.city}
                    onChange={(e) =>
                      setFormValues((v) => ({ ...v, city: e.target.value }))
                    }
                    className="appearance-none px-4 h-12 text-base md:text-sm md:h-auto py-1.5 border border-dark-gray rounded shadow-sm placeholder-dark-gray focus:outline-none"
                  />
                </div>
                <div className="flex flex-1 flex-col gap-2 w-full md:w-1/2">
                  <label htmlFor="state">State/Province/District*</label>
                  <input
                    id="state"
                    name="state"
                    type="text"
                    placeholder="State/Province/District"
                    required
                    value={formValues.state}
                    onChange={(e) =>
                      setFormValues((v) => ({
                        ...v,
                        state: e.target.value,
                      }))
                    }
                    className="appearance-none px-4 h-12 text-base md:text-sm md:h-auto py-1.5 border border-dark-gray rounded shadow-sm placeholder-dark-gray focus:outline-none"
                  />
                </div>
              </div>

              <div className={`flex flex-wrap mb-6 gap-6`}>
                <div className="flex md:flex-1 flex-col gap-2 w-full md:w-1/2">
                  <label htmlFor="postalCode">Postal Code</label>
                  <input
                    id="postalCode"
                    name="postalCode"
                    type="text"
                    placeholder="Postal Code"
                    value={formValues.postalCode}
                    onChange={(e) =>
                      setFormValues((v) => ({
                        ...v,
                        postalCode: e.target.value,
                      }))
                    }
                    className="appearance-none px-4 h-12 text-base md:text-sm md:h-auto py-1.5 border border-dark-gray rounded shadow-sm placeholder-dark-gray focus:outline-none"
                  />
                </div>
                <div
                  className="flex md:flex-1 flex-col gap-2 w-full md:w-1/2"
                  ref={countryRef}
                >
                  <label htmlFor="country">Country*</label>
                  <PlainDropDown
                    className=""
                    selectClass="flex flex-row justify-between px-4 h-12 text-base md:text-sm md:h-auto py-1 border border-dark-gray rounded shadow-sm placeholder-dark-gray items-center cursor-pointer"
                    optionsClass="flex flex-col !flex-nowrap p-1 bg-white rounded max-h-80"
                    dropdownStyles={{
                      overflow: "auto",
                      width: countryRef.current
                        ? countryRef.current.getBoundingClientRect().width
                        : undefined,
                    }}
                    defaultText="Country"
                    value={formValues.countryId}
                    onSelection={(e) => {
                      setFormValues((v) => ({
                        ...v,
                        countryId: e.id || 0,
                      }));
                    }}
                    itemClass="py-[14px] px-6 cursor-pointer hover:bg-primary-20"
                    options={countries || []}
                  />
                </div>
              </div>

              <div className={`flex flex-col gap-6`}>
                <div className="flex flex-row w-full">
                  <span className="text-sm md:text-base">
                    I acknowledge and understand that the content provided
                    within this Customer Portal is confidential and subject to
                    confidentiality and use restrictions within agreements to
                    which my company has already agreed (including without
                    limitation: an LTSA, TOMONI Solutions Agreement, or others
                    as applicable).
                  </span>
                </div>

                <div className="flex flex-row w-full">
                  <span className="text-sm md:text-base">
                    If you have any questions or difficulties in receiving your
                    registration,
                    <button
                      className="text-ocean !bg-transparent font-normal border-none text-sm md:text-base hover:cursor-pointer outline-none focus:outline-none "
                      type="button"
                      onClick={() =>
                        (window.location.href = `mailto:${AdminContactEmail}`)
                      }
                    >
                      contact
                    </button>
                    the Customer Portal Administrator for assistance.
                  </span>
                </div>

                <label
                  className="flex flex-row flex-nowrap gap-3 items-start !pb-0 self-stretch"
                  htmlFor="agreeTerms"
                >
                  <Checkbox
                    tabIndex={0}
                    name="agreeTerms"
                    required
                    isChecked={formValues.agreeTerms}
                    onChange={() =>
                      setFormValues((v) => ({
                        ...v,
                        agreeTerms: !v.agreeTerms,
                      }))
                    }
                    className="mt-1"
                  />
                  <span className="break-words text-sm md:text-base">
                    I have read and agree to the{" "}
                    <a
                      className="no-underline text-ocean text-sm md:text-base"
                      href={PrivacyPolicy}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Customer Portal Privacy Policy
                    </a>
                    ,{" "}
                    <a
                      className="no-underline text-ocean text-sm md:text-base"
                      href={TermsOfUse}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Customer Portal Terms of Use
                    </a>
                    ,{" "}
                    <a
                      className="no-underline text-ocean text-sm md:text-base"
                      href={UgpPrivacyPolicy}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Users' Groups Privacy Policy
                    </a>
                    , and{" "}
                    <a
                      className="no-underline text-ocean text-sm md:text-base"
                      href={UgpTermsOfUse}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Users' Groups Terms of Use
                    </a>
                    .
                  </span>
                </label>

                {useCaptcha && (
                  <div className="flex flex-row">
                    <ReCAPTCHA
                      sitekey={siteKey}
                      onChange={(cap) => {
                        setFormValues((v) => ({ ...v, captcha: cap ?? "" }));
                      }}
                    />
                  </div>
                )}

                <div className="flex justify-between items-center flex-col md:flex-row gap-6 mb-10">
                  <div className="flex !pb-0 flex-col md:flex-row md:gap-6">
                    <div>
                      <button
                        type="submit"
                        className="primaryBtn bg-dark-blue darkBlue text-sm"
                        disabled={!isFormValid}
                      >
                        Request Access
                      </button>
                    </div>
                    <div>
                      <Button
                        className="whiteBtn text-sm"
                        text={"Cancel"}
                        onClick={() => navigate("/splash")}
                      />
                    </div>
                  </div>
                  <span className="whitespace-nowrap text-sm">
                    *Required fields
                  </span>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default PortalAccessRequest;
