
import Moment from "react-moment";
import { Link } from "react-router-dom";
import classes from "../../containers/Reports/ReportsToolTipData/ReportsToolTipData.module.css";
import ImageImports from "../../utils/ImageImports";
import { Report } from "../../utils/interface";

const { Arrow } = ImageImports;

export const ViewDocPaneContent = ({report}: {report: Report}) => {
    return (
      <div className={classes.wrp}>
        <div className={classes.row}>
          <h2 className={classes.title}> Details for {report.displayFileName}</h2>
        </div>
        <div className={classes.row}>
          <p>Please find the details for “{report.displayFileName}” below.</p>
        </div>
        <div className={`${classes.row} ${classes.mb26}`}>
          <div className={classes.badgeLabel}>Implementation Status</div>
          {
            report.implementationStatus.toLowerCase() === "completed" ?
              <div className={classes.badgeCompleted}>{report.implementationStatus}</div>
              : report.implementationStatus.toLowerCase() === "not performed" ?
                <div className={classes.badgeNotPerformed}>{report.implementationStatus}</div> :
                <div className={classes.badgePartiallyPerformed}>{report.implementationStatus}</div>
          }
        </div>
        <div className={`${classes.row} ${classes.mb26}`}>
          <div className={classes.label}>Scope</div>
          <div className={classes.details}>{report.scope} </div>
        </div>
        <div className={`${classes.row} ${classes.mb26}`}>
          <div className={classes.label}>Summary</div>
          <div className={classes.details}> {report.summaryGuideline} </div>
        </div>
        <div className={`${classes.row} ${classes.mb26}`}>
          <div className={classes.label}>Document Group</div>
          <div className={classes.details}>{report.reportType}</div>
        </div>
        <div className={`${classes.row} ${classes.mb26}`}>
          <div className={classes.label}>Last Modified</div>
          <div className={classes.details}>
            <Moment date={report.updated} format="MM/DD/YYYY" />
          </div>
        </div>
        <div className={classes.row}>
          <Link to={`shared-document/${report?.fileId}`} className="no-underline text-dark-blue flex flex-row gap-[10px] font-bold" target="_blank" rel="noreferrer">
            View the full document
            <img src={Arrow} className="mt-[-1px]" style={{filter: 'var(--svgFilterDarkBlueButton)'}} />
          </Link>
        </div>
      </div>
    )
  }