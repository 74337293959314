import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { UseQueryResult, useIsFetching } from "react-query";
import { Store } from "../../Store";
import { useGet_PartsFileByIDS, useUpdate_QuoteStatus } from "../../containers/PartsAndService/QuoteRequestHttpServices";
import { useOnError } from "../../customHooks/useOnError";
import { queryKeys } from "../../react-query/queryKeys";
import { queryClient } from "../../react-query/useQueryClientGet";
import { CloseAllModal } from "../../utils/CloseAllModal";
import { QuoteFormInterfaceDTO, QuoteRequestAttachment, QuoteRequestGetAll, base64Interface } from "../../utils/interface";
import { ItemTypeCatalogIds, QuoteRequestAttachmentTypeID, QuoteRequestEventID } from "../../utils/moduleIDs";
import FormSuccess from "../FormsPages/FormSuccess/FormSuccess";
import AttachQuoteDocumentModal from "./AttachQuoteDocumentModal";
import AttachmentCardPlain from "./AttachmentCardPlain";
import DeleteModal from "./DeleteModal";
import classes from "./QuoteRequestForm.module.css";

const QuoteDocumentUpload = ({ quoteRequestItem, isEventClosed, isExternal }: quoteProps) => {
  const setErrorData = useOnError();
  const { setIsModal, isModal } = useContext(Store);
  const isFetching = useIsFetching([queryKeys.getQuoteRequest]);
  const [quoteRequestAttachmentIds, setQuoteRequestAttachmentIds] = useState<QuoteRequestAttachment[] | []>([]);
  const [attachmentID, setAttachmentID] = useState<number>(0);
  const [quoteRequestList, setQuoteRequestList] = useState(quoteRequestItem);

  const { mutate: updateQuoteStatus } = useUpdate_QuoteStatus();

  const { user } = useContext(Store);

  useEffect(() => {
    setQuoteRequestList(quoteRequestItem);
  }, [quoteRequestItem]);

  useEffect(() => {
    if (quoteRequestItem.quoteRequestAttachments.length > 0) {
      const filteredAttachments = quoteRequestItem.quoteRequestAttachments.filter((attachment) => {
        if (attachment) {
          return attachment.quoteRequestAttachmentTypeId === QuoteRequestAttachmentTypeID.QuoteDocument;
        } else {
          return null;
        }
      });

      setQuoteRequestAttachmentIds(filteredAttachments);
    } else {
      setQuoteRequestAttachmentIds([]);
    } 
  }, [quoteRequestItem]);

  const getAttachmentID = (id: number) => {
    setAttachmentID(id);
  };
  const handleDeleteAttachment = (id: number) => {
    setAttachmentID(id);
    setIsModal &&
      setIsModal({
        ...CloseAllModal,
        isDelete: { ...isModal.isDelete, isQuoteDocument: true },
      });
  };

  const handleQuoteClosed = () => {
    updateQuoteStatus(
      {
        quoteRequestId: quoteRequestList.quoteRequestId,
        quotestatusTypeId: QuoteRequestEventID.QuoteClosed,
      },
      {
        onSuccess: (quoteResponse: QuoteFormInterfaceDTO) => {
          queryClient.invalidateQueries([queryKeys.getQuoteRequest, quoteResponse.data[0].globalItemId]);
          setIsModal &&
            setIsModal({
              ...CloseAllModal,
            });
        },
        onError: (error: unknown) => {
          setErrorData(error);
        },
      }
    );
  };

  const isPresent = quoteRequestList.quoteRequestAttachments
    .filter((e) => (e ? e : null))
    .map((e) => e.quoteRequestAttachmentTypeId)
    .indexOf(QuoteRequestAttachmentTypeID.QuoteDocument);

  const queryResult = useGet_PartsFileByIDS(quoteRequestAttachmentIds);
  const isLoadingAttachments = queryResult.some((result) => result.isLoading);
  
  // parts requests can have <=5 uploads -- service = 1
  const canAddDocument: boolean =
    quoteRequestItem.itemTypeId === ItemTypeCatalogIds.PartsQuoteRequest
      ? queryResult.length < 5
      : quoteRequestItem.itemTypeId === ItemTypeCatalogIds.ServiceQuoteRequest
      ? queryResult.length < 1
      : false;

  const handleAnotherDocument = () => {
    setIsModal?.({
      ...CloseAllModal,
      isAdd: { ...isModal.isAdd, isQuoteDocument: true },
    });
  };
  return (
    <>
      {!isExternal && (
        <>
          {canAddDocument && (
            <div className={`${classes.mb32}`}>
              <div className={`${classes.subtitle}`}>
                <strong>Add a Quote Request Document(s)</strong>
              </div>

              {quoteRequestList.itemTypeId === ItemTypeCatalogIds.PartsQuoteRequest ? (
                <p>
                  In order to close this quote request, you must upload at least one quote document for the customer to review. For part quote
                  requests, you may upload up to five documents for partial quoting purposes. Uploading a quote attachment will notify the user(s)
                  associated with this request.
                </p>
              ) : (
                <p>
                  In order to close this quote request, you must upload at least one quote document for the customer to review. Uploading a quote
                  attachment <strong>will automatically close this quote request</strong> and will notify the user(s) associated with this request.{" "}
                </p>
              )}

              {!isExternal && (
                <>
                  {quoteRequestList.itemTypeId === ItemTypeCatalogIds.ServiceQuoteRequest && (
                    <p>
                      Note: The original request (and attachments) may still be referenced at any time after this request has been automatically
                      closed.
                    </p>
                  )}
                </>
              )}
            </div>
          )}

          {canAddDocument && (
            <div className={`${classes.mb32}`}>
              <button
                type="button"
                className={`primaryBtn whiteBtn ${classes.buttonMargin} ${classes.addMoreBtn}`}
                disabled={isEventClosed || queryResult.length >= 5}
                onClick={() =>
                  setIsModal?.({
                    ...CloseAllModal,
                    isAdd: { ...isModal.isAdd, isQuoteDocument: true },
                  })
                }
              >
                Attach Document
              </button>
            </div>
          )}
          {queryResult.length > 0 && (
            <div className={`${classes.mb24}`}>
              <div className={`${classes.subtitle}`}>
                <strong>Uploaded Document(s)</strong>
              </div>
            </div>
          )}
        </>
      )}

      <div className={classes.attachmentwrapper}>
        {queryResult.length > 0 ? (
          queryResult.map((pd: UseQueryResult<base64Interface, unknown>) => {
            if (pd.data) {
              return (
                <AttachmentCardPlain
                  key={pd.data.fileId}
                  isEventClosed={isEventClosed}
                  isExternal={isExternal}
                  data={pd.data}
                  fileType={"sdsdasd"}
                  ext={`.${"pd.data.fileTypeObj.extension"}`}
                  lastUpdated={`${dayjs(pd.data?.created).format("MM/DD/YYYY")}`}
                  getAttachmentID={getAttachmentID}
                  disabled={!!isFetching || !!isLoadingAttachments}
                  isDocumentType={"isQuoteDocument"}
                />
              );
            } else {
              return null;
            }
          })
        ) : (
          <div style={{ marginLeft: "1%" }}>
            {isExternal ? (
              <p>
                Your Mitsubishi Power Program Manager has not updated this step. Please check back later or contact your Program Manager for more
                details.
              </p>
            ) : (
              <p>No documents have been uploaded.</p>
            )}
          </div>
        )}
      </div>
      {!canAddDocument && !isExternal && <p>Note: Updating an above quote attachment will notify the user(s) associated with this request.</p>}

      {!isExternal && (
        <>
          {quoteRequestList.itemTypeId === ItemTypeCatalogIds.PartsQuoteRequest && (
            <>
              <p>Note: The original request (and attachments) may still be referenced at any time after this request has been closed.</p>
              <div className="flex align-center">
                <div className="flex-grow-1 flex">
                  <button
                    type="submit"
                    className="primaryBtn darkBlue"
                    disabled={isEventClosed || isPresent === -1}
                    onClick={() => {
                      setIsModal?.({
                        ...CloseAllModal,
                        isView: { ...isModal.isView, isCloseQuoteRequest: true },
                      });
                    }}
                  >
                    Close Quote Request
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}

      {isModal.isAdd.isQuoteDocument && (
        <AttachQuoteDocumentModal
          modaltitle={`Add Quote Request Document`}
          quoteRequestAttachmentTypeID={QuoteRequestAttachmentTypeID.QuoteDocument}
          attachmentID={0}
          quoteRequestList={quoteRequestList}
          isEdit={false}
        />
      )}
      {isModal.isEdit.isQuoteDocument && (
        <AttachQuoteDocumentModal
          modaltitle={`Edit Quote Request Document`}
          quoteRequestAttachmentTypeID={QuoteRequestAttachmentTypeID.QuoteDocument}
          attachmentID={attachmentID}
          handleDeleteAttachment={handleDeleteAttachment}
          quoteRequestList={quoteRequestList}
          isEdit={true}
        />
      )}
      {isModal.isDelete.isQuoteDocument && <DeleteModal modaltitle={""} modaltitlename={`Delete Quote Request Document?`} id={attachmentID} />}
      {isModal.isDeleteSuccess.isQuoteDocument && (
        <FormSuccess
          comment={"Successfully Deleted."}
          modalSize={"sm"}
          isTitleCenter={true}
          button_done={false}
          button_addmore={false}
          modaltitlename={"Document Deleted Successfully "}
          modaltitle={""}
        />
      )}
      {isModal.isView.isCloseQuoteRequest && (
        <FormSuccess
          comment={`Are you sure you’d like to close this ${quoteRequestList.requestTypeName} request? Doing so will assume all quote request documents have been provided for customer viewing, especially in the instance of partial quoting. This cannot be undone.`}
          modalSize={"sm"}
          isTitleCenter={true}
          button_done={true}
          button_addmore={true}
          modaltitlename={`Close ${quoteRequestList.requestTypeName} Request #${quoteRequestList.quoteRequestId}?`}
          modaltitle={""}
          buttonName="Close Quote Request"
          onClick={handleQuoteClosed}
          DoneButtonName="Cancel"
        />
      )}

      {isModal.isAddedSuccess.isQuoteDocument && (
        <FormSuccess
          comment={`Your ${quoteRequestList.requestTypeName} request was successfully submitted. Mitsubishi Power will contact you if there are any issues with or questions about your request.`}
          modalSize={"sm"}
          button_done={true}
          button_addmore={!isEventClosed && canAddDocument}
          modaltitlename={`${quoteRequestList.requestTypeName} Added Successfully`}
          isTitleCenter={true}
          modaltitle={""}
          buttonName="Add Another Document"
          onClick={handleAnotherDocument}
        />
      )}
      {isModal.isEditedSuccess.isQuoteDocument && (
        <FormSuccess
          comment={`Your ${quoteRequestList.requestTypeName} request was successfully Edited. Mitsubishi Power will contact you if there are any issues with or questions about your request.`}
          modalSize={"sm"}
          button_done={true}
          button_addmore={false}
          modaltitlename={`${quoteRequestList.requestTypeName} Edited Successfully`}
          isTitleCenter={true}
          modaltitle={""}
          buttonName="Add Another Document"
          onClick={handleAnotherDocument}
        />
      )}
    </>
  );
};

interface quoteProps {
  quoteRequestItem: QuoteRequestGetAll;
  isEventClosed: boolean;
  isExternal: boolean;
}

export default QuoteDocumentUpload;
