import React, { useEffect, useState } from "react";
import { useDelete_PlantContact, useGetAll_PlantContact } from "../../containers/PlantInformation/PlantContactHttpServices";
import SiteContactAccordian from "../Accordian/SiteContactAccordian/SiteContactAccordian";
import FormContacts from "../FormsPages/FormContacts/FormContacts";
import FormSuccess from "../FormsPages/FormSuccess/FormSuccess";
import classes from "./SiteContacts.module.css";

import { PlantContact, PlantContactDTO, PlantContactsDTO } from "../../utils/interface";

import { AxiosError } from "axios";
import Skeleton from "react-loading-skeleton";
import { useIsFetching } from "react-query";
import { useOutletContext } from "react-router-dom";
import { useOnError } from "../../customHooks/useOnError";
import { usePermissions } from "../../customHooks/useUserPermissions";
import useWindowSize from "../../customHooks/useWindowSize";
import { apiPlantContactGetAvatar } from "../../endpoints/endpoints";
import { queryKeys } from "../../react-query/queryKeys";
import { queryClient } from "../../react-query/useQueryClientGet";
import { useStore } from "../../useStore";
import { CloseAllModal } from "../../utils/CloseAllModal";
import FormDeleteGeneric from "../FormsPages/FormDeleteGeneric/FormDeleteGeneric";
import RelatedLinks from "../RelatedLinks/RelatedLinks";

const SiteContacts = () => {
  const { hasOnePermission } = usePermissions();
  const relatedData = useOutletContext<{
    relatedTitle: string;
    relatedSections: [];
  }>();
  
  const { selectedPlant, isModal, setIsModal, plantContacts, setContactAccordianData, contactAccordianData, setPlantContacts, setShowError, user } =
    useStore();
  const setErrorData = useOnError();
  const isFetching = useIsFetching([queryKeys.plantContacts]);
  const {isDesktop, isMobile} = useWindowSize();
  //{***************************************************************************************************************************}
  const { mutate: deletePlantContacts, isLoading: isDeleting, isSuccess: isDeleted, data: deleteResponseData } = useDelete_PlantContact();

  const handleContactDelete = (id: number) => {
    deletePlantContacts(id, {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(queryKeys.plantContacts);
        setIsModal?.({
          ...CloseAllModal,
          isDeleteSuccess: {
            ...isModal.isDelete,
            isContactModal: true,
          },
        });
      },
      onError(error) {
        setErrorData(error);
      },
    });
  };
  //{***************************************************************************************************************************}

  const onSuccess = (data: PlantContactsDTO) => {
    setPlantContacts?.(data);
  };
  const onError = (error: AxiosError<string>) => {
    setPlantContacts?.({
      success: false,
      message: "",
      permissions: 0,
      data: [],
    });
    // setErrorData(error);
  };
  const { isLoading } = useGetAll_PlantContact(selectedPlant.plantId, onSuccess, onError);

  //{***************************************************************************************************************************}

  const contactInitailvalue = {
    plantContactId: 0,
    plantId: 0,
    userId: 0,
    firstName: "",
    lastName: "",
    jobTitle: "",
    primaryPhone: "",
    primaryPhoneTypeId: 0,
    primaryPhoneType: "",
    secondaryPhone: "",
    secondaryPhoneTypeId: 0,
    secondaryPhoneType: "",
    email: "",
    isMitsubishiContact: false,
    avatarFile: "",
    avatarSize: 0,
    displayAvatarSize: "",
    companyName: "",
  };
  const [selectedContact, setSelectedContact] = useState<PlantContact | null>(contactInitailvalue);

  const [contactApiResponseData, setContactApiResponseData] = useState<PlantContactDTO | null>(null);

  const handleAddContact = () => {
    setIsModal?.({
      ...isModal,
      isAdd: {
        ...isModal.isAdd,
        isContactModal: true,
      },
    });
  };

  const handleOpenEditContact = (contactId: number) => {
    const editData = plantContacts.data.filter((a) => {
      return a.plantContactId === contactId;
    });
    editData[0].avatarFile = `${apiPlantContactGetAvatar}/${contactId}`;
    setSelectedContact(editData[0]);

    setIsModal?.({
      ...isModal,
      isEdit: {
        ...isModal.isEdit,
        isContactModal: true,
      },
    });
  };

  plantContacts.data.sort((a, b) => (a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1));
  const isMitsubishiContact = plantContacts.data.filter((a) => {
    if (plantContacts) {
      return a.isMitsubishiContact === true;
    }
    return a;
  });
  const isPlantsiteContact = plantContacts.data.filter((a) => {
    if (plantContacts) {
      return a.isMitsubishiContact === false;
    }
    return a;
  });
  useEffect(() => {
    if (isMitsubishiContact || isPlantsiteContact) {
      setContactAccordianData?.({
        data: [
          {
            id: 0,
            contact_type: isMobile ? "Mitsubishi Power" : "Mitsubishi Power Contacts",
            contact_list: isMitsubishiContact,
          },
          {
            id: 1,
            contact_type: isMobile ? `${selectedPlant.name}` : `${selectedPlant.name} Site Contacts`,
            contact_list: isPlantsiteContact,
          },
        ],
      });
    }
  }, [plantContacts, isMobile]);

  return (
    <>
    <div className={classes.sitecontact}>
      {isModal.isAdd.isContactModal && (
        <FormContacts isEdit={false} modaltitle={"Add New Contact"} modaltitlename={""} setContactApiResponseData={setContactApiResponseData!} />
      )}
      {isModal.isEdit.isContactModal && (
        <FormContacts
          isEdit={true}
          selectedContact={selectedContact!}
          modaltitle={"Edit Contact"}
          modaltitlename={""}
          setContactApiResponseData={setContactApiResponseData}
        />
      )}
   

      {isModal.isAddedSuccess.isContactModal && (
        <FormSuccess
          modaltitlename={""}
          modaltitle={"Contact Successfully Added"}
          comment={`${contactApiResponseData?.data.firstName} ${contactApiResponseData?.data.lastName} was added to the  ${contactApiResponseData?.data?.isMitsubishiContact ? "Mitsubishi Power contacts" : `${selectedPlant.name} Site contacts`
          } list.`}
          modalSize={"sm"}
          button_done={true}
          button_addmore={false}
        />
      )}
      {isModal.isEditedSuccess.isContactModal && (
        <FormSuccess
          modaltitlename={""}
          modaltitle={"Contact Successfully Updated"}
          comment={`${contactApiResponseData?.data.firstName} ${contactApiResponseData?.data.lastName} was updated to the ${
            contactApiResponseData?.data?.isMitsubishiContact ? "Mitsubishi Power contacts" : `${selectedPlant.name} Site contacts`
          } list.`}
          modalSize={"sm"}
          button_done={true}
          button_addmore={false}
        />
      )}
      {isModal.isDeleteSuccess.isContactModal && (
        <FormSuccess
          modaltitlename={""}
          modaltitle={"Contact Successfully Deleted"}
          comment={`${selectedContact?.firstName} ${selectedContact?.lastName} was deleted from the ${
            selectedContact?.isMitsubishiContact ? "Mitsubishi Power contacts" : `${selectedPlant.name} Site contacts`
          } list.`}
          modalSize={"sm"}
          button_done={true}
          button_addmore={false}
        />
      )}
      {isModal.isDelete.isContactModal && (
        <FormDeleteGeneric
          modaltitle={`Delete contact?`}
          modaltitlename={""}
          comment={`Are you sure you want to delete the selected contact from ${selectedPlant.name}? This cannot be undone.`}
          modalSize={"sm"}
          buttonName={"Delete"}
          onClick={() => selectedContact && handleContactDelete(selectedContact?.plantContactId)}
          buttonDoneOnclick={() => alert("buttonDoneOnclick")}
          button_done={true}
          button_addmore={true}
        />
      )}

      <div className={classes.row}>
        <div className={classes.col}>
          <p>Internal and External Contacts</p>
        </div>
        <div className={classes.col}>
        {hasOnePermission(['CustomerContacts', 'MitsubishiPowerContacts'], 'add') && (
            <button className="primaryBtn darkBlue" onClick={handleAddContact}>
              Add New Contact
            </button>
        )}
        </div>
      </div>

      {isFetching > 0 ? (
        <>
          <Skeleton height={56} style={{ marginBottom: "10px" }} />
          <Skeleton height={56} />
        </>
      ) : (
        <SiteContactAccordian panels={contactAccordianData.data} handleOpenEditContact={handleOpenEditContact} />
      )}
    </div>
     { isDesktop && relatedData && <RelatedLinks data={relatedData} />}
    </>
  );

};

export default SiteContacts;
