import { Module } from "module";
import { FunctionComponent, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useOutletContext } from "react-router-dom";
import Button from "../../components/Button/Button";
import Loader from "../../components/Loader/Loader";
import ModalGlobal from "../../components/UI/Modal/ModalGlobal";
import { useOnError } from "../../customHooks/useOnError";

import { useStore } from "../../useStore";
import { CloseAllModal } from "../../utils/CloseAllModal";
import { Report } from "../../utils/interface";
import { ModuleIDs } from "../../utils/moduleIDs";
import {
  useCreateNewMitsubishiPowerReport,
  useCreateNewOMManual,
  useCreate_TBReport,
  useNewPartsCatalog,
  usePlantSiteUnits,
  useShareWithUG_TBReport,
  useUpdateMitsubishiPowerReport,
  useUpdateNewPartsCatalog,
  useUpdateOMManual,
  useUpdate_TBReport,
} from "../Reports/ReportsHttpServices";
import CopyDocumentModal from "./CopyDocumentModal";
import classes from "./TechnicalDocumentation.module.css";
import { TechnicalDocumentationModuleOutletInterface } from "./TechnicalDocumentationModuleContainer";

interface AddDocumentContainerProps {
  isEdit: boolean;
}

const getService = (moduleId: ModuleIDs, addNew: boolean) => {
  switch (moduleId) {
    case ModuleIDs.MitsubishiPowerReports:
      return addNew ? useCreateNewMitsubishiPowerReport : useUpdateMitsubishiPowerReport;
    case ModuleIDs.OMManuals:
      return addNew ? useCreateNewOMManual : useUpdateOMManual;
    case ModuleIDs.PartsCatalog:
      return addNew ? useNewPartsCatalog : useUpdateNewPartsCatalog;
    case ModuleIDs.TechnicalBulletins:
      return addNew ? useCreate_TBReport : useUpdate_TBReport;
    // ??
    case ModuleIDs.IssueReports:
      return addNew ? useCreateNewMitsubishiPowerReport : useUpdateMitsubishiPowerReport;
    case ModuleIDs.PartsServiceQuotes:
      return addNew ? useCreateNewMitsubishiPowerReport : useUpdateMitsubishiPowerReport;
    case ModuleIDs.PartsServiceQuotes:
      return addNew ? useCreateNewMitsubishiPowerReport : useUpdateMitsubishiPowerReport;
    default:
      return addNew ? useCreateNewMitsubishiPowerReport : useUpdateMitsubishiPowerReport;
  }
};

const AddDocumentContainer: FunctionComponent<AddDocumentContainerProps> = ({ isEdit }) => {
  const { documentModule, documentGroups, refetch, setDocumentAdded } = useOutletContext<TechnicalDocumentationModuleOutletInterface>();

  const navigate = useNavigate();
  const { addDocumentModalBodyContent: DocumentModalBodyContent } = documentModule;
  const { setIsModal, selectedPlant, setSelectedPlantDetails, setShowError, report } = useStore();
  const { data: siteUnits } = usePlantSiteUnits();
  const queryClient = useQueryClient();
  const setErrorData = useOnError();
  const [onShareMode, setOnShareMode] = useState<boolean | undefined>(undefined);

  const refetchReport = () => {
    queryClient.invalidateQueries(["reports", "getReports", "header"]);
  };

  const {
    mutate: AddDocument,
    isSuccess,
    isLoading: isAdding,
    data: reportData,
    reset,
  } = getService(
    documentModule.id,
    true
  )({
    onError: (error: unknown) => {
      setErrorData(error);
    },
    onSuccess: (response: Report) => {
      setDocumentAdded(response);
    },
  });

  const {
    mutate: UpdateDocument,
    isSuccess: isEdited,
    isLoading: isEditing,
    data: editedReportData,
  } = getService(
    documentModule.id,
    false
  )({
    onError: (error: unknown) => {
      setErrorData(error);
    },
    onSuccess: (response: Report) => {
      setDocumentAdded(response);
    },
  });
  const { mutate: shareWithUG, isLoading: isSharing, isSuccess: isShared } = useShareWithUG_TBReport();
  
  useEffect(() => {
    if (isEdited || isSuccess) {
      refetchReport();
      refetch();
    }
  }, [isEdited, isSuccess]);

  const handleCloseModal = () => {
    setIsModal?.({ ...CloseAllModal });
    navigate("..");
  };

  const handleShareWithUG = () => {
    setOnShareMode(true);
    !!reportData?.globalItemId && shareWithUG(reportData?.globalItemId,{
      onError: (error: unknown) => {
        setErrorData(error);
      },
    });
  };

  return (
    <>
      {(isAdding || isEditing) &&
        <div className="fixed top-0 left-0 w-full h-screen z-40 !overflow-hidden opacity-50 bg-gray-100 flex flex-col items-center justify-center">
          <Loader />
        </div>
      }
    
      {!onShareMode && (
        <ModalGlobal
          modaltitle={
            isSuccess || isEdited
              ? `Document Successfully ${isEdit ? "Edited" : "Added"}`
              : `${isEdit ? "Edit" : "Add"} ${documentModule.addEditHeaderName}`
          }
          isModalHeader={true}
          isTitleCenter={!!((isSuccess || isEdited) && (reportData || editedReportData))}
          isContentCentered={!!((isSuccess || isEdited) && (reportData || editedReportData))}
          modalClose={handleCloseModal}
          modalSize={!!(isSuccess && (reportData || editedReportData)) ? "special" : "md"}
        >
          {!isEdit && documentModule.addModalHeaderContent.length > 0 && (
            <div>
              {documentModule.addModalHeaderContent.map((e, i) => {
                return (
                  <p key={i} className={classes.modal_body_text}>
                    {e}
                  </p>
                );
              })}
            </div>
          )}
          {isSuccess && reportData && (
            <div>
              <p>
                {reportData.displayFileName || reportData.displayName} was successfully added to the {documentModule.name} page for{" "}
                {selectedPlant.name}. Would you like to add another document at this time?
              </p>
              <div className={`${classes.flex_row} ${classes.add_document_confirmation_buttons}`}>
                {documentModule.id === ModuleIDs.TechnicalBulletins ? (
                  <Button className={`darkBlue`} text="Copy to Users’ Group Portal" onClick={handleShareWithUG} />
                ) : (
                  <Button className={`darkBlue`} text="Add Another Document" onClick={reset} />
                )}

                <Button className={`whiteBtn`} text="Done" onClick={() => navigate("..")} />
              </div>
            </div>
          )}
          {isEdited && editedReportData && (
            <div>
              <p>The document details for "{editedReportData.displayFileName || editedReportData.displayName}" have been successfully updated.</p>
              <div className={`${classes.flex_row} ${classes.add_document_confirmation_buttons}`}>
                <Button className={`darkBlue`} text="Done" onClick={() => navigate("..")} />
              </div>
            </div>
          )}
          {!isSuccess && !isEdited && (
            <DocumentModalBodyContent
              documentModule={documentModule}
              isLoading={{ isAdding, isEditing }}
              isEdit={isEdit}
              documentGroups={documentGroups}
              onSave={isEdit ? UpdateDocument : AddDocument}
              siteUnits={siteUnits ?? []}
            />
          )}
        </ModalGlobal>
      )}

      {onShareMode && (
        <CopyDocumentModal isSharing={isSharing} isShared={isShared} currentReport={reportData} handleCloseModal={handleCloseModal} />
      )}
    </>
  );
};

export default AddDocumentContainer;
