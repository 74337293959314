import { Dispatch, forwardRef, SetStateAction, useRef } from "react";
import { useField, useFormikContext } from "formik";
import classes from "./DatePickerField.module.css";
import ImageImports from "../../../../utils/ImageImports";
import DatePicker from "react-datepicker";
import React from "react";
import MiniTooltip from "../../MiniTooltip/MiniTooltip";

const { tooltip, calendar } = ImageImports;

const YearPickerField = (props: YearPickerFieldInterface) => {
  const [, meta] = useField(props);
  const { submitCount } = useFormikContext<any>();
  const inputref = useRef(null);

  let styles;
  if (props.className === "marginbottom0") {
    styles = {
      marginBottom: 0,
    };
  }

  return (
    <div className={`${classes.form_field_row}`} style={styles}>
      <label htmlFor={props.name} className={classes.input_label}>
        {props.labelName}
        {props.tooltip?.text.length && (
          <div className={classes.cursor_pointer}>
            <MiniTooltip text={props.tooltip.text} space={props.tooltip.space} placement={props.tooltip.placement}>
              <img src={tooltip} className={classes.tooltip_icon} alt="tool tip" />
            </MiniTooltip>
          </div>
        )}
      </label>
      <div className={`${classes.form_group}`}>
        <div className={ submitCount > 0 && meta.error ? `${classes.input} ${classes.errorField} ${classes.error_text}` : `${classes.input}`}>
          <DatePicker
            selected={props.startDate}
            customInput={<ExampleCustomInput inputref={inputref} />}
            placeholderText={props.placeholder}
            disabled={props.disabled}
            onChange={props.handleOnchange}
            showYearPicker
            dateFormat={props.dateFormat}
            minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
          />
        </div>

        {props.details && (
          <small className={classes.small} style={{ marginTop: "8px" }}>
            {props.details}
          </small>
        )}

        {/* <ErrorMessage name={props.name}>
          {(msg) => <div className={classes.error_text}>{msg}</div>}
        </ErrorMessage> */}
      </div>
    </div>
  );
};

const ExampleCustomInput = forwardRef((props: any, ref: any) => <input {...props} ref={ref} style={{ background: `url(${calendar}) no-repeat right 10px center`, paddingRight: "45px" }} />);
interface YearPickerFieldInterface {
  name: string;
  labelName: string;
  placeholder?: string;
  disabled?: boolean;
  className: string | "";
  dateFormat: string;
  handleOnchange: (date: Date) => void;
  startDate?: Date | null;
  setStartDate?: Dispatch<SetStateAction<Date | null>>;
  details?: string;
  tooltip?: {
    placement?: "bottom" | "top" | "right" | "left";
    isDisabled?: 0 | 1;
    space?: number;
    text: string;
  };
}

YearPickerField.defaultProps = {
  disabled: false,
  type: "text",
};

export default YearPickerField;
