import React, { CSSProperties, FunctionComponent, MouseEvent, ReactElement, useEffect, useId, useMemo, useRef, useState } from "react";

import "react-dates/initialize";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import Arrow from "../../assets/arrow-right.svg";
import Button from "../../components/Button/Button";
import BulkActionTooltip, { tooltipActionItem } from "../../components/PopoutTooltip/BulkActionTooltip";
import { withTooltip } from "../../components/PopoutTooltip/Tooltip";
import usePagination from "../../customHooks/usePagination";

import {
  DataWithPermissions,
  DocumentGroup,
  OMManualSectionDATA,
  OMManualSectionGetAllDTO,
  Permissions,
  PlantEquipmentsDATA,
  PlantEquipmentsDTO,
  PlantPermissionedReports,
  Report,
  UnitDATA,
  UnitGetAllDTO,
  User
} from "../../utils/interface";
import ReportRowAccordion from "./ReportRowAccordion";
import ReportRowTable from "./ReportRowTable";
import classes from "./Reports.module.css";

import useWindowSize from "../../customHooks/useWindowSize";
import ImageImports from "../../utils/ImageImports";
import { DocumentModuleInterface } from "../TechnicalDocumentation/TechnicalDocumentationContainer";

import axios, { AxiosError } from "axios";
import Moment from "react-moment";
import { useMutation, useQuery, useQueryClient } from "react-query";
import DeleteDocumentConfirmation from "./DeleteDocumentConfirmation";
import DeleteDocumentModal from "./DeleteDocumentModal";
import MoveDocumentConfirmation from "./MoveDocumentConfirmation";
import MoveDocumentModal from "./MoveDocumentModal";

import moment from "moment";
import "react-dates/lib/css/_datepicker.css";
import MultiSelectDropDown from "../../components/UI/Form/DropDownSelect/MultiSelectDropDown";
import { DropDownOptionItem, PlainDropDown } from "../../components/UI/Form/DropDownSelect/PlainDropDown";
import { RadioButtonGroup } from "../../components/UI/Form/RadioButtonsGrouped/RadioButtonsGrouped";
import { useGetAll_OMManualSectionData } from "../CPItemConfiguration/OMManualSectionHttpServices";
import { useGetAllByUnitID_PlantEquipments } from "../PlantInformation/EquipmentsHttpServices";
import { useGetAll_UnitData } from "../PlantInformation/UnitHttpServices";

import Dropdown from "../../components/Dropdown/Dropdown";
import Pill from "../../components/Pill/Pill";
import { downloadMultipleFiles, downloadMultipleFilesByFileId } from "../../customHooks/FileHttpServices";

import { Checkbox } from "../../components/UI/Form/Checkbox/Checkbox";
import ModalGlobal from "../../components/UI/Modal/ModalGlobal";
import { useOnError } from "../../customHooks/useOnError";
import { TechnicalDocumentationModuleOutletInterface } from "../TechnicalDocumentation/TechnicalDocumentationModuleContainer";

import { useBulkUpdateItemsAsRead } from "../../components/NotificationPane/NotificationHttpServices";
import { queryKeys } from "../../react-query/queryKeys";
import { ModuleIDs } from "../../utils/moduleIDs";
import "./React.Dates.Override.css";

import dayjs, { Dayjs } from "dayjs";
import Loader from "../../components/Loader/Loader";
import { DownloadMultipleFilesModal } from "../../components/Modals/DownloadMultipleFilesModal";
import DateRangePicker from "../../components/UI/BasicForm/CustomSingleDatePicker/DateRangePicker";
import ToggleSwitch from "../../components/UI/ToggleSwitch/ToggleSwitch";
import useFileViewer from "../../customHooks/useFileViewer";
import { usePermissions } from "../../customHooks/useUserPermissions";
import { technicalBulletinShareWithUG } from "../../endpoints/endpoints";
import { useStore } from "../../useStore";
import { FilterDateComparisons } from "../../utils/Sortutils";
import CopyDocumentModal from "../TechnicalDocumentation/CopyDocumentModal";
import { useShareWithUG_TBReport } from "./ReportsHttpServices";

export interface ColumnDefinition {
  title: string;
  key: keyof Report;
  style: CSSProperties;
  component?: FunctionComponent<{ report: Report; tooltip?: ReactElement<any, any> }>;
  className?: string;
}

const { magnifyGlassBlack, filter, download, copyLink, downArrow, trash, blueTick, file_edit, checkbox } = ImageImports;
const ButtonWithTooltip = withTooltip(Button);

interface ReportsProps {
  documentModule: DocumentModuleInterface;
  documentGroups: DocumentGroup[];
  setReportPermissions: React.Dispatch<React.SetStateAction<Permissions>>;
}

const copyLinkBaseUrl = `${window.location.origin}/shared-document`;

const sortOptions = [
  {
    id: 1,
    name: "Modified: Newest to Oldest",
    key: "updated",
    direction: "desc",
  },
  {
    id: 2,
    name: "Modified: Oldest to Newest",
    key: "updated",
    direction: "asc",
  },
  {
    id: 3,
    name: "Published: Newest to Oldest",
    key: "created",
    direction: "desc",
  },
  {
    id: 4,
    name: "Published: Oldest to Newest",
    key: "created",
    direction: "asc",
  },
  {
    id: 5,
    name: "Name: A to Z",
    key: "displayFileName",
    direction: "asc",
  },
  {
    id: 6,
    name: "Name: Z to A",
    key: "displayFileName",
    direction: "desc",
  },
  {
    id: 7,
    name: "Clear Sort",
    key: "created",
    direction: "asc",
  },
];

const Reports: FunctionComponent<ReportsProps> = ({ documentModule, documentGroups, setReportPermissions }) => {
  const [searchText, setSearchText] = useState("");
  const { isDesktop, isMobile, isTablet, isTabletLandscape } = useWindowSize();
  const tooltipPortalRef = useRef(null);
  const [checkedRows, setCheckedRows] = useState<Report[]>([]);

  const [isBulkActionTooltipVisible, setIsBulkActionTooltipVisible] = useState<boolean>(false);
  const {
    setShowError,
    plantConfig,
    setReport,
    selectedPlant,
    user,
    header: {
      data: { notifications },
    },
  } = useStore();
  const [tableData, setTableData] = useState<Report[]>([]);
  const [onShareMode, setOnShareMode] = useState<boolean>(false);
  const [isShareModal, setShareModal] = useState<boolean>(false);
  const params = useParams();
  const groupId = Number.parseInt(params.groupId || "");
  const setErrorData = useOnError();
  const [filters, setFilters] = useState<Filters>({});
  const [sortSelectedOption, setSortSelectedOption] = useState<string | null>(null);
  const navigate = useNavigate();
  const [closeFilters, setCloseFilters] = useState<number>(0);
  const [enableClickWatcher, setEnableClickWatcher] = useState<boolean>(true);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false)

  // deleting documents
  const [documentsToDelete, setDocumentsToDelete] = useState<Report[]>();
  const [deletionCount, setDeletionCount] = useState<number>(0);

  // moving documents
  const [documentsToMove, setDocumentsToMove] = useState<Report[]>();
  const [documentsToCopyUgPortal, setDocumentsToCopyUgPortal] = useState<Report>();
  const [moveConfirmationMeta, setMoveConfirmationMeta] = useState<{ count: number; from: string; to: string }>();

  const [ommanualSections, setOmmanualSections] = useState<OMManualSectionDATA[]>();
  const [iStatus, setIStatus] = useState<{ value: boolean; name: string }[]>([]);

  // copy document link
  const [showCopyLinkModal, setShowCopyLinkModal] = useState<boolean>(false);
  const [showCopyLinkSuccess, setShowCopyLinkSuccess] = useState<boolean>(false);
  const [allVersionsDownload, setAllVersionsDownload] = useState<number>();
  const [copyLinkData, setCopyLinkData] = useState<Report[]>([]);
  const [pagePermissions, setPagePermissions] = useState<Permissions>({
    baseOrg: null,
    canAdd: false,
    canAdmin: false,
    canDelete: false,
    canEdit: false,
    canView: false,
    orgGroup: null,
    role: ''
  });
  const { documentIcon } = ImageImports;

  useGetAll_OMManualSectionData(
    (data: OMManualSectionGetAllDTO) => {
      if (documentModule?.id === ModuleIDs.OMManuals) {
        if (filters.ommanualSections?.length && filters.ommanualSections?.length > 0) {
          setOmmanualSections(
            data.data.map((s) => {
              var fil = filters.ommanualSections?.filter((p) => p == s.id);
              if (fil?.length && fil?.length > 0) s.checked = true;
              return s;
            })
          );
        } else {
          setOmmanualSections(data.data);
        }
      }
    },
    () => { }
  );

  const { handleFileView, isLoading } = useFileViewer();

  const queryClient = useQueryClient();
  const { mutate: markItemsNotificationsAsRead } = useBulkUpdateItemsAsRead({
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.header);
      refetchReports();
    }
  });

  const {
    pagedData,
    setData,
    data,
    totalRecords,
    setTotalRecords,
    filterFn,
    setFilter,
    pageButtonNumbers,
    hasPreviousPage,
    previousPage,
    hasNextPage,
    nextPage,
    setCurrentPageNumber,
    currentPageNumber,
    resultsPerPage,
    setResultsPerPage,
    setSortKey,
    setSortDirection,
  } = usePagination<Report>(16, ["displayFileName", "implementationStatus", "scope", "applicability", "summaryGuideline"]);

  useEffect(() => {
    setSortDirection("desc");
    setSortKey("updated");
  }, []);

  useEffect(() => {
    setCheckedRows([]);
    setFilters({ isDeleted: (user?.portalAdmin || pagePermissions.canAdmin) ? ["Show Deleted"] : undefined });
  }, [selectedPlant.plantId]);

  useEffect(() => {
    setCheckedRows([]);
    setFilters({ isDeleted: (user?.portalAdmin || pagePermissions.canAdmin) ? ["Show Deleted"] : undefined });
  }, [groupId]);

  const handleFile = (report: Report) => {
    const filetype = report.fileModel.fileType.contentType;
    const filePreviewTypes = ["image/jpeg", "application/pdf", "image/jpeg", "image/png"];
    const isValid = filePreviewTypes.indexOf(filetype);
    if (isValid === -1) {
      handleFileView(report.fileId, report.globalId,0, { isDownload: true, fileName: report.displayFileName! });
    } else {
      handleFileView(report.fileId, report.globalId);
    }
  };
  let tableColumns: ColumnDefinition[];
  tableColumns = [
    {
      title: "Document Name",
      key: "displayFileName",
      component: ({ report }) => {
        if (report.displayFileName) {
          return (
            <div onClick={() => handleFile(report)} style={{ color: "#00749E", textDecoration: "none", cursor: "pointer" }}>
              {report.displayFileName} {report.isDeleted && "(Deleted)"}
            </div>
          );
        }
        return <>N/A</>;
      },
      className: classes.document_name,
      style: {
        flexBasis: "24%",
      },
    },
    {
      title: "Report ID",
      key: "globalId",
      style: {
        flexBasis: "18%",
      },
    },
    {
      title: "Equipment",
      key: "associatedEquipment",
      component: ({ report }) => <>{report.associatedEquipment?.map((e) => e.name).join(",")}</>,
      style: {
        flexBasis: "18%",
      },
    },
    {
      title: "Published",
      key: "created",
      component: ({ report }) => {
        if (report.created) return <Moment date={report.created} format="MM/DD/YYYY" />;
        return <>N/A</>;
      },
      style: {
        flexBasis: "18%",
      },
    },
    {
      title: "Last Modified",
      key: "updated",
      component: ({ report }) => {
        if (report.updated) return <Moment date={report.updated} format="MM/DD/YYYY" />;
        return <>N/A</>;
      },
      style: {
        flexBasis: "18%",
      },
    },
  ];

  if (documentModule.id === ModuleIDs.TechnicalBulletins) {
    tableColumns = [
      {
        title: "Document Name",
        key: "displayFileName",
        component: ({ report }) => {
          if (report.displayFileName)
            return (
              <div onClick={() => handleFile(report)} style={{ color: "#00749E", textDecoration: "none", cursor: "pointer" }}>
                {report.displayFileName} {report.isDeleted && "(Deleted)"}
              </div>
            );
          return <>N/A</>;
        },
        className: classes.document_name,
        style: {
          flexBasis: "24%",
        },
      },
      {
        title: "Equipment",
        key: "associatedEquipment",
        component: ({ report }) => <>{report.associatedEquipment?.map((e) => e.name).join(",")}</>,
        style: {
          flexBasis: "24%",
        },
      },
      {
        title: "Published",
        key: "created",
        component: ({ report }) => {
          if (report.created) return <Moment date={report.created} format="MM/DD/YYYY" />;
          return <>N/A</>;
        },
        style: {
          flexBasis: "24%",
        },
      },
      {
        title: "Details",
        key: "fileId",
        component: ({ report, tooltip }) => {
          if (tooltip) {
            return tooltip;
          }
          return <>""</>;
        },
        style: {
          flexBasis: "18%",
        },
      },
    ];
  }

  if (documentModule.id === ModuleIDs.OMManuals) {
    tableColumns = [
      {
        title: "Manual Name",
        key: "displayFileName",
        component: ({ report }) => {
          if (report.displayFileName)
            return (
              <div onClick={() => handleFile(report)} style={{ color: "#00749E", textDecoration: "none", cursor: "pointer" }}>
                {report.displayFileName} {report.isDeleted && "(Deleted)"}
              </div>
            );
          return <>N/A</>;
        },
        className: classes.document_name,
        style: {
          flexBasis: "24%",
        },
      },
      {
        title: "Section",
        key: "sectionId",
        component: ({ report }) => {
          return <>{ommanualSections?.find((d) => d.id === report.sectionId)?.name}</>;
        },
        style: {
          flexBasis: "18%",
        },
      },
      {
        title: "Equipment",
        key: "associatedEquipment",
        component: ({ report }) => <>{report.associatedEquipment?.map((e) => e.name).join(",")}</>,
        style: {
          flexBasis: "18%",
        },
      },
      {
        title: "Published",
        key: "created",
        component: ({ report }) => {
          if (report.created) return <Moment date={report.created} format="MM/DD/YYYY" />;
          return <>N/A</>;
        },
        style: {
          flexBasis: "18%",
        },
      },
      {
        title: "Last Modified",
        key: "updated",
        component: ({ report }) => {
          if (report.updated) return <Moment date={report.updated} format="MM/DD/YYYY" />;
          return <>N/A</>;
        },
        style: {
          flexBasis: "18%",
        },
      },
    ];
  }

  const { mutate: shareWithUG, isLoading: isSharing, isSuccess: isShared } = useShareWithUG_TBReport();

  const handleShareWithUG = () => {
    setShareModal(false);
    setOnShareMode(true);

    if (documentsToCopyUgPortal?.globalId) {
      shareWithUG(documentsToCopyUgPortal?.globalId, {
        onError: (error: unknown) => {
          setErrorData(error);
        },
      });
    }
  };

  const { mutate: deleteMutation } = useMutation((ids: number[]) => documentModule.documentApi.bulkDelete(ids, "User Deleted"), {
    onSuccess: (data: boolean) => {
      refetchReports();
    },
    onError: (error: AxiosError<string>) => {
      setShowError?.({
        isError: true,
        title: error.message,
        ErrorType: "danger",
      });
    },
  });
  const { mutate: moveMutation } = useMutation(
    (data: { ids: number[]; newItemTypeId: number }) => documentModule.documentApi.bulkMove(data.ids, data.newItemTypeId),
    {
      onSuccess: (data: boolean) => {
        refetchReports();
      },
      onError: (error: AxiosError<string>) => {
        setErrorData(error);
      },
    }
  );

  const group = documentGroups?.find((dg) => dg.id === groupId);
  const { isFetching, refetch: refetchReports } = useQuery(
    ["reports", "getReports", plantConfig.data.baseOrgId, group?.id],
    () => documentModule.documentApi.getAll(group?.id, plantConfig.data.baseOrgId),
    {
      onSuccess: (data: DataWithPermissions<Report[]>) => {
        setPagePermissions(data.permissions);
        setReportPermissions(data.permissions);
        const updatedReport = data.data.map((a) => ({
          ...a,
          equipmentName: a.associatedEquipment
            ?.filter((a) => (a ? a : null))
            .map((b) => b.name)
            .toString(),
        }));
        setCurrentPageNumber(1);
        setTableData(updatedReport);
        setData(updatedReport);
      },
      enabled: !!group && !!plantConfig.data.baseOrgId,
    }
  );

  const confirmMove = (from: string, to: DocumentGroup) => {
    if (documentsToMove) {
      moveMutation({ ids: documentsToMove.map((d) => d.globalId), newItemTypeId: to.id });
      setMoveConfirmationMeta({
        count: documentsToMove.length,
        from,
        to: to.name,
      });
    }
    setCheckedRows([]);
    setDocumentsToMove(undefined);
  };

  const confirmDelete = () => {
    if (documentsToDelete) {
      deleteMutation(documentsToDelete.map((d) => d.globalId));
      setDeletionCount(documentsToDelete.length);
    }
    setCheckedRows([]);
    setDocumentsToDelete(undefined);
  };

  useEffect(() => {
    setShowCopyLinkModal(copyLinkData.length > 0);
  }, [copyLinkData]);

  const mutation = useMutation({
    mutationFn: (globalItemId: number) => {
      return axios(`${technicalBulletinShareWithUG}/${globalItemId}`, {
        method: "POST",
      });
    },
  });

  // add additional tool tip actions to this array
  // the click event is passed to each onClick event handler as the first parameter
  // the second parameter is always an array of ReportRowData items - even when performing actions on a single row
  // `documentModule.documentApi` & `documentModule.documentGroupApi` point to api methods specific to the module (Technical Bulletins/Mitsubishi Power Reports/O&M Manuals)
  // each module's documentApi & documentGroupApi methods should have the same signature so the methods can be called the same way here
  // example, here we should be able to call documentModule.documentApi.delete(reportRow) and the underlying method for that module should know what endpoint to hit and how the request should look.
  const tooltipActions: tooltipActionItem[] = [
    {
      text: "Select All",
	    disabled: checkedRows.length === tableData.length, 
      showOnlyOn: ["BulkActions"],
      onClick: (event: MouseEvent, d: Report[]) => {
        setCheckedRows([...tableData]);
      },
    },
    {
      text: "Deselect All",
      disabled: !checkedRows.length, 
      showOnlyOn: ["BulkActions"],
      onClick: (event: MouseEvent, d: Report[]) => {
        setCheckedRows([]);
      },
    },
    {
      text: "Mark Read",
      disabled: !checkedRows.length,
      showOnlyOn: ["BulkActions"],
      onClick: (event: MouseEvent, d: Report[]) => {
        const reportIds = d.map((d) => d.globalId).filter(d => notifications.map(d => d.itemId).includes(d));

        markItemsNotificationsAsRead(reportIds);
        setCheckedRows([]);
		    event.preventDefault();
      },
    },
    {
      text: "Download",
      img: download,
      hideable: !!(pagePermissions.canAdmin),
      showOnlyOn: ["ItemActions"],     
      async onClick(event: MouseEvent, d: Report[]) {
        try {
          const fileIds = d.map((d) => d.globalId);
          setAllVersionsDownload(fileIds.length);
          await downloadMultipleFiles(fileIds);
          setAllVersionsDownload(undefined);          
        } catch (error) {
          setAllVersionsDownload(undefined);
          setErrorData(error);
        }
      },
    },
    {
      text: "Copy Link",
      img: copyLink,
      showOnlyOn: ["ItemActions"],
      hideWhenDeleted: true,
      onClick: (event: MouseEvent, d: Report[]) => {
        event.preventDefault();
        setCopyLinkData(d);
      },
    },
  ];

  if (pagePermissions.canEdit || pagePermissions.canAdmin || user?.portalAdmin) {
    tooltipActions.push({
      text: "Move",
      disabled: !checkedRows.length,
      showOnlyOn: ["BulkActions"],
      onClick: (event: MouseEvent, data: Report[]) => {
        setDocumentsToMove(data);
        setIsBulkActionTooltipVisible(false);
      },
    });
  }
  if (pagePermissions.canEdit || pagePermissions.canAdmin || user?.portalAdmin) {
    tooltipActions.push({
      text: "Edit",
      showOnlyOn: ["ItemActions"],
      img: file_edit,
      onClick: (event: MouseEvent, data: Report[]) => {
        if (data.length < 0) return;
        setReport && setReport(data[0]);
        navigate("edit");
      },
    });
  }

  if ((pagePermissions.canDelete || pagePermissions.canAdmin || user?.portalAdmin) && user?.mpInternal) {
    tooltipActions.push({
      text: "Delete",
      disabled: !checkedRows.length,
      showOnlyOn: ["BulkActions"],
      onClick: (event: MouseEvent, data: Report[]) => {
        setDocumentsToDelete(data);
        setIsBulkActionTooltipVisible(false);
      },
    });
    tooltipActions.push({
      text: "Delete",
      img: trash,
      hideWhenDeleted: true,
      showOnlyOn: ["ItemActions"],
      onClick: (event: MouseEvent, data: Report[]) => {
        setDocumentsToDelete(data);
        setIsBulkActionTooltipVisible(false);
      },
    });
  }

  if ((pagePermissions.canEdit || pagePermissions.canAdmin || user?.portalAdmin) && documentModule.id === ModuleIDs.TechnicalBulletins) {
    tooltipActions.push({
      text: "Copy to Users' Group Portal",
      img: documentIcon,
      showOnlyOn: ["ItemActions"],
      hideWhenDeleted: true,
      onClick: (event: MouseEvent, data: Report[]) => {
        setDocumentsToCopyUgPortal(data.pop());
        setShareModal(true);
        setIsBulkActionTooltipVisible(false);
      },
    });
  }

  if (pagePermissions.canAdmin || user?.portalAdmin) {
    tooltipActions.push({
      text: "Download All Versions",
      hideable: true,
      img: download,
      showOnlyOn: ["ItemActions"],      
      async onClick(event: MouseEvent, data: Report[]) {
        try {
          const fileIds = [data[0].fileId, ...(data[0].previousFiles || []).map(d => d.id)];
          setAllVersionsDownload(fileIds.length);
          await downloadMultipleFilesByFileId(fileIds);
          setAllVersionsDownload(undefined);          
        } catch (error) {
          setAllVersionsDownload(undefined);
          setErrorData(error);
        }
      },
    });
  }

  const toggleItemChecked = (row: Report): void => {
    let tmpCheckedRows = [...checkedRows],
      index = tmpCheckedRows.findIndex((item) => item.globalId === row.globalId);
    if (index > -1) {
      tmpCheckedRows.splice(index, 1);
    } else {
      tmpCheckedRows.push(row);
    }
    setCheckedRows(tmpCheckedRows);
  };
  const allRowsChecked = tableData.length > 0 && checkedRows.length === tableData.length;
  const someRowsChecked = tableData.length > 0 && checkedRows.length > 0;
  
  const toggleAllRowsChecked = () => {
    if (allRowsChecked) {
      setCheckedRows([]);
    } else {
      setCheckedRows([...tableData]);
    }
  };

  useEffect(() => {
    setCloseFilters((f) => f + 1);
  }, [filters]);

  useEffect(() => {
    setData([]);
    setTableData([]);
    setCurrentPageNumber(1);
  }, [groupId]);

  const filterReports = (report: Report): boolean => {
    let matches = true;
    if (filters.startDate) {
      matches = dayjs(report.created).isAfter(filters.startDate);
    }
    if (matches && filters.endDate) {
      matches = dayjs(report.created).isBefore(filters.endDate);
    }
    if (matches && filters.equipment && filters.equipment.length > 0) {
      const filteredEquipmentIds = filters.equipment.map((d) => d.plantEquipmentId);
      const eqIds = (report.associatedEquipment || []).map((d) => d.equipmentId);
      let hasEq = false;
      for (let i = 0; i < eqIds.length && hasEq === false; i++) {
        hasEq = filteredEquipmentIds.includes(eqIds[i]);
      }
      matches = hasEq;
    }
    // Filter by O&M Section
    if (matches && filters.ommanualSections?.length) {
      matches = filters.ommanualSections.includes(report.sectionId);
    }
    if (matches && filters.iStatus?.length) {
      matches = filters.iStatus.includes(report.implementationStatus);
    }
    if (matches && (user?.portalAdmin || pagePermissions.canAdmin) && !filters.isDeleted?.includes("Show Deleted")) {
      matches = report.isDeleted === false;
    }
    return matches;
  };

  useEffect(() => {
    setData(tableData.filter(filterReports));
    setTotalRecords(tableData.filter(filterReports).length);
  }, [tableData.filter(filterReports).length]);

  useEffect(() => {
    if (searchText.trim().length > 0) {
      setFilter(searchText.trim());
      setTotalRecords(data.filter(filterFn).length);
    } else {
      setFilter("");
      setTotalRecords(tableData.filter(filterReports).length);
    }
  }, [searchText]);

  const handleSort = (e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>, option?: any) => {
    setSortKey(option.key);
    setSortDirection(option.direction);
  };

  let filterCount = 0;
  if (filters.equipment) {
    filterCount = filters.equipment.length;
  }

  if (filters.dateFilter) {
    filterCount++;
  }

  if (filters.ommanualSections?.length) {
    filterCount += filters.ommanualSections?.length;
  }

  if (filters.iStatus?.length) {
    filterCount += filters.iStatus?.length;
  }
  if (user?.portalAdmin && filters.isDeleted?.length) {
    filterCount += filters.isDeleted?.length;
  }

  const tooltipId = useId().replaceAll(":", "_");
  
  return (
    <div
      className={`${classes.flex_column} ${isDesktop ? classes.is_desktop : ""} ${isMobile ? classes.is_mobile : ""} ${isTablet ? classes.is_tablet : ""
        }`}
      style={{ flexGrow: 1 }}
    >
      {/* Delete document modals */}
      {documentsToDelete && (
        <DeleteDocumentModal
          documentModule={documentModule}
          documents={documentsToDelete}
          onClose={() => setDocumentsToDelete(undefined)}
          confirm={confirmDelete}
        />
      )}
      {deletionCount > 0 && (
        <DeleteDocumentConfirmation documentModule={documentModule} deletionCount={deletionCount} onClose={() => setDeletionCount(0)} />
      )}
      {/* Move document modals */}
      {documentsToMove && (
        <MoveDocumentModal
          documentGroupName={group?.name || ""}
          documentGroups={documentGroups}
          documents={documentsToMove}
          onClose={() => setDocumentsToMove(undefined)}
          move={confirmMove}
        />
      )}
      {moveConfirmationMeta && (
        <MoveDocumentConfirmation moveConfirmationMeta={moveConfirmationMeta} onClose={() => setMoveConfirmationMeta(undefined)} />
      )}
           {allVersionsDownload !== undefined && allVersionsDownload > 0 && <DownloadMultipleFilesModal type="Document" fileCount={allVersionsDownload} />}


      <div className={`${classes.report_container} ${classes.flex_column}`}>
        <div className={`${isMobile ? classes.flex_column : classes.flex_row} ${classes.options}`}>
          <div className={`${classes.flex_row} ${classes.options_left}`}>
            {isDesktop && (
              <div className="nameplate-dropdown">
                <Dropdown
                  dropdowntype={"Sort by:"}
                  handleDropDownSelect={handleSort}
                  selectValue={"Modified: Newest to Oldest"}
                  selectedOption={sortSelectedOption}
                  setSelectedOption={(sortOptionName) => setSortSelectedOption(sortOptionName)}
                  options={sortOptions}
                />
              </div>
            )}
            <div className={`${classes.flex_row} ${classes.search_keyword_container}`}>
              <img src={magnifyGlassBlack} className="" alt="keyword search" />
              <input
                className={classes.search_keyword}
                value={searchText}
                placeholder="Search documents"
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
            {!isMobile && (
              <div>
                {totalRecords} {totalRecords === 1 ? "Result" : "Results"}
              </div>
            )}
          </div>
          <div style={{ position: "relative" }}>
            <ButtonWithTooltip
              className="whiteBtn"
              text={
                <>
                  {filterCount > 0 ? <Pill type="primary">{filterCount}</Pill> : ""} {isDesktop ? "Filter" : "Sort/Filter"}
                </>
              }
              img={<img src={filter} alt="filter" className="bottom pl-2" />}
              textClassName="filter-text"
              forceClose={closeFilters}
              toggleClickWatcher={enableClickWatcher}
              tooltipContent={<FilterTooltipContent user={user!} onFiltersApplied={setFilters} filters={filters} permissions={pagePermissions} />}
            />
          </div>
        </div>
        {filterCount > 0 && (
          <div className={classes.filter_pill_container}>
            {filters.iStatus?.map((status) => (
              <Pill
                className={classes.filter_pill}
                key={status}
                dismissible={true}
                onDismiss={() =>
                  setFilters((filters) => {
                    return {
                      ...filters,
                      iStatus: filters.iStatus?.filter((d) => d !== status),
                    };
                  })
                }
                type="default"
              >
                {status}
              </Pill>
            ))}
            
            {(user?.portalAdmin || pagePermissions.canAdmin) &&
              filters.isDeleted?.map((status) => (
                <Pill
                  className={classes.filter_pill}
                  key={status}
                  dismissible={true}
                  onDismiss={() =>
                    setFilters((filters) => {
                      return {
                        ...filters,
                        isDeleted: filters.isDeleted?.filter((d) => d !== status),
                      };
                    })
                  }
                  type="default"
                >
                  {status}
                </Pill>
              ))}
            {filters.ommanualSections?.map((section) => (
              <Pill
                className={classes.filter_pill}
                key={section}
                dismissible={true}
                onDismiss={() =>
                  setFilters((filters) => {
                    return {
                      ...filters,
                      ommanualSections: filters.ommanualSections?.filter((d) => d !== section),
                    };
                  })
                }
                type="default"
              >
                {ommanualSections?.find((d) => d.id === section)?.name}
              </Pill>
            ))}
            {filters.equipment?.map((filteredEquipment) => (
              <Pill
                className={classes.filter_pill}
                key={filteredEquipment.plantEquipmentId}
                dismissible={true}
                onDismiss={() =>
                  setFilters((filters) => {
                    return {
                      ...filters,
                      equipment: filters.equipment?.filter((d) => d.plantEquipmentId !== filteredEquipment.plantEquipmentId),
                    };
                  })
                }
                type="default"
              >
                {filteredEquipment.equipmentName}
              </Pill>
            ))}
            {filters.dateFilter && (
              <Pill
                className={classes.filter_pill}
                dismissible={true}
                onDismiss={() => setFilters({ ...filters, dateFilter: undefined, startDate: undefined })}
                type="default"
              >
                {DatePublishedFilters.find((d) => d.value === filters.dateFilter)?.label}
              </Pill>
            )}
          </div>
        )}
        {isLoading || isLoadingDownload ? (
          <Loader containerStyle={{ marginTop: "100px", marginBottom: "100px" }} />
        ) : (
          <div className={`${classes.results_container} ${classes.flex_column}`}>
            {isDesktop ? (
              <>
                <table className={classes.report_table}>
                  <thead>
                    <tr>
                      <th>
                        <div className={`${classes.checkmark_container} ${classes.flex_row}`}>
                          <div className={classes.checkbox}>
                            <img src={checkbox} className={"cursor-pointer"} alt="checkbox" onClick={toggleAllRowsChecked} style={allRowsChecked ? {display: "inherit"} : {display: "none"}} />
                            <span className={allRowsChecked ? classes.checkmark : classes.someCheckmark} onClick={toggleAllRowsChecked} style={!allRowsChecked ? {display: "inherit"} : {display: "none"}} />
                          </div>
                          <div id={tooltipId} className={`${classes.bulk_action_button} ${classes.has_checked_rows}`}>
                            <img
                              src={downArrow}
                              alt="Bulk Actions"
                              onClick={() => setIsBulkActionTooltipVisible(!isBulkActionTooltipVisible)}
                            />
                              <BulkActionTooltip
                                isDeleted={!!checkedRows.filter((row) => row.isDeleted).length}
                                shown={isBulkActionTooltipVisible}
                                displayRight
                                setShown={setIsBulkActionTooltipVisible}
                                parentId={tooltipId}
                                listItems={tooltipActions.filter((action) => !action.showOnlyOn || action.showOnlyOn.includes("BulkActions"))}
                                data={checkedRows}
                              />
                          </div>
                        </div>
                      </th>
                      {tableColumns.map((col) => (
                        <th key={col.title} style={col.style}>
                          {col.title}
                        </th>
                      ))}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {pagedData.filter(filterReports).map((row) => (
                      <ReportRowTable
                        key={row.globalId}
                        unread={notifications?.filter((alert) => alert.itemId === row.globalId && !alert.read).length > 0}
                        data={row}
                        columnDefinitions={tableColumns}
                        tooltipActions={tooltipActions.filter((action) => !action.showOnlyOn || action.showOnlyOn.includes("ItemActions"))}
                        isChecked={checkedRows.map((item) => item.globalId).includes(row.globalId)}
                        onRowChecked={() => toggleItemChecked(row)}
                      />
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <>
                <div className={`${classes.flex_row} ${classes.accordion_heading}`}>
                  <div className="">
                    <div className={`${classes.checkmark_container} ${classes.flex_row}`}>
                      <div className={classes.checkbox}>
                        {/* <input type="checkbox" checked={allRowsChecked} onChange={() => { }} /> */}
                        { allRowsChecked && <img src={checkbox} className={"cursor-pointer"} alt="checkbox" onClick={toggleAllRowsChecked} />}
                        { !allRowsChecked && <span className={allRowsChecked ? classes.checkmark : classes.someCheckmark} onClick={toggleAllRowsChecked} />}
                        {/* <span className={ allRowsChecked ? classes.checkmark : classes.someCheckmark } onClick={toggleAllRowsChecked} /> */}
                      </div>
                      <div id={tooltipId} className={`${classes.bulk_action_button} ${classes.has_checked_rows}`}>
                        <img
                          src={downArrow}
                          alt="Bulk Actions"
                          onClick={() => setIsBulkActionTooltipVisible(!isBulkActionTooltipVisible)}
                        />
                          <BulkActionTooltip
                            isDeleted={!!checkedRows.filter((row) => row.isDeleted).length}
                            shown={isBulkActionTooltipVisible}
                            setShown={setIsBulkActionTooltipVisible}
                            parentId={tooltipId}
                            listItems={tooltipActions.filter((action) => !action.showOnlyOn || action.showOnlyOn.includes("BulkActions"))}
                            data={checkedRows}
                          />
                      </div>
                    </div>
                  </div>
                  <span>Report Name</span>
                </div>
                {pagedData.filter(filterReports).map((row) => (
                  <ReportRowAccordion
                    unread={notifications?.filter((alert) => alert.itemId === row.globalId).length > 0}
                    key={row.globalId}
                    data={row}
                    isChecked={checkedRows.map((item) => item.globalId).includes(row.globalId)}
                    onRowChecked={() => toggleItemChecked(row)}
                    columnDefinitions={tableColumns.filter((column) => column.key !== "displayFileName")}
                    tooltipPortalRef={tooltipPortalRef}
                    tooltipActions={tooltipActions.filter((action) => !action.showOnlyOn || action.showOnlyOn.includes("ItemActions"))}
                  />
                ))}
              </>
            )}
            {totalRecords > 0 ? (
              <div className={`${classes.pagination} ${isDesktop || isTablet ? classes.flex_row : `${classes.flex_column} gap-7`}`}>
                <div className={`${classes.flex_row} ${classes.pagination_left}`}>
                  <span className={classes.result_count}>
                    {Math.min(resultsPerPage, pagedData.filter(filterReports).length)} of {totalRecords} Result{totalRecords !== 1 && "s"}
                  </span>
                  {resultsPerPage < totalRecords && (
                    <Button
                      className={`whiteBtn ${classes.show_all}`}
                      text={ isMobile || isTablet ? "Show All Results": "Show All" }
                      onClick={() => setResultsPerPage(totalRecords)}
                      style={{ order: isMobile ? "-1" : "unset" }}
                    />
                  )}
                </div>
                <div className={`${classes.flex_row} ${classes.page_numbers}`}>
                  <img
                    src={Arrow}
                    className="pagination-left-arrow"
                    style={hasPreviousPage ? { filter: "invert(31%) sepia(21%) saturate(786%) hue-rotate(150deg) brightness(94%) contrast(83%)" } : {}}
                    alt="arrow"
                    onClick={() => {
                      if (hasPreviousPage) {
                        previousPage();
                      }
                    }}
                  />
                  {pageButtonNumbers.map((pageNumber) => (
                    <span
                      key={pageNumber}
                      className={`${classes.page_number} ${pageNumber === currentPageNumber ? classes.active : ""}`}
                      onClick={() => {
                        setCurrentPageNumber(pageNumber);
                      }}
                    >
                      {pageNumber}
                    </span>
                  ))}
                  <img
                    src={Arrow}
                    className="pagination-right-arrow"
                    style={hasNextPage ? { filter: "invert(31%) sepia(21%) saturate(786%) hue-rotate(150deg) brightness(94%) contrast(83%)" } : {}}
                    alt="arrow"
                    onClick={() => {
                      if (hasNextPage) {
                        nextPage();
                      }
                    }}
                  />
                </div>
              </div>
            ) : isFetching ? (
              <Loader />
            ) : (
              <div>No reports to display.</div>
            )}
          </div>
        )}
      </div>
      <div ref={tooltipPortalRef} />

      {showCopyLinkModal && copyLinkData.length && (
        <ModalGlobal
          modalSize="sm"
          modalClass="!max-w-[500px]" 
          titleClassName="centeredText"
          modaltitle={`Copy Link to "${copyLinkData[0].displayFileName}"`}
          isModalHeader={true}
          useFallbackInitialFocus={true}
          modalClose={() => {
            setShowCopyLinkSuccess(false);
            setCopyLinkData([]);
          }}
        >
          <div className="flex flex-row gap-2 justify-between items-center">
            <input type="text" className="w-2/3 pl-[16px] py-[13px] text-black" disabled defaultValue={`${copyLinkBaseUrl}/${copyLinkData[0].fileId}`} />

            <Button
              className={`primaryBtn darkBlue hover:bg-dark-blue-30 focus:bg-dark-blue-30 active:bg-black whitespace-nowrap`}
              text="Copy Link"
              preImg={<img src={copyLink} alt="Copy Link" className="invert hover:invert mr-2 h-5 w-5" />}
              onClick={() => {
                navigator.clipboard.writeText(`${copyLinkBaseUrl}/${copyLinkData[0].fileId}`);
                setShowCopyLinkSuccess(true);
                setTimeout(() => {
                  setShowCopyLinkSuccess(false);
                  setCopyLinkData([]);
                }, 2000);
              }}
            />
          </div>

          {showCopyLinkSuccess && (
            <div className="flex justify-start content-start mt-4 text-sm">
              <img src={blueTick} alt="Link successfully copied" className="mr-2" />
              <span className="text-[#00749E]">Link successfully copied</span>
            </div>
          )}
        </ModalGlobal>
      )}

      {isShareModal && (
        <ModalGlobal
          modalSize="sm"
          modaltitle={`Copy (1) Document(s)?`}
          isModalHeader={true}
          modalClose={() => {
            setShareModal(false);
          }}
        >
          <div style={{ textAlign: "justify" }}>
            <p>
              {`Are you sure you want to make a copy of this Technical Bulletin available to the Users’ Group Portal?`}
              <br />
            </p>
            <div style={{ color: "#666666", marginTop: 20 }}>{documentsToCopyUgPortal?.displayFileName}</div>
            <div style={{ marginTop: 40 }} className={classes.flex_row}>
              {<Button className={`darkBlue`} disabled={false} text={"Yes, Copy to Users’ Group Portal"} onClick={handleShareWithUG} />}
              &nbsp;&nbsp;&nbsp;
              <Button
                className={`whiteBtn`}
                disabled={false}
                text={"Cancel"}
                onClick={() => {
                  setShareModal(false);
                }}
              />
            </div>
          </div>
        </ModalGlobal>
      )}

      {onShareMode && (
        <CopyDocumentModal
          isSharing={isSharing}
          isShared={isShared}
          currentReport={documentsToCopyUgPortal}
          handleCloseModal={() => setOnShareMode(false)}
        />
      )}
    </div>
  );
};

export default Reports;

const DatePublishedFilters = [
  {
    label: "Last 24 Hours",
    value: "P1D", // ISO-8601 durations
  },
  {
    label: "Last 7 Days",
    value: "P7D",
  },
  {
    label: "Last 30 Days",
    value: "P30D",
  },
  {
    label: "Last 60 Days",
    value: "P60D",
  },
  {
    label: "Last 90 Days",
    value: "P90D",
  },
  {
    label: "Custom Range",
    value: "custom",
  },
];
interface Filters {
  equipment?: PlantEquipmentsDATA[];
  startDate?: Dayjs;
  endDate?: Dayjs;
  dateFilter?: string;
  unit?: number | false;
  iStatus?: string[];
  ommanualSections?: number[];
  isDeleted?: string[];
}
interface FilterTooltipContentProps {
  onFiltersApplied: (filters: Filters) => void;
  filters: Filters;
  user: User;
  permissions: Permissions;
}
const FilterTooltipContent: FunctionComponent<FilterTooltipContentProps> = ({ onFiltersApplied, filters, user, permissions }) => {
  const [filtersChanged, setFiltersChanged] = useState<boolean>(false);
  const { selectedPlant, plantConfig } = useStore();
  const [unit, setUnit] = useState<number | false>(false);
  const [units, setUnits] = useState<UnitDATA[]>([]);
  const [ommanualSections, setOmmanualSections] = useState<OMManualSectionDATA[]>([]);
  const [iStatus, setIStatus] = useState<{ value: boolean; name: string }[]>([]);
  const [equipment, setEquipment] = useState<number[]>([]);
  const [equipmentOptions, setEquipmentOptions] = useState<PlantEquipmentsDATA[]>([]);
  const [productTypes, setProductTypes] = useState<{ id: number; name: string }[]>([]);
  const [dateFilter, setDateFilter] = useState<string>();
  const { isDesktop, isMobile } = useWindowSize();
  const [startDate, setStartDate] = useState(dayjs(null));
  const [endDate, setEndDate] = useState(dayjs(null));
  const [focusedInput, setFocusedInput] = useState<"startDate" | "endDate" | null>("startDate");
  const startDateId = useId().replaceAll(":", "_");
  const endDateId = useId().replaceAll(":", "_");
  const params = useParams();
  const groupId = Number.parseInt(params.groupId || "");
  const { documentModule, documentGroups } = useOutletContext<TechnicalDocumentationModuleOutletInterface>();
  const [selectionText, setSelectionText] = useState<string>("");
  const [showStartCalendar, setShowStartCalendar] = useState<boolean>(false);
  const [showEndCalendar, setShowEndCalendar] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<{ value?: boolean; name: string }[]>([
    {
      value: filters?.isDeleted?.includes("Show Deleted"),
      name: "Show Deleted",
    },
  ]);

  useGetAll_UnitData(
    selectedPlant.plantId,
    (data: UnitGetAllDTO) => {
      setUnits(data.data);
    },
    () => { }
  );

  useGetAll_OMManualSectionData(
    (data: OMManualSectionGetAllDTO) => {
      if (documentModule.id === ModuleIDs.OMManuals) {
        if (filters.ommanualSections?.length && filters.ommanualSections?.length > 0) {
          setOmmanualSections(
            data.data.map((s) => {
              var fil = filters.ommanualSections?.filter((p) => p == s.id);
              if (fil?.length && fil?.length > 0) s.checked = true;
              return s;
            })
          );
        } else {
          setOmmanualSections(data.data);
        }
      }
    },
    () => { }
  );

  const group = documentGroups.find((dg) => dg.id === groupId);
  useQuery(
    [plantConfig.data.baseOrgId, "reports", group?.id, "getReports"],
    () => documentModule.documentApi.getAll(group?.id, plantConfig.data.baseOrgId),
    {
      onSuccess: (data: PlantPermissionedReports) => {
        setIStatus([]);
        if (documentModule.id !== ModuleIDs.TechnicalBulletins) return;

        var uniqueArray = data.items.map((r) => {
          return { value: false, name: r.implementationStatus };
        });

        if (!uniqueArray) return;
        uniqueArray = uniqueArray.filter((v, i, a) => a.findIndex((d) => d.name === v.name) === i);
        setIStatus(
          uniqueArray.map((s) => {
            var fil = filters.iStatus?.filter((p) => p == s.name);
            if (fil?.length && fil?.length > 0) s.value = true;
            return s;
          })
        );
      },
      enabled: !!group,
    }
  );

  useGetAllByUnitID_PlantEquipments(
    unit || 0,
    (data: PlantEquipmentsDTO) => {
      setEquipmentOptions(data.data);
      if (filters.equipment && filters.equipment.length > 0) {
        handleEquipmentSelection(
          filters.equipment.map((d) => d.plantEquipmentId),
          filters.equipment[0].plantEquipmentId
        );
      }
    },
    () => setEquipmentOptions([])
  );

  useEffect(() => {
    setEquipment([]);
  }, [unit]);

  useEffect(() => {
    if (filters.unit) {
      setUnit(filters.unit);
    }
    if (filters.equipment && filters.equipment.length > 0) {
      handleEquipmentSelection(
        filters.equipment.map((d) => d.plantEquipmentId),
        filters.equipment[0].plantEquipmentId
      );
    }
    if (filters.dateFilter) {
      setDateFilter(filters.dateFilter);
      if (filters.dateFilter === "custom") {
        if (filters.startDate) {
          setStartDate(dayjs(filters.startDate));
        }
        if (filters.endDate) {
          setEndDate(dayjs(filters.endDate));
        }
        setFocusedInput(null);
      }
    }

    setIsDeleted(
      isDeleted.map((s) => {
        var fil = filters.isDeleted?.filter((p) => p === s.name);
        if (fil?.length && fil?.length > 0) s.value = true;
        return s;
      })
    );
  }, [filters]);

  const applyFilters = () => {
    setFiltersChanged(false);
    const selectedEquipment = equipment.filter((d) => d > 0);
    const filters: Filters = {};
    if (unit) {
      filters.unit = unit;
    }
    if (selectedEquipment.length > 0) {
      filters.equipment = equipmentOptions.filter((d) => selectedEquipment.includes(d.plantEquipmentId));
      // filters.equipment = selectedEquipment;
    }
    if (dateFilter) {
      if (dateFilter === "custom" && startDate && endDate) {
        filters.startDate = dayjs(startDate.startOf("day"));
        filters.endDate = dayjs(endDate.endOf("day"));
      } else {
        filters.startDate = FilterDateComparisons(dateFilter)
      }
      filters.dateFilter = dateFilter;
    }
    if (ommanualSections?.find((s) => s.checked)) {
      filters.ommanualSections = ommanualSections.filter((s) => s.checked).map((s) => s.id);
    }
    if (iStatus?.find((s) => s.value == true)) {
      filters.iStatus = iStatus.filter((s) => s.value).map((s) => s.name);
    }
    if (user.portalAdmin || permissions.canAdmin) {
      if (isDeleted?.find((s) => s.value === true)) {
        filters.isDeleted = isDeleted.filter((s) => s.value).map((s) => s.name);
      } else {
        filters.isDeleted = undefined;
      }
    }
    onFiltersApplied(filters);
  };

  // get all unique product type ids and names
  useEffect(() => {
    let tmpProductTypes: { [index: number]: string } = {};
    if (equipmentOptions) {
      tmpProductTypes = equipmentOptions?.reduce((carry: { [index: number]: string }, current: PlantEquipmentsDATA) => {
        carry[current.productType] = current.productTypeName;
        return carry;
      }, {});
    }
    const ids = Object.keys(tmpProductTypes);
    const productTypes: { id: number; name: string }[] = [];
    for (let i = 0; i < ids.length; i++) {
      let key = Number.parseInt(ids[i]);
      productTypes.push({ id: key * -1, name: `All ${tmpProductTypes[key]}` });
    }
    setProductTypes(productTypes);
  }, [equipmentOptions]);

  const equipmentOptionItems: { id: number; name: string }[] = [{ id: 0, name: "All Equipment" }, ...productTypes];

  if (equipmentOptions) {
    for (let i = 0; i < equipmentOptions.length; i++) {
      equipmentOptionItems.push({
        id: equipmentOptions[i].plantEquipmentId,
        name: equipmentOptions[i].equipmentName,
      });
    }
  }

  const getEquipmentLabel = (values: number[]) => {
    if (!!(values.length > 1)) {
      const eqCountByType = equipmentOptions?.filter(eq => eq.productType === (values.filter(v => v < 0)[0] * -1)).length || 0;
      const valuesIncludeEqFromOutsideGroup = values.length !== eqCountByType + 1;
      if (valuesIncludeEqFromOutsideGroup)
        setSelectionText('Multiple equipment options selected');
      else if (!valuesIncludeEqFromOutsideGroup)
        setSelectionText(equipmentOptionItems.filter(type => values.includes(type.id))[0].name);
    }
    // if values includes 'All Equipment'
    if (values.includes(0)) setSelectionText(equipmentOptionItems[0].name);
    // if only 1 option selected
    if (values.length === 1) setSelectionText(equipmentOptionItems.filter(el => el.id === values[0])[0]?.name)
  }

  const handleEquipmentSelection = (values: number[], toggled: number) => {
    setFiltersChanged(true);
    let tmpValues = [...values];
    let tmpSiteEquipment = equipmentOptions || [];

    /**
     * Remove all grouped options from tmpValues
     * if toggled = 0 and orig values includes 0, select all
     * if toggled = 0 and orig values does not include 0, un select all
     *
    */

    if (toggled === 0) {
      if (values.includes(0)) {
        tmpValues = tmpSiteEquipment.map((d) => d.plantEquipmentId);
      } else {
        tmpValues = [];
      }
    }

    if (toggled < 0) {
      let productTypeId = toggled * -1;
      // a group was selected, check everything in that group
      if (values.includes(toggled)) {
        for (let i = 0; i < tmpSiteEquipment.length; i++) {
          if (tmpSiteEquipment[i].productType === productTypeId && !tmpValues.includes(tmpSiteEquipment[i].productType)) {
            tmpValues.push(tmpSiteEquipment[i].plantEquipmentId);
          }
        }
      } else {
        // a group was deselected, un check everything in that group
        let productEquipment = tmpSiteEquipment.filter(eq => eq.productType === productTypeId).map(eq => eq.plantEquipmentId);
        tmpValues = tmpValues.filter(v => !productEquipment.includes(v));
      }
    }

    for (let i = 0; i < productTypes.length; i++) {
      let allOptionsOfTypeSelected = true;
      let productTypeId2 = +productTypes[i].id * -1;
      for (let j = 0; j < tmpSiteEquipment.length; j++) {
        if (tmpSiteEquipment[j].productType === productTypeId2) {
          allOptionsOfTypeSelected = allOptionsOfTypeSelected && tmpValues.includes(tmpSiteEquipment[j].plantEquipmentId);
        }
      }
      if (allOptionsOfTypeSelected) {
        tmpValues.push(productTypes[i].id);
      } else {
        tmpValues = tmpValues.filter(val => (val * -1) !== productTypeId2)
      }
    }

    // if everything is selected, check select all
    if (tmpValues.length > 0 && tmpValues.filter((d) => d > 0).length === tmpSiteEquipment.length) {
      tmpValues.push(0);
      for (let i = 0; i < productTypes.length; i++) {
        tmpValues.push(productTypes[i].id);
      }
    } else tmpValues = tmpValues.filter((val) => val !== 0);

    tmpValues = tmpValues.filter((v, i, a) => a.indexOf(v) === i);

    setEquipment(tmpValues);

    getEquipmentLabel(tmpValues);
  };

  const unitOptions: DropDownOptionItem[] = (units || []).map((unit) => ({ id: unit.unitId, name: unit.name }));
  const canApplyFilters =
    ((equipment.length > 0 || dateFilter) && !(dateFilter === "custom" && (!startDate || !endDate))) ||
    filtersChanged ||
    ommanualSections.find((s) => s.checked !== undefined) ||
    iStatus.find((s) => s.value === true) ||
    isDeleted.find((s) => s.value !== undefined);

  const datastring: string[] = [];

  const selectOptionE = (selectOption: string) => {
    const newState = iStatus.map((obj) => {
      if (obj.name === selectOption) {
        return { ...obj, value: !obj.value };
      }
      return obj;
    });

    setIStatus(newState);
  };

  const selectOptionD = (selectOption: string) => {
    const newState = isDeleted.map((obj) => {
      if (obj.name === selectOption) {
        return { ...obj, value: !obj.value };
      }
      return obj;
    });

    setIsDeleted(newState);
  };

  const selectedUnit = useMemo(() => {
    return unitOptions.map((d) => d.id).includes(unit) ? unit : false;
  }, [unit, unitOptions]);

  return (
    <div className={`${classes.flex_column} ${classes.filter_tooltip_content}`}>
      {(user?.portalAdmin || permissions.canAdmin) && (
        <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
          <label>Show Deleted:</label>
          <div className={classes.checkboxWrapper}>
            {isDeleted.map((status, index) => (
              <div className={classes.checkboxRow} key={index} onClick={() => selectOptionE(status.name)}>
                <ToggleSwitch isChecked={status.value || false} label={status.name} onChange={() => selectOptionD(status.name)} />
              </div>
            ))}
          </div>
        </div>
      )}

      {iStatus.length > 0 && (
        <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
          <label>Implementation Status:</label>
          <div className={classes.checkboxWrapper}>
            {iStatus
              .filter((filter) => filter?.name?.length > 0)
              .map((status, index) => (
                <div className={classes.checkboxRow} key={index} onClick={() => selectOptionE(status.name)}>
                  <Checkbox isChecked={status.value} label={status.name} onChange={() => { }} />
                </div>
              ))}
          </div>
        </div>
      )}

      {ommanualSections.length > 0 && (
        <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
          <label>Section:</label>
          <div className={classes.checkboxWrapper}>
            {ommanualSections.map((section, index) => (
              <div
                className={classes.checkboxRow}
                key={index}
                onClick={() =>
                  setOmmanualSections(
                    ommanualSections.map((s) => {
                      if (s.id === section.id) s.checked = !s.checked;
                      return s;
                    })
                  )
                }
              >
                <Checkbox isChecked={section.checked || false} label={section.name} onChange={() => { }} />
              </div>
            ))}
          </div>
        </div>
      )}

      <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
        <label>Unit Association:</label>
        <PlainDropDown
          className="relative"
          defaultText="Select a unit"
          selectClass={`flex flex-row items-center border border-solid border-[#999] pt-1 pr-2 !pb-1 pl-4 text-sm justify-between rounded ${!selectedUnit && 'text-[#999]'}`}
          optionsClass="flex flex-col p-1 bg-white rounded max-h-80 w-full"
          itemClass="py-[14px] px-6 cursor-pointer hover:bg-primary-20"
          onSelection={(unit) => setUnit(unit.id)}
          value={selectedUnit}
          options={unitOptions}
        />
      </div>
      <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
        <label>Equipment Association:</label>
        <MultiSelectDropDown
          className="relative"
          selectClass={`flex flex-row items-center pt-1 pr-2 !pb-1 pl-4 text-sm justify-between rounded ${equipment.length === 0 && 'text-[#999]'} ${selectedUnit && equipmentOptions.length > 0? 'border border-[#999] border-solid' : 'bg-[#F2F2F2]'}`}
          optionsClass="flex flex-col p-1 bg-white rounded max-h-80 w-full"
          itemClass="py-[14px] px-6 cursor-pointer hover:bg-primary-20"
          onSelection={handleEquipmentSelection}
          value={equipment}
          disabled={!unit}
          disabledClass={classes.select_disabled}
          noSelectionText="Select equipment"
          hasSelectionText={selectionText}
          options={unit ? equipmentOptionItems : []}
        />
      </div>
      <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
        <label>Date Published:</label>
        <RadioButtonGroup onChange={setDateFilter} name="date_published" options={DatePublishedFilters} value={dateFilter} />
      </div>
      {dateFilter === "custom" && (
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          showEndCalendar={showEndCalendar}
          showStartCalendar={showStartCalendar}
          startName="startDate"
          endName="endDate"
          startDateChange={setStartDate}
          endDateChange={setEndDate}
          setToggleEndCalendarDisplay={setShowEndCalendar}
          setToggleStartCalendarDisplay={setShowStartCalendar}
        />
      )}
      <div className={`${classes.filter_tooltip_field}`}>
        <Button text="Apply" className="darkBlue" onClick={applyFilters} disabled={!canApplyFilters} />
      </div>
    </div>
  );
};
