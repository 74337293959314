import { useContext } from "react";
import { StaticFileLink } from "../../../components/StaticFileLink/StaticFileLink";
import { TakasagoTechBulletinWebsiteURL } from "../../../endpoints/endpoints";
import { Store } from "../../../Store";
import ImageImports from "../../../utils/ImageImports";

const ESRLinks = ({ setErrorData }: { setErrorData: (errorData: any) => void }) => {
	const { linkout } = ImageImports;

	const { user } = useContext(Store);
	
	let esrAppUrl = 'https://apps.powerapps.com/play/e/43262104-6aa4-4a12-9dee-b4f287254956/a/385bef30-0cfe-45eb-885b-027c79b2e189';
	
	return (
		<div style={{ fontSize: 14 }}>
			{user?.mpInternal && (
			<div className="mb-5">
				<div className="font-bold gap-5 flex items-center">
					<a href={esrAppUrl} target="_blank" rel="noreferrer" className="no-underline text-black flex items-center">
						<img src={linkout} className="bg-gray-300 rounded-lg w-4 p-0.5 mr-2" alt="Go to Internal ESR App" /> <span>Go to Internal ESR App</span>
					</a>
				</div>
				<div>To learn more about the ESR system, please see the instructional work aid <StaticFileLink StaticFileUrl={process.env.REACT_APP_ESR_INSTRUCTIONAL_WORK_AID ?? ''} text="here"/>.</div>
			</div>
			)}
			<div>
				<div className="font-bold gap-5 flex items-center">
					<a href={TakasagoTechBulletinWebsiteURL} target="_blank" rel="noreferrer" className="no-underline text-black flex items-center">
						<img src={linkout} className="bg-gray-300 rounded-lg w-4 p-0.5 mr-2" alt="Go to Takasago Technical Bulletin Website (TBW)" /> <span>Go to Takasago Technical Bulletin Website (TBW)</span>
					</a>
				</div>
				<div>
					The Gas Turbine and Gas Turbine Generator Technical Bulletins are on the Technical Bulletin Website administered by Gas Turbine Service Engineering in Takasago, and requires a
					separate username and password.
				</div>
			</div>			
		</div>
	);
};

export default ESRLinks;
