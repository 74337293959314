import { AxiosError } from "axios";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import { Store } from "../../Store";
import Alert from "../../components/Alert/Alert";
import Button from "../../components/Button/Button";
import ErrorCard from "../../components/ErrorCard/ErrorCard";
import Footer from "../../components/Footer/Footer";
import { ItemActionDefinition, ItemList, ItemListColumnDefinition, SortOptionsDefinition, SortablePropertiesDefinition } from "../../components/List/ItemList";
import EmptyModal from "../../components/Modals/EmptyModal";
import Pill from "../../components/Pill/Pill";
import { createBaseOrgGroup, deleteBaseOrgGroup, updateBaseOrgGroup, useGetAllBaseOrgGroups } from "../../customHooks/PlantHttpServices";
import { usePageRequiresAdmin } from "../../customHooks/usePageRequiresAdmin";
import ImageImports from '../../utils/ImageImports';
import { BaseOrgGroup } from "../../utils/interface";
import classes from "./OrgGroupList.module.css";

const { ellipsis, pencil, trash } = ImageImports;
interface FilterStructure {
    plantAssociations: 'any'|'none'|'some';
}
export const OrgGroupList = () => {
    usePageRequiresAdmin();
    const { showError } = useContext(Store);

    const [isAdding, setIsAdding] = useState<boolean>(false);
    const [createdOrganization, setCreatedOrganization] = useState<BaseOrgGroup>();
    const [deleteConfirmation, setDeleteConfirmation] = useState<BaseOrgGroup>();
    const [deletedOrganization, setDeletedOrganization] = useState<BaseOrgGroup>();
    const [editOrganization, setEditOrganization] = useState<BaseOrgGroup>();
    const [editConfirmation, setEditConfirmation] = useState<BaseOrgGroup>();
    const [filters, setFilters] = useState<FilterStructure>({plantAssociations: 'any'});
    const [closeFilters, setCloseFilters] = useState<number>(0);
    const [tableData, setTableData] = useState<BaseOrgGroup[]>([]);

    const {refetch: refetchOrganizations, data: orgGroups} = useGetAllBaseOrgGroups();

    const {mutate: createOrganization, error: createOrgGroupError} = useMutation<BaseOrgGroup, AxiosError<string>, Pick<BaseOrgGroup, "name" | "description">, unknown>(createBaseOrgGroup, {
        onSuccess: (group) => {
            refetchOrganizations()
            setIsAdding(false);
            setCreatedOrganization(group);
        }
    });

    const {mutate: updateOrganization, error: updateOrgGroupError} = useMutation<BaseOrgGroup, AxiosError<string>, BaseOrgGroup, unknown>(updateBaseOrgGroup, {
        onSuccess: (group) => {
            setEditOrganization(undefined);
            setEditConfirmation(group);
            refetchOrganizations();
        }
    });

    const {mutate: deleteOrganization} = useMutation<boolean, AxiosError<string>, number>(deleteBaseOrgGroup, {
        onSuccess: () => {
            refetchOrganizations();
            setDeleteConfirmation(undefined);
            setDeletedOrganization(deleteConfirmation);
        }
    });

    const addAnother = useCallback(() => {
        setCreatedOrganization(undefined);
        setDeletedOrganization(undefined);
        setIsAdding(true);
    }, []);

    const filterGroups = useCallback((group: BaseOrgGroup): boolean => {
        switch (filters.plantAssociations) {
            case 'any':
                return true;
            case 'none':
                return group.associatedPlantsCount === 0;
            case 'some':
                return group.associatedPlantsCount > 0;
        }
    }, [filters]);

    const filterCount = useMemo(() => {
        let c = 0;
        if (filters.plantAssociations !== 'any') {
            c++;
        }
        return c;
    }, [filters]);

    useEffect(() => {
      setTableData(orgGroups?.filter(d => !d.deleted).filter(filterGroups) || []);
    }, [orgGroups, filterGroups]);
    
    const columns: ItemListColumnDefinition<BaseOrgGroup>[] = [
        {
            title: 'Organization Name',
            key: 'name',
        },
        {
            title: 'Description',
            key: 'description',
        },
        {
            title: 'Plant Associations',
            key: 'associatedPlantsCount'
        }
    ];
    const sortableProperties: SortOptionsDefinition<BaseOrgGroup>[] = [
        {
            id: 1,
            key: 'name',
            name: 'Name A-Z',
            direction: 'asc',
            default: true,
        },
        {
            id: 2,
            key: 'name',
            name: 'Name Z-A',
            direction: 'desc'
        },
        {
            id: 3,
            key: 'associatedPlantsCount',
            name: 'Plant Associations: High to Low',
            direction: 'desc'
        },
        {
            id: 4,
            key: 'associatedPlantsCount',
            name: 'Plant Associations: Low to High',
            direction: 'asc'
        }
    ];

    const actions: ItemActionDefinition<BaseOrgGroup>[] = [
        {
            text: <div className="flex flex-row items-center flex-nowrap gap-[10px] whitespace-nowrap text-dark-blue cursor-pointer font-bold">
                <img src={pencil} alt="Edit Details" />
                <span>Edit Details</span>
            </div>,
            onClick(event, data) {
                setEditOrganization(data);
            }
        },
        {
            text: <div className="flex flex-row items-center flex-nowrap gap-[10px] whitespace-nowrap text-dark-blue cursor-pointer font-bold">
                <img src={trash} alt="Delete" style={{filter: 'var(--svgFilterDarkBlueButton)'}} />
                <span>Delete</span>
            </div>,
            onClick(event, data) {
                setDeleteConfirmation(data);
            }
        },
    ];

    
    
    return (
        <div className="w-full flex flex-col justify-between">
            {isAdding && <AddModal shown={isAdding} onClose={() => setIsAdding(false)} onAdd={createOrganization} error={createOrgGroupError} />}
            {createdOrganization && <AddOrganizationConfirmation organization={createdOrganization} shown={true} onClose={() => setCreatedOrganization(undefined)} onAddAnother={addAnother} />}
            {deleteConfirmation && <DeleteOrganizationConfirmation organization={deleteConfirmation} shown={true} onClose={() => setDeleteConfirmation(undefined)} onDelete={() => deleteOrganization(deleteConfirmation.id)} />}
            {deletedOrganization && <DeletedOrganization organization={deletedOrganization} onClose={() => setDeletedOrganization(undefined)} onAddAnother={addAnother} />}
            {editOrganization && <EditOrganization organization={editOrganization} shown={true} onClose={() => setEditOrganization(undefined)} onEdit={updateOrganization} error={updateOrgGroupError} />}
            {editConfirmation && <EditOrganizationConfirmation organization={editConfirmation} onClose={() => setEditConfirmation(undefined)} />}
            <div className="content ">
                {showError.isError && <ErrorCard ErrorMessage={showError.title} onRefresh={window.location.reload} ErrorType={showError.ErrorType} />}
            
                <div className="path breadcrumb">
                    <Link to={"/"} className="link_text">
                    Home
                    </Link>{" "}
                    / Administration / Organization Management
                </div>
                <div className="login-header">
                    Organization Management
                </div>
                <div className="flex flex-col p-6 gap-6 self-stretch bg-white">
                    <div className="flex flex-row items-start gap-6 self-stretch">
                        <div className="flex flex-col items-start gap-2 grow">
                            <span className="font-bold text-2xl">
                                Mitsubishi Power Organizations
                            </span>
                            <span className="text-sm">
                            See below for Mitsubishi Power Organizations, Customers and Associations that can be granted access to the various parts of the portal:
                            </span>
                        </div>
                        <div className="flex flex-row items-start gap-4 whitespace-nowrap">
                            <Button className="darkBlue" text="Add Organization" onClick={() => setIsAdding(true)} />
                        </div>
                    </div>
                    <hr className="m-0 text-[#B3B3B3]" />
                    <div className="self-stretch">
                        <ItemList 
                            data={tableData}
                            columns={columns}
                            sortValues={sortableProperties}
                            accordionTitle={g => g.name}
                            itemActions={actions}
                            itemActionText={() => <img src={ellipsis} alt="More Actions" className="cursor-pointer" style={{filter: 'var(--svgFilterDarkBlueButton)'}} />}
                            filterContent={<Filters filters={filters} onFiltersUpdated={f => {
                                setFilters(f);
                                setCloseFilters(closeFilters + 1);
                            }} />}
                            filterCount={filterCount}
                            closeFilters={closeFilters}
                            filters={<FilterPills filters={filters} onFiltersUpdated={setFilters} />} 
							searchBoxPlaceholder="Search organizations"
						/>
                    </div>
                </div>
            </div>
            <div className="h-6 pt-6"></div>
            <div className="content flex-grow-0 flex-end">
                <Footer />
            </div>
        </div>
    );
};

const AddModal = ({shown, error, onClose, onAdd}: {shown: boolean, error: AxiosError<string>|null, onClose: () => void, onAdd: (baseOrgGroup: Pick<BaseOrgGroup, 'name' | 'description'>) => void}) => {
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');

    useEffect(() => {
        setName('');
        setDescription('');
    }, [shown]);

    const body = useMemo(() => {
        return <div className="flex flex-col items-start gap-6 self-stretch">
            {error && error.response && (
                <Alert type="error" onClose={() => {}} dismissible={false}>
                    {error.response.data ?? ''}
                </Alert>
            )}
            <div className="flex flex-row items-start gap-6 self-stretch">
                <span className="basis-44">Organization Name*</span>
                <input type="text"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    placeholder="Organization name"
                    className={`pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base ${error? 'bg-[#FEE2E2] rounded' : ''}`}
                    style={{boxShadow: `0 1px 0 ${error? '#FEE2E2' : '#B3B3B3'}`}} />
            </div>
            <div className="flex flex-row items-start gap-6 self-stretch">
                <span className="basis-44">Description</span>
                <input type="text"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    placeholder="Description"
                    className="pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base"
                    style={{boxShadow: '0 1px 0 #B3B3B3'}} />
            </div>
        </div>
    }, [name, description, error]);

    const isValid = useMemo(() => {
        return name.trim().length > 0;
    }, [name]);

    const add = useCallback(() => {
        onAdd({name, description});
    }, [name, description]);

    return (
        <EmptyModal body={body} footer={(
            <div className="flex flex-row justify-between items-center pt-6 grow">
                <Button className="darkBlue" text="Add Organization" disabled={!isValid} onClick={add} />
                <span className="text-sm font-light text-right">*Required Fields</span>
            </div>
        )} heading="Add Organization" onClose={onClose} shown={shown} />
    );
};

const AddOrganizationConfirmation = ({shown, organization, onClose, onAddAnother}: {shown: boolean, organization: BaseOrgGroup, onClose: () => void, onAddAnother: () => void}) => {
    return (
        <EmptyModal body={(
                <span>"{organization.name}" was successfully added to the global Mitsubishi Power organizations list. This organization is now able to be associated to plants. Would you like to add another organization at this time?</span>
            )}
            footer={(
                <div className="flex flex-row justify-center grow gap-4">
                    <Button className="darkBlue" text="Add Another Organization" onClick={onAddAnother} />
                    <Button className="whiteBtn" text="Done" onClick={onClose} />
                </div>
            )}
            heading="Organization Successfully Added"
            headingClass="text-center"
            onClose={onClose} shown={shown} />
    );
};

const DeleteOrganizationConfirmation = ({shown, organization, onClose, onDelete}: {shown: boolean, organization: BaseOrgGroup, onClose: () => void, onDelete: () => void}) => {
    return (
        <EmptyModal body={(
                <div className="flex flex-col items-start gap-6">
                    <span>Are you sure you want to delete the selected organization from Mitsubishi Power? Any plant(s) associated to this organization will remain in the database. This cannot be undone.</span>
                    <div className="text-[#666] text-sm self-stretch">
                        {organization.name}<br />
                        {organization.description}
                    </div>
                </div>
            )}
            footer={(
                <div className="flex flex-row grow gap-4">
                    <Button className="redDelete" text="Yes, Delete" onClick={onDelete} />
                    <Button className="whiteBtn" text="Cancel" onClick={onClose} />
                </div>
            )}
            heading="Delete Organization?"
            onClose={onClose} shown={shown} />
    );
}

const DeletedOrganization = ({organization, onClose, onAddAnother}: {organization: BaseOrgGroup, onClose: () => void, onAddAnother: () => void}) => {
    return (
        <EmptyModal body={(
                <span>You successfully deleted the organization "{organization.name}". Would you like to add another organization to Mitsubishi Power at this time?</span>
            )}
            footer={(
                <div className="flex flex-row justify-center grow gap-4">
                    <Button className="darkBlue" text="Add Organization" onClick={onAddAnother} />
                    <Button className="whiteBtn" text="Done" onClick={onClose} />
                </div>
            )}
            heading="Organization Successfully Deleted"
            headingClass="text-center"
            onClose={onClose} shown={true} />
    );
};

const EditOrganization = ({shown, error, organization, onClose, onEdit}: {shown: boolean, error: AxiosError<string>|null, organization: BaseOrgGroup, onClose: () => void, onEdit: (baseOrgGroup: BaseOrgGroup) => void}) => {
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');

    useEffect(() => {
        setName(organization.name);
        setDescription(organization.description ?? '');
    }, [organization]);

    const body = useMemo(() => {
        return <div className="flex flex-col items-start gap-6 self-stretch">
            {error && error.response && (
                <Alert type="error" onClose={() => {}} dismissible={false}>
                    {error.response.data ?? ''}
                </Alert>
            )}
            <div className="flex flex-row items-start gap-6 self-stretch">
                <span className="basis-44">Organization Name*</span>
                <input type="text"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    placeholder="Organization name"
                    className={`pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base ${error? 'bg-[#FEE2E2] rounded' : ''}`}
                    style={{boxShadow: `0 1px 0 ${error? '#FEE2E2' : '#B3B3B3'}`}} />
            </div>
            <div className="flex flex-row items-start gap-6 self-stretch">
                <span className="basis-44">Description</span>
                <input type="text"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    placeholder="Description"
                    className="pt-[5px] pr-2 pb-[5px] pl-1 outline-none border-none grow text-base"
                    style={{boxShadow: '0 1px 0 #B3B3B3'}} />
            </div>
        </div>
    }, [name, description, error]);

    const isValid = useMemo(() => {
        return name.trim().length > 0;
    }, [name]);

    const isDifferent = useMemo(() => {
        return name !== organization.name || description !== (organization.description ?? '');
    }, [name, description, organization]);

    const edit = useCallback(() => {
        onEdit({...organization, name, description});
    }, [name, description]);

    return (
        <EmptyModal body={body} footer={(
            <div className="flex flex-row justify-between items-center pt-6 grow">
                <Button className="darkBlue" text="Save Changes" disabled={!isValid || !isDifferent} onClick={edit} />
                <span className="text-sm font-light text-right">*Required Fields</span>
            </div>
        )} heading="Edit Organization Details" onClose={onClose} shown={shown} />
    );
};

const EditOrganizationConfirmation = ({organization, onClose}: {organization: BaseOrgGroup, onClose: () => void}) => {
    return (
        <EmptyModal body={(
                <span>The organization details for "{organization.name}" have been successfully updated.</span>
            )}
            footer={(
                <div className="flex flex-row justify-center grow gap-4">
                    <Button className="darkBlue" text="Done" onClick={onClose} />
                </div>
            )}
            heading="Changes Saved"
            headingClass="text-center"
            onClose={onClose} shown={true} />
    );
};

const Filters = ({filters, onFiltersUpdated}: {filters: FilterStructure, onFiltersUpdated: (data: FilterStructure) => void}) => {
    const [localFilters, setLocalFilters] = useState<FilterStructure>({plantAssociations: 'any'});

    useEffect(() => {
        setLocalFilters(filters);
    }, [filters]);

    const isDifferent = useMemo(() => {
        return Object.entries(localFilters).toString() !== Object.entries(filters).toString();
    }, [filters, localFilters]);

    return (
        <div className="flex flex-col items-start p-4 gap-6 whitespace-nowrap">
            <div className="flex flex-col items-start gap-2">
                <span className="font-bold">Plant Associations:</span>
                <label className="flex flex-row gap-2 items-start">
                    <input type="radio"
                            name="plantAssociations"
                            value="none"
                            onChange={e => setLocalFilters({...localFilters, plantAssociations: 'none'})}
                            checked={localFilters.plantAssociations === 'none'} />
                    No Plant Associations
                </label>
                <label className="flex flex-row gap-2 items-start">
                    <input type="radio"
                            name="plantAssociations"
                            value="some"
                            onChange={e => setLocalFilters({...localFilters, plantAssociations: 'some'})}
                            checked={localFilters.plantAssociations === 'some'} />
                    ≥ 1 Plant Associations
                </label>
            </div>
            <div>
                <Button className="darkBlue" text="Apply" onClick={() => onFiltersUpdated(localFilters)} disabled={!isDifferent} />
            </div>
        </div>
    );
};

const FilterPills = ({filters, onFiltersUpdated}: {filters: FilterStructure, onFiltersUpdated: (data: FilterStructure) => void}) => {
    const plantAssociationMap = {
        some: '1 ≥ Plant Associations',
        none: 'No Plant Associations'
    };
    
    return (
        <>
            {filters.plantAssociations !== 'any' && (
                <div className={classes.filter_pill_container}>
                    <Pill 
                        className={classes.filter_pill}
                        dismissible={true}
                        onDismiss={() => onFiltersUpdated({...filters, plantAssociations: 'any'})}
                        type="default">
                            {plantAssociationMap[filters.plantAssociations]}
                    </Pill>
                </div>
            )}
        </>
    );
};