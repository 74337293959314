import React, { FunctionComponent, useEffect, useState, useMemo } from "react";

import { PlantManagementFilterInterface } from "../../../../utils/interface";

import "react-dates/lib/css/_datepicker.css";
import "../../../IssueReports/React.Dates.Override.css";

const defaultFilters: PlantManagementFilterInterface = {
	unitAssociations: 'any'
};

const PlantManagementFilter: FunctionComponent<{
	appliedFilters: PlantManagementFilterInterface;
	setAppliedFilters: React.Dispatch<React.SetStateAction<PlantManagementFilterInterface>>;
}> = ({ appliedFilters, setAppliedFilters }) => {
	const [filters, setFilters] = useState<PlantManagementFilterInterface>(defaultFilters);

	useEffect(() => {
		setFilters(appliedFilters);
	}, [appliedFilters]);

	const isDisabled = useMemo(() => {
		return Object.entries({
			unitAssociations: filters.unitAssociations
		}).toString() === Object.entries({
			unitAssociations: appliedFilters.unitAssociations
		}).toString();
	}, [filters, appliedFilters]);

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				setAppliedFilters(filters);
			}}
			onClick={(e) => e.stopPropagation()}
		>
			<div className="flex flex-col items-start p-4 gap-6 whitespace-nowrap">
				<div className="flex flex-col items-start gap-2">
					<span className="font-bold">Unit Associations:</span>
					<label className="flex flex-row gap-2 items-start">
						<input type="radio"
								name="unitAssociations"
								value="none"
								onChange={e => setFilters({...filters, unitAssociations: 'none'})}
								checked={filters.unitAssociations === 'none'} />
						No Unit Associations
					</label>
					<label className="flex flex-row gap-2 items-start">
						<input type="radio"
								name="unitAssociations"
								value="some"
								onChange={e => setFilters({...filters, unitAssociations: 'some'})}
								checked={filters.unitAssociations === 'some'} />
						1 ≥ Unit Associations
					</label>
				</div>
			</div>
			<div className="">
				<button
					type="submit"
					className="primaryBtn darkBlue"
					disabled={isDisabled}
				>
					Apply
				</button>
			</div>
		</form>
	);
};

export default PlantManagementFilter;
