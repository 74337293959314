import { AxiosError } from "axios";
import dayjs, { Dayjs } from "dayjs";
import React, { CSSProperties, FunctionComponent, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Moment from "react-moment";
import { useParams } from "react-router-dom";
import Button from "../../components/Button/Button";
import Dropdown from "../../components/Dropdown/Dropdown";
import Loader from "../../components/Loader/Loader";
import Pill from "../../components/Pill/Pill";
import { withTooltip } from "../../components/PopoutTooltip/Tooltip";
import SimpleSearch from "../../components/Search/SimpleSearch";
import CustomDateRangePicker from "../../components/UI/BasicForm/CustomSingleDatePicker/DateRangePicker";
import { Checkbox } from "../../components/UI/Form/Checkbox/Checkbox";
import { DropDownOptionItem, PlainDropDown } from "../../components/UI/Form/DropDownSelect/PlainDropDown";
import { RadioButtonGroup } from "../../components/UI/Form/RadioButtonsGrouped/RadioButtonsGrouped";
import ToggleSwitch from "../../components/UI/ToggleSwitch/ToggleSwitch";
import useFileViewer from "../../customHooks/useFileViewer";
import { useOnError } from "../../customHooks/useOnError";
import usePagination from "../../customHooks/usePagination";
import useWindowSize from "../../customHooks/useWindowSize";
import { useStore } from "../../useStore";
import ImageImports from "../../utils/ImageImports";
import { FilterDateComparisons } from "../../utils/Sortutils";
import { DataWithPermissions, DiscussionItem, IssueReport, Permissions, User, UnitDATA, PlantEquipmentsDATA, PlantEquipmentsDTO, UnitGetAllDTO } from "../../utils/interface";
import reportClasses from "../Reports/Reports.module.css";
import { useQuery_IssueReportItems } from "./IssueReportHttpServices";
import IssueReportItem from "./IssueReportItem";
import classes from "./IssueReports.module.css";
import MultiSelectDropDown from "../../components/UI/Form/DropDownSelect/MultiSelectDropDown";
import { useGetAllByUnitID_PlantEquipments } from "../PlantInformation/EquipmentsHttpServices";
import { useGetAll_UnitData } from "../PlantInformation/UnitHttpServices";

interface SortOption {
  id: number;
  name: string;
  key: keyof IssueReport;
  direction: "asc" | "desc";
}

const sortOptions: SortOption[] = [
  {
    id: 1,
    name: "Posted: Newest to Oldest",
    key: "created",
    direction: "desc",
  },
  {
    id: 2,
    name: "Posted: Oldest to Newest",
    key: "created",
    direction: "asc",
  },
  {
    id: 3,
    name: "Priority: Low to High",
    key: "priority",
    direction: "asc",
  },
  {
    id: 4,
    name: "Priority: High to Low",
    key: "priority",
    direction: "desc",
  },

  {
    id: 5,
    name: "Modified: Newest to Oldest",
    key: "updated",
    direction: "desc",
  },
  {
    id: 6,
    name: "Modified: Oldest to Newest",
    key: "updated",
    direction: "asc",
  },
];
const { filter, Arrow } = ImageImports;

const IssueReportList: FunctionComponent<{
  setDiscussionItem: React.Dispatch<React.SetStateAction<DiscussionItem | undefined>>;
  params?: { preSort?: string; preFilter?: string };
}> = ({ setDiscussionItem, params }) => {
  const {
    selectedPlant,
    plantConfig,
    user,
    header: {
      data: { notifications },
    },
  } = useStore();
  const tooltipPortalRef = useRef(null);
  const [sortSelectedOption, setSortSelectedOption] = useState<string | null>(null);
  const [searchText, setSearchText] = useState("");
  const ButtonWithTooltip = withTooltip(Button);
  const [tableData, setTableData] = useState<IssueReport[]>([]);
  const [filters, setFilters] = useState<Filters>({});
  const [closeFilters, setCloseFilters] = useState<number>(0);
  const { preSort = "", preFilter = "" } = params || {};
  const [enableClickWatcher, setEnableClickWatcher] = useState<boolean>(true);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const { globalId: searchGlobalId } = useParams();
  const [pagePermissions, setPagePermissions] = useState<Permissions>({
    baseOrg: null,
    canAdd: false,
    canAdmin: false,
    canDelete: false,
    canEdit: false,
    canView: false,
    orgGroup: null,
    role: ''
  });

  //////////////////////////////////////////////////////////
  // Starts Hooks ////////////////////////
  //////////////////////////////////////////////////////////

  const globalId = useMemo(() => {
    return Number(searchGlobalId) || 0;
  }, [searchGlobalId]);

  const { isDesktop, isMobile, isTablet } = useWindowSize();

  const { handleFileView, isLoading } = useFileViewer();
  const setErrorData = useOnError();

  const onSuccess = (data: DataWithPermissions<IssueReport[]>) => {
    if (data.data.length > 0) {
      let issueReport: IssueReport | undefined;
      if (globalId > 0 && (issueReport = data.data.find(d => d.globalId === globalId)) !== undefined) {
        setDiscussionItem({
          id: issueReport.reportId,
          globalId: issueReport.globalId,
          description: issueReport.displayFileName,
          topic: "Issue Report",
          statusBadge: {
            label: issueReport.status,
            color: issueReport.status === "Open" ? `#DC6914` : `#5E8800`,
          },
          isLocked: issueReport.isLocked,
          statusChanges: (issueReport.statusChanges || []),
          statusTimestamp:
            issueReport.statusChanges? dayjs(Math.max(...issueReport.statusChanges?.map((s) => dayjs(s.modified).get('ms')))) : undefined
        });
      } else {
        setShowLoading(true);
        setPagePermissions(data.permissions);
        setTableData(data.data);
        setData((data.data).filter(filterReports));
        setShowLoading(false);
      }
    }
  };
  const onError = (error: AxiosError<string>) => setErrorData(error);

  const { isLoading: isFetching } = useQuery_IssueReportItems(plantConfig.data.baseOrgId, onSuccess, onError);

  const {
    pagedData,
    setData,
    totalRecords,
    setFilter,
    pageButtonNumbers,
    hasPreviousPage,
    previousPage,
    hasNextPage,
    nextPage,
    setCurrentPageNumber,
    currentPageNumber,
    resultsPerPage,
    setResultsPerPage,
    setSortKey,
    setSortDirection,
  } = usePagination<IssueReport>();

  //////////////////////////////////////////////////////////
  // End Hooks ////////////////////////
  //////////////////////////////////////////////////////////

  // apply default filters/sort should only run 1 time on mount
  useEffect(() => {
    setFilters({ ...filters, isDeleted: user?.portalAdmin ? ["Show Deleted"] : undefined });
    const today = dayjs().startOf('day');    
    if (!!preFilter?.length) {
      setShowLoading(true);
      if (preFilter === "new") {        
        setFilters({ ...filters, datePostedStartDate: today.subtract(2, 'weeks'), datePostedendDate: today.endOf('day'), datePostedFilter: 'datepostedcustom' });
      } else if (preFilter === "aged") {
        setFilters({ ...filters, datePostedendDate: today.subtract(6, 'months'), datePostedFilter: 'datepostedcustom', isStatus: ["Open"] } );
      } else if (preFilter === "resolved") {
        setFilters({...filters, dateModifiedStartDate: dayjs().startOf('year'), dateModifiedendDate: today.endOf('day'), dateModifiedFilter: 'datemodifiedcustom', isStatus: ["Closed"] });
      }
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterReports = useCallback(
    (report: IssueReport): boolean => {
      let matches = true;
      
      if (filters.datePostedStartDate) {
        matches = dayjs(report.created).isAfter(filters.datePostedStartDate);
      }
      if (matches && filters.datePostedendDate) {
        matches = dayjs(report.created).isBefore(filters.datePostedendDate);
      }
      if (matches && filters.dateModifiedStartDate) {
        matches = dayjs(report.updated).isAfter(filters.dateModifiedStartDate);
      }
      if (matches && filters.dateModifiedendDate) {
        matches = dayjs(report.updated).isBefore(filters.dateModifiedendDate);
      }
      if (matches && user?.portalAdmin && !filters.isDeleted?.includes("Show Deleted")) {
        matches = report.isDeleted === false;
      }

      if (matches && filters.equipment && filters.equipment.length > 0) {        
        for (let i = 0; i < filters.equipment.length; i++) {
          const obj = filters.equipment[i];
          if(obj.equipmentName !== report.equipmentName) {
            matches = false
          }
        }
      }

      if (matches && filters.system?.length) {
        matches = filters.system.includes(report.system);
      }
      if (matches && filters.priority?.length) {
        matches = filters.priority.includes(report.priorityTitle);
      }
      if (matches && filters.isReplies?.length && filters.isReplies.includes("≥ 5 Replies") && filters.isReplies.includes("1 - 4 Replies")) {
        matches = report.messageCount > 0;
      } else if (matches && filters.isReplies?.length && filters.isReplies.includes("≥ 5 Replies")) {
        matches = report.messageCount >= 5;
      } else if (matches && filters.isReplies?.length && filters.isReplies.includes("1 - 4 Replies")) {
        matches = report.messageCount < 5 && report.messageCount > 0;
      }

      if (matches && filters.isReplies?.length && filters.isReplies.includes("No Replies")) {
        matches = report.messageCount === 1;
      }
      if (matches && filters.isStatus?.length) {
        matches = filters.isStatus.includes(report.status);
      }
      return matches;
    },
    [user, filters]
  );

  useEffect(() => {
    setCurrentPageNumber(1);
  }, [filters, tableData]);

  useEffect(() => {
    setSortKey('created');
    setSortDirection('desc');
  }, []);

  useEffect(() => {
    if (searchText.trim().length > 0) {
      setFilter(searchText.trim());
    } else {
      setFilter("");
    }
  }, [searchText]);

  useEffect(() => {
    setData(tableData.filter(filterReports));
  }, [tableData?.filter(filterReports).length]);

  let filterCount = 0;

  if (filters.datePostedFilter) {
    filterCount++;
  }

  if (filters.dateModifiedFilter) {
    filterCount++;
  }

  if (filters.priority?.length) {
    filterCount += filters.priority?.length;
  }
  if (filters.system?.length) {
    filterCount += filters.system?.length;
  }
  if (filters.isReplies?.length) {
    filterCount += filters.isReplies?.length;
  }
  if (filters.isStatus?.length) {
    filterCount += filters.isStatus?.length;
  }
  if (user?.portalAdmin && filters.isDeleted?.length) {
    filterCount += filters.isDeleted?.length;
  }

  const handleSort = (e: React.MouseEvent<HTMLDivElement>, option: any) => {
    setSortKey(option.key);
    setSortDirection(option.direction);
  };

  let tableColumns: IssueReportColumnDefinition[];
  tableColumns = [
    {
      title: "ID",
      key: "fileId",
      className: classes.document_name,
      style: { 
        width: "5%",
      },
    },
    {
      title: "Description",
      key: "displayFileName",
      style: {
        width: "auto",
      },
      component: ({ report }) => {
        if (report.displayFileName)
          return (
            <div
              onClick={() => handleFileView(report.fileId, report.globalId)}
              style={{ color: "#00749E", textDecoration: "none", cursor: "pointer" }}
            >
              {report.displayFileName} {report.isDeleted && "(Deleted)"}
            </div>
          );
        return <>N/A</>;
      },
    },
    {
      title: "Equipment Name",
      key: "equipmentName",
      className: classes.document_name,
      style: { 
        width: "11%",
      },
    },
    {
      title: "Date Posted",
      key: "created",
      component: ({ report }) => {
        if (report.created) return <Moment date={report.created} format="MM/DD/YYYY" />;
        return <>N/A</>;
      },
      style: {
        width: "11%",
      },
    },
    {
      title: "Priority",
      key: "priorityTitle",
      style: {
        width: "11%",
      },
    },
    {
      title: "System",
      key: "system",
      style: {
        width: "11%",
      },
    },
    {
      title: "Last Modified",
      key: "updated",
      component: ({ report }) => {
        if (report.updated) return <Moment date={report.created} format="MM/DD/YYYY" />;
        return <>N/A</>;
      },
      style: {
        width: "11%",
      },
    },
    {
      title: "Status",
      key: "status",
      style: {
        width: "11%",
      },
    },
    {
      title: "Discussion",
      key: "messageCount",
      style: {
        width: "11%",
      },
    },
  ];

  return (
    <>
      <div className="flex flex-col grow-1 justify-start rounded-b-lg ">
        <div className={`pb-4 lg:pb-0`}>
          <h3 className={`text-base lg:text-2xl`}>
            <strong>{selectedPlant.name} Issue Reports</strong>
          </h3>
          {isDesktop && (
            <p className={"w-full pr-8 text-sm lg:mb-0"}>
              This page contains the reports issued by the Mitsubishi Power TOMONI HUB (Remote Monitoring Center) related to the plant site(s)
              indicated. Access is also provided to the TOMONI HUB PI data summaries for those Users permissioned to view that data.
            </p>
          )}
        </div>
      </div>

      <hr />
	  
      {isLoading || isFetching || showLoading
      ? <Loader containerStyle={{ marginTop: "100px", marginBottom: "100px" }} />
      : <div className={classes.issueReportcolumn}>
          {pagePermissions.canView && (
          <div className={`${!isMobile ? classes.flex_row_space_between : ""}`}>
            <div className={` flex justify-start flex-row gap-4 lg:gap-6 md:w-3/5`}>
            {isDesktop && (
              <div className="nameplate-dropdown">
              <Dropdown
                dropdowntype=""
                dropdownStyle={{ height: 42 }}
                handleDropDownSelect={handleSort}
                selectValue={sortOptions[0]?.name}
                selectedOption={sortSelectedOption}
                setSelectedOption={(sortOptionName) => setSortSelectedOption(sortOptionName)}
                options={sortOptions}
              />
              </div>
            )}
            <SimpleSearch searchText={searchText} setSearchText={(inputText) => setSearchText(inputText)} placeholder="Search Issue Reports" />
            {!isMobile && (
              <div style={{ display: "flex", alignItems: "center", height: !isDesktop ? "48px" : "initial" }}>
              <div className={` md:whitespace-nowrap ${classes.table_icon}`}>{`${totalRecords} Results`}</div>
              </div>
            )}
            </div>
            <div style={{ position: "relative", ...(isMobile ? { marginTop: 20 } : {}) }}>
            <ButtonWithTooltip
              className="whiteBtn"
              text={
              <>
                {filterCount > 0 ? <Pill type="primary">{filterCount}</Pill> : ""} {isDesktop ? "Filter" : "Sort/Filter"}
              </>
              }
              img={<img src={filter} alt="filter" className="bottom pl-2" />}
              textClassName="filter-text"
              forceClose={closeFilters}
              toggleClickWatcher={enableClickWatcher}
              tooltipContent={<FilterTooltipContent user={user!} tableData={tableData} onFiltersApplied={setFilters} filters={filters} />}
            />
            </div>
          </div>
          )}
          {filterCount > 0 && (
          <div className={classes.filter_pill_container}>
            {filters.isReplies?.map((status) => (
            <Pill
              className={classes.filter_pill}
              key={status}
              dismissible={true}
              onDismiss={() =>
              setFilters((filters) => {
                return {
                ...filters,
                isReplies: filters.isReplies?.filter((d) => d !== status),
                };
              })
              }
              type="default"
            >
              {status}
            </Pill>
            ))}
            {filters.priority?.map((status) => (
            <Pill
              className={classes.filter_pill}
              key={status}
              dismissible={true}
              onDismiss={() =>
              setFilters((filters) => {
                return {
                ...filters,
                priority: filters.priority?.filter((d) => d !== status),
                };
              })
              }
              type="default"
            >
              {status}
            </Pill>
            ))}
            {filters.system?.map((status) => (
            <Pill
              className={classes.filter_pill}
              key={status}
              dismissible={true}
              onDismiss={() =>
              setFilters((filters) => {
                return {
                ...filters,
                system: filters.system?.filter((d) => d !== status),
                };
              })
              }
              type="default"
            >
              {status !== "" ? status : "(blank)"}
            </Pill>
            ))}
            {filters.isStatus?.map((status) => (
            <Pill
              className={classes.filter_pill}
              key={status}
              dismissible={true}
              onDismiss={() =>
              setFilters((filters) => {
                return {
                ...filters,
                isStatus: filters.isStatus?.filter((d) => d !== status),
                };
              })
              }
              type="default"
            >
              {status}
            </Pill>
            ))}
            {user?.portalAdmin &&
            filters.isDeleted?.map((status) => (
              <Pill
              className={classes.filter_pill}
              key={status}
              dismissible={true}
              onDismiss={() =>
                setFilters((filters) => {
                return {
                  ...filters,
                  isDeleted: filters.isDeleted?.filter((d) => d !== status),
                };
                })
              }
              type="default"
              >
              {status}
              </Pill>
            ))}
            {filters.equipment?.map((filteredEquipment) => (
              <Pill
                className={classes.filter_pill}
                key={filteredEquipment.plantEquipmentId}
                dismissible={true}
                onDismiss={() =>
                  setFilters((filters) => {
                    return {
                      ...filters,
                      equipment: filters.equipment?.filter((d) => d.plantEquipmentId !== filteredEquipment.plantEquipmentId),
                    };
                  })
                }
                type="default"
              >
                {filteredEquipment.equipmentName}
              </Pill>
            ))}
            {filters.datePostedFilter && (
            <Pill
              className={classes.filter_pill}
              dismissible={true}
              onDismiss={() => setFilters({ ...filters, datePostedFilter: undefined, datePostedStartDate: undefined, datePostedendDate: undefined })}
              type="default"
            >
              {DatePostedFilters.find((d) => d.value === filters.datePostedFilter)?.label}
            </Pill>
            )}
            {filters.dateModifiedFilter &&
            (filters.dateModifiedFilter !== "datemodifiedcustom" || filters.dateModifiedFilter === "datemodifiedcustom") && (
              <Pill
              className={classes.filter_pill}
              dismissible={true}
              onDismiss={() => setFilters({ ...filters, dateModifiedFilter: undefined, dateModifiedStartDate: undefined })}
              type="default"
              >
              {DateModifiedFilters.find((d) => d.value === filters.dateModifiedFilter)?.label}
              </Pill>
            )}
          </div>
          )}
          <>
            {isDesktop &&  (
            <div className="width-full overflow-auto">
              <table className={`${reportClasses.report_table} text-sm w-full`}>
                <thead>
                <tr>
                  {tableColumns.map((col) => (
                  <th key={col.title} style={col.style}>
                    {col.title}
                  </th>
                  ))}
                  <th style={{ width: "5%" }}></th>
                </tr>
                </thead>
                <tbody>
              {!pagedData.length
                      ? <tr><td colSpan={8}>No data to display.</td></tr>
                      : pagedData.map((issueReport: IssueReport, index: number) => (
                        <IssueReportItem
                          key={index}
                          unread={notifications?.filter((item) => item.itemId === issueReport.globalId && !item.read).length > 0}
                          index={index}
                          issueReport={{ ...issueReport }}
                          canDelete={!!user?.portalAdmin}
                          canOpenClose={!!user?.portalAdmin}
                          setDiscussionItem={setDiscussionItem}
                          handleFileView={handleFileView}
                        />
              ))}
                </tbody>
              </table>
            </div>
            )}
            {(isMobile || isTablet) && (
            <div>
              {pagedData && pagedData.length > 0 ? (
                    pagedData.map((issueReport: IssueReport, index: number) => (
                      <IssueReportItem
                        key={index}
                        unread={notifications?.filter((item) => item.itemId === issueReport.globalId && !item.read).length > 0}
                        index={index}
                        issueReport={{ ...issueReport }}
                        canDelete={!!user?.portalAdmin}
                        canOpenClose={!!user?.portalAdmin}
                        setDiscussionItem={setDiscussionItem}
                        handleFileView={handleFileView}
                      />
                  ))) : (
                  <div style={{ paddingTop: "8%", paddingLeft: "10%", display: "block", width: "110%" }}>No data to display</div>
                )}
            </div>
            )}
          </>
      
          {totalRecords > 0 && (
          <div className={`${reportClasses.pagination} ${isDesktop || isTablet ? reportClasses.flex_row : reportClasses.flex_column}`}>
            <div className={`${reportClasses.flex_row} ${reportClasses.pagination_left}`}>
            <span className={reportClasses.result_count}>
              {Math.min(resultsPerPage, pagedData.filter(filterReports).length)} of {totalRecords} Result{totalRecords !== 1 && "s"}
            </span>
            {resultsPerPage < totalRecords && (
              <Button
              className="whiteBtn show_all"
              text={isMobile || isTablet ? "Show All Results": "Show All"}
              onClick={() => setResultsPerPage(totalRecords)}
              style={{ order: isMobile ? "-1" : "unset" }}
              />
            )}
            </div>
            <div className={`${reportClasses.flex_row} ${reportClasses.page_numbers}`}>
            <img
              src={Arrow}
              className="pagination-left-arrow"
              style={hasPreviousPage ? {filter: "invert(31%) sepia(21%) saturate(786%) hue-rotate(150deg) brightness(94%) contrast(83%)"} : {}}
              alt="arrow"
              onClick={() => {
              if (hasPreviousPage) {
                previousPage();
              }
              }}
            />
            {pageButtonNumbers.map((pageNumber) => (
              <span
              key={pageNumber}
              className={`${reportClasses.page_number} ${pageNumber === currentPageNumber ? reportClasses.active : ""}`}
              onClick={() => {
                setCurrentPageNumber(pageNumber);
              }}
              >
              {pageNumber}
              </span>
            ))}
            <img
              src={Arrow}
              className="pagination-right-arrow"
              style={hasNextPage ? {filter: "invert(31%) sepia(21%) saturate(786%) hue-rotate(150deg) brightness(94%) contrast(83%)"} : {}}
              alt="arrow"
              onClick={() => {
              if (hasNextPage) {
                nextPage();
              }
              }}
            />
            </div>
          </div>
          )}
          <div ref={tooltipPortalRef} />
        </div>
      }
    </>
  );
};

const DatePostedFilters = [
  {
    label: "Last 24 Hours",
    value: "P1D", // ISO-8601 durations
  },
  {
    label: "Last 7 Days",
    value: "P7D",
  },
  {
    label: "Last 30 Days",
    value: "P30D",
  },
  {
    label: "Last 60 Days",
    value: "P60D",
  },
  {
    label: "Last 90 Days",
    value: "P90D",
  },
  {
    label: "Custom Range",
    value: "datepostedcustom",
  },
];
const DateModifiedFilters = [
  {
    label: "Last 24 Hours",
    value: "P1D", // ISO-8601 durations
  },
  {
    label: "Last 7 Days",
    value: "P7D",
  },
  {
    label: "Last 30 Days",
    value: "P30D",
  },
  {
    label: "Last 60 Days",
    value: "P60D",
  },
  {
    label: "Last 90 Days",
    value: "P90D",
  },
  {
    label: "Custom Range",
    value: "datemodifiedcustom",
  },
];

interface Filters {
  datePostedStartDate?: Dayjs;
  datePostedendDate?: Dayjs;
  datePostedFilter?: string;
  dateModifiedStartDate?: Dayjs;
  dateModifiedendDate?: Dayjs;
  dateModifiedFilter?: string;
  isReplies?: string[];
  isStatus?: string[];
  isDeleted?: string[];
  priority?: string[];
  system?: string[];
  unit?: number | false;
  equipment?: PlantEquipmentsDATA[];
}
interface FilterTooltipContentProps {
  onFiltersApplied: (filters: Filters) => void;
  filters: Filters;
  user: User;
  tableData: IssueReport[];
}

const FilterTooltipContent: FunctionComponent<FilterTooltipContentProps> = ({ onFiltersApplied, filters, user, tableData }) => {
  const [filtersChanged, setFiltersChanged] = useState<boolean>(false);
  const [isReplies, setIsReplies] = useState<{ value: boolean; name: string }[]>([]);
  const [uniqueArray, setUniqueArray] = useState<{ value: boolean; name: string }[]>([
    {
      value: false,
      name: "No Replies",
    },
    {
      value: false,
      name: "1 - 4 Replies",
    },
    {
      value: false,
      name: "≥ 5 Replies",
    },
  ]);

  const [isDeleted, setIsDeleted] = useState<{ value?: boolean; name: string }[]>([
    {
      value: filters?.isDeleted?.includes("Show Deleted"),
      name: "Show Deleted",
    },
  ]);

  const [datePostedStartDate, setDatePostedStartDate] = useState(dayjs(null));
  const [datePostedendDate, setDatePostedEndDate] = useState(dayjs(null));
  const [datePostedFilter, setDatePostedFilter] = useState<string>();
  const [dateModifiedStartDate, setDateModifiedStartDate] = useState(dayjs(null));
  const [dateModifiedendDate, setDateModifiedEndDate] = useState(dayjs(null));
  const [dateModifiedFilter, setDateModifiedFilter] = useState<string>();
  const [isStatus, setIsStatus] = useState<{ value?: boolean; name: string }[]>([]);
  const [systemOptions, setSystemOptions] = useState<DropDownOptionItem[]>();
  const [priorityOptions, setPriorityOptions] = useState<DropDownOptionItem[]>();
  const [selectedPriority, setSelectedPriority] = useState<number | false>(0);
  const [selectedSystem, setSelectedSystem] = useState<number | false>(0);
  const [selectedStatus, setSelectedStatus] = useState<number | false>(0);
  const [showStartCalendar, setShowStartCalendar] = useState<boolean>(false);
  const [showEndCalendar, setShowEndCalendar] = useState<boolean>(false);
  const [unit, setUnit] = useState<number | false>(false);
  const [units, setUnits] = useState<UnitDATA[]>([]);
  const [selectionText, setSelectionText] = useState<string>("");
  const [productTypes, setProductTypes] = useState<{ id: number; name: string }[]>([]);
  const [equipment, setEquipment] = useState<number[]>([]);
  const [equipmentOptions, setEquipmentOptions] = useState<PlantEquipmentsDATA[]>([]);
  const { selectedPlant, plantConfig } = useStore();

  useGetAll_UnitData(
    selectedPlant.plantId,
    (data: UnitGetAllDTO) => {
      setUnits(data.data);
    },
    () => { }
  );

  useGetAllByUnitID_PlantEquipments(
    unit || 0,
    (data: PlantEquipmentsDTO) => {
      setEquipmentOptions(data.data);
      if (filters.equipment && filters.equipment.length > 0) {
        handleEquipmentSelection(
          filters.equipment.map((d) => d.plantEquipmentId),
          filters.equipment[0].plantEquipmentId
        );
      }
    },
    () => setEquipmentOptions([])
  );

  useEffect(() => {
    setEquipment([]);
  }, [unit]);

  useEffect(() => {
    const priorities: any[] = [...new Map(tableData.map(({ priority, priorityTitle }) => [priority, { priority, priorityTitle }])).values()];
    const systems = [...new Set(tableData?.map((i) => i.system))];
    const statuses = [...new Set(tableData?.map((i) => i.status))];

    setSystemOptions([
      { id: false, name: "Select system" },
      ...systems.map((s, index) => ({ id: index + 1, name: (s || "(blank)") as string, value: s })),
    ]);
    setIsStatus(statuses.map(status => ({ name: (status || "(blank)") as string, value: !!filters.isStatus?.find(s => s === status) })));
    setPriorityOptions([{ id: false, name: "Select priority" }, ...(priorities || []).map((p) => ({ id: p.priority, name: p.priorityTitle }))]);
  }, [tableData, filters]);

  useEffect(() => {
    let tmpProductTypes: { [index: number]: string } = {};
    if (equipmentOptions) {
      tmpProductTypes = equipmentOptions?.reduce((carry: { [index: number]: string }, current: PlantEquipmentsDATA) => {
        carry[current.productType] = current.productTypeName;
        return carry;
      }, {});
    }
    const ids = Object.keys(tmpProductTypes);
    const productTypes: { id: number; name: string }[] = [];
    for (let i = 0; i < ids.length; i++) {
      let key = Number.parseInt(ids[i]);
      productTypes.push({ id: key * -1, name: `All ${tmpProductTypes[key]}` });
    }
    setProductTypes(productTypes);
  }, [equipmentOptions]);

  useEffect(() => {
    if (filters.unit) {
      setUnit(filters.unit);
    }
    if (filters.equipment && filters.equipment.length > 0) {
      handleEquipmentSelection(
        filters.equipment.map((d) => d.plantEquipmentId),
        filters.equipment[0].plantEquipmentId
      );
    }
    if (filters.datePostedFilter) {
      setDatePostedFilter(filters.datePostedFilter);
      if (filters.datePostedFilter === "datepostedcustom") {
        if (filters.datePostedStartDate) {
          setDatePostedStartDate(filters.datePostedStartDate);
        }
        if (filters.datePostedendDate) {
          setDatePostedEndDate(filters.datePostedendDate);
        }
      }
    }
    if (filters.dateModifiedFilter) {
      setDateModifiedFilter(filters.dateModifiedFilter);
      if (filters.dateModifiedFilter === "datemodifiedcustom") {
        if (filters.dateModifiedStartDate) {
          setDateModifiedStartDate(filters.dateModifiedStartDate);
        }
        if (filters.dateModifiedendDate) {
          setDateModifiedEndDate(filters.dateModifiedendDate);
        }
      }
    }

    if (filters.system && filters.system?.length > 0 && systemOptions && systemOptions.length > 0) {
      const filterString = filters?.system.pop();
      if (filterString) {
        const systemId = systemOptions
          ?.filter((s) => s.name === filterString)
          .map((a) => a.id)
          .pop();
        if (systemId) {
          setSelectedSystem(systemId);
        }
      }
    }

    if (filters.priority && filters.priority?.length > 0 && priorityOptions && priorityOptions.length > 0) {
      const filterString = filters?.priority.pop();
      if (filterString) {
        const priorityId = priorityOptions
          ?.filter((s) => s.name === filterString)
          .map((a) => a.id)
          .pop();
        if (priorityId) {
          setSelectedPriority(priorityId);
        }
      }
    }

    setIsReplies(
      uniqueArray.map((s) => {
        var fil = filters.isReplies?.filter((p) => p === s.name);
        if (fil?.length && fil?.length > 0) s.value = true;
        return s;
      })
    );

    setIsDeleted(
      isDeleted.map((s) => {
        var fil = filters.isDeleted?.filter((p) => p === s.name);
        if (fil?.length && fil?.length > 0) s.value = true;
        return s;
      })
    );
  }, [filters, systemOptions]);

  const applyFilters = () => {
    setFiltersChanged(false);
    const selectedEquipment = equipment.filter((d) => d > 0);

    const filters: Filters = {};
    if (unit) {
      filters.unit = unit;
    }
    if (selectedEquipment.length > 0) {
      filters.equipment = equipmentOptions.filter((d) => selectedEquipment.includes(d.plantEquipmentId));
      // filters.equipment = selectedEquipment;
    }
    if (selectedPriority) {
      filters.priority = priorityOptions?.filter((a) => a.id === selectedPriority).map((a) => a.name);
    }

    if (selectedSystem) {
      filters.system = systemOptions?.filter((a) => a.id === selectedSystem).map((a) => a.value || "");
    }


    if (datePostedFilter) {
      if (datePostedFilter === "datepostedcustom" && datePostedStartDate && datePostedendDate) {
        filters.datePostedStartDate = datePostedStartDate;
        filters.datePostedendDate = datePostedendDate;
      } else {
        filters.datePostedStartDate = FilterDateComparisons(datePostedFilter);
      }
      filters.datePostedFilter = datePostedFilter;
    }
    if (dateModifiedFilter) {
      if (dateModifiedFilter === "datemodifiedcustom" && dateModifiedStartDate && dateModifiedendDate) {
        filters.dateModifiedStartDate = dateModifiedStartDate;
        filters.dateModifiedendDate = dateModifiedendDate;
      } else {
        filters.dateModifiedStartDate = FilterDateComparisons(dateModifiedFilter);
      }
      filters.dateModifiedFilter = dateModifiedFilter;
    }

    if (isReplies?.find((s) => s.value === true)) {
      filters.isReplies = isReplies.filter((s) => s.value).map((s) => s.name);
    }

    if (isStatus?.find((s) => s.value === true)) {
      filters.isStatus = isStatus.filter((s) => s.value).map((s) => s.name);
    }

    if (user.portalAdmin) {
      if (isDeleted?.find((s) => s.value === true)) {
        filters.isDeleted = isDeleted.filter((s) => s.value).map((s) => s.name);
      } else {
        filters.isDeleted = undefined;
      }
    }
    
    onFiltersApplied(filters);
  };

  const canApplyFilters =
    (dateModifiedFilter && !(dateModifiedFilter === "datemodifiedcustom" && (!dateModifiedStartDate || !dateModifiedendDate))) ||
    (datePostedFilter && !(datePostedFilter === "datepostedcustom" && (!datePostedStartDate || !datePostedendDate))) ||
    filtersChanged ||
    isStatus.find((s) => s.value !== undefined) ||
    isReplies.find((s) => s.value === true) ||
    (selectedPriority && selectedPriority > 0) ||
    (selectedSystem && selectedSystem > 0) ||

    isDeleted.find((s) => s.value !== undefined);

  const selectOptionE = (selectOption: string) => {
    const newState = isReplies.map((obj) => {
      if (obj.name === selectOption) {
        return { ...obj, value: !obj.value };
      }
      return obj;
    });

    setIsReplies(newState);
  };
  const selectOptionS = (selectOption: string) => {
    const newState = isStatus.map((obj) => {
      if (obj.name === selectOption) {
        return { ...obj, value: !obj.value };
      }
      return obj;
    });

    setIsStatus(newState);
  };

  const selectOptionD = (selectOption: string) => {
    const newState = isDeleted.map((obj) => {
      if (obj.name === selectOption) {
        return { ...obj, value: !obj.value };
      }
      return obj;
    });

    setIsDeleted(newState);
  };
  const unitOptions: DropDownOptionItem[] = (units || []).map((unit) => ({ id: unit.unitId, name: unit.name }));

  const selectedUnit = useMemo(() => {
    return unitOptions.map((d) => d.id).includes(unit) ? unit : false;
  }, [unit, unitOptions]);

  const equipmentOptionItems: { id: number; name: string }[] = [{ id: 0, name: "All Equipment" }, ...productTypes];

  if (equipmentOptions) {
    for (let i = 0; i < equipmentOptions.length; i++) {
      equipmentOptionItems.push({
        id: equipmentOptions[i].plantEquipmentId,
        name: equipmentOptions[i].equipmentName,
      });
    }
  }

  const getEquipmentLabel = (values: number[]) => {
    if (!!(values.length > 1)) {
      const eqCountByType = equipmentOptions?.filter(eq => eq.productType === (values.filter(v => v < 0)[0] * -1)).length || 0;
      const valuesIncludeEqFromOutsideGroup = values.length !== eqCountByType + 1;
      if (valuesIncludeEqFromOutsideGroup)
        setSelectionText('Multiple equipment options selected');
      else if (!valuesIncludeEqFromOutsideGroup)
        setSelectionText(equipmentOptionItems.filter(type => values.includes(type.id))[0].name);
    }
    // if values includes 'All Equipment'
    if (values.includes(0)) setSelectionText(equipmentOptionItems[0].name);
    // if only 1 option selected
    if (values.length === 1) setSelectionText(equipmentOptionItems.filter(el => el.id === values[0])[0]?.name)
  }

  const handleEquipmentSelection = (values: number[], toggled: number) => {
    setFiltersChanged(true);
    let tmpValues = [...values];
    let tmpSiteEquipment = equipmentOptions || [];

    /**
     * Remove all grouped options from tmpValues
     * if toggled = 0 and orig values includes 0, select all
     * if toggled = 0 and orig values does not include 0, un select all
     *
    */

    if (toggled === 0) {
      if (values.includes(0)) {
        tmpValues = tmpSiteEquipment.map((d) => d.plantEquipmentId);
      } else {
        tmpValues = [];
      }
    }

    if (toggled < 0) {
      let productTypeId = toggled * -1;
      // a group was selected, check everything in that group
      if (values.includes(toggled)) {
        for (let i = 0; i < tmpSiteEquipment.length; i++) {
          if (tmpSiteEquipment[i].productType === productTypeId && !tmpValues.includes(tmpSiteEquipment[i].productType)) {
            tmpValues.push(tmpSiteEquipment[i].plantEquipmentId);
          }
        }
      } else {
        // a group was deselected, un check everything in that group
        let productEquipment = tmpSiteEquipment.filter(eq => eq.productType === productTypeId).map(eq => eq.plantEquipmentId);
        tmpValues = tmpValues.filter(v => !productEquipment.includes(v));
      }
    }

    for (let i = 0; i < productTypes.length; i++) {
      let allOptionsOfTypeSelected = true;
      let productTypeId2 = +productTypes[i].id * -1;
      for (let j = 0; j < tmpSiteEquipment.length; j++) {
        if (tmpSiteEquipment[j].productType === productTypeId2) {
          allOptionsOfTypeSelected = allOptionsOfTypeSelected && tmpValues.includes(tmpSiteEquipment[j].plantEquipmentId);
        }
      }
      if (allOptionsOfTypeSelected) {
        tmpValues.push(productTypes[i].id);
      } else {
        tmpValues = tmpValues.filter(val => (val * -1) !== productTypeId2)
      }
    }

    // if everything is selected, check select all
    if (tmpValues.length > 0 && tmpValues.filter((d) => d > 0).length === tmpSiteEquipment.length) {
      tmpValues.push(0);
      for (let i = 0; i < productTypes.length; i++) {
        tmpValues.push(productTypes[i].id);
      }
    } else tmpValues = tmpValues.filter((val) => val !== 0);

    tmpValues = tmpValues.filter((v, i, a) => a.indexOf(v) === i);

    setEquipment(tmpValues);

    getEquipmentLabel(tmpValues);
  };

  return (
    <div className={`${classes.flex_column} ${classes.filter_tooltip_content}`}>
      {user?.portalAdmin && (
        <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
          <label>Show Deleted:</label>
          <div className={classes.checkboxWrapper}>
            {isDeleted.map((status, index) => (
              <div className={classes.checkboxRow} key={index} onClick={() => selectOptionE(status.name)}>
                <ToggleSwitch isChecked={status.value || false} label={status.name} onChange={() => selectOptionD(status.name)} />
              </div>
            ))}
          </div>
        </div>
      )}

      <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
        <label>Priority:</label>
        {priorityOptions && priorityOptions?.length > 0 && (
          <PlainDropDown
            className={classes.select_container}
            selectClass={`${classes.select_class} ${!selectedPriority ? classes.empty_value : ""}`}
            optionsClass={classes.select_options_class}
            onSelection={(option) => {
              setSelectedPriority(option.id);
            }}
            value={priorityOptions?.map((d) => d.id).includes(selectedPriority) ? selectedPriority : false}
            options={priorityOptions}
          />
        )}
      </div>

      <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
        <label>Unit Association:</label>
        <PlainDropDown
          className="relative"
          defaultText="Select a unit"
          selectClass={`flex flex-row items-center border border-solid border-[#999] pt-1 pr-2 !pb-1 pl-4 text-sm justify-between rounded ${!selectedUnit && 'text-[#999]'}`}
          optionsClass="flex flex-col p-1 bg-white rounded max-h-80 w-full"
          itemClass="py-[14px] px-6 cursor-pointer hover:bg-primary-20"
          onSelection={(unit) => setUnit(unit.id)}
          value={selectedUnit}
          options={unitOptions}
        />
      </div>
      <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
        <label>Equipment Association:</label>
        <MultiSelectDropDown
          className="relative"
          selectClass={`flex flex-row items-center pt-1 pr-2 !pb-1 pl-4 text-sm justify-between rounded ${equipment.length === 0 && 'text-[#999]'} ${selectedUnit && equipmentOptions.length > 0? 'border border-[#999] border-solid' : 'bg-[#F2F2F2]'}`}
          optionsClass="flex flex-col p-1 bg-white rounded max-h-80 w-full"
          itemClass="py-[14px] px-6 cursor-pointer hover:bg-primary-20"
          onSelection={handleEquipmentSelection}
          value={equipment}
          disabled={!unit}
          disabledClass={classes.select_disabled}
          noSelectionText="Select equipment"
          hasSelectionText={selectionText}
          options={unit ? equipmentOptionItems : []}
        />
      </div>
      <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
        <label>Status:</label>
        <div className={classes.checkboxWrapper}>
          {isStatus.map((status, index) => (
            <div className={classes.checkboxRow} key={index} onClick={() => selectOptionS(status.name)}>
              <Checkbox isChecked={status.value || false} label={status.name} onChange={() => { }} />
            </div>
          ))}
        </div>
      </div>
      <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
        <label>Number of Replies:</label>
        <div className={classes.checkboxWrapper}>

          {isReplies.map((status, index) => (
            <div className={classes.checkboxRow} key={index} onClick={() => selectOptionE(status.name)}>
              <Checkbox isChecked={status.value} label={status.name} onChange={() => { }} />
            </div>
          ))}
        </div>
      </div>
      <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
        <label>System:</label>
        {systemOptions && systemOptions?.length > 0 && (
          <PlainDropDown
            className={classes.select_container}
            selectClass={`${classes.select_class} ${!selectedSystem ? classes.empty_value : ""}`}
            optionsClass={classes.select_options_class}
            onSelection={(option) => {
              setSelectedSystem(option.id);
            }}
            value={systemOptions?.map((d) => d.id).includes(selectedSystem) ? selectedSystem : false}
            options={systemOptions}
          />
        )}
      </div>

      <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
        <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
          <label>Date Published:</label>
          <RadioButtonGroup onChange={setDatePostedFilter} name="date_posted" options={DatePostedFilters} value={datePostedFilter} />
        </div>
        {datePostedFilter === "datepostedcustom" && (
          <CustomDateRangePicker
            startDate={datePostedStartDate}
            endDate={datePostedendDate}
            showEndCalendar={showEndCalendar}
            showStartCalendar={showStartCalendar}
            startName="datePostedStartDate"
            endName="datePostedendDate"
            startDateChange={setDatePostedStartDate}
            endDateChange={setDatePostedEndDate}
            setToggleEndCalendarDisplay={setShowEndCalendar}
            setToggleStartCalendarDisplay={setShowStartCalendar}
          />
        )}
      </div>
      <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
        <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
          <label>Last Modified:</label>
          <RadioButtonGroup onChange={setDateModifiedFilter} name="date_modified" options={DateModifiedFilters} value={dateModifiedFilter} />
        </div>
        {dateModifiedFilter === "datemodifiedcustom" && (
          <CustomDateRangePicker
            startDate={dateModifiedStartDate}
            endDate={dateModifiedendDate}
            showEndCalendar={showEndCalendar}
            showStartCalendar={showStartCalendar}
            startName="dateModifiedStartDate"
            endName="dateModifiedendDate"
            startDateChange={setDateModifiedStartDate}
            endDateChange={setDateModifiedEndDate}
            setToggleEndCalendarDisplay={setShowEndCalendar}
            setToggleStartCalendarDisplay={setShowStartCalendar}
          />
        )}
      </div>

      <div className={`${classes.filter_tooltip_field}`}>
        <Button text="Apply" className="darkBlue" onClick={applyFilters} disabled={!canApplyFilters} />
      </div>
    </div>
  );
};
export interface IssueReportColumnDefinition {
  title: string;
  key: keyof IssueReport;
  style: CSSProperties;
  component?: FunctionComponent<{ report: IssueReport; tooltip?: ReactElement<any, any> }>;
  className?: string;
}

export default IssueReportList;
