import React, { useContext } from "react";
import { useQueryClient } from "react-query";
import { Store } from "../../Store";
import { useDelete_Attachment } from "../../containers/PartsAndService/QuoteRequestHttpServices";
import { useOnError } from "../../customHooks/useOnError";
import { queryKeys } from "../../react-query/queryKeys";
import { CloseAllModal } from "../../utils/CloseAllModal";
import Loader from "../Loader/Loader";
import ModalGlobal from "../UI/Modal/ModalGlobal";

function DeleteModal({ id, modaltitle, modaltitlename }: FormIdObj) {
  const { isModal, setIsModal } = useContext(Store);
  const queryClient = useQueryClient();
  const setErrorData = useOnError();

  const { mutate: deleteAttachment, isLoading: isDeleting, isIdle } = useDelete_Attachment();

  const handleDelete = (id:number) => {
    deleteAttachment(id, {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.getQuoteRequest]);
        setIsModal &&
            setIsModal({
              ...CloseAllModal,
              isDeleteSuccess: { ...isModal.isDeleteSuccess, isQuoteDocument: true },
            })
      },
      onError: (error: unknown) => {
        setErrorData(error);
      },
    });
  };
// console.log(id);

  return (
    <ModalGlobal modaltitle={modaltitle} modaltitlename={modaltitlename} isModalHeader={true}>
      {isDeleting ? (
        <Loader />
      ) : (
        <>
          <div className="flex align-center marginBot30">
            <div className="flex-grow-1">
              Are you sure you want to delete the selected Document ? 
            </div>
          </div>
        </>
      )}
      <div className="flex align-center">
        <div className="flex-grow-1 flex">
          <button type={"button"} className="primaryBtn redDelete " onClick={() => handleDelete(id)} >
            Yes, Delete
          </button>
          <button
            type={"button"}
            className="primaryBtn whiteBtn marginLeft16"
            onClick={() => {
              setIsModal!({
                ...CloseAllModal,
              });
            }}
          >
            Cancel
          </button>
        </div>
        <span>*Required fields</span>
      </div>
    </ModalGlobal>
  );
}

interface FormIdObj {
  modaltitle: string;
  modaltitlename: string;
  id: number;
}
export default DeleteModal;
