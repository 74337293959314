import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import Alert from "../../components/Alert/Alert";
import Button from "../../components/Button/Button";
import { DropDownOptionItem, PlainDropDown } from "../../components/UI/Form/DropDownSelect/PlainDropDown";
import { Toggle } from "../../components/UI/Form/Toggle/Toggle";
import { useGetAllBaseOrgGroups, useGetAllPlantsConfig } from "../../customHooks/PlantHttpServices";
import { useGetRoles, useGetUser, useGetUserRoleAssignments, useDelete_User, useReactivate_User, updateUserOrganizationRoles, removeUserAssignment, useUpdateUser } from "../../customHooks/UserHttpServices";
import ImageImports from "../../utils/ImageImports";
import { UserRole, User, UserRoleAssignments } from "../../utils/interface";
import { queryKeys } from "../../react-query/queryKeys";
import { useQueryClient } from "react-query";
import { useOnError } from "../../customHooks/useOnError";
import { ConfirmUserStatusChangeModal } from './ConfirmUserStatusChangeModal';
import { ConfirmRemoveUserAssignmentModal } from './ConfirmRemoveUserAssignmentModal';
import { Store } from "../../Store";
import MiniTooltip from "../../components/UI/MiniTooltip/MiniTooltip";

const { Arrow, help } = ImageImports;

interface ManageUserProps {
    userId: number
}

export const ManageUser = ({userId}: ManageUserProps) => {
    const [showConfirmUserStatusChangeModal, setShowConfirmUserStatusChangeModal] = useState<boolean>(false);
    const [showConfirmRemoveUserAssignmentModal, setShowConfirmRemoveUserAssignmentModal] = useState<boolean>(false);
    const queryClient = useQueryClient();
    const setErrorData = useOnError();
    const {header: {data: {user: currentUser}}} = useContext(Store);

    const nUserId: number = useMemo(() => +(userId || 0), [userId]);
    const [assignments, setAssignments] = useState<UserRole[]>([]);
    const [assignmentToRemove, setAssignmentToRemove] = useState<UserRole|null>(null);
    const [assignmentInddexToRemove, setAssignmentIndexToRemove] = useState<number|null>(null);
    const [roleAssignments, setRoleAssignments] = useState<UserRoleAssignments>();
    const [isPortalAdmin, setIsPortalAdmin] = useState<boolean>(false);
    const [ adminToggleChanged, setAdminToggleChanged ] = useState<boolean>(false);

    const {isFetching, refetch, data: user} = useGetUser(nUserId);
    const {data: userRoleAssignments, isFetched: fetchedRoleAssignments, refetch: refreshUserRoleAssignments} = useGetUserRoleAssignments(nUserId, {
        onSuccess: (data) => {
            setAssignments(data.roles);
            setRoleAssignments(roleAssignments);
        }
    });

    const { data: roles } = useGetRoles();
    const { data: plants } = useGetAllPlantsConfig();
    const { data: baseOrgGroups } = useGetAllBaseOrgGroups();
    const { mutate: DeleteUser, isSuccess: deactivateUserSuccess, reset: resetDeactivateMutation } = useDelete_User();
    const { mutate: ReactivateUser, isSuccess: reactivateUserSuccess, reset: resetReactivateMutation } = useReactivate_User();
    const { mutate: UpdateUser, isLoading: isUpdatingUser, reset: resetUpdateUserMutation } = useUpdateUser();
    const { mutate: addUpdateUserOrganizationRoles, isSuccess: updatedRoleAssignments, reset: resetUpdateMutation, isError: updateAssignmentError } = useMutation(updateUserOrganizationRoles, {
        onSuccess: () => {
            refreshUserRoleAssignments();
            resetRemoveUserAssignmentMutation();
        }
    });

    const {mutate: DeleteUserAssignment, isSuccess: roleAssignmentRemoved, reset: resetRemoveUserAssignmentMutation} = useMutation(removeUserAssignment, {
        onSuccess: () => {
            setAssignmentToRemove(null);
            setAssignmentIndexToRemove(null);
            //refreshUserRoleAssignments();
            setShowConfirmRemoveUserAssignmentModal(false);
            resetUpdateMutation();
        }
    });

    const stashRoleChange = useCallback((i: number, userRole: UserRole) => {
        let newAssignments = [...assignments]; 
        newAssignments.splice(i, 1, userRole);
        setAssignments(newAssignments);
    }, [assignments]);

    const handleUpdateUser = (updateUser: User) => {
        UpdateUser(updateUser, {
          onSuccess: () => {
            refetch();
            resetUpdateUserMutation();
            resetRemoveUserAssignmentMutation();
          },
          onError: (error:unknown) => {
            setErrorData(error);
          }
        });
    }

    const commitRoleChanges = useCallback(() => {
        addUpdateUserOrganizationRoles({userId: user?.id || 0, roles: assignments});
        if (adminToggleChanged === true && user) {
            const updateUser: User = {
                ...user,
                portalAdmin: isPortalAdmin
            }
            handleUpdateUser(updateUser);
        }
    }, [assignments, addUpdateUserOrganizationRoles, adminToggleChanged, handleUpdateUser, isPortalAdmin, user]);

    const addNewRoleAssignment = useCallback(() => {
        setAssignments(assignments => {
            return [...assignments, {
                baseOrgId: null,
                orgGroupId: null,
                roleId: 0,
                any: false,
                baseOrgName: '',
                orgGroupName: '',
                roleName: '',
                userId: nUserId,
                id: 0
            }];
        })
    }, [nUserId]);

    const orgOptions = useMemo(() => {
        const options: {id: number, name: string}[] = [];
        if (plants) {
            options.push(...plants.map(plant => ({
                id: plant.baseOrgId,
                name: plant.baseOrgName
            })));
        }
        if (baseOrgGroups) {
            options.push(...baseOrgGroups.map(group => ({
                id: group.id * -1,
                name: group.name
            })))
        }
        return options;
    }, [plants, baseOrgGroups]);

    const roleOptions = useMemo(() => {
        const options: {id: number, name: string}[] = [];
        if (roles) {
            options.push(...roles.filter(role => role.mpInternal === user?.mpInternal).map(role => ({
                id: role.id,
                name: role.name
            })));
        }
        return options;
    }, [roles, user]);

    const handleDeactivateUser = (user: User) => {
        DeleteUser(user.id, {
            onError: (error: unknown) => {
                setErrorData(error);
            },
            onSuccess: (responseData) => {
                queryClient.invalidateQueries([queryKeys.user]);
                refetch();
                setShowConfirmUserStatusChangeModal(false);
                resetUpdateUserMutation();
                resetRemoveUserAssignmentMutation();
            },
        });
    };

    const handleReactivateUser = (user: User) => {
        ReactivateUser(user.id, {
            onError: (error: unknown) => {
                setErrorData(error);
            },
            onSuccess: (responseData) => {
                queryClient.invalidateQueries([queryKeys.user]);
                refetch();
                setShowConfirmUserStatusChangeModal(false);
                resetUpdateUserMutation();
                resetRemoveUserAssignmentMutation();
            },
        });
    };

    const handleRemoveUserAssignment = useCallback(() => {
        if (assignmentInddexToRemove !== null) {
            setAssignments(assignments => {
                assignments.splice(assignmentInddexToRemove, 1);
                return [...assignments];
            });
        }

        if (assignmentToRemove?.id && assignmentToRemove?.id > 0) {
            DeleteUserAssignment({baseOrgRoleId: assignmentToRemove?.id || 0})
        } else {
            setShowConfirmRemoveUserAssignmentModal(false);
        }
    }, [assignmentToRemove, assignmentInddexToRemove, adminToggleChanged, DeleteUserAssignment]);

    const canAddAnotherAssignment = useMemo(() => {
        return assignments.filter(a => {
            return !(a.roleId > 0 && (a.baseOrgId || a.orgGroupId));
        }).length === 0;
    }, [assignments]);


    const canSaveAssignments = (canAddAnotherAssignment && (JSON.stringify(assignments) !== JSON.stringify(userRoleAssignments?.roles))) || (isPortalAdmin !== (user?.portalAdmin || false));

    const hasConflictingRoles = useMemo(() => Object.values(assignments.reduce((carry: {[index: number]: number}, current) => {
        const key = ((current.orgGroupId || 0) * -1) || current.baseOrgId || 0;
        if (!carry.hasOwnProperty(key)) {
            carry[key] = 0;
        }
        carry[key]++;
        return carry;
    }, {})).filter(d => d > 1).length > 0, [assignments]);

    useEffect(() => {
        setIsPortalAdmin(user?.portalAdmin === true);
    }, [user]);

    useEffect(() => {
        if(userRoleAssignments){
            setAssignments(userRoleAssignments?.roles);
        }
    }, [userRoleAssignments]);
    
    const handleToggleAdmin = (isChecked: boolean) => {
        setIsPortalAdmin(isChecked);
        setAdminToggleChanged(isChecked !== (user?.portalAdmin || false));
    };

    const resetMutations = () => {
        resetUpdateUserMutation();
        resetRemoveUserAssignmentMutation();
        resetDeactivateMutation();
        resetReactivateMutation();
        resetUpdateMutation();
    };

    return (
        <div className={`card flex flex-col gap-[10px]`}>
            {showConfirmUserStatusChangeModal && userRoleAssignments && user &&
                <ConfirmUserStatusChangeModal
                    shown={showConfirmUserStatusChangeModal} 
                    user={userRoleAssignments}
                    onConfirmDeactivate={() => handleDeactivateUser(user)} 
                    onConfirmReactivate={() => handleReactivateUser(user)} 
                    onClose={() => setShowConfirmUserStatusChangeModal(false)} 
                    actionType= {`${user.accountDisabled ? 'reactivate' : 'deactivate'}`}
                />
            }
            {showConfirmRemoveUserAssignmentModal && userRoleAssignments && assignmentToRemove && 
                <ConfirmRemoveUserAssignmentModal 
                    shown={showConfirmRemoveUserAssignmentModal}
                    user={userRoleAssignments} 
                    assignment={assignmentToRemove}
                    onConfirm={handleRemoveUserAssignment} 
                    onClose={() => setShowConfirmRemoveUserAssignmentModal(false)} 
                />
            }
            {user && <div className="gap-8 flex flex-col items-start">
                <div className="flex flex-col items-start gap-8 w-full">
                    <Link to={`/administration/user-management/${user.mpInternal === true ? 'internal' : 'external'}`} className="no-underline dark-blue">
                        <div className="flex flex-row gap-[10px] items-center">
                            <img src={Arrow} title="Back to Customer Portal Users" className="rotate-180 h-6 w-6" style={{filter: 'var(--svgFilterDarkBlueButton)'}} />
                            <span className="font-bold text-sm">Back to Customer Portal Users</span>
                        </div>
                    </Link>
                    {user.accountDisabled === true &&
                        <div className="flex flex-col items-start gap-8 w-full">
                            <Alert dismissible={false} type="info" onClose={() => {}}>
                                This user is currently deactivated.
                            </Alert>
                        </div>
                    }
                    {(updatedRoleAssignments || roleAssignmentRemoved || deactivateUserSuccess || reactivateUserSuccess) && <Alert onClose={resetMutations} type="success">Changes saved.</Alert>}
                    <div className="flex flex-row items-start gap-6 self-stretch">
                        <div className="flex flex-col items-start gap-2 grow text-2xl font-bold">
                            {user.fName} {user.lName}
                        </div>
                    </div>
                    <hr className="m-0 text-[#B3B3B3]" />
                </div>
                <div className="flex flex-col items-stretch gap-8 w-full">
                    {/* User Details */}
                    <div className="flex flex-col items-start gap-8">
                        <div className="flex flex-col items-start gap-2">
                            <span className="font-bold text-xl">User Details</span>
                            <span className="text-base">Data originally fed from Active Directory. To change user details, please contact Mitsubishi Power IT.</span>
                        </div>
                        <div className="flex flex-col gap-3">
                            <div className="flex flex-row items-start gap-6">
                                <span className="w-[120px]">First Name:</span>
                                <span>{user.fName}</span>
                            </div>
                            <div className="flex flex-row items-start gap-6">
                                <span className="w-[120px]">Last Name:</span>
                                <span>{user.lName}</span>
                            </div>
                            <div className="flex flex-row items-start gap-6">
                                <span className="w-[120px]">Email Address:</span>
                                <span>{user.email}</span>
                            </div>
                            <div className="flex flex-row items-start gap-6">
                                <span className="w-[120px]">User ID:</span>
                                <span>{user.id}</span>
                            </div>
                        </div>
                    </div>
                    {/* Role Assignments */}
                    <div className="flex flex-col items-start gap-8"> {/* Role_Assignments */}
                        <div className="flex flex-col items-start gap-6 self-stretch"> {/* Frame 6092 */}
                            <div className="flex flex-col items-start gap-6 self-stretch"> {/* Frame 6327 */}
                                <div className="flex flex-row items-center gap-4 self-stretch"> {/* Frame 6382 */}
                                    <Toggle checked={isPortalAdmin} onChange={(e) => handleToggleAdmin(e.currentTarget.checked)} />
                                    <div className="flex flex-row !pb-0 gap-2 text-base items-center"> {/* Frame 6384 */}
                                        Enable Customer Portal Administrator permissions for this user
                                        <span className="cursor-pointer">
                                            <MiniTooltip text={"If enabled, this user will be granted the highest administration permissions, and will be able to access all pages, modules and data in this portal."} space={15} placement={"top"}>
                                                <img src={help} style={{ maxWidth: "16px", display: "block", marginLeft: "6px" }} alt="Help" />
                                            </MiniTooltip>
                                        </span>
                                    </div>
                                </div>
                                <div className="flex flex-col items-start gap-2"> {/* Frame 6354 */}
                                    <span className="text-xl font-bold">Role Assignment(s)</span>
                                    
                                    <span className="">Role definitions can be managed in the <Link to="/administration/Role-Configuration" className="no-underline text-[#00749E] hover:text-[#00749E] visited:text-[#00749E] hover:underline">Role Configuration</Link> page.</span>
                                </div>
                                {hasConflictingRoles && <Alert onClose={() => {}} type="warning" dismissible={false}>Some role assignments below have conflicting access permissions. Please note the user will be granted access according to the highest permission(s) allowed between the two or more roles.</Alert>}
                                {updateAssignmentError && <Alert onClose={() => {}} type="error" dismissible={false}>Changes cannot be saved. One or more role selections below are identical assignments. Please resolve and try saving changes again.</Alert>}
                                <div className="flex flex-col items-start self-stretch gap-6"> {/* Frame 6326 */}
                                    {/* This part will be in a loop */}
                                    {fetchedRoleAssignments && assignments.map((assignment, i) => (
                                         <RoleAssignmentItem key={i} 
                                            orgOptions={orgOptions} 
                                            roleOptions={roleOptions} 
                                            assignment={assignment} 
                                            onRemove={() => {
                                                setAssignmentToRemove(assignment);
                                                setAssignmentIndexToRemove(i);
                                                setShowConfirmRemoveUserAssignmentModal(true);
                                            }} 
                                            onAssignmentChange={userRole => stashRoleChange(i, userRole)} /> 
                                    ))}
                                    {/* End Loop Item */}
                                </div>
                            </div>
                            <Button className="whiteBtn" text="Add Another Assignment" disabled={!canAddAnotherAssignment} onClick={addNewRoleAssignment} />
                        </div>
                        <hr className="m-0 text-[#B3B3B3]" />
                    </div>
                    <div className="flex flex-row items-start gap-4"> {/* Actions */}
                        <Button className="darkBlue" text="Save Changes" disabled={!canSaveAssignments} onClick={commitRoleChanges} />
                        <Button 
                            className={`${user.accountDisabled  === true ? 'darkBlue' : 'redDelete'}`} 
							style={{order: user.accountDisabled === true ? -1 : "unset"}}
                            text={`${user.accountDisabled === true ? 'Reactivate' : 'Deactivate'} User`} 
                            onClick={() => setShowConfirmUserStatusChangeModal(true)} 
                        />
                    </div>
                </div>
            </div>}
        </div>
    );
};

interface RoleAssignmentItemInterface {
    orgOptions: {id: number, name: string}[];
    roleOptions: {id: number, name: string}[];
    assignment: UserRole;
    onRemove: (onRemove: boolean) => void;
    onAssignmentChange: (assignment: UserRole) => void;
}
const RoleAssignmentItem = ({orgOptions, roleOptions, assignment, onRemove, onAssignmentChange}: RoleAssignmentItemInterface) => {
    const handleOrgChange = (val: DropDownOptionItem) => {
        if (val.id === false) {
            onAssignmentChange({...assignment, orgGroupId: null, baseOrgId: null});
        } else {
            if (val.id < 0) {
                onAssignmentChange({...assignment, orgGroupId: val.id * -1, baseOrgId: null, orgGroupName: val.name});
            } else {
                onAssignmentChange({...assignment, orgGroupId: null, baseOrgId: val.id, baseOrgName: val.name});
            }
        }
    };

    return ( 
        <div className="flex flex-row items-end gap-6 self-stretch">
            <div className="flex flex-row items-start gap-6 basis-[648px]">
                <div className="flex flex-col items-stretch gap-2 basis-1/2">
                    <span className="">Organization/Plant*</span>
                    <PlainDropDown selectClass="flex flex-row items-center border border-[#999] pt-1 pr-2 !pb-1 pl-4 text-sm justify-between rounded w-full cursor-pointer"
                                optionsClass="flex flex-col p-1 bg-white rounded max-h-80"
                                dropdownStyles={{overflow: 'auto'}}
                                itemClass="py-[14px] px-6 cursor-pointer hover:bg-primary-20"
                                options={orgOptions.sort((a, b) => a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1)}
                                searchable={true}
                                onSelection={handleOrgChange}
                                value={assignment.orgGroupId? (assignment.orgGroupId * -1) : assignment.baseOrgId ?? false}
                                defaultText="Select an organization or plant" />
                </div>
                <div className="flex flex-col items-stretch gap-2 basis-1/2">
                    <span className="">Role Assignment*</span>
                    <PlainDropDown selectClass="flex flex-row items-center border border-[#999] pt-1 pr-2 !pb-1 pl-4 text-sm justify-between rounded w-full cursor-pointer"
                                optionsClass="flex flex-col p-1 bg-white rounded max-h-80"
                                dropdownStyles={{overflow: 'auto'}}
                                itemClass="py-[14px] px-6 cursor-pointer hover:bg-primary-20"
                                options={roleOptions.sort((a, b) => a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1)}
                                onSelection={val => onAssignmentChange({...assignment, roleId: val.id? val.id: 0, roleName: val.name})}
                                value={assignment.roleId}
                                defaultText="Select a role assignment" />
                </div>
            </div>
            <div className="flex flex-row items-center gap-6">
                <Button 
                    className="text-[#E31F26] bg-transparent font-bold px-3 py-1 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
                    text="Remove Assignment" 
                    onClick={() => onRemove(true)} 
                />
            </div>
        </div>
    );
};