import dayjs, { Dayjs } from "dayjs";
import { useField, useFormikContext } from "formik";
import { forwardRef, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import useWindowSize from "../../../../customHooks/useWindowSize";
import ImageImports from "../../../../utils/ImageImports";
import Button from "../../../Button/Button";
import MiniTooltip from "../../MiniTooltip/MiniTooltip";
import classes from "./DatePickerField.module.css";

const { tooltip, calendar, closeImg } = ImageImports;
const DatePickerFields = ({ containerClassName = classes.input, ...props }: DatePickerFieldInterface) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const inputref = useRef(null);

  return (
    <div className={`${classes.form_field_row} mb-0`}>
      <label htmlFor={props.name} className={classes.input_label}>
        {props.labelName}
        {props.tooltip?.text.length && (
          <div className={classes.cursor_pointer}>
            <MiniTooltip text={props.tooltip.text} space={props.tooltip.space} placement={props.tooltip.placement}>
              <img src={tooltip} className={classes.tooltip_icon} alt="tool tip" />
            </MiniTooltip>
          </div>
        )}
      </label>
      <div className={`${classes.form_group}`}>
        <div className={meta.touched && meta.error ? `${containerClassName} ${classes.errorField} ${classes.error_text}` : `${containerClassName}`}>
          <DatePicker
            placeholderText={props.placeholder}
            selected={(field.value && new Date(field.value)) || null}
            showYearPicker={props.showYearPicker}
            dateFormat={props.dateFormat}
            disabled={props.disabled}
            onChange={(val) => {
              if (val) {
                setFieldValue(field.name, val);
              }
            }}
            customInput={
              <DateInput
                handleClear={() => {
                  setFieldValue(field.name, "");
                }}
                inputref={inputref}
              />
            }
          />
        </div>

        {props.details && (
          <small className={classes.small} style={{ marginTop: "8px" }}>
            {props.details}
          </small>
        )}
      </div>
    </div>
  );
};

const DateInput = forwardRef(({handleClear, ...props}: any, ref: any) => {
  return(
  <div className="flex justify-between items-center relative">
    <input
      disabled={props.disabled}
      {...props}
      ref={ref}      
    />
    {!props.value && <div className="absolute right-1  h-[24px] w-[40px] opacity-100" style={
        props.disabled
          ? { background: `#F2F2F2 url(${calendar}) no-repeat right  center ` }
          : { background: `url(${calendar}) no-repeat right  center` }
      }  />}
    {!!props.value && <img className="absolute right-1 cursor-pointer opacity-50 hover:opacity-100 " onClick={handleClear ?? undefined} src={closeImg} alt="" />}
  </div>
)});

interface IDateInputWithCalendarToggleProps {
  name: string;
  disabled?: boolean;
  format?: string;
  toggleIcon: () => void;
  onBlur?: () => void;
  onChange: (val: Dayjs) => void;
  value?: string;
  minDate?: Dayjs;
  maxDate?: Dayjs;
}

export const DateInputWithCalendarToggle = (props: IDateInputWithCalendarToggleProps) => {
  var customParseFormat = require("dayjs/plugin/customParseFormat");
  dayjs.extend(customParseFormat);
  const { isMobile } = useWindowSize();
  const [date, setDate] = useState(props.value || '');
  const ref = useRef<HTMLInputElement>(null);
  
  const handleChangeDate = (event: any) => {
    const day = dayjs(event.target.value);

    if (((props.minDate && props.minDate.isAfter(day)) || (props.maxDate && props.maxDate.isBefore(day)))) {
      if (ref.current) {
        ref.current.focus();
      }
    }

    const inputDate = dayjs(date);
    if (inputDate.isValid()) {
      props.onChange(inputDate);
    } else {
      props.onChange(dayjs(null));
    }
  };

  useEffect(() => {
    if (props.value !== date) {
      setDate(props.value || "");
    }
  }, [props.value]);

  return (
    <div style={{ position: "relative" }}>
      <input
        ref={ref}
        value={date}
        name={props.name}
        className={`text-current font-normal text-base pt-3 pb-3 pr-2 pl-4 w-4/5 rounded my-2 outline-none`}
        placeholder="Select date"
        pattern="MM/DD/YYYY"
        onBlur={handleChangeDate}
        onChange={(event) => setDate(event.target.value)}
      />
      <Button
        style={{
          background: "transparent",
          position: "absolute",
          width: "20%",
          height: "60%",
          padding: 0,
          top: isMobile ? "20%" : "20%",
          right: isMobile ? "-23%" : "22%",
        }}
        disabled={props.disabled}
        className=""
        onClick={props.toggleIcon}
        img={<img src={calendar} alt="calendar-icon" />}
        text={""}
      />
    </div>
  );
};

DateInputWithCalendarToggle.defaultProps = {
  disabled: false,
  format: "MM/DD/YYYY",
};

interface DatePickerFieldInterface {
  name: string;
  labelName: string;
  placeholder?: string;
  showYearPicker: boolean;
  disabled?: boolean;
  dateFormat: string;
  details?: string;
  tooltip?: {
    placement?: "bottom" | "top" | "right" | "left";
    isDisabled?: 0 | 1;
    space?: number;
    text: string;
  };
  containerClassName?: string;
}
DatePickerFields.defaultProps = {
  placeholder: "Select a date",
  dateFormat: "MM/dd/yyyy",
  showYearPicker: false,
  containerClassName: classes.input,
};

export default DatePickerFields;
