import { useFormikContext } from "formik";
import React, {useState, useEffect } from "react";
import TextAreaField from "../UI/BasicForm/TextAreaField/TextAreaField";
import SelectField from "../UI/BasicForm/SelectField/SelectField";
import YearPickerField from "../UI/BasicForm/DatePickerField/YearPickerField";
import classes from "./QuoteRequestForm.module.css";
import RadioButtonsGrouped from "../UI/BasicForm/RadioButtonsGrouped/RadioButtonsGrouped";
import QuoteRequestFormStepThree from "./QuoteRequestFormStepThree";

import "react-datepicker/dist/react-datepicker.css";
import { RequestQuoteFormInterface } from "../../utils/interface";
import {
  useGetAll_OutageSeasons,
  useGetAll_PartsRequestReasons,
  useGetAll_ServiceRequestReasons,
  useGetAll_RequestPriority,
} from "../../containers/PartsAndService/QuoteRequestHttpServices";
import DatePickerFields from "../UI/BasicForm/DatePickerField/DatePickerFields";

const QuoteRequestFormStepTwo = (props: stepTwoInterface) => {
  const { values, errors, touched, submitForm, setFieldValue } = useFormikContext<RequestQuoteFormInterface>();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [requestPriority, setRequestPriority] = useState<{ id: number; name: string }[]>([{ id: 0, name: "Select a priority" }]);
  const [partsRequestReasons, setPartsRequestReasons] = useState<{ id: number; name: string }[]>([{ id: 0, name: "Select a reason" }]);
  const [serviceRequestReasons, setServiceRequestReasons] = useState<{ id: number; name: string }[]>([{ id: 0, name: "Select a reason" }]);
  const [outageSeasons, setOutageSeasons] = useState<{ id: number | string; name: string }[]>([{ id: "", name: "Select an outage seasons" }]);
  const [isServiceRequest, setIsServiceRequest] = useState<boolean>(false);

  useEffect(() => {
    setFieldValue("quoteRequest.outageYear", "");
    setFieldValue("quoteRequest.outageSeasonId", "");
    setIsServiceRequest(Number(values.quoteRequest.quoteRequestType) === 1);
  }, []);
  
  useEffect(() => {
    if (values.quoteRequest.isPlannedOutage === "true") {
      setFieldValue("quoteRequest.outageYear", startDate?.getFullYear());
    } else {
      setFieldValue("quoteRequest.outageYear", "");
      setFieldValue("quoteRequest.outageSeasonId", "");
    }
  }, [values.quoteRequest.isPlannedOutage]);

  const handleOnchange = (date: Date) => {
    setStartDate(date);
    setFieldValue("quoteRequest.outageYear", date.getFullYear());
  };

  // Api fetch start-----------------------------------------------------//
  const onSuccessRP = (requestPriority: { data: [{ id: number; order: number; title: string }] }) => {
    setRequestPriority(
      requestPriority.data.map((option: { id: number; order: number; title: string }) => ({ id: option.id, name: option.title.toLowerCase() })).sort()
    );
  };
  const onErrorRP = (error: any) => {
    setRequestPriority([]);
  };
  useGetAll_RequestPriority(onSuccessRP, onErrorRP);
  // Api fetch end-------------------------------------------------------//

  // Api fetch start-----------------------------------------------------//
  const onSuccessPRR = (partsRequestReasons: { data: [{ reasoningId: number; name: string; description: string }] }) => {
    setPartsRequestReasons(
      partsRequestReasons.data.map((option: { reasoningId: number; name: string; description: string }) => ({
        id: option.reasoningId,
        name: option.name.toLowerCase(),
      })).sort()
    );
  };
  const onErrorPRR = (error: any) => {
    setPartsRequestReasons([{ id: 0, name: "Select a reason" }]);
  };
  useGetAll_PartsRequestReasons(onSuccessPRR, onErrorPRR);

  const onSuccessSRR = (serviceRequestReasons: { data: [{ reasoningId: number; name: string; description: string }] }) => {
    setServiceRequestReasons(
      serviceRequestReasons.data.map((option: { reasoningId: number; name: string; description: string }) => ({
        id: option.reasoningId,
        name: option.name.toLowerCase(),
      })).sort()
    );
  };
  const onErrorSRR = (error: any) => {
    setServiceRequestReasons([{ id: 0, name: "Select a reason" }]);
  };
  useGetAll_ServiceRequestReasons(onSuccessSRR, onErrorSRR);

  // Api fetch end-------------------------------------------------------//
  // Api fetch start-----------------------------------------------------//
  const onSuccessOS = (partsRequestReasons: { data: [{ outageSeasonId: number; name: string; description: string }] }) => {
    setOutageSeasons(
      partsRequestReasons.data.map((option: { outageSeasonId: number; name: string; description: string }) => ({
        id: option.outageSeasonId,
        name: option.name.toLowerCase(),
      })).sort()
    );
  };
  const onErrorOS = (error: any) => {
    setOutageSeasons([]);
  };
  useGetAll_OutageSeasons(onSuccessOS, onErrorOS);
  // Api fetch end-------------------------------------------------------//

  return (
    <>
      <div>
        <TextAreaField name="quoteRequest.description" height={64} labelName="Request Description*" placeholder="Description" className="" />
      </div>
      <div className={classes.flex}>
        <div className={classes.flexCol}>
          <SelectField
            defaultValue={0}
            placeholder={values.quoteRequest.quotePriority}
            options={requestPriority}
            name={"quoteRequest.quotePriorityId"}
            labelName={"Quote Turnaround Priority*"}
          />
        </div>
        <div className={classes.flexCol}>
          <DatePickerFields labelName={"Quote Needed Date*"} name="quoteRequest.quoteNeededDate" />
        </div>
      </div>
      <div className={classes.flex}>
        <div className={classes.flexCol}>
          <SelectField
            defaultValue={0}
            placeholder={values.quoteRequest.quotePriority}
            options={requestPriority}
            name={"quoteRequest.partsPriorityId"}
            labelName={!isServiceRequest ? "Parts Delivery Priority*" : "Service Delivery Priority*"}
          />
        </div>
        <div className={classes.flexCol}>
          <DatePickerFields 
            labelName={!isServiceRequest ? "Parts Needed Date*" : "Service Needed Date*"} 
            name="quoteRequest.partsNeededDate" />
        </div>
      </div>

      {!isServiceRequest && (
        <div className={classes.flex}>
          <div className={classes.flexCol}>
            <SelectField
              defaultValue={0}
              options={partsRequestReasons}
              name={"quoteRequest.partsRequestReasoningId"}
              labelName={"Parts Request Reasoning*"}
            />
          </div>
          <div className={classes.flexCol}></div>
        </div>
      )}

      {isServiceRequest && (
        <div className={classes.flex}>
          <div className={classes.flexCol}>
            <SelectField
              defaultValue={0}
              options={serviceRequestReasons}
              name={"quoteRequest.serviceRequestReasoningId"}
              labelName={"Service Request Reasoning*"}
            />
          </div>
          <div className={classes.flexCol}></div>
        </div>
      )}

      <div className={classes.flexCol}>
        <div>
          <RadioButtonsGrouped
            name="quoteRequest.isPlannedOutage"
            display="block"
            firstRadioName="Yes"
            firstRadioValue="true"
            SecondRadioName="No"
            SecondRadioValue="false"
            labelName="Is this quote related to a planned outage?"
            className=""            
          />
        </div>
      </div>
      {values.quoteRequest.isPlannedOutage === "true" && (
        <div className={classes.flex}>
          <div className={classes.flexCol}>
            <SelectField
              placeholder="Select an outage season"
              defaultValue={0}
              options={outageSeasons}
              name={"quoteRequest.outageSeasonId"}
              labelName={"Outage Season*"}
            />
          </div>
          <div className={classes.flexCol}>
            <YearPickerField
              name="quoteRequest.outageYear"
              dateFormat={"yyyy"}
              handleOnchange={handleOnchange}
              setStartDate={setStartDate}
              startDate={startDate}
              labelName="Outage Year*"
              className=""
              placeholder="Select Outage Year"
            />
          </div>
        </div>
      )}

      <hr />
      <QuoteRequestFormStepThree handleSubmitNew={props.handleSubmitNew} />
    </>
  );
};

interface stepTwoInterface {
  handleSubmitNew?(): void;
}

export default QuoteRequestFormStepTwo;
