import { CSSProperties, FunctionComponent, HTMLAttributes, useEffect, useId, useMemo, useState } from "react";
import ImageImports from "../../../../utils/ImageImports";

const {downArrow} = ImageImports;

export interface DropDownOptionItem {
    id: number | false;
    name: string;
	value?: string;
}
interface PlainDropDownProps extends HTMLAttributes<HTMLDivElement> {
    options: DropDownOptionItem[];
    onSelection: (item: DropDownOptionItem) => void;
    value?: number|false;
    selectClass?: string;
    optionsClass?: string;
    itemClass?: string;
    searchable?: boolean;
    dropdownStyles?: CSSProperties;
    defaultText?: string;
}
const PlainDropDown: FunctionComponent<PlainDropDownProps> = ({options, onSelection, value, selectClass, optionsClass, itemClass, searchable, dropdownStyles, defaultText, ...props}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [filterText, setFilterText] = useState<string>('');
    const id = useId().replaceAll(":", "_");

    const selectOption = (opt: DropDownOptionItem) => {
        setIsOpen(false);
        onSelection(opt);
    }

    useEffect(() => {
        const handleCloseDropDown = (e: any) => {
            if (id && !e.target.closest(`.${id}`)) {
                setIsOpen(false);
            }
        }

        document.body.addEventListener("click", handleCloseDropDown)
        return () => document.body.removeEventListener("click", handleCloseDropDown);
    }, [id]);

    useEffect(() => {
        setFilterText('');
    }, [isOpen]);

    const dropDownText = useMemo(() => {
        return options.find(opt => opt.id === value)?.name ?? defaultText;
    }, [options, value, defaultText]);

    return (
        <div {...props}>
            <div className={`select-container ${id}`}>
                <div className={`${selectClass} ${dropDownText === defaultText? 'text-[#999]' : ''}`} onClick={() => setIsOpen(!isOpen)} tabIndex={0}>
                    {dropDownText}
                    <img src={downArrow} alt="Open/Close" style={{
                        transition: 'transform 0.35s ease-out',
                        transform: isOpen? 'rotateX(180deg)' : 'none'
                    }} />
                </div>
                <div className={optionsClass} style={{
                    position: 'absolute',
                    overflow: 'hidden',
                    maxHeight: isOpen? '' : '0',
                    padding: isOpen? undefined : '0',
                    transition:'max-height 0.15s ease-out, padding 0.15s ease-out',
                    zIndex:1,
                    boxShadow: '0px 25px 50px -12px rgba(0, 0, 0, 0.1)',
                    marginTop: '3px',
                    // width: '-webkit-fill-available',
                    ...dropdownStyles
                }}>
                    {searchable && (
                        <input type="text" className="border border-[#999] box-border py-[6px] px-2 rounded" placeholder="Search" value={filterText} onChange={e => setFilterText(e.target.value)} />
                    )}
                    {options.filter(o => o.name.toLowerCase().includes(filterText.toLowerCase())).map(opt => (
                        <div key={`${opt.id}`} onClick={() => selectOption(opt)} className={itemClass}>{opt.name}</div>
                    ))}
                </div>
            </div>
        </div>
    );
};

PlainDropDown.defaultProps = {
    value: false,
    selectClass: '',
    optionsClass: '',
    itemClass: '',
    searchable: false,
    dropdownStyles: {},
    defaultText: 'Select'
}

export {PlainDropDown};