import { AxiosError } from "axios";
import { Form, Formik, FormikHelpers } from "formik";
import React, { Dispatch, useCallback, useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import * as Yup from "yup";
import { Store } from "../../../Store";
import {
  useCreate_PlantEquipment,
  useGet_PlantEquipment,
  useUpdate_PlantEquipment,
} from "../../../containers/PlantInformation/EquipmentsHttpServices";
import { useApiGetManuFacturerTypes, useApiProductType } from "../../../containers/PlantInformation/TypesHttpServices";
import { useOnError } from "../../../customHooks/useOnError";
import { usePermissions } from "../../../customHooks/useUserPermissions";
import { queryKeys } from "../../../react-query/queryKeys";
import { CloseAllModal } from "../../../utils/CloseAllModal";
import { InterfaceFormDataPlantEquipment, ManufacturerList, PlantEquipmentDTO, PlantEquipmentResponseData, TypeDTO } from "../../../utils/interface";
import ErrorCard from "../../ErrorCard/ErrorCard";
import Loader from "../../Loader/Loader";
import DropDownSelect from "../../UI/Form/DropDownSelect/DropDownSelect";
import TextInputField from "../../UI/Form/TextInputField/TextInputField";
import ModalGlobal from "../../UI/Modal/ModalGlobal";

const FormEquipmentAddEdit = ({
  setEquipmentApiResponseData,
  openEquipmentDeleteModal,
  selectedEquipmentId,
  isEdit,
  modaltitle,
  modaltitlename,
}: FormEquipmentAddEditProps) => {
  const {
    manufacturerType: { data: manufacturerType },
    productType: { data: productType },
    setManufacturerType,
    selectedPlant,
    isModal,
    setPlantEquipment,
    showError,
    unit,
    unitTypes,
    plantEquipment,
    setIsModal,
    setProductType,
  } = useContext(Store);
  const { hasOnePermission } = usePermissions();

  const [selectedManufacture, setSelectedManufacture] = useState<number | boolean | string | null>(null);

  const queryClient = useQueryClient();
  const setErrorData = useOnError();

  //{***************************************************************************************************************************}
  const onSuccessManufacturerGetAll = (data: TypeDTO) => {
    //console.log("onSuccess", data);
    setManufacturerType && setManufacturerType(data);
  };

  const onErrorManufacturerGetAll = (error: AxiosError<string>) => {
    setErrorData(error);
  };

  const manufacturerList = useCallback((manufacturerType: ManufacturerList[]) => {
    if (manufacturerType.length > 0) {
      if (hasOnePermission('MitsubishiPowerEquipment', ['add', 'edit'])) {
        return manufacturerType;
      } else {
        return manufacturerType.filter(item => item.id !== 1);
      }
    }
    return [];
  }, []);

  const { isLoading: mt_isLoading } = useApiGetManuFacturerTypes(onSuccessManufacturerGetAll, onErrorManufacturerGetAll);
  ///////////////////////////////////////////
  // Fetch useApiPlantEquipments Start
  ///////////////////////////////////////////

  const onSuccessPlantEquipment = (data: PlantEquipmentDTO) => {
    setPlantEquipment && setPlantEquipment(data);
  };
  const onErrorPlantEquipment = (error: AxiosError<string>) => {
    setErrorData(error);
  };

  const { isLoading } = useGet_PlantEquipment(selectedEquipmentId as number, onSuccessPlantEquipment, onErrorPlantEquipment);

  ///////////////////////////////////////////
  // Fetch useApiPlantEquipments End
  ///////////////////////////////////////////

  useApiProductType(setProductType, setErrorData);

  //{***************************************************************************************************************************}

  const { mutate: AddPlantEquipment, isLoading: isAdding, isSuccess: isAdded } = useCreate_PlantEquipment();
  const { mutate: EditPlantEquipment, isLoading: isEditing, isSuccess: isEdited, data: apiResponseEditData } = useUpdate_PlantEquipment();

  const handleOnSubmit = (values: InterfaceFormDataPlantEquipment, formikHelpers: FormikHelpers<InterfaceFormDataPlantEquipment>) => {
    // console.log("🚀 ~ file: FormEquipmentAddEdit.tsx ~ line 83 ~ handleOnSubmit ~ values", values);
    formikHelpers.setSubmitting(false);
    if (isEdit) {
      EditPlantEquipment(values, {
        onSuccess: (apiResponseEditData) => {
          setEquipmentApiResponseData(apiResponseEditData);
          queryClient.invalidateQueries(queryKeys.plantEquipments);
          setIsModal!({
            ...CloseAllModal,
            isEditedSuccess: { ...isModal.isEditedSuccess, isEquipment: true },
          });
        },
        onError: (error: unknown) => {
          setErrorData(error);
          formikHelpers.setSubmitting(false);
        },
      });
    } else {
      AddPlantEquipment(values, {
        onSuccess: (apiAddResponseData) => {
          setPlantEquipment && setPlantEquipment(apiAddResponseData);
          setEquipmentApiResponseData && setEquipmentApiResponseData(apiAddResponseData);
          queryClient.invalidateQueries(queryKeys.plantEquipments);
          setIsModal!({
            ...CloseAllModal,
            isAddedSuccess: { ...isModal.isAddedSuccess, isEquipment: true },
          });
        },
        onError: (error: unknown) => {
          setErrorData(error);
          formikHelpers.setSubmitting(false);
        },
      });
    }
  };

  useEffect(() => {
    if (isEdit) {
      if (plantEquipment.data.manufacturerType) {
        setSelectedManufacture(plantEquipment.data.manufacturerType);
      } else {
        setSelectedManufacture(-1);
      }
    } else {
      setSelectedManufacture(null);
    }
  }, [plantEquipment, isEdit]);

  let initialValues: InterfaceFormDataPlantEquipment;

  initialValues = {
    equipmentName: plantEquipment.data.equipmentName,
    unitID: unit.data ? unit.data.unitId : 0,
    plantEquipmentId: plantEquipment.data.plantEquipmentId,
    equipmentID: plantEquipment.data.equipmentID? plantEquipment.data.equipmentID : '',
    productType: plantEquipment.data.productType,
    manufacturerType: plantEquipment.data.manufacturerType ? plantEquipment.data.manufacturerType : -1,
    manufacturerName: plantEquipment.data.manufacturerName,
    equipmentModel: plantEquipment.data.equipmentModel,
  };

  if (!isEdit) {
    initialValues = {
      equipmentName: "",
      unitID: unit.data ? unit.data.unitId : 0,
      plantEquipmentId: 0,
      equipmentID: "",
      productType: 0,
      manufacturerType: null,
      manufacturerName: "",
      equipmentModel: "",
    };
  }

  return (
    <ModalGlobal modaltitle={modaltitle} modaltitlename={modaltitlename} isModalHeader={true}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        validationSchema={Yup.object().shape({
          equipmentName: Yup.string().required("This field is required"),
          equipmentID: Yup.string().required("This field is required"),
          productType: Yup.number().required("This field is required"),
          equipmentModel: Yup.string().required("This field is required"),
          manufacturerType: Yup.number()
            .min(-1)
            .nullable(true)
            .transform((_, val) => (val ? Number(val) : null)),
          manufacturerName: Yup.string().when("manufacturerType", {
            is: (manufacturerType: number) => manufacturerType === null,
            then: Yup.string().required("This field is required"),
          }),
        })}
      >
        {(formikProps) => (
          <>
            {showError.isError && <ErrorCard ErrorMessage={showError.title} ErrorType={"danger"} />}
            <Form>
              {isAdding || isLoading || mt_isLoading ? (
                <Loader />
              ) : (
                <>
                  <div>
                    <DropDownSelect
                      name="unitID"
                      options={unitTypes.data}
                      placeholder={unit.data ? "" : "No Units Avialable"}
                      labelName="Associated Unit*"
                      disabled={true}
                      defaultValue={unit.data ? unit.data.unitId : 0}
                    />
                  </div>
                  <div>
                    <TextInputField
                      name="equipmentName"
                      tooltip={{
                        text: "Unique OEM- Assigned Equipment Serial Number: (T-xxxx)",
                      }}
                      labelName="Equipment Name*"
                      placeholder="ex. T-xxxx"
                      className=""
                    />
                  </div>
                  <div>
                    <TextInputField
                      name="equipmentID"
                      tooltip={{
                        text: "Site Equipment and Unit Number (e.g. GT21 or GT2A)",
                      }}
                      labelName="Equipment ID*"
                      className=""
                      placeholder="ex. GT2A"
                    />
                  </div>
                  <div>
                    <DropDownSelect
                      name="productType"
                      options={productType}
                      labelName="Product Type*"
                      placeholder={isEdit ? "" : "Select product type"}
                      defaultValue={isEdit ? plantEquipment.data.productType : undefined}
                    />
                  </div>
                  {isEdit && (
                    <div>
                      <DropDownSelect
                        name="manufacturerType"
                        options={manufacturerList(manufacturerType)}
                        labelName="Manufacturer*"
                        placeholder={isEdit ? "" : "Select manufacturer"}
                        defaultValue={isEdit ? selectedManufacture  || -1 : undefined}
                        getSelectedOption={e => {
                          setSelectedManufacture(e);
                          if (e === -1 && (e !== initialValues.manufacturerType) && formikProps.values.manufacturerName !== "") {
                            formikProps.setFieldValue("manufacturerName","");
                          }
                        }}
                      />
                    </div>
                  )}
                  {!isEdit && (
                    <div>
                      <DropDownSelect
                        name="manufacturerType"
                        options={manufacturerList(manufacturerType)}
                        labelName="Manufacturer*"
                        placeholder={isEdit ? "" : "Select manufacturer"}
                        defaultValue={selectedManufacture || undefined} 
                        
                        getSelectedOption={e => {
                          console.log('selected Man', e);
                          setSelectedManufacture(e);
                          if (e === -1 && (e !== initialValues.manufacturerType) && formikProps.values.manufacturerName !== "") {
                            formikProps.setFieldValue("manufacturerName","");
                          }
                        }}
                      />
                    </div>
                  )}

                  {selectedManufacture === -1 && (
                    <div>
                      <TextInputField
                        name="manufacturerName"
                        tooltip={{
                          text: "Equipment Manufacturer’s if “Other OEM”",
                        }}
                        labelName="Please Describe*"
                        className=""
                        placeholder="Manufacturer’s name"
                      />
                    </div>
                  )}

                  <div>
                    <TextInputField
                      name="equipmentModel"
                      tooltip={{
                        text: "Manufacturer's Equipment Model Number (e.g. M501GAC)",
                      }}
                      labelName="Model Name*"
                      className=""
                      placeholder="ex. M501GAC"
                    />
                  </div>
                </>
              )}

              <div className="flex align-center">
                <div className="flex-grow-1 flex">
                  {isEdit ? (
                    <>
                      <button type="submit" className="primaryBtn darkBlue" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                        Save Equipment
                      </button>
                      {(
                        (hasOnePermission(['MitsubishiPowerEquipment'], 'delete') && plantEquipment.data.manufacturerType === 1) 
                        || (hasOnePermission(['ThirdPartyEquipment', 'MitsubishiPowerEquipment'], 'delete') && plantEquipment.data.manufacturerType !== 1)
                      ) && (
                        <button
                          type="button"
                          className="primaryBtn redDelete marginLeft16"
                          onClick={() => {
                            openEquipmentDeleteModal && openEquipmentDeleteModal(plantEquipment.data.plantEquipmentId);
                          }}
                        >
                          Delete
                        </button>
                      )}
                    </>
                  ) : (
                    <>
                      <button type="submit" className="primaryBtn darkBlue" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                        Add Equipment
                      </button>
                    </>
                  )}
                </div>
                <span>*Required fields</span>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </ModalGlobal>
  );
};

interface FormEquipmentAddEditProps {
  modaltitle: string;
  modaltitlename: string;
  isEdit: boolean;
  openEquipmentDeleteModal?(value: number): void;
  selectedEquipmentId?: number;
  setEquipmentApiResponseData: Dispatch<React.SetStateAction<PlantEquipmentResponseData | null>>;
}

export default FormEquipmentAddEdit;
