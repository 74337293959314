import React, { useEffect, useState } from "react";
import ImageImports from "../../../utils/ImageImports";
import { PlantEquipmentsDATA, PlantEquipmentsDTO } from "../../../utils/interface";
import Button from "../../Button/Button";
import "./EquipmentAccordian.css";
import EquipmentAccordianPanel from "./EquipmentAccordianPanel/EquipmentAccordianPanel";

const EquipmentAccordian = ({ panels, editRow, openEquipmentsAttachments }: EquipmentAccordianProps) => {
  const { downArrow } = ImageImports;
  const [newData, setNewData] = useState<PlantEquipmentsDATA[]>([]);
  const getRowID = (id: number) => {
    editRow && editRow(id);
  };

  const addMore = () => {
    const addMoreData = panels.data;
    setNewData(addMoreData);
  };
  const subtractMore = () => {
    const subtractMoreData = panels.data.slice(0, 4);
    setNewData(subtractMoreData);
  };

  let button = null;
  if (panels.data.length > 4) {
    if (newData.length > 4) {
      button = (
        <Button
          className="whiteBtn showMoreBtn subtractMore"
          text="View Less Equipment"
          img={<img src={downArrow} className={"button-dropdown-less"} alt="" />}
          onClick={subtractMore}
        />
      );
    } else {
      button = (
        <Button
          className="whiteBtn showMoreBtn addMore"
          text="View More Equipment"
          img={<img src={downArrow} className={"button-dropdown"} alt="" />}
          onClick={addMore}
        />
      );
    }
  }

  useEffect(() => {
    setNewData(panels.data);
    if (panels.data.length > 0) {
      const newSliceData = panels.data.slice(0, 4);
      setNewData(newSliceData);
    }
  }, [panels]);

  return (
    <>
      {newData.length > 0 ? (
        <>
          <div className="accordion" role="tablist">
            {newData.map((panel, index) => (
              <EquipmentAccordianPanel
                key={index}
                index={index}
                editRow={getRowID}
                openEquipmentsAttachments={openEquipmentsAttachments}
                Paneldata={{ ...panel }}
              />
            ))}
          </div>
          {button}{" "}
        </>
      ) : (
        <p style={{marginTop: "0"}}>No equipment to display</p>
      )}
    </>
  );
};

interface EquipmentAccordianProps {
  panels: PlantEquipmentsDTO;
  editRow?: (id: number) => void;
  openEquipmentsAttachments: (value: number) => void;
}

export default EquipmentAccordian;
