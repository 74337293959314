import React, { MouseEventHandler, MutableRefObject, useCallback, useEffect, useMemo, useState } from "react";
import ImageImports from "../../utils/ImageImports";
import Accordion from "../Accordian/Accordion";
import Button from "../Button/Button";
import { withTooltip } from "../PopoutTooltip/Tooltip";
import { Checkbox } from "../UI/Form/Checkbox/Checkbox";
import classes from "./AccordionListItem.module.css";
import { ItemListProps, PropertiesOfType } from "./ItemList";

const { ellipsis } = ImageImports;

interface AccordionListItemProps<T> {
  data: T;
  columns: ItemListProps<T>['columns'];
  bulkActions?: ItemListProps<T>['bulkActions'];
  itemActions?: ItemListProps<T>['itemActions'];
  deletedItem?: ItemListProps<T>['deletedItem'];
  readItemKey?: ItemListProps<T>['readItemKey'];
  accordionTitle?: ItemListProps<T>['accordionTitle'];
  tooltipPortalRef: MutableRefObject<null>;
  isChecked: boolean;
  onRowChecked: MouseEventHandler;
}

export const AccordionListItem = <T extends {id: number}>({data, columns, deletedItem, tooltipPortalRef, bulkActions, isChecked, onRowChecked, readItemKey, accordionTitle, itemActions}: AccordionListItemProps<T>) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const closeAccordion = () => setIsOpen(false);
    window.addEventListener("resize", closeAccordion);
    return () => window.removeEventListener("resize", closeAccordion);
  }, []);

  const getDataBooleanValue = useCallback(<K extends PropertiesOfType<T, boolean>>(d: T, k: K): boolean => !!d[k], []);
  const isRead = useMemo(() => {
    return readItemKey === undefined || getDataBooleanValue(data, readItemKey);
  }, [data, readItemKey, getDataBooleanValue]);
  const isDeleted = useCallback((item: T): boolean => {
    if (deletedItem) {
      if (typeof deletedItem === 'function') {
        return deletedItem(item);
      } else {
        return getDataBooleanValue(item, deletedItem);
      }
    }
    return false;
  }, [deletedItem, getDataBooleanValue]);

  const ItemActionTooltip = useMemo(() => {
    return withTooltip(Button);
  }, []);

  return (
    <Accordion 
      isDeleted={isDeleted(data)}
      titleContent={<AccordionTitle unread={!isRead} isChecked={isChecked} reportName={typeof accordionTitle === 'function'? accordionTitle(data) : ''} onRowChecked={onRowChecked} hasBulkActions={bulkActions !== undefined && bulkActions.length > 0} />}
      isOpen={isOpen}
      headerStyle={{}}
      contentStyle={{
        overflow: isOpen? 'visible' : 'hidden'
      }}
      toggleOpen={toggleOpen}
    >
      <div className={`${classes.result_container} ${classes.flex_column} ${!isRead ? classes.unread : ""}`}>
        <table className={`${classes.report_table}  ${classes.report_table_accordion}`}>
          <tbody>
            {columns.map((columnDefinition) => (
              <tr key={columnDefinition.key as string} className={classes.data_point}>
                <th className="text-sm">{columnDefinition.title}</th>
                <td className="text-base">
                  {columnDefinition.component? (<columnDefinition.component data={data} />) : (<div><>{data[columnDefinition.key]}</></div>)}
                </td>
              </tr>
            ))}
            {itemActions !== undefined && itemActions.length > 0? (
            <tr>
              <td>
                <span className="flex flex-row items-center">
                  <ItemActionTooltip 
                    className='border-none bg-transparent cursor-pointer !p-0 mb-0'
                    text={
                      <div className="flex flex-row items-center gap-4">
                        <img src={ellipsis} style={{ filter: "var(--svgFilterDarkBlueButton)" }} alt="More Actions" className="hover:cursor-pointer" /> 
                        <span className="text-dark-blue font-bold text-sm hover:cursor-pointer">More Actions</span>
                      </div>
                    }
                    style={{left: "0", right: "auto !important"}}
                    tooltipContent={<div className="rounded-lg p-3 relative">
                      <div className="flex flex-col items-start gap-6 grow -mt-1">
                          {itemActions.map((action, i) => (
                              <span key={`action-${i}`} className="text-sm" onClick={(e) => {
                                  action.onClick(e, data);
                              }}>{action.text}</span>
                          ))}
                      </div>
                  </div>} />
                </span>
              </td>
              <td></td>
            </tr>
            ) : ''}
          </tbody>
        </table>
      </div>
    </Accordion>
  );
}

interface AccordionTitleProps {
  isChecked: boolean;
  reportName: string;
  onRowChecked: MouseEventHandler;
  unread: boolean;
  hasBulkActions: boolean;
}

function AccordionTitle({isChecked, reportName, onRowChecked, unread, hasBulkActions}: AccordionTitleProps) {
  const checkRow: MouseEventHandler = (e) => {
    e.stopPropagation();
    onRowChecked(e);
  };
  return (
    <div className={`flex flex-row !p-2 items-center ${unread? 'font-bold' : ''}`}>
      {hasBulkActions && (
        <div className="pr-2" style={{flexBasis: '18px'}} onClick={checkRow}>
          <Checkbox isChecked={isChecked} onChange={() => {}} />
        </div>
      )}
      <span className="text-black text-sm">{reportName}</span>
    </div>
  );
}