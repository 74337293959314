import { CloseAllModal } from "../../../utils/CloseAllModal";
import ImageImports from "../../../utils/ImageImports";
import classes from "./PlantSiteDetailsTop.module.css";
import PlantSiteDataBlock from "../PlantSiteDataBlock/PlantSiteDataBlock";
import useWindowSize from "../../../customHooks/useWindowSize";
import { useIsFetching } from "react-query";
import Skeleton from "react-loading-skeleton";
import { queryKeys } from "../../../react-query/queryKeys";
import { toBase64 } from "../../../utils/FileUtils";
import { AxiosError } from "axios";
import { useGetFile_PlantConfigData } from "../../../containers/PlantInformation/PlantConfigHttpServices";
import { useStore } from "../../../useStore";
import dayjs from "dayjs";

const PlantSiteDetailsTop = () => {
  const {
    selectedPlant,
    isModal,
    setIsModal,
    plantConfigImage,
    user,
    header,
    setPlantConfigImage,
    selectedPlantDetails: {
      data: { plantLoad, ambientTemperatureFahrenheit, ambientTemperatureCelsius },
    },
    plantConfig: {
      data: { websiteURL, owner, address1, address2, address3, frequency, mhiRegion, notesComments, file, operators, lastOutage, nextPlannedOutage },
    },
  } = useStore();
  const { alarmWarningBlack, linkout, pencil } = ImageImports;
  const { isDesktop, isTablet, isMobile } = useWindowSize();
  // const isFetchingImage = useIsFetching([queryKeys.plantConfigImage]);
  const isFetching = useIsFetching([queryKeys.plantSiteDetails]);



  let ImageStyle;

  if (selectedPlant.plantId) {
    ImageStyle = {
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${plantConfigImage.file})`,
    };
  }

  ///////////////////////////////////////////
  // Fetch useGet_PlantConfigData //
  ///////////////////////////////////////////

  const onSuccessPlantConfigImage = (data: any) => {
    toBase64(data)
      .then((base64Image: any) => {
        setPlantConfigImage?.({
          file: base64Image,
        });
      })
      .catch((error) => {
        setPlantConfigImage?.({
          file: "",
        });
      });
  };
  const onErrorPlantConfigImage = (error: AxiosError<string>) => {
    setPlantConfigImage?.({
      file: "",
    });
  };

  const { isLoading: isLoadingImage } = useGetFile_PlantConfigData(selectedPlant.plantId, onSuccessPlantConfigImage, onErrorPlantConfigImage);

  ///////////////////////////////////////////
  // Fetch useGet_PlantConfigData End//
  ///////////////////////////////////////////

  const plantdetailsjsx = {
    address: <PlantSiteDataBlock label={"Address"} value1={address1} value2={address2} value3={address3} />,
    operators: <PlantSiteDataBlock label={"Operator"} value1={operators} />,
    output: plantLoad ? (
      <PlantSiteDataBlock label={"TOMONI Hub Monitored Output"} numberValue={`${plantLoad}`} value1={""} />
    ) : (
      <div style={{ fontSize: "0.875rem", marginTop: 0 }}>
        <strong style={{ marginTop: "2%", color: "#991B1B" }}>TOMONI Hub Monitored Output</strong>
        <p style={{ margin: "2% 0 0", fontSize: "14px", color: "#991B1B" }}>Unavailable</p>
      </div>
    ),
    ambientTemp: (
      <PlantSiteDataBlock
        label={"Ambient Temperature"}
        value1={
          ambientTemperatureCelsius || ambientTemperatureFahrenheit ? `${ambientTemperatureFahrenheit}\u00B0 F / ${ambientTemperatureCelsius}\u00B0 C` : "Unavailable"
        }
      />
    ),
    frequency: <PlantSiteDataBlock label={"Frequency"} value1={(frequency ? frequency.includes("Hz") ? frequency : frequency+" Hz" :  "Unavailable")} />,
    lastPlannedOutrage: (
      <PlantSiteDataBlock label={"Last Planned Outage"} value1={lastOutage ? dayjs(lastOutage).format('MM/DD/YYYY') : "Unavailable"} />
    ),
    nextPlannedOutrage: (
      <PlantSiteDataBlock
        label={"Next Planned Outage"}
        value1={nextPlannedOutage ? dayjs(nextPlannedOutage).format('MM/DD/YYYY') : "Unavailable"}
      />
    ),
    owner: <PlantSiteDataBlock label={"Owner"} value1={owner} />,
    mhiRegion: <PlantSiteDataBlock label={"MHI Region"} value1={mhiRegion} />,
    notes: <PlantSiteDataBlock label={"Notes"} value1={notesComments} />,
    glance: (
      <>
        <a className="text-black no-underline" href={"https://glance.amermhi.com/"} target="_blank" rel="noreferrer noopener">
          <div className="flex">
            <div className={classes.iconWrapper}>
              <img src={linkout} className="plt-icon" alt="" />
            </div>
            <div className="">
              <strong>Go to Glance</strong>
            </div>
          </div>
        </a>
      </>
    ),
  };

  return (
    <>
      <section>
        <div className={classes.tblCol1}>
          <div className={classes.tblCell}>
            <div className={classes.plName}>
              <h2>{selectedPlant.name}</h2>
              {user?.portalAdmin && (
                <div
                  id="myBtn"
                  className="flex align-center"
                  onClick={() => {                 
                    setIsModal?.({
                      ...CloseAllModal,
                      isEdit: {
                        ...isModal.isEdit,
                        isPlantConfig: true,
                      },
                    });
                  }}
                >
                  <img src={pencil} className="pencil marginLeft21" alt="" />
                  <span className={classes.table_text}>Edit Details</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {isDesktop && (
        <section>
          <div className={classes.tbl}>
            <div className={classes.tblCell}>
              <div className={classes.mapWrp}>
                {isLoadingImage ? <Skeleton height={200} /> : <div className={classes.map} style={ImageStyle}></div>}
              </div>
            </div>
            <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>{plantdetailsjsx.output}</div>
              <div className={classes.marginBottom24}>{plantdetailsjsx.ambientTemp}</div>
              <div>{plantdetailsjsx.frequency}</div>
            </div>
            <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>{plantdetailsjsx.lastPlannedOutrage}</div>
              <div className={classes.marginBottom24}>{plantdetailsjsx.nextPlannedOutrage}</div>
            </div>
            {/* <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>
                {isFetching > 0 ? (
                  <Skeleton height={56} />
                ) : (
                  <div className={classes.flex}>
                    <div className={classes.iconWrapper}>
                      <img src={alarmWarningBlack} className="plt-icon" alt="" />
                    </div>
                    <div className={classes.font14}>
                      <strong>View Active Alarms</strong>
                    </div>
                  </div>
                )}
              </div>
            </div> */}
            <div className={classes.tblCell}>
              <div className="row-to-col font14 flex-grow-1">
                {isFetching > 0 ? (
                  <Skeleton height={56} style={{ marginBottom: "24px" }} />
                ) : (
                  <div className={`${classes.flex} ${classes.marginBottom24}`}>{plantdetailsjsx.glance}</div>
                )}
                {isFetching > 0 ? (
                  <Skeleton height={56} />
                ) : (
                  <a className={`text-black no-underline ${websiteURL.length > 0? '' : 'pointer-events-none opacity-30'}`} href={websiteURL} target="_blank" rel="noreferrer noopener">
                    <div className={classes.flex}>
                      <div className={classes.iconWrapper}>
                        <img src={linkout} className="plt-icon" alt="" />
                      </div>
                      <b className="header flex-grow-1">View Website</b>
                    </div>
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className={classes.tbl}>
            <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>{plantdetailsjsx.owner}</div>
              <div>{plantdetailsjsx.address}</div>
            </div>
            <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>{plantdetailsjsx.operators}</div>
              <div className={classes.marginBottom24}>{plantdetailsjsx.mhiRegion}</div>
            </div>
            <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>{plantdetailsjsx.notes}</div>
            </div>
          </div>
        </section>
      )}
      {isTablet && (
        <section>
          <div className={classes.tbl}>
            <div className={classes.tblCell}>
              <div className={classes.mapWrp}>
                <div className={classes.mapWrp}>
                  {isLoadingImage ? <Skeleton height={200} /> : <div className={classes.map} style={ImageStyle}></div>}
                </div>
              </div>
            </div>
            <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>{plantdetailsjsx.output}</div>
              <div className={classes.marginBottom24}>{plantdetailsjsx.ambientTemp}</div>
              <div>{plantdetailsjsx.frequency}</div>
            </div>
          </div>
          {isFetching > 0 ? (
            <Skeleton height={56} style={{ marginBottom: "24px" }} />
          ) : (
            <>
              <div className={classes.tbl}>
                <div className={classes.tblCell}>
                  <div className={classes.marginBottom24}>{plantdetailsjsx.lastPlannedOutrage}</div>
                  <div>{plantdetailsjsx.nextPlannedOutrage}</div>
                </div>
                {/* <div className={classes.tblCell}>
                  <div className={classes.marginBottom24}>
                    <div className={classes.flex}>
                      <div className={classes.iconWrapper}>
                        <img src={alarmWarningBlack} className="plt-icon" alt="" />
                      </div>
                      <div className={classes.font14}>
                        <strong>View Active Alarms</strong>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className={classes.tblCell}>
                  <div className="row-to-col font14 flex-grow-1">
                    <div className={`${classes.flex} ${classes.marginBottom24}`}>{plantdetailsjsx.glance}</div>
                    <a className="text-black no-underline" href={websiteURL} target="_blank" rel="noreferrer noopener">
                      <div className={classes.flex}>
                        <div className={classes.iconWrapper}>
                          <img src={linkout} className="plt-icon" alt="" />
                        </div>
                        <b className="header flex-grow-1">View Website</b>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className={`${classes.tbl} ${classes.half}`}>
            <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>{plantdetailsjsx.owner}</div>
            </div>
            <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>{plantdetailsjsx.operators}</div>
            </div>
            <div className={classes.tblCell}>
              <div>{plantdetailsjsx.address}</div>
            </div>

            <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>{plantdetailsjsx.mhiRegion}</div>
            </div>
            <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>{plantdetailsjsx.notes}</div>
            </div>
          </div>
        </section>
      )}
      {isMobile && (
        <section>
          <div className={classes.tbl}>
            <div className={classes.tblCell}>
              <div className={classes.mapWrp}>
                <div className={classes.mapWrp}>
                  {isLoadingImage ? <Skeleton height={200} /> : <div className={classes.map} style={ImageStyle}></div>}
                </div>
              </div>
            </div>
            <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>{plantdetailsjsx.output}</div>
              <div className={classes.marginBottom24}>{plantdetailsjsx.ambientTemp}</div>
              <div className={classes.marginBottom24}>{plantdetailsjsx.frequency}</div>
            </div>
            <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>{plantdetailsjsx.lastPlannedOutrage}</div>
              <div className={classes.marginBottom24}>{plantdetailsjsx.nextPlannedOutrage}</div>
            </div>
            {/* <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>
                <div className={classes.flex}>
                  <div className={classes.iconWrapper}>
                    <img src={alarmWarningBlack} className="plt-icon" alt="" />
                  </div>
                  <div className={classes.font14}>
                    <strong>View Active Alarms</strong>
                  </div>
                </div>
              </div>
            </div> */}
            <div className={classes.tblCell}>
              <div className="row-to-col font14 flex-grow-1">
                <div className={`${classes.flex} ${classes.marginBottom24}`}>{plantdetailsjsx.glance}</div>
                <div className={` ${classes.marginBottom24}`}>
                  <a className="text-black no-underline" href={websiteURL} target="_blank" rel="noreferrer noopener">
                    <div className={`${classes.flex}`}>
                      <div className={classes.iconWrapper}>
                        <img src={linkout} className="plt-icon" alt="" />
                      </div>
                      <b className="header flex-grow-1">View Website</b>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.tbl}>
            <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>{plantdetailsjsx.owner}</div>
              <div className={classes.marginBottom24}>{plantdetailsjsx.address}</div>
            </div>
            <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>{plantdetailsjsx.operators}</div>
              <div className={classes.marginBottom24}>{plantdetailsjsx.mhiRegion}</div>
            </div>
            <div className={classes.tblCell}>
              <div className={classes.marginBottom24}>{plantdetailsjsx.notes}</div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default PlantSiteDetailsTop;
