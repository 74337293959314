import { useContext, useEffect, useRef, useState } from "react";

import ImageImports from "../../utils/ImageImports";
import { RequestQuoteFormInterface } from "../../utils/interface";
import classes from "./AttachmentCard.module.css";
import QuoteAttachmentOptions from "./QuoteAttachmentOptions";

import dayjs from "dayjs";
import { useFormikContext } from "formik";
import Skeleton from "react-loading-skeleton";
import { useIsFetching } from "react-query";
import { Store } from "../../Store";
import { CloseAllModal } from "../../utils/CloseAllModal";
import { supportedFileTypes } from "../../utils/getSupportedFileTypes";

interface AttachmentCardProps {
  id: number;
  fileName: string;
  lastUpdated: string;
  image: string;
  disabled?:boolean;
}

function AttachmentCard(props: AttachmentCardProps) {
  // Image imports;
  const { ellipsis, trash, file_edit, excelDummy, docDummy, pdfDummy} = ImageImports;
  const { setFieldValue } = useFormikContext<RequestQuoteFormInterface>();
  const { isModal, setIsModal } = useContext(Store);
  const [imgBase64, setImgBase64] = useState<string>("");
  const { docTypes, excelTypes } = supportedFileTypes();
  const isFetching = useIsFetching();

  const popoutToolTipListItems = [
    {
      img: file_edit,
      text: "Edit",
      onclick: (id:number) => {
        if(id === 1){
            setIsModal?.({
              ...CloseAllModal,
              isAdd: { ...isModal.isAdd, isPartsListDocument: true },
            })
        }else if(id === 2){
          setIsModal?.({
            ...CloseAllModal,
            isAdd: { ...isModal.isAdd, isAttachNameplateAsset: true },
          })
        }else{
          setIsModal?.({
            ...CloseAllModal,          
          })
        }
      },
    },    
    {
      img: trash,
      text: "Delete",
      onclick: (id:number) => {
        if(id === 1){
          setFieldValue("partsDocumentBase64","");
          setFieldValue("partsDocument.file","");
          setFieldValue("partsDocument.name","");
        }else if(id === 2){
          setFieldValue("nameplateBase64","");
          setFieldValue("nameplate.file","");
          setFieldValue("nameplate.name","");
        }else{
          setIsModal?.({
            ...CloseAllModal,          
          })
        }
        
      },
    },
  ];

  // const stateContext = useContext(Store);
  const ActionToolTipRef = useRef<HTMLImageElement>(null);
  const [ actionToolTip, setActionToolTip] = useState(false);

  useEffect(() => {
    const handleCloseDropDown = (event: any) => {
      var path = event.path || (event.composedPath && event.composedPath());
      if (path[0] !== ActionToolTipRef.current) {
        setActionToolTip(false);
      }
    };
    document.body.addEventListener("click", handleCloseDropDown);
    //Cleanup
    return () => document.body.removeEventListener("click", handleCloseDropDown);
  }, []);

  useEffect(() => {
    setImgBase64(props.image);
  }, [props.image]);

  let previewContainer;  
  if (imgBase64) {
    if (imgBase64.includes("data:image")) {   
      previewContainer = <div className={`${classes.col_item} ${classes.imgPrevWrapper}`} onLoad={() => URL.revokeObjectURL(imgBase64 || "")} style={{ backgroundImage: `url(${imgBase64})` }}></div>;
    }
    if (imgBase64.includes("data:application")) {
      if (imgBase64.includes("data:application/pdf")) {
        //previewContainer = <iframe src={imgBase64}></iframe>;
        previewContainer = <div className={`${classes.col_item} ${classes.imgPrevWrapper}`} onLoad={() => URL.revokeObjectURL(pdfDummy || "")} style={{ backgroundImage: `url(${pdfDummy})` }}></div>;
      } else {
        docTypes.forEach((type) => {
          if (imgBase64.includes(type)) {        
            previewContainer = <div className={`${classes.col_item} ${classes.imgPrevWrapper}`} onLoad={() => URL.revokeObjectURL(docDummy || "")} style={{ backgroundImage: `url(${docDummy})` }}></div>;
          }
        });
        excelTypes.forEach((type) => {
          if (imgBase64.includes(type)) {            
            previewContainer = <div className={`${classes.col_item} ${classes.imgPrevWrapper}`} onLoad={() => URL.revokeObjectURL(excelDummy || "")} style={{ backgroundImage: `url(${excelDummy})` }}></div>;
          }
        });
      }
    }
  } 


  return (
    <>
      <div className={classes.nameplate_title}>
        {
          <div className={classes.nameplate_content} title={props.fileName}>
            <div className={classes.nameplate_ellipse}>
              <img
                ref={ActionToolTipRef}
                src={ellipsis}
                className="ellipsis"
                alt="ellipsis"
                style={{filter: 'var(--svgFilterDarkBlueButton)'}}
                onClick={() => {
                 !props.disabled && setActionToolTip(!actionToolTip);
                }}
                tabIndex={1}
              />
              <QuoteAttachmentOptions listItems={popoutToolTipListItems} displayTrigger={actionToolTip} id={props.id} />
            </div>
            {isFetching > 0 ? <Skeleton height={200} /> : previewContainer }

            <div className={`${classes.flex_grow1} ${classes.col_item} ${classes.nameplate_text_container}`}>
              <div className="col-item flex-grow-1 truncate block" >{props.fileName}</div>
              <div className={`${classes.flex_grow1} ${classes.col_item} ${classes.grey_font}`}>
                Last Modified: {`${dayjs(props.lastUpdated).format("MMM DD YYYY")}`}
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
}

export default AttachmentCard;
