import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import {
  useCreate_Attachment,
  useGet_AttachmentByid,
  useGet_FileBlob,
  useUpdate_QuoteAttachment,
  useUpdate_QuoteStatus,
} from "../../containers/PartsAndService/QuoteRequestHttpServices";
import { useOnError } from "../../customHooks/useOnError";
import { queryKeys } from "../../react-query/queryKeys";
import { queryClient } from "../../react-query/useQueryClientGet";
import { useStore } from "../../useStore";
import { CloseAllModal } from "../../utils/CloseAllModal";
import ImageImports from "../../utils/ImageImports";
import { QuoteFormInterfaceDTO, QuoteRequestAttachment, QuoteRequestGetAll, quoteAttachmentFormInterface } from "../../utils/interface";
import { ItemTypeCatalogIds, QuoteRequestEventID } from "../../utils/moduleIDs";
import Loader from "../Loader/Loader";
import DragDropFieldFormik from "../UI/Form/DragDropField/DragDropFieldFormik";
import TextInputField from "../UI/Form/TextInputField/TextInputField";
import ModalGlobal from "../UI/Modal/ModalGlobal";
import classes from "./AttachPartDocumentModal.module.css";
import DragDropFieldInfo from "../UI/Form/DragDropField/DragDropFieldInfo";

const AttachQuoteDocumentModal = ({
  quoteRequestList,
  isEdit,
  attachmentID,
  quoteRequestAttachmentTypeID,
  modaltitle,
  handleDeleteAttachment,
}: quoteProps) => {
  const { isModal, setIsModal } = useStore();
  const [previewBase24, setPreviewBase24] = useState<string>("");
  const [attachment, setAttachment] = useState<File|Blob>();
  const setErrorData = useOnError();
  const [quoteRequestAttachment, setQuoteRequestAttachment] = useState<QuoteRequestAttachment>({
    quoteRequestAttachmentId: 0,
    name: "",
    quoteRequestAttachmentTypeId: 0,
    fileType: 0,
    fileLocation: "",
    created: "",
    createdBy: "",
    createdById: 0,
    size: 0,
    file: null,
    fileTypeName: "",
    quoteRequestAttachmentTypeName: "",
    displaySize: "",
    fileTypeObj: { id: 0, name: "", description: "", extension: "", maxSizeMb: 0, contentType: "" },
    fileId: 0,
    sapQuoteNumber: "",
  });

  const { trash } = ImageImports;

  const { isLoading: isLoadingFile } = useGet_AttachmentByid(attachmentID, {
    enabled: attachmentID > 0,
    onSuccess(data) {
      setQuoteRequestAttachment(data.data);
    },
    onError(err) {
      setErrorData(err);
    },
  });
  
  const { isFetching: isFetchingAttachment } = useGet_FileBlob(quoteRequestAttachment.fileId, {
    enabled: quoteRequestAttachment.fileId > 0,
    onSuccess(data: Blob) {
      setAttachment(data)
    },
    onError(err) {
      setErrorData(err);
    },
  });

  const { mutate: AddAttachment, isLoading: isAdding } = useCreate_Attachment();
  const { mutate: EditAttachment, isLoading: isEditing } = useUpdate_QuoteAttachment();
  const { mutate: updateQuoteStatus, isLoading: isUpdatingStatus } = useUpdate_QuoteStatus();

  const isEditedSuccessModal = () => {
    queryClient.invalidateQueries([queryKeys.getQuoteRequest, quoteRequestList.globalItemId]);
    setIsModal?.({
      ...CloseAllModal,
      isEditedSuccess: { ...isModal.isEditedSuccess, isQuoteDocument: true },
    });
  };
  const isAddedSuccessModal = () => {
    queryClient.invalidateQueries([queryKeys.getQuoteRequest, quoteRequestList.globalItemId]);
    setIsModal?.({
      ...CloseAllModal,
      isAddedSuccess: { ...isModal.isAddedSuccess, isQuoteDocument: true },
    });
  };

  const onSuccessUpdateEvent = (values: quoteAttachmentFormInterface,isEdit:boolean) => {
    if (quoteRequestList.itemTypeId === ItemTypeCatalogIds.PartsQuoteRequest) {
      updateQuoteStatus(
        { quoteRequestId: values.quoteRequestId, quotestatusTypeId: QuoteRequestEventID.QuoteUploaded },
        {
          onSuccess: (quoteResponse: QuoteFormInterfaceDTO) => {
            isEdit ? isEditedSuccessModal() : isAddedSuccessModal();
            
          },
          onError: (error: unknown) => {
            setErrorData(error);
          },
        }
      );
    } else if (quoteRequestList.itemTypeId === ItemTypeCatalogIds.ServiceQuoteRequest) {
      updateQuoteStatus(
        { quoteRequestId: values.quoteRequestId, quotestatusTypeId: QuoteRequestEventID.QuoteUploaded },
        {
          onSuccess: (quoteResponse: QuoteFormInterfaceDTO) => {
            updateQuoteStatus(
              { quoteRequestId: values.quoteRequestId, quotestatusTypeId: QuoteRequestEventID.QuoteClosed },
              {
                onSuccess: (quoteResponse: QuoteFormInterfaceDTO) => {
                  isEdit ? isEditedSuccessModal() : isAddedSuccessModal();
                },
                onError: (error: unknown) => {
                  setErrorData(error);
                },
              }
            );
          },
          onError: (error: unknown) => {
            setErrorData(error);
          },
        }
      );
    }
  };

  const handleOnSubmit = (values: quoteAttachmentFormInterface, formikHelpers: FormikHelpers<quoteAttachmentFormInterface>) => {
    if (isEdit) {
      EditAttachment(values, {
        onSuccess(res: any) {
          onSuccessUpdateEvent(values,true);
        },
        onError(error: unknown) {
          setErrorData(error);
        },
      });
    } else {
      AddAttachment(values, {
        onSuccess(res: any) {
          onSuccessUpdateEvent(values,false);
        },
        onError(error: unknown) {
          setErrorData(error);
        },
      });
    }
  };
  let initailValues: quoteAttachmentFormInterface;
  let validationSchema;

  validationSchema = Yup.object({
    quoteRequestId: Yup.number().positive().min(0).required(),
    quoteRequestAttachmentTypeId: Yup.number().positive().min(1).required(),
    name: Yup.string().required(),
    file: Yup.string().required(),
    SAPQuoteNumber: Yup.string().when([], {
      is: () => quoteRequestList.itemTypeId === ItemTypeCatalogIds.PartsQuoteRequest,
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired(),
    }),
  });
  initailValues = {
    quoteRequestId: quoteRequestList.quoteRequestId,
    quoteRequestAttachmentTypeId: quoteRequestAttachmentTypeID,
    name: "",
    description: "",
    file: "",
    SAPQuoteNumber: "",
  };

  if (isEdit) {
    initailValues = {
      quoteRequestAttachmentId: quoteRequestAttachment.quoteRequestAttachmentId,
      quoteRequestId: quoteRequestList.quoteRequestId,
      quoteRequestAttachmentTypeId: quoteRequestAttachment?.quoteRequestAttachmentTypeId,
      name: quoteRequestAttachment?.name,
      description: quoteRequestAttachment?.description,
      file: "",
      SAPQuoteNumber: quoteRequestAttachment?.sapQuoteNumber,
    };

    validationSchema = Yup.object({
      quoteRequestId: Yup.number().positive().min(0).required(),
      quoteRequestAttachmentTypeId: Yup.number().positive().min(1).required(),
      name: Yup.string().required(),
      SAPQuoteNumber: Yup.string().when([], {
        is: () => quoteRequestList.itemTypeId === ItemTypeCatalogIds.PartsQuoteRequest,
        then: Yup.string().required(),
        otherwise: Yup.string().notRequired(),
      }),
    });
  }

  return (
    <ModalGlobal modaltitle={modaltitle} modalSize={"md"} isModalHeader={true}>
      <Formik enableReinitialize initialValues={initailValues} onSubmit={handleOnSubmit} validationSchema={validationSchema}>
        {(formikProps) => {
          const { setErrors, values, errors, setFieldValue, dirty, isSubmitting, isValid } = formikProps;
          // name being set by DragandDropfield fails to trigger formik validation
          // if name is populated AND theres still a name error AND only that error = clear errors
          if (!!values?.name?.length && !!errors["name"]?.length && !!(Object.entries(errors).length === 1)) setErrors({});
          return (
            <Form>
              {isEditing || isAdding || isUpdatingStatus ? (
                <Loader containerStyle={{marginTop: "50px"}} />
              ) : (
                <>
                  <div>
                    <TextInputField
                      name="name"
                      disabled
                      details="Document name will auto-populate to match the attachment’s filename."
                      labelName="Document Name* "
                      className=""
                      placeholder=""
                    />
                  </div>
                  {quoteRequestList.itemTypeId === ItemTypeCatalogIds.PartsQuoteRequest &&
                    <div>
                      <TextInputField name="SAPQuoteNumber" labelName="SAP Quote Number*" className="" placeholder="12345678ABC" />
                    </div>
                  }
                  <div className={`${classes.form_field_row} ${classes.add_document_field}`}>
                    <span className={classes.input_label}>Attachment*</span>
                    <div className={classes.form_group}>
                      {
                        (!!previewBase24 || !!attachment) && <><input type="text" name="attachment_name" disabled className={`${classes.input} pr-8`} style={{}} value={values.name} />
                        <img
                          className={classes.deleteFile}
                          onClick={() => {
                            setFieldValue("name", "");
                            setFieldValue("file", "");
                            setPreviewBase24("");
                            setAttachment(undefined);
                          }}
                          src={trash}
                          alt="Remove Attachment"
                        /></>
                      }
                      
                    </div>
                  </div>
                  {isLoadingFile ? (
                    <Loader containerStyle={{marginTop: "50px", marginBottom:"100px"}} />
                  ) : (
                    <DragDropFieldFormik
                      name="file"
                      onChange={setAttachment}
                      attachment={attachment}
                      displayName={"name"}
                      previewBase24={previewBase24}
                      fileLoading={!!isFetchingAttachment}
                    />
                  )}

                  <div className={`${classes.form_field_row}`}>
                    <DragDropFieldInfo fileTypes=".PDF, .DOC, .XLSX, .JPG, .PNG" />
                  </div>
                </>
              )}
              <div className="flex align-center">
                <div className="flex-grow-1 flex">
                  {isEdit ? (
                    <>
                      <button type="submit" className="primaryBtn darkBlue" disabled={!dirty || isSubmitting}>
                        Save Changes
                      </button>
                      <button type="button" className="primaryBtn redDelete marginLeft16" onClick={() => handleDeleteAttachment!(attachmentID)}>
                        Delete
                      </button>
                    </>
                  ) : (
                    <>
                      <button type="submit" className="primaryBtn darkBlue" disabled={!dirty || !isValid || isSubmitting}>
                        Add Document
                      </button>
                      <button
                        type="button"
                        className="primaryBtn whiteBtn marginLeft16"
                        disabled={isSubmitting}
                        onClick={() =>
                          setIsModal!({
                            ...CloseAllModal,
                          })
                        }
                      >
                        Cancel
                      </button>
                    </>
                  )}
                </div>
                <span>*Required fields</span>
              </div>
            </Form>
          );
        }}
      </Formik>
    </ModalGlobal>
  );
};

interface quoteProps {
  quoteRequestList: QuoteRequestGetAll;
  isEdit: boolean;
  attachmentID: number;
  quoteRequestAttachmentTypeID: number;
  handleDeleteAttachment?(id: number): void;
  modaltitle: string;
}
export default AttachQuoteDocumentModal;
