export enum ModuleIDs {
  Home = 1,
  PlantInformation = 2,
  PlantDetails = 3,
  MitsubishiPowerEquipment = 4,
  ThirdPartyEquipment = 5,
  EquipmentAttachments = 6,
  MitsubishiPowerContacts = 7,
  CustomerContacts = 8,
  IssueReports = 9,
  DiscussionThreads = 10,
  DiscussionMessages = 11,
  PartsServices= 12,
  PartsServiceQuotes = 13,
  QuoteRequestStatus = 14,
  PartsCatalog = 15,
  PartsCatalogDocumentGroups = 16,
  VoiceofCustomerResponses = 17,
  TechnicalDocumentation = 18,
  MitsubishiPowerReports = 19,
  MitsubishiPowerReportsDocumentGroups = 20,
  TechnicalBulletins = 21,
  TechnicalBulletinDocumentGroups = 22,
  OMManuals = 23,
  OMManualDocumentGroups = 24,
  Support = 25,
  CustomerRequestedTechnicalInfo = 26,
  CustomerSupport = 27,
  Settings = 28,
  Administration = 29,
  OrganizationManagement = 30,
  PlantManagement = 31,
  UnitManagement = 32,
  RoleConfiguration = 33,
  UserManagement = 34,
  EmailAlertNotificationManagement = 35,
  DataAndReporting = 35,
  GroupManagement = 36,
}

export enum ItemTypeCatalogIds {
  CPItem = 1,
  Document = 2,
  Request = 3,
  SharedDocuments = 4,
  PartsCatalog = 5,
  RMCReport = 6,
  OMManual = 7,
  TechnicalBulletin = 8,
  PlantSupportDocument = 9,
  MitsubishiPowerReport = 10,
  RepairReport = 11,
  TuningReport = 12,
  QuarterlyReport = 13,
  OutageReport = 14,
  RootCauseAnalysis = 15,
  PartsQuoteRequest = 16,
  ServiceQuoteRequest = 17,
  TechnicalInformationRequest = 18,
  IssueReport = 19,
  StaticFile = 20
};

export enum QuoteRequestEventID {
  RequestReceivedFromCustomer = 1,
  QuoteRequestSubmittedInPortal = 2,
  SubmittedToQuoteTeam = 3,
  QuoteTeamCompleted = 4,
  QuoteUploaded = 5,
  QuoteClosed = 6,
  PurchaseOrderUploaded = 7,
}

export enum QuoteRequestAttachmentTypeID {
  PartsDocument = 1,
  NameplateAsset = 2,
  QuoteDocument = 3,
  PODocument = 4,
}
