import { useFormikContext } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Store } from "../../Store";
import { CloseAllModal } from "../../utils/CloseAllModal";
import { toBase64 } from "../../utils/FileUtils";
import ImageImports from "../../utils/ImageImports";
import { RequestQuoteFormInterface } from "../../utils/interface";
import DragDropFieldFormik from "../UI/Form/DragDropField/DragDropFieldFormik";
import TextInputField from "../UI/Form/TextInputField/TextInputField";
import ModalGlobal from "../UI/Modal/ModalGlobal";
import classes from "./AttachPartDocumentModal.module.css";
import DragDropFieldInfo from "../UI/Form/DragDropField/DragDropFieldInfo";

const AttachNameplateAssetModal = () => {
  const { values, setFieldValue } = useFormikContext<RequestQuoteFormInterface>();

  const [attachment, setAttachment] = useState<File | Blob>();
  const [previewBase24, setPreviewBase24] = useState<string>(""); 

  const { setIsModal } = useContext(Store);
  const { trash } = ImageImports;

  const handleAddDocument = () => {
    if (attachment) {
      toBase64(attachment)
        .then((base64Image: string) => {
          setFieldValue("nameplateBase64", base64Image); 
          setIsModal?.({ ...CloseAllModal });
        })
        .catch((err: Error) => {
          setFieldValue("nameplateBase64", "");
        });
    }else{
      setIsModal?.({ ...CloseAllModal });
    }
  };

  const handleCancel = () => {
    setIsModal?.({ ...CloseAllModal });
  };

  useEffect(() => {
    setPreviewBase24(values.nameplateBase64);    
  }, []);

  return (
    <ModalGlobal modaltitle={"Add a Nameplate Asset"} modalSize={"md"} isModalHeader={true} modalClose={handleCancel}>
      <div>
        <>
        <div>
            <TextInputField
              name="nameplate.name"
			        disabled
              details="Document name will auto-populate to match the attachment’s filename."
              labelName="Document Name* "
              className=""
              placeholder="ex Nameplate"
            />
          </div>
          <div className={`${classes.form_field_row} ${classes.add_document_field}`}>
            For third-party equipment configurations where multiple nameplate assets are available, please combine multiple nameplate images into one file before attaching below.
          </div>
          <div className={`${classes.form_field_row} mb-4 ${classes.add_document_field}`}>
            <span className={classes.input_label}>Attachment*</span>
            <div className={classes.form_group}>
              {(!!attachment || !!previewBase24) && (
                <>
                  <input type="text" disabled className={`${classes.input} pr-8`} style={{}} value={values?.nameplate?.name} />
                  <img
                    className={classes.deleteFile}
                    onClick={() => {                  
                      setAttachment(undefined);
                      setFieldValue("nameplate.name", "");
                      setFieldValue("nameplate.file", "");  
                      setFieldValue("nameplateBase64", "");       
                      setPreviewBase24("");          
                    }}
                    src={trash}
                    alt="Remove Attachment"
                  />
                </>
              )}
            </div>
          </div>
          <DragDropFieldFormik 
		  	    name="nameplate.file" 
			      onChange={setAttachment} 
            displayName={"nameplate.name"}
            attachment={attachment} 
            previewBase24={previewBase24} 
          />
          
          <div className={`${classes.form_field_row}`}>
            <DragDropFieldInfo fileTypes=".PDF, .DOC, .XLSX, .JPG, .PNG" />
          </div>
          <div className="flex align-center">
            <div className="flex-grow-1 flex">
              <button type={"button"} className="primaryBtn darkBlue" disabled={(!(!!values?.nameplate?.name && !!attachment))}  onClick={handleAddDocument}>
                Add Nameplate
              </button>
              <button type={"button"} className="primaryBtn whiteBtn marginLeft16" onClick={handleCancel}>
                Cancel
              </button>
            </div>
            <span>*Required fields</span>
          </div>
        </>
      </div>
    </ModalGlobal>
  );
};

export default AttachNameplateAssetModal;
