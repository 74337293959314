import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Store } from "../../Store";
import { useUpdate_AccountSettings } from "../../containers/PlantInformation/DashboardHttpServices";
import { useOnError } from "../../customHooks/useOnError";
import useWindowSize from "../../customHooks/useWindowSize";
import { HeaderData } from "../../utils/interface";
import Button from "../Button/Button";
import Dropdown from "../Dropdown/Dropdown";
import { PlainDropDown } from "../UI/Form/DropDownSelect/PlainDropDown";
import ModalGlobal from "../UI/Modal/ModalGlobal";
import classes from "./Navbar.module.css";

function AccountSettingsModal({ headerData, closeModal }: { headerData: HeaderData; closeModal: () => void}) {
  const plants = headerData.user.plants.map((p) => ({ id: p.baseOrgId, name: p.name }));
  const setErrorData = useOnError();

  const {
    setAccountSettings,
    accountSettings: { data: accountSettings },
    setShowError,
  } = useContext(Store);

  const [defaultPlant, setDefaultPlant] = useState<any>(plants.find((p) => p.id === accountSettings.defaultBaseOrgId));
  

  const [optedOut, setOptedOut] = useState<boolean>(accountSettings?.optedOut);

  const { isDesktop, isTablet } = useWindowSize();

  const { mutate: UpdateAccountSetting } = useUpdate_AccountSettings();
  const saveSettings = async () => {
    UpdateAccountSetting(
      {
        ...accountSettings,
        defaultBaseOrgId: defaultPlant?.id,
        optedOut: optedOut || false,
      },
      {
        onSuccess(data) {
          setAccountSettings?.(data)
          setShowError?.({
            isError: true,
            title: "Your account settings have been successfully updated.",
            ErrorType: "success",
          });
          closeModal();
        },
        onError(error: unknown) {
          setErrorData(error);
        },
      }
    );
  };

  return (
    <ModalGlobal modalSize="sm" modaltitle={`Account & Settings`} isModalHeader={true} modalClose={closeModal}>
      <div className={`${classes.accountModalBody}`}>
        <div>
          <strong>Account{isDesktop? '' : ' Details'}</strong>
        </div>
        <div className={`${classes.accountModalCard}`}>
          <div className="nav-item login-details" style={{ justifyContent: "flex-start" }}>
            <div className="initials">
              <img src={headerData.user.pictureUrl} alt="" />
            </div>
            <div>
              <div className="name">
                {accountSettings.fName} {accountSettings.lName}
              </div>
            </div>
          </div>      
          <div className={`mt-4 flex gap-1 ${!isDesktop? 'ml-12 flex-row' : 'flex-col'}`}>
            {!isDesktop && <strong>E-mail:</strong>}
            <span>{accountSettings.email}</span>
          </div>
          <div className={`mt-4 ${!isDesktop? 'ml-12' : ''}`}>
            If you have any questions or difficulties updating your information, <a href={`mailto:admin.customerportal@amer.mhps.com`} className="no-underline text-ocean">contact</a> the Customer Portal Administrator for assistance.
          </div>
        </div>
        <div>
          <form>
            <div className={`flex flex-col gap-2`}>
			        {isDesktop && <strong className="mt-5">Settings</strong>}
              <div className="flex flex-col lg:flex-row items-center gap-1 mt-5">
                <div className="w-full lg:w-1/3 text-normal font-bold lg:font-normal">Default Plant Site</div>
                <div className={`w-full lg:w-2/3`}>
                  <PlainDropDown selectClass={`flex flex-row items-center border-solid border-[#999] pt-1 pr-2 !pb-1 pl-4 text-normal justify-between border lg:border-0 lg:border-b ${isDesktop? '' : 'rounded'}`}
                          optionsClass="flex flex-col !flex-nowrap p-1 bg-white rounded max-h-80"
                          className="relative"
                          dropdownStyles={{overflow: 'auto'}}
                          itemClass="py-[14px] px-6 cursor-pointer hover:bg-primary-20"
                          options={plants}
                          onSelection={setDefaultPlant}
                          value={defaultPlant?.id || false}
                          defaultText="Select" />
                </div>
              </div>
            <div className="flex flex-col lg:flex-row items-center mt-5 mb-4 gap-1">
			  	    <div className="w-full lg:w-1/3 font-bold lg:font-normal">Email Settings</div>
				        <div className={`w-full lg:w-2/3`}>
                  <label className="text-normal">
                    <input type="checkbox" checked={!optedOut || false} onChange={() => setOptedOut(!optedOut)} /> Opt In to All Email Alerts
                  </label>
                </div>
              </div>
            </div>
            <div className={`flex flex-col md:flex-row mt-6 gap-x-3 gap-y-2`}>
              <Button
                className={`darkBlue`}
                disabled={(!defaultPlant?.id || defaultPlant?.id === accountSettings?.defaultBaseOrgId) && optedOut === accountSettings.optedOut}
                text={"Save"}
                onClick={saveSettings}
              />
              
              <Button className={`whiteBtn`} disabled={false} text={"Cancel"} onClick={closeModal} />
            </div>
          </form>
        </div>
      </div>
    </ModalGlobal>
  );
}
export default AccountSettingsModal;
