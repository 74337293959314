import { FunctionComponent, useContext } from "react";
import { Store } from "../../Store";
import Button from "../../components/Button/Button";
import ModalGlobal from "../../components/UI/Modal/ModalGlobal";
import { CloseAllModal } from "../../utils/CloseAllModal";
import { DocumentModuleInterface } from "../TechnicalDocumentation/TechnicalDocumentationContainer";

interface Props {
  documentModule: DocumentModuleInterface;
  deletionCount: number;
  onClose: () => void;
}

const DeleteDocumentConfirmation: FunctionComponent<Props> = ({documentModule, deletionCount, onClose}) => {
  const {selectedPlant, setIsModal} = useContext(Store);
  const close = () => {
    setIsModal && setIsModal!({ ...CloseAllModal });
    onClose();
  };
  return (
    <ModalGlobal modaltitle={`(${deletionCount}) Document(s) Successfully Deleted`} isModalHeader={true} isTitleCenter={true} modalClose={close} modalSize="sm" modalClass="max-w-[536px]">
      <div className={`flex flex-col gap-8`}>
        <div className="flex align-center" tabIndex={0}>
          <div className={`flex-grow-1 text-center`}>
            <p className="p-0 -mt-2">You successfully deleted document(s) on the {documentModule.name} page. This document(s) will no longer be accessible to {selectedPlant.name} customers.</p>
          </div>
        </div>
        <div className={`align-center flex justify-center`}>
          <Button className={`darkBlue`} disabled={false} text={"Done"} onClick={close} />
        </div>
      </div>
    </ModalGlobal>
  );
}

export default DeleteDocumentConfirmation;