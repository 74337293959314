import React, { useState, useContext, useEffect, useRef, MouseEvent, MouseEventHandler, StyleHTMLAttributes } from "react";
import { Store } from "../../Store";
import "./Dropdown.css";
import ImageImports from "../../utils/ImageImports";
import useScroll from "../../customHooks/useScroll";
import { SelectOptionProps, SelectPlantProps } from "../../utils/interface";

interface selectProps {
  className?: string;
  dropdowntype?: string;
  options: SelectOptionProps[] | SelectPlantProps[];
  isPlantSelect?: boolean;
  selectValue?: string;
  placeholderStyle?: React.CSSProperties;
  selectOptions?: Array<any>;
  isPlantSelection?: boolean;
  style?: React.CSSProperties;
  isError?: boolean;
  dropdownStyle?: React.CSSProperties;
  overrideInputMargin?: boolean;
  handleDropDownSelect(e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>, option?: any): void;
  selectedOption: string | null;
  setSelectedOption?: (option: string | null) => void;
}

Dropdown.defaultProps = {
  onScroll: false,
  isPlantSelection: false,
  isError: false,
};

function Dropdown(props: selectProps) {
  const [selectToggle, setSelectToggle] = useState(false);
  const [overrideInputMargin, setOverrideInputMargin] = useState(false);
  const [isSelected, setIsSelected] = useState<boolean>(false);

  const selectButtonRef = useRef<HTMLDivElement>(null);
  const { downArrow } = ImageImports;
  const stateContext = useContext(Store);
  const toggleSelect = () => setSelectToggle(!selectToggle);

  useEffect(() => {
    if (props?.overrideInputMargin && props.overrideInputMargin === true) {
      setOverrideInputMargin(true);
    }
  }, []);

  useEffect(() => {
    const handleCloseDropDown = (event: any) => {
      var path = event.path || (event.composedPath && event.composedPath());
      if (path[0] !== selectButtonRef.current) {
        if (path[0].id !== "drop-arrow") {
          setSelectToggle(false);
        }
      }
    };
    document.body.addEventListener("click", handleCloseDropDown);

    //Cleanup
    return () => document.body.removeEventListener("click", handleCloseDropDown);
  }, []);

  return (
    <div style={props.style} className={"selectDropdownWrp dropDown"}>
      <div className="input-box select" tabIndex={0}>
        <div
          className={"form-control selectbox "}
          ref={selectButtonRef}
          id="selectDropdown"
          onClick={toggleSelect}
          style={{ ...props.dropdownStyle, ...(props.isError ? { backgroundColor: "#FEE2E2", color: "#991B1B", borderColor:"#999999"} : {}) }}
        >
          {props.dropdowntype}{props.dropdowntype && <span>&nbsp;</span>}
          {props.selectedOption ? (
            <span style={{...(isSelected || props.isPlantSelection ? { color: "#000", textTransform: "capitalize" } : { color: "#999" }), pointerEvents: "none"}}>{props.selectedOption}</span>
          ) : (
            <span style={{...props.placeholderStyle, pointerEvents: "none"}}>{props.selectValue}</span>
          )}
          <img id="drop-arrow" src={downArrow} className={selectToggle ? "select-arrow active" : "select-arrow"} alt="" />
        </div>
      </div>
      <div className={`input-box dropdown ${selectToggle ? "active" : ""} ${overrideInputMargin ? "!mt-0 !pt-0" : ""}`}>
        <div className={`options-wrapper ${overrideInputMargin ? "!mt-0 !pt-0 !pl-0 !pr-0" : ""}`}>
          {props.isPlantSelection
            ? props.options.map((option: any, i: number) => {
                return (
                  <div
                    className="options"
                    data-id={option.plantId}
                    key={i}
                    onClick={(e) => {
                      props.setSelectedOption?.(option.name);
                      props.handleDropDownSelect?.(e, option);
                    }}
                  >
                    {option.name}
                  </div>
                );
              })
            : props.options.map((option: any, i: number) => {
                return (
                  <div
                    className="options"
                    data-id={option.id}
                    key={i}
                    onClick={(e) => {
                      props.setSelectedOption?.(option.name);
                      props.handleDropDownSelect?.(e, option);
                      setIsSelected(true);
                    }}
                  >
                    {option.name}
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
}

export default Dropdown;
