export const PortalAccessRequestSubmitted = () => {
  return (
    <div className="absolute w-full h-full bg-primary-20 flex justify-center flex-row">
      <div className="w-full md:w-[564px] bg-white self-center p-8 rounded-2xl">
        <div className="flex flex-col items-center gap-6">
          <span className="font-bold text-xl text-center">
            Portal Access Request Submitted
          </span>
          <span className="text-center">
            Mitsubishi Power will contact you if there are any issues with or
            questions about your request; otherwise, you will be notified via
            email with your login credentials.
          </span>
        </div>
      </div>
    </div>
  );
};

export default PortalAccessRequestSubmitted;
