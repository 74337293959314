import { MouseEventHandler, MutableRefObject, useContext, useEffect, useId, useState } from "react";
import { Store } from "../../Store";
import Accordion from "../../components/Accordian/Accordion";
import BulkActionTooltip, { tooltipActionItem } from "../../components/PopoutTooltip/BulkActionTooltip";
import ImageImports from "../../utils/ImageImports";
import { Report } from "../../utils/interface";
import { ColumnDefinition } from "./Reports";
import classes from "./Reports.module.css";

const { ellipsis } = ImageImports;

interface ReportRowAccordionProps {
  data: Report;
  columnDefinitions: ColumnDefinition[];
  tooltipPortalRef: MutableRefObject<null>;
  tooltipActions: tooltipActionItem[];
  isChecked: boolean;
  onRowChecked: MouseEventHandler;
  unread: boolean;
}

function ReportRowAccordion({ data, columnDefinitions, tooltipPortalRef, tooltipActions, isChecked, onRowChecked, unread }: ReportRowAccordionProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);

  const showTooltip = (e: React.MouseEvent<HTMLImageElement>) => {
    const willBeVisible = !isTooltipVisible;
    const rect = e.currentTarget.getBoundingClientRect();
    setIsTooltipVisible(willBeVisible);
  };

  const { setReport, toggleNotificationPane, notificationPaneOpen } = useContext(Store);
  const tooltipId = useId().replaceAll(":", "_");

  const Tooltip = () => {
    return (
      <div
        className="row-item text-l cursor-pointer"
        style={{ color: "#00749E" }}
        tabIndex={1}
        onClick={() => {
          setReport && setReport(data);
          toggleNotificationPane && toggleNotificationPane(!notificationPaneOpen, true);
        }}
      >
        <strong>View</strong>
      </div>
    );
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    setIsTooltipVisible(false);
  };

  useEffect(() => {
    const closeTooltip = () => setIsTooltipVisible(false);
    window.addEventListener("resize", closeTooltip);
    return () => window.removeEventListener("resize", closeTooltip);
  }, []);

  return (
    <Accordion
      isDeleted={data.isDeleted}
      titleContent={<ReportRowAccordionTitle isDeleted={data.isDeleted} unread={unread} isChecked={isChecked} reportName={data.displayFileName} onRowChecked={onRowChecked} />}
      isOpen={isOpen}
      headerStyle={{}}
      contentStyle={{}}
      toggleOpen={toggleOpen}
    >
      <div className={`${classes.result_container} ${classes.flex_column} ${unread ? classes.unread : ""}`}>
        <table className={`${classes.report_table} ${classes.report_table_accordion}`}>
          <tbody>
            {columnDefinitions.map((columnDefinition) => (
              <tr key={columnDefinition.key} className={classes.data_point}>
                <th>{columnDefinition.title}</th>
                <td>
                  {columnDefinition.component ? (
                    <columnDefinition.component report={data} tooltip={<Tooltip />} />
                  ) : (
                    <div>
                      <>{data[columnDefinition.key]}</>
                    </div>
                  )}
                </td>
              </tr>
            ))}
            <tr>
              <td>
                <div id={tooltipId} className={`${classes.flex_row} ${classes.more_actions}`}>
                  <img src={ellipsis} alt="More Actions" style={{ filter: "var(--svgFilterDarkBlueButton)" }} onClick={showTooltip} /> <span className="text-sm font-bold text-dark-blue hover:cursor-pointer" onClick={showTooltip}>More Actions</span>
                  <BulkActionTooltip isDeleted={data?.isDeleted} shown={isTooltipVisible} setShown={setIsTooltipVisible} parentId={tooltipId} listItems={tooltipActions} data={[data]} />
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </Accordion>
  );
}

export default ReportRowAccordion;

interface AccordionTitleProps {
  isChecked: boolean;
  isDeleted: boolean;
  reportName: string;
  onRowChecked: MouseEventHandler;
  unread: boolean;
}

function ReportRowAccordionTitle({ isChecked, reportName, onRowChecked, unread, isDeleted }: AccordionTitleProps) {
  const checkRow: MouseEventHandler = (e) => {
    e.stopPropagation();
    onRowChecked(e);
  };
  return (
    <div className={`${classes.flex_row} ${classes.accordion_title} ${unread ? classes.unread : ""}`}>
      <div className={classes.checkbox} style={{ flexBasis: "18px" }}>
        <input type="checkbox" checked={isChecked} onChange={() => {}} />
        <span className={classes.checkmark} onClick={checkRow} />
      </div>
      <div className="max-w-[calc(100vw-200px)] overflow-hidden text-ellipsis">
        {reportName} {isDeleted && " (Deleted)"}
      </div>
    </div>
  );
}
