import { AxiosError } from "axios";
import dayjs, { Dayjs } from "dayjs";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import { Store } from "../../Store";
import Button from "../../components/Button/Button";
import Dropdown from "../../components/Dropdown/Dropdown";
import { ItemListColumnDefinition } from "../../components/List/ItemList";
import Pill from "../../components/Pill/Pill";
import { withTooltip } from "../../components/PopoutTooltip/Tooltip";
import DateRangePicker from "../../components/UI/BasicForm/CustomSingleDatePicker/DateRangePicker";
import { RadioButtonGroup } from "../../components/UI/Form/RadioButtonsGrouped/RadioButtonsGrouped";
import { useOnError } from "../../customHooks/useOnError";
import usePagination from "../../customHooks/usePagination";
import useWindowSize from "../../customHooks/useWindowSize";
import ImageImports from "../../utils/ImageImports";
import { FilterDateComparisons } from "../../utils/Sortutils";
import { DataWithPermissions, QuoteRequestSurvey, QuoteRequestSurveyTable } from "../../utils/interface";
import classes from "../QuoteRequests/QuoteRequests.module.css";
import { useGetAllByBaseOrg_CustomerSurveys } from "./CustomerSurveyHttpServices";
import CustomerSurveyItem from "./CustomerSurveyItem";

const CustomerSurveyList = () => {
  const {
    header: {
      data: { notifications },
    },
    selectedPlant,
  } = useContext(Store);
  const [quoteRequestSurveyItems, setQuoteRequestSurveyItems] = useState<QuoteRequestSurvey[]>([]);

  const columns: ItemListColumnDefinition<QuoteRequestSurveyTable>[] = [
    {
      title: "Survey ID",
      key: "id",
      className: "text-ocean",
    },
    {
      title: "First Name",
      key: "fName",
    },
    {
      title: "Last Name",
      key: "lName",
    },
    {
      title: "Date Completed",
      key: "dateCompleted",
    },
    {
      title: "Time Completed",
      key: "timeSubmitted",
    },
    {
      title: "Details",
      // component: (({ data }) => data.quoteRequestSurveyId ? <Link className="text-ocean font-bold no-underline" to={generatePath('/parts&service/customer-survey/:param', { param: data.quoteRequestSurveyId })} state={{ render: 'view-only' }}>View</Link> : <></>),
      key: "quoteRequestSurveyId",
    },
  ];
  const sortOptions: SortOption[] = [
    {
      id: 1,
      name: "Newest to Oldest",
      key: "dateCompleted",
      direction: "desc",
    },
    {
      id: 2,
      name: "Oldest to Newest",
      key: "dateCompleted",
      direction: "asc",
    },
    {
      id: 3,
      name: "First Name: A to Z",
      key: "fName",
      direction: "asc",
    },
    {
      id: 4,
      name: "First Name: Z to A",
      key: "fName",
      direction: "desc",
    },
    {
      id: 5,
      name: "Last Name: A to Z",
      key: "lName",
      direction: "asc",
    },
    {
      id: 6,
      name: "Last Name: Z to A",
      key: "lName",
      direction: "desc",
    },
    {
      id: 7,
      name: "Clear Sort",
      key: "dateCompleted",
      direction: "desc",
    },
  ];

  interface SortOption {
    id: number;
    name: string;
    key: keyof QuoteRequestSurveyTable;
    direction: "asc" | "desc";
  }

  const [sortSelectedOption, setSortSelectedOption] = useState<string | null>(null);
  const [searchText, setSearchText] = useState("");
  let dataWithIdField: QuoteRequestSurveyTable[] = [];
  const ButtonWithTooltip = withTooltip(Button);
  const { magnifyGlassBlack, filter, Arrow } = ImageImports;
  const setErrorData = useOnError();
  const [enableClickWatcher, setEnableClickWatcher] = useState<boolean>(true);
  const [closeFilters, setCloseFilters] = useState<number>(0);

  useGetAllByBaseOrg_CustomerSurveys(selectedPlant.baseOrgId, {
    onSuccess: ({ data }: DataWithPermissions<QuoteRequestSurvey[]>) => {
      setQuoteRequestSurveyItems(data);
      setData(data);
    },
    onError: (error: AxiosError<string>) => setErrorData(error)
  });
  const [filters, setFilters] = useState<Filters>({});
  const { isDesktop, isMobile,isTablet } = useWindowSize();
  const filterReports = (item: QuoteRequestSurvey) => {
    let matches = true;
    if (filters.dateStartDate) {
      matches = dayjs(item.dateTimeSubmitted).isAfter(filters.dateStartDate);
    }
    if (matches && filters.dateEndDate) {
      matches = dayjs(item.dateTimeSubmitted).isBefore(filters.dateEndDate);
    }
    return matches;
  };

  const {
    pagedData,
    setData,
    data,
    totalRecords,
    pageCount,
    setTotalRecords,
    setFilter,
    pageButtonNumbers,
    hasPreviousPage,
    previousPage,
    hasNextPage,
    nextPage,
    setCurrentPageNumber,
    currentPageNumber,
    resultsPerPage,
    setResultsPerPage,
    setSortKey,
    setSortDirection,
  } = usePagination<QuoteRequestSurvey>();

  useEffect(() => {
    setSortDirection("desc");
    setSortKey("dateTimeSubmitted");
  }, []);

  useEffect(() => {
    if (!!quoteRequestSurveyItems?.filter(filterReports).length) {
      setData(quoteRequestSurveyItems.filter(filterReports));
      setTotalRecords(quoteRequestSurveyItems.filter(filterReports).length);
    } else {
      setData([]);
      setTotalRecords(0);
    }
  }, [quoteRequestSurveyItems, filters]);

  useEffect(() => {
    if (searchText.trim().length > 0 && !!quoteRequestSurveyItems?.length) {
      setFilter(searchText.trim());
      setTotalRecords(data.filter(filterReports).length);
    } else {
      setFilter("");
      setTotalRecords(quoteRequestSurveyItems.filter(filterReports).length);
    }
  }, [searchText]);

  let filterCount = 0;

  if (filters.dateFilter) {
    filterCount++;
  }

  const handleSort = (e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>, option?: any) => {
    setSortKey(option.key);
    setSortDirection(option.direction);
  };

  return (
    <>
      <div className="flex mb-8 items-center font-bold login-header">Voice of Customer Responses</div>
      <div className="flex flex-col grow-1 leading-4 card justify-start gap-4 rounded-b-lg bg-white p-4">
        <p className="font-bold 700 text-2xl m-0">{selectedPlant?.name} Quote Request Customer Survey Data</p>
        <hr style={{ width: "98%", margin: "16px 0" }} />
        <div className={`flex justify-between flex-col md:flex-row gap-[20px] md:gap-0`}>
          <div className={` flex justify-start flex-row gap-4 lg:gap-6 `}>
            {isDesktop && (
              <div className="nameplate-dropdown">
                <Dropdown
                  dropdowntype={"Sort by:"}
                  handleDropDownSelect={(e, option) => handleSort(option)}
                  dropdownStyle={{ height: 42 }}
                  selectValue={"Newest to Oldest"}
                  selectedOption={sortSelectedOption}
							    setSelectedOption={(sortOptionName) => setSortSelectedOption(sortOptionName)}
                  options={sortOptions}
                />
              </div>
            )}
            <div className={`${classes.flex_row} ${classes.search_keyword_container}`}>
              <img src={magnifyGlassBlack} className="" alt="keyword search" />
              <input
                className={`${classes.search_keyword} h-[42px] md:h-auto`}
                value={searchText}
                placeholder="Search survey responses"
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>

            {!isMobile && (
              <div style={{ display: "flex", alignItems: "center", height: !isDesktop ? "48px" : "initial" }}>
                <div className={`md:whitespace-nowrap  ${classes.table_icon}`}>
                  {" "}
                  {totalRecords} {totalRecords === 1 ? "Result" : "Results"}
                </div>
              </div>
            )}
          </div>
          <div className={classes.filterbutton}>
            <ButtonWithTooltip
              className="whiteBtn"
              text={
                <>
                  {filterCount > 0 ? <Pill type="primary">{filterCount}</Pill> : ""} {isDesktop ? "Filter" : "Sort/Filter"}
                </>
              }
              img={<img src={filter} alt="filter" className="bottom pl-2" />}
              textClassName="filter-text"
              forceClose={closeFilters}
              toggleClickWatcher={enableClickWatcher}
              tooltipContent={<FilterTooltipContent onFiltersApplied={setFilters} filters={filters} />}
            />
          </div>
        </div>
        {filterCount > 0 && (
          <div className={classes.filter_pill_container}>
            {filters.dateFilter && (filters.dateFilter !== "datecustom" || filters.dateFilter === "datecustom") && (
              <Pill className={classes.filter_pill} dismissible={true} onDismiss={() => setFilters({ ...filters, dateFilter: undefined, dateStartDate: undefined })} type="default">
                {DateSubmitted.find((d) => d.value === filters.dateFilter)?.label}
              </Pill>
            )}
          </div>
        )}
        <div className={`${isDesktop ? classes.table : ""}`}>
          {isDesktop && (
            <div>
              <div>
                {columns.map((val) => (
                  <div key={val.key + "-column-header"}>{val.title}</div>
                ))}
              </div>
            </div>
          )}
          <div>
            {pagedData &&
              pagedData
                .filter(filterReports)
                .map((item,index:number) => (
                  <CustomerSurveyItem key={index} unread={notifications?.filter((note) => note.itemId === item.quoteRequestId).length > 0} index={index} data={item} />
                ))}
          </div>
        </div>
        <>
          {pagedData.length === 0 && (
            <div style={{ display: "block" }} className="block px-2 py-4">
              No results to display
            </div>
          )}
        </>
        <div>
        {totalRecords > 0 && (
          <div className={`${classes.pagination} ${isDesktop || isTablet ? classes.flex_row : classes.flex_column}`}>
            <div className={`${classes.flex_row} ${classes.pagination_left}`}>
              <span className={classes.result_count}>
                {Math.min(resultsPerPage, pagedData.filter(filterReports).length)} of {totalRecords} Result{totalRecords !== 1 && "s"}
              </span>
              {pageCount > 1 && <Button className="whiteBtn show_all" text={isMobile || isTablet ? "Show All Results": "Show All"} onClick={() => setResultsPerPage(totalRecords)} style={{ order: isMobile ? "-1" : "unset" }} />}
            </div>

            {pageCount > 1 && (
              <div className={`${classes.flex_row} ${classes.page_numbers}`}>
                <img
                  src={Arrow}
                  className="pagination-left-arrow"
                  alt="arrow"
                  style={hasPreviousPage ? { filter: "invert(31%) sepia(21%) saturate(786%) hue-rotate(150deg) brightness(94%) contrast(83%)" } : {}}
                  onClick={() => {
                    if (hasPreviousPage) {
                      previousPage();
                    }
                  }}
                />
                {pageButtonNumbers.map((pageNumber) => (
                  <span
                    key={pageNumber}
                    className={`${classes.page_number} ${pageNumber === currentPageNumber ? classes.active : ""}`}
                    onClick={() => {
                      setCurrentPageNumber(pageNumber);
                    }}
                  >
                    {pageNumber}
                  </span>
                ))}
                <img
                  src={Arrow}
                  className="pagination-right-arrow"
                  style={hasNextPage ? { filter: "invert(31%) sepia(21%) saturate(786%) hue-rotate(150deg) brightness(94%) contrast(83%)" } : {}}
                  alt="arrow"
                  onClick={() => {
                    if (hasNextPage) {
                      nextPage();
                    }
                  }}
                />
              </div>
            )}
          </div>
        )}
        </div>
      </div>
    </>
  );
};

export default CustomerSurveyList;

const DateSubmitted = [
  {
    label: "Last 24 Hours",
    value: "P1D", // ISO-8601 durations
  },
  {
    label: "Last 7 Days",
    value: "P7D",
  },
  {
    label: "Last 30 Days",
    value: "P30D",
  },
  {
    label: "Last 60 Days",
    value: "P60D",
  },
  {
    label: "Last 90 Days",
    value: "P90D",
  },
  {
    label: "Custom Range",
    value: "datecustom",
  },
];

interface Filters {
  dateStartDate?: Dayjs;
  dateEndDate?: Dayjs;
  dateFilter?: string;
}
interface FilterTooltipContentProps {
  onFiltersApplied: (filters: Filters) => void;
  filters: Filters;
}

const FilterTooltipContent: FunctionComponent<FilterTooltipContentProps> = ({ onFiltersApplied, filters }) => {
  const [filtersChanged, setFiltersChanged] = useState<boolean>(false);
  const [dateStartDate, setDateStartDate] = useState(dayjs(null));
  const [dateEndDate, setDateEndDate] = useState(dayjs(null));
  const [dateFilter, setDateFilter] = useState<string>();
  const [showStartCalendar, setShowStartCalendar] = useState<boolean>(false);
  const [showEndCalendar, setShowEndCalendar] = useState<boolean>(false);

  useEffect(() => {
    if (filters.dateFilter) {
      setDateFilter(filters.dateFilter);
      if (filters.dateFilter === "datecustom") {
        if (filters.dateStartDate) {
          setDateStartDate(dayjs(filters.dateStartDate).startOf("day"));
        }
        if (filters.dateEndDate) {
          setDateEndDate(dayjs(filters.dateEndDate).startOf("day"));
        }
      }
    }
  }, [filters]);

  const applyFilters = () => {
    setFiltersChanged(false);
    const filters: Filters = {};
    if (dateFilter) {
      if (dateFilter === "datecustom" && dateStartDate && dateEndDate) {
        filters.dateStartDate = dayjs(dateStartDate.startOf("day"));
        filters.dateEndDate = dayjs(dateEndDate.endOf("day"));
      } else {
        filters.dateStartDate = FilterDateComparisons(dateFilter);
      }
      filters.dateFilter = dateFilter;
    }
    onFiltersApplied(filters);
  };

  const canApplyFilters = (dateFilter && !(dateFilter === "datecustom" && (!dateStartDate || !dateEndDate))) || filtersChanged;
  return (
    <div className={`${classes.flex_column} ${classes.filter_tooltip_content}`}>
      <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
        <div className={`${classes.flex_column} ${classes.filter_tooltip_field}`}>
          <label>Date Submitted:</label>
          <RadioButtonGroup onChange={setDateFilter} name="datecustom" options={DateSubmitted} value={dateFilter} />
        </div>
        {dateFilter === "datecustom" && (
          <DateRangePicker
            startDate={dateStartDate}
            endDate={dateEndDate}
            showEndCalendar={showEndCalendar}
            showStartCalendar={showStartCalendar}
            startName="startDate"
            endName="endDate"
            startDateChange={setDateStartDate}
            endDateChange={setDateEndDate}
            setToggleEndCalendarDisplay={setShowEndCalendar}
            setToggleStartCalendarDisplay={setShowStartCalendar}
          />
        )}
      </div>

      <div className={`${classes.filter_tooltip_field}`}>
        <Button text="Apply" className="darkBlue" onClick={applyFilters} disabled={!canApplyFilters} />
      </div>
    </div>
  );
};
