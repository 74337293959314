import { useMemo } from "react";
import { UnitDATA } from "../../utils/interface";
import Button from "../Button/Button";
import EmptyModal from "../Modals/EmptyModal";


export const AddEditUnitConfirmation = ({unit, onClose}: {unit: UnitDATA, onClose: () => void}) => {

    const wasEditing = useMemo(() => {
        return unit.unitId > 0;
    }, [unit]);

    // don't know if recording.userGroupId will be an array or number at this point... 
    let bodyText: string = '';
    let headingText = '';
    if (wasEditing) {
        headingText = 'Changes Saved';
        bodyText = `The details for "${unit.name}" have been successfully updated.`;
    } else {
        bodyText = `The "${unit.name}" has been successfully added to the Customer Portal.`;
        headingText = 'Unit Successfully Added';
    }

    return (
        <EmptyModal body={(
                <span className="self-center">{bodyText}</span>
            )}
            footer={(
                <div className="flex flex-row justify-center grow gap-4">
                    <Button className="darkBlue" text="Done" onClick={onClose} />
                </div>
            )}
            heading={headingText}
            headingClass={wasEditing? 'pl-16 text-center' : ''}
            onClose={onClose} shown={true} width={564} />
    );
};