import { useMsal } from "@azure/msal-react";
import Button from "../Button/Button";
import ModalGlobal from "../UI/Modal/ModalGlobal";
import classes from "./Navbar.module.css";

function SignOutModal({ closeModal }: { closeModal: () => void }) {
	const { instance } = useMsal();
	return (
		<ModalGlobal modalSize="sm" modaltitle={`Are you sure you want to sign out of Customer Portal?`} isModalHeader={true} modalClose={closeModal} titleClassName="centeredText">
			<div className={`${classes.accountModalBody}`}>
				<div style={{ marginTop: 40, display: "flex", justifyContent: "center" }} className={classes.flex_row}>
					<Button
						className={`darkBlue`}
						disabled={false}
						text={"Sign Out"}
						onClick={() => {							
							instance.logoutRedirect();
							localStorage.clear();					
						}}
					/>
					&nbsp;&nbsp;&nbsp;
					<Button className={`whiteBtn`} disabled={false} text={"Cancel"} onClick={closeModal} />
				</div>
			</div>
		</ModalGlobal>
	);
}
export default SignOutModal;
