import { Field, Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import {
  CPApiResponse,
  CustomerRequestInterface, QuoteRequestGetAll, User
} from "../../utils/interface";
import classes from "./QuoteRequestForm.module.css";

import dayjs from "dayjs";
import { useIsFetching } from "react-query";
import * as Yup from "yup";
import { useGetAllByBaseOrg_Users } from "../../containers/Administration/ListApiMethods";
import { useCreate_CustomerRequest } from "../../containers/PartsAndService/QuoteRequestHttpServices";
import { useOnError } from "../../customHooks/useOnError";
import { queryKeys } from "../../react-query/queryKeys";
import { queryClient } from "../../react-query/useQueryClientGet";
import { useStore } from "../../useStore";
import Loader from "../Loader/Loader";
import DatePickerFields from "../UI/BasicForm/DatePickerField/DatePickerFields";
import TextInputField from "../UI/BasicForm/TextInputField/TextInputField";

interface quoteProps {
  quoteRequestItem: QuoteRequestGetAll;
  getDirtyFlagOne(value:boolean): void;
  disabled:boolean;
}
const QuoteRequestRecievedFromCustomer = ({ quoteRequestItem, getDirtyFlagOne,disabled }: quoteProps) => {
  const { selectedPlant,isModal,setIsModal } = useStore();
  const setErrorData = useOnError();
  const isFetching = useIsFetching([queryKeys.getQuoteRequest]);
  const [quoteRequestList,setQuoteRequestList] = useState(quoteRequestItem);
  const [isDirty, setIsDirty] = useState(false);
  const [isLeavePage, setIsLeavePage] = useState(false);

  const [contactType, setContactType] = useState<{ id: number; name: string }[]>([
    { id: quoteRequestList.customerContactId, name: `${quoteRequestList.customerContact}` },
  ]);

  useEffect(() => {
    setQuoteRequestList(quoteRequestItem);
  },[quoteRequestItem]);

  useEffect(() => {
    getDirtyFlagOne(isDirty);
  }, [isDirty]);

  //{***************************************************************************************************************************}

  const onSuccessGetAllByBaseOrg = (users: CPApiResponse<User[]>) => {
    const allUsers = users.data.filter((a) => !a.mpInternal).map((contact) => ({ id: contact.id, name: `${contact.fName} ${contact.lName}` }));
    allUsers.sort((a, b) => {
      return a.name.toLowerCase().slice(0, 1) > b.name.toLowerCase().slice(0, 1) ? 1 : -1;
    });
    if (allUsers.length > 0) {
      setContactType([{ id: 0, name: "Select Customer" }, ...allUsers]);
    } else {
      setContactType([{ id: 0, name: "No designated customer contact found" }]);
    }
  };

  const onErrorGetAllByBaseOrg = (error: unknown) => {
    setErrorData(error);
  };

  useGetAllByBaseOrg_Users(selectedPlant.baseOrgId, onSuccessGetAllByBaseOrg, onErrorGetAllByBaseOrg);

  const { mutate: CreateCustomerRequest } = useCreate_CustomerRequest();

  const handleOnSubmit = (values: CustomerRequestInterface, formikHelpers: FormikHelpers<CustomerRequestInterface>) => {
    if (values.dateRequestReceivedFromCustomer) {
      values.dateRequestReceivedFromCustomer = dayjs(values.dateRequestReceivedFromCustomer).format('MM/DD/YYYY');
    }
    CreateCustomerRequest(values, {
      onSuccess: (quoteResponse) => {
        queryClient.invalidateQueries([queryKeys.getQuoteRequest, quoteResponse.data[0].globalItemId]);
      },
      onError: (error: unknown) => {
        setErrorData(error);
      },
      onSettled: () => {
        formikHelpers.setSubmitting(false);
      }
    });
  };
  //{***************************************************************************************************************************}

  let initialValues: CustomerRequestInterface;


  

  initialValues = {
    globalItemId: quoteRequestList.globalItemId,
    dateRequestReceivedFromCustomer: quoteRequestList.dateRequestReceivedFromCustomer
      ? `${dayjs(quoteRequestList.dateRequestReceivedFromCustomer).format("MM/DD/YYYY")}`
      : "",
    customerContactId: (quoteRequestList.customerContactId || 0),
    internalOutageId: quoteRequestList.internalOutageId ? quoteRequestList.internalOutageId : "",
  };

  let LastUpdated: string;
  if (quoteRequestList.quoteRequestEvents && quoteRequestList.quoteRequestEvents.length > 0) {
    LastUpdated = `Last updated on ${dayjs(quoteRequestList.quoteRequestEvents[0].modified).format("MM/DD/YYYY")}`;
  }


  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          dateRequestReceivedFromCustomer: Yup.string().required(),
          customerContactId: Yup.number().required().moreThan(0),
        })}
        onSubmit={handleOnSubmit}
      >
        {(formikProps) => {
          return (
            <Form>
              {isFetching > 0 ? (
                <Loader containerStyle={{marginTop: "50px"}} />
              ) : (
                <>
                  <div className={classes.flex}>
                    <div className={classes.flexCol}>                     
                      <DatePickerFields
                            labelName={"Date Request Received by Customer*"}                           
                            name="dateRequestReceivedFromCustomer"
                            details={LastUpdated}
                            disabled={disabled}
                          />
                    </div>
                    <div className={classes.flexCol}>
                      <label htmlFor={"customerContactId"} className={classes.input_label}>
                        Designated Customer Contact*
                      </label>
                      <Field as={"select"} name={"customerContactId"} disabled={disabled}>
                        {contactType.map((ct, i) => (
                          <option key={i} value={ct.id}>
                            {ct.name}
                          </option>
                        ))}
                      </Field>
                      {
                        <small className={classes.small} style={{ marginTop: "8px" }}>
                          {LastUpdated}
                        </small>
                      }
                    </div>
                  </div>

                  <div className={`${classes.mb32} ${classes.flex}`}>
                    <div className={classes.flexCol}>
                      <TextInputField
                        type="text"
                        name="internalOutageId"
                        tooltip={{
                          text: "CRM Outage ID"
                        }}
                        labelName="Internal Outage ID "
                        className="marginbottom0"
                        placeholder="Add internal outage ID"
                        disabled={disabled}
                        details={LastUpdated}
                      />
                    </div>
                    <div className={classes.flexCol}></div>
                  </div>
                  <div className="flex align-center">
                    <div className="flex-grow-1 flex">
                      <button type="submit" className="primaryBtn darkBlue" disabled={!formikProps.dirty || formikProps.isSubmitting || !formikProps.isValid}>
                        Publish Details
                      </button>
                    </div>
                  </div>
                </>
              )}
            </Form>
          );
        }}
      </Formik>

      
    </div>
  );
};

export default QuoteRequestRecievedFromCustomer;
